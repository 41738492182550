import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import ScoreAnimate from './ScoreAnimate';
import axios from 'axios';
import Swal from 'sweetalert2';

export default function AttemptedQuizzes() {

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Quiz />
        </div>
    )
}

function Quiz() {
    useEffect(() => {
        window.onbeforeunload = () => { sessionStorage.setItem("lpath", history.location.pathname) }
    }, [])

    const [aquizlist, setaquizlist] = useState();
    const [results, setresults] = useState([]);
    const [reviews, setreviews] = useState([]);
    const [search, setSearch] = useState("");
    const [loader, setLoader] = useState(true);
    const history = useHistory();
    const { sid, uid } = useParams()
    sessionStorage.setItem("lpath", history.location.pathname)

    useEffect(() => {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
            { ['fun_name']: "Quizapp_Get_QuizResultByUID", ['sid']: sid, ['userid']: uid })
            .then(res => {
                axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', {
                    fun_name: "Quizapp_Get_QuizReviewByUID",
                    sid: sid,
                    userid: uid
                }).then(res => {
                    let a = {};
                    try {
                        res.data.review.map(review => {
                            a = { ...a, [review.quizid]: review }
                        })
                    } catch (e) {
                        console.error(e);
                    }
                    setreviews(a);
                })
                try {
                    setresults((res.data.quizresults).reverse())
                } catch (e) {
                    setresults(results);
                    console.error(e);
                }
                setLoader(false);
            })

    }, [])

    useEffect(() => {
        try {
            setaquizlist(results.filter((quiz) => {
                return (quiz.quizName.toLowerCase().indexOf(search.toLowerCase()) !== -1)
            }))
        } catch (e) {
            console.error(e);
        }
    }, [search, results])

    return (
        <div className="quiz-container" style={{ padding: "0px", border: 0 }}>
            <div style={{
                display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginBottom: "10px", backgroundColor: "#eff1f1", padding: "5px 0px", border: "10px", borderColor: "black", transition: '10s',
                color: "rgba(4, 44, 173, 0.856)",
                fontSize: window.innerWidth > 500 ? "25px" :
                    window.innerWidth > 400 && window.innerWidth < 500 ? "22px" :
                        window.innerWidth > 300 && window.innerWidth < 350 ? "18px" :
                            window.innerWidth > 350 && window.innerWidth < 400 ? "20px" : "15px", height: "50px"
            }}>
                <div style={{ width: "50%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", cursor: "pointer" }} onClick={() => { history.push('/quizapp' + '/' + sid + '/' + uid, uid) }}>
                    <h5 style={{ textAlign: "center",fontFamily:'Poppins'  }}>Active Quizzes</h5>
                </div>
                <div style={{ width: "50%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", cursor: "pointer" }} onClick={() => { history.push('/quizapp/attemptedquizzes' + '/' + sid + '/' + uid, uid) }}>
                    <h5 style={{ textAlign: "center",fontFamily:'Poppins'  }}>Attempted Quizzes<hr className="hr-line" style={{ width: "100%", height: "3px" }} /></h5>
                </div>
            </div>
            <div className="sub-container">
                <div className="search-div">
                    <input className="quiz-search" type="text" placeholder=" Quick Search" onChange={e => { setSearch(e.target.value) }} />
                </div>
                {loader ?
                    <div id="loader">
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={70}
                            width={70}
                        /></div> :
                    <div className="quiz-list">
                        <div className="quiz-list-items">
                            {aquizlist.map((quiz, key) => {
                                return (
                                    <div key={key}>
                                        <div key={key} className="quiz-card" style={{ paddingBottom: "1px" }}>
                                            <div className="quiz-title">
                                                {key + 1} . {quiz.quizName}
                                            </div>

                                            <div className="quiz-options">
                                                {quiz.status === "pending" ?
                                                    <div style={{ color: "rgb(0, 102, 255)", fontSize: "15px", padding: "10px", cursor: "pointer", textAlign: "center", fontWeight: "500",fontFamily:'Poppins'  }}>
                                                        Your answers submitted for checking, you will get your results soon
                                                    </div>
                                                    : <>
                                                        <div>
                                                            <label style={{ fontSize: "15px", padding: "10px", cursor: "pointer",fontFamily:'Poppins'  }}
                                                                onClick={() => { history.push('/quizapp/results' + '/' + sid + '/' + uid, { id: quiz.quizid, quizName: quiz.quizName }) }}
                                                            >Leaderboard</label>
                                                            <span style={{ color: "rgba(255, 0, 64, 0.705)", fontSize: "15px", display: reviews[quiz.quizid] !== undefined ? "inline" : "none", cursor: "pointer",fontFamily:'Poppins'  }}
                                                                onClick={() => {
                                                                    if (reviews[quiz.quizid]) {
                                                                        history.push('/quizapp/review' + '/' + sid + '/' + uid, { ...quiz, ...reviews[quiz.quizid] })
                                                                    } else {
                                                                        Swal.fire('Warning', "Data Not Found", 'error')
                                                                    }
                                                                }}
                                                            >Review</span>
                                                        </div>
                                                        <div style={{ width: "50px", height: "50px" }}>
                                                            <ScoreAnimate score={quiz.score !== "0" && quiz.score !== NaN ? Math.round((quiz.score / quiz.total) * 100) : 0} textsize={30} />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }

                            <div className="quiz-notfound" style={{ display: aquizlist.length === 0 ? "block" : "none",fontFamily:'Poppins'  }}>Quizzes Not Available</div>
                        </div>
                    </div>

                }

            </div>
        </div >

    );
}
