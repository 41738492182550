import React from "react";
import Tickets from "../ReusedComponents/Tickets";
import { useParams } from "react-router-dom";

export default function Dashboard() {
  const { sid, uid } = useParams();
  console.warn("hello world!");
  return (
    <Tickets
      userType={"admin"}
      title={"Admin Dashboard"}
      options={[
        { title: "Reports", url: `/tickets/admin/reports/${sid}/${uid}` },
      ]}
    />
  );
}
