import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Canvasfd from "./Canvsd";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import CanvasV from "./CanvasV";
import { saveAs } from "file-saver";
import { DecryptED } from "../DecryptJS/Decrypt";
import moment from "moment";


var JsBarcode = require("jsbarcode");
const drawerWidth = 240;

var QRCode = require("qrcode");
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      //   width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    [theme.breakpoints.up("sm")]: {
      marginTop: 64,
    },
    width: drawerWidth,
    height: "90vh",
  },
  content: {
    // contentVisibility:'hidden',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  submit: {
    position: "fixed",
    bottom: 30,
    left: "45vw",
    marginLeft: "10%",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
function Generate(props) {
  const classes = useStyles();
  // const theme = useTheme();
  const refInput = useRef();
  // const refimg=useRef();
  // const delay = ms => new Promise(res => setTimeout(res, ms));

  const [loaderAll, setLoaderAll] = React.useState(false);
  const [Arrayd, setArray] = React.useState(null);
  const [Static_Data, setStaticData] = React.useState(null);
  const [Parent_Data, setParentData] = React.useState(null);
  const [pdatalen, setPdatalen] = React.useState(null);
  const [K, setK] = React.useState(0);
  const [B64, setB64] = React.useState(null);
  const [Canvastype, setCtype] = React.useState(0);
  const [sdatalen, setSlen] = React.useState(0);
  const [schoolName, setSchoolName] = React.useState("");

  const { userid } = useParams();
  const { sid } = useParams();
  const { did } = useParams();
  const { utype } = useParams();

  const resizedataURL = (datas, wantedWidth, wantedHeight) => {
    return new Promise(async function(resolve, reject) {
      // We create an image to receive the Data URI
      var img = document.createElement("img");

      // When the event "onload" is triggered we can resize the image.
      img.onload = function() {
        // We create a canvas and get its context.
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        // We set the dimensions at the wanted size.
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;

        // We resize the image with the canvas method drawImage();
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

        var dataURI = canvas.toDataURL();

        // This is the return of the Promise
        resolve(dataURI);
      };

      // We put the Data URI in the image's src attribute
      img.src = datas;
    });
  };

  useEffect(() => {
    const getSchoolName = async () => {
      const data = {
        fun_name: "getSchoolURL",
        sid,
      };
      await axios({
        method: "POST",
        url: "https://topschool.prisms.in/rest/index.php/user_list.json",
        data,
      }).then((response) => {
        setSchoolName(response.data);
      });
    };
    getSchoolName();

    var temp = "Student";
    if (utype != null || utype !== undefined) {
      temp = utype;
    }

    const res = axios.post(
      "https://topschool.prisms.in/rest/index.php/user_list.json?",
      {
        fun_name: "GetDefaultDesign",
        sid: sid,
        did: did,
      }
    );
    res
      .then((res) => {
        console.log(res);
        var gg = parseInt(res.data.design[0].orientation);
        console.log(gg);
        setCtype(gg);
        setArray(res.data.design);
        console.log(res.data.design);
        localStorage.setItem("design", res.data.design[0].design);
      })
      .catch((err) => {
        console.log(err);
      });

    if (utype === "Student" || utype === "staff") {
      const resp = axios.post(
        "https://topschool.prisms.in/rest/index.php/user_list.json?",
        {
          fun_name: "IDcard_getProofbyUid",
          sid: sid,
          usertype: temp,
          u_id: userid,
          did: did,
        }
      );
      resp
        .then((res) => {
          setStaticData(res);
          setSlen(res.data.student.length);
        })
        .catch(() => {});
    }
    if (utype === "Parent") {
      axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/idcard/generate/parent/specific",
        data: {
          sid: DecryptED(sid),
          userid: userid,
          design_id: did,
        },
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      })
        .then((res) => {
          // console.log(res.data)
          setParentData(res.data);
          setPdatalen(res.data.length);
          // console.log(res.data.length);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const toupper = (str) => {
    return str.toUpperCase();
  };
  const toDataURL = (url, callback) => {
    var s = url;

    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();

      reader.onloadend = function() {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", s);
    xhr.responseType = "blob";
    xhr.send();
  };
  const convertDate = (dateString) => {
    var p = dateString.split(/\D/g);
    return [p[2], p[1], p[0]].join("-");
  };
  const convertStr = (String) => {
    var p = String.replaceAll(",", ", ");

    return p;
  };
  const Load = async () => {
    setLoaderAll(true);
    var dt = JSON.parse(Arrayd[0].design);

    if (dt === null || dt === undefined) {
      window.alert("No saved design found");
      setLoaderAll(false);
    }
    if (utype === "Student" || utype === "staff") {
      if (Static_Data != null && Static_Data !== undefined) {
        for (let i = 0; i < dt.length; i++) {
          if (dt[i].text === "!name" || dt[i].text === toupper("!name")) {
            dt[i].text = toupper(Static_Data.data.student[K].name);
          }
          if (dt[i].text === "!father_name" || dt[i].text === toupper("!father_name")) {
            dt[i].text = toupper(Static_Data.data.student[K].father_name);
          }

          if (dt[i].text === "!mother_name" || dt[i].text === toupper("!mother_name")) {
            dt[i].text = toupper(Static_Data.data.student[K].father_name);
          }

          if (dt[i].text === toupper("!name_wt_moname") || dt[i].text === "!name_wt_moname") {
            dt[i].text = toupper(Static_Data.data.student[K].name_wt_moname);
          }

          if (dt[i].text === "!staffid" || dt[i].text === toupper("!staffid")) {
            dt[i].text = toupper(Static_Data.data.student[K].staffid);
          }
          if (dt[i].text === toupper("!genregid") || dt[i].text === "!genregid") {
            dt[i].text = toupper(Static_Data.data.student[K].genregid);
          }
          if (dt[i].text === toupper("!userid") || dt[i].text === "!userid") {
            dt[i].text = Static_Data.data.student[K].userid.toString();
          }
          if (dt[i].text === toupper("!gender") || dt[i].text === "!gender") {
            dt[i].text = toupper(Static_Data.data.student[K].gender);
          }
          if (dt[i].text === toupper("!roll_no") || dt[i].text === "!roll_no") {
            dt[i].text = toupper(Static_Data.data.student[K].roll_no);
          }
          if (dt[i].text === toupper("!house_details") || dt[i].text === "!house_details") {
            dt[i].text = toupper(Static_Data.data.student[K].house_details);
          }
          if (
            dt[i].text === toupper("!designation") ||
            dt[i].text === "!designation"
          ) {
            dt[i].text = toupper(Static_Data.data.student[K].staff_type);
          }
          if (
            dt[i].text === toupper("!joining_date") ||
            dt[i].text === "!joining_date"
          ) {
            dt[i].text = toupper(moment(Static_Data.data.student[K].joining_date).format("DD-MM-YYYY"));
          }
          if (
            dt[i].text === toupper("!qualification") ||
            dt[i].text === "!qualification"
          ) {
            dt[i].text = toupper(Static_Data.data.student[K].qualification);
          }
          if (
            dt[i].text === toupper("!employee_id") ||
            dt[i].text === "!employee_id"
          ) {
            dt[i].text = toupper(Static_Data.data.student[K].employee_id);
          }
          if (dt[i].id === "avatar") {
            if (
              Static_Data.data.student[K].avatar !== "" &&
              Static_Data.data.student[K].avatar.includes("https://")
            ) {
              var replaceUrl = Static_Data.data.student[K].avatar.replace(
                "https://s3.amazonaws.com/topschool-attachments/",
                "https://files.prisms.in/"
              );

              // sessionStorage.removeItem('avatar');

              toDataURL(replaceUrl, async function(dataUrl) {
                //  console.log('RESULT:', dataUrl)
                var fil = dataUrl.replace(
                  "application/octet-stream",
                  "image/png"
                );
                // let FileO = await dataURLtoFile(fil, Static_Data.data.Id[K].name + '-Avater.png')
                // let file2 = await resizeFileC(FileO);
                var file2 = await resizedataURL(fil, 300, 300);
                dt[i].crossOrigin = "";
                dt[i].src = file2;
                var hasavatar = dt.filter((e) => e.id === "father");
                if (
                  hasavatar.length === 0 ||
                  Static_Data.data.student[K].mother_avatar === ""
                ) {
                  setTimeout(() => {
                    RenderImg(JSON.stringify(dt));
                  }, 1000);
                } else if (hasavatar.length > 0) {
                  if (
                    hasavatar[0].src === undefined ||
                    hasavatar[0].src === ""
                  ) {
                    setTimeout(() => {
                      RenderImg(JSON.stringify(dt));
                    }, 1000);
                  }
                }
                // sessionStorage.setItem('avatar', file2)
                // setTimeout(() => { RenderImg(JSON.stringify(dt)) }, 1000);
              });
            } else {
              dt[i].src = "";
              dt[i].crossOrigin = "";
            }
          }
          if (dt[i].id === "father") {
            if (
              Static_Data.data.student[K].father_avatar.length > 0 &&
              Static_Data.data.student[K].father_avatar.includes("https://")
            ) {
              toDataURL(
                Static_Data.data.student[K].father_avatar,
                async function(dataUrl) {
                  var fil = dataUrl.replace(
                    "application/octet-stream",
                    "image/png"
                  );
                  var file2 = await resizedataURL(fil, 300, 300);
                  dt[i].crossOrigin = "";
                  dt[i].src = file2;
                  var hasavatar = dt.filter((e) => e.id === "mother");

                  if (hasavatar.length === 0) {
                    setTimeout(() => {
                      RenderImg(JSON.stringify(dt));
                    }, 1000);
                  } else if (hasavatar.length > 0) {
                    if (
                      hasavatar[0].src === undefined ||
                      hasavatar[0].src === ""
                    ) {
                      setTimeout(() => {
                        RenderImg(JSON.stringify(dt));
                      }, 1000);
                    }
                  }
                  // sessionStorage.setItem('avatar', file2)
                  // setTimeout(() => { RenderImg(JSON.stringify(dt)) }, 1000);
                }
              );
            } else {
              dt[i].src = "";
              dt[i].crossOrigin = "";
            }
          }
          if (dt[i].id === "mother") {
            if (
              Static_Data.data.student[K].mother_avatar !== "" &&
              Static_Data.data.student[K].mother_avatar.includes("https://")
            ) {
              toDataURL(
                Static_Data.data.student[K].mother_avatar,
                async function(dataUrl) {
                  var fil = dataUrl.replace(
                    "application/octet-stream",
                    "image/png"
                  );
                  var file2 = await resizedataURL(fil, 300, 300);
                  dt[i].crossOrigin = "";
                  dt[i].src = file2;

                  // sessionStorage.setItem('avatar', file2)
                  setTimeout(() => {
                    RenderImg(JSON.stringify(dt));
                  }, 1000);
                }
              );
            } else {
              dt[i].src = "";
              dt[i].crossOrigin = "";
            }
          }
          if (dt[i].text === "!class" || dt[i].text === toupper("!class")) {
            dt[i].text = toupper(Static_Data.data.student[K].class_name);
          }
          if (dt[i].text === "!div" || dt[i].text === toupper("!div")) {
            dt[i].text = toupper(Static_Data.data.student[K].divname);
          }
          if (dt[i].text === "!dob" || dt[i].text === toupper("!dob")) {
            dt[i].text = convertDate(Static_Data.data.student[K].dob);
          }
          if (
            dt[i].text === "!bloodgroup" ||
            dt[i].text === toupper("!bloodgroup")
          ) {
            dt[i].text = toupper(Static_Data.data.student[K].bloodgroup);
          }
          if (dt[i].text === "!address" || dt[i].text === toupper("!address")) {
            var oldfz = dt[i].fontSize;
            var fsize = oldfz;
            var tx = toupper(Static_Data.data.student[K].address);
            var areatxt = tx.length * dt[i].fontSize * dt[i].height;
            var arear = dt[i].width * (dt[i].scaleY * dt[i].height);
            while (areatxt > arear) {
              fsize = parseFloat(dt[i].fontSize) - 0.1;
              dt[i].fontSize = fsize;
              var areatxt = tx.length * fsize * dt[i].height;
              var arear = dt[i].width * (dt[i].scaleY * dt[i].height);
            }
            if (fsize * 2 > oldfz) {
              dt[i].fontSize = oldfz;
            } else {
              dt[i].fontSize = fsize * 2;
            }
            dt[i].height = dt[i].scaleY * dt[i].height;
            dt[i].width = dt[i].width * dt[i].scaleX;
            dt[i].scaleY = 1;
            dt[i].scaleX = 1;
            dt[i].text = convertStr(tx);
          }
          if (dt[i].text === "!paddress" || dt[i].text === toupper("!paddress")) {
            const oldfz = dt[i].fontSize;
            const fsize = oldfz;
            const tx = toupper(Static_Data.data.student[K].paddress);
            const areatxt = tx.length * dt[i].fontSize * dt[i].height;
            const arear = dt[i].width * (dt[i].scaleY * dt[i].height);
            while (areatxt > arear) {
              dt[i].fontSize = fsize;
            }
            if (fsize * 2 > oldfz) {
              dt[i].fontSize = oldfz;
            } else {
              dt[i].fontSize = fsize * 2;
            }
            dt[i].height = dt[i].scaleY * dt[i].height;
            dt[i].width = dt[i].width * dt[i].scaleX;
            dt[i].scaleY = 1;
            dt[i].scaleX = 1;
            dt[i].text = convertStr(tx);
          }
          if (dt[i].text === "!mobile" || dt[i].text === toupper("!mobile")) {
            dt[i].text = toupper(Static_Data.data.student[K].mobile);
          }
          if (dt[i].text === "!mobile2" || dt[i].text === toupper("!mobile2")) {
            dt[i].text = toupper(Static_Data.data.student[K].mobile2);
          }
          if (dt[i].id === "qrcode") {
            if (utype === "staff") {
              var datastr = JSON.stringify([
                {
                  staffid: Static_Data.data.student[K].staffid,
                  name: Static_Data.data.student[K].name,
                },
              ]);
            } else {
              var datastr = JSON.stringify([
                {
                  userid: Static_Data.data.student[K].userid,
                  name: Static_Data.data.student[K].name,
                  class: Static_Data.data.student[K].class_name,
                  div: Static_Data.data.student[K].divname,
                },
              ]);
            }
            try {
              dt[i].src = await QRCode.toDataURL(datastr.toString());
            } catch (err) {
              console.error(err);
            }
          }
          if (dt[i].id === "barcode") {
            var txt = dt[i].textfield.toString();
            if (utype === "staff") {
              if (txt.includes("staffid")) {
                txt = txt.replace(
                  "staffid",
                  Static_Data.data.student[K].staffid
                );
              }
              if (txt.includes("Mobile Number")) {
                txt = txt.replace(
                  "Mobile Number",
                  Static_Data.data.student[K].mobile
                );
              }
              if (txt.includes("Timestamp")) {
                txt = txt.replace("Timestamp", Date.now().toString());
              }
              var canvasbar = document.createElement("canvas");
              JsBarcode(canvasbar, txt, { format: "CODE128" });
              try {
                dt[i].src = canvasbar.toDataURL("image/png");
              } catch (err) {
                console.error(err);
              }
            }
            if (txt.includes("Userid")) {
              txt = txt.replace("Userid", Static_Data.data.student[K].userid);
            }
            if (txt.includes("Mobile Number")) {
              txt = txt.replace(
                "Mobile Number",
                Static_Data.data.student[K].mobile
              );
            }
            if (txt.includes("Timestamp")) {
              txt = txt.replace("Timestamp", Date.now().toString());
            }
            var canvasbar = document.createElement("canvas");
            JsBarcode(canvasbar, txt, { format: "CODE128" });
            try {
              dt[i].src = canvasbar.toDataURL("image/png");
            } catch (err) {
              console.error(err);
            }
          }
        }
        var hasavatar = dt.filter((e) => e.id === "avatar");

        if (hasavatar.length > 0) {
          if (hasavatar[0].src === undefined || hasavatar[0].src === "") {
            setTimeout(() => {
              RenderImg(JSON.stringify(dt));
            }, 1000);
          }
        }
        if (hasavatar.length === 0) {
          setTimeout(() => {
            RenderImg(JSON.stringify(dt));
          }, 1000);
        }
      }
    }
    if (utype === "Parent") {
      if (Parent_Data != null && Parent_Data !== undefined) {
        for (let i = 0; i < dt.length; i++) {
          if (dt[i].text === "!name" || dt[i].text === toupper("!name")) {
            dt[i].text = toupper(Parent_Data[K].name);
          }
          if (dt[i].id === "avatar") {
            if (
              Parent_Data[K].avatar !== "" &&
              Parent_Data[K].avatar.includes("https://")
            ) {
              let replaceUrl = Parent_Data[K].avatar.replace(
                "https://s3.amazonaws.com/topschool-attachments/",
                "https://files.prisms.in/"
              );

              // sessionStorage.removeItem('avatar');

              toDataURL(replaceUrl, async function(dataUrl) {
                //  console.log('RESULT:', dataUrl)
                var fil = dataUrl.replace(
                  "application/octet-stream",
                  "image/png"
                );
                // let FileO = await dataURLtoFile(fil, Static_Data.data.Id[K].name + '-Avater.png')
                // let file2 = await resizeFileC(FileO);
                var file2 = await resizedataURL(fil, 300, 300);
                dt[i].crossOrigin = "";
                dt[i].src = file2;
                var hasavatar = dt.filter((e) => e.id === "father");
                if (
                  hasavatar.length === 0 ||
                  Parent_Data.mother_avatar === ""
                ) {
                  setTimeout(() => {
                    RenderImg(JSON.stringify(dt));
                  }, 1000);
                } else if (hasavatar.length > 0) {
                  if (
                    hasavatar[0].src === undefined ||
                    hasavatar[0].src === ""
                  ) {
                    setTimeout(() => {
                      RenderImg(JSON.stringify(dt));
                    }, 1000);
                  }
                }
                // sessionStorage.setItem('avatar', file2)
                // setTimeout(() => { RenderImg(JSON.stringify(dt)) }, 1000);
              });
            } else {
              dt[i].src = "";
              dt[i].crossOrigin = "";
            }
          }
          if (dt[i].id === "father") {
            if (
              Parent_Data[K].father_avatar.length > 0 &&
              Parent_Data[K].father_avatar.includes("https://")
            ) {
              let replaceUrl = Parent_Data[K].father_avatar.replace(
                "https://s3.amazonaws.com/topschool-attachments/",
                "https://files.prisms.in/"
              );
              toDataURL(replaceUrl, async function(dataUrl) {
                var fil = dataUrl.replace(
                  "application/octet-stream",
                  "image/png"
                );
                var file2 = await resizedataURL(fil, 300, 300);
                dt[i].crossOrigin = "";
                dt[i].src = file2;
                var hasavatar = dt.filter((e) => e.id === "mother");

                if (hasavatar.length === 0) {
                  setTimeout(() => {
                    RenderImg(JSON.stringify(dt));
                  }, 1000);
                } else if (hasavatar.length > 0) {
                  if (
                    hasavatar[0].src === undefined ||
                    hasavatar[0].src === ""
                  ) {
                    setTimeout(() => {
                      RenderImg(JSON.stringify(dt));
                    }, 1000);
                  }
                }
                // sessionStorage.setItem('avatar', file2)
                // setTimeout(() => { RenderImg(JSON.stringify(dt)) }, 1000);
              });
            } else {
              dt[i].src = "";
              dt[i].crossOrigin = "";
            }
          }
          if (dt[i].id === "mother") {
            if (
              Parent_Data[K].mother_avatar !== "" &&
              Parent_Data[K].mother_avatar.includes("https://")
            ) {
              let replaceUrl = Parent_Data[K].mother_avatar.replace(
                "https://s3.amazonaws.com/topschool-attachments/",
                "https://files.prisms.in/"
              );

              toDataURL(replaceUrl, async function(dataUrl) {
                var fil = dataUrl.replace(
                  "application/octet-stream",
                  "image/png"
                );
                var file2 = await resizedataURL(fil, 300, 300);
                dt[i].crossOrigin = "";
                dt[i].src = file2;

                // sessionStorage.setItem('avatar', file2)
                setTimeout(() => {
                  RenderImg(JSON.stringify(dt));
                }, 1000);
              });
            } else {
              dt[i].src = "";
              dt[i].crossOrigin = "";
            }
          }
          if (dt[i].id === "guardian") {
            if (
              Parent_Data[K].guardian_avatar !== "" &&
              Parent_Data[K].guardian_avatar.includes("https://")
            ) {
              let replaceUrl = Parent_Data[K].guardian_avatar.replace(
                "https://s3.amazonaws.com/topschool-attachments/",
                "https://files.prisms.in/"
              );
              toDataURL(replaceUrl, async function(dataUrl) {
                var fil = dataUrl.replace(
                  "application/octet-stream",
                  "image/png"
                );
                var file2 = await resizedataURL(fil, 300, 300);
                dt[i].crossOrigin = "";
                dt[i].src = file2;

                // sessionStorage.setItem('avatar', file2)
                setTimeout(() => {
                  RenderImg(JSON.stringify(dt));
                }, 500);
              });
            } else {
              dt[i].src = "";
              dt[i].crossOrigin = "";
            }
          }
          if (dt[i].text === "!class" || dt[i].text === toupper("!class")) {
            dt[i].text = toupper(Parent_Data[K].class_name);
          }
          if (dt[i].text === "!div" || dt[i].text === toupper("!div")) {
            dt[i].text = toupper(Parent_Data[K].divname);
          }
          if (dt[i].text === "!dob" || dt[i].text === toupper("!dob")) {
            dt[i].text = convertDate(Parent_Data[K].dob);
          }
          if (
            dt[i].text === "!bloodgroup" ||
            dt[i].text === toupper("!bloodgroup")
          ) {
            dt[i].text = toupper(Parent_Data[K].bloodgroup);
          }
          if (
            dt[i].text === "!guardianname" ||
            dt[i].text === toupper("!guardianname")
          ) {
            dt[i].text = toupper(Parent_Data[K].guardian_name);
          }
          if (
            dt[i].text === toupper("!guardianrelation") ||
            dt[i].text === "!guardianrelation"
          ) {
            dt[i].text = toupper(Parent_Data[K].guardian_relation);
          }
          if (
            dt[i].text == toupper("!fathername") ||
            dt[i].text == "!fathername"
          ) {
            dt[i].text = toupper(Parent_Data[K].father_name);
          }
          if (
            dt[i].text == toupper("!mothername") ||
            dt[i].text == "!mothername"
          ) {
            dt[i].text = toupper(Parent_Data[K].mother_name);
          }
          if (dt[i].text === "!address" || dt[i].text === toupper("!address")) {
            var oldfz = dt[i].fontSize;
            var fsize = oldfz;
            var tx = toupper(Parent_Data[K].address);
            var areatxt = tx.length * dt[i].fontSize * dt[i].height;
            var arear = dt[i].width * (dt[i].scaleY * dt[i].height);
            while (areatxt > arear) {
              fsize = parseFloat(dt[i].fontSize) - 0.1;
              dt[i].fontSize = fsize;
              var areatxt = tx.length * fsize * dt[i].height;
              var arear = dt[i].width * (dt[i].scaleY * dt[i].height);
            }
            if (fsize * 2 > oldfz) {
              dt[i].fontSize = oldfz;
            } else {
              dt[i].fontSize = fsize * 2;
            }
            dt[i].height = dt[i].scaleY * dt[i].height;
            dt[i].width = dt[i].width * dt[i].scaleX;
            dt[i].scaleY = 1;
            dt[i].scaleX = 1;
            dt[i].text = convertStr(tx);
          }
          if (dt[i].text === "!mobile" || dt[i].text === toupper("!mobile")) {
            dt[i].text = toupper(Parent_Data[K].mobile);
          }
          if (dt[i].text === "!mobile2" || dt[i].text === toupper("!mobile2")) {
            dt[i].text = toupper(Parent_Data[K].mobile2);
          }
          if (dt[i].id === "qrcode") {
            if (utype === "Parent") {
              var datastr = JSON.stringify([
                {
                  userid: Parent_Data[K].userid,
                  name: Parent_Data[K].name,
                },
              ]);
            } else {
              var datastr = JSON.stringify([
                {
                  userid: Parent_Data[K].userid,
                  name: Parent_Data[K].name,
                  class: Parent_Data[K].class_name,
                  div: Parent_Data[K].divname,
                },
              ]);
            }
            try {
              dt[i].src = await QRCode.toDataURL(datastr.toString());
            } catch (err) {
              console.error(err);
            }
          }
          if (dt[i].id === "barcode") {
            var txt = dt[i].textfield.toString();
            if (utype === "Parent") {
              if (txt.includes("userid")) {
                txt = txt.replace("userid", Parent_Data[K].userid);
              }
              if (txt.includes("Mobile Number")) {
                txt = txt.replace("Mobile Number", Parent_Data[K].mobile);
              }
              if (txt.includes("Timestamp")) {
                txt = txt.replace("Timestamp", Date.now().toString());
              }
              var canvasbar = document.createElement("canvas");
              JsBarcode(canvasbar, txt, { format: "CODE128" });
              try {
                dt[i].src = canvasbar.toDataURL("image/png");
              } catch (err) {
                console.error(err);
              }
            }
            if (txt.includes("Userid")) {
              txt = txt.replace("Userid", Parent_Data[K].userid);
            }
            if (txt.includes("Mobile Number")) {
              txt = txt.replace("Mobile Number", Parent_Data[K].mobile);
            }
            if (txt.includes("Timestamp")) {
              txt = txt.replace("Timestamp", Date.now().toString());
            }
            var canvasbar = document.createElement("canvas");
            JsBarcode(canvasbar, txt, { format: "CODE128" });
            try {
              dt[i].src = canvasbar.toDataURL("image/png");
            } catch (err) {
              console.error(err);
            }
          }
        }
        var hasavatar = dt.filter((e) => e.id === "avatar");

        if (hasavatar.length > 0) {
          if (hasavatar[0].src === undefined || hasavatar[0].src === "") {
            setTimeout(() => {
              RenderImg(JSON.stringify(dt));
            }, 1000);
          }
        }
        if (hasavatar.length === 0) {
          setTimeout(() => {
            RenderImg(JSON.stringify(dt));
          }, 1000);
        }
      }
    }
  };

  useEffect(() => {
    if (Static_Data != null || Parent_Data != null) {
      if (Arrayd != null) {
        if ((sdatalen || pdatalen) > 0) {
          Load();
        }
      }
    }
  }, [sdatalen, Arrayd, Static_Data, Parent_Data, pdatalen]);
  const RenderImg = (json) => {
    return new Promise((resolve, reject) => {
      refInput.current.handler.clear();
      console.log();
      refInput.current.handler.importJSON(json, () => {
        refInput.current.canvas.renderAll.bind(
          refInput.current.canvas.renderAll
        );
        var interval = setTimeout(function() {
          clearInterval(interval);
          refInput.current.canvas.renderAll();
          setB64(refInput.current.canvas.toDataURL());
          // refInput.current.handler.saveCanvasImage({ name: 'ID'+Date().valueOf(), format: 'base64', quality: 1 })
          //  refInput.current.canvas.on('mouse:over', mouseDownHandler);
        }, 1000);
      });
      resolve();
    });
  };
  const OpenImg = async () => {
    if (B64 != null) {
      const base64ImageData = B64;
      const contentType = "image/png";

      const byteCharacters = atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      );
      const byteArrays = [];
      // if (
      //   Static_Data.data.student[0] === null ||
      //   Static_Data.data.student[0] === undefined || Parent_Data.data[0] === null ||
      //   Parent_Data.data[0] === undefined
      // ) {
      //   window.alert("Something went wrong|");
      //   return;
      // }
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      const blobUrl = URL.createObjectURL(blob);
      //  window.open(blobUrl, '_self');
      const fm = new FormData();

      if (utype === "Student" || utype === "staff") {
        if (utype === "staff") {
          fm.append("staffid", Static_Data.data.student[0].staffid);
          let FileO = dataURLtoFile(
            B64,
            Static_Data.data.student[0].staffid + ".png"
          );
          fm.append("file", FileO);
        } else {
          fm.append("userid", Static_Data.data.student[0].userid);
          let FileO = dataURLtoFile(
            B64,
            Static_Data.data.student[0].userid + ".png"
          );
          fm.append("file", FileO);
          fm.append("calssname", Static_Data.data.student[0].class_name);
          fm.append("divname", Static_Data.data.student[0].divname);
        }
        fm.append("sid", sid);
        fm.append("fun_name", "IDCard_Insert");
        fm.append("name", Static_Data.data.student[0].name);
        fm.append("designid", did);

        await axios
          .post(
            "https://topschool.prisms.in/rest/index.php/user_list.json?",
            fm
          )
          .then((res) => {
            setLoaderAll(false);
            // setAv(res.data)
            window.open(blobUrl, "_self");
            saveAs(blobUrl, `${Static_Data.data.student[0].divname ? Static_Data.data.student[0].divname :'image'}.png`);
          });
      }

      if (utype === "Parent") {
        let bucketName = `topschool-attachments/${schoolName}prisms/idcardcanvas/parent/${
          Parent_Data[0].userid
        }}`;
        let formData = new FormData();
        formData.append("sid", DecryptED(sid));
        formData.append("userid", Parent_Data[0].userid);
        formData.append("design_id", Arrayd.design_id);
        formData.append("bucketName", bucketName);
        let FileO = dataURLtoFile(B64, Parent_Data[0].userid + ".png");

        formData.append("image", FileO);

        await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/idcard/insertDesign",
          data: formData,
          headers: {
            Authorization: "#$laksdfnlknoea#$@$%^&%$",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        }).then((res) => {
          setLoaderAll(false);
          // setAv(res.data)
          window.open(blobUrl, "_self");
          saveAs(blobUrl, "image.png");
          console.log("img inserted");
        });
      }
    }
  };
  useEffect(() => {
    if (B64 != null) {
      OpenImg();
    }
  }, [B64]);


  return (sdatalen || pdatalen) > 0 ? (
    <div className={classes.root}>
      <CssBaseline />

      {loaderAll ? (
        <CircularProgress
          color="primary"
          size={100}
          style={{ position: "fixed", left: "50vw", top: "25vh" }}
        />
      ) : null}
      <main className={classes.content}>
        {Canvastype === 1 ? <CanvasV ref={refInput} /> : null}
        {Canvastype === 0 ? <Canvasfd ref={refInput} /> : null}
      </main>
    </div>
  ) : (
    <div>No data found</div>
  );
}

export default Generate;
