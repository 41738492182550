import React,{Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputBase from '@material-ui/core/InputBase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationsNoneTwoToneIcon from '@material-ui/icons/NotificationsNoneTwoTone';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import Notifications from './Notifications'
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Opportunity from './Opportunity'
import Events from './Events'
import SearchIcon from '@material-ui/icons/Search';
import CreateIcon from '@material-ui/icons/Create';
import InfoIcon from '@material-ui/icons/Info';
import CreateOpp from './CreateOpp';
import Achive from './Achive';
import AssistantIcon from '@material-ui/icons/Assistant';
import Donation from './Donations';
import { withStyles,withTheme,fade,makeStyles  } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import history from './history';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AboutUsMain from './AboutUsMain';
import Contactinfo from './Contactinfo';
import CampaignD from './CampaignD';
import Feedback from './Feedback';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ErrorHandle from './ErrorHandle';
import Gallary from './Gallary';
import Helpdesk from './Helpdesk';
import OptionalForm from './OptionalForm';
import AddIcon from '@material-ui/icons/Add';
import SubmitAchivement from './SubmitAchivement';
import Submitvideo from './submitvideo';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import PhotoShare from './PhotoShare';
import YouTubeIcon from '@material-ui/icons/YouTube';
import VideoGallary from './VideoGallary';
import { FormatListBulletedSharp, TrafficOutlined } from '@material-ui/icons';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const drawerWidth = 240;

const styles = theme => ({
    root: {
    display: 'flex',
   // minWidth:'98vw',
    //maxWidth:'98vw',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,      
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    
  },
  achiv: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display:'flex',
      position:'fixed',
      right:100
    },
    
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  
  
  },
  '@global': {
    '*:focus': {
      outline: ' none !important'
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),   
   // minWidth:'98vw',maxWidth:'98vw'   
  },
  button:{
    float:'right',
    width:'100%',
    paddingLeft:70,
    paddingTop:25,
    paddingBottom:15,
    background:'#a52a2a',
    color:'white'
    
  },
  search:{
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
  },
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      alignItems:'center',
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    
});


class Home extends Component{
  
  state={
    sneaks:false,
    oppinfo:'',
    sneaksA:false,
    isLoading:true,
    localdt:JSON.parse(localStorage.getItem('data')),
    uid:null,
    oppotype:null,
    achivabout:'',
  sd:{hd:"dash",data:this.props.location.data!=undefined?this.props.location.data.data:JSON.parse(localStorage.getItem('data')).data,sid:this.props.match.params.sid},
  sa:{hd:"About",data:this.props.location.data!=undefined?this.props.location.data.data:JSON.parse(localStorage.getItem('data')).data,sid:this.props.match.params.sid},
TitleTop:this.props.location.data!=undefined?this.props.location.data.hd:JSON.parse(localStorage.getItem('data')).hd,
window:'',
mobileOpen:false,
submitA:false,
submitv:false,
e:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Events'?true:this.props.location.data!=undefined ? this.props.location.data.hd=="Events"? true:false:false,
n:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Latest Notifications'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Latest Notifications"? true:false:false,
o:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Latest Opportunity'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Latest Opportunity"? true:false:false,
cr:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Submit Opportunity'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Submit Opportunity"? true:false:false,
ac:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Achievements'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Achievements"? true:false:false,
do:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Make Donation'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Make Donation"? true:false:false,
camp:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Upcoming Projects'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Upcoming Projects"? true:false:false,
gallary:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Gallery'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Gallery"? true:false:false,
help:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Helpdesk'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Helpdesk"? true:false:false,
feedbk:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Feedback'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Feedback"? true:false:false,
youtube:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Videos'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Videos"? true:false:false,
form:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Update your Profile'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Update your Profile"? true:false:false,
abt:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='About'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="About"? true:false:false,
abtmain:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='About Us'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="About Us"? true:false:false,
ctinfo:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Contact Us'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Contact Us"? true:false:false,
ptosh:this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Share Your School Memory'?true:this.props.location.data!=undefined ?this.props.location.data.hd=="Share Your School Memory"? true:false:false,
  }
  //Dash=()=>{this.setState({D:true})}
  Dash=()=>{this.setState({D:true,e:false, n:false,o:false,cr:false,ac:false,do:false,abt:false,camp:false,gallary:false,submitA:false,submitv:false, help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false})}
  Event=()=>{this.setState({e:true, TitleTop:"Events",n:false,o:false,cr:false,ac:false,do:false,abt:false,camp:false,gallary:false,submitA:false,submitv:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false})}
  About=()=>{this.setState({abt:true,e:false, n:false,o:false,cr:false,ac:false,do:false,camp:false,submitA:false,submitv:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false})}
  Notif=()=>{this.setState({e:false, TitleTop:"Latest Notifications",n:true,o:false,cr:false,ac:false,do:false,abt:false,camp:false,gallary:false,submitA:false,submitv:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false})}
  Opp=()=>  {this.setState({o:true,TitleTop:"Latest Opportunity",e:false, n:false,cr:false,ac:false,do:false,abt:false,camp:false,gallary:false,submitA:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false,submitv:false})}
  CrOp=()=>{this.setState({cr:true,TitleTop:"Submit Opportunity",o:false,e:false, n:false,ac:false,do:false,abt:false,camp:false,gallary:false,submitA:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false,submitv:false})}
  Achivee=()=>{this.setState({ac:true,TitleTop:"Achievements",cr:false,o:false,e:false, n:false,do:false,abt:false,camp:false,gallary:false,submitA:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false,submitv:false,})}
  Donations=()=>{this.setState({ac:false,TitleTop:"Make Donation",cr:false,o:false,e:false, n:false,do:true,abt:false,camp:false,gallary:false,submitA:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false,submitv:false,})}
  Gallary=()=>{this.setState({ac:false,TitleTop:"Gallery",cr:false,o:false,e:false, n:false,do:false,abt:false,camp:false,gallary:true,submitA:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false,submitv:false})}
  Campaign=()=>{this.setState({camp:true,ac:false,TitleTop:"Upcoming Projects",cr:false,o:false,e:false, n:false,do:false,abt:false,gallary:false,submitA:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false,submitv:false})}
  Help=()=>{this.setState({ac:false,TitleTop:"Helpdesk",cr:false,o:false,e:false, n:false,do:false,abt:false,camp:false,gallary:false,submitA:false,help:true,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false,submitv:false})}
  Youtube=()=>{this.setState({ac:false,TitleTop:"Videos",cr:false,o:false,e:false, n:false,do:false,abt:false,camp:false,gallary:false,submitA:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:true,submitv:false})}
  Feedbk=()=>{this.setState({ac:false,TitleTop:"Feedback",cr:false,o:false,e:false, n:false,do:false,abt:false,camp:false,gallary:false,submitA:false,help:false,form:false,feedbk:true,abtmain:false,ctinfo:false,ptosh:false,youtube:false,submitv:false})}
  Aboutmain=()=>{this.setState({ac:false,TitleTop:"About Us",cr:false,o:false,e:false, n:false,do:false,abt:false,camp:false,gallary:false,submitA:false,help:false,form:false,feedbk:false,abtmain:true,ctinfo:false,ptosh:false,youtube:false,submitv:false})}
  Contactinfo=()=>{this.setState({ac:false,TitleTop:"Contact Us",cr:false,o:false,e:false, n:false,do:false,abt:false,camp:false,gallary:false,submitA:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:true,ptosh:false,youtube:false,submitv:false})}
  Photosh=()=>{this.setState({ac:false,TitleTop:"Share Your School Memory",cr:false,o:false,e:false, n:false,do:false,abt:false,camp:false,gallary:false,submitA:false,help:false,form:false,feedbk:false,abtmain:false,ctinfo:false,ptosh:true,youtube:false,submitv:false})}
  Form=()=>{this.setState({ac:false,TitleTop:"Update your Profile",cr:false,o:false,e:false, n:false,do:false,abt:false,camp:false,gallary:false,submitA:false,help:false,form:true,feedbk:false,abtmain:false,ctinfo:false,ptosh:false,youtube:false,submitv:false})}
     handleDrawerToggle = () => {
      // window.alert(this.state.mobileOpen)
      this.setState({
        mobileOpen:!this.state.mobileOpen
      })
    };
  Logout=()=>{
    localStorage.setItem('data',null)
    history.push({pathname:"/alumni/about/"+this.props.match.params.sid})
    
  }
  Opport=(res,aboutinfo)=>{
       // window.alert(this.props.location.data.data[0].userid)

       this.setState({oppotype:res,uid:this.props.location.data !=undefined?this.props.location.data.data[0].userid:JSON.parse(localStorage.getItem('data')).data[0].userid,oppinfo:aboutinfo})
  }
  AchivAbout=(about)=>{
    this.setState({achivabout:about})
  }
 
render(){
  const { classes,theme } = this.props;
      //console.log(JSON.parse(localStorage.getItem('data')).hd=='Latest Notifications')
      //console.log(this.props.location.data==undefined && JSON.parse(localStorage.getItem('data')).hd=='Latest Notifications'?true:this.props.location.data.hd=="Latest Notifications"? true:false)

  const container = this.props.window !== undefined ? () => window().document.body : undefined;
  const themess = createMuiTheme({
    palette: {
      primary: {
        main: '#a52a2a'
      }
    }
  });
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({sneaks:false,sneaksA:false})
  };
  const handleSnack = () => {
    this.setState({sneaks:true})
  };
  const handleSnackA = () => {
    this.setState({sneaksA:true})
  };
  
  const drawer = (
    
    <div >
      <Typography  color="white" className={classes.button}>
  Prisms
</Typography>
      <div className={classes.toolbar}  />
      
      <Divider />
      <List>
      <ListItem button onClick={this.Dash} >
      <ListItemIcon><DashboardIcon color="primary"  /></ListItemIcon>
      <ListItemText primary="Dashboard"    />
      </ListItem>
      <ListItem button onClick={()=>{this.Event();this.handleDrawerToggle()}} >
      <ListItemIcon><EventAvailableTwoToneIcon color="primary"  /></ListItemIcon>
      <ListItemText primary="Events"    />
      </ListItem>
      <ListItem button  onClick={()=>{this.Notif();this.handleDrawerToggle()}} >
      <ListItemIcon><NotificationsNoneTwoToneIcon color="primary" /></ListItemIcon>
      <ListItemText primary="Notifications"  />
      </ListItem>
      <ListItem button  onClick={()=>{this.Form();this.handleDrawerToggle()}} >
      <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
      <ListItemText primary="Update your Profile"  />
      </ListItem>
      <ListItem button  onClick={()=>{this.Opp();this.handleDrawerToggle()}} >
      <ListItemIcon><WorkOutlineIcon color="primary" /></ListItemIcon>
      <ListItemText primary="Opportunities"  />
      </ListItem>
      {/* <ListItem button  onClick={this.CrOp} >
      <ListItemIcon><CreateIcon color="primary" /></ListItemIcon>
      <ListItemText primary=" Submit Opportunity"  />
      </ListItem> */}
      <ListItem button  onClick={()=>{this.Achivee();this.handleDrawerToggle()}} >
      <ListItemIcon><AssistantIcon color="primary" /></ListItemIcon>
      <ListItemText primary=" Achievements"  />
      </ListItem>
      <ListItem button  onClick={()=>{this.Donations();this.handleDrawerToggle()}} >
      <ListItemIcon> <img  src={'/icons/rupee1.png'} style={{fill:'currentcolor',width:'1em',height:'1em',display:'inline-block',fontSize:'1.5rem',transition:'fill 200ms',flexShrink:0,userSelect:'none'}} alt="fireSpot"/></ListItemIcon>
      <ListItemText primary="Make Donation"  />
      </ListItem>
      <ListItem button  onClick={()=>{this.Campaign();this.handleDrawerToggle()}} >
      <ListItemIcon><AccountBalanceIcon color="primary" /></ListItemIcon>
      <ListItemText primary="Upcoming Projects"  />
      </ListItem>
      <ListItem button  onClick={()=>{this.Gallary();this.handleDrawerToggle()}} >
      <ListItemIcon><PermMediaIcon color="primary" /></ListItemIcon>
      <ListItemText primary="Gallery"  />
      </ListItem>
      <ListItem button  onClick={()=>{this.Youtube();this.handleDrawerToggle()}} >
      <ListItemIcon><YouTubeIcon color="primary" /></ListItemIcon>
      <ListItemText primary="Videos"  />
      </ListItem>
      <ListItem button  onClick={()=>{this.Photosh();this.handleDrawerToggle()}} >
      <ListItemIcon><PhotoAlbumIcon color="primary" /></ListItemIcon>
      <ListItemText primary="Share Your School Memory"  />
      </ListItem>
      <ListItem button  onClick={()=>{this.Help();this.handleDrawerToggle()}}>
      <ListItemIcon><ContactSupportIcon color="primary" /></ListItemIcon>
      <ListItemText primary="Helpdesk"  />
      </ListItem>
      <ListItem button  onClick={()=>{this.Feedbk();this.handleDrawerToggle()}} >
      <ListItemIcon><FeedbackIcon color="primary" /></ListItemIcon>
      <ListItemText primary="Suggestion /Feedback"  />
      </ListItem>
      <ListItem button  onClick={()=>{this.Aboutmain();this.handleDrawerToggle()}} >
      <ListItemIcon><InfoIcon color="primary" /></ListItemIcon>
      <ListItemText primary=" About Us"  />
      </ListItem>
      <ListItem button  onClick={()=>{this.Contactinfo();this.handleDrawerToggle()}} >
      <ListItemIcon><WhatsAppIcon color="primary" /></ListItemIcon>
      <ListItemText primary="Contact Us"  />
      </ListItem>
      <ListItem button  onClick={this.Logout} >
      <ListItemIcon><PowerSettingsNewIcon color="primary" /></ListItemIcon>
      <ListItemText primary="Logout"  />
      </ListItem>
      </List>
   
    </div>
  );

  return (
    <MuiThemeProvider theme={themess}>
    <div className={classes.root}>
      {/* {window.alert(JSON.stringify(this.state.sd))} */}
        {/* {console.log("cob-"+this.state.e+this.props.location.data)} */}
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} >
      {/* <AppBar position="fixed" className={classes.appBar}> */}
        <Toolbar>
         {this.state.submitA==false && this.state.cr==false && this.state.submitv==false? <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
            
          >
            <MenuIcon />
          </IconButton>:this.state.submitA==true?<IconButton color="inherit" edge="start" className={classes.menuButton} onClick={()=>{this.setState({submitA:false,ac:true})}}>
            <KeyboardBackspaceIcon fontSize='large'/>
            </IconButton>:this.state.cr==true?<IconButton color="inherit" edge="start" className={classes.menuButton} onClick={()=>{this.setState({cr:false,o:true})}}>
            <KeyboardBackspaceIcon fontSize='large'/>
            </IconButton>:this.state.submitv==true?<IconButton color="inherit" edge="start" className={classes.menuButton} onClick={()=>{this.setState({youtube:true,submitv:false})}}>
            <KeyboardBackspaceIcon fontSize='large'/>
            </IconButton>:null}
         {this.state.abtmain==false? <Typography style={{marginRight:25}} variant="h6" Wrap>
            {this.state.TitleTop}
          </Typography>:<Typography style={{ position: 'absolute',top: '50%', left:'50%', transform: 'translateX(-50%) translateY(-50%)'}} variant="h6" Wrap>
            {this.state.TitleTop}
          </Typography>}
          
          {this.state.ac===true?<><Typography className={classes.achiv}>Submit Your Achievements</Typography>
          <IconButton  color="inherit" edge='end' style={{position:'fixed',right:20}} onClick={()=>{this.setState({submitA:true,ac:false,cr:false,o:false,submitv:false})}}>
            <AddIcon fontSize='large' /></IconButton>
            <IconButton  color="inherit" edge='end' style={{position:'fixed',right:60}} onClick={()=>{handleSnackA()}}>
        <InfoIcon fontSize='large'/></IconButton>
            </>:null}
            
          {this.state.o===true?<><Typography className={classes.achiv}>Submit Opportunity</Typography>
          <IconButton  color="inherit" edge='end' style={{position:'fixed',right:20}} onClick={()=>{this.setState({cr:true,o:false,ac:false,submitA:false,submitv:false})}}>
            <AddIcon fontSize='large' /></IconButton>
            <IconButton  color="inherit" edge='end' style={{position:'fixed',right:60}} onClick={()=>{handleSnack()}}>
        <InfoIcon fontSize='large'/></IconButton>
            </>:null}
            {this.state.youtube===true?<><Typography className={classes.achiv}>Submit Video</Typography>
          <IconButton  color="inherit" edge='end' style={{position:'fixed',right:20}} onClick={()=>{this.setState({submitA:false,ac:false,cr:false,o:false,youtube:false,submitv:true})}}>
            <AddIcon fontSize='large' /></IconButton>
            </>:null}
        </Toolbar>
      </AppBar>
      <Snackbar anchorOrigin={{  vertical: 'top', horizontal: 'center' }} open={this.state.sneaks || this.state.sneaksA} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
        {this.state.sneaks==true?"If you have any Opportunity avaliable for our students, please click on '+' icon to submit new opportunity."
      :this.state.sneaksA==true?"Your Achivements are importent to us. Let us know of your latest Achivement , please click on '+' icon to submit your latest Achivement":null  
      }
        </Alert>
      </Snackbar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="js">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
          {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {this.state.n===false && this.state.e===false &&  this.state.o===false &&  this.state.cr===false &&  this.state.ac===false &&
          this.state.do===false && this.state.camp==false && this.state.gallary==false && this.state.submitA==false && this.state.help==false && this.state.form==false && this.state.feedbk==false && this.state.abtmain==false && this.state.ctinfo==false && this.state.ptosh==false && this.state.youtube==false && this.state.submitv==false? null: null}
       {this.state.n===true? <Notifications sid={this.props.match.params.sid}/>:null}
        {this.state.e===true?<Events sid={this.props.match.params.sid}/>:null}
        {this.state.o===true?<Opportunity sid={this.props.match.params.sid} Op={this.Opport}/>:null}
        {this.state.cr===true?<CreateOpp info={this.state.oppinfo} sid={this.props.match.params.sid} opt={this.state.oppotype} uid={this.state.uid}/>:null}
        {this.state.ac===true?<Achive Acinfo={this.AchivAbout} ud={this.props.location.data!=undefined?this.props.location.data:JSON.parse(localStorage.getItem('data'))} sid={this.props.match.params.sid}/>:null}
        {this.state.do===true?<Donation sid={this.props.match.params.sid}/>:null}
        {this.state.camp===true?<CampaignD ud={this.props.location.data!=undefined?this.props.location.data:JSON.parse(localStorage.getItem('data'))} sid={this.props.match.params.sid}/>:null}
        {this.state.gallary===true?<Gallary sid={this.props.match.params.sid} />:null}
        {this.state.ptosh===true?<PhotoShare ud={this.props.location.data!=undefined?this.props.location.data:JSON.parse(localStorage.getItem('data'))} sid={this.props.match.params.sid} />:null}
        {this.state.help===true?<Helpdesk ud={this.props.location.data!=undefined?this.props.location.data:JSON.parse(localStorage.getItem('data'))} sid={this.props.match.params.sid}/>:null}
        {this.state.youtube===true?<VideoGallary ud={this.props.location.data!=undefined?this.props.location.data:JSON.parse(localStorage.getItem('data'))} sid={this.props.match.params.sid}/>:null}
        {this.state.feedbk===true?<Feedback ud={this.props.location.data!=undefined?this.props.location.data:JSON.parse(localStorage.getItem('data'))} sid={this.props.match.params.sid}/>:null}
        {this.state.form===true?<OptionalForm sid={this.props.match.params.sid}  />:null}
        {this.state.abtmain===true?<AboutUsMain sid={this.props.match.params.sid} />:null}
        {this.state.ctinfo===true?<Contactinfo sid={this.props.match.params.sid} />:null}
        {this.state.submitA==true?<SubmitAchivement about={this.state.achivabout} ud={this.props.location.data!=undefined?this.props.location.data:JSON.parse(localStorage.getItem('data'))} sid={this.props.match.params.sid}/>:null}
        {this.state.submitv==true?<Submitvideo about={this.state.achivabout} ud={this.props.location.data!=undefined?this.props.location.data:JSON.parse(localStorage.getItem('data'))} sid={this.props.match.params.sid}/>:null}
        {this.state.abt===true?history.push({pathname:"/alumni/about/"+this.props.match.params.sid,data:this.state.sa}):null}
        {this.state.D===true?history.push({pathname:"/alumni/Dashboard/"+this.props.match.params.sid,data:this.state.sd}):null}
      </main>
    </div>
    </MuiThemeProvider>
  );
}
}
Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(withStyles(styles)(Home));