import React, { useState } from 'react'
import Style from './Pos.module.css'
import Axios from 'axios'

const PosSale = () => {

  const [Apipostdata, setApipostdata] = useState({
    to: "",
    amount: "",
    datetime: "",
    purpose: ""
  })

  const apiHandler = () => {
    Axios.post(" https://api.paykit.in/create-transaction", Apipostdata
      , {
        headers: { Authorization: "" }
      }).then(res => console.log(res))
      .catch(err => console.log(err))
  }

  return (
    <div className={Style.wrapper}>
      <img src="../../icons/wallet/sales.png" alt="" />
      <form action="">
        <p>Enter Amount</p>
        <input type="number" />
        <p>Enter Details</p>
        <input type="text" />
      </form>
      <img style={{ height: "200xpx", width: "400px", marginTop: "1rem" }} src="../../icons/wallet/PayCard.png" alt="" />
    </div>
  )
}

export default PosSale