import styled from "styled-components";
import React, { useState } from "react";
import StaffImg from "../Components/StaffImg"
import StudentImg from  "../Components/Main"
import Navbar from "../Components/Navbar";
import { useParams } from "react-router-dom";

const Dashboard = () => {
  const [activetab, setActiveTab] = useState(0);
  const { sid } = useParams();

  return (
    <>
      <Container>
        <Navbar/>
        <Wrapper>
          <div className="tabs">
            <Navlist>
              <Tablink
                key={0}
                activetab={activetab === 0}
                onClick={() => setActiveTab(0)}
              >
                Students
              </Tablink>
              <Tablink
                key={1}
                activetab={activetab === 1}
                onClick={() => setActiveTab(1)}
              >
                Staff
              </Tablink>
            </Navlist>
          </div>
        </Wrapper>
        {/* <StudentImg/> */}
        <InnerContainer>
          { activetab ? <StaffImg sid={sid}/> : <StudentImg sid={sid}/> }
        </InnerContainer>
      </Container>
    </>
  );
};

export default Dashboard;

const InnerContainer = styled.div`
width: 100%;
height: 0;
margin-top: 40px;
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 70px;
`;

const Navlist = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const Tablink = styled.button`
  cursor: pointer;
  font-size: 1.5rem;
  font-family: monospace;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  font-weight: 700;
  color: #111;
  border: none;
  width: 300px;
  height: 55px;
  &:focus {
  outline: none;
  outline: none;
}

  ${({ activetab }) =>
    activetab &&
    `
    border-bottom: 3px solid #4d5fe4;
  `}
`;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 60%;

  .tabs{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
`;
