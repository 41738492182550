import React from 'react';
import {IconButton} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loaddesign from './loaddesign';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

export default function ModelTop(props) {
  const [open, setOpen] = React.useState(true);

const [isload,setload]=React.useState(false)
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
        
      <Dialog open={open}  aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Open Design</DialogTitle>
        <DialogContent>
          <DialogContentText>
             To open existing design click on Open Design
          </DialogContentText>
          {isload===false?<div style={{display:'flex'}}><div ><IconButton onClick={()=>setload(true)}>
              <OpenInBrowserIcon  style={{width:60,height:60}}  color="primary"/>
              
          </IconButton>
          <p>Open Design</p>
          </div>
          {/* <div style={{marginLeft:30,textAlign:'center'}}>
          <IconButton onClick={()=>handleClose()}>
             <AddIcon style={{width:60,height:60}}  color="primary"/>
          </IconButton>
          <p>New Design</p>
          </div> */}
          </div>:null}
          {isload?<Loaddesign mod={open} modclose={handleClose} close={props.close} ld={props.ld}/>:null}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
