import React,{useState,useEffect} from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import { deepOrange, green } from '@material-ui/core/colors';
import {Button,Avatar, Typography,Card,CardHeader,CardContent ,Grid ,Box  } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import axios from 'axios';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft:10,
    minWidth: 345,   
    marginBottom:10,
    marginTop:20,
  },
  desc:{
    borderRadius:20,
    marginTop:40,
    transform: 'translateX(-50%)',
    marginLeft:'50%'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    
  },
  expand: {
    transform: 'rotate(0deg)',
  
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    
  },
  avatar: {
    marginLeft:10,
     backgroundColor: '#acc3da',
    verticalAlign: 'bottom',   
  },
    root1:{
        display: 'flex',
        background:green[400],
    },
    rounded: {  
      color: '#fff',
      backgroundColor: green[600],
      marginLeft:'40%',
      marginTop:'3%',
      width: theme.spacing(12),
        height: theme.spacing(12),
    },
  }));
  const handleClick = () => {
    window.alert("receipt Not Avaliable");
  };
  
export default function Donation(props) {
    const classes = useStyles();
    const {sid} = useParams();

    const [result, setRes]= useState({});
    useEffect(()  =>{
      async function fetchD(){
       const res= await axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"AlumniDonation",['sid']:sid})
       .then(res=> {
            // console.log("Donations-->"+ JSON.stringify(res));
           setRes(res)}).catch(err =>console.log("Error-"+err));
   }
     fetchD();
     
  },[])
  const handleClick1=()=>{
    window.open("https://easebuzz.in/pay/ShikshanXL0RA","_blank");
  }
    return(
      result.data !=null? 
    <div style={ {display:'inner-block'}}>
      {/* <CssBaseline />
          <AppBar>
            <Toolbar>
              <Typography variant="h6">Alumni</Typography>
            </Toolbar>
          </AppBar>
        <Toolbar /> */}
        {result.data.about_don!=undefined?<Box fontFamily="Monospace" fontSize="h6.fontSize" fontStyle='italic' m={1}>{result.data.about_don[0].description}</Box>:null}
    
    <Button  variant="contained" color="primary"className={classes.desc} onClick={handleClick1} style={{justifyContent:'center',textAlign:'center'}}> Make Donation</Button> 

    </div>:<><CircularProgress/></>
    );
}
