import React, { Component } from "react";
import { MDBCard, MDBCardText } from "mdbreact";
import "../App.css";
import "react-datepicker/dist/react-datepicker.css";
class Eventdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDataFromParent: [],
    };
  }
  componentDidMount() {
    if (
      this.props.getEventDataFromParent &&
      this.props.getEventDataFromParent != ""
    ) {
      this.setState({
        eventDataFromParent: this.props.getEventDataFromParent,
      });
    }
  }

  fmFam = {
    fontFamily: "Poppins",
  }
  render() {
    return (
      <div className="dateEvent display-6">
        {this.state.eventDataFromParent.length > 0 ? (
          this.state.eventDataFromParent.map((eachEventData, index) => (
            <MDBCardText className="margin-top-2" key={index}>
              <MDBCard className="card-body">
                <MDBCardText>
                  <strong style={this.fmFam}>Date : </strong>
                  {eachEventData.eventdate.split("-")[2] +
                    "-" +
                    eachEventData.eventdate.split("-")[1] +
                    "-" +
                    eachEventData.eventdate.split("-")[0]}
                </MDBCardText>
                <MDBCardText>
                  <strong style={this.fmFam}>Event Name : </strong>
                  {eachEventData.event}
                </MDBCardText>
              </MDBCard>
            </MDBCardText>
          ))
        ) : (
          <MDBCard className="card-body mt-1">
            <MDBCardText style={this.fmFam}>Events are not scheduled in this month.</MDBCardText>
          </MDBCard>
        )}
      </div>
    );
  }
}
export default Eventdetails;
