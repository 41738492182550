import React, { Component, Fragment } from "react";
import "../App.css";
import DatePicker from "react-datepicker";
import { MDBContainer, MDBBtn } from "mdbreact";
import "react-datepicker/dist/react-datepicker.css";
var moment = require("moment");

class Inputdetails extends Component {
  onPrevButtonClick() {
    this.props.onPrevClick_attr(
      new Date(
        moment(this.props.changeDates).add(
          this.props.next_previous_count - 1,
          "month"
        )
      )
    );
  }
  onNextButtonClick() {
    this.props.onNextClick_attr(
      new Date(
        moment(this.props.changeDates).add(
          this.props.next_previous_count + 1,
          "month"
        )
      )
    );
  }

  render() {
    return (
      <div className="margin-top-2 pt-2">
        <div className="row">
          <div className="col-2 col-lg-2 col-md-2 col-sm-2 d-flex justify-content-center date_header">
            <MDBBtn
              onClick={this.onPrevButtonClick.bind(this)}
              className="date_header btn-sm btn-block"
              color="success"
            >
              {"<"}
            </MDBBtn>
          </div>
          <div className="col-8 col-lg-8 col-md-8 col-sm-8 d-flex justify-content-center date_display_btn">
            <MDBBtn className="btn-sm btn-block" color="success">
              <b className="font-weight-bold">
                {moment(this.props.changeDates).format("MMMM YYYY")}
              </b>
            </MDBBtn>
          </div>
          <div className="col-2 col-lg-2 col-md-2 col-sm-2 d-flex justify-content-center date_header">
            <MDBBtn
              onClick={this.onNextButtonClick.bind(this)}
              className="date_header btn-sm btn-block"
              color="success"
            >
              {">"}
            </MDBBtn>
          </div>
        </div>

        <DatePicker
          className="yourdiv"
          inline
          openToDate={this.props.changeDates}
          highlightDates={this.props.highlightDates}
        />
      </div>
    );
  }
}

export default Inputdetails;
