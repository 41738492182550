import React, { forwardRef } from "react";
import { Canvas } from "react-design-editor";
import { memo } from "react";

function Canvsd(props, ref) {
  // console.log(props)
  // console.log(ref)
  React.useEffect(() => {
    document.getElementsByTagName("title")[0].innerHTML = "IDcard Design";
  }, []);
  return (
    <Canvas
      style={{
        width: 1600, //1600
        height: 1005, //1005
        top: -153,
        left: -173,
        border: "2px solid",
        backgroundColor: "white",
        transform:"scale(0.4)"
      }}
      workareaOption={{ width: 1600, height: 1005 }}
      editable={props.editable}
      zoomEnabled={false}
      ref={ref}
    />
  );
}

const Canvasfd = forwardRef(Canvsd);
export default memo(Canvasfd);


//        width: 637, //1600
// height: 400, //1005

//width: 900, height: 900 }