import React, { useState, useEffect} from "react";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import GetAppIcon from "@material-ui/icons/GetApp";
import FormData from "form-data";
import { useParams } from "react-router-dom";
import  { DecryptED } from "../../DecryptJS/Decrypt.js"
import styled from "styled-components";


export default function ReplyLeftTab(props) {

  const { sid, ticket_id, uid } = useParams();
  const dsid = DecryptED(sid);
  const d_id = DecryptED(ticket_id);
  const duid = DecryptED(uid);

  const multiDownload = require("multi-download");
  const TicketDetails = props.data;
  const [fileError, setFileError] = useState(false);
  const [fileLength, setFilesLength] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [acadYear, setAcadyear] = useState("");
  const [file_1, setFile_1] = useState("");
  const [file_2, setFile_2] = useState("");
  const [file_3, setFile_3] = useState("");
  const [Files, setFilesdata] = useState([]);
  const [render, setRender] = useState(false);
  const [f1, setF1] = useState("");
  const [f2, setF2] = useState("");
  const [f3, setF3] = useState("");

  function fileSize(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > 10485760) {
        setFileError(true);
        break;
      }
    }
  }

  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  function updatefiles(e) {
    let files = [];
    for (let i = 0; i < e.length; i++) {
      if (e[i].name.includes(",")) {
        files.push(renameFile(e[i], e[i].name.replaceAll(",", "_")));
      } else {
        files.push(e[i]);
      }
    }
    props.updateTicket({
      data: { file: files, nooffiles: files.length },
      history: `User Updated files`,
    });
  }

  useEffect(() => {
    const getSchoolName = async () => {
      const data = {
        fun_name: "getSchoolURL",
        sid:dsid,
      };
      await axios({
        method: "POST",
        url: "https://topschool.prisms.in/rest/index.php/user_list.json",
        data,
      }).then((response) => {
        setSchoolName(response.data);
      });
    };
    getSchoolName();

    const getAcadmic = async () => {
      const data = {
        sid:dsid,
      };
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/tscommon/getCurrentAcad",
        data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      }).then(async (response) => {
        setAcadyear(response.data[0].academics);
      });
    };
    getAcadmic();
  }, []);

  const uploadattachments = async (e, attach, colName) => {
    e.preventDefault();
    let bucketName = `topschool-attachments/${schoolName}prisms/${acadYear}/tickets/files`;
    let formData = new FormData();
    formData.append("sid", dsid);
    formData.append("id", d_id);
    if(props.userType === "user"){ formData.append("column2","staff_id") } 
    else if (props.userType === "User") {formData.append("column2","uid")}
    formData.append("uid", duid);
    formData.append("tablename", "k6kb9_tickets_created");
    formData.append("column", colName);
    formData.append("bucketName", bucketName);
    formData.append("file", attach);

    await axios({
      method: "post",
      url: "https://marcopolo.prisms.in/api/tickets/attachments",
      data: formData,
      headers: {
        Authorization: "#$laksdfnlknoea#$@$%^&%$",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `File uploaded successfully!`,
          showConfirmButton: false,
          timer: 1500,
        });
        // window.location.reload();
        setRender(!render);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
        });
      });
  };

  useEffect(() => {
    const getattachments = async () => {
      const data = {
        sid : dsid,
        id: d_id,
      };
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/tickets/files",
        data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      }).then(async (response) => {
        setFilesdata(response.data);
        setF1(response.data[0].file_1);
        setF2(response.data[0].file_2);
        setF3(response.data[0].file_3);
      });
    };
    getattachments();
  }, [render])

  const ValidateFileName = ( fileUrl ) => {
    let filename = "";
    if (fileUrl) {
      const url = new URL(fileUrl);
      const path = url.pathname;
    return  filename = decodeURIComponent(path.split('/').pop());
    }
  }
  return (
    <>
      <div className="totalTicketingModule TMreplyLeftTabStyles">
        {TicketDetails[0].parent_id ? (
          <div
            className="TMAdminDashboard__SchoolNameCard"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              margin: "0px",
              padding: "10px",
            }}
            onClick={() => {
              props.NavigatorwithID(TicketDetails[0].parent_id);
            }}
          >
            <span>Parent Ticket Id - {TicketDetails[0].parent_id}</span>
            <img
              src="https://img.icons8.com/color/100/00bfff/circled-right-2--v1.png"
              style={{ width: "30px" }}
              alt={""}
            />
          </div>
        ) : null}
        <h4> Committee/Department </h4>
        <select
          defaultValue={TicketDetails[0].committee}
          disabled={props.userType === "User" ? true : false}
          onChange={(e) => {
            props.updateTicket({
              data: { committee: e.target.value },
              history: `Committee/Department changed from ${
                TicketDetails[0].committee
              } to ${e.target.value}`,
            });
          }}
        >
          {props.Stafftype.map((i, k) => {
            return (
              <option key={k} value={i}>
                {i}
              </option>
            );
          })}
        </select>

        <h4> Staff Name </h4>
        <select
          defaultValue={JSON.stringify({
            staff: TicketDetails[0].staff,
            staff_id: TicketDetails[0].staff_id,
          })}
          disabled={props.userType === "User" ? true : false}
          onChange={(e) => {
            if (e.target.value === "") {
              props.updateTicket({
                data: { staff: "", staff_id: "" },
                history: `Staff Name changed from ${
                  TicketDetails[0].staff === ""
                    ? "Unassigned"
                    : TicketDetails[0].staff
                } to Unassigned`,
              });
            } else {
              const sdata = JSON.parse(e.target.value);
              props.updateTicket({
                data: { staff: sdata.staff, staff_id: sdata.staff_id },
                history: `Staff Name changed from ${
                  TicketDetails[0].staff === ""
                    ? "Unassigned"
                    : TicketDetails[0].staff
                } to ${sdata.staff}`,
              });
            }
          }}
        >
          <option value="">Unassigned</option>
          {props.StaffDetails[TicketDetails[0].committee].map((i, k) => {
            return (
              <option
                key={k}
                value={JSON.stringify({
                  staff: i.first_name,
                  staff_id: i.staff_id,
                })}
              >
                {i.first_name}
              </option>
            );
          })}
        </select>

        <h4> Status </h4>
        <select
          defaultValue={TicketDetails[0].status}
          disabled={props.userType === "User" ? true : false}
          onChange={(e) => {
            if (e.target.value === "Closed") {
              Swal.fire({
                title: "Please Wait!",
                html: "Checking weather all child tickets or closed or not...", // add html attribute if you want or remove
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
              axios
                .post(
                  "https://topschool.prisms.in/rest/index.php/user_list.json?",
                  {
                    fun_name: "GetTicketCreate",
                    sid: props.sid,
                    parent_id: props.parentId,
                  }
                )
                .then((res) => {
                  const isClosed = res.data.Tickets.every((i) => {
                    return i.status === "Closed";
                  });
                  if (isClosed) {
                    Swal.close();
                    props.updateTicket({
                      data: { status: e.target.value },
                      history: `Status changed from ${
                        TicketDetails[0].status
                      } to ${e.target.value}`,
                    });
                  } else {
                    Swal.close();
                    e.target.value = TicketDetails[0].status;
                    Swal.fire(
                      "",
                      "Please first close all child tickets",
                      "warning"
                    );
                  }
                });
            } else {
              props.updateTicket({
                data: { status: e.target.value },
                history: `Status changed from ${TicketDetails[0].status} to ${
                  e.target.value
                }`,
              });
            }
          }}
        >
          <option value="Open"> Open </option>
          <option value="Hold"> Hold </option>
          <option value="Closed"> Closed </option>
        </select>

        <h4> Due Date </h4>
        <input
          type="date"
          disabled={props.userType === "User" ? true : false}
          defaultValue={TicketDetails[0].duedate1.split(" ")[0]}
          onChange={(e) => {
            props.updateTicket({
              data: {
                duedate: `${e.target.value} ${moment(
                  TicketDetails[0].duedate
                ).format("HH:mm:ss")}`,
              },
              history: `Due Date changed from ${TicketDetails[0].duedate1} to ${
                e.target.value
              } ${moment(TicketDetails[0].duedate).format("HH:mm:ss")}`,
            });
          }}
        />

        <h4> Priority </h4>
        <select
          defaultValue={TicketDetails[0].priority}
          disabled={props.userType === "User" ? true : false}
          onChange={(e) => {
            props.updateTicket({
              data: { priority: e.target.value },
              history: `Priority changed from ${TicketDetails[0].priority} to ${
                e.target.value
              }`,
            });
          }}
        >
          <option value="High"> High </option>
          <option value="Medium"> Medium </option>
          <option value="Low"> Low </option>
        </select>

        {TicketDetails[0].file !== "" ? (
          <>
            <h4> Files </h4>
            <p style={{ opacity: 0.7, fontSize: "13px" }}>
              Note : There are attachments added by user click on download files
              to view
            </p>
            <a
              href=""
              onClick={() => {
                multiDownload(TicketDetails[0].file.split(","));
              }}
              style={{ display: "flex", alignItems: "center", width: "85%" }}
            >
              <img src="/icons/download.png" alt={""} style={{ width: "18px" }} />{" "}
              &nbsp;&nbsp;<span> Download files</span>
            </a>
          </>
        ) : null}

        {props.userType === "User" || props.userType === "user" ? (
          <>
            <h4>{TicketDetails[0].file === "" ? "Upload" : "Update"} Files </h4>
            <div className="TMReplyLeftUpdateFile">
              <img src="/icons/updatefile.png" style={{ width: "18px" }} alt={""}/>
              <input
                id="fileupdateleft"
                type="file"
                multiple
                style={{ boxShadow: "none" }}
                onChange={(e) => {
                  setFilesLength(e.target.files.length);
                  setFileError(false);
                  fileSize(e);
                }}
              />
            </div>
            {fileError ? (
              <span style={{ color: "red", fontWeight: 500, fontSize: "13px" }}>
                {" "}
                Please Choose Files Less Than 10 MB{" "}
              </span>
            ) : fileLength > 0 ? (
              <div
                className="TMSubmitButton"
                style={{ width: "50px", marginTop: "10px" }}
                onClick={() => {
                  updatefiles(document.getElementById("fileupdateleft").files);
                }}
              >
                {TicketDetails[0].file === "" ? "Upload" : "Update"}
              </div>
            ) : null}
          </>
        ) : null}


        {/* Upload File here */}
        <DownloadFiles>
          {Files.length > 0
            ? Files.map((j, k) => {
                const { file_1, file_2, file_3 } = j;
                return (
                  <div key={k}>
                    <div style={{margin: '8px 0'}}>
                      {file_1 || file_2 || file_3 ? (
                        <p
                          style={{
                            opacity: 0.7,
                            fontSize: "12px",
                            textAlign: "start",
                          }}
                        >
                          Download Files.
                        </p>
                      ) : null}
                    </div>

                    {file_1 != 0 ? (
                      <div className="icon__file">
                        <a
                          className="file__path"
                          href={file_1 != "" ? file_1 : null}
                          download="attachments"
                        >
                          <GetAppIcon className="appIcon" />
                        </a>
                        <div className="file_name">
                          <span className="file__name">
                            {/* {file_1.slice(62).replace(/%/g, " ")} */}
                            {ValidateFileName(file_1)}
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {file_2 != 0 ? (
                      <div className="icon__file">
                        <a
                          className="file__path"
                          href={file_2 != "" ? file_2 : null}
                          download="attachments"
                        >
                          <GetAppIcon className="appIcon" />
                        </a>
                        <div className="file_name">
                          <span className="file__name">
                            {/* {file_2.slice(62).replace(/%/g, " ")} */}
                            { ValidateFileName(file_2)}
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {file_3 != 0 ? (
                      <div className="icon__file">
                        <a
                          className="file__path"
                          href={file_3 != "" ? file_3 : null}
                          download="attachments"
                        >
                          <GetAppIcon className="appIcon" />
                        </a>
                        <div className="file_name">
                          <span className="file__name">
                            {/* {file_3.slice(62).replace(/%/g, " ")} */}
                           {ValidateFileName(file_3)}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null}
        </DownloadFiles>

        { props.userType === "User" || props.userType === "user" ? (<><Container>
          <div>
            {/* File size less than 10 MB */}
            {fileError ? (
              <span
                style={{
                  color: "red",
                  fontWeight: 500,
                  fontSize: "12px",
                  textAlign: "start",
                }}
              >
                {" "}
                Please Choose Files Less Than 10 MB{" "}
              </span>
            ) : null}
          </div>


          {f1 ? null : (
            <div>
              <span style={{fontSize:'0.8rem',color:'#777'}}>File 2</span>

              <div>
                <input
                  type="file"
                  onChange={(e) => {
                    setFile_1(e.target.files[0]);
                    fileSize(e);
                  }}
                />
                {file_1 != "" ? (
                  <button
                    // onClick={(e) => updateFiles(e,file_1,"file_1")}
                    onClick={(e) => uploadattachments(e, file_1, "file_1")}
                    className="submit_btn"
                  >
                    upload
                  </button>
                ) : null}
              </div>
            </div>
          ) }

          {f2 ? null : (
            <div>
             <span style={{fontSize:'0.8rem',color:'#777'}}>File 3</span>

              <div>
                <input
                  type="file"
                  onChange={(e) => {
                    setFile_2(e.target.files[0]);
                    fileSize(e);
                  }}
                />
                {file_2 != "" ? (
                  <button
                    onClick={(e) => uploadattachments(e, file_2, "file_2")}
                    className="submit_btn"
                  >
                    upload
                  </button>
                ) : null}
              </div>
            </div>
          ) }

          {f3 ? null :(
            <div>
                          <span style={{fontSize:'0.8rem',color:'#777'}}>File 4</span>

              <div>
                <input
                  type="file"
                  onChange={(e) => {
                    setFile_3(e.target.files[0]);
                    fileSize(e);
                  }}
                />
                {file_3 != "" ? (
                  <button
                    onClick={(e) => uploadattachments(e, file_3, "file_3")}
                    className="submit_btn"
                  >
                    upload
                  </button>
                ) : null}
              </div>
            </div>
          ) }
        </Container></>) : null }

   
       
     

        <h4> Name of Ticket Creator </h4>
        <span> {TicketDetails[0].name} </span>

        <h4> Contact </h4>
        <span> {TicketDetails[0].contact} </span>

        <h4> Email </h4>
        <span> {TicketDetails[0].email} </span>
      </div>
    </>
  );
}

const Container = styled.div`
  margin: 13px 0;

  .submit_btn {
    height: 32px;
    border: none;
    border-radius: 4px;
    width: 90%;
    background: darkblue;
    color: #fff;
    text-transform: capitalize;
  }
  .attachments {
    color: #203dab;
    font-size: 0.9rem;
    font-weight: 700;
  }
`;

const DownloadFiles = styled.div`
  .icon__file {
    display: flex;
    align-items: center;

    .file_name {
      padding: 5px;
      .file__name {
        font-size: 0.7rem;
        color: #555;
        margin-left: 5px;
      }
    }
  }
  .icon__file {
    .file__path {
      margin: 10px 0;
      width: 22px;
      height: 22px;
      margin-right: 4px;
      color: #111;
      border-radius: 4px;
    }
  }
`;
