import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import DashboardIcon from '@material-ui/icons/Dashboard';
import axios from 'axios';
import history from './history';
import OptionalForm from './OptionalForm';
import { GoogleLogin } from 'react-google-login';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import Login from './Login';
import Register from './Register';
import {useParams} from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#a52a2a'
    }
  }
});
const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    //backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 'auto'),

  },
  desc: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '12%',
      marginRight: '12%'
    }
  },
  heroButtons: {
    marginTop: theme.spacing(3),
  },
  cardGrid: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
  },
  card: {
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '46.25%', //'56.25%', // 16:9
    backgroundSize: 'contain'
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  Googlebtn: {
    width: '165px',
    height: '35px',
    borderRadius: '4px',
    background: '#3b5998',
    color: 'white',
    border: '0px transparent',
    textAlign: 'center',
    margin: '5px',
    display: 'inline-block',
    '&:hover': {
      background: '#3b5998',
      opacity: 0.6,
    }
  }
}));


export default function About(props) {
  const classes = useStyles();
  const [result, setRes] = useState({});
  const [page2, setPage2] = React.useState(false);
  const [page3, setPage3] = React.useState(false);

  const [form, setForm] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [eid, setEid] = React.useState(null);
  const [pass, setPass] = React.useState(null);
  const {sid} = useParams();

  useEffect(() => {
    var udata = JSON.parse(localStorage.getItem('data'));
    var school = JSON.parse(localStorage.getItem('Schoollist'));
    var auth = JSON.parse(localStorage.getItem('auth'));
    if (udata != null && udata != undefined && udata != '' && school != null && school != undefined && school != '' && auth != null && auth != undefined && auth != '') {
      var s = { hd: "dash", data: udata.data };
      history.push({ pathname: "/alumni/Dashboard/" + props.match.params.sid, data: s })
    }
  }, [])

  const responseGoogle = (resp) => {
    // const data= JSON.parse(resp);

    var em = resp.profileObj.email;
    //console.log(em);
    var v = [{ name: resp.profileObj.name, email: resp.profileObj.email }];
    if (resp.profileObj.email != null) {
      axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?", { ['fun_name']: 'AlumniLogin', ['sid']: sid, ['email']: resp.profileObj.email, ['glogin']: 1 }).then((respo) => {
        console.log(respo);
        if (respo.data.Login == 1) {
          localStorage.setItem('Schoollist', JSON.stringify(respo.data.school_list))
          setEid(em);
          localStorage.setItem('auth', JSON.stringify(v))
          if (respo.data.user_data.length > 0) {
            var s = { hd: "dash", data: respo.data.user_data };
            // var s={hd:"Dashboard",data:resp.data.user_data} ;
            // console.log(s);
            localStorage.setItem('data', JSON.stringify(s));
            // localStorage.setItem('Schoollist',JSON.stringify(respo.data.school_list))
            history.push({ pathname: "/alumni/Dashboard/" + props.match.params.sid, data: s })
          }
          else {
            setForm(true);
          }
        }
      })
    }

    console.log(resp);

  }
  const handleRegClose=()=>{
    setPage3(false);
    setPage2(false);
    setForm(false)
  }
  useEffect(() => {

    async function fetchD() {
      const res = await axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { ['fun_name']: "get_alumini_aboutinfo", ['sid']:sid })
        .then(res => {

          setRes(res)
        }).catch(err => console.log("Error-" + err));
    }
    fetchD();

  }, [])
  var s;

  const Dash = () => { s = { hd: "dash", data: props.location.data.data, sid: props.match.params.sid }; history.push({ pathname: "/alumni/Dashboard/" + props.match.params.sid, data: s }) };
  const Notif = () => { s = { hd: "Latest Notifications", data: props.location.data.data, sid: props.match.params.sid }; history.push({ pathname: "/alumni/AlumniHome/" + props.match.params.sid, data: s }) };
  return (
    result.data != null || result == null ?  <MuiThemeProvider theme={theme}>
      {page2 == false && form == false && page3==false ?
        <div>
          <CssBaseline />
          <AppBar>
            <Toolbar>
              <Typography variant="h6" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' }}>About Us</Typography>
            </Toolbar>
          </AppBar>
          <Toolbar />
          <main>
            <div className={classes.heroContent}>
              <Container maxWidth="sm" style={{ maxWidth: 500 }}>

                <Typography variant="h5" align="center" color="textPrimary" paragraph><strong>{result.data.alumini_aboutus[0].title}</strong></Typography>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={result.data.alumini_aboutus[0].photo}
                    title="Image title"
                  />
                </Card><br /></Container>
              <Typography variant="h6" align="center" color="textSecondary" paragraph className={classes.desc}>
                {result.data.alumini_aboutus[0].description}
              </Typography>
              {(props.location.data == null || props.location.data == undefined) ? <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="center">
                  <Grid item>

                    <GoogleLogin
                      clientId={process.env.REACT_APP_API_KEY}
                      buttonText="Login With Google"
                      // className={classes.Googlebtn}
                      onSuccess={responseGoogle}

                      cookiePolicy={'single_host_origin'}
                      isSignedIn={false}
                    />
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" color="primary" onClick={() => setPage2(true)}>
                      <LockOutlinedIcon /> <p style={{ marginLeft: 10, marginBottom: 0 }}>Sign in With Email or Phone Number </p>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" color="primary" onClick={() => setPage3(true)}>
                      <LockOutlinedIcon /> <p style={{ marginLeft: 10, marginBottom: 0 }}>First Time Registration </p>
                    </Button>
                  </Grid>
                </Grid>
              </div> : (props.location.data != null || props.location.data != undefined) ?

                  <div className={classes.heroButtons}>
                    <Grid container spacing={2} justify="center">
                      <Grid item>
                        <Button variant="contained" color="primary" onClick={Dash}>
                          <DashboardIcon />   Dashboard
                </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" onClick={Notif}>
                          Notifications
                </Button>
                      </Grid>
                    </Grid>
                  </div> : null}

            </div>

          </main>

        </div> : page2 == true && form == false && page3==false ? <Login sid={props.match.params.sid} /> : page3==true && form==false && page2==false?<Register sid={props.sid} lg={handleRegClose} />:
         form == true ? <OptionalForm sid={props.match.params.sid} email={eid} />
          : null
      }</MuiThemeProvider> : <><div className="d-flex justify-content-center">
        <div className="loader border-top-info margin-top-1" />
      </div></>
  );
}