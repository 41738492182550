import React,{useEffect,useState} from 'react';
//import {Avatar} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {Typography,ButtonGroup, InputBase,Paper,IconButton} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {SearchRounded} from '@material-ui/icons';
import {useParams} from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Imageprvw from '../alumni/Imageprvw';
const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth:'100%',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    
    margin: theme.spacing(2, 0, 2),
  },
  inputRoot: {
    //backgroundColor:'#778beb',
    color: 'inherit',
  },
  inputInput: {
    color: 'inherit',
    padding: theme.spacing(1, 1, 1, 0),
      
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    width: '70vw',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rootpaper: {

    display: 'flex',
    alignItems: 'center',
    borderRadius: 30,
    //width: 150,
    height: 40,
  },
  iconButton: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    // background: theme.palette.red[200],
    '& svg': {
      // fill: theme.palette.base.white,
    },
  },
  idimage:{
    objectFit:'contain', display:'flex',justifyContent:'center',maxWidth:'88vw',maxHeight:'60vh',border:'2px solid',margin:'auto'

  }
  
}));

export default function Details(props) {
  const classes = useStyles();
  const [result,setResult]=useState();
  const [submit,setSubmit]=useState();
  const [open, setOpen] = React.useState(false);
  const [Sdiv, setDiv] = React.useState(null);
  const [Sclass, setClass] = React.useState(null);
  const [Sdivid, setDivid] = React.useState(null);
  const [Sclassid, setClassid] = React.useState(null);
  const [addr, setAddr] = React.useState(null);
  const [mobile, setMobile] = React.useState(null);

  const [dob, setDob] = React.useState(null);
   const [search, setSearch]=React.useState(null);
   const [name, setName]=React.useState(null);
   const [Bloodg, setBg]=React.useState(null);
   const [K, setK]=React.useState(0);
   const [Static_Data, setStaticData]=React.useState(null);
   const [total, setTotal]=React.useState(0);
   const [ispreview,setPreview]=useState(false)
   const [imgsrc,setImgsrc]=useState(null)
   const {sid}=useParams()
   const [Darray, setDarray] = React.useState(null);
   const [age, setAge] = React.useState('');
   const [Sel, setSel] = React.useState(null);
   const [isapprove,setApproved]=React.useState(false)
  const handleChangeClass = (event) => {
    console.log(event.target.value)
    for(let i=0;i<props.divlist.length;i++){
      if( props.divlist[i].class_name=== event.target.value){
        setClass(props.divlist[i].class_name);
        setClassid(props.divlist[i].class);
      }
    }
    // setClass(event.target.value);
  
}
const FontFm = (event) => {
       
  setAge(event.target.value);
  for(var i=0;i<Darray.length;i++){
      if(Darray[i].name === event.target.value){
      setSel(Darray[i])
      // setOpen(Darray[i].orientation)
      }
  }
  console.log(event.target.value)
  // props.ld(event.target.value)
};
const Close=()=>{
  setImgsrc(null)
  setPreview(false);
}
useEffect(()=>{
  if(imgsrc!=null)
  setPreview(true)
},[imgsrc])
const getList = (classnm, div) => {
  axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { ['fun_name']: 'IDcard_getProof', ['sid']: sid, ['classid']: classnm, ['divid']: div,['did'] :Sel.id}).then(res => {
    console.log(res.data.Id.length);
    setStaticData(res)
    setTotal(res.data.Id.length)
  });
}
const handleNext = () => {
if(K<total-1){
  //console.log(K)
  setK(K+1);
}
else{
  window.alert('final entry')
}
}
const handlePrev= () => {
  if(K>0){
    setK(K-1);
  }
  else{
    window.alert('first entry')
  }

}
useEffect(()=>{
setK(0)
},[props.data])
  const handleChangeDiv = (event) => {
  
    for(let i=0;i<props.divlist.length;i++){
      if( props.divlist[i].div_name=== event.target.value){
        setDiv( props.divlist[i].div_name);
        setDivid( props.divlist[i].divid);
      }
    }
    
  }
  const Prv=(s)=>{
    setImgsrc(s)
    
  }
  useEffect(()=>{
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'GetIDDesign',['sid']:sid}).then (res=> {
          // console.log(res.data.design)
        setDarray(res.data.design)
       
        });
  },[])
  const handleTotal=()=>{
    setTotal(0)
  }
  const Confirm=()=>{
    if(name!=null && Static_Data.data.Id[K].design_id!=undefined && Static_Data.data.Id[K].design_id!=null){
      console.log(name+'=>'+Bloodg)////k6kb9_idcard_approval_log
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['sid']:sid,['fun_name']:'IDcard_ProofConfirm',['did']:Static_Data.data.Id[K].design_id,['userid']:parseInt(Static_Data.data.Id[K].userid),['isimage']:1}).then (res=> {
      window.alert('Confirmed successfully')      
    getList(Sclassid, Sdivid)
      setK(K)
    
      });
    }
  }
  useEffect(()=>{
    if(Static_Data!=null){
      if(Static_Data.data.Id.length>0){
setName(Static_Data.data.Id[K].name)
setAddr(Static_Data.data.Id[K].address)
setMobile(Static_Data.data.Id[K].mobile)
setBg(Static_Data.data.Id[K].bloodgroup)
setDob(Static_Data.data.Id[K].dob)
if(Static_Data.data.Id[K].teacher_id_image_confirm=="1"){
  setApproved(true)
}
else{
  setApproved(false)
}
      }
    }
  },[K,Static_Data])
  return (
    <div >
      <CssBaseline />
    
     {/* <Snackbar  anchorOrigin={{vertical:'top',horizontal:'center'}} open={open} autoHideDuration={6000} onClose={handleClose}>
     <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
  Successfully Registered — <strong>{result!=null || result!=undefined? result.data.quiz[0].name: null}!</strong>
      </Alert>
      </Snackbar> */}
      <div className={classes.paper}>
        <div style={{padding:14}}>
          {/* <div className={classes.searchIcon}>
           
          </div> */}
          {/* {console.log(props.data!=undefined? props.data.data.length:0)} */}
          <Paper className={classes.rootpaper}> 
          <IconButton className={classes.iconButton} aria-label="search" >
        <SearchRounded />
        
      </IconButton>
  
               <Autocomplete
              //  setDiv(value.div_name !=null?value.div_name:'');setClass(value.class_name!=null?value.class_name:'');
              onChange={(event, value) => {setSearch(value);setK(value!=null? Static_Data.data.Id.indexOf(value)>=0?Static_Data.data.Id.indexOf(value):K:K);setName(value!=null? value.name:name);setAddr(value!=null?value.address:addr);setDob(value!=null?value.dob:dob);setBg(value!=null?value.bloodgroup:Bloodg)}}
        
         value={search}
        
        options={ props.data !=undefined ?Static_Data.data.Id.map((option) =>option):[]}
        getOptionLabel={(option) => option.name}        
        autoHighlight
        renderInput={(params) => (
          <InputBase
          placeholder="Search…"
         
          ref={params.InputProps.ref}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          autoFocus
          inputProps={params.inputProps}
          
        />
  )}
      />
            </Paper>
            
              
              <div style={{ display:'flex',marginRight:15}}>
      <FormControl variant="outlined" fullWidth style={{marginBottom:30,marginRight:12,marginTop:10}}>
        <InputLabel id="div">Select Class </InputLabel>
        <Select fullWidth variant='outlined'
          labelId="class"
          id="class"
          value={Sclass!=undefined?Sclass:''}
          onChange={handleChangeClass}
          label="Select Class"
          
        >
          {props.classnm!=undefined? props.classnm.map((v,i)=>(
        <MenuItem value={v.class_name} >{v.class_name}</MenuItem>
          )):null}
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth style={{marginBottom:30,marginRight:12,marginTop:10}}>
        <InputLabel id="div">Select Div </InputLabel>
        <Select fullWidth
          labelId="div"
          id="div"
          value={Sdiv!=undefined?Sdiv:''}
          onChange={handleChangeDiv}
          label="Select Div"
          
        >
          {props.divlist!=undefined? props.divlist.map((v,i)=>(
            v.div_name.startsWith(Sclass+'-')?<MenuItem value={v.div_name} >{v.div_name}</MenuItem>:null
            )):null}
        </Select>
      </FormControl>
              </div>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <InputLabel  >
          Design
        </InputLabel>
        <Select fullWidth
          variant="outlined"
          value={age}
          onChange={FontFm}
          displayEmpty
          label="Design"
          className={classes.selectEmpty}
        >
            {Darray!=null || Darray!=undefined? Darray.map((v,i)=>
            <MenuItem value={v.name}>{v.name}</MenuItem>
            ):null}
        </Select>
      </FormControl>
              <Button style={{marginRight:30,border:"none", outline:"none",}}
            onClick={()=>{setK(0);setSearch(''); handleTotal(); getList(Sclassid,Sdivid)}}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Get List
          </Button>
              </div>
              {ispreview && imgsrc!=null ?<Imageprvw img={imgsrc} exit={Close} isopen={true}/>:null}
        {Static_Data!=null && total>0?<div className={classes.form}  >
          <Grid container spacing={2}>
            <div style={{display:'flex',justifyContent:'center',marginLeft:'30vw'}}>
          </div>
            <Grid item xs={12}> 
            <Typography style={{textAlign:'center'}}>{name} </Typography>
            </Grid>
            {Static_Data.data.Id[K].idcard_image!=""?<img className={classes.idimage} onClick={()=>Prv(Static_Data.data.Id[K].idcard_image)} src={Static_Data.data.Id[K].idcard_image} /> :
            
            <div style={{textAlign:'center',marginTop:'10vh',marginBottom:'10vh',width:'100vw'}}>ID Card Not Yat Generated</div>}          
          </Grid>
          <ButtonGroup>
          <Button style={{marginRight:30,border:"none", outline:"none",}}
            onClick={()=>handlePrev()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Previous
          </Button>
          <Button
          style={{border:"none", outline:"none",}}
             onClick={()=>handleNext()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Next
          </Button>
         {Static_Data.data.Id[K].idcard_image!="" && isapprove==false ? <Button
             onClick={()=>Confirm()}
            fullWidth
            variant="contained"
            color="primary"
            style={{marginLeft:10,border:"none", outline:"none",}}
            className={classes.submit}
          >
            Confirm
          </Button>: Static_Data.data.Id[K].idcard_image!="" && isapprove?
          <CheckCircleIcon  className={classes.submit} fontSize="large" style={{color:'green'}}/>:null}
          </ButtonGroup>
          </div>:null}
          {/* ):null}  */}
      </div>
    
    </div>
  );
}