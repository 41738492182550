import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import "./WeeklyPlan.css";
import DayPlan from "./DayPlan";
import axios from "axios";
import Swal from "sweetalert2";
import {DecryptED} from "../../DecryptJS/Decrypt"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  headDiv: {
    // textAlign: "center",
    // margin: "15px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  home: {
    marginLeft: "24px",
    fontSize: "20px",
    color: "#0099FF",
    fontWeight: "bold",
    cursor: "pointer",
  },
  report: {
    marginRight: "24px",
    fontSize: "20px",
    color: "#0099FF",
    fontWeight: "bold",
    cursor: "pointer",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    color: "white",
    backgroundColor: "rgb(46, 175, 226)",
    padding: "6px 10px",
    fontSize: "18px",
    outline: "none",
    border: "none",
    borderRadius: "6px",
  },
}));

const WeeklyPlan = () => {
  const classes = useStyles();
  let { sid, userid } = useParams();
  sid = DecryptED(sid);
  userid = DecryptED(userid);
  const [weeklyData, setWeeklyData] = useState([]);
  const [lessonName, setLessonName] = useState("");
  const [currentAcadId, setCurrentAcadId] = useState("");
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [month, setMonth] = useState("");
  const [classId, setClassId] = useState("");
  const [subjectId, setSubjectId] = useState("");

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!classId || !month || !subjectId || !lessonName) {
      Swal.fire("Please fill the required fields");
    } else {
      const data = {
        fun_name: "addWeeklyLessionPlan",
        staff_id: userid,
        acad_Id: currentAcadId,
        sid,
        class_id: classId,
        month: month,
        subject_id: subjectId,
        lession_name: lessonName,
        days: weeklyData,
      };
      const response = await axios.post(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data
      );
      if (response) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };
  const selectClassIdHandler = (e) => {
    e.preventDefault();
    if (e.target.value !== "default") {
      const classId = e.target.value;
      setClassId(classId);
      const getAccessableSubject = async () => {
        const response = await axios.post(
          "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
          {
            fun_name: "getAccessClassSubject",
            sid,
            staff_id: userid,
            div_id: classId,
          }
        );
        if (response) {
          setSubjectList(response.data.result);
        }
      };
      getAccessableSubject();
    }
  };
  useEffect(() => {
    const getAccessableClass = async () => {
      const data = {
        fun_name: "getAccessClass",
        sid: sid,
        staff_id: userid,
      };
      const response = await axios.post(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data
      );
      if (response) {
        setClassList(response.data.result);
      }
    };
    getAccessableClass();

    const getCurrentAcadId = async () => {
      const data = {
        fun_name: "currentAcadId",
        sid: sid,
      };
      const response = await axios.post(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data
      );
      if (response) {
        setCurrentAcadId(response.data.result[0].acadid);
      }
    };
    getCurrentAcadId();
  }, []);

  return (
    <div className="form-main">
      <div className={classes.headDiv}>
        <Link className={classes.home} to={`/weeklyHome/${sid}/${userid}`}>
          Home
        </Link>
        <h2
          style={{
            color: "rgb(46, 175, 226)",
            marginTop: "5px",
            fontSize: "22px",
            fontWeight: "550",
          }}
        >
          WEEKLY LESSON PLAN
        </h2>
        <Link className={classes.report} to={`/weeklyReport/${sid}/${userid}`}>Report</Link>
      </div>
      <form className="form">
        <div className="user-details">
          <div className="form-details">
            <div style={{ display: "flex" }}>
              <label htmlFor="class">Class: </label>
              <select
                className="select"
                onChange={(e) => selectClassIdHandler(e)}
              >
                <option value="default">Select Class</option>
                {classList &&
                  classList.map((data, idx) => {
                    return (
                      <option key={idx} value={data.id}>
                        {data.div_name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="box">
              <label htmlFor="month">Date: </label>
              <input
                onChange={(e) => setMonth(e.target.value)}
                name="month"
                id="month"
                type="date"
                placeholder=""
              />
            </div>
          </div>
          <div className="form-subject-details">
            <div style={{ display: "flex", marginTop: "15px" }}>
              <label htmlFor="class">Subject: </label>
              <select
                className="select"
                onClick={(e) => setSubjectId(e.target.value)}
              >
                <option value="">Select Subject</option>
                {subjectList &&
                  subjectList.map((data, idx) => {
                    return (
                      <option key={idx} value={data.id}>
                        {data.subject}
                      </option>
                    );
                  })}
              </select>
            </div>
            {/* <div className="date-duration">
              <label htmlFor="date">Date </label>
              <span style={{ marginLeft: "10px" }}>From</span>
              <input id="date" type="date" />
              <span>To</span>
              <input id="date" type="date" />
            </div> */}
          </div>
          <div className="lesson-name">
            <label htmlFor="lessonName">Lesson Name: </label>
            <input
              value={lessonName}
              onChange={(e) => setLessonName(e.target.value)}
              id="lessonName"
              type="text"
              placeholder=""
            />
          </div>
        </div>
        <div className="Accordion">
          {days.map((day, idx) => {
            return (
              <DayPlan
                day={day}
                key={idx}
                weeklyData={weeklyData}
                setWeeklyData={setWeeklyData}
              />
            );
          })}
        </div>
        <div className="btn-div">
          <button
            onClick={(e) => handleSubmit(e)}
            className={classes.button}
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default WeeklyPlan;
