import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import lessonImg from "../images/lessonPlan.png";
import reportImg from "../images/report.png";
import axios from "axios";
import { DecryptED } from "../../DecryptJS/Decrypt";
const Home = () => {
  let { sid, userid } = useParams();
 
  useEffect(() => {
    const getAcadyears = async () => {
      const response = await axios.get(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        {
          fun_name: "allAcadId",
          sid: DecryptED(sid),
        }
      );
      console.log(response);
    };
    getAcadyears();
    // const response = axios.get(
    //   "https://topschooldev.prisms.in/rest/index.php/staff_list.json?",
    //   {
    //     fun_name: "getAccessClass",
    //     sid: "258",
    //     staff_id: "491",
    //     acad_id: "8",
    //   }
    // );
    // console.log(response);
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "40%", height: "40%", textAlign: "center" }}>
          <div className="lessonPlan" style={{ marginBottom: "30px" }}>
            <Link to={`/lessonPlan/${sid}/${userid}`}>
              <img
                style={{ width: "100px", height: "100px" }}
                src={lessonImg}
                alt="lessonImg"
              />
            </Link>
            <h4 style={{ color: "black", marginTop: "8px" }}>Lesson Plan</h4>
          </div>
          <div className="report">
            <Link to={`/report/${sid}/${userid}`}>
              <img
                style={{ width: "100px", height: "100px" }}
                src={reportImg}
                alt="lessonImg"
              />
            </Link>
            <h4 style={{ color: "black", marginTop: "8px" }}>Report</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
