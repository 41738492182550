import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ReportResult from "./ReportResult";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import {DecryptED} from "../../DecryptJS/Decrypt"
const AdminWeeklyReport = () => {
    const classes = useStyles();
    const [apiloader, setapiLoader] = useState();
    const { sid, userid } = useParams();
    const [isCalled, setIsCalled] = useState(false);
    const [reports, setReports] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [classList, setClassList] = useState([]);
    const [inputData, SetInputData] = useState({
        "sid": DecryptED(sid),
        "userId": DecryptED(userid),
        "staff": [],
        "class_id": [],
        "startDate": "",
        "endDate": "",
        "page": 1,
        "pageSize": 10

    });

    const handleSubmit = async () => {
        setapiLoader(true);
        try {
            // const data = inputData;
            const response = await axios(
                {
                    method: "POST",
                    url: `${process.env.REACT_APP_BASE_URL}/api/admin/weeklyPlan/all/report`,
                    data: inputData,
                    headers: {
                        Authorization: "#$laksdfnlknoea#$@$%^&%$",
                    }
                }

            )
                .then((response) => {
                    setReports(response.data.data);
                    setapiLoader(false);
                })
                .catch((error) => {
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setapiLoader(false);
                });
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: error,
                showConfirmButton: false,
                timer: 1500,
            });
            setapiLoader(false);
        }

    }

    const getTeachers = async () => {
        try {
            const data = {
                "sid": DecryptED(sid),
                "userId": DecryptED(userid)
            }
            const response = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASE_URL}/api/admin/weeklyPlan/teachers`,
                data,
                headers: {
                    Authorization: "#$laksdfnlknoea#$@$%^&%$",
                },
            })
                .then((response) => {
                    setTeachers(response.data);
                })
                .catch((error) => {
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: error,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }

    const getClasses = async () => {
        try {
            const data = {
                "sid": DecryptED(sid),
                "userId": DecryptED(userid)
            }
            const response = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASE_URL}/api/admin/weeklyPlan/class`,
                data,
                headers: {
                    Authorization: "#$laksdfnlknoea#$@$%^&%$",
                },
            })
                .then((response) => {
                    setClassList(response.data)
                })
                .catch((error) => {
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: error,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }

    const InsertTeachers = (e) => {

        if (e === 'SELECT_ALL') {
            //SetInputData({ ...inputData, staff: []});
            for (let i = 0; i < teachers.length; i++) {
                inputData.staff.push(teachers[i].staff_id)
            }
        } else {
            SetInputData({ ...inputData, staff: [e] })
        }
        
    }

    const InsertClasses = (e) => {

        if (e === 'SELECT_ALL') {
            // SetInputData({ ...inputData, class_id: []});
            for (let i = 0; i < classList.length; i++) {
                inputData.class_id.push(classList[i].class_id)
            }
        } else {
            SetInputData({ ...inputData, class_id: [e] })
        }
    
    }

    useEffect(() => {
        getTeachers();
        getClasses();
    }, [])
    useEffect(() => {
        if(isCalled){
            handleSubmit();
        }
        else{
            setIsCalled(true)
        }
    }, [inputData.page])
    
    return (
        <div className={classes.mainDiv}>
            <div className={classes.heading}>
                <h3
                    style={{
                        color: "#42A0EA",
                        padding: "8px 12px",
                        fontWeight: "550",
                        fontSize: "22px",
                        marginTop: "2%"
                    }}
                >
                    Weekly Reports
                </h3>
            </div>
            <div className="container">


                <div className={classes.dateDiv}>
                    <form className={classes.form} >
                        <div className="row">

                            <div className="col-md-6">
                                <label className={classes.label} htmlFor="teachers">
                                    TEACHERS
                                </label>
                                <select className={classes.input} style={{ color: "white" }} name="teachers" onChange={(e) => InsertTeachers(e.target.value)}>
                                    <option>SELECT TEACHERS</option>
                                    <option value={'SELECT_ALL'}>SELECT ALL</option>
                                    {
                                        teachers.length ?
                                            teachers.map((Teach, index) => (<option value={Teach.staff_id} key={index}>{Teach.Teacher}</option>))
                                            : <option>NO DATA</option>
                                    }
                                </select>
                            </div>

                            <div className="col-md-6">
                                <label className={classes.label} htmlFor="classes">
                                    CLASSES
                                </label>
                                <select className={classes.input} style={{ color: "white" }} name="classes" onChange={(e) => InsertClasses(e.target.value)}>
                                    <option>SELECT CLASS</option>
                                    <option value={'SELECT_ALL'}>SELECT ALL</option>
                                    {
                                        classList.length ? classList.map((item, index) => (
                                            <option value={item.class_id} key={index}>{item.div_name}</option>
                                        ))
                                            : <option>NO DATA</option>
                                    }
                                </select>
                            </div>

                            <div className="col-md-6">
                                <label className={classes.label} htmlFor="fromDate">
                                    Start Date
                                </label>
                                <input
                                    className={classes.input}
                                    type="date"
                                    style={{ color: "white" }}
                                    onChange={(e) => SetInputData({ ...inputData, startDate: e.target.value })}
                                    name="fromDate"
                                />
                            </div>

                            <div className="col-md-6">
                                <label className={classes.label} htmlFor="toDate">
                                    End Date
                                </label>
                                <input
                                    className={classes.input}
                                    style={{ marginLeft: "20px", color: "white" }}
                                    type="date"
                                    name="toDate"
                                    onChange={(e) => SetInputData({ ...inputData, endDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <input type="button" className="btn btn-primary form-controll mt-0" value={"submit"} onClick={handleSubmit}/>
                    </form>

                </div>
            </div>
            {
                apiloader ? (
                    <div className="TMLoader">
                        <Loader type="ThreeDots" color="#bd2f5e" height={80} width={80} />
                    </div>
                ) :
                    <>
                        <ReportResult
                            reports={reports}
                            setReports={setReports}
                            startDate={inputData.startDate}
                            endDate={inputData.endDate}
                            userid={DecryptED(userid)}
                            sid={DecryptED(sid)}
                        />
                        {reports.length > 0 ? 
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end">
                                <li className={`page-item ${inputData.page <=1? 'disabled':''}`} onClick={()=>{SetInputData({...inputData, page: inputData.page-1});}}>
                                    <div className="page-link" tabIndex={'-1'}>Previous page</div>
                                </li>
                                <li className="page-item">
                                    <div className={`page-link `} onClick={()=>{SetInputData({...inputData, page: inputData.page+1});}}>Next page</div>
                                </li>
                            </ul>
                        </nav>:""}
                    </>
            }
        </div>
    )
}

export default AdminWeeklyReport;

const useStyles = makeStyles({
    mainDiv: {
        marginTop: "0px",
    },
    heading: {
        margin: "0",
        padding: "0",
        // backgroundColor: "#42A0EA",
        textAlign: "center",
        height: "45px",
    },
    dateDiv: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        marginTop: "40px",
    },
    input: {
        width: "200px",
        marginLeft: "15px",
        backgroundColor: "#42A0EA",
        padding: "5px 8px",
        border: "none",
        outline: "none",
    },
    label: {
        fontSize: "16px",
        color: "#42A0EA",
    },
    form: {
        margin: "10px 0px",
    },
    button: {
        margin: "20px 0px",
        backgroundColor: "#42A0EA",
        padding: "6px 18px",
        color: "white",
    },
});