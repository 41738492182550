import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import {Fade,Step,Stepper,StepLabel,StepContent,Button,Typography,Paper} from '@material-ui/core';
import CartList from './CartList';
import OrderPayForm from './OrderPayForm';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
    return ['Verify Cart Items', 'Verify Details', 'Payment'];
  }
  
  function getStepContent(step,props,fname,femail,fmob,faddr,namep,emailp,mobp,addressp) {
    switch (step) {
      case 0:
        return <CartList data={props.data} del={props.del} />;
      case 1:
        return<> <OrderPayForm info={props.usr} pname={fname} pemail={femail} pmob={fmob} paddr={faddr} vname={namep} vemail={emailp} vmob={mobp} vaddr={addressp}  />
       
        </>;;
      case 2:
    return<> Totla Amount: <p style={{color:'#12C407'}}>&#x20B9;{props.total}</p>
      <p>Payment Mode : COD</p>
    </>;
    
      default:
        return 'Unknown step';
    }
  }
  

export default function TransitionsModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [name,setName]=React.useState();
const [email,setEmail]=React.useState();
const [mob,setMob]=React.useState();
const [address,setAddress]=React.useState();
  //  const getStepC= getStepContent()
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  
  };
  const handleCod = () => {
    if(props.data!=null && props.usr!=null ){
      var uniq='OD'+Date.now().toString()+props.usr.data.student[0].user_id;
        for(var i=0;i<props.data.length;i++){
          
          if(props.data[i].p_id!=null ||props.data[i].p_id!=undefined){
           

          axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"addProductSale",['sid']:props.usr.data.school.school_id,['userid']:props.usr.data.student[0].user_id,
          ['p_id']:props.data[i].p_id,['qty']:1,['sale_price']:props.data[i].sale_price,['purchase_price']:props.data[i].purchase_price,['orderid']:uniq,['acad_id']:props.usr.data.student[0].acad_id,['classdiv_id']:props.usr.data.student[0].classdiv_id}).
            then (res=> {
                console.log(res);
               
              });
        
        }
      }     
      setTimeout(()=>{
      props.cc()},10000)
        
       
    }
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };  

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.isopen}
        onClose={props.close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.isopen}>
          <div className={classes.paper}>
          <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index,props, setName,setEmail,setMob,setAddress,name,email,mob,address)}</Typography>
             
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={activeStep === steps.length - 1 ? handleCod:handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Place Order' : 'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>Order Placed Successfully </Typography>
          
        </Paper>
      )}
    </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}