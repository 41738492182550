import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import {useParams} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {MenuItem,InputLabel,FormControl,Select,ListItemIcon,ListItemText} from '@material-ui/core';
import axios from 'axios';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
        
      paddingLeft: theme.spacing(4),
    },
    select: {
        '&$.MuiSelect-select': {
            display:'flex',
            '::after':{
                display:'flex',
            }
        }
      }
    
  }));
export default function FormDialog(props) {
    const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState('');
  const [Darray, setDarray] = React.useState(null);
  const {sid}=useParams();
  const [Sel, setSel] = React.useState(null);
  useEffect(()=>{
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'GetIDDesign',['sid']:sid}).then (res=> {
        // console.log(res.data.design)
        setDarray(res.data.design)
        });
  },[])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.close()
  };
const Save=()=>{
    if(Sel===null){
        window.alert('Please Select the design')
    }
    else{
    if(Sel.default==='1'){
        window.alert('This Design is Already Set to Default')
    }
    else if(props.Ori!=undefined && Sel.default!='1'){
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'SetFinalDesign',['sid']:sid,['id']:Sel.id,['ori']:props.Ori}).then (res=> {
        setDarray(res.data.design)
        localStorage.setItem('design',Sel.design)
        props.ld(Sel)
        });
    }
}

}
const FontFm = (event) => {
       
    setAge(event.target.value);
    for(var i=0;i<Darray.length;i++){
        if(Darray[i].name === event.target.value){
        setSel(Darray[i])
        }
    }
    console.log(event.target.value)
    // props.ld(event.target.value)
  };
  return (
    <div>
     
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Set Final Design</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To set the final design Select the title and click on set button.
          </DialogContentText>
          <FormControl fullWidth className={classes.formControl}>
        <InputLabel  >
          Designs
        </InputLabel>
        <Select fullWidth 
         style={{display:"flex"}}
          value={age}
          onChange={FontFm}
          displayEmpty
        
          classes={ classes.select}
        >
            {Darray!=null || Darray!=undefined? Darray.map((v,i)=>
            <MenuItem key={i} value={v.name} >
             
          <ListItemText primary={v.name} />
          {v.default==="1"?<ListItemIcon>
            <CheckCircleOutlineIcon color="primary" />
          </ListItemIcon>:null}
            </MenuItem>
            ):null}
          
          
        </Select>
      </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>Save()} color="primary">
            Set Final Design
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
