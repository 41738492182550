import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Participants from '../ReusedComponents/Participants'

export default function AddParticipants() {
    const history = useHistory();
    const {sid,uid} = useParams();
  
    function onAdd(params){
        history.push(`/appointment/superadmin/appointmentdetails/${sid}/${uid}`,params);
        console.log(params);
    }

    return (
        <Participants historydata={history.location.state} sid={sid} uid={uid} fun={"Appointment_Superadmin"} type={"Super"} onAdd={(params)=>{onAdd(params)}} />
    );
}
