import React,{useEffect} from 'react';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import {Button,ButtonGroup,GridList,GridListTile } from '@material-ui/core'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: '90%',
    height: '90%',
  },
  img1: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  button: {
    margin: theme.spacing(1),
    display: 'inline-block'
  },
  img:{
    height: 255,
    display: 'block',
    //maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft:'20%',
      width:'60%'
    }
  },
  // gridList: {
  //   width: 500,
  //   height: 450,
  // },
}));

export default function Product(props) {
  const classes = useStyles();
  const [imgs,setImgs]=React.useState(null);
  useEffect(()=>{
    if(props.data.prod_img.includes(',')){
      let imgarr=props.data.prod_img.split(',');
      setImgs(imgarr)
      console.warn("currprod"+imgarr[0])
  }
  },[])
  const [activeStep, setActiveStep] = React.useState(0);
  //const [selected,setSel]=React.useState();
  const maxSteps = 3;
  const theme = useTheme();


  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <div className={classes.root}>
     
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item style={{ overflow:'hidden'}}>
            {imgs==null ?
            <ButtonBase className={classes.image}>
              
              <img className={classes.img1} alt="complex" 
              src={
                props.data.prod_img=="" || props.data.prod_img==null?
                "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS6Dw1Etemhe9K_LEK8pXzVO0X2tnqkMW8q4Q&usqp=CAU":props.data.prod_img
              } />
            </ButtonBase>:
             <GridList cellHeight={160} className={classes.gridList} cols={3}>
        <AutoPlaySwipeableViews
        style={{width:'100%',height:'100%'}}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {imgs.map((step, index) => (
          <div key={step.label} >
            {Math.abs(activeStep - index) <= 2 ? (
              <img  src={step} className={classes.img} alt={step}  />

            ) : null}
          </div>
        ))}
        </AutoPlaySwipeableViews>
             {/* {imgs.map((tile) => (
               <GridListTile key={tile.img} cols={tile.cols || 1}>
                 <img style={{flex: 1,
                  width: '100%',
                  height: '100%',
                  resizeMode: 'contain'}} src={tile} alt={tile}  />
               </GridListTile>
             ))} */}
           </GridList>
            
            }
          </Grid>
          <Grid item xs={12} sm container style={{marginLeft:30}}>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" style={{textTransform: 'capitalize'}}>
                  {props.data.p_name}
                </Typography>
                <Typography variant="body2" gutterBottom style={{display: 'inline-block'}}><p>
  Price: <Typography style={{color:'#12C407', display: 'inline-block'}}>&#x20B9;{props.data.purchase_price} </Typography> 
  </p> </Typography>
                <Typography variant="body2" color="textPrimary">
                  Detials:  {props.data.p_details ==null || props.data.p_details==''? "Details Not Avaliable":props.data.p_details}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                <p>{Number(props.data.available_stock)>0?<p style={{color:'#12C407'}}>Stock Avaliable</p>:<p style={{color:'#C20906'}}>Out Of Stock</p>}</p>
                </Typography>
              </Grid>
              <Grid item >
              <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical outlined primary button group"
      >
              <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<ShoppingBasketIcon />}
      >
        Buy Now
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<ShoppingCartIcon />}
        onClick={()=>props.addCart(props.data)}
      >
        Add To Cart
      </Button>
      </ButtonGroup>

              </Grid>
            </Grid>
            {/* <Grid item>
              <Typography variant="subtitle1">$19.00</Typography>
            </Grid> */}
          </Grid>
        </Grid>
      </Paper>
      
    </div>
  );
}

