import React from 'react';
import Tickets from '../ReusedComponents/Tickets'
import { useParams } from 'react-router-dom'

export default function AssignedTickets() {
    const { sid, uid } = useParams();

    return (
        <Tickets
            userType={"user"}
            title={"Assigned Tickets"}
            options={[{ title: "Create ticket", url: `/tickets/user/creating/${sid}/${uid}` },
            { title: "Ticket reports", url: `/tickets/user/report/${sid}/${uid}` }]}
        />
    );
}
