import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {AppBar,Toolbar,IconButton} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
//import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
//import {saveAs} from 'file-saver';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
// const SaveFile=(file)=>{
//   let fname=file.substr(file.lastIndexOf('-')+1)
//    saveAs(file,fname);
//  }

export default function ImageView(props) {
  const classes = useStyles();
  return (
    <div>    
         
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.isopen}
        onClose={()=>props.exit()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
       <div>   
         {console.log(props.img)}
       <IconButton style={{position:'fixed',top:20,left:10}} onClick={()=>props.exit()}>
        <KeyboardBackspaceIcon style={{color:'white'}}/>
        </IconButton>
        {/* <IconButton style={{position:'fixed',top:20,right:10}} onClick={()=>SaveFile(props.img)}>
        <CloudDownloadIcon style={{color:'white'}}/>
        </IconButton>   */}
        <Fade in={props.isopen}>
        
         <img style={{objectFit:'contain',maxWidth:'100%',maxHeight:'100%'}} src={props.img}/>
        </Fade>
        </div>
      </Modal>
    </div>
  );
}