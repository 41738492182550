import React, { useState, useEffect } from 'react';
import Header from '../ReusedComponents/Header';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios'
import moment from 'moment'
import Loader from 'react-loader-spinner'
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Swal from 'sweetalert2';
import { SendNotification } from '../ReusedComponents/SendNotification'

export default function RequestTicket({ props, userData, staffData, parentId, onCreateNew, staffDetails }) {

    console.log(props === "admin")
    const history = useHistory();
    const { sid, uid, ssid } = useParams();
    const [fileError, setFileError] = useState(false);
    const [progress, setprogress] = useState(0);
    const [userDetails, setUserDetails] = useState("");
    const [Committee, setCommittee] = useState("");
    const [Stafftype, setStafftype] = useState("");
    const [StaffDetails, setStaffDetails] = useState("");
    const [Staff_Id, setStaff_Id] = useState({ staff: "", staff_id: "" });

    useEffect(() => {
        if (props === "admin") {
            setStaffDetails(staffDetails)
            setStafftype(staffData);
            setUserDetails(userData);
        } else {
            axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "GetStaffDetails", sid: sid }).then((res) => {
                var a = [];
                (res.data.Staff_Details).map(i => {
                    if (a.length > 0 ? a[0][i.type] === undefined : true) {
                        a.length === 0 ? a.push({ [i.type]: [i] }) : a[0][i.type] = [i]
                    } else {
                        a[0][i.type].push(i)
                    }
                })
                setStafftype(Object.keys(a[0]));
                setStaffDetails(a[0]);
            })
            axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "AUserInfo", sid: ssid || sid, u_id: uid }).then((res) => {
                setUserDetails(res.data);
            })
        }
    }, []);

    function fileSize(e) {
        for (let i = 0; i < e.target.files.length; i++) {
            if (e.target.files[i].size > 10485760) {
                setFileError(true)
                break;
            }
        }
    }

    function renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    function NavigatetoNext(id, un) {

        console.log(id, un)
        Swal.fire(`Ticket id : ${id}`, "Ticket Created Successfully", "success");
        if (props === "admin") {
            onCreateNew(id)
        } else {
            history.push(`/tickets/user/details/${sid}/${uid}/${id}`,
                {
                    id: id,
                    username: un,
                    userType: "User",
                    title: "Tracking Reports"
                })
        }
    }



    const sendTicketNotification = async (sid)=> {
      console.log(sid)
    }

    async function CreateRequest() {
        var formData = {};
        const formdata = new FormData();
        var filesInF = document.getElementById("upload").files
        for (let i = 1; i < filesInF.length; i++) {
            if (filesInF[i].name.includes(",")) {
                formdata.append("file" + i, renameFile(filesInF[i], filesInF[i].name.replaceAll(",", "_")));
            } else {
                formdata.append("file" + i, filesInF[i]);
            }
        }

        Array.from(document.getElementsByTagName('form')[0]).map((i) => {
            if (i.name === 'file') {
                if (i.files.length === 0) {
                    formdata.append('file', "")
                } else {
                    if (i.files[0].name.includes(",")) {
                        formdata.append('file0', renameFile(i.files[0], i.files[0].name.replaceAll(",", "_")))
                    } else {
                        formdata.append('file0', i.files[0])
                    }
                }
            } else if (i.name === "staff") {
                formdata.append("staff", Staff_Id.staff)
                formdata.append("staff_id", Staff_Id.staff_id)
            } else {
                formdata.append(i.name, i.value)
            }
            formData[i.name] = i.value;
            return null
        });

        formdata.append('fun_name', "TicketInsert");
        formdata.append('sid', sid);
        formdata.append('uid', uid);
        formdata.append('nooffiles', filesInF.length);
        formdata.append('closed_tickets', 0);
        formdata.append('staffClosed_uid', 0);
        formdata.append('remark', "");
        formdata.append('parent_id', props === "admin" ? parentId : "");
        formdata.append('s_id', sid);
        formdata.append('closed_by', "");
        formdata.append('status', 'Open');
        formdata.append('name', userDetails !== undefined ? userDetails.student !== undefined && userDetails.student.length > 0 ? userDetails.student[0].name || "Unknown" : "Unknown" : "Unknown");
        formdata.append('schoolname', userDetails !== undefined ? userDetails.school !== undefined ? userDetails.school.school_name || "Unknown" : "Unknown" : "Unknown");
        formdata.append('email', userDetails !== undefined ? userDetails.student !== undefined && userDetails.student.length > 0 ? userDetails.student[0].studemail : "Unknown" : "Unknown");
        formdata.append('contact', userDetails !== undefined ? userDetails.student !== undefined && userDetails.student.length > 0 ?
            userDetails.student[0].mobile1 === "0" || userDetails.student[0].mobile1 === null || userDetails.student[0].mobile1 === undefined ?
                userDetails.student[0].mobile2 === "0" || userDetails.student[0].mobile2 === null || userDetails.student[0].mobile2 === undefined ? "Unknown" :
                    userDetails.student[0].mobile2 :
                userDetails.student[0].mobile1 :
            "Unknown" : "Unknown");
        formdata.append('date', moment().format("HH") >= 18 ? moment().add(1, "days").format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss"));
        formdata.append('duedate', moment().format("HH") >= 18 ? moment().add(3, "days").format("YYYY-MM-DD HH:mm:ss") : moment().add(2, "days").format("YYYY-MM-DD HH:mm:ss"));
        const nam = formdata.get("name").charAt(0).toUpperCase() + formdata.get("name").slice(1).toLowerCase();
        if (formData.committee !== "") {
            if (formData.priority !== "") {
                if (formData.title !== "") {
                    if (formData.discription !== "") {
                        if (formData.file !== "") {
                            axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?", formdata,
                                { onUploadProgress: ProgressEvent => { setprogress(Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)) } }
                            ).then(res => {
                                setStafftype("");
                                axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
                                    fun_name: "TicketHistoryInsert", sid: sid,
                                    ticket_id: res.data.Inserted[0].id, date: formdata.get("date"), committee: formdata.get("committee"),
                                    remark_reply: nam + " created new ticket", reply: 1, staff: "User", status: "Open", uid: uid,
                                    history: nam + " created new ticket", history_details: `<br />Name of Creator : ${nam}<br />Email : ${formdata.get('email')}<br />Contact : ${formdata.get('contact')}<br />Subject : ${formdata.get('title')}<br />Status : ${formdata.get('status')}<br />School : ${formdata.get('schoolnme')}<br />Committee : ${formdata.get('committee')}<br />Priority : ${formdata.get('priority')}<br />Due Date : ${moment(formdata.get('duedate')).format("DD-MM-YYYY hh:mm A")}`

                                    

                                }).then(() => {
                                    // let data = {
                                    //     to: "9918004115@klu.ac.in", subject: "Ticketing System", img: "https://img.icons8.com/color/240/000000/secured-letter--v2.gif",
                                    //     head: `${nam} replied on your ticket`, body: `Ticket Id - #${res.data.Inserted[0].id}<br>Ticket title - ${formdata.get('title')}<br><br>Check all details in your login`
                                    // }
                                    // document.getElementById('sendMailDetails').innerHTML = JSON.stringify(data)
                                    // document.getElementById('sendMailClick').click();

                                    // console.log("Before calling sendTicketNotification");
                                    // sendTicketNotification(sid);
                                    // console.log("After calling sendTicketNotification");
                                    
                                    // SendNotification({ sid: sid, userid: uid, ticket_id: res.data.Inserted[0].id, action: `Ticket created successfully with id - ${res.data.Inserted[0].id}` })

                                    // console.log(res);
                                  
                                    NavigatetoNext(res.data.Inserted[0].id, nam);
                                    // console.log(res.data);
                                    
                                    SendNotification({
                                        sid: sid,
                                        userid: uid,
                                        ticket_id: res.data.Inserted[0].id,
                                        action: `${nam} assigned a ticket to you`,
                                      });
                          
                                })
                            })

                        } else {
                            axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?", formdata
                            ).then(res => {
                                setStafftype("");
                                if (props === "admin") {
                                    axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
                                        fun_name: "TicketHistoryInsert", sid: sid,
                                        ticket_id: parentId, date: formdata.get("date"), committee: formdata.get("committee"),
                                        remark_reply: nam + " created child ticket", reply: 0, staff: nam, status: "Open", uid: uid,
                                        history: nam + " created child ticket", history_details: ""
                                    })
                                }
                                axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
                                    fun_name: "TicketHistoryInsert", sid: sid,
                                    ticket_id: res.data.Inserted[0].id, date: formdata.get("date"), committee: formdata.get("committee"),
                                    remark_reply: nam + " created new ticket", reply: 1, staff: "User", status: "Open", uid: uid,
                                    history: nam + " created new ticket", history_details: `<br />Name of Creator : ${nam}<br />Email : ${formdata.get('email')}<br />Contact : ${formdata.get('contact')}<br />Subject : ${formdata.get('title')}<br />Status : ${formdata.get('status')}<br />School : ${formdata.get('schoolname')}<br />Committee : ${formdata.get('committee')}<br />Priority : ${formdata.get('priority')}<br />Due Date : ${moment(formdata.get('duedate')).format("DD-MM-YYYY hh:mm A")}`
                                }).then(() => {
                                    // let data = {
                                    //     to: "9918004115@klu.ac.in", subject: "Ticketing System", img: "https://img.icons8.com/color/240/000000/secured-letter--v2.gif",
                                    //     head: `${nam} replied on your ticket`, body: `Ticket Id - #${res.data.Inserted[0].id}<br>Ticket title - ${formdata.get('title')}<br><br>Check all details in your login`
                                    // }
                                    // document.getElementById('sendMailDetails').innerHTML = JSON.stringify(data)
                                    // document.getElementById('sendMailClick').click()

                                    // SendNotification({ sid: sid, userid: uid, ticket_id: res.data.Inserted[0].id, action: `Ticket created successfully with id - ${res.data.Inserted[0].id}` })

                                    // console.log(res)
                                    
                                    // SendNotification({
                                    //     sid: sid,
                                    //     userid: uid,
                                    //     ticket_id: res.data.Inserted[0].id,
                                    //     action: `${nam} assigned a ticket to you`,
                                    //   });
                                    NavigatetoNext(res.data.Inserted[0].id, nam);  
                                    
                                    SendNotification({
                                            sid: sid,
                                            userid: uid,
                                            ticket_id: res.data.Inserted[0].id,
                                            action: `${nam} assigned a ticket to you`,
                                          });
                                })
                            })
                        }
                    }
                    else { alert("Please Write Discription") }
                } else { alert("Please Enter Title") }
            } else { alert('Please Choose Priority') }
        } else { alert("Please Choose Committee/Department") }
    }

    return (
        <>
            <div className="totalTicketingModule" style={{ overflow: "hidden", height: "100vh", position: "relative" }}>
                {props === "admin" ? null :
                    <Header
                        title={"Create ticket"}
                        options={[{ title: "Ticket reports", url: `/tickets/user/report/${sid}/${uid}` },
                        { title: "Assigned Tickets", url: `/tickets/user/assigned_tickets/${sid}/${uid}` }
                        ]}
                        ChangePage={(params => history.push(params.data !== undefined ? params.url : params.url, params.data))}
                    />
                }
                <div className="APsidemenu1" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: progress > 0 ? "100%" : "0%", padding: 0, backgroundColor: "rgba(0,0,0,0.4)" }}>
                    <div className="APquiz-card">
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                            <h3 style={{ fontSize: 20, fontWeight: "bold", color: "blue" }}>File Uploading ...</h3><br></br>
                            <div style={{ width: "170px", height: "170px" }}>
                                <CircularProgressbar
                                    value={progress}
                                    text={`${progress}%`}
                                    circleRatio={0.75}
                                    styles={buildStyles({
                                        rotation: 1 / 2 + 1 / 8,
                                        trailColor: "#eee",
                                        pathTransition: "none",
                                        pathColor: progress > 20 ? "#4CC417" : "red",
                                        textColor: progress > 20 ? "#4CC417" : "red",
                                        textSize: "20px"
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <h4 style={{ position: "absolute", textAlign: "center", fontWeight: "500", color: "lightblue", bottom: "5px" }}>Please wait after file upload you will automatically redirected</h4>
                </div>
                <div>
                    {userDetails === "" || Stafftype === "" || StaffDetails === "" ?
                        <div className="TMLoader">
                            <Loader
                                type="Puff"
                                color="#1e5597"
                                height={70}
                                width={70}
                            />
                        </div> :
                        <div style={{ overflow: "scroll", height: "100vh" }}>
                            <div style={{ marginBottom: "300px" }}>
                                <form className="TMRequestMainDiv">
                                    <div className="TMRequestSubMainDiv1">
                                        <div>
                                            <label>Committee/Department</label>
                                            <select name="committee" defaultValue={props === "update" ? history.location.state.committee : ""} onChange={(e) => { setCommittee(e.target.value) }}>
                                                <option value="">Choose</option>
                                                {Stafftype.map((i, k) => {
                                                    return (
                                                        <option key={k} value={i}>{i}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div>
                                            <label> Staff Name </label>
                                            <select name="staff"
                                                onChange={
                                                    (e) => {
                                                        if (e.target.value === "") {
                                                            setStaff_Id({ staff: "", staff_id: "" })
                                                        } else {
                                                            const sdata = JSON.parse(e.target.value)
                                                            setStaff_Id({ staff: sdata.staff, staff_id: sdata.staff_id })
                                                        }
                                                    }
                                                } >
                                                <option value="">Unassigned</option>

                                                {
                                                    Committee !== "" ?
                                                        StaffDetails[Committee].map((i, k) => {
                                                            return (
                                                                <option key={k} value={JSON.stringify({ staff: i.first_name, staff_id: i.staff_id })} >{i.first_name} {i.last_name}</option>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </select>
                                        </div>
                                        <div>
                                            <label>Priority</label>
                                            <select name="priority" defaultValue={props === "update" ? history.location.state.priority : ""} onChange={() => { }}>
                                                <option value="">Choose</option>
                                                <option value="High">High</option>
                                                <option value="Medium">Medium</option>
                                                <option value="Low">Low</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label>File Upload</label>
                                            <input id="upload" name="file" type="file" style={{ display: "block" }} onChange={(e) => { setFileError(false); fileSize(e) }} multiple />
                                            {
                                                fileError ?
                                                    <span style={{ color: "red", fontWeight: 500, fontSize: "13px" }}>Please Choose Files Less Than 10MB</span>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <div className="TMRequestSubMainDiv2">
                                        <div>
                                            <label>Title</label>
                                            <input name="title" type="text" defaultValue={props === "update" ? history.location.state.title : null} />
                                        </div>
                                        <div>
                                            <label>Description</label>
                                            <textarea name="description" rows={10} defaultValue={props === "update" ? history.location.state.description : null} />
                                        </div>
                                    </div>
                                </form>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "20px", marginBottom: "100px" }}>
                                    <div className="TMSubmitButton" onClick={() => { fileError ? alert("Please Choose Files Less Than 10MB") : CreateRequest() }}>
                                        {props === "update" ? "Update" : "Create"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
// axios.post("http://localhost:8080/raiseticket", {id:"",committee:formdata.get('committee'),file:formdata.get('file'),discription:formdata.get('discription'),status:"Open",priority:formdata.get('priority'),title:formdata.get('title'),duedate:formdata.get('duedate'),date:formdata.get('date'),school:formdata.get('school'),name:formdata.get('name'),email:formdata.get('email'),contact:formdata.get('contact'),uid:uid,remark:""},
                            //     { onUploadProgress: ProgressEvent => { setprogress(Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)) } }
                            // ).then(res => {
                            //     axios.post("http://localhost:8080/inserthistory", {
                            //         id: "",
                            //         ticket_id: res.data.insertId, date: formdata.get("date"), committee: formdata.get("committee"),
                            //         remark_reply: formdata.get("name") + " created new ticket",reply:1, staff: "User", status: "Open",
                            //         history: formdata.get("name") + " created new ticket",history_details:`<br />Name of Creator : ${formdata.get('name')}<br />Email : ${formdata.get('email')}<br />Contact : ${formdata.get('contact')}<br />Subject : ${formdata.get('title')}<br />Status : ${formdata.get('status')}<br />School : ${formdata.get('school')}<br />Committee : ${formdata.get('committee')}<br />Priority : ${formdata.get('priority')}<br />Due Date : ${moment(formdata.get('duedate')).format("DD-MM-YYYY hh:mm A")}`
                            //     }).then(() => {
                            //         history.push(`/tickets/user/report/${sid}/${uid}`)
                            //     })
                            // })