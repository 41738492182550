import React from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import Details from '../ReusedComponents/Details';

export default function AppointmentDetails() {
    const history = useHistory();
    const { sid, uid } = useParams();

    const ApproveRequest = (status) => {
        if (status === true) {
            if (history.location.state.requestStatus !== "Pending" && history.location.state.requestStatus !== "Not available") {
                axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Update_SuperAStatus", sid: history.location.state.s_id, ...(history.location.state), id: parseInt(history.location.state.id) }).then(() => {
                    history.push(`/appointment/superadmin/${sid}/${uid}`)
                })
            } else { alert("Please Book Slot") }
        } else {
            axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Update_SuperAStatus", sid: history.location.state.s_id, ...(history.location.state), id: parseInt(history.location.state.id), requestStatus: "Not available", participants: "" }).then((res) => {
                history.push(`/appointment/superadmin/${sid}/${uid}`)
            })
        }
    }

    return (
        <Details data={history.location.state} type={"superadmin"} sid={sid} uid={uid} ApproveRequest={(params) => { ApproveRequest(params) }} />
    );
}
