import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import Swal from "sweetalert2";

const CreateForm = ({ fields, setFields, formdata, setapiLoader }) => {

  const removeLastObject = () => {
    const newArray = fields.slice(0, -1);
    setFields(newArray);
  };


  const SubmitForm = async (e) => {
    e.preventDefault();
    setapiLoader(true);
    try {
      //  const data = formdata
      if (!formdata.sid || !formdata.FormTitle || !formdata.startDate || !formdata.endDate || !formdata.acadYear || !fields) {
       throw "All Fields are required"
      }
      const data = {
        "sid": formdata.sid,
        "FormTitle": formdata.FormTitle,
        "startDate": formdata.startDate,
        "endDate": formdata.endDate,
        "acadYear": formdata.acadYear,
        "form_data": fields
      }
      await axios({
        method: "POST", 
       // url: `http://localhost:8080/api/information/create/dynamicform`,
        url: `${process.env.REACT_APP_BASE_URL}/api/information/create/dynamicform`,
        data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      })
        .then((response) => {
          setapiLoader(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });

        })
        .catch((error) => {
          setapiLoader(false);
          Swal.fire({
            position: "center",
            icon: "warning",
            title: error,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } catch (error) {
      setapiLoader(false);
      Swal.fire({
        position: "center",
        icon: "warning",
        title: error,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  return (
    <div className='card mt-4'>
      <div className="card-body">
        <form onSubmit={SubmitForm}>
          {fields.length > 0 ? (
            <>
              {fields.map((field, index) => (
                <div key={index} className='form-group'>
                  {field.type === 'label' ? (
                    <h5 className='text-center'>{field.Title}</h5>
                  ) : (
                    <>
                      <label htmlFor={`input${index}`}>{field.Title}</label>
                      <input
                        name={`fields[${index}].name`}
                        placeholder={`Enter ${field.Title}`}
                        type={field.type}
                        defaultValue={""}
                        className="form-control"
                        id={`input${index}`}
                      />
                    </>
                  )}
                </div>
              ))}

              <DeleteIcon
                className='text-danger'
                onClick={removeLastObject}
                style={{ cursor: "pointer" }}
              />
              <input
                type="submit"
                className='form-controll btn btn-primary mt-0'
                value={"Create Form"}
              />
            </>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default CreateForm;
