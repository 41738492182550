import React, { useState, useEffect, useRef } from "react";
import Header from "../ReusedComponents/Header";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import moment from "moment";
import ReplyLeftTab from "./ReplyLeftTab";
import TMRHistory from "./TMRHistory";
import TMUserTrack from "./TMUserTrack";
import { SendNotification } from "./SendNotification";
import RequestTicket from "../User/RequestTicket";
import TicketReport from "../User/TicketReport";
import { cryptoED } from "./cryptoED";
import { useReactToPrint } from "react-to-print";
import useAutosizeTextArea from "./useAutosizeTextArea";

export default function AdminTicket(props) {
  // console.log(props.userType);
  const history = useHistory();
  const name = history.location.state.username || "Unknown";
  const { sid, uid, ticket_id, ssid } = useParams();
  const [Reply, setReply] = useState(false);
  const [ReplyClose, setReplyClose] = useState(false);
  const [Details, setDetails] = useState(false);
  const [ReplyData, setReplyData] = useState([]);
  const [Historydata, setHistorydata] = useState("");
  const [TicketDetails, setTicketDetails] = useState("");
  const [Stafftype, setStafftype] = useState("");
  const [StaffDetails, setStaffDetails] = useState("");
  const [selectedOption, setSelectedOption] = useState("Chat");
  const [UserDetails, setUserDetails] = useState("");

  const [value, setValue] = useState("");

  const chatRef = useRef(null);

  const generatePdf = useReactToPrint({
    content: () => chatRef.current,
    documentTitle: `chats ${moment().format("DD/MM/YYYY")}`,
  });

  const textAreaRef = useRef(null);

  useAutosizeTextArea(textAreaRef.current, value);

  const handleChange = (evt) => {
    const val = evt.target.value;

    setValue(val);
  };

  useEffect(() => {
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        fun_name: "AUserInfo",
        sid: ssid || sid,
        u_id: uid,
      })
      .then((res) => {
        setUserDetails(res.data);
      });
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        fun_name: "GetStaffDetails",
        sid: sid,
      })
      .then((res) => {
        var a = [];
        res.data.Staff_Details.map((i) => {
          if (a.length > 0 ? a[0][i.type] === undefined : true) {
            a.length === 0 ? a.push({ [i.type]: [i] }) : (a[0][i.type] = [i]);
          } else {
            a[0][i.type].push(i);
          }
        });
        setStafftype(Object.keys(a[0]));
        setStaffDetails(a[0]);
      });
  }, []);

  useEffect(() => {
    setHistorydata("");
    setReplyData("");
    setTicketDetails("");
    setSelectedOption("Chat");
    getTicketCreated(history.location.state.id);
    getTicketHistory(history.location.state.id);
  }, [ticket_id]);

  function getTicketHistory(id) {
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        fun_name: "GetTicketHistory",
        sid: sid,
        ticket_id: id,
      })
      .then((res) => {
        setReplyData([...res.data.Ticket_History].reverse());
        // console.log([...res.data.Ticket_History].reverse());
        setHistorydata([...res.data.Ticket_History].reverse());
        // ScrollBottom(1000)
      });
  }

  function getTicketCreated(id) {
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        fun_name: "GetTicketCreate",
        sid: sid,
        id: id,
      })
      .then((res) => {
        setTicketDetails(res.data.Tickets);
        // ScrollBottom(1000)
      });
  }

  function ScrollBottom(time) {
    setTimeout(() => {
      if (document.getElementById("TMChat") !== null) {
        document.getElementById("TMChat").scrollBehavior = "smooth";
        document.getElementById("TMChat").scrollTop = document.getElementById(
          "TMChat"
        ).scrollHeight;
      }
    }, time);
  }

  function updateTicket(data) {
    setTicketDetails("");
    const d = { nooffiles: 0, ...TicketDetails[0], ...data.data };
    if (data.data.status === "Closed") {
      d.closed_by = name;
      d.staffClosed_uid = uid;
    }
    const fm = new FormData();
    if (data.data.file) {
      for (let i = 1; i < data.data.nooffiles; i++) {
        fm.append("file" + i, d.file[i]);
      }
    }
    if (d.nooffiles === 0) {
      Object.keys(d).map((i) => {
        if (i !== "duedate1") {
          fm.append(i === "school" ? "schoolname" : i, d[i]);
        }
      });
    } else {
      Object.keys(d).map((i) => {
        if (i !== "duedate1") {
          if (i === "file") {
            fm.append("file0", d.file[0]);
          } else {
            fm.append(i === "school" ? "schoolname" : i, d[i]);
          }
        }
      });
    }
    fm.append("fun_name", "Update_Ticket_Create");
    fm.append("sid", sid);
    const insertHistory = axios.post(
      "https://topschool.prisms.in/rest/index.php/user_list.json?",
      {
        fun_name: "TicketHistoryInsert",
        sid: sid,
        ticket_id: history.location.state.id,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        committee: TicketDetails[0].committee,
        remark_reply: "",
        reply: 0,
        staff: name,
        status:
          data.data.status !== undefined
            ? data.data.status
            : TicketDetails[0].status,
        history: `${name} updated the ticket`,
        history_details: data.history,
        uid: uid,
        is_sadmin: ssid ? 1 : 0,
      }
    );
    if (d.status === "Closed") {
      fm.delete("closed_tickets");
      axios
        .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
          fun_name: "GetTicketHistory",
          sid: sid,
          staffClosed_uid: uid,
        })
        .then((res) => {
          fm.append(
            "closed_tickets",
            res.data.Ticket_History[0].closed_tickets
          );
          const updateticket = axios.post(
            "https://topschool.prisms.in/rest/index.php/user_list.json?",
            fm
          );
          axios.all([updateticket, insertHistory]).then(() => {
            SendNotification({
              sid: sid,
              userid: TicketDetails[0].uid,
              ticket_id: TicketDetails[0].id,
              action: `${name} closed your ticket`,
            });
            getTicketCreated(history.location.state.id);
            getTicketHistory(history.location.state.id);
          });
        });
    } else {
      const updateticket = axios.post(
        "https://topschool.prisms.in/rest/index.php/user_list.json?",
        fm
      );
      axios.all([updateticket, insertHistory]).then(() => {
        if (TicketDetails[0].staff !== data.data.staff) {
          SendNotification({
            sid: sid,
            userid: TicketDetails[0].uid,
            ticket_id: TicketDetails[0].id,
            action: `${name} assigned a ticket to you`,
          });
        }
        getTicketCreated(history.location.state.id);
        getTicketHistory(history.location.state.id);
      });
    }
  }

  function InsertTicketHistory(reply) {
    if (ReplyClose)
      updateTicket({
        data: { status: "Closed" },
        history: `Status changed from ${TicketDetails[0].status} to Closed`,
      });
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        fun_name: "TicketHistoryInsert",
        sid: sid,
        ticket_id: history.location.state.id,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        committee: TicketDetails[0].committee,
        remark_reply: reply,
        reply: 1,
        staff: name,
        status: ReplyClose ? "Closed" : TicketDetails[0].status,
        history: `${name} replied on ticket`,
        history_details: "",
        uid: uid,
        is_sadmin: ssid ? 1 : 0,
      })
      .then(() => {
        SendNotification({
          sid: sid,
          userid: TicketDetails[0].uid,
          ticket_id: TicketDetails[0].id,
          action: `${name} replied on your ticket`,
        });
        getTicketHistory(history.location.state.id);
      });
  }

  function NavigatorwithID(id) {
    setHistorydata("");
    setReplyData("");
    setTicketDetails("");
    setSelectedOption("Chat");
    ssid
      ? history.push(
          `/tickets/superadmin/details/${ssid}/${sid}/${uid}/${id}`,
          { ...history.location.state, id: id }
        )
      : history.push(`/tickets/${props.userType}/details/${sid}/${uid}/${id}`, {
          ...history.location.state,
          id: id,
        });
  }
  // useEffect(()=>{console.log(ReplyData)},[ReplyData])

  // console.log(ReplyData)

  return (
    <>
      <div
        className="totalTicketingModule"
        style={{ overflow: "hidden", height: "100vh" }}
      >
        <Header
          title={props.title}
          options={props.options}
          ChangePage={(params) =>
            history.push(
              params.data !== undefined ? params.url : params.url,
              params.data
            )
          }
        />
        {ReplyData === "" ||
        TicketDetails === "" ||
        Stafftype === "" ||
        StaffDetails === "" ||
        UserDetails === "" ? (
          <div className="TMLoader">
            <Loader type="Puff" color="#1e5597" height={70} width={70} />
          </div>
        ) : (
          <div>
            <div className="TMTicketDivResponsive">
              <div className="TMDetailsMainDiv">
                <div className="TMDetailsLeft">
                  <div className="TMDetailsLeftTop">
                    <ReplyLeftTab
                      data={TicketDetails}
                      updateTicket={(params) => {
                        updateTicket(params);
                      }}
                      userType={props.userType}
                      Stafftype={Stafftype}
                      StaffDetails={StaffDetails}
                      NavigatorwithID={NavigatorwithID}
                      sid={sid}
                      parentId={ticket_id}
                    />
                  </div>
                </div>
                <div className="TMDetailsRight">
                  <div style={{}}>
                    <span
                      className="TMTR__Title"
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      #{TicketDetails[0].id} - {TicketDetails[0].title}
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "grey",
                      }}
                    >
                      Created on{" "}
                      {moment(TicketDetails[0].date).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </span>
                    <div className="TMDetailsReplyButtons">
                      <div
                        style={{
                          borderBottom:
                            selectedOption === "Chat"
                              ? "3px solid rgb(30, 85, 151)"
                              : "3px solid white",
                        }}
                        onClick={() => {
                          setSelectedOption("Chat"); /* ScrollBottom(1000)*/
                        }}
                      >
                        Chat
                      </div>
                      {props.userType === "admin" ||
                      props.userType === "user" ? (
                        <>
                          <div
                            style={{
                              borderBottom:
                                selectedOption === "History"
                                  ? "3px solid rgb(30, 85, 151)"
                                  : "3px solid white",
                            }}
                            onClick={() => {
                              setSelectedOption("History");
                            }}
                          >
                            History
                          </div>
                        </>
                      ) : (
                        <div
                          style={{
                            borderBottom:
                              selectedOption === "Track"
                                ? "3px solid rgb(30, 85, 151)"
                                : "3px solid white",
                          }}
                          onClick={() => {
                            setSelectedOption("Track");
                          }}
                        >
                          Track
                        </div>
                      )}
                      <div
                        style={{
                          borderBottom:
                            selectedOption === "Tickets"
                              ? "3px solid rgb(30, 85, 151)"
                              : "3px solid white",
                        }}
                        onClick={() => {
                          setSelectedOption("Tickets"); /* ScrollBottom(1000) */
                        }}
                      >
                        Child Tickets
                      </div>
                      <div
                        className="TMMobileDetailsButton"
                        onClick={() => {
                          setDetails(true);
                        }}
                      >
                        Details
                      </div>
                    </div>
                  </div>
                  {selectedOption === "Chat" ? (
                    <>
                      <div
                        id="TMChat"
                        style={{ overflow: "scroll", height: "100vh" }}
                        ref={chatRef}
                      >
                        <div
                          className="w-100 card"
                          style={{ backgroundColor: "#dbfde6" }}
                        >
                          <h4
                            style={{
                              margin: "15px 0px 0px 0px",
                              fontWeight: "600",
                            }}
                            className="card-title"
                          >
                            Ticket Description &nbsp;{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                color: "grey",
                                fontWeight: "500",
                              }}
                            >
                              {moment(TicketDetails[0].date).format(
                                "DD-MM-YYYY hh:mm:ss A"
                              )}
                            </span>
                          </h4>
                          <div className="">
                            <div
                              // style={{
                              //   borderRadius: "0px 10px 10px 10px",
                              //   margin: "10px",
                              //   position: "relative",
                              // }}
                              className="card-text"
                            >
                              <i>{TicketDetails[0].description}</i>
                              {/* <div
                                style={{
                                  position: "absolute",
                                  width: "10px",
                                  height: "10px",
                                  padding: "0px",
                                  backgroundColor: "#ade6e6",
                                  borderRadius: "0px 0px 0px 20px",
                                  left: "0",
                                  top: "0",
                                  margin: "-2px -7px",
                                  boxShadow: "none",
                                  border: 0,
                                  borderLeft:
                                    "2px solid rgba(245, 245, 245, 0.3)",
                                  borderTop:
                                    "2px solid rgba(245, 245, 245, 0.3)",
                                }}
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div style={{ marginBottom: "300px" }}>
                          {ReplyData.map((i, k) => {
                            if (i.reply === "1") {
                              return (
                                <div
                                  key={k}
                                  style={{
                                    display: "flex",
                                    alignItems:
                                      k === 0
                                        ? "flex-start"
                                        : ssid
                                        ? i.s_id === cryptoED(ssid) &&
                                          i.uid === cryptoED(uid)
                                          ? "flex-end"
                                          : "flex-start"
                                        : i.uid === cryptoED(uid)
                                        ? "flex-end"
                                        : "flex-start",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <>
                                    <h4 style={{ margin: "15px 0px 0px 0px" }}>
                                      {i.uid === cryptoED(uid)
                                        ? ""
                                        : i.staff !== "User"
                                        ? i.staff
                                        : ""}
                                      &nbsp;{" "}
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "grey",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {i.staff !== "User" ? i.date1 : ""}
                                      </span>
                                    </h4>
                                    {i.staff !== "User" ? (
                                      <div className="TMDetailsMessage">
                                        <div
                                          style={{
                                            borderRadius:
                                              i.uid !== cryptoED(uid)
                                                ? "0px 15px 15px 15px"
                                                : "15px 0px 15px 15px",
                                            margin: "10px",
                                            position: "relative",
                                          }}
                                        >
                                          {i.remark_reply && (
                                            <>
                                              {i.uid === cryptoED(uid) && (
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    width: "10px",
                                                    height: "10px",
                                                    padding: "0px",
                                                    backgroundColor: "#ade6e6",
                                                    right:
                                                      i.uid !== cryptoED(uid)
                                                        ? "0"
                                                        : "unset",
                                                    left:
                                                      i.uid === cryptoED(uid)
                                                        ? "0"
                                                        : "unset",
                                                    top: "0",
                                                    margin: "-2px -7px",
                                                    boxShadow: "none",
                                                    border: 0,
                                                    borderRight:
                                                      i.uid !== cryptoED(uid)
                                                        ? "2px solid rgba(245, 245, 245, 0.3)"
                                                        : "unset",
                                                    borderLeft:
                                                      i.uid === cryptoED(uid)
                                                        ? "2px solid rgba(245, 245, 245, 0.3)"
                                                        : "unset",
                                                  }}
                                                />
                                              )}
                                              {i.staff !== "User" &&
                                                i.remark_reply}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    ) : null}
                                  </>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </div>
                      </div>
                      {TicketDetails[0].status !== "Closed" ? (
                        <div className="TMDetailsRightButtons">
                          {!Reply &&
                          (props.userType === "user" ||
                            props.userType === "admin") ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                className="TMSubmitButton"
                                onClick={() => {
                                  setReply(true);
                                  setReplyClose(false);
                                }}
                              >
                                Reply
                              </div>
                              <div
                                className="TMSubmitButton"
                                onClick={() => {
                                  setReply(true);
                                  setReplyClose(true);
                                }}
                              >
                                Reply and Close
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                              }}
                            >
                              <textarea
                                id="replytext"
                                placeholder="Enter your reply here..."
                                ref={textAreaRef}
                                onChange={handleChange}
                                rows={1}
                                value={value}
                                style={{ padding: 5 }}
                                autoFocus
                              />
                              <img
                                onClick={() => {
                                  if (
                                    document.getElementById("replytext").value
                                  ) {
                                    if (
                                      document
                                        .getElementById("replytext")
                                        .value.replaceAll(" ", "")
                                        .replaceAll("\n", "").length > 0
                                    ) {
                                      InsertTicketHistory(
                                        document.getElementById("replytext")
                                          .value
                                      );
                                      setReplyData("");
                                      setValue("");
                                    } else {
                                      document.getElementById(
                                        "replytext"
                                      ).value = "";
                                      setValue("");
                                      document
                                        .getElementById("replytext")
                                        .focus();
                                      alert("Please enter something");
                                    }
                                  }
                                }}
                                src="/icons/reply.png"
                                alt={""}
                              />
                              <button
                                className="btn btn-primary mt-0"
                                onClick={() => generatePdf()}
                              >
                                Print
                              </button>
                              {props.userType === "admin" ||
                              props.userType === "user" ? (
                                <span
                                  onClick={() => {
                                    setReply(false);
                                  }}
                                >
                                  ✖
                                </span>
                              ) : null}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="TMDetailsRightButtons">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <b>Ticket Closed</b>
                          </div>
                        </div>
                      )}
                    </>
                  ) : selectedOption === "History" ? (
                    <TMRHistory data={Historydata} />
                  ) : selectedOption === "Track" ? (
                    <TMUserTrack data={ReplyData} />
                  ) : selectedOption === "Child" ? (
                    <>
                      <RequestTicket
                        props={"admin"}
                        userData={UserDetails}
                        staffData={Stafftype}
                        staffDetails={StaffDetails}
                        parentId={history.location.state.id}
                        onCreateNew={NavigatorwithID}
                      />
                      <div
                        className="TMDetailsRightButtons"
                        style={{ padding: "10px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            className="TMSubmitButton"
                            onClick={() => {
                              setSelectedOption("Tickets");
                            }}
                          >
                            <b>View Child Tickets</b>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : selectedOption === "Tickets" ? (
                    <>
                      <TicketReport
                        props={"admin"}
                        TicketData={history.location.state.TicketData}
                        parentId={history.location.state.id}
                        setID={NavigatorwithID}
                      />

                      {props.userType === "admin" ||
                      props.userType === "user" ? (
                        <div
                          className="TMDetailsRightButtons"
                          style={{ padding: "10px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <div
                              className="TMSubmitButton"
                              onClick={() => {
                                setSelectedOption("Child");
                              }}
                            >
                              <b>Create Child Ticket</b>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="TMDetailsDetailsPopup"
              style={{ width: Details ? "100%" : "0px", zIndex: 100 }}
            >
              <Header
                title={props.title}
                options={props.options}
                ChangePage={(params) =>
                  history.push(
                    params.data !== undefined ? params.url : params.url,
                    params.data
                  )
                }
              />
              <div
                className="TMDetailsCloseButton"
                onClick={() => {
                  setDetails(false);
                }}
              >
                ✖
              </div>
              <div style={{ overflow: "scroll", height: "100vh" }}>
                <div
                  className="TMDetailsLeftTop"
                  style={{ width: "90%", padding: "10px" }}
                >
                  <ReplyLeftTab
                    data={TicketDetails}
                    updateTicket={(params) => {
                      updateTicket(params);
                    }}
                    userType={props.userType}
                    Stafftype={Stafftype}
                    NavigatorwithID={NavigatorwithID}
                    StaffDetails={StaffDetails}
                    sid={sid}
                    parentId={ticket_id}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
