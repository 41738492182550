import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import axios from 'axios';
import Swal from 'sweetalert2';

export default function QuizCard() {
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <Quiz />
    </div>
  )
}

function Quiz() {

  const [seconds, setseconds] = useState(-1);
  const [questionNo, setquestionNo] = useState(0);
  const [mins, setmins] = useState(-1);
  const [totalmarks, setTotalmks] = useState(0)
  const [timetaken, setTimetaken] = useState(0);
  const history = useHistory();
  const { sid, uid } = useParams();
  const [answers, setanswers] = useState([{ isOption1: "0", isOption2: "0", isOption3: "0", isOption4: "0" }])
  const [loader, setLoader] = useState(true);
  const [FullScreenloader, setFullScreenLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(true);
  const [validate, setValidate] = useState(false);
  const [questions, setQuestions] = useState([{ questionTitle: "Questions Not Created Yet" }]);
  const [is1, setis1] = useState('white')
  const [is2, setis2] = useState('white')
  const [is3, setis3] = useState('white')
  const [is4, setis4] = useState('white')
  const [rqno, setrqno] = useState([0]);
  const fillRange = (start, end) =>
    [...Array(end - start + 1)].map((item, index) => start + index);

  useEffect(() => {
    let isCancelled = false;
    if (history.location.state !== undefined) {
      axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Quizapp_Getquestion", sid: sid, quizid: history.location.state.id })
        .then((res) => {
          if (!isCancelled) {
            try {
              res.data.Questions = res.data.Questions.filter(i => i.isdeleted === "0");
              setQuestions(res.data.Questions)
              if (sessionStorage.questionno === undefined) {
                setanswers(new Array((res.data.Questions).length).fill({ isOption1: "0", isOption2: "0", isOption3: "0", isOption4: "0" }))
              } else { setanswers(JSON.parse(sessionStorage.getItem("answers_given"))); setquestionNo(parseInt(sessionStorage.questionno)) }
              setTotalmks(res.data.Questions.reduce((acc, i) => {
                return acc + parseInt(i.mark);
              }, 0))
              if (sessionStorage.rqno === undefined) {
                let fillarray = fillRange(0, (res.data.Questions).length - 1);
                const randa = history.location.state.questionOrder !== "sequential" ? fillarray.sort(() => Math.random() - 0.5) : fillarray;
                setrqno(randa)
                sessionStorage.setItem("rqno", JSON.stringify(randa))
              } else {
                setrqno(JSON.parse(sessionStorage.rqno))
              }
              sessionStorage.mins === undefined || sessionStorage.mins === "-1" ? setmins((history.location.state.duration) - 1) : setmins(sessionStorage.mins)
              sessionStorage.seconds === undefined || sessionStorage.seconds === "-1" ? setseconds(59) : setseconds(sessionStorage.seconds)
              setLoader(false)
            } catch (e) {
              console.error(e);
            }
          }
        })

      return () => isCancelled = true;
    }
  }, []);

  useEffect(() => {
    try {
      if (history.location.state !== undefined && history.location.state.duration !== 0) {
        setTimetaken((parseFloat("" + history.location.state.duration + ".00") - parseFloat('' + mins + '.' + seconds)).toFixed(2))
        if (mins === 0 && seconds === 0) {
          Swal.fire("Warning", "TimeUp", "error");
          marksCalulator()
        }
        if (seconds !== -1 && mins !== -1) {
          sessionStorage.setItem("seconds", seconds);
          setTimeout(() => { setseconds(seconds !== 0 ? seconds - 1 : 59) }, 1000)

          if (seconds === 0) {
            sessionStorage.setItem("mins", mins - 1);
            setmins(mins - 1)
          }

        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [seconds])


  function marksCalulator() {
    try {
      setFullScreenLoader(true)
      let correct = 0, wrong = 0;
      const score = answers.reduce((acc, i, k) => {
        if (questions[k].questionType === "4") return acc;
        else {
          if (i.isOption1 === questions[k].isOption1 && i.isOption2 === questions[k].isOption2 && i.isOption3 === questions[k].isOption3 && i.isOption4 === questions[k].isOption4) {
            correct += 1;
            answers[0].correct = correct;
            return acc + parseInt(questions[k].mark);
          } else {
            if (i.isOption1 === "1" || i.isOption2 === "1" || i.isOption3 === "1" || i.isOption4 === "1") {
              wrong++;
              answers[0].wrong = wrong;
              if (history.location.state.negativeMarking === "yes") return acc - parseInt(questions[k].negativ_mark);
              return acc
            } else {
              return acc;
            }
          }
        }
      }, 0)

      total(score, correct, wrong)
    } catch (e) {
      console.error(e);
    }
  }


  function total(s, c, w) {
    try {
      sessionStorage.clear();
      if (history.location.state !== undefined) {
        const reviewQuizApi = axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', {
          fun_name: "Quizapp_Get_QuizReview",
          sid: sid,
          userid: uid,
          quizid: history.location.state.id
        }).then(res => {
          axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
            {
              fun_name: res.data.review.length === 0 ? "Addreview" : "UpdateReview",
              sid: sid,
              answers: JSON.stringify(answers),
              quizid: history.location.state.id,
              userid: uid,
              correct: c,
              status: validate ? "pending" : "",
              totalquestions: questions.length
            })
        })
        const QuizResultApi = axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
          {
            fun_name: history.location.state.ioru !== "insert" ? "UpdateQuizResult" : "AddQuizResult",
            sid: sid,
            quizid: history.location.state.id,
            userid: history.location.state.userid,
            score: s,
            time: timetaken,
            status: validate ? "pending" : "",
            attempts: history.location.state.attempts === "0" ? "0" : history.location.state.ioru === "insert" ? 1 : parseInt(history.location.state.ioru.attempts) + 1,
            quizName: history.location.state.quizName,
            total: totalmarks,
            studentName: history.location.state.studentName
          })

        axios.all([reviewQuizApi, QuizResultApi])
          .then(() => { history.push('/quizapp/leaderboard' + '/' + sid + '/' + uid, { score: s, time: timetaken, his: history.location.state, total: totalmarks, validate: validate }) })
      }
    } catch (err) {
      console.log(err)
    }
  }


  if (sessionStorage.lpath === `/quizapp/leaderboard/${sid}/${uid}`) {
    try {
      sessionStorage.clear();
      return <Redirect to={'/quizapp' + '/' + sid + '/' + uid} />
    } catch (e) {
      console.error(e);
    }
  }


  function selected(qno) {
    try {
      sessionStorage.setItem("answers_given", JSON.stringify(answers));
      sessionStorage.setItem("questionno", qno);
      setis1(answers[qno].isOption1 === "1" ? '#93E6F6' : "white")
      setis2(answers[qno].isOption2 === "1" ? '#93E6F6' : "white")
      setis3(answers[qno].isOption3 === "1" ? '#93E6F6' : "white")
      setis4(answers[qno].isOption4 === "1" ? '#93E6F6' : "white")
    } catch (e) {
      console.error(e);
    }
  }


  function answerCheck(id, qno) {
    try {
      if (id === "clearSelected") {
        answers[qno] = {
          isOption1: "0",
          isOption2: "0",
          isOption3: "0",
          isOption4: "0"
        }
        selected(qno);
      } else if (questions[qno].questionType === "2") {
        answers[qno] = { ...answers[qno], [id]: answers[qno][id] === "0" ? "1" : "0" };
        selected(qno);
      } else {
        answers[qno] = {
          isOption1: id === "isOption1" ? "1" : "0",
          isOption2: id === "isOption2" ? "1" : "0",
          isOption3: id === "isOption3" ? "1" : "0",
          isOption4: id === "isOption4" ? "1" : "0"
        }
        selected(qno);
      }
    } catch (e) {
      console.error(e);
    }
  }


  function shortAnswerSave(button) {
    try {
      answers[rqno[questionNo]] = { isOption1: document.getElementById("quiz__shortanswer").value, isOption2: "0", isOption3: "0", isOption4: "0" }
      setValidate(true);

      if (button === "back") {
        setquestionNo(questionNo - 1); selected(rqno[questionNo - 1]); setImageLoader(true);
      } else if (button === "next") {
        setquestionNo(questionNo + 1); selected(rqno[questionNo + 1]); setImageLoader(true);
      } else {
        marksCalulator()
      }
    } catch (e) {
      console.error(e);
    }
  }


  return (
    <div className="quiz-container">
      {
        FullScreenloader ?
          <div id="loader">
            <Loader
              type="Oval"
              color="#0066ff"
              height={70}
              width={70}
            />
            <br /><br />
            <div style={{ color: "#0066ff", textAlign: "center", fontSize: "19px" }}>
              <b style={{fontFamily:'Poppins'}}>Calculating Results...</b><br /><br />
              <i style={{fontFamily:'Poppins'}}>Please wait it takes few seconds</i>
            </div>
          </div>

          :
          <>
            <div className="quiz-head">Quiz</div>
            <hr className="hr-line" />
            <div>

              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "30px", marginRight: "30px" }}>
                <label>{history.location.state.quizName}</label>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <label style={{fontFamily:'Poppins'}}>Question:{questionNo + 1}/{questions.length}</label>
                {history.location.state.duration <= 0 ? null :
                  seconds === -1 ?
                    <label style={{fontFamily:'Poppins'}}>Time Remaining:{history.location.state.duration}m:00s</label> :
                    <label style={{fontFamily:'Poppins'}}>Time Remaining:{mins}m:{seconds.toString().length === 1 ? 0 : null}{seconds}s</label>
                }
              </div>

              <div style={{ maxHeight: "75vh", overflow: "auto", marginTop: "10px" }}>

                {
                  loader ?
                    <div id="loader">
                      <Loader
                        type="Oval"
                        color="#0066ff"
                        height={70}
                        width={70}
                      /></div>
                    : questions.length === 0 ?
                      <div id="loader" style={{ marginBottom: "100px", color: "rgba(4, 44, 173, 0.856)", fontWeight: "500", textAlign: "center", fontFamily:'Poppins' }}>
                        Questions not found
                      </div>
                      : <div style={{ width: "100%", marginBottom: "100px" }}><br></br>
                        <div style={{ backgroundColor: "white", padding: "10px", borderRadius: "7px", marginBottom: "30px" }}>{questions[rqno[questionNo]].questionTitle}<br></br>{questions[rqno[questionNo]].additionalText}<div style={{ float: 'right', color: "red" }}>* {questions[rqno[questionNo]].mark} {questions[rqno[questionNo]].mark > 1 ? "marks" : "mark"}</div><br></br></div>
                        {questions[rqno[questionNo]].image !== "" ?
                          <div style={{ display: "flex", alignItems: 'center', justifyContent: "center", marginBottom: 5, minHeight: "200px", maxHeight: "315px", width: "100%", position: "relative", border: 0 }}>
                            <img src={questions[rqno[questionNo]].image} onLoad={() => { setImageLoader(false) }}
                              style={{ maxHeight: "300px", maxWidth: "100%", position: "relative", border: 0 }}
                              alt="Image Loading... Please Wait..."
                            />
                            <div style={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", opacity: imageLoader ? 1 : 0, border: 0, padding: 5 }}>
                              <Loader
                                type="Oval"
                                color="red"
                                height={50}
                                width={50}
                              />
                            </div>
                          </div> : null
                        }
                        {
                          questions[rqno[questionNo]].questionType === "4" ?
                            <textarea
                              id="quiz__shortanswer"
                              className="quiz__card__textarea"
                              rows="10"
                              placeholder="Enter your answer here..."
                              defaultValue={answers[rqno[questionNo]].isOption1 === "0" ? "" : answers[rqno[questionNo]].isOption1}
                            />
                            :
                            <>
                              <p id="isOption1" style={{ backgroundColor: is1, padding: "10px", borderRadius: "25px", display: questions[rqno[questionNo]].option1 !== "" ? 'block' : "none" }} onClick={() => { answerCheck("isOption1", rqno[questionNo]); }}>
                                <input type={questions[rqno[questionNo]].questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {questions[rqno[questionNo]].option1}
                              </p>
                              <p id="isOption2" style={{ backgroundColor: is2, padding: "10px", borderRadius: "25px", display: questions[rqno[questionNo]].option2 !== "" ? 'block' : "none" }} onClick={() => { answerCheck("isOption2", rqno[questionNo]) }}>
                                <input type={questions[rqno[questionNo]].questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {questions[rqno[questionNo]].option2}
                              </p>
                              <p id="isOption3" style={{ backgroundColor: is3, padding: "10px", borderRadius: "25px", display: questions[rqno[questionNo]].option3 !== "" ? 'block' : "none" }} onClick={() => { answerCheck("isOption3", rqno[questionNo]) }}>
                                <input type={questions[rqno[questionNo]].questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {questions[rqno[questionNo]].option3}
                              </p>
                              <p id="isOption4" style={{ backgroundColor: is4, padding: "10px", borderRadius: "25px", display: questions[rqno[questionNo]].option4 !== "" ? 'block' : "none" }} onClick={() => { answerCheck("isOption4", rqno[questionNo]) }}>
                                <input type={questions[rqno[questionNo]].questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {questions[rqno[questionNo]].option4}
                              </p>
                              <div style={{ display: is1 !== "white" || is2 !== "white" || is3 !== "white" || is4 !== "white" ? "flex" : "none", alignItems: "center", justifyContent: "center", color: "#b3b3b3" }}>
                                <span onClick={() => { answerCheck("clearSelected", rqno[questionNo]); }} style={{ padding: "3px 10px 5px", borderRadius: "25px", cursor: "pointer", textDecoration: "underline" }}><b>Clear Selected</b></span>
                              </div>
                            </>
                        }
                        {
                          questions[rqno[questionNo]].questionType === "4" ?
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <button onClick={() => { shortAnswerSave("back") }} style={{ fontSize: "20px", left: 1, display: questionNo > 0 ? "block" : "none" }} className="create-button">Back</button>
                              {questionNo + 1 !== questions.length ?
                                <button onClick={() => { shortAnswerSave("next") }} style={{ fontSize: "20px", right: 1, display: questionNo + 1 !== questions.length ? "block" : "none" }} className="create-button">Next</button>
                                :
                                <button onClick={() => { shortAnswerSave("submit") }} style={{ fontSize: "20px", right: 1, display: (questionNo + 1) === questions.length ? "block" : "none" }} className="create-button">Submit</button>
                              }
                            </div>
                            :
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <button onClick={() => { setquestionNo(questionNo - 1); selected(rqno[questionNo - 1]); setImageLoader(true) }} style={{ fontSize: "20px", left: 1, display: questionNo > 0 ? "block" : "none" }} className="create-button">Back</button>
                              {questionNo + 1 !== questions.length ?
                                <button onClick={() => { setquestionNo(questionNo + 1); selected(rqno[questionNo + 1]); setImageLoader(true) }} style={{ fontSize: "20px", right: 1, display: questionNo + 1 !== questions.length ? "block" : "none" }} className="create-button">Next</button>
                                :
                                <button onClick={marksCalulator} style={{ fontSize: "20px", right: 1, display: (questionNo + 1) === questions.length ? "block" : "none" }} className="create-button">Submit</button>
                              }
                            </div>
                        }
                      </div>

                }
              </div>
            </div>
          </>
      }
    </div>
  );
}

