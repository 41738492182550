import axios from 'axios';
import React,{useState,useEffect} from 'react';
import { makeStyles,withStyles  } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {  blue } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import {useParams} from 'react-router-dom';

var sortJsonArray = require('sort-json-array');
const useStyles = makeStyles((theme) => ({
    root: {
      marginLeft:10,
      marginRight:10,
      minWidth: 345,   
      maxWidth:345,
      marginBottom:10,
      marginTop:20,
      fontFamily:'monospace',
      fontSize:'medium'
      },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: blue[500],

  },
  inline: {
    display: 'inline',
    
  },
 
  media: {
    minWidth:345,
    height: 0,
    paddingTop: '56.25%',
    backgroundSize:'contain'
  },
 
}));

export default function Achive(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [Select, SetSel] = useState("All");
  const [result, setRes]= useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {sid} = useParams();

  useEffect(()  =>{
    async function fetchD(){
     const res= await axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"get_alumini_achievents",['sid']:sid})
     .then(res=> {
          console.log("then"+ JSON.stringify(res));
          props.Acinfo(res.data.about_achiv[0].description)
         setRes(res)}).catch(err =>console.log("Error-"+err));
 }
   fetchD();
   
},[])


  return (
    
    
    result.data!=null?
    <div style={ {display:'inner-block'}}>
      {console.log("APID-->"+sortJsonArray(result.data.alumini_acheivements,'id','des'))}
   <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
               
            >
          
                {result.data.alumini_acheivements.map((v,i)=>(
                v.admin_approve==1? <>
    <Card className={classes.root} variant="outlined" id={i}>
    
    <CardMedia
      className={classes.media}
      image={v.image!=null && v.image !="" && v.image.length>0?v.image:(v.image.length==0) && (v.photo !=null && v.photo!='' && v.photo.length>0)?v.photo:'https://www.careerpoint-solutions.com/wp-content/uploads/2017/10/achievements.jpg'}
      title="Alumni Achivement"
    /> 
   
      <CardHeader
        avatar={
          v.photo!=null?<Avatar src={v.photo} style={{width:51,height:51}} sizes='large'/>:
         <EmojiEventsIcon color='secondary' fontSize='large'/>
        }
        title={v.name!="" && v.image.length>0?v.name:v.full_name}
        subheader={v.achievement}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
        {v.description}
        </Typography>
      </CardContent>
    </Card></>:null))}
    </Grid>
    </div>:<><CircularProgress/></>
  );
}
