import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios';
import Loader from 'react-loader-spinner';
import Navbar from '../User/navbar';
import Swal from 'sweetalert2';


export default function EditQuiz() {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Quiz />
        </div>
    )
}

function Quiz() {
    const history = useHistory();
    const { newSid,sid, uid } = useParams();
    if (history.location.state === undefined) {
        history.push("/quizapp" + '/' + sid + '/' + uid)
    }
    sessionStorage.setItem("lpath", history.location.pathname)
    const [search, setSearch] = useState('');
    const [loader, setLoader] = useState(true);
    const [preview, setPreview] = useState(false);
    const [questions, setQuestions] = useState([{ questionTitle: "Questions Not Created Yet" }]);
    const [validate, setValidate] = useState("");
    const [deleted, setDeleted] = useState(true);

    useEffect(() => {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Quizapp_Getquestion", sid: newSid, sid_school:sid, userid: uid, quizid: history.location.state.id })
            .then(res => {
                let v = "0";
                try {
                    setQuestions(res.data.Questions.filter(i => {
                        if (i.questionType === "4") v = "1";
                        return i.isdeleted === "0"
                    }));
                } catch (e) { console.error(e); }
                setValidate(v);
                setLoader(false);
            })
    }, [deleted]);


    useEffect(() => {
        try {
            if (validate !== "") {
                if (history.location.state.validate !== validate) {
                    console.log("runing")
                    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
                        {
                            fun_name: "Quizapp_UpdateQuiz",
                            ...history.location.state,
                            validate: validate,
                            sid: newSid,
                            sid_school:sid,
                            userid: uid,
                            deleted: "0"
                        }
                    ).then(() => { history.location.state.validate = validate })
                }
            }
        } catch (e) { console.error(e); }
    }, [validate])

    function deleteQuestion(fData) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setLoader(true);
                const formData = new FormData();
                formData.append('fun_name', 'Quizapp_hideQuestion')
                formData.append('sid', sid)
                formData.append('quizid', fData.quizid)
                formData.append('questionType', fData.questionType)
                formData.append('questionTitle', fData.questionTitle)
                formData.append('isOption1', fData.isOption1)
                formData.append('option1', fData.option1)
                formData.append('isOption2', fData.isOption2)
                formData.append('option2', fData.option2)
                formData.append('isOption3', fData.isOption3)
                formData.append('option3', fData.option3)
                formData.append('isOption4', fData.isOption4)
                formData.append('option4', fData.option4)
                formData.append('additionalText', fData.additionalText)
                formData.append('file', fData.image);
                formData.append('mark', Math.abs(fData.mark));
                formData.append('negativ_mark', Math.abs(fData.negativ_mark));
                formData.append('userid', uid);
                formData.append('qid', fData.qid);
                formData.append('isdeleted', 1);
                axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', formData).then(() => { setDeleted(!deleted); })
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your question is safe :)',
                    'error'
                )

            }
        })
    }


    return (
        <div className="quiz-container">
            <div className="quiz-head"><div className="quiz-head-title">{history.location.state.quizName}</div><Navbar home={{ isHome: true, home: `${sessionStorage.tora}` }} back={true} refresh={true} /></div>
            <hr className="hr-line" />
            <div className="search-div">
                <input className="quiz-search" type="text" placeholder=" Quick Search" onChange={e => { setSearch(e.target.value) }} />
            </div>
            {loader ?
                <div id="loader">
                    <Loader
                        type="Oval"
                        color="#0066ff"
                        height={70}
                        width={70}
                    /></div> :
                preview === true ?
                    <div className="quiz-list">
                        <div className="quiz-list-items">
                            {questions.filter((quiz) => {
                                return (quiz.questionTitle.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                            }).map((question, k) => {

                                return (
                                    <div style={{ width: "95%", marginBottom: "50px" }} key={k} onLoad={() => { console.log(question.questionType) }}>

                                        <div style={{ backgroundColor: "white", padding: "10px", borderRadius: "7px", marginBottom: "20px" }}>{k + 1} . {question.questionTitle}<br></br>{question.additionalText}</div>
                                        {question.image !== "" ?
                                            <div style={{ display: "flex", alignItems: 'center', justifyContent: "center", marginBottom: 5, minHeight: "200px", maxHeight: "315px", width: "100%", position: "relative", border: 0 }}>
                                                <img src={question.image} onLoad={() => { document.getElementById(`image${k}`).style.opacity = 0 }}
                                                    style={{ maxHeight: "300px", maxWidth: "100%", position: "relative", border: 0 }}
                                                    alt="Image Loading... Please Wait..."
                                                />
                                                <div id={`image${k}`} style={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", border: 0, padding: 5 }}>
                                                    <Loader
                                                        type="Oval"
                                                        color="red"
                                                        height={50}
                                                        width={50}
                                                    />
                                                </div>
                                            </div> : null
                                        }
                                        <p id="isOption1" style={{ backgroundColor: question.isOption1 === "0" ? "white" : "lightgreen", padding: "10px", borderRadius: "25px", display: question.option1 !== "" ? 'block' : "none" }}>
                                            <input type={question.questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {question.option1}
                                        </p>
                                        <p id="isOption2" style={{ backgroundColor: question.isOption2 === "0" ? "white" : "lightgreen", padding: "10px", borderRadius: "25px", display: question.option2 !== "" ? 'block' : "none" }}>
                                            <input type={question.questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {question.option2}
                                        </p>
                                        <p id="isOption3" style={{ backgroundColor: question.isOption3 === "0" ? "white" : "lightgreen", padding: "10px", borderRadius: "25px", display: question.option3 !== "" ? 'block' : "none" }}>
                                            <input type={question.questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {question.option3}
                                        </p>
                                        <p id="isOption4" style={{ backgroundColor: question.isOption4 === "0" ? "white" : "lightgreen", padding: "10px", borderRadius: "25px", display: question.option4 !== "" ? 'block' : "none" }}>
                                            <input type={question.questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {question.option4}
                                        </p>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    :
                    <div className="quiz-list">
                        <div className="quiz-list-items">
                            {questions.filter((quiz) => {
                                return (quiz.questionTitle.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                            }).map((quiz, key) => {

                                if (quiz.questionTitle !== "Questions Not Created Yet") {
                                    return (
                                        <div key={key} className="quiz-card">
                                            <div className="quiz-title">
                                                {key + 1} .&nbsp;
                                                {(((quiz.questionTitle).length > 80) ? ((quiz.questionTitle).slice(0, 80).concat("...")) : (quiz.questionTitle))}
                                            </div>
                                            <div style={{ fontWeight: "bold", padding: "10px" }}>
                                                <span className="quiz-view-results">Type - {quiz.questionType === "1" ? "Single Choice" : (quiz.questionType === "2" ? "Multiple Choice" : quiz.questionType === "3" ? "True or False" : "Short Answer")}</span>
                                            </div>
                                            <div className="quiz-option" style={{ flexWrap: 'wrap' }}>
                                                <span className="quiz-edit QMquiz_EditButton" style={{ margin: "0px 10px" }} onClick={() => {
                                                    history.push('/quizapp_all/createquestion/' + newSid + '/' + sid + '/' + uid, { ...quiz, actiontodo: "updatequestion", nmark: history.location.state.negativeMarking })
                                                }}>Edit </span>
                                                <span onClick={() => { deleteQuestion(quiz) }} className="quiz-view-results QMquiz_EditButton">Delete</span>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div className="quiz-notfound" key={key}>Questions Not Created Yet</div>
                                    )
                                }
                            })
                            }
                            <div className="quiz-notfound">
                                {
                                    (questions.filter((quiz) => {
                                        return (quiz.questionTitle.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                                    }).length !== 0) ? null : "Questions Not Found"
                                }
                            </div>
                        </div>
                    </div>
            }

            <div style={{ position: "absolute", bottom: "2%", textAlign: "center", width: "90%", color: "white", display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}>

                <img alt=""
                    onClick={() => {
                        history.push('/quizapp_all/addnewquiz/' + newSid + '/' + sid + '/' + uid, { ...history.location.state, actiontodo: "updatequiz" })
                    }}
                    src="https://img.icons8.com/metro/45/042CAD/settings.png" style={{ opacity: 0.8 }} />

                <div style={{ marginBottom: "10px" }}>
                    <span style={{ background: "rgba(4, 44, 173,0.8)", padding: "5px 15px", borderRadius: "25px", cursor: "pointer" }} onClick={() => { setPreview(!preview) }}><b>{!preview ? "Preview" : "Edit"}</b></span>
                </div>

                <img
                    onClick={() => {
                        history.push('/quizapp_all/createquestion/' + newSid + '/' + sid + '/' + uid, { ...history.location.state, actiontodo: 'addquestion', nmark: history.location.state.negativeMarking })
                    }}
                    src="https://img.icons8.com/ios-glyphs/50/042CAD/plus-math.png" alt='New Quiz' style={{ opacity: 0.8 }} />

            </div>


        </div>
    );
}
