import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import {Fade,Button,Grid,TextField,ButtonGroup } from '@material-ui/core';
import {FormLabel,FormControl,Radio,RadioGroup,FormControlLabel} from '@material-ui/core';
import {useParams} from 'react-router-dom';

import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const [result, setRes]= useState({});
  const {sid} = useParams();

  const [value, setValue] = React.useState();
  const [open, setOpen] = React.useState(props.isopen);
  const [name,setName]=useState(props.ud.data[0].full_name);
  const [disabledN,setDisabled]=React.useState(props.ud.data[0].full_name==null||props.ud.data[0].full_name==''?false:true);
  const [email,setEmail]=useState(props.ud.data[0].emailid);
  const [disablede,setDisableE]=React.useState(props.ud.data[0].emailid==null||props.ud.data[0].emailid==''?false:true);
  const [mob,setMob]=useState(props.ud.data[0].contact);
  const [disabledM,setDisableM]=React.useState(props.ud.data[0].contact==null||props.ud.data[0].contact==''?false:true);
  const handleSubmit=()=>{
    console.log("OnSubmit-->"+name+props.camp_name)
    if(name !='' && mob != '' && email !='' && name !=null  && mob !=null && email!=null  ){
      if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email) &&/^\d{10}$/.test(mob)){
        
 const res=  axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'insert_alumni_donation'
    ,['sid']:sid,['userid']:props.ud.data[0].userid,['name']:name,['dtype']:value,['cname']:props.camp_name,['email']:email, ['mob']:mob,['amount']:props.amt}
    
    )
    .then(res=> {
         console.log("Result insert"+ JSON.stringify(res));
        setRes(res)}).catch(err =>console.log("Error-"+err));  
        //alert("Successfully Created the Opportunity");
    
      props.onClick();
    }
    else alert("Name is invalid or mobile number is invalid");
  }  
    //  event.preventDefault()
  }
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.isopen}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 >Alumni Donation Details</h2>
            <p >please fill all the details correctly.</p>
            <form className={classes.form} noValidate='false' >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled
                autoComplete="Campaign Name"
                name="Campaign Name"
                variant="outlined"
                required
                fullWidth
                id="Cname"
                label="Campaign Name"
                 value={props.camp_name}
                // onChange={(e) => setFName(e.target.value)}
                 autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                 disabled={disabledN}
                variant="outlined"
                required
                fullWidth
                id="Name"
                label="Name"
                name="Name"
                value={name}
               onChange={(e) => setName(e.target.value)}
                autoComplete="name"
              />
            </Grid>
            <Grid item xs={12}>
          <TextField required variant="outlined" id="Mob" name="Mob" label="Contact-No" type="number" disabled={disabledM}
            value={mob}
             onChange={(e) => setMob(e.target.value)}
            fullWidth />
        </Grid>
        <Grid item xs={12}>
              <TextField
                disabled={disablede}
                variant="outlined"
                required
                fullWidth
                type='email'
                id="email"
                label="Email Address"
                name="email"          
                value={email}
                
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                variant="outlined"
                required
                fullWidth
                name="Amount"
                label="&#x20B9; Amount"
                type="number"
                id="Amount"
                value={props.amt}
                // onChange={(e) => setCompany(e.target.value)}
                // autoComplete="current_Company"
              />
              <FormControl component="fieldset">
      <FormLabel  component="legend">Donation Method</FormLabel>
      <RadioGroup  aria-label="Donation" name="Donation" value={value} onChange={handleChange}>
        <FormControlLabel  value="public" control={<Radio />} label="Public Donation" />
        <FormControlLabel  value="private" control={<Radio />} label="Private Donation" />
      </RadioGroup>
    </FormControl>
            </Grid>
            </Grid>
            </form>
            <ButtonGroup
        orientation="horizontal"
        color="primary"
        
      >
            <Button style={{marginTop:10}} variant="contained" color="primary" onClick={()=>handleSubmit()}>
             Pay
            </Button>
            <Button style={{marginTop:10, marginLeft:20}} variant="contained" color="primary" onClick={props.handleClose}>
             Cancel
            </Button>
            </ButtonGroup>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
