import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { FcDownload } from "react-icons/fc";
import { makeStyles } from "@material-ui/core/styles";
import { FcSearch } from "react-icons/fc";
import {handleDownloadExcel} from "../ExportToExcel"

import { AiFillDelete } from "react-icons/ai";
import { IconContext } from "react-icons";
import axios from "axios";
import Swal from "sweetalert2";
import { useEffect } from "react";

const useStyles = makeStyles({
  mainDiv: {
    margin: "0px 30px",
    marginTop: "40px",
  },
  dateInfo: {
    display: "flex",
    margin: "0px 10px",
    justifyContent: "space-between",
  },
  teacherInfo: {
    display: "flex",
  },
});

const ReportResult = ({
  startDate,
  endDate,
  reports,
  setReports,
  userid,
  sid,
}) => {
  const classes = useStyles();

  const [exportData, setExportData] = useState({
    headers: [
      "Teachers", "Class", "Subject", "Lesson Name", "Date"
    ],
    body: []
  }
  )

  useEffect(() => {
    const myArray = reports.map(item => {
      return {
        Teacher: item.Teacher,
        created_date: item.div_name,
        div_name: item.subject,
        subject: item.LessonName,
        LessonName: item.created_date.substring(0, 11)
      }
    });
    setExportData({...exportData, body: myArray})
  }, [reports])
  
  return (
    <div className={classes.mainDiv}>
      <div className={classes.dateInfo}>
        <div>
          <span style={{ color: "#42A0EA" }}>
            {startDate} - {endDate}
          </span>
        </div>
        <FcDownload size={28} cursor={"pointer"} onClick={()=>handleDownloadExcel(exportData.headers, exportData.body)}/>
      </div>
      <div className={classes.teacherInfo}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Teachers</TableCell>
                <TableCell align="center">Class</TableCell>
                <TableCell align="center">Subject</TableCell>
                <TableCell align="center">Lesson Name</TableCell>
                <TableCell align="center">Date</TableCell>

                <TableCell align="center">Actions</TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {reports &&
                reports.map((report, idx) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={idx}
                    >
                      <TableCell align="center">{report.Teacher}</TableCell>
                       <TableCell align="center">{report.div_name}</TableCell>
                       <TableCell align="center">{report.subject}</TableCell>
                       <TableCell align="center">
                        {report.LessonName}
                      </TableCell>
                      <TableCell align="center">
                        {report.created_date.substring(0, 11)}
                      </TableCell> 

                       <TableCell align="center">
                        <Link
                          to={`/admin/reportWeeklyView/${sid}/${userid}/${report.id}`}
                        >
                          <FcSearch size={28} />{" "}
                        </Link>
                      </TableCell> 
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ReportResult;
