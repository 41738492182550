import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {Button,Breadcrumbs,Link} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {FolderOpenOutlined,Home as HomeIcon, PhonelinkSetupOutlined} from '@material-ui/icons';
import GallaryImages from './GallaryImages';
import AddIcon from '@material-ui/icons/Add';
import Uploadimg from './uploadimg';
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    maxWidth: 100,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  foldericon:{
      width:100,
      height:100,
  },
  link: {
    display: 'flex',
  },
});

export default function Gallary(props) {
  const classes = useStyles();
  const [fclick,setFolder]=useState(false);
  const [isUpload,setUpload]=useState(false);
  const [open, setOpen] = React.useState(false);
  const {sid} = useParams();

  const handleUploadOpen = () => {
    setUpload(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleUploadClose = () => {
    setUpload(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    fclick==false && isUpload==false?<div style={{display:'flex'}}>
       <Snackbar  anchorOrigin={{vertical:'top',horizontal:'center'}} open={open} autoHideDuration={6000} onClose={handleClose}>
     <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success</AlertTitle>
        Successfully Submited Photos
      </Alert>
      </Snackbar>
       {/* <Card className={classes.root} style={{marginRight:10}} onClick={()=>handleUploadOpen()}>
      <CardContent style={{paddingBottom:16}}>
        <AddIcon style={{height:60,width:50}} color='primary' fontSize='large' />
      </CardContent>
  
    </Card> */}
    <Card className={classes.root} onClick={()=>setFolder(true)}>
      <CardContent style={{paddingBottom:8}}>
        <FolderOpenOutlined color='primary'style={{marginLeft:15,fontSize:'3.1875rem'}}/>
        <Typography>Memories from Alumni</Typography>
      </CardContent>
  
    </Card>
   </div>:fclick && isUpload==false?<div>
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit"  className={classes.link}>
        <HomeIcon className={classes.icon} />
        Home
      </Link>
      <Link
        color="inherit"
        // href="/getting-started/installation/"
         onClick={()=>setFolder(false)}
         className={classes.link}
      >
        <FolderOpenOutlined className={classes.icon} />
        Memories from Alumni
      </Link>
    </Breadcrumbs>
      <GallaryImages sid={props.sid}/>
      </div>: isUpload?<Uploadimg isup={handleUploadClose} Submited={handleOpen} isopen={isUpload}/>:null
  );
}
