import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Participants from '../../ReusedComponents/Participants'

export default function AddParticipants() {
    const history = useHistory();
    const { sid, uid } = useParams();

    function onAdd(params) {
        history.push(`/appointment/user/appointmentdetails/${sid}/${uid}`, params);
    }

    return (
        <Participants historydata={history.location.state} sid={sid} uid={uid} fun={"GetStaffDetails"} type={"Admin"} onAdd={(params) => { onAdd(params) }} />
    );
}
