import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";

const TeacherName = ({ divName, teacher }) => {

  const [currentDate, setDate] = useState("");



  useEffect(() => {
    let currentDate = new Date().toISOString().split("T")[0];
    setDate(currentDate);
  },[]);

  return (
    <>
      <Container>
        <div className="student-details">
          <div className="student_div">
            <div className="student_div-student_names">
              <span>Teacher</span>
            </div>
            
                <div className="box_div">
                  {
                    teacher.map((data,indx)=> {
                      return (
                        <span className="student_names" key={indx} >{data.first_name} {data.last_name}</span>

                      )
                    })
                  }
                </div>
             
          </div>

          <div className="student_div">
            <div className="student_div-student_names">
              <span>Date</span>
            </div>
            <div className="box_div">
              <span className="student_names">
                {moment(currentDate).format("DD-MM-YYYY")}
              </span>
            </div>
          </div>

          <div className="student_div">
            <div className="student_div-student_names">
              <span>Class</span>
            </div>
            <div className="box_div">
              <span className="student_names">{divName}</span>
            </div>
          </div>
        </div>

        <hr className="line" />
      </Container>
    </>
  );
};

export default TeacherName;

const Container = styled.div`
  margin-left: 19px;

  .title {
    padding: 0;
  }
  .student_div-student_names {
    width: 100px;
    color: #0a1784;
  }
  .student_div {
    margin: 6px 0;
    display: flex;
    flex-wrap: nowrap;
  }

  span {
    font-size: 1.2rem;
    /* color: #0A1784; */
  }

  .student-details {
    margin: 15px 3px;
  }
  .box_div {
    margin-left: 30px;
  }

  .student_names {
    list-style-type: none;
    margin: 0;
    padding: 0px;
    color: #0a1784;

    li {
      color: rgba(70, 130, 210);
      font-weight: 600;
      font-size: 1rem;
    }
  }
  .line {
    width: 80%;
    color: #0a1784;
    margin-left: 20px;
    font-size: 2rem;
  }
`;
