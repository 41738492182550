import React, { useState, useEffect } from 'react'
import styles from './Pay.module.css'
import axios from 'axios'
import { useHistory } from 'react-router-dom'


const Pay = () => {

  const history = useHistory()
  const [walletId, setwalletId] = useState("")
  // const [userList, setuserList] = useState([])
  const [apiData, setApiData] = useState({
    walletid: "",
    txnid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
    amount: "",
    datetime: "2022-3-31 7:34:25",
    purpose: "test_purpose",

  })

  const clickHandler = () => {
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : ""
    if (apiData.amount > 0 && apiData.walletid !== "") {
      axios.post("https://api.paykit.in/add-balance", apiData, {
        headers: { "Authorization": token }

      }).then(res => {
        alert("Balance added successfully")
        history.push("/wallet")
      }
      )
        .catch(err => alert("Oops something went wrong!"))

    }
  }

  useEffect(() => {
    if(walletId){
    
    axios.post("https://marcopolo.prisms.in/api/LinksPay/getwalletId", {"walletUsername":walletId}, {
        headers: { "authorization": "#$laksdfnlknoea#$@$%^&%$" }

      }).then(res => {
        setApiData({
              ...apiData,
              walletid: res.data[0].walletId
             })
      }
      )
        .catch(err => console.log(err))}

  }, [walletId]);

  const selectHandler = (e) => {
    setwalletId(e.target.value)
  }

  const changeHandler = (e) => {
    setApiData({
      ...apiData,
      amount: e.target.value
    })
  }

  // useEffect(() => {
  //   axios.post("https://marcopolo.prisms.in/api/LinksPay/getUserList", {}, {
  //     headers: { Authorization: "#$laksdfnlknoea#$@$%^&%$" }
  //   })
  //     .then(res => setuserList(res.data))
  //     .catch(err => console.log(err))
  // }, [])

  return (
    
    <div className={styles.wrapper}>
      {console.log(apiData)}
      <div className={styles.main}>
        <h2>Add Balance</h2>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img style={{ width: "150px", height: "150px" }} src="../icons/wallet/sales.png" alt="" />
        </div>
        <div style={{ width: "100%", textAlign: "left" }}>
          <p>Add balance to wallet</p>
          <input onChange={selectHandler} style={{ width: "100%" }} />
          
        </div>
        <div style={{ width: "100%", textAlign: "left" }}>
          <p>Enter Amount</p>
          <input onChange={changeHandler} type="number" style={{ width: "100%" }} />
        </div>
        <button onClick={clickHandler} className={styles.btn}>Add balance</button>
      </div>
    </div>
  )
}

export default Pay
