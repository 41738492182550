import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    height: "40px",
  },
}));

const DayPlan = ({ day, weeklyData, setWeeklyData }) => {
  const classes = useStyles();
  const [topic, setTopic] = useState("");
  const [period, setPeriod] = useState("");
  const [notes, setNotes] = useState("");
  const [activity, setActivity] = useState("");
  const [work, setWork] = useState("");
  const [value, setValue] = useState("");
  const [reason, setReason] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
    const obj = {
      day,
      top_sub_top: topic,
      no_of_period: period,
      imp_note: notes,
      cls_activity: activity,
      homework: work,
      if_no_reas_with_comp_date: reason,
    };
    const newArr = weeklyData;
    newArr.push(obj);
    setWeeklyData(newArr);
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Your work has been saved",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{day}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <TextField
          fullWidth
          id="topic"
          label="Topic/Sub-Topic"
          variant="outlined"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
        />
        <TextField
          fullWidth
          id="periods"
          label="No. of Periods"
          variant="outlined"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        />
        <TextField
          fullWidth
          id="notes"
          label="Important Notes"
          variant="outlined"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <TextField
          fullWidth
          id="activities"
          label="Class Activities"
          variant="outlined"
          value={activity}
          onChange={(e) => setActivity(e.target.value)}
        />
        <TextField
          fullWidth
          id="homework"
          label="Home Work"
          variant="outlined"
          value={work}
          onChange={(e) => setWork(e.target.value)}
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Accomplished</FormLabel>
          <RadioGroup
            aria-label="accomplished"
            name="accomplished"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {value === "no" ? (
          <TextField
            fullWidth
            id="reason"
            label="If No Reason With Completion"
            variant="outlined"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        ) : (
          ""
        )}
        <Button
          onClick={(e) => handleSave(e)}
          className={classes.button}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default DayPlan;
