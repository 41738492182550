import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { InputBase, Paper, CssBaseline } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import Chatlist from "./Chatlist";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import PeopleIcon from "@material-ui/icons/People";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ChatDetails from "./ChatDetails";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import Resizer from "react-image-file-resizer";
import ClearIcon from "@material-ui/icons/Clear";
import { animateScroll } from "react-scroll";

//  console.warn = console.error = () => {};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily:'Poppins'
  },
  root1: {
    display: "flex",
    position: "absolute",
    bottom: 5,
    alignItems: "center",
    borderRadius: 30,
    //width: '100vw',
    minHeight: 40,
    maxHeight: 200,
    border: "1px solid",
    overflow: "auto",
  },
 
  iconButton: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    flexWrap: "wrap",
  },
  inputInput: {
    backgroundColor: "white",
    color: "inherit",
    padding: theme.spacing(1, 1, 1, 0),

    transition: theme.transitions.create("width"),
    width: "70%",
    //border:'1px solid',
    marginTop: 5,
    marginLeft: 10,
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  appbarDesk: {
    marginLeft: 300,
  },
  appBarMob: {
    marginLeft: 0,
  },
  appbars: {
    [theme.breakpoints.up("sm")]: {
      position: "fixed",

      width: "auto",
    },
  },
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const [msg, setMsg] = useState(null);
  const [FileLocal, setFile] = useState(null);
  const [filename, setFileName] = useState(null);
  const [fileimg, setFileimg] = useState(null);
  const [prvw, setPrvw] = React.useState(null);
  const [To, setTo] = React.useState(null);
  const [Chat, setResult] = React.useState(null);
  const { register, handleSubmit } = useForm();
  const [timerid, setTimerId] = React.useState();
  const [ScrollB, setScrollB] = React.useState(true);
  const [flag, setFlag] = React.useState(0);
  const [msgDeleting, setMsgDeleting] = React.useState(false);

  useEffect(() => {

    setTo(props.GroupD);

    if (props.isf === true) {
      console.log("in true");
      axios
        .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
          ["fun_name"]: "GetForumChat",
          ["sid"]: props.sid,
          ["groupid"]: props.gid,
          ["subgroupid"]: props.sub,
          ["flag"]: flag,
        })
        .then((res) => {
          console.log(res);
          setResult(res);
          animateScroll.scrollToBottom();
          clearTimeout(timerid);
        });

      props.f();
    }

     let t = setTimeout(() => {
       // if(props.isf==false)
       GetData();
       // console.log('in timerout')
     }, 15000);
    setTimerId(t);
    clearTimeout(timerid);
  }, [Chat]);

  const GetData = () => {
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        ["fun_name"]: "GetForumChat",
        ["sid"]: props.sid,
        ["groupid"]: props.gid,
        ["subgroupid"]: props.sub,
        ["flag"]: flag,
      })
      .then((res) => {
        setResult(res);
        clearTimeout(timerid);
      });

    props.f();
  };
  const GetOldData = () => {
    setFlag(1);
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        ["fun_name"]: "GetForumChat",
        ["sid"]: props.sid,
        ["groupid"]: props.gid,
        ["subgroupid"]: props.sub,
        ["flag"]: 1,
      })
      .then((res) => {
        setResult(res);
      });
  };
  const bottomscroll = () => {
    setScrollB(false);
  };
  const AddToChat = (data) => {
    const cuser = props.uid;
    const sid = props.sid;
    var name = null;

    for (var i = 0; i < props.members.length; i++) {
      if (
        props.members[i].user_id == props.uid &&
        props.members[i].name != null
      ) {
        var name = props.members[i].name;
      }
    }
    const formData = new FormData();

    if ((msg != null && msg != "" && name != null) || FileLocal != null) {
      var newmsg = msg.replace(/'/g, "''");
      formData.append("fun_name", "forumApiWithAttachments");
      formData.append("sid", sid);
      formData.append("from", cuser);
      formData.append("name", name);
      formData.append("groupid", props.gid);
      formData.append("subgroupid", props.sub);
      formData.append("msg", newmsg);
      formData.append("file", FileLocal);
      axios
        .post(
          "https://topschool.prisms.in/rest/index.php/user_list.json?",
          formData
        )
        .then((res) => {
          console.log(res);
          setResult(res);
          clearTimeout(timerid);
          animateScroll.scrollToBottom({
            containerId: "chat",
            duration: 0
          });
        });
    } else console.warn("s->" + msg);

    setFile(null);
    setMsg("");
    setPrvw(null);
    // setFile(null);
    setFileimg(null);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const getImageUrl = async (e) => {
    var file = e.target.files[0];
    //let file=Resizer.imageFileResizer(file2, 300, 300, 'JPEG', 100, 0,'blob',200,200);
    if (file != null) setFileName(file.name);
    // setFileExt(file.type.substr(file.type.lastIndexOf('/')+1));

    let ext1 = file.type.substr(0, file.type.lastIndexOf("/"));
    if (ext1 == "image") {
      setFileimg(1);
      const file2 = await resizeFile(file);
      var arr = file2.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      // console.log(mime)
      let FileO = new File([u8arr], file.name, { type: mime });
      setPrvw(file2);
      setFile(FileO);
    }
    if (file.size <= 5000000 && ext1 != "image") {
      let reader = new FileReader();
      let a = reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        // console.log("d-->"+reader.result);
        var arrf = reader.result.split(","),
          mimef = arrf[0].match(/:(.*?);/)[1],
          bstrf = atob(arrf[1]),
          nf = bstrf.length,
          u8arrf = new Uint8Array(nf);

        while (nf--) {
          u8arrf[nf] = bstrf.charCodeAt(nf);
        }
        let FileO = new File([u8arrf], file.name, { type: mimef });
        setFile(FileO);
      };
    } else if (file.size > 5000000) {
      window.alert("file size is too large...");
    }
  };
 

  return props.cdval == false ? (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBarMob}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            onClick={() => props.back()}
            color="inherit"
            aria-label="open drawer"
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            className={classes.title}
            style={{ textTransform: "capitalize" }}
          >
            {To}
          </Typography>
          <IconButton
            edge="start"
            className={classes.menuButton1}
            onClick={() => props.cd(true)}
            color="inherit"
            aria-label="open drawer"
          >
            <PeopleIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* {console.log("Hello-"+typeof(props.cdval))} */}
      <main>
        {/* {console.log(Chat)} */}
        {Chat != null ? (
          <div>
            {!msgDeleting ? (
              <Paper
                style={{ marginTop: 2, overflow: "auto", height: "85vh", border: '1px solid black' }}
              >
                {/* {console.warn("UD-->"+props.gid)} */}
                {/* {console.log("Mobile")} */}
                <Chatlist
                  data={props.data}
                  first={ScrollB}
                  setScrolloff={bottomscroll}
                  scrolledup={GetOldData}
                  To={To}
                  doc={FileLocal}
                  C={Chat}
                  sub={props.sub}
                  Gid={props.gid}
                  uid={props.uid}
                  sid={props.sid}
                  setMsgDeleting={setMsgDeleting}
                />

                {/* </Paper>    :<Paper style={{maxHeight: '82%', overflow: 'auto',marginTop:2,}}>
                {/* <Chatlist data={props.data} currUser={currUser} To={To} doc={FileLocal} C={Chat} sub={props.sub}Gid={props.gid} uid={props.uid}/> */}
              </Paper>
            ) : (
              <>
                <CircularProgress
                  style={{ marginLeft: "40vw", marginTop: "40vh" }}
                />
              </>
            )}
          </div>
        ) : (
          <>
            <CircularProgress
              style={{ marginLeft: "40vw", marginTop: "40vh" }}
            />
          </>
        )}
      </main>

      <Paper
        style={{
          position: "fixed",
          bottom: 5,
          float: "right",
          minHeight: "5%",
          maxHeight: 200,
          overflowY: "scroll",
          minWidth: "100%",
          borderRadius: "1%",
        }}
      >
        <InputBase
          className={classes.inputInput}
          placeholder=" Type message here..."
          type="file"
          // accept="image/*"
          multiline
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        />
        {FileLocal != null ? (
          prvw != null ? (
            <img
              style={{
                objectFit: "containe",
                maxWidth: "50%",
                maxHeight: "50%",
              }}
              src={prvw}
            />
          ) : (
            <Typography>{filename}</Typography>
          )
        ) : null}
        {FileLocal != null ? (
          <IconButton
            onClick={() => {
              setPrvw(null);
              setFile(null);
            }}
          >
            <ClearIcon color="primary" />
          </IconButton>
        ) : null}
      </Paper>

      <Paper style={{ position: "fixed", bottom: 1, right: 5 }}>
        <input
          ref={register}
          style={{ display: "none" }}
          id="icon-button-file"
          name="attach"
          type="file"
          onChange={(e) => getImageUrl(e)}
        />
        <label htmlFor="icon-button-file">
          <IconButton
            className={classes.iconButton}
            component="span"
            type="submit"
          >
            <AttachFileIcon color="primary" />
          </IconButton>
        </label>
        <IconButton
          type="submit"
          className={classes.iconButton}
          onClick={AddToChat}
        >
          <SendIcon color="primary" />
        </IconButton>
      </Paper>
    </div>
  ) : props.cdval ? (
    <ChatDetails
      To={To}
      members={props.members}
      sid={props.sid}
      uid={props.uid}
      sub={props.sub}
      back={props.cd}
      data={props.data}
      gid={props.gid}
    />
  ) : null;
}
