import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Table from "./TableView";
import { useEffect } from "react";
import Loader from "react-loader-spinner";

const TicketCR = ({ sid }) => {
  const [data, setData] = useState([]);
  const [Month, setMonth] = useState("");
  const [apiloader, setapiLoader] = useState();

  const getFunc = (e) => {
    e.preventDefault();
    setapiLoader(true);
    const data = {
      sid: sid,
      month: Month,
    };
    axios({
      method: "POST",
      url: "https://marcopolo.prisms.in/api/tickets/created/report",
      data,
      headers: {
        Authorization: "#$laksdfnlknoea#$@$%^&%$",
      },
    })
      .then((res) => {
        setapiLoader(false)
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get Current MM-YYY

  useEffect(() => {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm;
    setMonth(today);
  }, []);

  return (
    <Container>
      <Wrapper>
        <div className="inner__container">
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* Year and Month wise report */}
            <div
              style={{
                marginRight: "8px",
                fontSize:'1.1rem',
                fontWeight:'bold'
              }}
            >
              <span>Select Date</span>
            </div>
            <input
              type="month"
              value={Month}
              onChange={(e) => setMonth(e.target.value)}
              className="mm__yyyy"
            />
            <button
              className="btn__check"
              onClick={(e) => {
                getFunc(e);
              }}
            >
              check
            </button>
          </div>
        </div>
        {apiloader ? (
          <div className="TMLoader">
            <Loader type="ThreeDots" color="#bd2f5e" height={80} width={80} />
          </div>
        ) : (
          <Table
            data={data}
            tcby={"Ticket Created By"}
            title={"Ticket Created"}
            Month={Month}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default TicketCR;

const Container = styled.div`
  position: relative;
  max-width: 1000px;
  height: 100vh;
  margin: 0 auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const Wrapper = styled.div`
  max-width: 100%;

  .inner__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px;

    .mm__yyyy {
      height: 30px;
      border: none;
      border-radius: 4px;
      padding: 0 4px;
      width: 160px;
      color: #fff;
      background-color: #022a79
    }

    .btn__check {
      height: 30px;
      border-radius: 4px;
      background: #082d48;
      border: none;
      color: #fff;
      margin-left: 5px;
      width: 70px;
    }
  }
  h1 {
    text-align: center;
    margin-top: 5px;
    font-weight: 600;
  }
`;
