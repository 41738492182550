import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import Loader from 'react-loader-spinner'
import { xorBy } from 'lodash'

export default function AddParticipants(props) {
    const [personName, setPersonName] = React.useState([]);
    const history = useHistory();
    const { sid, uid } = props;
    const [names, setnames] = useState()
    const [staffDetails, setStaffDetails] = useState([])
    const [staff, setStaff] = useState([])
    const [selectPopup, setPopup] = useState(false)
    const [stafftype, setStafftype] = useState([])
    const [search, setSearch] = useState('');
    const [loader, setloader] = useState(true);
    const [schoollist, setSchoollist] = useState([]);
    const [ssid, setssid] = useState("");
    let selctedStaff = [];
    useEffect(() => {
        if (props.type === "Super") {
            axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: props.fun, sid: sid }).then((res) => {
                if (res.data === undefined || res.data === null || (res.data !== undefined ? (res.data.slist).length === 0 : false) || typeof (res.data) !== "object") {
                    setSchoollist([{ sname: props.historydata.s_name, sid: sid }])
                } else {
                    setSchoollist(res.data.slist);
                }
                setloader(false);
            })
        }
    }, []);
    useEffect(() => {
        if (props.type !== "Super" || (ssid !== "" && ssid !== undefined)) {
            setloader(true);
            axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Appointment_StaffAccess", sid: props.type === "Super" ? ssid : sid }).then((res) => {
                var a = [];
                var b = [];
                (res.data.Staff).map(i => {
                    if (i.first_name !== "" || i.middle_name !== "" || i.last_name !== "") {
                        const idata = props.type === "Super" ? { ...i, school_id: `s${ssid}u${i.staff_id}` } : i
                        if ((props.historydata.participants).includes(`"${i.staff_id}"`) || (props.historydata.participants).includes(`"s${ssid}u${i.staff_id}"`)) {
                            if (personName.indexOf(idata) === -1 && !JSON.stringify(personName).includes(JSON.stringify(idata))) b.push(idata)
                        }
                        if (a.length > 0 ? a[0][i.type] === undefined : true) {
                            a.length === 0 ? a.push({ [i.type]: [idata] }) : a[0][i.type] = [idata]
                        } else {
                            a[0][i.type].push(idata)
                        } return null
                    }
                })
                props.type === "Super" ? b.length > 0 ? setPersonName([...new Set([...personName, ...b])]) : setPersonName(personName) : setPersonName(b);
                setStafftype(Object.keys(a[0]))
                setStaffDetails(a[0]);
                setloader(false);
            })
        }
    }, [ssid]);
    useEffect(() => {
        setnames(staff.filter(name => ((name.last_name).toLowerCase().indexOf((search).toLowerCase()) !== -1) || ((name.middle_name).toLowerCase().indexOf((search).toLowerCase()) !== -1) || ((name.first_name).toLowerCase().indexOf((search).toLowerCase()) !== -1) || ((name.first_name + " " + name.middle_name + " " + name.last_name).toLowerCase().indexOf((search).toLowerCase()) !== -1)))
    }, [staff, search]);

    return (
        <div className="APMCard">
            <div className="APMCardSub">
                <div className="APsidemenu1" id="staffpopup" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: selectPopup ? "100%" : "0%", padding: 0, backgroundColor: "rgba(0,0,0,0.4)" }} >
                    <div style={{ width: "100%", height: "100vh", position: "absolute" }} onClick={() => { setPopup(false) }}></div>
                    <div className="APquiz-card" style={{ height: "60vh" }}>
                        <div className="APform-div" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", fontSize: "25px", color: "#1e5597" }}>
                            <input type="text" placeholder="Search here..." style={{ width: "70%" }} onChange={(e) => { setSearch(e.target.value) }} />
                            <div onClick={() => { document.getElementById('staffpopup').style.right = "0px"; setPopup(false) }}>
                                &#10006;
                            </div>
                        </div>
                        <div style={{ overflow: "scroll", maxHeight: "50vh" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", color: "#1e5597" }}>
                                <div style={{ width: "100%" }}>
                                    {
                                        names === undefined ?
                                            <div className="APLoader">
                                                <Loader
                                                    type="Puff"
                                                    color="#1e5597"
                                                    height={60}
                                                    width={60}
                                                />
                                            </div> :
                                            names.map((name, k) => {


                                                return (
                                                    <div key={k} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} onClick={() => { props.type === "Super" ? setPersonName(xorBy(personName, [name], 'school_id')) : setPersonName(xorBy(personName, [name], 'staff_id')) }}>
                                                        <input type="checkbox" readOnly checked={JSON.stringify(personName).includes(JSON.stringify(name))} style={{ width: "20px", height: "20px" }} />
                                                        <span style={{ padding: "15px", fontWeight: "500" }}>{name.first_name + " " + name.middle_name + " " + name.last_name}</span>
                                                    </div>
                                                )

                                            })
                                    }
                                </div><br></br>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="APHeader">
                    <img onClick={() => { history.goBack() }} src="/icons/back.png" alt="back" /><div>Add Participants</div>
                </div>
                <hr className="APhr-line"></hr>
                {loader ?
                    <div className="APLoader">
                        <Loader
                            type="Puff"
                            color="#1e5597"
                            height={80}
                            width={80}
                        />
                    </div>
                    :
                    <div style={{ width: "100%" }}>
                        {
                            props.type === "Super" ?
                                <div className="APform-div">
                                    <div className="APtableContent" style={{ width: "100%", padding: "10px" }}>
                                        <select defaultValue={ssid === "" ? "Choose" : ssid} onChange={(e) => { setssid((e.target.value) === "Choose" ? "" : e.target.value) }}>
                                            <option value="Choose">Choose School Name</option>
                                            {schoollist.map((s, k) => (
                                                <option key={k} value={s.sid}>{s.sname}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                : null
                        }
                        <div className="APform-div">
                            <div className="APtableContent" style={{ width: "100%", padding: "10px" }} onClick={() => { if ((ssid === "" || ssid === undefined) && props.type === "Super") { alert("Please Select School Name First") } }}>
                                <select id="stafftype" onChange={(e) => { setStaff(staffDetails[e.target.value]) }}>
                                    <option value="Choose">Choose Staff Type</option>
                                    {stafftype.map((s, k) => (
                                        <option key={k} value={s}>{s}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="APform-div">
                            <div className="APtableContent" style={{ width: "100%", padding: "10px" }} >
                                <div onClick={() => { if (document.getElementById('stafftype').value !== "Choose") { setPopup(true) } else { alert("Please Select Staff Type First") } }}>
                                    <select disabled={true} className="APdisabledSelect">
                                        <option value="">{personName.length < 1 ? "Choose Staff Name" : `Selected ${personName.length} ${personName.length < 2 ? "Participant" : "Participants"}`}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginBottom: "30px", width: "100%", marginTop: "20px" }}>
                            <div className="APDetailsname" style={{ justifyContent: "center", margin: '0px 0px' }}>
                                {personName.length !== 0 ? "Selected Participants" : ""}
                            </div>
                            <div className="APA_booked">
                                <div className="APA_booked_sub">
                                    {personName.map((name, k) => {
                                        props.type === "Super" ? selctedStaff.push(name.school_id) : selctedStaff.push(name.staff_id)
                                        return (
                                            <div key={k} className="APA-booked_child" style={{ padding: "6px 10px" }} >
                                                <span>{name.first_name + " " + name.middle_name + " " + name.last_name}</span>
                                                <div onClick={() => { setPersonName(xorBy(personName, [name], 'staff_id')) }}>
                                                    &#10006;
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div style={{ alignItems: "center", justifyContent: "center", display: "flex", width: "100%", marginTop: "25px" }}>
                                <div className="APLandingButtons" style={{ padding: "7px 40px", backgroundColor: "#d4faf7" }} onClick={() => { selctedStaff.length < 1 ? alert("Please Add Participants") : props.onAdd({ ...props.historydata, participants: JSON.stringify(selctedStaff) }) }}>
                                    Confirm
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
