import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {FormLabel,FormControl,Radio,RadioGroup,Box} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// import {Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import { GoogleLogin } from 'react-google-login';
import AchiveImageUp from './AchiveImageUp';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import history from './history';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const [Name,setName]=React.useState(null);
  const [city,setCity]=React.useState(null);
  const [sname,setSname]=React.useState(null);
  const [title,setTitle]=React.useState(null);
  const [details,setDetails]=React.useState(null);
  const [File,setFile]=React.useState(null);
  const [photo,setPhoto] = React.useState(null);
  const {sid} = useParams();
  const [value, setValue] = React.useState(null);
  const SubmitA=()=>{
    const formData= new FormData();
    formData.append("file",photo);
    formData.append("fun_name",'AlumniSubmitAchivement');
    formData.append("sid",sid);
    formData.append("userid",props.ud.data[0].userid);
    formData.append("title",title);
    formData.append("details",details);
    console.log(formData)
    if(sid!=null && props.ud.data[0].userid!=null &&  title!=null && details !=null && title!='' && details!='' ){
   
     axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?",formData).then((resp)=>{
      console.log("1-->"+resp.data.Achievement);
    if(resp.data.Achievement=='1'){
      Swal.fire(
        "Info",
        "Successfully submited your Achievement",
        'success'
    )
     
      setPhoto('');
      setTitle('');
      setDetails('');
    }
    })     
  }
  else{
    Swal.fire(
      "Alert",
      "Please Enter All Fields !",
      'warning'
  )
  }
}

  const Getfile=(file)=>{
    console.log(file)
    setPhoto(file);
  }
  return (
    <Container component="main" maxWidth="xs">
     
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <EmojiEventsIcon  />
        </Avatar>
       
        {/* <Typography component="h1" variant="h5">
          Sign in 
        </Typography> */}
        <Typography component="h1" variant="h5">
          Submit Achievement
        </Typography>
        {props.about!=undefined?<Box fontFamily="Monospace" fontSize="h6.fontSize" fontStyle='italic' m={1}>{props.about}</Box>:null}
        {/* {console.log(props.ud.data[0].userid)} */}
        <div className={classes.form} >
          
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Title"
            label="Title"
            type="Title"
            id="Title"
            value={title}
            onChange={(e)=>{setTitle(e.target.value)}}
            autoComplete="Title"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Details"
            label="Details"
            type="Details"
            id="Details"
            value={details}
            multiline
            rows={4}
            onChange={(e)=>{setDetails(e.target.value)}}
            autoComplete="Title"
          />
           <div >
               <Typography style={{marginRight:20}}></Typography><AchiveImageUp file={Getfile}/>
            </div>
          </div>
          <Button style={{margin:20}} variant="contained" color="primary" component="span" onClick={()=>SubmitA()}>
          Submit Achivement
        </Button>
          </div></Container>
  );
    }