import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles({
    mainDiv: {
      marginTop: "0px",
    },
    heading: {
      margin: "0",
      padding: "0",
      height: "45px",
      textAlign: "center",
    },
    dateDiv: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      marginTop: "40px",
    },
    input: {
      width: "200px",
      marginLeft: "5px",
     // backgroundColor: "rgb(111, 214, 255)",
     backgroundColor:"#ade6e6",
      padding: "5px 8px",
      border: "none",
      outline: "none",
      height: "35px"
    },
    label: {
      fontSize: "16px",
      color: "black",
      marginLeft: "10px"
    },
    form: {
      margin: "10px 0px",
    },
    button: {
      margin: "20px 0px",
      backgroundColor: "rgb(111, 214, 255)",
      padding: "6px 18px",
      color: "white",
    },
  });