import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Classtest from "./classtest/classtest";
import * as serviceWorker from "./serviceWorker";
import { Route, Switch, Router } from "react-router-dom";
import Attendance from "./attendance/attendance";
import Calendar from "./calendar/calendar";
import SessionReport from "./sessionlog/Sessionlog";
import Studonlineclass from "./studonlineclass/studonlineclass";
import Teacheronlineclass from "./teacheronlineclass/teacheronlineclass";
import Home from "./store/Home";
import AlumniHome from "./alumni/AlumniHome";
import Dashboard from "./alumni/Dashboard";
import Loginopt from "./alumni/LoginOpt";
import About from "./alumni/About";
import history from "./alumni/history";
import Inbox from "./forumapp/Inbox";
import QuizForm from "./quizform/QuizForm";
import FollowHome from "./clientfollowup/FollowHome";
import FollowupTopschool from "./followuptopschool/FollowHome";
import Quizzes from "./quizapp/Admin/Quizzes";
// import { Quizzes as Aquiz, AddNewQuiz as Anewquiz , AddQuestion as Aaddquest, ViewResults as Aviewresults, EditQuiz as Aedit, } from "./quizapp/Admin2";
import Quizzes2 from "./quizapp/quizapp2/Quizzes2.js";
import AQuizzes from "./quizapp/Admin2/Quizzes.js";
import AAddNewQuiz from "./quizapp/Admin2/AddNewQuiz";
import AAddQuestion from "./quizapp/Admin2/AddQuestion.js";
import AViewResults from "./quizapp/Admin2/ViewResults";
import AEdit from "./quizapp/Admin2/EditQuiz.js";

import ViewQuizDetails2 from "./quizapp/quizapp2/ViewQuizDetails2.jsx";
import AddNewQuiz from "./quizapp/Admin/AddNewQuiz";
import AddQuestion from "./quizapp/Admin/AddQuestion";
import EditQuiz from "./quizapp/Admin/EditQuiz";
import ViewResults from "./quizapp/Admin/ViewResults";
import ViewResults2 from "./quizapp/quizapp2/ViewResult2.jsx";
import UQuizzes from "./quizapp/User/Quizzes";
import ViewQuizDetails from "./quizapp/User/ViewQuizDetails";
import QuizCard from "./quizapp/User/QuizCard";
import QuizCard2 from "./quizapp/quizapp2/QuizCard2.jsx"
import LeaderBoard from "./quizapp/User/LeaderBoard";
import LeaderBoard2 from "./quizapp/quizapp2/LeaderBoard2.jsx";

import AttemptedQuizzes from "./quizapp/User/AttemptedQuizzes";
import AttemptedQuizzes2 from "./quizapp/quizapp2/AttemptedQuizzes.jsx";
import Container from "./idcardcanvas/Container";
import Review from "./quizapp/User/Review";
import ResultA from "./resultanalysis/home";
import IDGenerate from "./idcardcanvas/Generate";
import StaffContainer from "./idcardcanvas/StaffContainer";
import CounsellingTest from "./counselling/Home";
import UserLanding from "./appointment/User/UserLanding";
import UserRequest from "./appointment/User/UserRequest";
import RequestAppointment from "./appointment/User/RequestAppointment";
import AppointmentStatus from "./appointment/User/AppointmentStatus";
import GroupMeetings from "./appointment/User/GroupMeetings";
import AppointmentRequests from "./appointment/Admin/AppointmentRequests";
import AppointmentDetails from "./appointment/Admin/AppointmentDetails";
import BookSlot from "./appointment/Admin/BookSlot";
import AddParticipants from "./appointment/Admin/AddParticipants";
import AppointmentR from "./appointment/SuperAdmin/AppointmentsR";
import AppointmentD from "./appointment/SuperAdmin/AppointmentD";
import AppointmentB from "./appointment/SuperAdmin/AppoinmentB";
import AddSParticipants from "./appointment/SuperAdmin/AddSParticipants";
import AddNotesP from "./appointment/SuperAdmin/AddNotesP";
import IDHome from "./idcardinfo/Home";
import AddNotesA from "./appointment/Admin/AddNotesA";
import RequestTicket from "./tickets/User/RequestTicket";
import TicketReport from "./tickets/User/TicketReport";
import UserReply from "./tickets/User/UserReply";
import DashboardT from "./tickets/Admin/Dashboard";
import AdminTicket from "./tickets/Admin/AdminTicket";
import TDashboard from "./tickets/Report/Pages/Dashboard"
import Cred from "./cred/cred";
import CheckingAnswer from "./quizapp/Admin/CheckingAnswer";
import PrintView from "./idcardcanvas/Printview";
import AssignedTickets from "./tickets/User/AssignedTickets";
import RequestAndStatus from "./appointment/Parent/RequestAndStatus";
import Requests from "./appointment/User/ParentAppointment/Requests";
import PAppointmentDetails from "./appointment/User/ParentAppointment/AppointmentDetails";
import PAddParticipants from "./appointment/User/ParentAppointment/AddParticipants";
import PAddNotesA from "./appointment/User/ParentAppointment/AddNotesA";
import PBookSlot from "./appointment/User/ParentAppointment/BookSlot";
import SADashboard from "./tickets/SuperAdmin/SADashboard";
import TMSAReports from "./tickets/SuperAdmin/TMSAReports";
import SATicketReply from "./tickets/SuperAdmin/SATicketReply";
import LessonHome from "./lessonPlan/Home/Home.jsx";
import LessonPlan from "./lessonPlan/Form/Form.jsx";
import ReportLessonPlan from "./lessonPlan/Report/Report.jsx";
import ReportView from "./lessonPlan/Report/ReportView.jsx";
import WeeklyHome from "./weeklyPlan/Home/Home.jsx";
import WeeklyPlan from "./weeklyPlan/Form/WeeklyPlan.jsx";
import ReportWeeklyPlan from "./weeklyPlan/Report/Report.jsx";
import WeeklyReportView from "./weeklyPlan/Report/ReportView.jsx";
import Teacher from "./studentlogfeature/Home/Teacher";
import AddComments from "./studentlogfeature/Home/AddComments";
import Comment from "./studentlogfeature/Home/Comment";
import studentlogAdmin from "./studentlogfeature/Admin/AdminReport";
import StartingPage from "./wallet/StartingPage";
import Transaction from "./wallet/Transaction";
import Admin from "./wallet/Admin";
import AdminLoad from "./wallet/AdminLoad";
import Pos from "./wallet/Pos";
import PosSale from "./wallet/PosSale";
import PosReport from "./wallet/PosReport";
import Pay from "./wallet/Pay";
import ChangePassword from "./wallet/ChangePassword";
import AddBalance from "./wallet/AddBalance";
import Leave from "./studentleave/Leave";
import LeaveReport from "./studentleave/LeaveReport";
import StudentInfo from "./parentForm/components/StudentInfo";
import ParentForm from "./parentForm/components/ParentForm";
import StudenProfile from "./studentProfileDeatils/Profiledeatils";
import PhotoCropDash from "./photocrop/Pages/Dashboard"
import HolidayDashboard from "./holidayapp/pages/Dashboard"
import LessonPlanAdminHome from "./lessonPlan/Admin/LessonPlanAdminHome";
import AdminLessonReport from "./lessonPlan/Admin/AdminLessonReport";
import UpdateLessonReport from "./lessonPlan/Admin/UpdateLessonReport";
import AdminWeeklyReport from "./weeklyPlan/Admin/AdminWeeklyReport";
import AdminReportView from "./weeklyPlan/Admin/AdminReportView";
import DownLoadMultiple from "./idcardcanvas/DownLoadMultiple";
import DownloadStaff from "./idcardcanvas/DownloadStaff";
import DynamicHome from "./parentForm/DynamicForm/DynamicHome";
import ViewsDynamicForm from "./parentForm/DynamicForm/ViewsDynamicForm";
import { GetForm } from "./parentForm/DynamicForm/GetForm";
import ViewRecords from "./parentForm/DynamicForm/ViewRecords";

const routing = (
  <Router history={history}>
    <Switch>
      <Route exact path="/holiday/details/:sid" component={HolidayDashboard}  />

      <Route exact path="/parentForm/:sid/:userid" component={ParentForm} />
      <Route exact path="/dynamic/parentForm/:sid" component={DynamicHome} />
      <Route exact path="/dynamic/myforms/:sid" component={ViewsDynamicForm} />
      <Route exact path="/dynamic/forms/:sid/:formid/:userid" component={GetForm} />
      <Route exact path="/dynamic/records/:sid/:formid" component={ViewRecords} />
      
      <Route exact path="/studentinfo/:sid/:userid" component={StudentInfo} />

      <Route exact path="/studentleave/:sid/:userid" component={Leave} />
      <Route
        exact
        path="/studentleave/report/:sid/:userid"
        component={LeaveReport}
      />
      <Route
        exact
        path="/Student/Profile/:sid/:userid"
        component={StudenProfile}
      />
      <Route exact path="/admin/report/:sid/:userid/:id" component={AdminLessonReport} />
      <Route exact path="/admin/updatereport/:sid/:userid/:id" component={UpdateLessonReport} />
      <Route exact path="/admin/lessonPlan/:sid/:userid" component={LessonPlanAdminHome} />
      
      <Route exact path="/lessonHome/:sid/:userid" component={LessonHome} />
      <Route exact path="/lessonPlan/:sid/:userid" component={LessonPlan} />
      <Route exact path="/report/:sid/:userid" component={ReportLessonPlan} />
      <Route
        exact
        path="/reportLessonView/:sid/:userid/:reportid"
        component={ReportView}
      />

      <Route exact  path="/admin/weeklyReport/:sid/:userid"  component={AdminWeeklyReport}/>
      <Route exact path="/admin/reportWeeklyView/:sid/:userid/:reportid" component={AdminReportView}/>
      <Route exact path="/weeklyHome/:sid/:userid" component={WeeklyHome} />
      <Route exact path="/weeklyPlan/:sid/:userid" component={WeeklyPlan} />
      <Route
        exact
        path="/weeklyReport/:sid/:userid"
        component={ReportWeeklyPlan}
      />
      <Route
        exact
        path="/reportWeeklyView/:sid/:userid/:reportid"
        component={WeeklyReportView}
      />
    

      <Route exact path="/studentlog/:sid/:staffid" component={Teacher} />
      <Route
        exact
        path="/studentlog/comment/:user_id/:sid/:staffid"
        component={Comment}
      />
      <Route
        exact
        path="/studentlog/addcomments/:user_id/:sid/:staffid"
        component={AddComments}
      />
      <Route
        exact
        path="/studentlog/admin/:sid/:staffid"
        component={studentlogAdmin}
      />
      <Route exact path="/photocrop/:sid" component={PhotoCropDash} />

      <Route exact path="/classtest/:sid/:userid" component={Classtest} />
      <Route exact path="/attendance/:sid/:userid" component={Attendance} />
      <Route exact path="/calendar/:sid/:userid" component={Calendar} />
      <Route
        exact
        path="/studonlineclass/:sid/:userid"
        component={Studonlineclass}
      />
      <Route
        exact
        path="/teacheronlineclass/:sid/:userid"
        component={Teacheronlineclass}
      />
      <Route exact path="/store/:sid/:userid" component={Home} />
      <Route exact path="/alumni/Login/:sid" component={Loginopt} />
      <Route exact path="/alumni/AlumniHome/:sid" component={AlumniHome} />
      <Route exact path="/alumni/Dashboard/:sid" component={Dashboard} />
      <Route exact path="/alumni/About/:sid" component={About} />
      <Route exact path="/forumapp/:sid/:userid" component={Inbox} />
      <Route exact path="/IdcardCanvas/PrintView/:sid" component={PrintView} />
      <Route exact path="/quizform/:sid/:userid" component={QuizForm} />
      <Route exact path="/clientfollowup/:sid/" component={FollowHome} />

      <Route
        exact
        path="/clientfollowup-topschool/:sid/"
        component={FollowupTopschool}
      />
      <Route exact={true} path="/quizapp/:sid/:uid">
        <div className="App">
          <UQuizzes />
        </div>
      </Route>


      <Route exact={true} path="/quizapp_all/:newSid/:sid/:uid">
        <div className="App">
          <Quizzes2 />
        </div>
      </Route>

      <Route exact={true} path="/quizapp/quizcard/:sid/:uid">
        <div className="App">
          <QuizCard />
        </div>
      </Route>


{/* quizapp_all */}

{/* quizapp_all routes for admin */}

      <Route exact={true} path="/quizapp_all/admin/:newSid/:sid/:uid">
        <div className="App">
          <AQuizzes />
        </div>
      </Route>


      <Route exact={true} path="/quizapp_all/addnewquiz/:newSid/:sid/:uid">
        <div className="App">
          <AAddNewQuiz />
        </div>
      </Route>

      <Route exact={true} path="/quizapp_all/createquestion/:newSid/:sid/:uid">
        <div className="App">
          <AAddQuestion />
        </div>
      </Route>
      <Route exact={true} path="/quizapp_all/editquiz/:fromAdd/:newSid/:sid/:uid">
        <div className="App">
          <AEdit/>
        </div>
      </Route>

      <Route exact={true} path="/quizapp_all/results/:newSid/:sid/:uid">
        <div className="App">
          <AViewResults/>
        </div>
      </Route>



{/*  */}

      <Route exact={true} path="/quizapp_all/quizcard/:newSid/:sid/:uid">
        <div className="App">
          <QuizCard2 />
        </div>
      </Route>


      <Route exact={true} path="/quizapp/review/:sid/:uid">
        <div className="App">
          <Review />
        </div>
      </Route>
      <Route exact={true} path="/quizapp/attemptedquizzes/:sid/:uid">
        <div className="App">
          <AttemptedQuizzes />
        </div>
      </Route>

      <Route exact={true} path="/quizapp_all/attemptedquizzes/:newSid/:sid/:uid">
        <div className="App">
          <AttemptedQuizzes2 />
        </div>
      </Route>
      <Route exact={true} path="/quizapp/leaderboard/:sid/:uid">
        <div className="App">
          <LeaderBoard />
        </div>
      </Route>

      <Route exact={true} path="/quizapp_all/leaderboard/:newSid/:sid/:uid">
        <div className="App">
          <LeaderBoard2 />
        </div>
      </Route>

      <Route exact={true} path="/quizapp/quizdetails/:sid/:uid">
        <div className="App">
          <ViewQuizDetails />
        </div>
      </Route>

      <Route exact={true} path="/quizapp_all/quizdetails/:newSid/:sid/:uid">
        <div className="App">
          <ViewQuizDetails2 />
        </div>
      </Route>



      <Route exact={true} path="/admin/:sid/:uid">
        <div className="App">
          <Quizzes />
        </div>
      </Route>
      <Route exact={true} path="/quizapp/admin/:sid/:uid">
        <div className="App">
          <Quizzes />
        </div>
      </Route>
      <Route exact={true} path="/quizapp/addnewquiz/:sid/:uid">
        <div className="App">
          <AddNewQuiz />
        </div>
      </Route>
      <Route exact={true} path="/quizapp/createquestion/:sid/:uid">
        <div className="App">
          <AddQuestion />
        </div>
      </Route>
      <Route exact={true} path="/quizapp/editquiz/:fromAdd/:sid/:uid">
        <div className="App">
          <EditQuiz />
        </div>
      </Route>

      <Route exact={true} path="/quizapp/results/:sid/:uid">
        <div className="App">
          <ViewResults/>
        </div>
      </Route>

      <Route exact={true} path="/quizapp_all/results/:newSid/:sid/:uid">
        <div className="App">
          <ViewResults2 />
        </div>
      </Route>
      {/*  */}

  
      <Route exact={true} path="/quizapp/checking_answers/:sid/:uid">
        <div className="App">
          <CheckingAnswer />
        </div>
      </Route>
      <Route exact path="/IdcardCanvas/:sid" component={Container} />
      <Route exact path="/IdcardCanvas/download/idcards/:sid/:idesign" component={DownLoadMultiple} />
      <Route exact path="/IdcardCanvas/download/staff/idcards/:sid/:idesign" component={DownloadStaff} />
      <Route exact path="/idcardCanvas/PrintView/:sid" component={PrintView} />

      <Route exact path="/idcardcanvas/staff/:sid" component={StaffContainer} />
      <Route exact path="/Result-Analysis/:sid/:userid" component={ResultA} />
      <Route exact path="/counselling" component={CounsellingTest} />
      <Route
        exact
        path="/IdcardCanvas/generate/:utype/:did/:sid/:userid"
        component={IDGenerate}
      />
      <Route
        exact
        path="/IdcardCanvas/generate/:utype/:did/:sid/:userid"
        component={IDGenerate}
      />
      <Route exact path="/IdcardCanvas/PrintView/:sid" component={PrintView} />
      <Route exact={true} path="/appointment/parent/:sid/:uid">
        <RequestAndStatus />
      </Route>
      <Route exact={true} path="/appointment/user/:sid/:uid">
        <UserLanding />
      </Route>
      <Route exact={true} path="/appointment/user/requestappointment/:sid/:uid">
        <RequestAppointment />
      </Route>
      <Route exact={true} path="/appointment/user/appointmentStatus/:sid/:uid">
        <AppointmentStatus />
      </Route>
      <Route exact={true} path="/appointment/user/appointmentRequest/:sid/:uid">
        <UserRequest/>
      </Route>
      <Route exact={true} path="/appointment/user/groupmeetings/:sid/:uid">
        <GroupMeetings />
      </Route>
      <Route exact={true} path="/appointment/user/requests/:sid/:uid">
        <Requests />
      </Route>
      <Route exact={true} path="/appointment/user/appointmentdetails/:sid/:uid">
        <PAppointmentDetails />
      </Route>
      <Route
        exact={true}
        path="/appointment/user/bookslot/:reschedule/:sid/:uid"
      >
        <PBookSlot />
      </Route>
      <Route exact={true} path="/appointment/user/addparticipants/:sid/:uid">
        <PAddParticipants />
      </Route>
      <Route exact={true} path="/appointment/user/addnotes/:sid/:uid">
        <PAddNotesA />
      </Route>
      <Route exact={true} path="/appointment/admin/:sid/:uid">
        <AppointmentRequests />
      </Route>
      <Route
        exact={true}
        path="/appointment/admin/appointmentdetails/:sid/:uid"
      >
        <AppointmentDetails />
      </Route>
      <Route
        exact={true}
        path="/appointment/admin/bookslot/:reschedule/:sid/:uid"
      >
        <BookSlot />
      </Route>
      <Route exact={true} path="/appointment/admin/addparticipants/:sid/:uid">
        <AddParticipants />
      </Route>
      <Route exact={true} path="/appointment/admin/addnotes/:sid/:uid">
        <AddNotesA />
      </Route>
      <Route exact={true} path="/appointment/superadmin/:sid/:uid">
        <AppointmentR />
      </Route>
      <Route
        exact={true}
        path="/appointment/superadmin/appointmentdetails/:sid/:uid"
      >
        <AppointmentD />
      </Route>
      <Route
        exact={true}
        path="/appointment/superadmin/bookslot/:reschedule/:sid/:uid"
      >
        <AppointmentB />
      </Route>
      <Route
        exact={true}
        path="/appointment/superadmin/addparticipants/:sid/:uid"
      >
        <AddSParticipants />
      </Route>
      <Route exact={true} path="/appointment/superadmin/addnotes/:sid/:uid">
        <AddNotesP />
      </Route>
      <Route exact={true} path="/appointment/parent/:sid/:uid">
        <RequestAndStatus />
      </Route>
      <Route exact={true} path="/idcardinfo/:sid">
        <IDHome />
      </Route>
      <Route exact={true} path="/tickets/user/report/:sid/:uid">
        <TicketReport />
      </Route>
      <Route exact={true} path="/tickets/user/creating/:sid/:uid">
        <RequestTicket />
      </Route>
      <Route exact={true} path="/tickets/user/details/:sid/:uid/:ticket_id">
        <UserReply />
      </Route>
      <Route exact={true} path="/tickets/user/assigned_tickets/:sid/:uid">
        <AssignedTickets />
      </Route>
      <Route exact={true} path="/tickets/admin/dashboard/:sid/:uid">
        <DashboardT />
      </Route>
      <Route exact={true} path="/tickets/admin/details/:sid/:uid/:ticket_id">
        <AdminTicket />
      </Route>
      <Route exact={true} path="/tickets/admin/reports/:sid/:uid">
        <TDashboard />
      </Route>
      <Route exact={true} path="/tickets/superadmin/dashboard/:sid/:uid">
        <SADashboard />
      </Route>
      <Route
        exact={true}
        path="/tickets/superadmin/details/:ssid/:sid/:uid/:ticket_id"
      >
        <SATicketReply />
      </Route>
      <Route exact={true} path="/tickets/superadmin/reports/:sid/:uid">
        <TMSAReports />
      </Route>
      <Route exact={true} path="/credin/:sid">
        <Cred />
      </Route>
      <Route exact={true} path="/sessionlog/:sid">
        <SessionReport />
      </Route>
      <Route exact={true} path="/sessionlog/:sid/:staffid">
        <SessionReport />
      </Route>

      <Route path="/wallet" exact>
        <StartingPage />{" "}
      </Route>
      <Route path="/wallet/auth/changepassword" exact>
        <ChangePassword />{" "}
      </Route>
      <Route path="/wallet/add-balance" exact>
        <AddBalance />{" "}
      </Route>

      <Route path="/wallet/transaction" exact>
        {" "}
        <Transaction />
      </Route>
      <Route path="/wallet/admin" exact>
        {" "}
        <Admin />{" "}
      </Route>
      <Route path="/wallet/admin/load" exact>
        {" "}
        <AdminLoad />{" "}
      </Route>
      <Route path="/wallet/pos" exact>
        {" "}
        <Pos />{" "}
      </Route>
      <Route path="/wallet/pos/new-sale" exact>
        {" "}
        <PosSale />{" "}
      </Route>
      <Route path="/wallet/pos/reports" exact>
        {" "}
        <PosReport />{" "}
      </Route>
      <Route path="/wallet/pay" exact>
        {" "}
        <Pay />{" "}
      </Route>
    </Switch>
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
