import React from "react";
import styled from "styled-components";



const TableView = ({ data, tcby, title, Month }) => {

  return (
    <>
      <Table>
        {data.length > 0 ? (
          <thead>
            <tr>
              <th
                style={{ textAlign: "center", fontWeight: "bold" }}
                rowspan="3"
              >
                S.No
              </th>
              <th
                style={{ textAlign: "center", fontWeight: "bold" }}
                rowspan="3"
              >
                {tcby}
              </th>
            </tr>
            <tr
              style={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <th
                style={{ textAlign: "center", fontWeight: "bold" }}
                colspan="4"
              >
                {title}
              </th>
            </tr>

            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "rgb(219, 248, 113)",
                }}
              >
                All
              </th>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "rgb(255, 201, 201)",
                }}
              >
                Open
              </th>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "rgb(190, 254, 199)",
                }}
              >
                Closed
              </th>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "#efe9e68c",
                }}
              >
                Hold
              </th>
            </tr>
          </thead>
        ) : (
          <Nodata>
            {/* <h3>Data Not Availble For Year {Month}</h3> */}

          </Nodata>
        )}

        <tbody>
          {/* Map data */}
          {data
            ? data.map((item, idx) => {
                return (
                  <tr className="tr" key={idx}>
                    <td style={{ textAlign: "center" }} rowspan="1">
                      {idx + 1}
                    </td>

                    {/* USER TICKET CREATED NAME */}
                    <td style={{ textAlign: "start" }} rowspan="1">
                      {item.name? item.name : "_"}
                    </td>

                    {/* OPEN, CLOSED, HOLD STATUS  */}
                    {item.status
                      ? item.status.split(",").map((item, idx) => {
                          return (
                            <>
                              <td style={{ textAlign: "center" }} rowspan="1">
                                {item
                                  ? (item.match(/Open/g) || []).length +
                                    (item.match(/Closed/g) || []).length +
                                    (item.match(/Hold/g) || []).length
                                  : null}
                              </td>
                              <td style={{ textAlign: "center" }} rowspan="1">
                                {(item.match(/Open/g) || []).length}
                              </td>

                              <td style={{ textAlign: "center" }} rowspan="1">
                                {(item.match(/Closed/g) || []).length}
                              </td>

                              <td style={{ textAlign: "center" }} rowspan="1">
                                {(item.match(/Hold/g) || []).length}
                              </td>
                            </>
                          );
                        })
                      : null}

                    {/* DATE
              <td style={{ textAlign: "center" }} rowspan="1">
                {item.date1 ? item.date1.substring(0, 11) : "_"}
              </td> */}
                  </tr>
                );
              })
            : "NO REPORT"}
        </tbody>
      </Table>
    </>
  );
};

export default TableView;

const Nodata = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 900;
  font-size: 2rem;
  margin-top: 76px;

  h3 {
    color: #111;
  }
`;

const Table = styled.table`
  font-family: arial, sans-serif;
  letter-spacing: 0.1rem;
  border-collapse: collapse;
  font-weight: 700;
  color: #222;
  width: 100%;

  td,
  th {
    border: 1px solid #eee;
    text-align: left;
    padding: 8px;
  }
  td {
    margin: 5px 0;
  }

  .tr {
    height: 50px;
  }

  .tr:nth-child(even) {
    background-color: #cdffea;
  }
`;
