import React from 'react'
import StudentList from './StudentList'
import Style from './Pos.module.css'
import Axios from 'axios'

const PosReport = () => {

  const apiHandler = () => {
    Axios.post(" https://api.paykit.in/get-tnx-list", {}, {
      headers: { Authorization: "" }
    }).then(res => console.log(res))
      .catch(err => console.log(err))
  }

  return (
    <div>
      <div className={Style.quicksearch}>
        <span>Quick search</span>
        <div className={Style.dateInput}>
          <span>Start Date</span><input type="date" name="" id="" />
        </div>
        <div className={Style.dateInput}>
          <span>End Date</span><input style={{ marginLeft: "0.5rem" }} type="date" name="" id="" />
        </div>

      </div>
      <div>
        <StudentList isPos={true} />
        <StudentList isPos={true} />
        <StudentList isPos={true} />
        <StudentList isPos={true} />
        <StudentList isPos={true} />

      </div>
    </div>
  )
}

export default PosReport