import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const useStyles = makeStyles((theme) => ({
  root: {
    margin:0,
    maxWidth: '100%',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
    marginTop:'7%',
    marginLeft:240,
    maxWidth: '80%',
    flexGrow: 1,
    
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    //maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft:'20%',
      width:'60%'
    }
  },
}));

function LatestItems(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  //const [selected,setSel]=React.useState();
  const maxSteps = 3;


  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      {/* <Paper square elevation={0} className={classes.header}>
        <Typography>{tutorialSteps[activeStep].label}</Typography>
      </Paper> */}
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
      
        {props.latest.map((step, index) => (
          <div key={step.label}>
            {console.warn("LA-->"+JSON.stringify(props.latest))}
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step.prod_img=="" || step.prod_img==null ?
              "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS6Dw1Etemhe9K_LEK8pXzVO0X2tnqkMW8q4Q&usqp=CAU" :step.prod_img.includes(',')?
              step.prod_img.substr(0,step.prod_img.indexOf(',')):step.prod_img
              }  alt={step.label} onClick={()=>props.select(step.p_name)} />

            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      {/* <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
      
      /> */}
    </div>
  );
}

export default LatestItems;
