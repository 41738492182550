import { Button } from '@material-ui/core'
import { useParams } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from 'react'
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import SuccessPage from './SuccessPage';

export const GetForm = () => {
    const { sid, formid, userid } = useParams();
    const [formTitle, setFormsTitle] = useState("");
    const [formData, setFormData] = useState([]);
    const [apiloader, setapiLoader] = useState(false);
    const [submitData, setSubmitData] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [recordId, setRecordId] = useState(null);
    const [updatedStaticData, setUpdatedStaticData] = useState([]);

    const getData = async () => {
        setapiLoader(true);
        try {
            const data = {
                "sid": sid,
                "id": formid,
                "userid": userid
            }
            await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASE_URL}/api/information/getsingle/dynamicform`,
                //url: `http://localhost:8080/api/information/getsingle/dynamicform`,
                data,
                headers: {
                    Authorization: "#$laksdfnlknoea#$@$%^&%$",
                },
            })
                .then((response) => {
                    setFormData(response.data.form_data);
                    setFormsTitle(response.data.title);
                    setIsUpdated(response.data.updated);
                    setShowSuccess(response.data.updated);
                    setRecordId(response.data.id)
                    setapiLoader(false);
                })
                .catch((error) => {
                    setapiLoader(false);
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        } catch (error) {
            setapiLoader(false);
            Swal.fire({
                position: "center",
                icon: "warning",
                title: error,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }

    const InsertData = async (e) => {
        e.preventDefault();
        setapiLoader(true);
        try {
            const data = {
                sid,
                "userId": userid,
                "formId": formid,
                "records": submitData,
                "recordId": isUpdated ? recordId : null,
                "ExistingData": updatedStaticData
            }
            await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASE_URL}/api/information/${isUpdated ? 'update' : 'insert'}/dynamicrecord`,
                //url: `http://localhost:8080/api/information/${isUpdated ? 'update' : 'insert'}/dynamicrecord`,
                data,
                headers: {
                    Authorization: "#$laksdfnlknoea#$@$%^&%$",
                },
            })
                .then((response) => {
                    setFormData([]);
                    setSubmitData([]);
                    setUpdatedStaticData([]);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                   
                    getData();
                })
                .catch((error) => {
                    setapiLoader(false);
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: error,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        } catch (error) {
            setapiLoader(false);
            Swal.fire({
                position: "center",
                icon: "warning",
                title: error,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (formData || formData.length > 0) {
            for (let index = 0; index < formData.length; index++) {
                submitData.push(formData[index].value)
            }
        }
    }, [formData])

    const SetRecords = (index, data, field) => {
        submitData[index] = data;
        if (field.status === "existing") {
            updatedStaticData[index] = { fieldName: field.fields, fieldValue: data, fieldDatabase: field.tablename }

        }
        console.log(updatedStaticData)
    }

    return (
        <>  {
            apiloader ? (
                <div className="TMLoader">
                    <Loader type="ThreeDots" color="#bd2f5e" height={80} width={80} />
                </div>
            ) :
                <div className='mx-auto' style={{ maxWidth: "600px" }}>
                    {
                        showSuccess ? <SuccessPage formTitle={formTitle} setShowSuccess={setShowSuccess} />
                            :
                            <>
                                <h2 className='mt-4'>{formTitle}</h2>
                                <div className='card mt-4'>
                                    <div className="card-body">
                                        <form onSubmit={InsertData}>
                                            {formData.length > 0 ? (
                                                <>
                                                    {
                                                        formData.map((field, index) => (
                                                            <div key={index} className='form-group'>
                                                                {field.type === 'label' ? (
                                                                    <h5 className='text-center'>{field.Title}</h5>
                                                                ) : (
                                                                    <>
                                                                        <label htmlFor={`input${index}`}>{field.Title}</label>
                                                                        <input
                                                                            name={`fields[${index}].name`}
                                                                            placeholder={`${field.Title}`}
                                                                            type={field.type}
                                                                            defaultValue={field.value}
                                                                            className="form-control"
                                                                            id={`input${index}`}
                                                                            required
                                                                            onChange={
                                                                                (e) => {
                                                                                    SetRecords(index, e.target.value, field)
                                                                                }
                                                                            }
                                                                        // {field.status === "existing" ? 'readonly' : null}
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                        ))
                                                    }

                                                    <button type='submit' className='btn btn-primary m-0'>{isUpdated ? 'Update' : 'Submit Data'}</button>
                                                </>
                                            ) : null}
                                        </form>
                                    </div>
                                </div>
                            </>
                    }

                </div>
        }
        </>
    )
}
