import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from 'axios';
import Loader from "react-loader-spinner";
import NotAvailable from '../../common/NotFound';

export default function Quizzes() {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column", width: "100%", height: "150%"
        }}>
            <Quiz />
        </div>
    )
}
function Quiz() {

    const history = useHistory();
    const { sid, uid } = useParams()
    const [quizlist, setQuizlist] = useState([{ quizName: "Quizzes Not Created Yet" }]);
    const [aquizzes, setaquizzes] = useState([]);
    const [search, setSearch] = useState("");
    const [loader, setLoader] = useState(true);
    const [quizzes, setQuizzes] = useState();
    const [studentname, setStudentname] = useState('');
    const [userid, setuserid] = useState(0);
    sessionStorage.clear();
    sessionStorage.setItem("lpath", history.location.pathname)
    try {
        if (sessionStorage.isAdmin === undefined) {
            sessionStorage.setItem("isAdmin", false)
        }
    } catch (e) {
        console.error(e);
    }

    useEffect(() => {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Quizapp_Stud_Info", sid: sid, u_id: uid })
            .then(res1 => {
                try {
                    if (typeof (res1.data) === 'object' ? res1.data.student.length !== 0 ? res1.data.student[0].div_name !== null ?
                        res1.data.student[0].class_name !== null ? res1.data.student[0].name !== null : false : false : false : false) {
                        setStudentname(res1.data.student[0].name); setuserid(res1.data.student[0].user_id);
                        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Quizapp_Get_Quizlist", sid: sid, uid: uid })
                            .then(res2 => {
                                axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Quizapp_Get_QuizResultByUID", sid: sid, userid: uid })
                                    .then(res3 => {
                                        const resultsObj = (res3.data.quizresults).reduce((acc, i) => {
                                            return ({ ...acc, [i.quizid]: i })
                                        }, {})
                                        setQuizlist(
                                            (res2.data.quizlist).filter((quiz) => {
                                                return (quiz.quizStatus === "1"
                                                    && (quiz.class.split(',').includes(res1.data.student[0].class_name))
                                                    && (quiz.division.split(',').includes(res1.data.student[0].div_name))
                                                    // && (res3.data.quizresults.some(q => quiz.id === q.quizid && parseInt(quiz.attempts === "0" ? "100" : quiz.attempts) > parseInt(q.attempts)) || !res3.data.quizresults.some(q => (quiz.id) === q.quizid))
                                                    && (resultsObj[quiz.id] ? parseInt(quiz.attempts === "0" ? "100" : quiz.attempts) > parseInt(resultsObj[quiz.id].attempts) : true)
                                                )
                                            }).reverse()
                                        );
                                        setaquizzes(resultsObj)
                                        setLoader(false);
                                    })
                            })
                    } else {

                        setLoader(false);
                        setQuizzes([])
                    }
                } catch (e) {
                    console.error(e);
                }
            })

    }, []);

    useEffect(() => {
        try {
            setQuizzes(quizlist.filter((quiz) => {
                return quiz.quizName.toLowerCase().indexOf(search.toLowerCase()) !== -1 && quiz.deleted !== "1"
            }))
        } catch (e) {
            console.error(e);
        }
    }, [search, quizlist])


    const fm = {
        fontFamily: "Poppins",

    }
    return (
        <>

            <div className="quiz-container" style={{ padding: "0px" }}>
                <div style={{
                    display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginBottom: "10px", backgroundColor: "#eff1f1", padding: "5px 0px", border: "10px", borderColor: "black", transition: '10s',
                    color: "rgba(4, 44, 173, 0.856)",
                    fontSize: window.innerWidth > 500 ? "25px" :
                        window.innerWidth > 400 && window.innerWidth < 500 ? "22px" :
                            window.innerWidth > 300 && window.innerWidth < 350 ? "18px" :
                                window.innerWidth > 350 && window.innerWidth < 400 ? "20px" : "15px", height: "50px"
                }}>
                    <div style={{ width: "50%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", cursor: "pointer" }} onClick={() => { history.push('/quizapp' + '/' + sid + '/' + uid, userid) }}>
                        <h5 style={{ textAlign: "center", fontFamily:'Poppins' }}>Active Quizzes <hr className="hr-line" style={{ width: "100%", height: "3.2px", transition: '3s' }} /></h5>
                    </div>
                    <div style={{ width: "50%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", cursor: "pointer" }} onClick={() => { history.push('/quizapp/attemptedquizzes' + '/' + sid + '/' + uid, userid) }}>
                        <h5 style={{ textAlign: "center", fontFamily:'Poppins' }}>Attempted Quizzes</h5>
                    </div>
                </div>
                <div className="sub-container">
                    <div className="search-div">
                        <input style={fm} className="quiz-search" type="text" placeholder=" Quick Search" onChange={e => { setSearch(e.target.value) }} />
                    </div>
                    {loader ?
                        <div id="loader">
                            <Loader
                                type="Oval"
                                color="#0066ff"
                                height={70}
                                width={70}
                            /></div> :
                        <div className="quiz-list">
                            <div className="quiz-list-items">
                                {quizzes.map((quiz, key) => {
                                    let count = 0;
                                    if (quiz.quizName !== "Quizzes Not Created Yet") {
                                        return (
                                            <div key={key} className="quiz-card" style={{ paddingBottom: "1px", cursor: "pointer" }}
                                                onClick={() => {
                                                    // const ioru = document.getElementById(quiz.id) === null ? 'insert' : aquizzes[document.getElementById(quiz.id).innerHTML]
                                                    const ioru = aquizzes[quiz.id] ? aquizzes[quiz.id] : 'insert'
                                                    history.push('/quizapp/quizdetails' + '/' + sid + '/' + uid, { ...quiz, ioru: ioru, userid: userid, studentName: studentname })
                                                }} >
                                                <div className="quiz-title" style={fm}>
                                                    {key + 1} . {quiz.quizName}
                                                </div>
                                                <div className="quiz-options">
                                                    <label style={{ fontSize: "15px", padding: "10px", fontFamily:'Poppins'  }}
                                                    >Date:{((quiz.publishDate).split('-'))[2] + "-" + ((quiz.publishDate).split('-'))[1] + "-" + ((quiz.publishDate).split('-'))[0]}</label>
                                                    {aquizzes[quiz.id] ?
                                                        aquizzes[quiz.id].status === "pending" ? <></>
                                                            :
                                                            parseInt(quiz.attempts === "0" ? "100" : quiz.attempts) > parseInt(aquizzes[quiz.id].attempts) ?
                                                                <label
                                                                    style={{ fontSize: "17px", padding: "10px", fontFamily:'Poppins', color: (aquizzes[quiz.id].score || parseInt(aquizzes[quiz.id].score) !== 0 ? Math.round((aquizzes[quiz.id].score / aquizzes[quiz.id].total) * 100) : 0) > 20 ? "#00a500" : "rgba(255, 0, 64, 0.705)" }}>
                                                                    Scored:{aquizzes[quiz.id].score || parseInt(aquizzes[quiz.id].score) !== 0 ? Math.round((aquizzes[quiz.id].score / aquizzes[quiz.id].total) * 100) : 0}%
                                                                </label>
                                                                : null
                                                        : null

                                                    }
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                                }
                                <div className="quiz-notfound" style={{ display: quizzes.length === 0 ? "block" : "none" }}>
                                    <NotAvailable title={'Quizzes Not Available'}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}


