import React, { useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom'
import ScoreAnimate from './ScoreAnimate';
import Navbar from './navbar';


export default function LeaderBoard() {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Quiz />
        </div>
    )
}

function Quiz() {
    useEffect(() => {
        window.onbeforeunload = () => { sessionStorage.setItem("lpath", history.location.pathname) }
    }, [])
    const history = useHistory();
    const { sid, uid } = useParams();
    try {
        if (history.location.state === undefined) {
            return <Redirect to={"/quizapp" + '/' + sid + '/' + uid} />
        }
    } catch (e) {
        console.error(e);
    }
    sessionStorage.setItem("lpath", history.location.pathname)


    return (
        <div className="quiz-container" style={{ backgroundColor: "white" }}>
            <div className="quiz-head"><div>Score</div>
                <Navbar home={{ isHome: true, home: `/quizapp/${sid}/${uid}` }} back={false} refresh={false} /></div>
            <hr className="hr-line" />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <h4 style={{ color: "#0066ff",fontFamily:'Poppins' }}>Quiz Name : {history.location.state.his.quizName}</h4>
                {history.location.state.validate ?
                    <>
                        <h3 style={{ color: "#0066ff", textAlign: "center",fontFamily:'Poppins' }}>Congratulations</h3>
                        <div style={{ color: "#0066ff", textAlign: "center",fontFamily:'Poppins' }}>
                            <i style={{fontFamily:'Poppins' }}>
                                You have successfully completed your quiz
                                <br /><br />
                                Your answers submitted for checking, you will get results soon
                            </i>
                        </div>
                    </>
                    :
                    <>
                        <div style={{ width: "50%", height: "50%", marginTop: "5%" }}>
                            <ScoreAnimate score={history.location.state.score !== 0 || history.location.state.score ? Math.round((history.location.state.score / history.location.state.total) * 100) : 0} />
                        </div>
                        <h3 style={{ color: "#0066ff", textAlign: "center",fontFamily:'Poppins'  }}>Congratulations</h3>
                        <div style={{ color: "#0066ff", textAlign: "center",fontFamily:'Poppins'  }}><i>You have successfully completed your quiz</i></div>
                    </>
                }
                <h3 className="create-button" onClick={() => { history.push('/quizapp/results' + '/' + sid + '/' + uid, { id: history.location.state.his.id, quizName: history.location.state.his.quizName }) }}>See LeaderBoard</h3>
            </div>

        </div>
    );
}
