import React, { forwardRef } from "react";
import { Canvas } from "react-design-editor";
import { memo } from "react";


function Canvsd(props, ref) {
  React.useEffect(() => {
    document.getElementsByTagName("title")[0].innerHTML = "IDcard Design";
  }, []);
  return (
    <Canvas
      style={{
        width: 1005,
        height:1600,
        top: -320,
        left: 60,
        border: "2px solid",
        backgroundColor: "white",
        transform:"scale(0.4)"
      }}
      workareaOption={{ width: 1005, height: 1600 }}
      editable={props.editable}
      zoomEnabled={false}
      ref={ref}
    />
  );
}

const Canvasfd = forwardRef(Canvsd);
export default memo(Canvasfd);

//width: 900, height: 900 }