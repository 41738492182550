import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Info(props) {


  return (
    <div>
      <Dialog onClose={props.close} aria-labelledby="customized-dialog-title" open={true}>
        <DialogTitle id="customized-dialog-title" onClose={props.close}>
          School Information
        </DialogTitle>
        <DialogContent dividers>
            <img src={props.data.photo} style={{maxHeight:'100%',maxWidth:'100%'}}/>
          <p>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>School Name</p> - {props.data.school_name} <br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>Strength</p> - {props.data.strength}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>Board </p>- {props.data.board}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>principal Name</p> - {props.data.principal_name}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>principal Contact</p> - {props.data.principal_contact}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>IT Person Name</p> - {props.data.it_person_name}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>IT Person Contact</p> - {props.data.it_person_contact}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>Director Name</p>- {props.data.director_name}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>Director Contact</p> - {props.data.director_contact}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>Person Met</p> - {props.data.person_met}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>School Address</p> - {props.data.school_addr}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>School City</p> - {props.data.school_city}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>Email</p> - {props.data.email}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>Using ERP</p> - {props.data.using_erp}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>ERP NAME</p> - {props.data.erp_name}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>Interest Level</p> - {props.data.interest_level}<br/>
              <p style={{color:'#f50057',marginTop:0,marginBottom:0,display:'contents'}}>School Infra</p> - {props.data.School_infra}<br/>

          </p>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.close} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
