import CryptoJS from "crypto-js";


export const DecryptED = (userid) => {
    if (userid.includes('enc-')) {
        var dec = userid.split('enc-')[1];
        console.log(userid);
        // window.alert(dec);
        // var cipher = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(dec));   

        var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_KEY);
        var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_IV);
        // var encryptedCP = CryptoJS.AES.encrypt("258", key, {​​​​​​​​ iv: iv }​​​​​​​​);
        var decryptedWA = CryptoJS.AES.decrypt(atob(dec), key, { iv: iv });

        // var encryptedBase64 = encryptedCP.toString();                              
        var decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8);

        return decryptedUtf8;
    }
    return userid
}