import jsPDF from "jspdf";
import "jspdf-autotable";

const generatePDF = (reports) => {
  console.log(reports)
  const doc = new jsPDF("l", "mm", [841, 594]);

  const tableColumn = [
    "Teacher",
    "Subject",
    "Lesson Name",
    "Created On"
  ];
  const tableRows = [];
  reports.forEach((report) => {
    const reportData = [
      report.Teacher,
      report.subject,
      report.Lessonname,
      report.created_date
    ];
    tableRows.push(reportData);
  });

  doc.autoTable(tableColumn, tableRows, { startY: 20 });

  const date = Date().split(" ");
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  doc.text("Lesson Reports", 14, 15);
  doc.save(`lesson_reports_${dateStr}.pdf`);
};

export default generatePDF;
