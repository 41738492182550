import React,{useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Button,TextField,Box} from '@material-ui/core';
import Resizer from 'react-image-file-resizer';
import AchiveImageUp from './AchiveImageUp';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY:'scroll'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UploadImg(props) {
    const classes = useStyles();
    const [result, setRes]= useState(null);
    const [imgarr, setImg] = useState([]);
    const [prvw,setPrvw]=useState([]);
    const [filename,setFileName]=useState([]);
    const [fileimg,setFileimg]=useState(null);
    const {sid} = useParams();

    const [FileLocal,setFile]=useState([]);
    const [Foldername,setFolderName]=useState(null);
    const [photo,setPhoto] = React.useState(null);
    const Cancel=()=>{
       setPhoto(null)
    }

  useEffect(()=>{
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"AlumniGetSharedPhoto",['sid']:sid})
    .then(res=> {
         console.log(res);
         
        setRes(res)}).catch(err =>console.log("Error-"+err));
  },[])
    const SubmitA=()=>{
        const formData= new FormData();
        formData.append("file",photo);
        formData.append("fun_name",'AlumniSharePhoto');
        formData.append("sid",sid);
        formData.append("userid",props.ud.data[0].userid);
        formData.append("name",props.ud.data[0].full_name)
        if(props.sid!=null && props.ud.data[0].userid!=null && photo!=null){
            console.log(formData)
           
         axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?",formData).then((resp)=>{
         // console.log("1-->"+resp.data.Achievement);
        if(resp.data.isShared=='1'){
          Swal.fire(
            "Success",
            "Successfully submited your Achievement",
            'success'
        )        
          
          window.location.reload(false);

        }
        })     
      }
      else{
        window.alert("Please Enter All Fields !")
      }
    }
  const Getfile=(file)=>{
    console.log(file)
    setPhoto(file);
  }
return (
  <div>
    {result!=null? result.data.alumni_info[0].details!=undefined?<Box fontFamily="Monospace" fontSize="h6.fontSize" fontStyle='italic' m={1}>{result.data.alumni_info[0].details}</Box>:null:null}
<div style={{justifyContent:'center',marginTop:80,marginLeft:'27vw'}}>
    <AchiveImageUp file={Getfile}  />
    {photo !=null? <Button  style={{margin:20}} variant="contained" color="primary" component="span" onClick={()=>SubmitA()} >Submit</Button>:null}
    </div>
    </div>
          );
        }