import React,{ useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Select,InputLabel,MenuItem,FormControl,Button,Typography} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Report from './Report';
import Swal from 'sweetalert2'
function isValid(date, h1, m1, h2, m2) {
  var h = date.getHours();
  var m = date.getMinutes();
  return (h1 < h || h1 == h && m1 <= m) && (h < h2 || h == h2 && m <= m2);
}
const useStyles = makeStyles((theme) => ({
    '@global': {
        ".MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense":{
            color:'black'
        },
        '*:focus': {
            outline: ' none !important'
          },
    },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background:`linear-gradient(323deg, rgba(2,0,36,1) 0%, rgba(9,218,205,1) 0%, rgba(10,180,240,1) 46%, rgba(21,203,158,0.6783088235294117) 88%, rgba(0,212,255,1) 100%)`,
        // backgroundColor:'#2996ed',
        textAlign:'center',
        width:190
      },
  
  }));
  export default function SessionLog() {
    const classes = useStyles(); 
    const {sid}=useParams() ;
    const {staffid}=useParams() ;

    const [div, setDiv] = React.useState(null);
    const [divlist, setDivlist]=React.useState(null);
    const [view, setView]=React.useState(false);
    const [olstud_report, setOlstud]=React.useState(null);
    const [stud_data, setStud_data]=React.useState(null);
    const [stud_log, setLog]=React.useState(null);
    const [finalarray, setFinalArray]=React.useState(null);
    const [istime, setTime]=React.useState(false);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    today = yyyy+'-'+mm+'-'+dd;
    const [date, setDate] = React.useState(today);
    useEffect(()=>{
      if(isValid(new Date(), 13, 0, 23, 59)){
        setTime(true)
      }
    },[])
  useEffect(()=>{
    if(staffid==undefined){
      axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'GetClassDiv',['sid']:sid}).then (res=> {
    setDivlist(res.data.div); 
    });
    }
    else{
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'GetClassDiv',['sid']:sid}).then (res=> {
      axios.post('https://topschool.prisms.in/rest/index.php/staff_list.json?',{['fun_name']:'getStaffDetails',['sid']:sid,['staff_id']:staffid}).then (res1=> {
    setDivlist(res1.data.class_details); 
    });
  });
    }
    },[])
    
    const handleView=()=>{
      if(view==true){
        setView(false)
      }
      if(date!=null && div!=null){
      axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'AttendanceReport',['sid']:sid,['classdiv']:div,['logdate']:date}).then (res=> {
       console.log('data',res.data.olstud_report)
       setOlstud(res.data.olstud_report)
       setStud_data(res.data.stud_data)
       setLog(res.data.stud_log)
       setFinalArray(res.data.studttdetailsArray)
      setView(true)
        });
      }
      else{
        Swal.fire(
          "Alert",
          "Date and Div are not Selected",
          "warning"
        )
      }
    }
    return (
        istime? <div style={{marginTop:20,marginLeft:10}}>
        <Typography style={{fontSize:'1.4rem', color:'#2996ed'}}>Attendance Report</Typography>
        <div style={{marginTop:20,display:'flex'}}>
            
        <TextField style={{marginRight:10}}
            fullWidth
          type="date"
          inputProps={{
            style: {
                color:'black',
                fontWeight: 'initial',
                
            }
         }}
         InputLabelProps={{
            shrink: true, 
          }}
          label="Select Date"
          value={date}
          onChange={(e)=>{setDate(e.target.value)} }
          defaultValue={date}
          variant="outlined"
          size="small"
          
        />
       <FormControl fullWidth className={classes.formControl} size="small">
        <InputLabel variant="outlined" >
        Select Div
        </InputLabel>
        <Select 
        label="Select Div"
        style={{marginRight:20}}
         variant="outlined"
          value={div}
          onChange={(e)=>setDiv(e.target.value)}
          
          className={classes.selectEmpty}
        >
            {divlist!=null && staffid!=undefined?divlist.map((v,i)=>
            <MenuItem value={v.id}>{v.div_name}</MenuItem>
            ):divlist!=null && staffid==undefined?divlist.map((v,i)=>
            <MenuItem value={v.divid}>{v.div_name}</MenuItem>
            ):null}
        </Select>
      </FormControl>
      </div>
      <div style={{textAlign:'center'}}>
      <Button

             onClick={handleView}
            size="medium"
            variant="contained"
            // color="primary"
            className={classes.submit}
          >
            Check
          </Button>
          </div>
         {view? <div>
            <Report olstud_report={olstud_report} stud_data={stud_data} stud_log={stud_log} SD={finalarray} />
          </div>:null}
      </div>:
      <div>
        <Typography style={{fontSize:'1.4rem', color:'#2996ed',textAlign:'center',marginTop:40}}>Attendance Report</Typography>
        <div>
        <Typography style={{fontSize:'1.2rem', color:'#2996ed',textAlign:'center',marginTop:'30vh'}}>This report will be avaliable post 1 PM</Typography>
        <Typography style={{fontSize:'1.2rem', color:'#2996ed',textAlign:'center'}}>Please Check after 1 PM</Typography>

        </div>
      </div>
        );
  }