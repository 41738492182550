import React from 'react';
import TicketReply from '../ReusedComponents/TicketReply'
import { useParams, useHistory } from 'react-router-dom'

export default function AdminTicket() {
    const history = useHistory();
    const { sid, uid } = useParams();

    return (
        <div className="totalTicketingModule">

            <TicketReply
                userType={history.location.state.userType}
                title={history.location.state.title}
                options={[{ title: "Dashboard", url: `/tickets/admin/dashboard/${sid}/${uid}` }, { title: "Reports", url: `/tickets/admin/reports/${sid}/${uid}` }]}
            />

        </div>
    )
}