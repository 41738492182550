import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { useState } from "react";
import styled from "styled-components";
import Loader from "../../common/Loader";
const Home = ({ report, loader }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log("calling when sort is called");
    function combine(arr) {
      var combined = arr.reduce(function(result, item) {
        var current = result[item.occasion];

        result[item.occasion] = !current
          ? item
          : {
              id: item.id,
              occasion: current.occasion,
              details: item.details,
              startdate: current.startdate,
              endDate: item.startdate,
            };

        return result;
      }, {});

      return Object.keys(combined).map(function(key) {
        return combined[key];
      });
    }

    const result = combine(report);
    setData(result);
  }, [report]);

  function SortData() {
    setData((data) => [...data.reverse()]);
  }

  return (
    <>
      <Container>
        {loader ? (
         <Loader/>
        ) : (
          <Wrapper>
            {data.length > 0 && (
              <div className="sortFunction">
                <div>
                  <span className="sort">sort</span>{" "}
                  <BiSortAlt2 className="sort__icon" onClick={SortData} />
                </div>
              </div>
            )}

            {data.length > 0 ? (
              data.map((item) => {
                const { occasion, startdate, endDate } = item;
                return (
                  <ContentBox key={item.id}>
                    <h4 style={{ color: "#0043ff", fontFamily:'Poppins', fontWeight:500 }}>{occasion}</h4>
                    <div className="innerBox">
                      <div style={{ width: "50%", fontSize: "0.9rem" }}>
                        <span style={{
                          fontFamily:'Poppins', fontWeight:500
                        }}>From: </span>
                        <span className="from">
                          {startdate
                            ? moment(startdate).format("Do MMM YY")
                            : null}
                        </span>
                      </div>

                      <div style={{ width: "50%", fontSize: "0.9rem" }}>
                        <span  style={{
                          fontFamily:'Poppins',fontWeight:500
                        }}>To: </span>
                        <span className="to">
                          {endDate
                            ? moment(endDate).format("Do MMM YY")
                            : moment(startdate).format("Do MMM YY")}
                        </span>
                      </div>
                    </div>
                  </ContentBox>
                );
              })
            ) : (
              <NoContent>
                <p></p>
              </NoContent>
            )}
          </Wrapper>
        )}
      </Container>
    </>
  );
};

export default Home;

const Container = styled.div`
  width: 100%;
  height: 80vh;
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 25px auto;
  padding: 20px;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 768px) {
    width: 95%;
    padding: 0;
    box-shadow: none;
    margin: 0 auto;
    height: 80vh;
  }

  .sortFunction {
    margin-left: 5px;

    div {
      span {
        color: #746e6e;
        font-weight: 500;
        font-family: Poppins;

      }
      .sort__icon {
        background-color: #65656f;
        color: #fff;
        margin-left: 5px;
        border-radius: 4px;
        &:hover {
          background-color: #0b0b8a;
        }
      }
    }
  }
`;

const ContentBox = styled.div`
  width: 98% !important;
  transition: all 0.3s ease 0s;
  padding: 10px;
  border: 0px none;
  font-weight: 400;
  box-shadow: rgba(235, 235, 235, 0.16) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 2px 10px 0px;
  margin: 12px auto;
  border-radius: 3px;

  .innerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .to {
      font-family: Poppins;

    }
    .from {
      font-family: Poppins;

    }
  }
  /* &:hover {
    background-color: #dff7e0;
    border: none;
  } */
`;

const NoContent = styled.div`
  width: 100%;
  height: 80px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 124px;
  border-radius: 3px;

  p {
    color: #e53690;
    font-size: 1.3rem;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 900;
    font-family: Poppins;

  }
`;
