import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Typography, TextField, Paper, IconButton, InputBase,Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import CommentIcon from '@material-ui/icons/Comment';
import CallIcon from '@material-ui/icons/Call';
import SortIcon from '@material-ui/icons/Sort';
import moment from 'moment';
import axios from 'axios';
import Chip from './chip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Clientinfo from './clientinfo';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'hidden',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  inputInput: {
    backgroundColor: 'white',
    color: 'inherit',
    padding: theme.spacing(1, 1, 1, 0),

    transition: theme.transitions.create('width'),
    width: '80%',
    //border:'1px solid',
    marginTop: 5,
    marginLeft: 10,
    marginBottom:10
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    },
    '*:focus': {
      outline: ' none !important'
    },
  },

}));

export default function Comments(props) {
  const classes = useStyles();
  const [comment, setComment] = useState(null);
  const [isform, setForm] = useState(false);
  const [date, setDate] = React.useState(null);
  const [pmet, setPmet] = React.useState(null);
  const [time,setTime]=   React.useState(null);
  const [interest,setInt]=   React.useState(null);
  const [demo,setDemo]=   React.useState(null);
  const [vtype,setVtype]=   React.useState(null);

  const [propComment, setPComment] = useState();

  const [isprof, setProf] = useState(false);
  const [adminu, setAdmin] = useState(0);
  const [uida, setUID] = useState(null);

  useEffect(() => {

    var Carr = [];

    if (props.cmt != null && props.cmt != undefined) {
      for (var i = 0; i < props.cmt.length; i++) {
        //console.log(cselected.cid)
        if (props.cmt[i].cid == props.cm.cid) {
          Carr.push(props.cmt[i]);
        }
      }
    }
    setPComment(Carr);

  }, [props])
const Hideform=()=>{
  setForm(false)
}
const SaveForm=(vtype,time,date,pmet,interest,demo,comment)=>{

setVtype(vtype);
setTime(time);
setDate(date);
setPmet(pmet);
setInt(interest);
setDemo(demo);
setComment(comment)
setForm(false);
}
const Hideprof=()=>{
  setProf(false)
}
useEffect(()=>{
var isadmin=  JSON.parse(localStorage.getItem('UserInfo1'))
if(isadmin!=null && isadmin!=undefined && isadmin!=[]){
  if(isadmin.user_master!=[]){
  setAdmin(parseInt(isadmin.user_master[0].admin))
  setUID(parseInt(isadmin.user_master[0].userid))
  }
}
},[])
  return (
    <>

      <Paper style={{ maxHeight: '87vh', overflow: 'auto', maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available' }}>
        <List className={classes.root}>
          <ListItem alignItems="center" style={{ marginLeft: '36%', }}>

            <ListItemText
              primary={props.cm.school_name}
              />

          </ListItem>
          <Divider />
          <ListItem alignItems="center">
            <IconButton style={{ alignItems: 'flex-start' }} onClick={() => window.location.href = "tel:" + props.cm.director_contact}>
              <CallIcon color="secondary" /> &nbsp;&nbsp; <p style={{ margin: 0, fontSize: 'medium', alignSelf: 'center' }}>{props.cm.director_contact}</p>
            </IconButton>
           
                <IconButton style={{float:'right' }} onClick={()=>setProf(true)}>
                <InfoOutlinedIcon color="secondary" /> &nbsp;<p style={{ margin: 0, fontSize: 'medium', alignSelf: 'center' }}>School Info</p>
              </IconButton>
              
          </ListItem>
          <Divider />
          <ListItem alignItems="center" style={{ marginLeft: '1%' }}>

            <CommentIcon color='secondary' style={{ marginRight: 10 }} />

            <ListItemText
              primary={'Comments : '}
            />
          </ListItem>
          <Divider variant='fullWidth' />
        </List>
        <List>
          {isprof?<Clientinfo close={Hideprof} data={props.cm} />:null}
          {propComment != null ? propComment.map((v, i) => (
            adminu==1?
            <>
              <ListItem alignItems="flex-start">
                {/* {console.log(propComment)} */}
                <ListItemText
               primary={<p style={{ margin: 0 }}>Visit Type- {v.visit_type}<br/> Date- {v.date}<br/> Time- {v.time}<br/> Person Met- {v.person_met}<br/> Probablity- {v.success_probablity} <br/> Demo- {v.product_demo} <br/> Comment - {v.comment}</p>}
                  // primary={<p style={{ margin: 0 }}>{v.comment}</p>} //User {v==3? 1:v}
                  secondary={<p style={{ float: 'right', margin: 0 }}>{v.visit_type} &nbsp;&nbsp; {v.username} &nbsp;&nbsp;{moment(v.created_on, ["YYYY-MM-DD"]).format("L")}</p>}
                />
              </ListItem>
              
              <Divider variant='fullWidth' />
            </> :uida!=null? v.userid==uida?
             <>
             <ListItem alignItems="flex-start">
               {/* {console.log(propComment)} */}
               <ListItemText
              primary={<p style={{ margin: 0 }}>Visit Type- {v.visit_type}<br/> Date- {v.date}<br/> Time- {v.time}<br/> Person Met- {v.person_met}<br/> Probablity- {v.success_probablity} <br/> Demo- {v.product_demo} <br/> Comment - {v.comment}</p>}
                 // primary={<p style={{ margin: 0 }}>{v.comment}</p>} //User {v==3? 1:v}
                 secondary={<p style={{ float: 'right', margin: 0 }}>{v.visit_type} &nbsp;&nbsp; {v.username} &nbsp;&nbsp;{moment(v.created_on, ["YYYY-MM-DD"]).format("L")}</p>}
               />
             </ListItem>
             
             <Divider variant='fullWidth' />
           </>:null:null
            
            )) : null}

            {isform?<Chip open={isform} data={props.cm} FormS={SaveForm} close={Hideform}/>:null}
          <ListItem alignItems="flex-start">

            <Paper style={{
              bottom: 5, float: 'right', minHeight: 73, backgroundColor: '#f5f6fa',
              maxHeight: 200, overflowY: 'scroll', minWidth: '100%', marginLeft: -2, borderRadius: 1
            }} >
              <Button style={{marginLeft:30}} variant="outlined" color="secondary" onClick={()=>setForm(true)}>
                Form
              </Button>
              {vtype!=null && time!=null&& date!=null && pmet!=null && interest!=null && demo!=null && comment!=null?
                <Button style={{marginLeft:30}} variant="outlined" endIcon={ <SendIcon/>} color="secondary"onClick={() => { props.send(vtype,time,date,pmet,interest,demo,comment); setComment(''); setVtype(null);setTime(null);setDate(null);setPmet(null);setInt(null);setDemo(null) }}>
                Send
              </Button>:
              null
              }
              {vtype!=null && time!=null&& date!=null ?<p>Visit Type- {vtype}<br/> Date- {date}<br/> Time- {time}<br/> Person Met- {pmet}<br/> Probablity- {interest} <br/> Demo- {demo} <br/> Comment - {comment}</p>:null }
              {/* <InputBase
                className={classes.inputInput}
                style={{ marginLeft: 10 }}
                placeholder="Type Comment here..."
                type="text"
                // accept="image/*"
                multiline
                rows={2}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              /> */}

              {/* <IconButton type="submit" className={classes.iconButton} onClick={() => { props.send(vtype,time,date,pmet,interest,demo,comment); setComment(''); setVtype(null);setTime(null);setDate(null);setPmet(null);setInt(null);setDemo(null) }}>
                <SendIcon color="secondary" />
              </IconButton> */}
              
            </Paper>

          </ListItem>
        </List>
      </Paper>

    </>
  );
}
