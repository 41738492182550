import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {FormLabel,FormControl,Radio,RadioGroup,Box} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// import {Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import YouTubeIcon from '@material-ui/icons/YouTube';

import Container from '@material-ui/core/Container';
import Alert from './alert';
import history from './history';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const [Name,setName]=React.useState(null);
  const [city,setCity]=React.useState(null);
  const [sname,setSname]=React.useState(null);
  const [title,setTitle]=React.useState(null);
  const [details,setDetails]=React.useState(null);
  const [File,setFile]=React.useState(null);
  const [photo,setPhoto] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const [isalert,setAlert]=React.useState(false)

  const {sid}=useParams() ;
  const handleAlertClose = () => {
    setAlert(false);
  };
  const SubmitA=()=>{
 
    if(sid!=null && props.ud.data[0].userid!=null &&  title!=null && details !=null && title!='' && details!='' ){
     axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?",{['fun_name']:'Alumni_Video_Insert',['sid']:sid,['userid']:props.ud.data[0].userid,['title']:title,['link']:details}).then((resp)=>{
      console.log("1-->"+resp.data.submitted);
    if(resp.data.submitted=='1'){
      Swal.fire(
        "Success",
        "Your link is submitted. It will be displayed on the videos page once approved by the admin",
        'success'
    )
      // setPhoto('');
      setTitle('');
      setDetails('');
    }
    })     
  }
  else{
    Swal.fire(
      "Alert",
      "Please Enter All Fields !",
      'warning'
  )
  }
}
  return (
    <Container component="main" maxWidth="xs">
     
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <YouTubeIcon  />
        </Avatar>
       
        {/* <Typography component="h1" variant="h5">
          Sign in 
        </Typography> */}
        <Typography component="h1" variant="h5">
          Submit Video
        </Typography>
        {/* {console.log(props.ud.data[0].userid)} */}
        {/* {isalert?<Alert open={isalert} close={handleAlertClose} msg={"Your link is submitted. It will be displayed on the videos page once approved by the admin"}/>:null} */}
        <div className={classes.form} >
          
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Title"
            label="Title"
            type="Title"
            id="Title"
            value={title}
            onChange={(e)=>{setTitle(e.target.value)}}
            autoComplete="Title"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Link"
            label="Link"
            type="Link"
            id="Link"
            value={details}
            onChange={(e)=>{setDetails(e.target.value)}}
            autoComplete="Title"
          />
          </div>
          <Button style={{margin:20}} variant="contained" color="primary" component="span" onClick={()=>SubmitA()}>
          Submit Video
        </Button>
          </div></Container>
  );
    }