import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SortIcon from '@material-ui/icons/Sort';
import moment from 'moment';
import {Typography,MenuItem,Menu,IconButton,TextField} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Comments from './Comments'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));
function getMax(arr, prop) {
  var max;
  for (var i=0 ; i<arr.length ; i++) {
      if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop]))
          max = arr[i];
  }
  return max;
}
export default function Clist(props) {
  const classes = useStyles();
  const assert = require("assert");
  const ITEM_HEIGHT = 48;
 // var udata=JSON.parse(localStorage.getItem('data'))!=undefined?JSON.parse(localStorage.getItem('data')):null;
 // console.log(udata[0].cmaster)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sortby, SetSort] = React.useState('Name');
  const open = Boolean(anchorEl);
  const [expanded, setExpanded] = React.useState(false);
  const [cdata, setCdata] = React.useState([]);
  const [search, setSearch] = React.useState('');

  //const [cm, setCm] = React.useState(props.cm!=undefined?props.cm:udata[0].cmaster!=undefined?udata[0].cmaster:[]);
  var sortJsonArray = require('sort-json-array');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const SortArray=(opt)=>{
    //console.log(props.Data[0].cmaster);
     SetSort(opt);
     if(opt=='Name')
     var sorted=sortJsonArray(props.cm,'client_name');
     if(opt=='Last Billing Date')
     var sorted=sortJsonArray(props.cm,'Last_billing_date','des');
     if(opt=='Amount')
     var sorted=sortJsonArray(props.cm,'amount','des');
     setAnchorEl(null);
    // console.log(sorted);
  }
  useEffect(()=>{    
  //  const temp1=[];
  //  var s=[];
  //   //console.log(max);
  //   for(var i=0;i<props.cm.length;i++){
  //     for(var j=0;j<props.cmt.length;j++){
  //     if(props.cmt[j].cid==i){
  //      s.push(props.cmt[j])
  //     }
  //     }
  //     temp1.push({i:s})
  //     s=[];
  //   }
   
  //   console.log(temp1)
  //   for(var i=0;i<props.cm.length;i++){
  //     for(var j=0;j<props.cmt.length;j++){
       
  //       var dt;
  //     if(props.cmt[j].cid===props.cm[i].cid){
  //       //console.log(props.cmt[j].cid)
        
  //       var max=props.cmt[0].created_on;
  //     //console.log(props.cm[i].cid)
  //       if (props.cmt[j].created_on > max){ 
            
  //           max = props.cmt[j].created_on;
            
  //       }
  //   }
  //       // max = v.reduce((prev, current) => (prev[0].created_on > current[0].created_on) ? prev[0] : current[0])
    
  //       temp1.push(max)
  //     }
      
     
  //   }
    
   
  
    //console.log(max)
      
     // console.log(temp1)
  },[])
 
  const Cmap=()=>{
    var temp=[];
    for(var i=0;i<props.Data[0].cmaster.length;i++){
      for(var j=0;j<props.Data[0].fcomments;j++){
      if(props.Data[0].cmaster[i].cid===props.Data[0].fcomments[j].cid){
        console.log(props.Data[0].fcomments[j].created_on+props.Data[0].fcomments[j].comment)
      }
    }
  }
  }
  const options = [
    'Name',
    'Last Billing Date',
    'Amount'
  ];
  /*
   */
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (  
    <List className={classes.root}>
     
      <ListItem alignItems="flex-start" >
      <ListItemText
          primary="Sort"
        />
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SortIcon />
      </IconButton>
        <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
         {options.map((option,i) => (
          <MenuItem key={option} selected={option === sortby} onClick={()=>SortArray(option)}>
            {option}
          </MenuItem>))}
      </Menu>
      
      </ListItem>
      <Divider variant='fullWidth' />
      <ListItem>
      <TextField
                variant="outlined"
                fullWidth
                id="search"
                label="Search"
                name="Search"
                value={search}
                 onChange={(e)=>setSearch(e.target.value)}
                autoComplete="Search"
              />
      </ListItem>
      {/* {console.log(props.Data[0])} */}
     {props.cm!=undefined? props.cm.map((v,i)=>(
          
          search!='' && search!=null? v.client_name.toLowerCase().includes(search.toLowerCase())?
         <Accordion expanded={expanded === i} onChange={handleChange(i)}> 
        <AccordionSummary
          expandIcon={<IconButton onClick={()=>props.isC(v)}><ExpandMoreIcon /></IconButton>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         
        <ListItem alignItems="flex-start" >
        <ListItemAvatar style={{marginTop:3}}>
          <Avatar  src="/static/images/avatar/2.jpg" />
        </ListItemAvatar>
        {/* {console.log(v.cid)} */}
        <ListItemText
          primary={v.client_name }
     secondary={<p style={{float:'right',margin:0}}>{moment(v.Last_billing_date, ["YYYY-MM-DD"]).format("L")}</p>}
        />
      </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        
      <Comments send={props.send} usrinfo={props.userinfo} cm={props.cms} cmt={props.cmt}  />
      </AccordionDetails>
      
      {/* <Divider variant='fullWidth' /> */}
      </Accordion>:null:
       <Accordion expanded={expanded === i} onChange={handleChange(i)}> 
       <AccordionSummary
         expandIcon={<IconButton onClick={()=>props.isC(v)}><ExpandMoreIcon /></IconButton>}
         aria-controls="panel1a-content"
         id="panel1a-header"
       >
        
       <ListItem alignItems="flex-start" >
       <ListItemAvatar style={{marginTop:3}}>
         <Avatar  src="/static/images/avatar/2.jpg" />
       </ListItemAvatar>
       {/* {console.log(v.cid)} */}
       <ListItemText
         primary={v.client_name }
    secondary={<p style={{float:'right',margin:0}}>{moment(v.Last_billing_date, ["YYYY-MM-DD"]).format("L")}</p>}
       />
     </ListItem>
     </AccordionSummary>
     <AccordionDetails>
       
     <Comments send={props.send} usrinfo={props.userinfo} cm={props.cms} cmt={props.cmt}  />
     </AccordionDetails>
     
     {/* <Divider variant='fullWidth' /> */}
     </Accordion>
      )):null}
      
    </List>
  );
}
