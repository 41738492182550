import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useParams, useHistory, Link } from "react-router-dom";

const Teacher = () => {
  const { sid, staffid } = useParams();
  const history = useHistory();

  const [divName, setDivName] = useState("");
  const [std, setStd] = useState("");
  const [sclass, setclass] = useState([]);
  const [sdiv, setDiv] = useState([]);
  const [studentDetails, setStudent] = useState([]);

  // get class details
  const getClassDetails = async () => {
    try {
      const token = "#$laksdfnlknoea#$@$%^&%$";
      const data = {
        sid: `${sid}`,
      };
      const response = await axios({
        method: "post",
        url: "https://marcopolo.prisms.in/api/tscommon/getallclass",
        data,
        headers: {
          Authorization: `${token}`,
        },
      });
      setclass(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // class Handler
  const classHandler = (e) => {
    e.preventDefault();
    const classArr = e.target.value.split(" ");
    const dataTarget = classArr[0];
    const std_1 = classArr[1];
    setStd(std_1);
    const getDivDetails = async () => {
      try {
        const token = "#$laksdfnlknoea#$@$%^&%$";
        const data = {
          class_id: dataTarget,
          sid: `${sid}`,
        };
        const response = await axios({
          method: "post",
          data: data,
          url: "https://marcopolo.prisms.in/api/tscommon/getalldiv",
          headers: {
            Authorization: `${token}`,
          },
        });
        setDiv(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getDivDetails();
  };

  // Div Handler
  const divHandler = (e) => {
    e.preventDefault();
    const divArr = e.target.value.split(" ");
    const divId = divArr[0];
    const divisionName = divArr[1];
    setDivName(divisionName);
    const getStudentDetails = async () => {
      try {
        const token = "#$laksdfnlknoea#$@$%^&%$";
        const data = {
          division: `${divId}`,
          sid: `${sid}`,
        };

        const response = await axios({
          method: "post",
          url: "https://marcopolo.prisms.in/api/tscommon/getstudentdetails",
          data,
          headers: {
            Authorization: `${token}`,
          },
        });
        setStudent(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getStudentDetails();
  };

  // comments user
  useEffect(() => {
    getClassDetails();
  }, []);

  console.log(divName);
  console.log(std)

  return (
    <>
      <Container>
        <Wrapper>
          <h3>Student Log</h3>
          <Form>
            <form>
              {/* Select Class */}
              <h4>Select Class</h4>
              <select onChange={(e) => classHandler(e)}>
                <option value="default">Select</option>
                {sclass.map((data, idx) => {
                  return (
                    <option key={idx} value={`${data.id} ${data.class_name}`}>
                      {data.class_name}
                    </option>
                  );
                })}
              </select>

              {/* Select Div  */}
              <h4>Select Div</h4>
              <select onChange={(e) => divHandler(e)}>
                <option value="default">Select</option>
                {sdiv.map((data, idx) => {
                  const { div_name, id } = data;
                  return (
                    <option key={idx} value={`${id} ${div_name}`}>
                      {div_name}
                    </option>
                  );
                })}
              </select>
            </form>
{/* 
            <AdminReport>
              <Link
                className="admin__report"
                to={`/studentlog/adminreport/${sid}/${staffid}`}
              >
                Admin Report
              </Link>
            </AdminReport> */}
            <StudentDetails>
              <ul>
                {studentDetails.map((data) => {
                  return (
                    <Navigation
                      onClick={() =>
                        history.push({
                          pathname: `/studentlog/comment/${
                            data.user_id
                          }/${sid}/${staffid}`,
                          state: `${data.fname} ${
                            data.lname
                          } ${divName} ${std}`,
                        })
                      }
                    >
                      <li
                        key={data.user_id}
                        value={`${data.fname} ${data.lname} ${data.user_id}`}
                      >
                        <img src={data.avatar} alt="" />
                        <span className="fl">
                          {data.fname} {data.lname}
                        </span>
                      </li>
                    </Navigation>
                  );
                })}
              </ul>
            </StudentDetails>
          </Form>
        </Wrapper>
      </Container>
    </>
  );
};

export default Teacher;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 10px auto;
  padding: 20px;
  height: 80vh;

  @media (max-width: 768px) {
    width: 95%;
    padding: 0;
    box-shadow: none;
    margin: 0 auto;
    height: 100vh;
  }

  h3 {
    text-align: center;
    color: #444;
    font-weight: 600;
    font-size: 1.7rem;
    text-transform: capitalize;
    margin-top: 20px;

    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }
`;
const Form = styled.div`
  max-width: 1100px;
  height: 400px;
  margin: 20px auto;

  @media (max-width: 768px) {
    margin: 10px auto;
  }

  form {
    display: flex;
    flex-direction: column;

    h4 {
      margin-top: 15px;
      font-weight: 600;
      font-size: 1.2rem;
      color: #444;
    }

    select {
      width: 100%;
      padding: 10px;
      margin: 12px 0;
      border-top: none;
      border-left: none;
      height: 40px;
      outline: none;
      border-right: none;
      font-size: 1rem;
      box-sizing: border-box;
      background-color: rgb(75, 130, 230);
      color: white;
      border-radius: 4px;
    }
  }
`;
// const AdminReport = styled.div`
//   .admin__report {
//     color: #fff;
//     background-color: rgb(75, 130, 230);
//     padding: 5px;
//     margin-top: 5px;
//     border-radius: 4px;
//   }
// `;
const StudentDetails = styled.div`
  margin: 25px auto;
  overflow: hidden scroll;
height: 100vh;

  img {
    width: 50px;
    height: 50px;
  }
  li {
    list-style-type: none;
    display: flex;
    align-items: center;
    height: 60px;
    margin: 10px 0;
    border-bottom: 1px solid #eee;
    border-radius: 0px;
    padding: 2px 8px;

    .fl {
      margin-left: 250px;
    }

    @media (max-width: 768px) {
      padding: 0 8px;
      .fl {
        margin-left: 50px;
      }
    }
  }

  span {
    font-weight: 600;
    font-size: 0.9rem;
    color: #333;
  }
`;

const Navigation = styled.div`
  text-decoration: none;
  outline: none;
  border: none;
`;
