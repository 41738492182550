import React from "react";
import styled from "styled-components";
const Navbar = () => {
  return (
    <>
      <Navbox>
        <Heading>
          <h4 className="title">Download IDcards</h4>
        </Heading>
      </Navbox>
    </>
  );
};

export default Navbar;

const Navbox = styled.div`
  position: static;
  background-color: #6610f2;
  height: 50px;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const Heading = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  .title {
    font-size: 1.2rem;
    color: #fff;
    font-weight: 700;

    @media screen and (max-width: 768px) {
      padding: 0;
      margin-left: 12px;
    }
  }
`;
