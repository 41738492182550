import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar,Toolbar,Typography,IconButton} from '@material-ui/core';
import CList from './CList';
import NewC from './NewC';
import Comments from './Comments';
import SignIn from './SignIn';
import axios from 'axios';
import AllCmts from './AllCmts';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CommentIcon from '@material-ui/icons/Comment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SortIcon from '@material-ui/icons/Sort';
import { version } from 'moment';
import SyncIcon from '@material-ui/icons/Sync';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    marginTop:25,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height:50,
    alignItems:'flex-start',
    justifyContent:'center',
   [theme.breakpoints.up('sm')]: {
   
   position:'fixed',
   left:0
   },
 },
}));

export default function FollowHome(props) {
  const classes = useStyles();
  var udataa=JSON.parse(localStorage.getItem('Data'));
  var usinfo=JSON.parse(localStorage.getItem('UserInfo'));
  const [isAddC, setIsAdd] = React.useState(false);
  const [IsAllC,setAllC]=React.useState(false);
  const [data, setData] = React.useState(udataa!=undefined?udataa:[]);

  const [isCmt, setIsCmt] = React.useState(false);
  const [isHome, setIsHome] = React.useState(false);
  const [isSign, setIsSing] = React.useState(false);
  const [cmaster, setCmaster] = React.useState(udataa!=undefined?udataa[0].cmaster:[]);
  const [fcomments, setFcomments] = React.useState(udataa!=undefined?udataa[0].fcomments:[]);
  const [UserInfo, setUserInfo] = React.useState(usinfo!=undefined?usinfo:[]);
  const [cselected, setCsel] = React.useState([]);
  const [selected, setSel] = React.useState([]);
  const [isLogin,setLogin]=useState(false);
  //const [udata,setUdata]=useState(JSON.parse(localStorage.getItem('data'))); 
  console.log(udataa)
  var sortJsonArray = require('sort-json-array');

const IsComment=(v)=>{
  // setIsCmt(true);
  // setIsAdd(false);
  // setAllC(false);
  // setIsHome(false);
  setCsel(v);
 
}
useEffect(()=>{    
  var usinfo=JSON.parse(localStorage.getItem('UserInfo'));
  if(usinfo!=null && usinfo!='' && usinfo!=[] && usinfo!=undefined){
    if(usinfo.user_master!=null && usinfo.user_master!=undefined && usinfo.user_master!=[]){
  axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
  {['fun_name']:"client_followup_auth",['sid']:props.match.params.sid,['email']:usinfo.user_master[0].email,['pass']:usinfo.user_master[0].password}).then((res)=>{
    // console.log(res);
    if(res.data.user_master.length>0){
    if(res.data.user_master[0].username==usinfo.user_master[0].username){
      console.log('success')
    }
  }else{
    setLogin(false);setIsAdd(false);setIsHome(false);setAllC(false);setAllC(false);localStorage.setItem('isAllC',false);localStorage.removeItem('isHome');localStorage.removeItem('isAdd');localStorage.removeItem('isLoginC');
    localStorage.removeItem('isAllC');localStorage.removeItem('Data');localStorage.removeItem('UserInfo');
    window.location.reload();
  }

  })
}
}else{
  setLogin(false);setIsAdd(false);setIsHome(false);setAllC(false);setAllC(false);localStorage.setItem('isAllC',false);localStorage.removeItem('isHome');localStorage.removeItem('isAdd');localStorage.removeItem('isLoginC');
  localStorage.removeItem('isAllC');localStorage.removeItem('Data');localStorage.removeItem('UserInfo');
}
},[])
// const v=useDispatch();
const Back=()=>{
  setIsCmt(false);
  setIsAdd(false);
  setAllC(false);
  setIsHome(true);
  localStorage.setItem('isHome',true);
  // v({type:'Add'});
}
// const Signin=()=>{
//   setLogin(true);
// }
const Sync=()=>{
    
     axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?",{['fun_name']:"client_followup_getinfo",['sid']:props.match.params.sid}).then((res)=>{
    console.log(res);
    setCmaster(res.data.client_master);
  
    setFcomments(res.data.fcomment);
    var v =[{cmaster:res.data.client_master,fcomments:res.data.fcomment}]
    localStorage.setItem("Data",JSON.stringify(v));
})
    
}
// const CmtClicked=(v)=>{
 
// }
const Send=(comment)=>{
  var arr=[];
  
  if(comment !=null && comment != ''){
   
    if(UserInfo!=undefined && UserInfo!=null){
    var us=UserInfo.user_master[0].userid;
    var nm=UserInfo.user_master[0].username;
    console.log(us)
    console.log(nm)
    console.log(cselected.cid)
    //}
    // if(us!=undefined && us!=null && nm!=undefined && nm !=null ){
      
  axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"client_followup_insert_comment",['sid']:props.match.params.sid,['uid']:us,['name']:nm,['cid']:cselected.cid,['comment']:comment}).then((res)=>{
    //setCmaster(res.data.client_master);
    // var fc=sortJsonArray(res.data.fcomment, 'id','des')
   // setFcomments(fc);
   var v =[{cmaster:cmaster,fcomments:res.data.fcomment}]

     setFcomments(res.data.fcomment);
     setData(v);
     localStorage.setItem("Data",JSON.stringify(v));
     
  })
}
}
}
const Sign=(e,p)=>{
  if(e !=null && p != null &&e !=undefined && p != undefined  ){
      axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"client_followup_auth",['sid']:props.match.params.sid,['email']:e,['pass']:p}).then((res)=>{
      // console.log(res);
         setUserInfo(res.data);
         localStorage.setItem("UserInfo",JSON.stringify(res.data));
         if(res.data.user_master.length==1){
          axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?",{['fun_name']:"client_followup_getinfo",['sid']:props.match.params.sid}).then((resp)=>{
            //console.log(res);
            var v =[{cmaster:resp.data.client_master,fcomments:resp.data.fcomment}]
          setData(v)
            setCmaster(resp.data.client_master);
            
            //localStorage.setItem("cmaster",JSON.stringify(resp.data.client_master));
           // var fc=sortJsonArray(res.data.fcomment, 'id','des')
            setFcomments(resp.data.fcomment);
            localStorage.setItem("Data",JSON.stringify(v));
        })
         setLogin(true);
         localStorage.setItem("isLoginC",true);
         localStorage.setItem("isHome",true);
         setIsHome(true)
         }
         else{
           window.alert("Wrong email or password");
         }
      })
     
    }else{
      window.alert("Enter email and password");
    }
}
const SubmitNewC=(cname,amt,mob,lbn)=>{
  //var chkmob = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  //var chkAmt= /^[0-9]*$/;
      //console.log(cname+'-'+amt+' -'+lbn+'-'+mob);
  if(cname !=null ){
    if(amt=='')
      amt='null';
    if(mob=='')
      mob='null';
    if(lbn=='')
      lbn='null';

    // if(chkmob.test(mob)==false || chkAmt.test(amt)==false){
    //   window.alert("Mobile No or amount wrong");
    // }
    //else{
   axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"client_followup_client_insert",['sid']:props.match.params.sid,['amount']:amt,['client_name']:cname,['mobile_no']:mob,['Last_billing_date']:lbn}).then((res)=>{
    //setCmaster(res.data.client_master);
    // var fc=sortJsonArray(res.data.fcomment, 'id','des')
   // setFcomments(fc);
   setCmaster(res.data.client_master);
   var v =[{cmaster:res.data.client_master,fcomments:fcomments}]
   setData(v)
   
   localStorage.setItem("Data",JSON.stringify(v));
    window.alert('success');
  })
//}
}
}

// console.log("redux->");

  return(
    <>
    {isLogin || JSON.parse(localStorage.getItem('isLoginC'))==true?<AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.tool}>
        
         {isHome||JSON.parse(localStorage.getItem('isHome'))==true ?<><Typography className={classes.title} variant="h6" noWrap>
         Client Followup
          </Typography>
          <IconButton style={{position:'fixed',right:150}} onClick={()=>Sync()}>
          <SyncIcon style={{color:'white'}} fontSize='large' />
          </IconButton>
          <IconButton style={{position:'fixed',right:100}} onClick={()=>{setIsAdd(false);setIsHome(false); setAllC(true);localStorage.setItem('isAllC',true);localStorage.setItem('isHome',false);localStorage.setItem('isAdd',false);}}>
          <CommentIcon style={{color:'white'}} fontSize='large' />
          </IconButton>
          <IconButton style={{position:'fixed',right:55}} onClick={()=>{setIsAdd(true);setIsHome(false);setAllC(false);setAllC(false);localStorage.setItem('isAllC',false);localStorage.setItem('isHome',false);localStorage.setItem('isAdd',true);}}>
          <PersonAddRoundedIcon style={{color:'white'}} fontSize='large' />
          </IconButton>
          <IconButton style={{position:'fixed',right:5}} onClick={()=>{setLogin(false);setIsAdd(false);setIsHome(false);setAllC(false);setAllC(false);localStorage.setItem('isAllC',false);localStorage.setItem('isHome',false);localStorage.setItem('isAdd',false);localStorage.setItem('isLoginC',false);localStorage.removeItem('isHome');localStorage.removeItem('isAdd');localStorage.removeItem('isLoginC');localStorage.removeItem('isAllC');localStorage.removeItem('Data');localStorage.removeItem('UserInfo'); window.location.reload();}}>
          <ExitToAppIcon style={{color:'white'}} fontSize='large' />
          </IconButton>
          </>: isAddC || JSON.parse(localStorage.getItem('isAdd'))==true?
          <>
          <Typography style={{position:'fixed',left:70}} variant="h6" noWrap>
          Add New Client
           </Typography>
           <IconButton style={{position:'fixed',left:2}} onClick={()=>{setIsAdd(false);setIsCmt(false);setIsHome(true);setAllC(false)}}>
          <KeyboardBackspaceIcon style={{color:'white'}} fontSize='large' />
          </IconButton>
           
           </>:IsAllC || JSON.parse(localStorage.getItem('isAllC'))==true?<>
           <Typography style={{position:'fixed',left:70}} variant="h6" noWrap>
          All Comments
           </Typography>
           <IconButton style={{position:'fixed',left:2}} onClick={()=>{setIsAdd(false);setIsCmt(false);setIsHome(true);setSel([]);setSel([]);setAllC(false);localStorage.setItem('isAllC',false);localStorage.setItem('isHome',true);localStorage.setItem('isAdd',false)}}>
          <KeyboardBackspaceIcon style={{color:'white'}} fontSize='large' />
          </IconButton>
           </>:null
          }
          
          <div className={classes.grow} />
        </Toolbar>
       
      </AppBar>:null}
      {isLogin || JSON.parse(localStorage.getItem('isLoginC'))==true ?<main style={{marginTop:50}}>
      {isHome ||JSON.parse(localStorage.getItem('isHome'))==true ?
      <CList isC={IsComment} cm={cmaster} isBack={Back} send={Send} usrinfo={UserInfo} cms={cselected} cmt={fcomments} Data={data}/>
     : isAddC || JSON.parse(localStorage.getItem('isAdd'))==true?<NewC isBack={Back} submit={SubmitNewC}/>
     :IsAllC || JSON.parse(localStorage.getItem('isAllC'))==true?<AllCmts cmt={fcomments} Data={data} userinfo={UserInfo} cm={cmaster} isClicked={IsComment}/>:null}
     </main>:<SignIn sign={Sign} />}
    </>
  );
}
