import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom'
import moment from "moment";

export default function Appointments(props) {
    const [Appointments, setAppointments] = useState();
    const [search, setSearch] = useState('');
    const [selectedDays, setSelectedDays] = useState('All');
    const [showPM, setShowPM] = useState(false);
    const history = useHistory();
    let k = 0;

    useEffect(() => {
        document.getElementsByTagName("title")[0].innerHTML = "Appointment"
    }, []);

    useEffect(() => {
        setAppointments(props.data.filter((list) => {
            if (list.requestDate !== null && list.requestDate !== undefined && list.requestDate !== "") {
                return (
                    list.requestDate.indexOf(search) !== -1 &&
                    // (showPM || props.userType === "Parent" || props.type === "user" ? list.staff_id !== "" : list.staff_id === "") &&
                    (selectedDays === "All" ? true :
                        selectedDays === "Today" ?
                            list.requestDate === moment().format("YYYY-MM-DD")
                            : selectedDays === "Recent" ?
                                list.requestDate < moment().format("YYYY-MM-DD")
                                : list.requestDate > moment().format("YYYY-MM-DD"))
                )
            }
        }).sort((a, b) => {
            return new Date(a.updated_on).getTime() - new Date(b.updated_on).getTime()
        }).reverse())
    }, [search, props.data, selectedDays, showPM]);

    function Details(d) {
        history.push(`/appointment/${props.type}/appointmentdetails/${props.sid}/${props.uid}`, d)
    }

    return (
        <div className={props.userType === "Parent" ? "" : "APMCard"} >
            <div className={props.userType === "Parent" ? "" : "APMCardSub"}>
                {props.userType === "Parent" ? null : <>
                    <div className="APHeader">
                        {props.type === "User" || props.type === "user" ? <img onClick={() => { history.push(`/appointment/user/${props.sid}/${props.uid}`) }} src="/icons/back.png" alt="back" /> : null}
                        <div>{props.type === "User" ? "Appointment Status" : "Appointment Requests"}</div>
                    </div>
                    <hr className="APhr-line"></hr>
                </>
                }

               {/*   */}
                {/* { props.type === "user" ? <BiArrowBack onClick={()=> { console.log("Hi") }} />  : null } */}
                {/* Navigate to Home */}
                <div className="APDatefilterDiv" >
                    <select
                        className="APDatefilter"
                        onChange={(e) => { setSelectedDays(e.target.value) }}
                        style={{ padding: "5px", width: "120px" }}
                    >
                        <option value="All" label="All" />
                        <option value="Today" label="Today" />
                        <option value="Upcoming" label="Upcoming" />
                        <option value="Recent" label="Recent" />
                    </select>
                    {selectedDays !== "Today" ? <>
                        <input
                            className="APDatefilter"
                            id="dateFilter"
                            type="date"
                            style={{ color: "black" }}
                            onChange={(e) => { setSearch(e.target.value) }}
                        />
                        {search !== "" ?
                            <div
                                style={{ fontSize: "25px", color: "red", marginTop: "-3px", cursor: "pointer" }}
                                onClick={() => { setSearch(''); document.getElementById('dateFilter').value = "" }}
                            >
                                <strong>&nbsp;&nbsp;&#10006;</strong>
                            </div>
                            :
                            null
                        }
                    </>
                        :
                        null
                    }
                </div>
                <div style={{ overflow: "scroll", height: "100%" }}>
                    {(props.loader || !Appointments || typeof (Appointments) !== "object") ?
                        <div className="APLoader"><Loader
                            type="Puff"
                            color="#1e5597"
                            height={80}
                            width={80}
                        /></div> :
                        <div style={{ marginBottom: "200px" }}>
                            {Appointments.length === 0 ?
                                <div style={{ padding: "10px", color: "#1e5597", fontWeight: 600, width: "90%", height: "50vh", alignItems: "center", justifyContent: "center", display: "flex", textAlign: "center" }}>
                                    {search === "" ? "Not Requested For Any Appointment Till Now" : `You Not Requested For Any Appointment On ${search}`}
                                </div>
                                : Appointments.map((a) => {
                                    if (a.subject !== "" && a.person !== "" && a.name !== "") {
                                        k = k + 1
                                        return (
                                            <div key={k} className={props.type !== "User" ? "APEachDiv" : "APEachDivUser"}>
                                                <div style={{ paddingLeft: "10px", color: "#1e5597", fontWeight: 600, margin: "15px" }} onClick={() => { if (props.type !== "User") { Details(a) } }}>
                                                    <div>
                                                        {k} . {props.type === "User" ? a.subject : a.name}
                                                    </div>
                                                    <div className="APAppoinmentDetails">
                                                        <div className="APAppoinmentDetailsSub1">
                                                            {props.type === "superadmin" ?
                                                                <div>
                                                                    <img src={'/icons/school.png'} alt="School" style={{ width: "21px" }} />&nbsp; {a.s_name}
                                                                </div>
                                                                : null}
                                                            <div>
                                                                <img src={'/icons/person.png'} alt="pending" style={{ width: "21px" }} />&nbsp; {a.person}
                                                            </div>
                                                            <div>
                                                                <img src={'/icons/calendar.png'} alt="pending" style={{ width: "16px", margin: "4px 4px 0px 3px" }} />&nbsp; {a.requestDate1 !== undefined && a.requestDate1 !== null && a.requestDate1 !== "" ? a.requestDate1 : a.requestDate}
                                                            </div>
                                                            <div>
                                                                <img src={'/icons/clock.png'} alt="pending" style={{ width: "21px" }} />&nbsp; {moment(a.created_on).format("hh:mm A")}
                                                            </div>
                                                        </div>
                                                        <div className="APAppoinmentDetailsSub2" style={{ marginRight: a.requestStatus === "Not available" ? "0px" : "10px" }}>
                                                            <div>
                                                                <img src={`/icons/${a.requestStatus === "Pending" ? 'pending' : a.requestStatus === "Not available" ? "wrong" : "tick"}.png`} alt="pending" style={{ width: "40px" }} />
                                                            </div>
                                                            <div style={{ whiteSpace: "nowrap" }}>
                                                                {a.requestStatus !== "Pending" && a.requestStatus !== "Not available" ? a.requestStatus : a.requestStatus}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    return null

                                })}
                            {props.type === 'admin' || props.type === 'superadmin' ?
                                <div className="APAdminParentMButton">
                                    <span onClick={() => { setShowPM(!showPM) }}>
                                        {!showPM ? "Parent Appointments" : "Staff Appointments"}
                                    </span>
                                </div>
                                : null
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
