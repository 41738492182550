import React, { Component } from 'react';
import '../App.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import { MDBCard,MDBCardText, MDBContainer } from "mdbreact";
import axios from 'axios';
import NotAvailable from '../common/NotFound';
class Classtest extends Component {

  constructor(props){
    super(props)
    this.state = {
      data : [],
      sid : this.props.match.params.sid?this.props.match.params.sid:'',
      userid:this.props.match.params.userid?this.props.match.params.userid : ''
    }
  }

  componentDidMount(){
    var self = this;
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', {['fun_name']:"get_ClassTest_Data_For_IOS",['sid']:this.state.sid,['userid']:this.state.userid})
    .then(function(result){
      console.log(result);
      self.setState({
        data:result.data
      })
    })
  }


 fmFam = {
    fontFamily: "Poppins",
  }
  render() {
    return (
        <MDBContainer>
          { 
            (this.state.data && this.state.data == 'INVALID'?
           
              <NotAvailable title={'Class test scores are not available at the moment'}/>
           
            :(this.state.data && this.state.data.length > 0)? this.state.data.map((value,index) =>(
              <div className="mx-auto pt-2" key={index}>
                <MDBCard className="card-body">
                  <MDBCardText>
                    <span className="float_left">
                      <strong style={this.fmFam}>Date: </strong>
                      {value.date}
                    </span>
                    <span className="float_right">
                      <strong  style={this.fmFam}>Subject: </strong>
                      {value.subject}
                    </span>
                  </MDBCardText>
                  <MDBCardText>
                  <strong  style={this.fmFam}>Topic Name: </strong>
                  {value.topic}
                  </MDBCardText>
                  <MDBCardText>
                    <span className="float_left">
                      <strong  style={this.fmFam}>Max.Marks: </strong>
                      {value.max}
                    </span>
                    <span className="float_right">
                      <strong  style={this.fmFam}>Marks Obtained: </strong>
                      {value.obtained}
                    </span>
                  </MDBCardText>
              </MDBCard>
              </div>
            )) :<div className="d-flex justify-content-center"><div className="loader border-top-info margin-top-1"></div></div>)}
        </MDBContainer>
    );
  }
}

export default Classtest;

{/* <MDBCard className="card-title mt-2" style={{background: "cadetblue",color: "white"}}><center>Class Test</center></MDBCard> */}
