import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Appointment from '../../ReusedComponents/Appointments';
import { useParams } from 'react-router-dom';

export default function Requests() {
    const [Appointmentslist, setAppointmentslist] = useState([]);
    const [Loader, setLoader] = useState(true);
    const { sid, uid } = useParams();
    useEffect(() => {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "GetSuperAStatusByUid", sid: sid, staff_id: uid }).then((res) => {
            setAppointmentslist((res.data.AStatus !== undefined) ? (res.data.AStatus).reverse() : []); setLoader(false);
        })
    }, []);

    return (
        <Appointment data={Appointmentslist} type="user" loader={Loader} sid={sid} uid={uid} />
    );
}
