import { Button } from '@material-ui/core'
import { useParams } from "react-router-dom";
import axios from "axios";
import React, { useState } from 'react'
import { useEffect } from 'react';
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import { Link } from 'react-router-dom/cjs/react-router-dom';

const ViewsDynamicForm = () => {
    const { sid } = useParams();
    const [apiloader, setapiLoader] = useState();
    const [forms, setForms] = useState([]);
    const getData = async () => {

        setapiLoader(true);
        try {
            //  const data = formdata
            const data = {
                "sid": sid
            }
            await axios({
                method: "POST",
                // url: `${process.env.REACT_APP_BASE_URL}/api/information/create/form`,
                url: `${process.env.REACT_APP_BASE_URL}/api/information/getall/dynamicform`,
                data,
                headers: {
                    Authorization: "#$laksdfnlknoea#$@$%^&%$",
                },
            })
                .then((response) => {
                    setForms(response.data);
                    setapiLoader(false);
                })
                .catch((error) => {
                    setapiLoader(false);
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: error,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                });
        } catch (error) {
            setapiLoader(false);
            Swal.fire({
                position: "center",
                icon: "warning",
                title: error,
                showConfirmButton: false,
                timer: 1500,
              });
        }
    }
    useEffect(() => {
        getData();
    }, [])

    const getStartDate = (startDate) =>{
        let date = new Date(startDate);
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns a zero-based value (0-11), so we add 1 to get the correct month number
        let year = date.getFullYear();

        // add leading zeros to day and month if necessary
        day = (day < 10) ? '0' + day : day;
        month = (month < 10) ? '0' + month : month;

        let formattedDate = day + '/' + month + '/' + year;
        console.log(formattedDate);
        return formattedDate;
    }

    const getEndDate = (endDate) =>{
        let date = new Date(endDate);
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns a zero-based value (0-11), so we add 1 to get the correct month number
        let year = date.getFullYear();

        // add leading zeros to day and month if necessary
        day = (day < 10) ? '0' + day : day;
        month = (month < 10) ? '0' + month : month;

        let formattedDate = day + '/' + month + '/' + year;
        console.log(formattedDate);
        return formattedDate;
    }

    return (
        <div>
             {
                apiloader ? (
                    <div className="TMLoader">
                        <Loader type="ThreeDots" color="#bd2f5e" height={80} width={80} />
                    </div>
                ) :
            <div className='container mt-5 '>
                <h2 className='text-center mb-2'>Created Forms</h2>
                <div className='table-responsive border'>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col"><b>sr no</b></th>
                                <th scope="col"><strong>Form Title</strong></th>
                                <th scope="col"><strong>Valid From</strong></th>
                                <th scope="col"><strong>Valid till</strong></th>
                                <th scope="col"><strong>Form Link</strong></th>
                                <th scope="col"><strong>View Data</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                forms.length ? forms.map((form, index) => (
                                    <tr key={index}>
                                        <th scope="row" ><b>{index+1}</b></th>
                                        <td>{form.title}</td>
                                        <td>{getStartDate(form.start_date)}</td>
                                        <td>{getEndDate(form.end_date)}</td>
                                        <td><Link to={`/dynamic/forms/${sid}/${form.id}/`}>Click here</Link></td>
                                        <td><Button className='bg-warning'><Link to={`/dynamic/records/${sid}/${form.id}`}>View Records</Link></Button></td>
                                    </tr>
                                )) : <tr>
                                    <td colSpan={7} className='text-center'>NO DATA FOUND</td>
                                </tr>
                            }


                        </tbody>
                        <tfoot>
                            <tr>

                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>}
        </div>
    )
}

export default ViewsDynamicForm