import { add } from 'lodash';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom'

export default function AddNotes(props) {
    const history = useHistory()
    const { sid, uid } = useParams()
    console.log(sid)
    console.log(uid)
    function Add() {
        var pre_meeting = document.getElementById("pre_meeting").value
        var post_meeting = document.getElementById("post_meeting").value
        history.location.state.pre_meeting = pre_meeting
        history.location.state.post_meeting = post_meeting
        history.push(`/appointment/${props.type}/appointmentdetails/${sid}/${uid}`, history.location.state);
    }

    return (
        <div className="APMCard">
            <div className="APMCardSub">
                <div className="APHeader">
                    <img onClick={() => { history.goBack() }} src="/icons/back.png" alt="back" /><div>Add Notes</div>
                </div>
                <hr className="APhr-line"></hr>
                <div style={{overflow:"scroll",height:"100%"}}>
                    <div className="APAddNotesMainDiv">
                        <div>
                            <label>Pre - Meeting Notes</label>
                            <textarea id="pre_meeting" defaultValue={history.location.state.pre_meeting} rows={6} name="pre_meetings" />
                        </div>
                        <div>
                            <label>Post - Meeting Notes</label>
                            <textarea id="post_meeting" defaultValue={history.location.state.post_meeting} rows={6} name="post_meetings" />
                        </div>
                    </div>
                    <div style={{ alignItems: "center", justifyContent: "center", display: "flex", width: "100%", marginTop: "35px", marginBottom:"100px" }}>
                        <div className="APLandingButtons" style={{ padding: "7px 40px", backgroundColor: "#d4faf7" }} onClick={() => { Add() }}>
                            Add
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
