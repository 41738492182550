import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {Typography,MenuItem,InputLabel,IconButton} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import '../App.css';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function TextC(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(true);

  const [age, setAge] = React.useState('');
  const [B, setB] = React.useState(false);
  const [Al, setAl] = React.useState(false);
  const [Ac, setAc] = React.useState(false);
  const [color, setColor] = React.useState(null);

  // const fonts = ['Akaya Kanadaka, cursive', 'Roboto', 'Roboto Mono', 'Fantasy', 'monospace','Ubuntu'];
  const fonts = [
    'Akaya Kanadaka, cursive', 
    'Roboto', 
    'Roboto Mono', 
    'Fantasy', 
    'monospace',
    'Ubuntu',
    'Arial',
    'Times New Roman',
    'Verdana',
    'Georgia',
    'Helvetica',
    'Courier New',
    'Tahoma',
    'Impact',
    'Comic Sans MS',
    'Lucida Console'
  ];
  const FontFm = (event) => {
    setAge(event.target.value);
    props.fm(event.target.value)
  };
  const FontB = (event) => {
      
     setB(!B);
    props.fb(!B)
  };
  const FontAL = (event) => {
      
     setAl(!Al);
    props.al(!Al)

  };
  const FontAC = (event) => {
      
     setAc(!Ac);
    props.ac(!Ac)

  };
  const FontColor = (event) => {
      
    setColor(event);
   props.fntcolor(event)
 };
  const valuetext=(value) =>{
    // console.log(value)
    props.fs(value)
    return `${value}°C`;
  }
  // const handleClick = () => {
  //   setOpen(!open);
  // };

  return (
    <List
      component="nav"
      aria-labelledby="Font Settings"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Font Settings
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem button>
      <Typography style={{fontSize:'small',marginRight:5}} id="discrete-slider" gutterBottom>
        Size<br/>
      </Typography><br/>
      <Slider
        defaultValue={40}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={2}
        marks
        min={30}
        max={110}
      />
      </ListItem>
      <ListItem button>
      <Typography style={{fontSize:'small',marginRight:5}} id="discrete-slider" gutterBottom>
        Alignment<br/>
      </Typography><br/>
      <div style={{display:'contents'}}> 
     <IconButton onClick={FontB}>
      <FormatBoldIcon color="primary"/>
     </IconButton>
     <IconButton  onClick={FontAL}>
      <FormatAlignLeftIcon color="primary"/>
     </IconButton>
     <IconButton  onClick={FontAC}>
      <FormatAlignCenterIcon color="primary"/>
     </IconButton>
     </div>
      </ListItem>
      <ListItem button><div style={{display:'flex'}}>
        <Typography>Select Font Color : </Typography>
        <input type="color" style={{background:color!=null?color:'#000000'}} onChange={e => FontColor(e.target.value)} />
      </div>
      </ListItem>
      <ListItem button>
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel  >
          Font Family
        </InputLabel>
        <Select fullWidth
         
          value={age}
          onChange={FontFm}
          displayEmpty
          className={classes.selectEmpty}
        >
            {fonts.map((v,i)=>
            <MenuItem value={v}>{v}</MenuItem>
            )}
        </Select>
      </FormControl>
      </ListItem>
    </List>
  );
}
