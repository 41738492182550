import React, { useEffect } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import InboxList from "./InboxList";
import SelectedUser from "./SelectedUser";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import { useParams } from "react-router-dom";
var CryptoJS = require("crypto-js");

// console.log = console.warn = console.error = () => {};
const drawerWidth = 300;

// console.warn = console.error = () => {};
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: 64,
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      left: 0,
    },
  },
  hide: {
    display: "none",
  },

  menuButton: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  tool: {
    minHeight: 10,
    [theme.breakpoints.up("sm")]: {
      minHeight: 64,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft:drawerWidth+1,

    // },
  },
  grow: {
    flexGrow: 1,
  },

  title: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginLeft: 5,
    fontFamily:'Poppins',
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    //marginRight: theme.spacing(2),
    marginLeft: 20,
    width: "90%",
    //paddingRight:20,
    color: "white",
    top: 5,
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      // width: 'auto',
      display: "none",
    },
  },
  searchdesk: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      // position: 'relative', left: '20%', top: '5%',
      transform: "translate(-50%, -50%)",

      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginTop: "15%",
      width: "40%",
      marginLeft: "15%",
    },
  },
  searchblock: {
    backgroundColor: "#3f51b5",
    marginTop: 49,
    height: 45,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    color: "inherit",
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Inbox(props) {
  const classes = useStyles();
  const [result, setRes] = React.useState({});
  const [isinboxMobile, setMobileInbox] = React.useState(false);

  const [glist, setGlist] = React.useState({});
  const [isHome, setHome] = React.useState(true);
  const [CurrGroup, setGroup] = React.useState(null);
  const [Gid, setGroupid] = React.useState(null);
  const [currGid, setCurrGroupid] = React.useState(null);
  const [currUname, setCurrUname] = React.useState(null);
  const [sublistford, setSublistf] = React.useState(null);
  const [isDetails, setDetails] = React.useState(false);
  const [first, setFirst] = React.useState(true);
  const [Grps, setGrps] = React.useState([]);
  const [members, setMembers] = React.useState(null);
  const [cipuid, setCipuid] = React.useState(null);
  const { userid } = useParams();

  var gd = [];
  let sub = [];
  const isFirst = () => {
    setFirst(false);
  };
  const GroupM = (data, indx, list) => {
    GetMemners(data.gid, data.sub);
    setGlist(data);
    setHome(false);
    setGroup(data);
    setCurrGroupid(data.gid);
    setSublistf(data.sub);
  };
  const ChatDetail = (val) => {
    setDetails(val);
  };

  useEffect(() => {
    var cipu;
    if (userid.includes("enc-")) {
      var dec = userid.split("enc-")[1];

      // var cipher = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(dec));

      // var key = CryptoJS.enc.Utf8.parse('FbcCY2yCFBwVCUE9R+6kJ4fAL4BJxxjd');
      // var iv  = CryptoJS.enc.Utf8.parse('e16ce913a20dadb8');

      var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_KEY);
      var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_IV);

      // var encryptedCP = CryptoJS.AES.encrypt("258", key, { iv: iv });
      var decryptedWA = CryptoJS.AES.decrypt(atob(dec), key, { iv: iv });

      // var encryptedBase64 = encryptedCP.toString();
      var decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8);

      console.log("Decrypted data (Utf8): " + decryptedUtf8);
      cipu = decryptedUtf8;
    } else {
      cipu = userid;
    }
    setCipuid(cipu);
    async function fetchD() {
      const res = await axios
        .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
          ["fun_name"]: "getCustomGroupData",
          ["sid"]: props.match.params.sid,
          ["uid"]: props.match.params.userid,
        })
        .then((res) => {
          //  console.log(res);

          let groupn = res.data.CustomGroups.map(function(obj) {
            return obj.group_name;
          });
          let groupid = res.data.CustomGroups.map(function(obj) {
            return obj.subgroup_id;
          });
          let username = res.data.CustomGroups.map(function(obj) {
            return obj.user_id;
          });
          let subgroupid = res.data.CustomGroups.map(function(obj) {
            return obj.parent_id;
          });
          let uniqgid = Array.from(new Set(groupid));
          let uniqname = Array.from(new Set(groupn));
          let uniquid = Array.from(new Set(username));

          setGroupid(uniqgid);
          // setGroupid({
          //   subid:subgroupid,
          //   gid:uniqgid});
          let a = res.data.CustomGroups;

          if (a != null) {
            let g = [];

            let usr = [];
            for (let i = 0; i < a.length; i++) {
              for (let j = 0; j < a.length; j++) {
                // console.warn("UGRp-->2"+a[i].group_name+a[i]+a);
                let tmp;

                // console.log("NAME-->"+a[i].name+a[i].user_id)

                let gname = a[i].group_name;

                if (a[i].parent_id == a[j].group_id && a[i].forum_show == 1) {
                  tmp = a[j].group_name + "-" + gname;
                  // console.log('1->'+gname +a[i].subgroup_id +a[j].forum_show);
                  sub.push(a[j].group_id);
                  gd.push(a[i].parent_id);
                  // mtyp.push(a[i].member_type);
                  g.push(tmp);
                  usr.push({
                    gname: tmp,
                    sub: a[i].group_id,
                    gid: a[i].parent_id,
                  });
                }

                if (a[i].subgroup_id == -1 && a[i].forum_show == 1) {
                  g.push(a[i].group_name);
                  sub.push(-1);
                  gd.push(a[i].group_id);
                  usr.push({
                    gname: a[i].group_name,
                    sub: -1,
                    gid: a[i].group_id,
                  });
                }
              }
            }
            let s = [];
            let g1 = [];
            let gd1 = [];

            sub.forEach(function(value) {
              if (s.indexOf(value) == -1) s.push(value);
            });
            g.forEach(function(value) {
              if (g1.indexOf(value) == -1) g1.push(value);
            });
            gd.forEach(function(value) {
              if (gd1.indexOf(value) == -1) gd1.push(value);
            });
            let jsonObject = usr.map(JSON.stringify);

            let uniqueSet = new Set(jsonObject);
            let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
            setGrps(uniqueArray);
          }
          setCurrUname(uniqname);
          setRes(res);
        })
        .catch((err) => console.log("Error-" + err));
    }
    fetchD();
  }, []);

  //const container = window !== undefined ? () => window().document.body : undefined;
  const backtohome = () => {
    setMobileInbox(false);
    setHome(true);
    setFirst(true);
    setGroup(null);
    setFirst(true);
    setMembers(null);
  };
  const handleOnIdle = (event) => {
    // console.log('user is idle' )
    backtohome();
    getLastActiveTime();
    // console.log('last active', getLastActiveTime())
  };

  const handleOnActive = (event) => {
    // console.log('user is active')
    getRemainingTime();
    // console.log('time remaining', getRemainingTime())
  };

  const handleOnAction = (e) => {
    // console.log('user did something')
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 600000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  const GetMemners = (gid, sub) => {
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        ["fun_name"]: "getGroupMembers",
        ["sid"]: props.match.params.sid,
        ["uid"]: props.match.params.userid,
        ["gid"]: gid,
        ["subgid"]: sub,
      })
      .then((res) => {
        //  console.log(res);
        setMembers(res.data.Members);
        setMobileInbox(true);
      });
  };

  return result.data != null ? (
    <div className={classes.grow}>
      {isinboxMobile == false ? (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.tool}>
            <Typography className={classes.title} variant="h6" noWrap>
              Communication Forum
            </Typography>

            <div className={classes.grow} />
          </Toolbar>
        </AppBar>
      ) : null}

      <main className={classes.content} style={{ padding: 0, marginTop: 60 }}>

        {isinboxMobile == false ? (
          <InboxList
            G={GroupM}
            grps={Grps}
            list={glist}
            gid={Gid}
            data={result.data.CustomGroups}
            uid={cipuid}
            sid={props.match.params.sid}
          />
        ) : null}

        {isinboxMobile ? (
          <SelectedUser
            members={members}
            cd={ChatDetail}
            f={isFirst}
            GroupD={CurrGroup.gname}
            isf={first}
            sid={props.match.params.sid}
            cdval={isDetails}
            sub={sublistford}
            back={backtohome}
            data={result.data.CustomGroups}
            gid={currGid}
            Fname={currUname}
            uid={cipuid}
          />
        ) : null}
      </main>
    </div>
  ) : (
    <>
      <div
        className="d-flex justify-content-center align-items-center w-100"
        style={{ height: "100vh" }}
      >
        <div className="loader border-top-info" />
      </div>
    </>
  );
}
