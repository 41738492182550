import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {FormLabel,FormControl,Radio,RadioGroup} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// import {Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useParams} from 'react-router-dom';
import AchiveImageUp from './AchiveImageUp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import history from './history';
import axios from 'axios';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ApartmentIcon from '@material-ui/icons/Apartment';
import CallIcon from '@material-ui/icons/Call';
import LinkIcon from '@material-ui/icons/Link';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Helpdesk(props) {
  const classes = useStyles();
  var sortJsonArray = require('sort-json-array');
  const {sid} = useParams();

  const [result, setRes]= useState({});
  useEffect(()  =>{
    
    async function fetchD(){
     const res= await axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"AlumniContactInfo",['sid']:sid})
     .then(res=> {
       sortJsonArray(res.data.alumini_contactinfo,'id')
         setRes(res)}).catch(err =>console.log("Error-"+err));
 }
   fetchD();
   
},[])

  return (
    result.data!=null || result==null?<Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <img  style={{objectFit:'contain',maxWidth:'100%',maxHeight:'40vh'}} src={result.data.alumini_contactinfo[0].image}></img>
        {/* <Avatar className={classes.avatar}>
          <WhatsAppIcon fontSize='large' />
        </Avatar> */}
        {/* <Typography component="h1" variant="h5">
          Sign in 
        </Typography> */}
          
          </div>
          <div style={{marginTop:'20%'}}>
          <Typography variant="body2" color="textSecondary"  style={{fontSize:20,marginLeft:'15vw',justifyContent:'center',marginBottom:20}}> <strong>{result.data.alumini_contactinfo[0].name}</strong></Typography>
          <div style={{display:'flex'}}><ApartmentIcon fontSize='large' color='secondary'/><Typography variant="body2" color="textSecondary"  style={{fontSize:20,marginLeft:'10vw',justifyContent:'center',marginBottom:10}}><strong>{result.data.alumini_contactinfo[0].Address}</strong></Typography></div>
          <div style={{display:'flex'}}><MailOutlineIcon fontSize='large' color='secondary'/> <Typography variant="body2" color="textSecondary"  style={{fontSize:20,marginLeft:'10vw',justifyContent:'center',marginBottom:10}}><strong> {result.data.alumini_contactinfo[0].email}</strong></Typography></div>
          <div style={{display:'flex'}}><WhatsAppIcon fontSize='large' color='secondary'/> <Typography variant="body2" color="textSecondary"  style={{fontSize:20,marginLeft:'10vw',justifyContent:'center',marginBottom:10}}> <strong> {result.data.alumini_contactinfo[0].contact}</strong></Typography></div>
          <div style={{display:'flex'}}><LinkIcon fontSize='large' color='secondary'/> <Typography variant="body2" color="textSecondary"  style={{fontSize:20,overflowWrap:'anywhere', marginLeft:'10vw',justifyContent:'center',marginBottom:10}}><a href="http://www.shikshanmandalkarad.com/" target="_blank">http://www.shikshanmandalkarad.com/</a> </Typography></div>
          </div>
          
          </Container>:<><div className="d-flex justify-content-center">
            <div className="loader border-top-info margin-top-1" />
          </div></>
  );
    }