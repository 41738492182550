import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import {Typography,Paper,IconButton} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function AllCmts(props) {
  const classes = useStyles();
  const [allc,setAllc]=React.useState([]);
 // const cmt=props.cmt!=undefined?props.cmt:
  useEffect(()=>{
    if(props.cm!=undefined && props.cmt!=undefined){
      var tmp=[];
      for(var i=0;i<props.cmt.length;i++){
          for(var j=0;j<props.cm.length;j++){
            if(props.cmt[i].cid==props.cm[j].cid){
                tmp.push({d1:props.cmt[i],d2:props.cm[j]})
            }
           
          }
      }
      setAllc(tmp);
    }
  },[])
 
  return (         
        <>
    <List className={classes.root}>
        <ListItem alignItems="center" >
        <AccountCircleIcon color='secondary'/>
        <ListItemText
          primary= {<p style={{float:'left',margin:0}}>&nbsp;&nbsp;{props.userinfo.user_master[0].username}</p>}
        //   secondary={<p style={{float:'left',margin:0}}><br/><EmailIcon color='secondary' fontSize='large'/> {props.userinfo.user_master[0].email}</p>}
        />
      </ListItem>
      <Divider/>
      
      <Divider variant='fullWidth' /> 
      </List>
          {allc.length>0 ? allc.map((v,i)=>(
          props.userinfo.user_master[0].admin=="1"?
        <List>
        <ListItem alignItems="flex-start" onClick={()=> props.isClicked(v.d2)}>
       
        <ListItemText
         primary={<p style={{ margin: 0 }}>Visit Type- {v.d1.visit_type}<br/> Date- {v.d1.date}<br/> Time- {v.d1.time}<br/> Person Met- {v.d1.person_met}<br/> Probablity- {v.d1.success_probablity} <br/> Demo- {v.d1.product_demo} <br/> Comment - {v.d1.comment}</p>}
          // primary={<p style={{margin:0}}>{v.d1.comment}</p>} //User {v==3? 1:v}
          secondary={<><p style={{float:'right',margin:0}}> {v.d2.school_name} &nbsp;&nbsp; {v.d1.username} &nbsp;&nbsp;{moment(v.d1.created_on, ["YYYY-MM-DD hh:mm:ss"]).format("L")}</p></>}
        />
      </ListItem>
      <Divider variant='fullWidth' />
      </List>: props.userinfo.user_master[0].userid==v.d1.userid?
      <List>
      <ListItem alignItems="flex-start" onClick={()=> props.isClicked(v.d2)}>
     
      <ListItemText
       primary={<p style={{ margin: 0 }}>Visit Type- {v.d1.visit_type}<br/> Date- {v.d1.date}<br/> Time- {v.d1.time}<br/> Person Met- {v.d1.person_met}<br/> Probablity- {v.d1.success_probablity} <br/> Demo- {v.d1.product_demo} <br/> Comment - {v.d1.comment}</p>}
        // primary={<p style={{margin:0}}>{v.d1.comment}</p>} //User {v==3? 1:v}
        secondary={<><p style={{float:'right',margin:0}}> {v.d2.school_name} &nbsp;&nbsp; {v.d1.username} &nbsp;&nbsp;{moment(v.d1.created_on, ["YYYY-MM-DD hh:mm:ss"]).format("L")}</p></>}
      />
    </ListItem>
    <Divider variant='fullWidth' />
    </List>:null
      
      )):null}    
        </>
  );
}
