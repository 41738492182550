import React, { useState, useEffect } from "react";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import Navbar from "./Header";
import Loader from "react-loader-spinner";
import "./Style.css";
import { DecryptED } from "../DecryptJS/Decrypt";

const configUrl = "https://marcopolo.prisms.in";
const token = "#$laksdfnlknoea#$@$%^&%$";

const DownLoadMultiple = () => {
  const { sid, idesign } = useParams();
  const [p1, setP] = useState(false);
 
  const [stateData, setStateData] = useState({
    imageData: [],
    divName: "",
    std: "",
    sclass: [],
    sdiv: [],
    classID: "",
    divID: "",
    apiLoader: false,
    studentCount:null,
    totalIdCards: null,
    render: false,
  });

  const {
    imageData,
    divName,
    std,
    sclass,
    sdiv,
    classID,
    divID,
    apiLoader,
    studentCount,
    totalIdCards,
    render,
  } = stateData;

  useEffect(() => {
    const getClassDetails = async () => {
      try {
        const { data } = await axios.post(
          `${configUrl}/api/tscommon/getallclass`,
          { sid : DecryptED(sid) },
          { headers: { Authorization: token } }
        );
        setStateData((prevState) => ({
          ...prevState,
          sclass: data,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    getClassDetails();
  }, [sid]);

  useEffect(() => {
    const getDivDetails = async (classId) => {
      try {
        const { data } = await axios.post(
          `${configUrl}/api/tscommon/getalldiv`,
          { class_id: classId, sid: DecryptED(sid)  },
          { headers: { Authorization: token } }
        );
        setStateData((prevState) => ({
          ...prevState,
          sdiv: data,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    if (classID) {
      getDivDetails(classID);
    }
  }, [classID, sid]);

  const downloadImagesAsZip = async (imageData) => {
    const zip = new JSZip();
  
    const imagePromises = imageData.map(async (image) => {
      if (image.idcard_image) {
        const url = image.idcard_image;
        const filename = url.substring(url.lastIndexOf("/") + 1);
        const escapedFilename = filename.substring(11); // Escape the first 20 characters
        const response = await axios.get(url, { responseType: "blob" });
        zip.file(escapedFilename, response.data);
      }
    });
  
    try {
      setP(true)

      await Promise.all(imagePromises);
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `class_${std}_div_${divName}.zip`);
      setP(false)

    } catch (error) {
      console.error("Error fetching images:", error);

    }
  };
  
  console.log('DONWLOADING IN PROGRESS'+p1)

  const handleClassChange = (e) => {
    const [classId, std] = e.target.value.split(" ");
    setStateData((prevState) => ({
      ...prevState,
      classID: classId,
      std: std,
    }));
  };

  const handleDivChange = (e) => {
    const [divId, divisionName] = e.target.value.split(" ");
    setStateData((prevState) => ({
      ...prevState,
      divID: divId,
      divName: divisionName,
      studentCount:null,
      totalIdCards: null,
    }));
  };

  const generateIdCards = async () => {
    try {
      setStateData((prevState) => ({
        ...prevState,
        apiLoader: true,
      }));

      const { data } = await axios.post(
        `${configUrl}/api/idcard/students/idcards`,
        { sid : DecryptED(sid), design_id: idesign, classid: [classID], div: [divID] },
        { headers: { Authorization: token } }
      );

      setStateData((prevState) => ({
        ...prevState,
        imageData: data,
        studentCount:data.length,
        totalIdCards: data.filter((image) => image.idcard_image !== "").length,
        render: true,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setStateData((prevState) => ({
        ...prevState,
        apiLoader: false,
      }));
    }
  };



  return (
    <>
      <Navbar />
      <div className="container">
        <div className="select-container">
          <select onChange={handleClassChange} className="select__box">
            <option value="default" className="select__option">
              Select Class
            </option>
            {sclass.map((data) => (
              <option key={data.id} value={`${data.id} ${data.class_name}`}>
                {data.class_name}
              </option>
            ))}
          </select>

          <select onChange={handleDivChange} className="select__box">
            <option value="default" className="select__option">
              Select Div
            </option>
            {sdiv.map((data) => (
              <option key={data.id} value={`${data.id} ${data.div_name}`}>
                {data.div_name}
              </option>
            ))}
          </select>
        </div>

        {apiLoader ? (
          <Loader type="ThreeDots" color="#111" height={80} width={80} />
        ) : (
          <button onClick={generateIdCards} className="btn__check">
            Check
          </button>
        )}

        {!render ? (
          <p className="message">
            "Download ID cards if they are available; otherwise, please apply
            first.".
          </p>
        ) : (
          <>
 {studentCount !== null && (
              <p className="message" style={{fontFamily:'monospace', marginTop: "10px", fontSize:'22px' }}>
                Total Students:{" "}
                {studentCount === 0 ? "Not available" : studentCount }
              </p>
            )}

            {totalIdCards !== null && (
              <p className="message" style={{ fontFamily:'monospace',marginTop: "5px", fontSize:'22px' }}>
                Total ID cards:{" "}
                {totalIdCards === 0 ? "Not available" : totalIdCards}
              </p>
            )}

            {render && totalIdCards !== null && totalIdCards !== 0 && (
           p1 ? <p style={{fontFamily:'monospace',fontWeight:'bold',fontSize:'24px'}}>...Downloading</p> :   <button
                onClick={() => downloadImagesAsZip(imageData)}
                className="btn__check"
              >
             Download
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DownLoadMultiple;
