import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import {Typography,TextField,Paper,IconButton,InputBase} from '@material-ui/core';
 import SendIcon  from '@material-ui/icons/Send';
 import CommentIcon from '@material-ui/icons/Comment';
 import CallIcon from '@material-ui/icons/Call';
 import SortIcon from '@material-ui/icons/Sort';
 import moment from 'moment';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX:'hidden',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  inputInput: {
    backgroundColor:'white',
    color: 'inherit',
    padding: theme.spacing(1, 1, 1, 0),
      
    transition: theme.transitions.create('width'),
    width: '80%',
    //border:'1px solid',
    marginTop:5,
    marginLeft:10
  },
   '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function Comments(props) {
  const classes = useStyles();
  const [comment,setComment]=useState(null);  
  

  const [propComment,setPComment]=useState();

  
useEffect(()=>{
  
  var Carr=[];
  
  if(props.cmt !=null && props.cmt != undefined){
    for(var i=0;i< props.cmt.length;i++){
      //console.log(cselected.cid)
      if( props.cmt[i].cid ==props.cm.cid){
        Carr.push(props.cmt[i]);
      }
    }
  }
  setPComment(Carr);
  
},[props])

// const Send=()=>{
//   var arr=[];
//   if(comment !=null && comment != ''){
//   axios.post('http://localhost:8080/Foruminsert/insert.php',{['uid']:222,['name']:'Abcd',['cid']:props.cm.cid,['comment']:comment}).then((res)=>{
//     for(var i=0;i<res.data.fcomment.length;i++){
//       if(res.data.fcomment[i].cid==props.cm.cid){
//         arr.push(res.data.fcomment[i]);
        
//       }
//     }
  
//     props.F();
//   })
 
// }

// setComment('');
// }
  return (
      <>
     
     <Paper style={{maxHeight:'87vh',overflow:'auto',maxWidth:'-webkit-fill-available',minWidth:'-webkit-fill-available'}}>
    <List className={classes.root}>
        <ListItem alignItems="center" style={{marginLeft:'36%',}}>
            
        <ListItemText
          primary={props.cm.client_name }
        />
      </ListItem>
      <Divider/>
      <ListItem alignItems="center">
            <IconButton style={{alignItems:'flex-start'}} onClick={()=>window.location.href="tel:"+props.cm.mobile_no}>
  <CallIcon color="secondary"/> &nbsp;&nbsp; <p style={{margin:0, fontSize:'medium',alignSelf:'center'}}>{props.cm.mobile_no}</p>
            </IconButton>
            <ListItemText
              primary={<p style={{margin:0,marginLeft:'32%'}}>&#x20B9; {props.cm.amount}</p> }
            />
          </ListItem>
          <Divider/>
      <ListItem alignItems="center" style={{marginLeft:'1%'}}>
      
                <CommentIcon color='secondary' style={{marginRight:10}} />
           
        <ListItemText
          primary={'Comments : ' }
        />
      </ListItem>
      <Divider variant='fullWidth' /> 
      </List>
      <List>
          {propComment!=null ? propComment.map((v,i)=>(
          
        <>
        <ListItem alignItems="flex-start">
        {/* {console.log(propComment)} */}
        <ListItemText
          primary={<p style={{margin:0}}>{v.comment}</p>} //User {v==3? 1:v}
      secondary={<p style={{float:'right',margin:0}}> {v.username} &nbsp;&nbsp;{moment(v.created_on, ["YYYY-MM-DD"]).format("L")}</p>}
        />
      </ListItem>
      <Divider variant='fullWidth' />
      </>)):null}  
      <ListItem alignItems="flex-start">
      
    <Paper style={{ bottom:5,float:'right',minHeight: 73,backgroundColor:'#f5f6fa',
    maxHeight:200,overflowY:'scroll',minWidth:'100%',marginLeft:-2,borderRadius:1}} >
     
          <InputBase
            className={classes.inputInput}
            style={{marginLeft:10}}
            placeholder="Type Comment here..."
            type="text"
           // accept="image/*"
           multiline
           rows={2}
            value={comment}
             onChange={(e)=>setComment(e.target.value)}
          />       
          
        <IconButton type="submit" className={classes.iconButton} onClick={()=>{props.send(comment);setComment('');}}>
             <SendIcon color="secondary" />
           </IconButton>     
        </Paper>
       
      </ListItem>
       </List> 
    </Paper>
    {/* <div>
    <Paper style={{ position: "fixed",bottom:5,float:'right',minHeight: '5%',backgroundColor:'#f5f6fa',
    maxHeight:200,overflowY:'scroll',minWidth:'100%',marginLeft:12,borderRadius:1}} >
     
          <InputBase
            className={classes.inputInput}
            style={{marginLeft:10}}
            placeholder="Type Comment here..."
            type="text"
           // accept="image/*"
           multiline
           
            value={comment}
             onChange={(e)=>setComment(e.target.value)}
          />            
        </Paper>
        <Paper style={{ position: "fixed",bottom:5,right:5}} >
        <IconButton type="submit" className={classes.iconButton} onClick={()=>{props.send(comment);setComment('');}}>
             <SendIcon color="secondary" />
           </IconButton>
           </Paper>
       
    </div> */}
    </>
  );
}
