import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { TextareaAutosize } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function RequestAppointment(props) {

    console.log(props)
    const history = useHistory();
    const { sid, uid } = useParams();

    const [progress, setprogress] = useState(0);
    const [userDetails, setUserDetails] = useState();
    const [personslist, setpersonslist] = useState("");
    const [fileError, setFileError] = useState(false);
    const [Staff_Id, setStaff_Id] = useState({ person: "", staff_id: "" });

    console.log(Staff_Id)

    useEffect(() => {
        if (props.userType === "Parent") {
            axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "GetStaffDetailsParent", sid: sid }).then((res) => {
                var data = typeof (res.data) === "object" ? res.data.Staff ? res.data.Staff : [] : [];
                if (data.length > 1) {
                    setpersonslist(data.sort((a, b) => -b.type.localeCompare(a.type)));
                } else {
                    setpersonslist(data)
                }
            })
        } else {
            axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Appointment_Superadmin", sid: sid }).then((res) => {
                let dataaa = [];
                axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Appointment_StaffAccess", sid: sid }).then((res) => {
                    var data = typeof (res.data) === "object" ? res.data.Staff ? res.data.Staff : [] : [];
                    dataaa.push(...data)
                })
                if (res.data) {
                    if (res.data.slist) {
                        if (typeof (res.data.slist) === "object") {
                            (res.data.slist).forEach((s) => {
                                if (s.is_main === "1" && s.sid !== sid) {
                                    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Appointment_StaffAccess", sid: s.sid }).then((res) => {
                                        var data = typeof (res.data) === "object" ? res.data.Staff ? res.data.Staff : [] : [];
                                        dataaa.push(...data)
                                    })
                                }
                            })
                        }
                    }
                }
                if (dataaa.length > 1) {
                    setpersonslist(dataaa.sort((a, b) => -b.type.localeCompare(a.type)));
                } else {
                    setpersonslist(dataaa)
                }
            })
        }
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "AUserInfo", sid: sid, u_id: uid }).then((res) => {
            setUserDetails(res.data);
        })
    }, []);

    function getstafflist(fun, sid) {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: fun, sid: sid }).then((res) => {
            var data = typeof (res.data) === "object" ? res.data.Staff ? res.data.Staff : [] : [];
            return data
        })
    }

    function fileSize(e) {
        for (let i = 0; i < e.target.files.length; i++) {
            if (e.target.files[i].size > 10485760) {
                setFileError(true)
                break;
            }
        }
    }

    function renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    const createRequest = async () => {
        var formData = {};
        const formdata = new FormData();
        var filesInF = document.getElementById("mfiles").files
        for (let i = 1; i < filesInF.length; i++) {
            if (filesInF[i].name.includes(",")) {
                formdata.append("file" + i, renameFile(filesInF[i], filesInF[i].name.replaceAll(",", "_")));
            } else {
                formdata.append("file" + i, filesInF[i]);
            }
        }
        Array.from(document.getElementsByTagName('form')[0]).map((i) => {
            if (i.name === "") {
                return null
            }
            if (i.name === 'file') {
                formData[i.name] = i.value;
                if (i.files.length === 0) {
                    formdata.append('file', "")
                } else {
                    if (i.files[0].name.includes(",")) {
                        formdata.append('file0', renameFile(i.files[0], i.files[0].name.replaceAll(",", "_")))
                    } else {
                        formdata.append('file0', i.files[0])
                    }
                }
            } else if (i.name === "person") {
                formdata.append("person", Staff_Id.person);
                formData.person = Staff_Id.person;
                formdata.append("staff_id", Staff_Id.staff_id);
                // if (props.userType === "Parent") formdata.append("staff_id", Staff_Id.staff_id)
            } else {
                formdata.append(i.name, i.value)
                formData[i.name] = i.value;
            }
            return null
        });

        formdata.append('fun_name', "Insert_ASuperadmin");
        formdata.append('sid', sid);
        formdata.append('uid', uid);
        formdata.append('requestStatus', 'Pending');
        formdata.append('participants', "");
        formdata.append('pre_meeting', "");
        formdata.append('post_meeting', "");
        formdata.append('name', userDetails !== undefined ? userDetails.student !== undefined ? userDetails.student[0].name : "" : "");
        formdata.append('s_name', userDetails !== undefined ? userDetails.student !== undefined ? userDetails.school.school_name : "" : "");
        formdata.append('nooffiles', filesInF.length)

        if (formData.person !== "") {
            if (formData.requestDate !== "") {
                if (formData.time !== "") {
                    if (formData.subject !== "") {
                        if (formData.priority !== "") {
                            if (formdata.get(['name']) !== undefined && formdata.get(['name']) !== null && formdata.get(['name']) !== "") {
                                if (formData.file !== "") {
                                    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', formdata,
                                        { onUploadProgress: ProgressEvent => { setprogress(Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)) } })
                                        .then(() => {
                                            props.userType === "Parent" ? props.showAppointment() : history.push(`/appointment/user/appointmentstatus/${sid}/${uid}`)
                                        })
                                } else {
                                    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', formdata).then(() => {
                                        props.userType === "Parent" ? props.showAppointment() : history.push(`/appointment/user/appointmentstatus/${sid}/${uid}`)
                                    })
                                }
                            }
                            else { alert("Cant find User Name, Please Refresh the Page") }
                        } else { alert("Please Choose Priority") }
                    } else { alert("Please Enter Subject") }
                } else { alert('Please Choose Duration') }
            } else { alert("Please Choose Date") }
        } else { alert("Please Choose Staff") }
    }

    return (<>
        <div className={props.userType === "Parent" ? "" : "APMCard"} >
            <div className={props.userType === "Parent" ? "" : "APMCardSub"}>
                {
                    props.userType === "Parent" ?
                        null
                        :
                        <>
                            <div className="APHeader">
                                <img onClick={() => { history.goBack() }} src="/icons/back.png" alt="back" /><div>Request Appointment</div>
                            </div>
                            <hr className="APhr-line"></hr>
                        </>
                }
                <div className="APsidemenu1" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: progress > 0 ? "100%" : "0%", padding: 0, backgroundColor: "rgba(0,0,0,0.4)" }}>
                    <div className="APquiz-card">
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                            <h3 style={{ fontSize: 20, fontWeight: "bold", color: "blue" }}>File Uploading ...</h3><br></br>
                            <div style={{ width: "170px", height: "170px" }}>
                                <CircularProgressbar
                                    value={progress}
                                    text={`${progress}%`}
                                    circleRatio={0.75}
                                    styles={buildStyles({
                                        rotation: 1 / 2 + 1 / 8,
                                        trailColor: "#eee",
                                        pathTransition: "none",
                                        pathColor: progress > 20 ? "#4CC417" : "red",
                                        textColor: progress > 20 ? "#4CC417" : "red",
                                        textSize: "20px"
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <h4 style={{ position: "absolute", textAlign: "center", fontWeight: "500", color: "lightblue", bottom: "5px" }}>Please wait after file upload you will automatically redirected</h4>
                </div>
                <div>
                    {!personslist ?
                        <div className="APLoader">
                            <Loader
                                type="Puff"
                                color="#1e5597"
                                height={60}
                                width={60}
                            />
                        </div> :
                        <div style={{ overflow: "scroll", height: "100vh" }}>
                            <div style={{ marginBottom: "100px" }}>
                                <form>
                                    <table style={{ width: "95%" }}>
                                        <tbody>
                                            <tr className="APform-div">
                                                <td className="APiconandheading">
                                                    <img src={'/icons/person.png'} style={{ width: "40px" }} alt="person img" />
                                                </td>
                                                <td className="APtableContent">
                                                    {/* <select name="person"
                                                        onChange={
                                                            (e) => {
                                                                if (e.target.value === "") {
                                                                    setStaff_Id({ person: "", staff_id: "" })
                                                                } else {
                                                                    const sdata = JSON.parse(e.target.value)
                                                                    setStaff_Id(sdata)
                                                                }
                                                            }
                                                        }
                                                    >
                                                        <input type="text" />
                                                        <option value="">Choose Staff</option>
                                                        {personslist.map((i, k) => {
                                                            return (
                                                                <option key={k} value={JSON.stringify({ person: i.first_name + " " + i.middle_name + " " + i.last_name + " - " + i.type, staff_id: i.staff_id })}>{i.first_name + " " + i.middle_name + " " + i.last_name + " - " + i.type}</option>
                                                            )
                                                        })}
                                                    </select> */}
                                                    <Autocomplete
                                                        id="custom-input-demo"
                                                        options={personslist}
                                                        getOptionLabel={i => i.first_name + " " + i.middle_name + " " + i.last_name}
                                                        onChange={(object, value) => {
                                                            if (!value) {
                                                                setStaff_Id({ person: "", staff_id: "" })
                                                            } else {
                                                                setStaff_Id({ person: value.first_name + " " + value.middle_name + " " + value.last_name, staff_id: value.staff_id })
                                                            }
                                                        }}
                                                        groupBy={(option) => option.type}
                                                        renderInput={(params) => (
                                                            <div ref={params.InputProps.ref}>
                                                                <input placeholder="Choose Staff" name="person" type="text" {...params.inputProps} />
                                                            </div>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                            <tr className="APform-div">
                                                <td className="APiconandheading">
                                                    <img src={'/icons/calendar.png'} style={{ width: "25px", marginTop: "15px", marginRight: "5px", marginLeft: "5px" }} alt="calendar icon" />
                                                </td>
                                                <td className="APtableContent">
                                                    <input id="date" type="date" style={{ color: "#1e5597" }} name="requestDate" min={new Date().toISOString().slice(0, 10)} />
                                                </td>
                                            </tr>
                                            <tr className="APform-div">
                                                <td className="APiconandheading">
                                                    <img src={'/icons/clock.png'} style={{ width: "35px", marginTop: "10px", marginRight: "5px" }} alt="clock" />
                                                </td>
                                                <td className="APtableContent">
                                                    <select name="time">
                                                        <option value="">Choose Duration</option>
                                                        <option value="10 mins">10 mins</option>
                                                        <option value="20 mins">20 mins</option>
                                                        <option value="30 mins">30 mins</option>
                                                        <option value="40 mins">40 mins</option>
                                                        <option value="50 mins">50 mins</option>
                                                        <option value="60 mins">60 mins</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr className="APform-div">
                                                <td className="APiconandheading">
                                                    <div style={{ color: "#1e5597", marginTop: "10px" }}><b>Subject</b></div>
                                                </td>
                                                <td className="APtableContent">
                                                    <input type="text" name="subject" placeholder="Enter Subject" />
                                                </td>
                                            </tr>
                                            <tr className="APform-div">
                                                <td className="APiconandheading">
                                                    <div style={{ color: "#1e5597", marginTop: "10px" }}><b>Priority</b></div>
                                                </td>
                                                <td className="APtableContent">
                                                    <select name="priority">
                                                        <option value="">Choose Priority</option>
                                                        <option value="High">High</option>
                                                        <option value="Medium">Medium</option>
                                                        <option value="Low">Low</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr className="APform-div">
                                                <td className="APiconandheading">
                                                    <img src={'/icons/uploadfile.png'} style={{ width: "35px", marginTop: "7px" }} alt="upload" />
                                                </td>
                                                <td className="APtableContent">
                                                    <input id="mfiles" type="file" name="file" style={{ display: "block" }} onChange={(e) => { setFileError(false); fileSize(e) }} multiple />
                                                </td>
                                            </tr>
                                            {props.userType === "Parent" ?
                                                <tr className="APform-div">
                                                    <td className="APiconandheading">
                                                        <img src={'/icons/notes.png'} style={{ width: "30px", marginTop: "7px" }} alt="upload" />
                                                    </td>
                                                    <td className="APtableContent">
                                                        <TextareaAutosize
                                                            placeholder="Enter Description here..."
                                                            name="description"
                                                        />
                                                    </td>
                                                </tr>
                                                :
                                                null
                                            }
                                        </tbody>
                                    </table>
                                    {
                                        fileError ?
                                            <div style={{ textAlign: "center" }} >
                                                <span style={{ color: "red", fontWeight: 600 }}>Please Choose Files Less Than 10MB</span>
                                            </div>
                                            :
                                            null
                                    }
                                </form>
                                <div style={{ alignItems: "center", justifyContent: "center", display: "flex", width: "95%", marginTop: "15px" }}>
                                    <div className="APLandingButtons" style={{ padding: "7px 50px" }} onClick={() => { fileError ? alert("Please Choose Files Less Than 10MB") : createRequest() }}>
                                        Request
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </>);
}
