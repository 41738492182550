import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Appointment from '../ReusedComponents/Appointments';
import { useParams } from 'react-router-dom';

export default function AppointmentStatus(props) {
    const [Appointmentslist, setAppointmentslist] = useState([]);
    const [Loader, setLoader] = useState(true);
    const { sid, uid } = useParams();

    useEffect(() => {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "GetSuperAStatusByUid", uid: uid, sid: sid }).then((res) => {
            console.log(res.data.AStatus)
            setAppointmentslist((res.data.AStatus !== undefined) ? (res.data.AStatus).reverse() : []); setLoader(false);
        })
    }, []);

    return (
        <Appointment data={Appointmentslist} userType={props.userType === "Parent" ? "Parent" : ""} type={"User"} loader={Loader} sid={sid} uid={uid} />
    );
}
