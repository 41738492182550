import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import { Button, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import ReactCrop from "react-image-crop";
import { FormControl } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "react-image-crop/dist/ReactCrop.css";
import Resizer from "react-image-file-resizer";
import Swal from "sweetalert2";
import useStyles from "../style/MaterilaUI";
import "../style/style.css";
import { DecryptED } from "../../DecryptJS/Decrypt";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
function ContainerMain({ sid }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(null);
  const [fileimage, setImgFile] = React.useState(null);
  const [Static_Data, setStaticData] = React.useState(null);

  const [total, setTotal] = React.useState(0);
  const [K, setK] = React.useState(null);
  const [width] = React.useState(200);
  const imgRef = React.useRef(null);

  const [height] = React.useState(200);
  const [Orgimg, setOrgimg] = React.useState(null);
  const [crop, setCrop] = React.useState({
    unit: "px",
    width: width,
    height: height,
  });
  const [completedCrop, setCompletedCrop] = React.useState(null);

  const configUrl = "https://marcopolo.prisms.in";

  const token = "#$laksdfnlknoea#$@$%^&%$";
  const [stateData, setStateData] = useState({
    imageData: [],
    divName: "",
    std: "",
    sclass: [],
    sdiv: [],
    classID: "",
    divID: "",
    render: false,
  });
  const { sclass, sdiv, classID, divID } = stateData;
  useEffect(() => {
    const getClassDetails = async () => {
      try {
        const { data } = await axios.post(
          `${configUrl}/api/tscommon/getallclass`,
          { sid: DecryptED(sid) },
          { headers: { Authorization: token } }
        );
        setStateData((prevState) => ({
          ...prevState,
          sclass: data,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    getClassDetails();
  }, [sid]);

  useEffect(() => {
    const getDivDetails = async (classId) => {
      try {
        const { data } = await axios.post(
          `${configUrl}/api/tscommon/getalldiv`,
          { class_id: classId, sid: DecryptED(sid) },
          { headers: { Authorization: token } }
        );
        setStateData((prevState) => ({
          ...prevState,
          sdiv: data,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    if (classID) {
      getDivDetails(classID);
    }
  }, [classID, sid]);

  useEffect(() => {
    document.getElementsByTagName("title")[0].innerHTML = "Photo Crop";
  }, []);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
        200,
        200
      );
    });

  useEffect(() => {
    if (Static_Data != null) setK(0);
    preview(0);
  }, [Static_Data]);

  const handleClassChange = (e) => {
    const [classId, std] = e.target.value.split(" ");
    setStateData((prevState) => ({
      ...prevState,
      classID: classId,
      std: std,
    }));
  };

  const handleDivChange = (e) => {
    const [divId, divisionName] = e.target.value.split(" ");
    setStateData((prevState) => ({
      ...prevState,
      divID: divId,
      divName: divisionName,
    }));
  };
  const preview = (K) => {
    if (Static_Data != null) setOrgimg(Static_Data.Id[K]);
  };

  const handleNext = () => {
    if (K < total - 1) {
      preview(K + 1);
      setK(K + 1);
    } else {
      window.alert("final entry");
    }
  };
  const handlePrev = () => {
    if (K > 0) {
      preview(K - 1);
      setK(K - 1);
    } else {
      window.alert("first entry");
    }
  };

  const generateIdCards = async () => {
    try {
      setStateData((prevState) => ({
        ...prevState,
        apiLoader: true,
      }));

      const { data } = await axios.post(
        `${configUrl}/api/crop/student/details`,
        { sid: DecryptED(sid), cid: classID, did: divID },
        { headers: { Authorization: token } }
      );
      setStaticData(data);
      setTotal(data.Total);
    } catch (error) {
      console.log(error);
    } finally {
      setStateData((prevState) => ({
        ...prevState,
        apiLoader: false,
      }));
    }
  };

  const toDataURL = (url, callback) => {
    // var s= url.replace("https://files.prisms.in","");
    var s = url;

    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();

      reader.onloadend = function() {
        // console.log(reader.result)

        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", s);
    // xhr.setRequestHeader('Content-type', 'application/octet-stream')
    // xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.responseType = "blob";
    xhr.send();
  };
  const getResizedCanvas = () => {
    var c = window.confirm("Do you want to crop and update the image");
    if (c) {
      const canvasg = document.createElement("canvas");
      if (!completedCrop || !canvasg) {
        return;
      }
      const canvas = canvasg;
      const image = imgRef.current;
  
      const crop = completedCrop;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext("2d");
      const pixelRatio = window.devicePixelRatio;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";
      var image1 = new Image();
      image1.onload = async function() {
        ctx.drawImage(
          image1,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
  
        // Construct the new image link
        let li = Orgimg.avatar.lastIndexOf(".");
        let link = Orgimg.avatar.substring(0, li);
        let re = Orgimg.avatar.substring(li);
        let newlink = link + ".crop" + re;
        if (
          newlink.includes("https://s3.amazonaws.com/topschool-attachments")
        ) {
          var res = newlink.replace(
            "https://s3.amazonaws.com/topschool-attachments",
            ""
          );
        } else if (newlink.includes("https://files.prisms.in")) {
          var res = newlink.replace("https://files.prisms.in", "");
        }
        var lname = newlink.substring(newlink.lastIndexOf("/") + 1);
        var newlink2 = res.substring(1, res.lastIndexOf("/"));
  
        // Convert canvas to file
        let FileO = await dataURLtoFile(canvas.toDataURL(), "Avater.png");
  
        // Create FormData
        const fmdata = new FormData();
        fmdata.append("fun_name", "Imagecrop");
        fmdata.append("sid", sid);
        fmdata.append("file", FileO);
        fmdata.append("userid", Orgimg.user_id);
        fmdata.append("newlink", newlink2);
        fmdata.append("lname", lname);
  
        // Post cropped image
        axios
          .post(
            "https://topschool.prisms.in/rest/index.php/user_list.json?",
            fmdata
          )
          .then((res) => {
            console.log(res.data.updated);
            Swal.fire({
              icon: "success",
              title: "Successfully cropped and updated",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              // After cropping and updating, call handleNext
              handleNext();
            });
          })
          .catch((error) => {
            console.error("Error while updating image:", error);
          });
      };
      image1.src = open;
    } else {
      console.log("cancel");
    }
  };
  
  useEffect(() => {
    if (Orgimg != null) {
      if (Orgimg.avatar != "") {
        toDataURL(Orgimg.avatar, async function(dataUrl) {
          var fil = dataUrl.replace("application/octet-stream", "image/png");
          setOpen(fil);
        });
      }
    }
  }, [Orgimg]);
  const onLoad = React.useCallback((img) => {
    imgRef.current = img;

    setImgFile(imgRef.current.src);
  }, []);
  useEffect(() => {
    const canvasg = document.createElement("canvas");
    if (!completedCrop || !canvasg) {
      console.log(canvasg);
      return;
    }
    const canvas = canvasg;
    const image = imgRef.current;

    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    // ctx.rotate(rotate * Math.PI / 180);
    ctx.imageSmoothingQuality = "high";

    var image1 = new Image();
    image1.onload = function() {
      ctx.drawImage(
        image1,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
      // console.log("open",canvas.toDataURL())
    };

    image1.src = open;
  }, [completedCrop]);

  const handleTotal = () => {
    setTotal(0);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div style={{ display: "flex", marginTop: 20 }}>
          {Orgimg != null ? (
            <div>
              <Typography
                style={{
                  position: "fixed",
                  top: "32vh",
                  height: "40vh",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {Orgimg !== null ? Orgimg.name : ""}
              </Typography>
              <div className={classes.divclass}>
                {open != null && Orgimg.avatar !== "" ? (
                  <ReactCrop
                    src={
                      open != null && Orgimg.avatar != ""
                        ? open
                        : "https://st2.depositphotos.com/1064024/10769/i/600/depositphotos_107694484-stock-photo-little-boy.jpg"
                    }
                    onImageLoaded={onLoad}
                    onComplete={(c) => setCompletedCrop(c)}
                    crop={crop}
                    onChange={(newCrop) => setCrop(newCrop)}
                  />
                ) : (
                  <Typography>No Image Available </Typography>
                )}
              </div>
            </div>
          ) : (
            <div style={{ width: "100vw" }} />
          )}
          <div className={classes.select}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              className={classes.form1}
            >
              <select onChange={handleClassChange} className="s1">
                <option value="default">Select Class</option>
                {sclass.map((data) => (
                  <option key={data.id} value={`${data.id} ${data.class_name}`}>
                    {data.class_name}
                  </option>
                ))}
              </select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.form2}
            >
              <select onChange={handleDivChange} className="s1">
                <option value="default">Select Div</option>
                {sdiv.map((data) => (
                  <option key={data.id} value={`${data.id} ${data.div_name}`}>
                    {data.div_name}
                  </option>
                ))}
              </select>
            </FormControl>

            <button
              className="btnP"
              onClick={() => {
                setK(0);
                handleTotal();
                generateIdCards();
              }}
            >
              Preview
            </button>
          </div>

          {Orgimg != null ? (
            <div className={classes.btnip}>
              <TextField
                className={classes.t1}
                size="small"
                variant="outlined"
                type="number"
                value={crop.width}
                onChange={(e) => setCrop({ ...crop, width: e.target.value })}
                label="width in px"
              />
              <TextField
                className={classes.t2}
                size="small"
                variant="outlined"
                type="number"
                value={crop.height}
                onChange={(e) => setCrop({ ...crop, height: e.target.value })}
                label="height in px"
              />
            </div>
          ) : null}
          {Orgimg != null ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.btncrop}
              onClick={
                () => getResizedCanvas()
                // generateDownload(document.getElementById('canvas'), completedCrop)
              }
            >
              Crop
            </Button>
          ) : null}
          {/* <p>{crop.height+"-"+crop.width}</p> */}
          {Orgimg != null ? (
            <div>
              <IconButton
                className={classes.arrowsmall}
                //   style={{position:'fixed',top:'36vh',left: '30%',transform: 'translateX(-50%)'}}
                onClick={() => handlePrev()}
                fullWidth
                variant="contained"
                color="primary"
                // className={classes.submit}
              >
                <ArrowBackIosIcon
                  color="primary"
                  style={{ width: 80, height: 80 }}
                />
              </IconButton>

              <IconButton
                className={classes.arrowsmall2}
                //   style={{position:'fixed',top:'36vh',left: '70%',transform: 'translateX(-50%)'}}

                onClick={() => handleNext()}
                fullWidth
                variant="contained"
                color="primary"
                // className={classes.submit}
              >
                <ArrowForwardIosIcon
                  color="primary"
                  style={{ width: 80, height: 80 }}
                />
              </IconButton>
            </div>
          ) : null}
        </div>
        <div style={{ marginTop: 30 }} />
      </main>
    </div>
  );
}

export default ContainerMain;
