import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


const ViewdaysPlan = ({ days, weeklyData, setWeeklyData, index }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");

  const updateState = (e, target) => {
    setWeeklyData(prevWeeklyData => {
      const updatedWeeklyData = [...prevWeeklyData];
      updatedWeeklyData[index].target = e;
      return updatedWeeklyData;
    })
    console.log(weeklyData)
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{days}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <TextField
          fullWidth
          id="topic"
          label="Topic/Sub-Topic"
          variant="outlined"
          defaultValue={weeklyData.top_sub_top || ""}
          onChange={
            (e) => {
              const newValue = e.target.value;
              setWeeklyData(prevWeeklyData => {
                const updatedWeeklyData = [...prevWeeklyData];
                updatedWeeklyData[index].top_sub_top = newValue;
                return updatedWeeklyData;
              })
            }
          }
        />
        <TextField
          fullWidth
          id="periods"
          label="No. of Periods"
          variant="outlined"
          defaultValue={weeklyData.no_of_period || ""}
          onChange={
            (e) => {
              const newValue = e.target.value;
              setWeeklyData(prevWeeklyData => {
                const updatedWeeklyData = [...prevWeeklyData];
                updatedWeeklyData[index].no_of_period = newValue;
                return updatedWeeklyData;
              })
            }
          }
        />
        <TextField
          fullWidth
          id="notes"
          label="Important Notes"
          variant="outlined"
          defaultValue={weeklyData.imp_note || ""}
          onChange={
            (e) => {
              const newValue = e.target.value;
              setWeeklyData(prevWeeklyData => {
                const updatedWeeklyData = [...prevWeeklyData];
                updatedWeeklyData[index].imp_note = newValue;
                return updatedWeeklyData;
              })
            }
          }
        />
        <TextField
          fullWidth
          id="activities"
          label="Class Activities"
          variant="outlined"
          defaultValue={weeklyData.cls_activity || ""}
          onChange={
            (e) => {
              const newValue = e.target.value;
                setWeeklyData(prevWeeklyData => {
                const updatedWeeklyData = [...prevWeeklyData];
                updatedWeeklyData[index].cls_activity = newValue;
                return updatedWeeklyData;
              })
            }
          }
        />
        <TextField
          fullWidth
          id="homework"
          label="Home Work"
          variant="outlined"
          defaultValue={weeklyData.homework || ""}
          onChange={
            (e) => {
              const newValue = e.target.value;
                setWeeklyData(prevWeeklyData => {
                const updatedWeeklyData = [...prevWeeklyData];
                updatedWeeklyData[index].homework = newValue;
                return updatedWeeklyData;
              })
            }
          }
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Accomplished</FormLabel>
          <RadioGroup
            aria-label="accomplished"
            name="accomplished"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {value === "no" ? (
          <TextField
            fullWidth
            id="reason"
            label="If No Reason With Completion"
            variant="outlined"
            defaultvalue={weeklyData.if_no_reas_with_comp_date}
            onChange={
              (e) => {
                const newValue = e.target.value;
                  setWeeklyData(prevWeeklyData => {
                  const updatedWeeklyData = [...prevWeeklyData];
                  updatedWeeklyData[index].if_no_reas_with_comp_date = newValue;
                  return updatedWeeklyData;
                })
              }
            }
          />
        ) : (
          ""
        )}

      </AccordionDetails>
    </Accordion>
  );
};

export default ViewdaysPlan;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    height: "40px",
    cursor: "pointer",
  },
}));