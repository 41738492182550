import React from 'react';
import Tickets from '../ReusedComponents/Tickets'
import { useParams } from 'react-router-dom'

export default function SADashboard() {
    const { sid, uid } = useParams();

    return (
        <Tickets
            userType={"superadmin"}
            title={"Admin Dashboard"}
            options={[{ title: "Reports", url: `/tickets/superadmin/reports/${sid}/${uid}` }]}
        />
    );
}
