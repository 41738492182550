import React, { useState, useEffect, useRef } from "react";
import Header from "../ReusedComponents/Header";
import { useHistory, useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useStyles } from "../MaterialStyle";
import { DecryptED } from "../../DecryptJS/Decrypt";
//import "react-datepicker/dist/react-datepicker.css";

export default function TicketReport({ props, setID, parentId }) {
  const history = useHistory();
  const { sid, uid, ssid } = useParams();
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("status");
  const [filterSelect, setFilterSelect] = useState("Open");
  const [sorting, setsorting] = useState(false);
  const [dataa, setDataa] = useState([]);
  const [name, setName] = useState("");
  const [imgShown, setImgShown] = useState("");
  const [imgSrc, setimgSrc] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [availScools, setAvailScools] = useState([]); 
  const [uniqSchool, setUniqSchool] = useState([]);
  const classes = useStyles();

  const tableRef = useRef(null);

  const generatePdf = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: `Tickets Reports ${moment().format("DD/MM/YYYY")}`,
    pageStyle: "@page { size: A4; margin: 0; }",
  });
  useEffect(() => {
    document.getElementsByTagName("title")[0].innerHTML = "Ticketing System";
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        fun_name: "AUserInfo",
        sid: ssid || sid,
        u_id: uid,
      })
      .then((res) => {
        setName(
          res.data !== undefined
            ? res.data.student !== undefined && res.data.student.length > 0
              ? res.data.student[0].name || "Unknown"
              : "Unknown"
            : "Unknown"
        );
      });
    if (props === "admin") {
      axios
        .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
          fun_name: "GetTicketCreate",
          sid: sid,
          parent_id: parentId,
        })
        .then((res) => {
          setDataa(res.data.Tickets ? res.data.Tickets.reverse() : []);
          const school = res.data.Tickets.map(school => school.school).filter((school, index, self) => self.indexOf(school) === index)
          setUniqSchool(school);
        });
    } else {
      axios({
        url: "https://marcopolo.prisms.in/api/tickets/user/report",
        method: "POST",
        data: {
          sid: DecryptED(sid),
          uid: DecryptED(uid),
        },
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      }).then((res) => {
        setDataa(res.data || []);
          setUniqSchool(res.data.map(school => school.school).filter((school, index, self) => self.indexOf(school) === index));
      });
    }
    // else {
    //   axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
    //     fun_name: "GetTicketCreate",
    //     sid: sid,
    //     uid:uid,
    //   }).then((res) => {
    //     setDataa(res.data.Tickets || []);
    //   });
    // }
  }, []);

  useEffect(() => {
    setData(
      dataa.filter((d) => {
        if (filter === "status")
          return d[filter].toString().indexOf(filterSelect) !== -1;
          
        if (typeof d[filter] === "string")
          return d[filter].toLowerCase().indexOf(search.toLowerCase()) !== -1;
        else return d[filter].toString().indexOf(search) !== -1;
      })
    );
    // console.log(data);
  }, [search, dataa, filterSelect]);

  function Sorting(p) {
    setImgShown(p);
    setData("");
    if (sorting === false) {
      setimgSrc("/icons/downA.png");
      setData(
        data.sort((a, b) => {
          if (p === "id") {
            if (parseInt(a[p]) > parseInt(b[p])) return -1;
            if (parseInt(a[p]) < parseInt(b[p])) return 1;
            return 0;
          }
          if (a[p] > b[p]) return -1;
          if (a[p] < b[p]) return 1;
          return 0;
        })
      );
      setsorting(true);
    } else {
      setimgSrc("/icons/upA.png");
      setData(
        data.sort((a, b) => {
          if (p === "id") {
            if (parseInt(a[p]) < parseInt(b[p])) return -1;
            if (parseInt(a[p]) > parseInt(b[p])) return 1;
            return 0;
          }
          if (a[p] < b[p]) return -1;
          if (a[p] > b[p]) return 1;
          return 0;
        })
      );
      setsorting(false);
    }
  }
  const onSubmit = () => {
    if (startDate && endDate) {
      const newData = dataa.filter((item) => {
        return item.date >= startDate && item.date < endDate;
      });

      setData(newData);
    }
  };
  useEffect(() => {
    onSubmit();
  }, [startDate, endDate]);

  useEffect(() => {
    setStartDate(
      new Date(new Date().setDate(new Date().getDate() - 30))
        .toISOString()
        .substr(0, 10)
    );
    setEndDate(new Date().toISOString().substr(0, 10));
  }, []);
  
  const schoolsList = () =>{
    for (let index = 0; index < data.length; index++) {
      for (let index2 = 0; index2 < index.length; index2++) {
        if(data.school[index2] !== data.school[index]){
          setAvailScools([...availScools, data.school[index2]]);
        }
        
      }
    }
  }
  return (
    <>
      <div
        className="totalTicketingModule"
        style={{ overflow: "hidden", height: "100vh" }}
      >
        {props === "admin" ? null : (
          <Header
            title={"Ticket Reports"}
            options={[
              {
                title: "Create ticket",
                url: `/tickets/user/creating/${sid}/${uid}`,
              },
              {
                title: "Assigned Tickets",
                url: `/tickets/user/assigned_tickets/${sid}/${uid}`,
              },
            ]}
            ChangePage={(params) =>
              history.push(
                params.data !== undefined ? params.url : params.url,
                params.data
              )
            }
          />
        )}
        {name ? (
          <div className="user__name">
            <span>Welcome {name}</span>
          </div>
        ) : null}

        <div
          className="TMTicketReportTableMainDiv"
          style={{ display: props === "admin" ? "block" : "flex" }}
        >
          <div className="TMDashboardOptions">
            <div className="TMAdminFilterDiv">
              {data === undefined && filter !== "status" && search === "" ? (
                ""
              ) : (
                <select
                  onChange={(e) => {
                    setSearch("");
                    setFilter(e.target.value);
                  }}
                  defaultValue={filter}
                >
                  <option value="status">Filter by Status</option>
                  <option value="committee">
                    Filter by Committee/Department
                  </option>
                  <option value="staffName">Filters by Assigned To</option>
                  <option value="title">Filters by Title</option>
                  <option value="duedate">Filters by Due Date</option>
                  <option value="date">Filters by Date</option>
                  <option value="id">Filters by Ticket Number</option>
                  <option value="remark">Filters by Remark</option>
                  <option value="school">Filters by School</option>
                  <option value="name">Filters Created by</option>
                </select>
              )}
            </div>

            <div className="TMAFilterbox">
              {filter !== "status" ? (
                filter === "date" ? (
                  <div className="custom__date_range">
                    <form className={classes.form}>
                      <label className={classes.label} htmlFor="fromDate">
                        Start Date
                      </label>
                      <input
                        className={classes.input}
                        type="date"
                        style={{ color: "white" }}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                        name="fromDate"
                        value={startDate}
                      />

                      <label className={classes.label} htmlFor="toDate">
                        End Date
                      </label>
                      <input
                        className={classes.input}
                        style={{ color: "white" }}
                        type="date"
                        name="toDate"
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                        value={endDate}
                      />
                    </form>
                  </div>
                ) : (
                  <>
                  <input
                    type={filter === "duedate" ? "date" : "text"}
                    className={"TMAdminSearch"}
                    defaultValue={search}
                    onChange={(e) => {
                      if (filter === "") {
                        alert("Please select any filter");
                      } else setSearch(e.target.value);
                    }}
                    onClick={() => {
                      if (filter === "") {
                        alert("Please select any filter");
                      }
                    }}
                    placeholder={"Quick Search"}
                  />
                  {
                    filter === "school"? 
                    <select
                    className="TMAdminSearch"
                    onChange={(e)=>setSearch(e.target.value)}
                    >
                      <option value="">Select School</option>
                      {uniqSchool.map((school, index)=>{
                       return <option value={school} key={index}>{school}</option>
                      })}
                      
                    </select>:""
                  }
                  
                  </>
                )
              ) : (
                <select
                  className={"TMAdminSearch"}
                  onChange={(e) => {
                    setFilterSelect(e.target.value);
                  }}
                >
                  <option value="Open" label="Open " />
                  <option value="Closed" label="Closed" />
                  <option value="" label="All" />
                </select>
              )}
              {/* {data.length ? <div className='count__status__user' ><span>{filterSelect ? filterSelect : null}  {data.length}</span></div> : null} */}
              {(filter === "status" || filter === "date") &&
              (filterSelect === "Open" ||
                filterSelect === "Closed" ||
                filterSelect === "") ? (
                <div className="count__status__user">
                  <span>
                    {filterSelect ? filterSelect : "All"} {data.length}
                  </span>
                </div>
              ) : null}
            </div>
            <button
              className="btn btn-primary mt-0"
              onClick={() => {
                generatePdf();
              }}
            >
              Print
            </button>
          </div>
          <div className="TMMobileOptionsBackGround" />
          {data === "" || dataa === "" || name === "" ? (
            <div className="TMLoader">
              <Loader type="Puff" color="#1e5597" height={80} width={80} />
            </div>
          ) : data.length < 1 ? (
            <div
              style={{
                padding: "10px",
                color: "#1e5597",
                fontWeight: 600,
                width: "90%",
                height: "50vh",
                marginTop: "20px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                textAlign: "center",
              }}
            >
              No Reports
            </div>
          ) : (
            <div
              className="TMTicketReportTableDiv"
              style={{
                overflow: "scroll",
                width: "100%",
                height: "100vh",
                display: props === "admin" ? "block" : "flex",
              }}
            >
              <div style={{ marginBottom: "300px" }}>
                <table
                  className="TMTicketReportTable"
                  style={props === "admin" ? { width: "100%" } : {}}
                  ref={tableRef}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th
                        onClick={() => {
                          Sorting("id");
                        }}
                      >
                        <div>
                          <span>Ticket No</span>{" "}
                          {imgShown === "id" ? <img src={imgSrc} /> : null}
                        </div>
                      </th>
                      <th
                        onClick={() => {
                          Sorting("committee");
                        }}
                      >
                        <div>
                          <span>Assigned To</span>{" "}
                          {imgShown === "committee" ? (
                            <img src={imgSrc} />
                          ) : null}
                        </div>
                      </th>
                      {props === "admin" ? null : (
                        <>
                          <th
                            onClick={() => {
                              Sorting("description");
                            }}
                          >
                            <div>
                              <span>Description</span>{" "}
                              {imgShown === "description" ? (
                                <img src={imgSrc} />
                              ) : null}
                            </div>
                          </th>
                          <th
                            onClick={() => {
                              Sorting("date");
                            }}
                          >
                            <div>
                              <span>Created On</span>{" "}
                              {imgShown === "date" ? (
                                <img src={imgSrc} />
                              ) : null}
                            </div>
                          </th>
                          <th
                            onClick={() => {
                              Sorting("date");
                            }}
                          >
                            <div>
                              <span>Updated On</span>{" "}
                              {imgShown === "date" ? (
                                <img src={imgSrc} />
                              ) : null}
                            </div>
                          </th>
                          <th
                            onClick={() => {
                              Sorting("duedate");
                            }}
                          >
                            <div>
                              <span>Due Date</span>{" "}
                              {imgShown === "duedate" ? (
                                <img src={imgSrc} />
                              ) : null}
                            </div>
                          </th>
                          <th
                            onClick={() => {
                              Sorting("priority");
                            }}
                          >
                            <div>
                              <span>Priority</span>{" "}
                              {imgShown === "priority" ? (
                                <img src={imgSrc} />
                              ) : null}
                            </div>
                          </th>
                        </>
                      )}
                      <th
                        onClick={() => {
                          Sorting("status");
                        }}
                      >
                        <div>
                          <span>Status</span>{" "}
                          {imgShown === "status" ? <img src={imgSrc} /> : null}
                        </div>
                      </th>
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  {sorting ? (
                    <tbody>
                      {data.map((d, k) => {
                        return (
                          <tr
                            key={k}
                            onClick={() => {
                              props === "admin"
                                ? setID(d.id)
                                : history.push(
                                    `/tickets/user/details/${sid}/${uid}/${
                                      d.id
                                    }`,
                                    {
                                      id: d.id,
                                      username:
                                        name.charAt(0).toUpperCase() +
                                        name.slice(1).toLowerCase(),
                                      userType: "User",
                                      title: "Tracking Reports",
                                    }
                                  );
                            }}
                          >
                            <td>{k + 1}</td>
                            <td>{d.id}</td>
                            <td>{d.committee}</td>
                            {props === "admin" ? null : (
                              <>
                                <td style={{ whiteSpace: "unset" }}>
                                  <strong>{d.title}</strong>
                                  <br />
                                  {d.description.length > 50 ? (
                                    <div title={d.description}>
                                      {d.description.substring(0, 47) + "..."}
                                    </div>
                                  ) : (
                                    d.description
                                  )}
                                </td>
                                <td>
                                  {moment(
                                    d.updated_on,
                                    "YYYY-MM-DD HH:mm:ss A"
                                  ).format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  {moment(
                                    d.date,
                                    "YYYY-MM-DD HH:mm:ss A"
                                  ).format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  {moment(
                                    d.duedate,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("DD-MM-YYYY")}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      d.priority === "High"
                                        ? "#ff7575"
                                        : d.priority === "Medium"
                                        ? "#fff44f"
                                        : "lightgreen",
                                  }}
                                >
                                  <span>{d.priority}</span>
                                </td>
                              </>
                            )}
                            <td
                              style={{
                                color:
                                  d.status === "Closed" ? "green" : "black",
                                fontWeight: "600",
                              }}
                            >
                              {d.status}
                            </td>
                            {/* <td>
                                  <span
                                    className="TMTrackButton"
                                    onClick={() => {
                                      props === "admin" ?
                                        setID(d.id)
                                        :
                                        history.push(`/tickets/user/details/${sid}/${uid}/${d.id}`,
                                          {
                                            id: d.id, username: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
                                            userType: "User",
                                            title: "Tracking Reports"
                                          })
                                    }}>
                                    Track
                                  </span>
                                </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      {data.map((d, k) => {
                        return (
                          <tr
                            key={k}
                            onClick={() => {
                              props === "admin"
                                ? setID(d.id)
                                : history.push(
                                    `/tickets/user/details/${sid}/${uid}/${
                                      d.id
                                    }`,
                                    {
                                      id: d.id,
                                      username:
                                        name.charAt(0).toUpperCase() +
                                        name.slice(1).toLowerCase(),
                                      userType: "User",
                                      title: "Tracking Reports",
                                    }
                                  );
                            }}
                          >
                            <td>{k + 1}</td>
                            <td>{d.id}</td>
                            <td>
                              {d.committee} - <strong>{d.staffName}</strong>
                            </td>
                            {props === "admin" ? null : (
                              <>
                                <td style={{ whiteSpace: "unset" }}>
                                  <strong>{d.title}</strong>
                                  <br />
                                  {d.description.length > 50 ? (
                                    <div title={d.description}>
                                      {d.description.substring(0, 47) + "..."}
                                    </div>
                                  ) : (
                                    d.description
                                  )}
                                </td>
                                <td>
                                  {moment(
                                    d.date,
                                    "YYYY-MM-DD HH:mm:ss A"
                                  ).format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  {moment(
                                    d.updated_on,
                                    "YYYY-MM-DD HH:mm:ss A"
                                  ).format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  {moment(
                                    d.duedate,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("DD-MM-YYYY")}
                                </td>
                                <td
                                  style={{
                                    backgroundColor:
                                      d.priority === "High"
                                        ? "#ff7575"
                                        : d.priority === "Medium"
                                        ? "#fff44f"
                                        : "lightgreen",
                                  }}
                                >
                                  {d.priority}
                                </td>
                              </>
                            )}
                            <td
                              style={{
                                color:
                                  d.status === "Closed" ? "green" : "black",
                                fontWeight: "600",
                              }}
                            >
                              {d.status}
                            </td>
                            {/* <td>
                                  <span
                                    className="TMTrackButton"
                                    onClick={() => {
                                      props === "admin" ?
                                        setID(d.id)
                                        :
                                        history.push(`/tickets/user/details/${sid}/${uid}/${d.id}`,
                                          {
                                            id: d.id, username: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
                                            userType: "User",
                                            title: "Tracking Reports"
                                          })
                                    }}>
                                    Track
                                  </span>
                                </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
