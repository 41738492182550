import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { useParams } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DecryptED } from "../DecryptJS/Decrypt";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function Search(props) {
  const classes = useStyles();
  const [Sclass, setClassid] = React.useState(null);
  const [divid, setDivid] = React.useState(null);
  const [staff, setStaff] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [data1, setData1] = React.useState(null);
  const [divlist, setDivlist] = React.useState(null);
  const [classnm, setClassnm] = React.useState(null);
  const [Stafftype, setStafftype] = React.useState(null);
  const [staffD, setStaffD] = React.useState(null);
  const [issearch, setSearch] = React.useState(false);
  const [Selected, setSelected] = React.useState(null);
  const [isStaffS, setStaffS] = React.useState(false);
  const [classdivmap, setClassDivMap] = React.useState(null);
  const { sid } = useParams();

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  //Generate and Download image
  const Gen = () => {
    if (
      Selected.design_id != null &&
      Selected.userid != null &&
      props.usertype === "Student"
    )
      window.open(
        "https://mpages.prisms.in/idcardcanvas/generate/Student/" +
          Selected.design_id +
          "/" +
          sid +
          "/" +
          Selected.userid,
        "_blank"
      );
    if (
      Selected.design_id != null &&
      Selected.userid != null &&
      props.usertype === "Parent"
    )
      window.open(
        // "https://mpages.prisms.in/idcardcanvas/generate/Parent/" +
        "https://mpages.prisms.in/idcardcanvas/generate/Parent/" +
          Selected.design_id +
          "/" +
          sid +
          "/" +
          Selected.userid,
        "_blank"
      );
    if (staff != null && Selected.design_id != null && Selected.staffid != null)
      window.open(
        "https://mpages.prisms.in/idcardcanvas/generate/staff/" +
          props.did +
          "/" +
          sid +
          "/" +
          Selected.staffid,
        "_blank"
      );

    // saveAs(Selected.idcard_image, 'image.jpg')
  };
  useEffect(() => {
    if (props.usertype === "Student") {
      if (Sclass != null && divid != null && props.did != null) {
        axios
          .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
            fun_name: "IDcard_getProof",
            sid: sid,
            classid: Sclass,
            divid: divid,
            did: props.did,
          })
          .then((res) => {
            setData(res.data);
            setSearch(true);
          });
      }
    } else if (props.usertype === "Parent") {
      if (Sclass != null && divid != null && props.did != null) {
        const token = "#$laksdfnlknoea#$@$%^&%$";
        const data = {
          sid: DecryptED(sid),
          classid: Sclass,
          divid: divid,
          did: props.did,
        };
        axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/idcard/proof/parent",
          data,
          headers: {
            Authorization: `${token}`,
          },
        }).then((res) => {
          setData1(res.data);
          setSearch(true);
        });
      }
    }
  }, [Sclass, divid]);

  useEffect(() => {
    if (divlist != null) {
      var a = [];
      divlist.map((i) => {
        if (i.class !== "") {
          const idata = i;

          if (a.length > 0 ? a[0][i.class] === undefined : true) {
            a.length === 0
              ? a.push({ [i.class]: [idata] })
              : (a[0][i.class] = [idata]);
          } else {
            a[0][i.class].push(idata);
          }
          return null;
        }
      });
      if (a.length > 0) {
        setClassDivMap(a[0]);
        // if(Arrayd!=null){
        // setClassMap(Arrayd.class.split(','));
        // setClassdMap(Arrayd.className.split(','))
        // }
        console.log(a[0]);
        console.log("key", Object.keys(a[0]));
      }
    }
  }, [divlist]);

  useEffect(() => {
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        fun_name: "GetClassDiv",
        sid: sid,
      })
      .then((res) => {
        axios
          .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
            fun_name: "GetStaffID",
            sid: sid,
          })
          .then((res1) => {
            var a = [];
            res1.data.Staffid.map((i) => {
              if (i.name !== "") {
                const idata = i;

                if (a.length > 0 ? a[0][i.staff_type] === undefined : true) {
                  a.length === 0
                    ? a.push({ [i.staff_type]: [idata] })
                    : (a[0][i.staff_type] = [idata]);
                } else {
                  a[0][i.staff_type].push(idata);
                }
                return null;
              }
            });
            if (a.length > 0) {
              setStafftype(Object.keys(a[0]));
              setStaffD(a[0]);
            }

            setDivlist(res.data.div);
            setClassnm(res.data.classnm);
          });
      });
  }, []);
  useEffect(() => {
    if (staff != null) setStaffS(true);
  }, [staff]);
  return (
    <div>
      <Dialog open={props.open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {" "}
          {props.usertype === "Student"
            ? "Student Information"
            : props.usertype === "Parent"
            ? "Parent Information"
            : "Staff Information"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.usertype === "Student"
              ? "Search Student"
              : props.usertype === "Parent"
              ? "Parent Search"
              : "Staff search"}
          </DialogContentText>
          <div className={classes.root}>
            {props.usertype === "Student" || props.usertype === "Parent" ? (
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                style={{ marginBottom: 10 }}
              >
                <InputLabel id="div">Select Class </InputLabel>
                <Select
                  variant="outlined"
                  style={{ borderRadius: 54 }}
                  labelId="class"
                  id="class"
                  value={Sclass}
                  onChange={(e) => setClassid(e.target.value)}
                  label="Select Class"
                >
                  {props.s === "Student" || "Parent"
                    ? divlist != null
                      ? [
                          ...new Map(
                            divlist.map((item) => [item["class"], item])
                          ).values(),
                        ].map((v, i) => (
                          // JSON.parse(sessionStorage.getItem('design-current')).classname.includes(v.class_name)?
                          <MenuItem value={v.class}>{v.class_name}</MenuItem>
                        ))
                      : null
                    : null}
                </Select>
              </FormControl>
            ) : null}

            {props.usertype === "staff" ? (
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                style={{ marginBottom: 10 }}
              >
                <InputLabel id="saff">Select Staff Type </InputLabel>
                <Select
                  variant="outlined"
                  style={{ borderRadius: 54 }}
                  labelId="staff"
                  id="staff"
                  value={staff}
                  onChange={(e) => setStaff(e.target.value)}
                  label="Select Staff Type"
                >
                  {Stafftype != null
                    ? Stafftype.map((v, i) => ((
                        // JSON.parse(sessionStorage.getItem('design-current')).classname.includes(v.class_name)?
                        <MenuItem key={i} value={v}>
                          {v}
                        </MenuItem> /*:null*/ /*:null*/ /*:null*/
                        //  :null
                      ) /*:null*/))
                    : null}
                </Select>
              </FormControl>
            ) : null}

            {props.usertype === "Student" || props.usertype === "Parent" ? (
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                style={{ marginBottom: 10 }}
              >
                <InputLabel id="div">Select Div </InputLabel>
                <Select
                  fullWidth
                  style={{ borderRadius: 54 }}
                  labelId="div"
                  id="div"
                  value={divid}
                  onChange={(e) => setDivid(e.target.value)}
                  label="Select Div"
                >
                  {/* {divlist != undefined 
                    ? divlist.map((v, i) => (
                        <MenuItem value={v.divid}>{v.div_name}</MenuItem>
                      ))
                    : null} */}
                  {classdivmap !== undefined && Sclass != null
                    ? classdivmap[Sclass].map((v, i) => (
                        <MenuItem value={v.divid}>{v.div_name}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            ) : null}

            {/* GENERATE STUDENT AND PARENT SPECIFIC ID CARD */}
            {issearch && (data || data1) != null ? (
              <div style={{ borderRadius: 54 }}>
                <Autocomplete
                  disableClearable
                  forcePopupIcon={false}
                  id="combo-box-demo"
                  options={data ? data.Id : data1}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 300, borderRadius: 54 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ borderRadius: 54 }}
                      {...params}
                      label="Search"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setSelected(newValue);
                  }}
                />
              </div>
            ) : null}

            {/* GENERATE STAFF SPECIFIC ID CARD */}
            {isStaffS && staffD != null ? (
              <div style={{ borderRadius: 54 }}>
                <Autocomplete
                  disableClearable
                  forcePopupIcon={false}
                  id="combo-box-demo"
                  options={staffD[staff].filter(
                    (item) => item.design_id === props.did
                  )}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 330, borderRadius: 50 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ borderRadius: 54 }}
                      {...params}
                      label="Search"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setSelected(newValue);
                  }}
                />
              </div>
            ) : null}
          </div>

          {(issearch && data != null && Selected != null) ||
          (isStaffS && staffD != null) ||
          (issearch && data1 != null && Selected != null) ? (
            <div style={{ marginTop: 10 }}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => Gen()}
              >
                Generate and Download
              </Button>
            </div>
          ) : null}
        </DialogContent>
        <Button onClick={props.close} color="primary">
          Cancel
        </Button>
      </Dialog>
    </div>
  );
}
