import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Button,AppBar} from '@material-ui/core';
import { useEffect } from 'react';
const useStyles = makeStyles((theme) => ({
  imgdiv:{
    backgroundColor:'#4b82e6',height:'50vh',
    [theme.breakpoints.up('sm')]: {
        backgroundColor:'#4b82e6',height:'60vh'
    }

  },
  imgIpad:{
    display:'none',
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',marginTop:'5vh',marginLeft:'8vw',width:'38vw',height:'64vh' ,display:'flex',
    }

  },
  typo1:{
    fontWeight:'bold',color:'white',marginLeft:'8vw',fontSize:20,
    [theme.breakpoints.up('md')]: {
        fontWeight:'bold',color:'white',marginLeft:'0vw',fontSize:42
    }

  },
  typo2:{
    fontWeight:'bold',color:'white',marginLeft:'8vw',fontSize:10,width:'39vw',
    [theme.breakpoints.up('md')]: {
        fontWeight:'bold',color:'white',marginLeft:'0vw',fontSize:14,width:'28vw'
    }

  },
  live:{
    width:'97vw',height:'38vh',marginTop:'5vh',
    
    [theme.breakpoints.up('sm')]: {
        width:'44vw',height:'50vh',
    }
  },
  main:{
    width:'92vw',
        marginTop:'2vh',
        textAlign:'center',
        float:'none',
        marginLeft:'1vw',
    [theme.breakpoints.up('sm')]: {
        
        float:'right',width:'50vw',marginTop:'2vh',
    }
    
  },
  btn:{    
    marginTop:'10vh',marginBottom:'1vh',textAlign:'center',
    [theme.breakpoints.up('sm')]: {
     
        marginTop:'6vh',marginBottom:'1vh',textAlign:'center',
    }
  },
}))
export default function Home(props) {
     const classes = useStyles();
     useEffect(()=>{
        document.getElementsByTagName("title")[0].innerHTML = "Career Explorer"
     },[])
return(
    <div style={{width:'100vw',height:'100vh',overflowX:'hidden'}}>
        <div style={{textAlign:'center' ,backgroundColor:'white' }}>
            <img src={'/icons/topicon.png'} style={{textAlign:'center'  }} />
        </div>
        <div style={{textAlign:'center',backgroundColor:'white'  }}>
            <img src={'/icons/topicon2.png'} style={{textAlign:'center'  }} />
        </div>
        <div className={classes.imgdiv}>
            <div   className={classes.main}>
            <iframe src='https://www.youtube.com/embed/TWVXuaRhvt8?rel=0'
         allowFullScreen="allowfullscreen"
         mozallowFullScreen="mozallowfullscreen" 
         msallowFullScreen="msallowfullscreen" 
         oallowFullScreen="oallowfullscreen" 
         webkitallowFullScreen="webkitallowfullscreen"
          className={classes.live}
        // style={{width:600, height:350}}
        frameborder='1'
        allow='autoplay; encrypted-media'
        allowFullScreen
        title='video'
        />
                {/* <Typography className={classes.typo1}>Psychometric</Typography>
                <Typography className={classes.typo1}>Tests</Typography>
                <Typography className={classes.typo2}>Access our Comprehensive Psychometric Test Portfolio which includes carefully curated Aptitude, Interest & Personality tests for students.</Typography> */}
                </div>
            <div style={{width:'10vw'}}>
        <img src={'/icons/ipadimg.png'} className={classes.imgIpad} />
        </div>
        <div className={classes.btn}>
            <Button variant="contained" style={{marginBottom:'6vh',backgroundColor:'#4b82e6',color:'white'}} onClick={()=>window.open("https://career.prismscommunications.com/app/login","_self")}> 
                Login / SignUp
            </Button>
        </div>
        </div>
    </div>
);
}