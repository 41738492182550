import styled from "styled-components";
import React, { useState } from "react";
import TC from "./TicketCR";
import TA from "./TicketAS";

const Tab = ({ sid }) => {
  const [activetab, setActiveTab] = useState(0);
  return (
    <>
      <Container>
        <Wrapper>
          <div className="tabs">
            <Navlist>
              <Tablink
                key={0}
                activetab={activetab === 0}
                onClick={() => setActiveTab(0)}
              >
                Ticket Created
              </Tablink>
              <Tablink
                key={1}
                activetab={activetab === 1}
                onClick={() => setActiveTab(1)}
              >
                Ticket Assigned
              </Tablink>
            </Navlist>
          </div>
        </Wrapper>
        <InnerContainer>
          {activetab ? <TA sid={sid} /> : <TC sid={sid} />}
        </InnerContainer>
      </Container>
    </>
  );
};

export default Tab;

const InnerContainer = styled.div`
  width: 100%;
  height: 0;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 5px;
`;

const Navlist = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const Tablink = styled.button`
  cursor: pointer;
  font-size: 1.2rem;
  font-family: monospace;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  background-color: #fff;
  font-weight: 500;
  color: #111;
  border: 2px solid #43c7c5;
  width: 194px;
  height: 40px;
  &:focus {
    outline: none;
    outline: none;
  }

  ${({ activetab }) =>
    activetab &&
    `
    color: #fff;
    border:none;
    background-color: #43c7c5;
  `}
`;

const Wrapper = styled.div`
  position: relative;

  .tabs {
    display: flex;
    align-items: center;
  }
`;
