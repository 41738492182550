import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner'
import { xorBy } from 'lodash';

export default function QMMultiselect(props) {
    const [selectPopup, setPopup] = useState(false);
    const [search, setSearch] = useState('');
    const [names, setnames] = useState('');

    useEffect(() => {
        try {
            setnames((props.data).filter(i => (props.label ? i[props.label] : i).toLowerCase().indexOf((search).toLowerCase()) !== -1))
        } catch (e) {
            setnames(names)
            console.error(e);
        }
    }, [search, selectPopup]);

    return (
        <div style={{ width: "100%", marginBottom: "-1px" }}>
            <div className="APsidemenu1" id="staffpopup" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: selectPopup ? "100%" : "0%", padding: 0, backgroundColor: "rgba(0,0,0,0.4)" }} >
                <div style={{ width: "100%", height: "100vh", position: "absolute" }} onClick={() => { setPopup(false) }}></div>
                <div className="APquiz-card" style={{ height: "60vh" }}>
                    <div className="APform-div" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", fontSize: "25px", color: "#1e5597" }}>
                        <input type="text" placeholder="Search here..." style={{ width: "70%", marginTop: "-7px" }} onChange={(e) => { setSearch(e.target.value) }} />
                        <div onClick={() => { setPopup(false) }}>
                            &#10006;
                        </div>
                    </div>
                    <div style={{ overflow: "scroll", maxHeight: "50vh" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", color: "#1e5597" }}>
                            <div style={{ width: "100%" }}>
                                {
                                    names === undefined || names === '' ?
                                        <div className="APLoader">
                                            <Loader
                                                type="Oval"
                                                color="#0066ff"
                                                height={60}
                                                width={60}
                                            />
                                        </div> :
                                        names.map((i, k) => {
                                            return (
                                                <div key={k} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} onClick={() => { props.value ? props.selected(xorBy(props.initialValues, [i[props.value]],)) : props.selected(xorBy(props.initialValues, [i])) }}>
                                                    <input type="checkbox" readOnly checked={props.initialValues.includes(props.value ? i[props.value] : i)} style={{ width: "18px", height: "18px" }} />
                                                    <span style={{ padding: "5px 15px", fontWeight: "500", marginTop: "7px" }}>{props.label ? i[props.label] : i}</span>
                                                </div>
                                            )
                                        })
                                }
                            </div><br></br>
                        </div>
                    </div>
                </div>
            </div>
            <div onClick={() => { setPopup(true) }} style={{ width: "100%", marginBottom: "-1px" }}>
                <select name={props.name} disabled style={{ width: "100%" }} >
                    <option>{props.initialValues === '' || props.initialValues.length < 1 ? props.placeholder : props.initialValues.join(',')}</option>
                </select>
            </div>
        </div>
    );
}
