import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Appointment from '../ReusedComponents/Appointments';
import { useParams } from 'react-router-dom';

export default function AppointmentR() {
    const [Appointmentslist, setAppointmentslist] = useState([]);
    const [Loader, setLoader] = useState(true);
    const { sid, uid } = useParams();
    console.log(Appointmentslist)
    useEffect(() => {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Appointment_Superadmin", sid: sid }).then(async (res) => {
            if (res.data === undefined || res.data === null || (res.data !== undefined ? (res.data.slist).length === 0 : false) || typeof (res.data) !== "object") {
                axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "GetSuperAStatus", sid: sid }).then((res) => {
                    setAppointmentslist((res.data.AStatus !== undefined) ? res.data.AStatus : []);
                })
            } else {
                const response = await axios.all((res.data.slist).map(s => axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "GetSuperAStatus", sid: s.sid })))
                const data = response.map(s => {
                    if (s.data) {
                        if (s.data.AStatus) {
                            if (typeof (s.data.AStatus) === "object") {
                                return s.data.AStatus
                            }
                        }
                    }
                    return []
                })
                setAppointmentslist(data.flat())
                setLoader(false)
            }
        })
    }, []);

    return (
        <Appointment data={Appointmentslist} type="superadmin" loader={Loader} sid={sid} uid={uid} />
    );
}
