import React, { useState } from 'react'
import Style from './Admin.module.css'
import Axios from 'axios'

const AdminLoad = () => {

  const [Apipostdata, setApipostdata] = useState({
    walletid: "",
    txnid: "",
    amount: "",
    datetime: "",
    purpose: ""
  })

  const apiHnadler = () => {
    Axios.post(" https://api.paykit.in/add-balance", Apipostdata
      , {
        headers: { Authorization: "" }
      }).then(res => console.log(res))
      .catch(err => console.log(err))
  }

  const quickSearch = () => {
    Axios.post(" https://api.paykit.in/get-wallet-status", {}
      , {
        headers: { Authorization: "" }
      }).then(res => console.log(res))
      .catch(err => console.log(err))
  }

  return (
    <div className={Style.main}>
      <div className={Style.search}>
        <p>User ID</p>
        <input type="text" placeholder="Quick search" />
        <button> Get Details </button>
      </div>
      <div className={Style.info__div}>
        <div className={Style.flex}>
          <div>
            <img src="../../icons/wallet/Person.png" alt="" />
          </div>
          <div>
            <p>Ajay patil</p>
            <p>VII A</p>
            <p>9404160459</p>
          </div>
        </div>
        <div>
          <p>Current Balance : 195</p>
        </div>

      </div>
      <div className={Style.load}>
        <img src="../../icons/wallet/folder.png" alt="" />
        <p>Load</p>
        <form action="" className={Style.form}>
          <input type="text" placeholder='Enter Amount' required />
          <button type='submit'>Confirm</button>
        </form>
      </div>
    </div>
  )
}

export default AdminLoad