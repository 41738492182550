import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Button, Select, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import ReactCrop from "react-image-crop";
import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import "react-image-crop/dist/ReactCrop.css";
import Swal from "sweetalert2";
import { DecryptED } from "../../DecryptJS/Decrypt";

const drawerWidth = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100vw",
  },
  drawer2: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    [theme.breakpoints.up("sm")]: {
      marginTop: 64,
    },
    width: drawerWidth,
    height: "90vh",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  submit: {
    position: "fixed",
    bottom: 30,
    left: "45vw",
    marginLeft: "10%",
  },
  applist: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    marginBottom: 0,
    marginLeft: "5%",
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "70vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "60vw",
    },
  },
  drawerOpen: {
    width: drawerWidth,

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",

    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  input: {
    borderRadius: 54,
  },
  divclass: {
    position: "fixed",
    top: "35vh",
    left: "50%",
    transform: "translateX(-50%)",
    minHeight: 177,
    maxHeight: 177,
    minWidth: 200,
    maxWidth: 200,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: "35vh",
      height: 200,
      width: 200,
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
    },
  },
  arrowsmall: {
    position: "fixed",
    top: "45vh",
    left: "30%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: "36vh",
      left: "22%",
      transform: "translateX(-50%)",
    },
  },
  arrowsmall2: {
    position: "fixed",
    top: "45vh",
    left: "70%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: "36vh",
      left: "80%",
      transform: "translateX(-50%)",
    },
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  select: {
    position: "fixed",
    left: "45%",
    top: "20%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: "21%",
      left: "39%",

      transform: "translateX(-50%)",
      display: "flex",
    },
  },
  form1: {
    marginBottom: 30,
    marginRight: 12,
    marginTop: 10,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
      marginRight: 12,
      marginTop: 10,
      width: 150,
    },
  },
  form2: {
    marginBottom: 30,
    marginRight: 12,
    marginTop: 10,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
      marginRight: 12,
      marginTop: 10,
      width: 150,
    },
  },
  btcip: {
    position: "fixed",
    left: "50%",
    top: "65vh",
    transform: "translateX(-50%)",
    display: "flex",
    width: 260,
    borderRadius: 54,
    marginRight: 3,
    marginLeft: 10,
    height: "fit-content",
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      left: "50%",
      top: "57vh",
      transform: "translateX(-50%)",
      display: "flex",
      width: 260,
      borderRadius: 54,
      marginRight: 3,
      marginLeft: 10,
      height: "fit-content",
      marginTop: 5,
    },
  },
  btnip: {
    position: "fixed",
    left: "50%",
    top: "75vh",
    transform: "translateX(-50%)",
    display: "flex",
    borderRadius: 54,
    marginRight: 3,
    marginLeft: 10,
    height: "fit-content",
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      left: "50%",
      top: "71vh",
      transform: "translateX(-50%)",
      display: "flex",
      width: 260,
      borderRadius: 54,
      marginRight: 3,
      marginLeft: 10,
      height: "fit-content",
      marginTop: 5,
    },
  },
  btncrop: {
    position: "fixed",
    left: "48%",
    top: "85vh",
    transform: "translateX(-50%)",
    borderRadius: 54,
    width: 152,
    marginRight: 3,
    marginLeft: 10,
    height: "fit-content",
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      left: "48%",
      top: "85vh",
      width: "152px",
      transform: "translateX(-50%)",
      borderRadius: 54,
      marginRight: 3,
      marginLeft: 10,
      height: "fit-content",
      marginTop: 5,
    },
  },
  t1: {
    borderRadius: 90,
    marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },
  t2: {
    borderRadius: 90,
    marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },
  t3: {
    background: "#6610f2",
    border: "none",
    color: "#fff",
    borderRadius: 90,
    marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },
}));
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
function ContainerMain({sid}) {

  console.log('Stafff' + sid)
  console.log('Decrypt sid' + DecryptED(sid));
  let newSid = DecryptED(sid);
  
  const classes = useStyles();
  const [open, setOpen] = React.useState(null);
  const [fileimage, setImgFile] = React.useState(null);
  const [stafflist, setStaffList] = React.useState(null);
  const [K, setK] = React.useState(null);
  const [schoolName, setSchoolName] = useState("");
  const [acadYear, setAcadYear] = useState("");

  const [width] = React.useState(200);
  const imgRef = React.useRef(null);

  const [height] = React.useState(200);
  const [Orgimg, setOrgimg] = React.useState(null);
  const [crop, setCrop] = React.useState({
    unit: "px",
    width: width,
    height: height,
  });
  const [completedCrop, setCompletedCrop] = React.useState(null);
  const [render, setRender] = useState(false);
  const [staff, setStaff] = React.useState(null);


  let url1 = "https://topschool-attachments.s3.amazonaws.com/";
  let url2 = "https://s3.amazonaws.com/topschool-attachments/";


  useEffect(() => {
    document.getElementsByTagName("title")[0].innerHTML = "Photo Crop";
  }, []);
 
  useEffect(() => {
    const getStaffID = async () => {
      const data = {
        sid : newSid
      }
      const response = axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/staff/details",
        data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      });
      const result = await response;

       let newResult = result.data.sort((a,b)=> {
        var a1 = a.name.toLowerCase();
        var b1 = b.name.toLowerCase();
        return a1<b1 ?-1:a1> b1? 1 :0;
        })
      
      setStaffList(newResult);


    };

    

    getStaffID();
  }, []);

  useEffect(() => {
    if (staff != null) setK(0);
    preview(0);
  }, [staff]);
  const preview = (K) => {
    
    if (staff != null) setOrgimg(staff);
  };

  useEffect(()=>{
    const getSchoolName = async () => {
      const data = {
        fun_name: "getSchoolURL",
        sid,
      };
      await axios({
        method: "POST",
        url: "https://topschool.prisms.in/rest/index.php/user_list.json",
        data,
      }).then((response) => {
        setSchoolName(response.data);
      });
    };
    getSchoolName();
    const getAcadId = async () => {
      const data = {
        sid : newSid
      };
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/tscommon/getCurrentAcad",
        data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      }).then(async (response) => {
        setAcadYear(response.data[0].academics);
      });
    };
    getAcadId();
  },[])
 
  
  const toDataURL = (url, callback) => {
    console.log(url);
    // var s = url.replace("https://files.prisms.in", "");
    let s = url;

    if (s) {
      s.replace(url2, "https://files.prisms.in/");
    }

    // if(url.replace(url1,"https://files.prisms.in"))else{
    //   url.replace(url2,"https://files.prisms.in")
    // }
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();

      reader.onloadend = function() {
        // console.log(reader.result)

        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", s);
    // xhr.setRequestHeader('Content-type', 'application/octet-stream')
    // xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.responseType = "blob";
    xhr.send();
  };
  const getResizedCanvas = async (e) => {
    e.preventDefault();
    var c = window.confirm("Do yo want to crop and update the image");
    if (c) {
      const canvasg = document.createElement("canvas");
      if (!completedCrop || !canvasg) {
         console.log(canvasg)
        return;
      }
      const canvas = canvasg;
      const image = imgRef.current;

      const crop = completedCrop;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext("2d");
      const pixelRatio = window.devicePixelRatio;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      // ctx.rotate(rotate * Math.PI/180);
      ctx.imageSmoothingQuality = "high";
      var image1 = new Image();
      image1.onload = async function() {
        ctx.drawImage(
          image1,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        let bucketName = `topschool-attachments/${schoolName}prisms/${acadYear}/staffphoto`;

        
        let li = Orgimg.avatar.lastIndexOf(".");
        let link = Orgimg.avatar.substring(0, li);
        let re = Orgimg.avatar.substring(li);
        let newlink = link + ".crop" + re;

        console.log(newlink)
        // if (
        //   newlink.includes("https://s3.amazonaws.com/topschool-attachments")
        // ) {
        //   var res = newlink.replace(
        //     "https://s3.amazonaws.com/topschool-attachments",
        //     ""
        //   );
        // } else if (newlink.includes("https://files.prisms.in")) {
        //   var res = newlink.replace("https://files.prisms.in", "");
        // }
        // var lname = newlink.substring(newlink.lastIndexOf("/") + 1);
        // var newlink2 = res.substring(1, res.lastIndexOf("/"));
        let FileO = dataURLtoFile(canvas.toDataURL(), "Avater.png");
        console.log(FileO);
        const fmdata = new FormData();
        fmdata.append("sid", newSid);
        fmdata.append("user_id", Orgimg.user_id);
        fmdata.append("tablename","k6kb9_pri_staff_details");
        fmdata.append("bucketName", bucketName);
        fmdata.append("profile","photo");
        fmdata.append("image",FileO);
   
        setOpen(FileO);
  
        await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/staff/image/upload",
          data: fmdata,
          headers: {
            Authorization: "#$laksdfnlknoea#$@$%^&%$",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        })          
       .then((res) => {
            console.log(res.data.updated);
            setOpen(res.data.updated);
            Swal.fire({
              icon: "success",
              title: "Successfully croped and updated",
              showConfirmButton: false,
              timer: 1500,
            });
          });

          setRender(!render)
      };
      image1.src = open;
    } else {
      console.log("cancel");
    }
  };

  useEffect(() => {
    console.log("calling here when after selection of staff ID")
    if (Orgimg != null) {
      if (Orgimg.avatar != "") {
        let url1 = "https://s3.amazonaws.com/topschool-attachments/";

        let newImgUrl = Orgimg.avatar.replace(url1, "https://files.prisms.in/");

        toDataURL(newImgUrl, async function(dataUrl) {
          var fil = dataUrl.replace("application/octet-stream", "image/png");
          setOpen(fil);
        });
      }
    }
  }, [Orgimg,render]);

  const onLoad = React.useCallback((img) => {
    imgRef.current = img;
    setImgFile(imgRef.current.src);
  }, []);
  useEffect(() => {
    const canvasg = document.createElement("canvas");
    if (!completedCrop || !canvasg) {
      return;
    }
    const canvas = canvasg;
    const image = imgRef.current;

    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    // ctx.rotate(rotate * Math.PI / 180);
    ctx.imageSmoothingQuality = "high";

    var image1 = new Image();
    image1.onload = function() {
      ctx.drawImage(
        image1,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    };

    image1.src = open;
  }, [completedCrop]);


  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div style={{ display: "flex", marginTop: 20 }}>
          {Orgimg != null ? (
            <div>
              <Typography
                style={{
                  position: "fixed",
                  top: "32vh",
                  height: "40vh",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {Orgimg !== null ? Orgimg.name : ""}
              </Typography>
              <div className={classes.divclass}>
                {open != null &&  Orgimg.avatar.replace("https://s3.amazonaws.com/topschool-attachments/", "https://files.prisms.in/") ? (
                  <ReactCrop
                    src={
                      open != null && Orgimg.avatar != ""
                        ? open
                        : "https://st2.depositphotos.com/1064024/10769/i/600/depositphotos_107694484-stock-photo-little-boy.jpg"
                    }
                    onImageLoaded={onLoad}
                    // style={{transform: `rotate(${rotate}deg)`}}
                    // style={{maxWidth:'100%',maxHeight:'100%',minWidth:'100%',minHeight:'100%'}}
                    onComplete={(c) => setCompletedCrop(c)}
                    crop={crop}
                    onChange={(newCrop) => setCrop(newCrop)}
                  />
                ) : (
                  <Typography>No Image Available </Typography>
                )}
              </div>
            </div>
          ) : (
            <div style={{ width: "100vw" }}>
              <Typography style={{ textAlign: "center", marginTop: "45vh" }}>
                No Image available{" "}
              </Typography>
            </div>
          )}
          <div className={classes.select}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              className={classes.form1}
            >
              <InputLabel id="div">Select Staff Type </InputLabel>
              <Select
                variant="outlined"
                style={{ width: 360, height:50 }}
                labelId="class"
                id="class"
                value={staff}
                onChange={(e) => setStaff(e.target.value)}
                label="Select Staff Type"
              >
                {stafflist != null
                  ? stafflist.map((v, i) =>
                      (
                        <MenuItem key={i} value={v}>
                          {v.name}
                        </MenuItem>
                      )
                    )
                  : null}
              </Select>
            </FormControl>
          </div>

          {Orgimg != null ? (
            <div className={classes.btnip}>
              <TextField
                className={classes.t1}
                size="small"
                variant="outlined"
                type="number"
                value={crop.width}
                onChange={(e) => setCrop({ ...crop, width: e.target.value })}
                label="width in px"
              />
              <TextField
                className={classes.t2}
                size="small"
                variant="outlined"
                type="number"
                value={crop.height}
                onChange={(e) => setCrop({ ...crop, height: e.target.value })}
                label="height in px"
              />
            </div>
          ) : null}
          {Orgimg != null ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.btncrop}
              onClick={(e) => getResizedCanvas(e)}
            >
              Crop
            </Button>
          ) : null}
          {/* {Orgimg != null ? (
            <div>
              <IconButton
                className={classes.arrowsmall}
                onClick={() => handlePrev()}
                fullWidth
                variant="contained"
                color="primary"
              >
                <ArrowBackIosIcon
                  color="primary"
                  style={{ width: 80, height: 80 }}
                />
              </IconButton>

              <IconButton
                className={classes.arrowsmall2}
                onClick={() => handleNext()}
                fullWidth
                variant="contained"
                color="primary"
              >
                <ArrowForwardIosIcon
                  color="primary"
                  style={{ width: 80, height: 80 }}
                />
              </IconButton>
            </div>
          ) : null} */}
        </div>
        <div style={{ marginTop: 30 }} />
      </main>
    </div>
  );
}

export default ContainerMain;
