import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import FormData from "form-data";
import Checkbox from "@material-ui/core/Checkbox";
import Loader from "../common/Loader.js";
import { DecryptED } from "../DecryptJS/Decrypt";

const StudentReport = () => {
  const { sid, userid } = useParams();
  const [firstDate, setFirstDate] = useState("");
  const [appliedDate, setAppliedDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [acadid, setAcad] = useState("");
  const [leaveReason, setLeaveReason] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [acadYear, setAcadyear] = useState("");
  const [file, setFile] = useState([]);
  const [halfDay, setHday] = useState(0);
  const [apiLoader, setApiLoader] = useState(false);

  const onChange = (e) => {
    setFile(e.target.files[0]);
  };

  //reset date
  const onReset = (e) => {
    setHday(e.target.value);
    // setEndDate(...endDate, "");
  };

  const onSubmit = async (e) => {

    try {
      if (!firstDate || !endDate || !leaveReason) {
        return Swal.fire({
          icon: "warning",
          title: "Select the fileds",
          showConfirmButton: false,
          timer: 1000,
        });

      }
      setApiLoader(true);

      let bucketName = `topschool-attachments/${schoolName}prisms/${acadYear}/studentLeave`;
      let formData = new FormData();
      formData.append("sid", DecryptED(sid));
      formData.append("user_id", DecryptED(userid));
      formData.append("leave_applied_date", appliedDate);
      formData.append("leave_start_date", firstDate);
      formData.append("leave_end_date", endDate);
      formData.append("half", halfDay);
      formData.append("leave_reason", leaveReason);
      formData.append("acad_id", acadid);
      formData.append("bucketName", bucketName);
      formData.append("file", file);

      const response = await axios({
        method: "post",
        url: "https://marcopolo.prisms.in/api/studentLeave/addLeave",
        data: formData,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Successfully submited",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
        setApiLoader(false);
      }
    } catch (error) {
      setApiLoader(false);

    }
  };

  useEffect(() => {
    let currentDate = new Date().toISOString().split("T")[0];
    setFirstDate(currentDate);
    setEndDate(currentDate);
    setAppliedDate(currentDate);

    const getSchoolName = async () => {
      const data = {
        fun_name: "getSchoolURL",
        sid,
      };
      await axios({
        method: "POST",
        url: "https://topschool.prisms.in/rest/index.php/user_list.json",
        data,
      }).then((response) => {
        setSchoolName(response.data);
      });
    };
    getSchoolName();

    const getAcadmic = async () => {
      const data = {
        sid: DecryptED(sid),
      };
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/tscommon/getCurrentAcad",
        data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      }).then(async (response) => {
        setAcad(response.data[0].acadid);
        setAcadyear(response.data[0].academics);
      });
    };
    getAcadmic();
  }, []);

  // const half_day = firstDate === endDate ? 1 : 0;
  return (
    <>
      <Container>
        {apiLoader ? (
          <Loader />
        ) : (
          <Wrapper>
            <h3 style={{ fontFamily: "Poppins" }}>Student Leave </h3>
            <div className="student-details">
              <div className="main_div">
                <div className="main-title">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: 500,
                    }}
                  >
                    From
                  </span>
                </div>
                <div className="tit">
                  <input
                    type="date"
                    value={firstDate}
                    onChange={(e) => {
                      setFirstDate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="main_div">
                <div className="main-title">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: 500,
                    }}
                  >
                    To
                  </span>
                </div>
                <div className="tit">
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </div>
              </div>

              {firstDate === endDate ? (
                <div className="half__day">
                  <span className="half_day_title"  style={{
                          fontFamily:'Poppins',fontWeight:500
                        }}>Half Day</span>
                  <Checkbox
                    color="primary"
                    required
                    value={1}
                    onChange={(e) => onReset(e)}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
              ) : null}
              <div className="description">
                <span className="reason"  style={{
                          fontFamily:'Poppins',fontWeight:500
                        }}>Reason For Leave</span>
                <textarea
                  id="1"
                  cols="48.5"
                  rows="10"
                  required
                  onChange={(e) => {
                    setLeaveReason(e.target.value);
                  }}
                />
              </div>
              <input style={{border:'none'}}
                type="file"
                className="select__file"
                onChange={(e) => onChange(e)}
              />

              <div className="btn-submit">
                <button className="btn" variant="contained" onClick={onSubmit}>
                  Submit
                </button>
              </div>

              <div className="btn-submit">
                <Navigation
                  className="btn"
                  variant="contained"
                  to={`/studentleave/report/${sid}/${userid}`}
               
                >
                  report
                </Navigation>
              </div>
            </div>
          </Wrapper>
        )}
      </Container>
    </>
  );
};

export default StudentReport;

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 25px auto;
  padding: 20px;
  height: 80vh;

  @media (max-width: 768px) {
    width: 95%;
    padding: 0;
    box-shadow: none;
    margin: 0 auto;
    height: 100vh;
  }

  .name {
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: space-between;
  }

  .date {
    font-size: 1rem;
    color: rgb(111, 214, 255);
  }

  .upload {
    margin: 20px 0;
    display: flex;
    align-items: center;
  }

  input {
    height: 40px;
    border: none;
    font-size: 1rem;
    margin: 10px 0;
    width: 100%;
    border:1px solid #ccc;
    border-radius: 4px;
    color: #111;
    padding: 0 10px;
    background-color: #fff;;
  }

  h3 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    color: #111;
    padding-top: 10px;
  }

  .line {
    color: rgb(111, 214, 255);
  }

  .btn-submit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    font-family: Poppins;
    
  }
  .btn {
    border: none;
    width: 150px;
    margin-top: 0;
    height: 40px;
    background-color: rgb(82 113 238);
    color: #fff;
    text-transform: capitalize;
    border-radius: 4px;
    font-size: 1rem;
    font-family: Poppins;
    font-weight: 400;
  }

  .main-title {
    width: 100px;
    margin: 5px 0;
  }
  .tit {
    width: 100%;
  }

  .description {
    margin-top: 15px;
    font-family: Poppins;
  }

  .half__day {
    display: flex;
    flex-direction: row;
    align-items: center;

    .half_day_title {
      margin-right: 5px;
    }
  }

  .main_div {
    margin: 6px 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .select__file {
    background-color: #fff;
    color: #111;
    margin-left: -8px;
  }

  span {
    font-size: 1.1rem;
    font-weight: 600;
    font-family: Poppins;
    padding: 0;
  }
  textarea {
    width: 100%;
    height: 100px;
    padding: 12px 20px;
    color: #111;
    background-color: #fbfbfb;
    box-sizing: border-box;
    border-radius: 4px;
    border:1px solid #ccc;
    font-family: Poppins;
    font-size: 15px;
    resize: none;
  }
  .student-details {
    margin: 15px 3px;
  }
`;

const Navigation = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
