
import React,{useEffect,useState} from 'react';
import {Avatar} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {InputLabel,OutlinedInput,InputAdornment  } from '@material-ui/core';
// import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Quizform(props) {
  const classes = useStyles();
  const [amt,setAmt]=useState(null);
  const [cname,setCname]=useState(null);
  const [mob, setMob] = React.useState(null);
  const [lbn, setLbn] = React.useState(null);
  useEffect(()=>{
  
      
  },[])
 
 
  return (
   <Container component="main" maxWidth="xs">
      <CssBaseline />
    
      <div className={classes.paper}>
       
  <PersonAddIcon color='secondary' fontSize='large'/>
        <Typography component="h1" variant="h5">
          Add New Client
        </Typography>
        
        <div className={classes.form} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id="cname"
                value={cname}
                onChange={(e)=>setCname(e.target.value)}
                // defaultValue={result!=null? result.data.quiz[0].name:''}
                label="Client's Name"
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                
                fullWidth
                id="cmob"
                label="Client's Mobile Number"
                name="cmob"
                value={mob}
                onChange={(e)=>setMob(e.target.value)}
                autoComplete="cmob"
              />
            </Grid>
            <Grid item xs={12}>

            <FormControl fullWidth className={classes.margin} variant="outlined">
          <InputLabel htmlFor="amt">Amount</InputLabel>
          <OutlinedInput
            id="amt"
            value={amt}
            onChange={(e)=>setAmt(e.target.value)}
            startAdornment={<InputAdornment position="start">&#8377;</InputAdornment>}
            labelWidth={60}
          />
        </FormControl>

              {/* <TextField
                variant="outlined"
                required
                fullWidth
                name="Amount Balence"
                label="Amount Balence"
                id="amt"
                autoComplete="amt"
              /> */}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                
                fullWidth
                //  name="Last Billing Date"
                   label="Last Billing Date"

                type='date'
                // defaultValue={Date.now()}
                id="bill"
                InputLabelProps={{
                    shrink: true,
                  }}
                  value={lbn}
                onChange={(e)=>setLbn(e.target.value)}
                autoComplete="bill"
              />
            </Grid>
           
          </Grid>
          <Button
             onClick={()=>props.submit(cname,amt,mob,lbn)}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </div>
      </div>
    
    </Container>
  );
}


/*


<div style={{minHeight:'100vh',minWidth:'100vw',overflow:'hidden',backgroundColor:'#b2bec3'}}>
    
    <CheckCircleIcon  style={{ minHeight:'30vh',minWidth:'50vw',color:'#27ae60',position: 'absolute',
    top: '35%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'}} />
    <Typography style={{marginLeft:'24vw',fontSize:'x-large',position: 'absolute',
    top: '53%',
    left: '25%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'}} >{result.data.quiz[0].name} </Typography>
    <Typography style={{marginLeft:'24vw',fontSize:'x-large',position: 'absolute',
    top: '58%',
    left: '25%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'}} >Already Registered </Typography><br/>
    <Typography style={{marginLeft:'24vw',fontSize:'x-large',position: 'absolute',
    top: '63%',
    left: '25%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'}} >Thank You </Typography>
    </div>
*/