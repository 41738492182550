import React from "react";
import Navbar from "../components/Navbar";
import Home from "../components/Home";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { DecryptED } from "../../DecryptJS/Decrypt";
const Dashboard = () => {
  const { sid } = useParams();
  const [report, setReport] = useState([]);
  const [loader, setLoader] = useState(false);

  const getReport = async () => {
    try {
      const token = "#$laksdfnlknoea#$@$%^&%$";
      setLoader(true);
      const data = {
        sid: DecryptED(sid),
      };
      const response = await axios({
        method: "post",
        url: "https://marcopolo.prisms.in/api/holiday/details",
        data,
        headers: {
          Authorization: `${token}`,
        },
      });
      setReport(response.data);
      setLoader(false);
    } catch (error) {}
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <Main>
      <Navbar />
      <Home report={report} loader={loader} />
    </Main>
  );
};

export default Dashboard;

const Main = styled.div`
  margin: 0;
  padding: 0;
  margin-top: -5px;
  width: 100%;
  height: 100vh;
`;
