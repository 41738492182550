import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import HomeIcon from '@material-ui/icons/Home';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import CardMedia from '@material-ui/core/CardMedia';
import Imageprvw from './Imageprvw';
import {useParams} from 'react-router-dom';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import {saveAs} from 'file-saver';
 var sortJsonArray = require('sort-json-array');
 const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 100,
    maxWidth: '96vw',
    backgroundColor:'#a2cf6e',
    marginBottom:20,
  
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize:'contain',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '21.25%',
    }
  },
  root1: {
    minWidth: 100,
    backgroundColor:'#2196f3',
    marginBottom:20
  },

  title: {
    fontSize: 15,
    color:'#e8f5e9'
  },
  pos: {
    marginBottom: 5,
  },
}));

export default function Notifications(props) {
  const classes = useStyles();
  const [result, setRes]= useState({});
  const [ispreview,setPreview]=useState(false)
  const [imgsrc,setImgsrc]=useState(null)
  const {sid} = useParams();

  useEffect(()  =>{
    async function fetchD(){
     const res= await axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"get_alumini_notifications",['sid']:sid})
     .then(res=> {
          // console.log("then"+ JSON.stringify(res));
         setRes(res)}).catch(err =>console.log("Error-"+err));
     
  

 }
   fetchD();
   
},[])
const Close=()=>{
  setImgsrc(null)
  setPreview(false);
}

const Prv=(s)=>{
  setImgsrc(s)
  
}
const SaveFile=(file)=>{
   var fname=file.substr(file.lastIndexOf('/')+1)
   fname=fname.replace(/%25/g, ' ')
   saveAs(file,fname);
 }
useEffect(()=>{
  if(imgsrc!=null)
  setPreview(true)
},[imgsrc])
  return (
    result.data !=null? 
      <>
      {/* {console.log("APID-->"+sortJsonArray(result.data.alumini_notifications,'id','des'))} */}
        {/* <CssBaseline />
          <AppBar>
            <Toolbar>
              <Typography variant="h6">Alumni</Typography>
          
            </Toolbar>
          </AppBar>
        <Toolbar /> */}
    
          {result.data.alumini_notifications.map((v,i)=>(
          
    <Card className={v.notif_type==="Announcement"? classes.root : classes.root1}>
      {v.file_path!=undefined && v.file_path!=null && v.file_path!="" && v.file_path.includes('.pdf')==false && v.file_path.includes('.docx')==false? <CardMedia onClick={()=>Prv(v.file_path)}
          className={classes.media}
          image={v.file_path}
          title="Attachment"
        />:null}
       
      <CardContent>
      
          <Typography>
            {v.notification_txt}
          </Typography>
          {v.file_path!=undefined && v.file_path!=null && v.file_path!="" && (v.file_path.includes('.pdf') || v.file_path.includes('.docx'))? 
          <div style={{display:'flex',alignItems:'center'}}>
             <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{marginTop:11}}
        startIcon={<PlayForWorkIcon />}
        onClick={()=>SaveFile(v.file_path)}
      >
        Download File
      </Button>
          
          </div>
        :null}
      </CardContent>
      <CardActions>
         <Typography className={classes.title} color="textSecondary" gutterBottom>
          {v.notif_type} <span style={{position: 'absolute', right: 10}}>{v.created_date}</span>
        </Typography>
      </CardActions>
     
      {ispreview && imgsrc!=null && v.file_path!=""?<Imageprvw img={imgsrc} exit={Close} isopen={true}/>:null}
      
    </Card>  
      ))}
    </> : <><CircularProgress/></>
  );
}