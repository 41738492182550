import React from 'react';
import { useHistory } from 'react-router-dom'

export default function Navbar(props) {

    const history = useHistory();

    return (
        <>
            <div id="menu" className="sidemenu">
                {props.home.isHome ?
                    <img onClick={() => { history.push(props.home.home) }}
                        src="https://img.icons8.com/material-sharp/100/042CAD/home.png" style={{ opacity: 0.8 }} />
                    : null}
                {props.refresh ?
                    <img onClick={() => { window.location.reload(true) }}
                        src="https://img.icons8.com/ios-glyphs/100/042CAD/refresh--v2.png" style={{ opacity: 0.8 }} />
                    : null}
                {props.back ?
                    <img onClick={() => { history.goBack() }}
                        src="https://img.icons8.com/metro/100/042CAD/circled-left.png" style={{ opacity: 0.8 }} />
                    : null}
            </div>
        </>
    );
}
