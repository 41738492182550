import React from 'react';

export default function ReportTrack(props) {
    const data = props.data;

    return (
        <div className="totalTicketingModule">
            {
                data === undefined || data.length < 1 || data === "" ?
                    <div style={{ padding: "10px", color: "#1e5597", fontWeight: 600, width: "90%", height: "50vh", alignItems: "center", justifyContent: "center", display: "flex", textAlign: "center" }}>
                        No Reports
                    </div>
                    :
                    <div style={{ overflow: "scroll", width: "100%", height: "100vh" }}>
                        <div style={{ marginBottom: "200px" }}>
                            <table className="TMTicketReportTable">
                                <thead>
                                    <tr>
                                        <th style={{ padding: '10px' }}>Date</th>
                                        <th style={{ padding: '10px' }}>Committee</th>
                                        <th style={{ padding: '10px' }}>Staff</th>
                                        <th style={{ width: "30%", padding: '10px' }}>Remark/Reply</th>
                                        <th style={{ padding: '10px' }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((d, k) => {
                                        if (d.remark_reply !== "") {
                                            return (
                                                <tr key={k}>
                                                    <td style={{ padding: '10px', whiteSpace: "unset" }}>{d.date1}</td>
                                                    <td style={{ padding: '10px', whiteSpace: "unset" }}>{d.committee}</td>
                                                    <td style={{ padding: '10px', whiteSpace: "unset" }}>{d.staff}</td>
                                                    <td style={{ width: "30%", padding: "10px", whiteSpace: "unset" }}>{d.remark_reply}</td>
                                                    <td style={{ padding: '10px', whiteSpace: "unset" }}>{d.status}</td>
                                                </tr>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
        </div>

    );
}
