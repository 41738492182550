import React from 'react';
import { useHistory } from 'react-router-dom';
const multiDownload = require('multi-download');

export default function Details(props) {
    const history = useHistory();
    history.location.state = props.data;
    const { sid, uid } = props;

    function downloadAll(urls) {
        multiDownload(urls);
    }

    return (
        <div className="APMCard">
            <div className="APMCardSub">
                <div id="apppointment-details">
                    <div className="APHeader">
                        <img
                            onClick={() => {
                                history.push(`/appointment/${props.type === "user" ? "user/requests" : props.type}/${sid}/${uid}`)
                            }}
                            src="/icons/back.png" alt="back"
                        />
                        <div>Appointment Details</div>
                    </div>
                    <hr className="APhr-line"></hr>
                    <div style={{ height: "90vh", overflow: "scroll" }}>
                        <div style={{ marginBottom: "100px" }}>
                            <div className="APDetailsname">Request By - {history.location.state.name}</div>
                            <table style={{ width: "95%", paddingLeft: "10px" }}>
                                <tbody>

                                    <tr className="APform-div">
                                        <td className="APiconandheading">
                                            <img src={'/icons/person.png'} style={{ width: "40px" }} alt="person img" />
                                        </td>
                                        <td className="APtableContent">
                                            <div className="APUserAppointmentDetails">{history.location.state.person}</div>
                                        </td>
                                    </tr>
                                    {
                                        props.type === "superadmin" ?
                                            <tr className="APform-div">
                                                <td className="APiconandheading">
                                                    <img src={'/icons/school.png'} style={{ width: "40px", marginBottom: "-10px" }} alt="person img" />
                                                </td>
                                                <td className="APtableContent">
                                                    <div className="APUserAppointmentDetails">{history.location.state.s_name}</div>
                                                </td>
                                            </tr> : null
                                    }
                                    <tr className="APform-div">
                                        <td className="APiconandheading">
                                            <img src={'/icons/calendar.png'} style={{ width: "25px", marginTop: "15px", marginRight: "5px", marginLeft: "5px" }} alt="calendar icon" />
                                        </td>
                                        <td className="APtableContent">
                                            <div className="APUserAppointmentDetails">{(history.location.state.requestDate).split("-").reverse().join("-")}</div>
                                        </td>
                                    </tr>
                                    <tr className="APform-div">
                                        <td className="APiconandheading">
                                            <img src={'/icons/clock.png'} style={{ width: "35px", marginTop: "10px", marginRight: "5px" }} alt="clock" />
                                        </td>
                                        <td className="APtableContent">
                                            <div className="APUserAppointmentDetails">{history.location.state.duration} {(history.location.state.duration).length > 2 ? "" : "mins"}</div>
                                        </td>
                                    </tr>
                                    <tr className="APform-div">
                                        <td className="APiconandheading">
                                            <div style={{ color: "#1e5597", marginTop: "10px" }}><b>Subject</b></div>
                                        </td>
                                        <td className="APtableContent">
                                            <div className="APUserAppointmentDetails">{history.location.state.subject}</div>
                                        </td>
                                    </tr>
                                    {props.type === "user" && history.location.state.description !== "" ?
                                        <tr className="APform-div">
                                            <td className="APiconandheading">
                                                <img src="https://img.icons8.com/material/24/1e5597/speaker-notes.png" style={{ width: "35px", marginTop: "15px" }} alt="description" />
                                            </td>
                                            <td className="APtableContent">
                                                <div className="APUserAppointmentDetails">{history.location.state.description}</div>
                                            </td>
                                        </tr> : null
                                    }
                                    <tr className="APform-div">
                                        <td className="APiconandheading">
                                            <div style={{ color: "#1e5597", marginTop: "10px" }}><b>Priority</b></div>
                                        </td>
                                        <td className="APtableContent">
                                            <div className="APUserAppointmentDetails">{history.location.state.priority}</div>
                                        </td>
                                    </tr>
                                    <tr className="APform-div" style={{ display: history.location.state.requestStatus === "Pending" || history.location.state.requestStatus === "Not available" ? "none" : "flex" }}>
                                        <td className="APiconandheading">
                                            <div style={{ color: "#1e5597", marginTop: "10px" }}><b>Slot</b></div>
                                        </td>
                                        <td className="APtableContent">
                                            <div className="APUserAppointmentDetails">{history.location.state.requestStatus}</div>
                                        </td>
                                    </tr>
                                    <tr className="APform-div" onClick={() => { history.push(`/appointment/${props.type}/addnotes/${sid}/${uid}`, history.location.state) }} style={{ cursor: "pointer" }}>
                                        <td className="APiconandheading">
                                            <img src={'/icons/notes.png'} style={{ width: "30px", marginTop: "7px", marginLeft: "5px" }} alt="notes" />
                                        </td>
                                        <td className="APtableContent">
                                            <div className="APUserAppointmentDetails">{(history.location.state.pre_meeting).length <= 0 && (history.location.state.post_meeting).length <= 0 ? "Add Notes" : "View Notes"}</div>
                                        </td>
                                    </tr>
                                    <tr className="APform-div" onClick={() => { history.push(`/appointment/${props.type}/addparticipants/${sid}/${uid}`, history.location.state) }} style={{ cursor: "pointer" }}>
                                        <td className="APiconandheading">
                                            <img src={'/icons/audience.png'} style={{ width: "35px", marginTop: "7px" }} alt="upload" />
                                        </td>
                                        <td className="APtableContent">
                                            <div className="APUserAppointmentDetails">{history.location.state.participants === "" ? "Add Participants" : JSON.parse(history.location.state.participants).length === 0 ? "Add Participants" : JSON.parse(history.location.state.participants).length === 1 ? JSON.parse(history.location.state.participants).length + " Participant Added" : JSON.parse(history.location.state.participants).length + " Participants Added"}</div>
                                        </td>
                                    </tr>
                                    {/* <tr className="APform-div" style={{ display: history.location.state.file === "" ? "none" : "flex" }}>
                                        <td className="APiconandheading">
                                            <img src={'/icons/uploadfile.png'} style={{ width: "35px", marginTop: "7px" }} alt="upload" />
                                        </td>
                                        <td className="APtableContent">
                                            <div className="APUserAppointmentDetails APLandingButtons" onClick={() => { downloadAll(history.location.state.file.split(",")) }}>Download</div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                            {history.location.state.file === "" ? null :
                                <div style={{ alignItems: "center", justifyContent: "center", display: "flex", width: "100%", marginBottom: "-20px" }}>
                                    <div className="APLandingButtons" onClick={() => { downloadAll(history.location.state.file.split(",")) }}>Download User Uploaded files</div>
                                </div>
                            }
                            <div style={{ alignItems: "center", justifyContent: "center", display: "flex", width: "100%" }}>
                                <div className="APLandingButtons" style={{ padding: "7px 20px", marginRight: "20px" }} onClick={() => { history.push(`/appointment/${props.type}/bookslot/false/${sid}/${uid}`, history.location.state) }}>
                                    {history.location.state.requestStatus === "Pending" || history.location.state.requestStatus === "Not available" ? "Book Slot" : "Change Slot"}
                                </div>
                                <div className="APLandingButtons" style={{ padding: "7px 20px" }} onClick={() => { history.push(`/appointment/${props.type}/bookslot/true/${sid}/${uid}`, history.location.state) }}>
                                    Reschedule
                                </div>
                            </div>
                            <div style={{ alignItems: "center", justifyContent: "center", display: "flex", width: "100%", marginTop: "-10px" }}>
                                <div className="APLandingButtons" style={{ padding: "7px 20px", backgroundColor: "lightgreen", color: "black", marginRight: "20px", marginBottom: "0px" }} onClick={() => { props.ApproveRequest(true) }}>
                                    Approve
                                </div>
                                <div className="APLandingButtons" style={{ padding: "7px 20px", backgroundColor: "#ff8080", color: "black", marginBottom: "0px" }} onClick={() => { props.ApproveRequest(false) }}>
                                    Decline
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
