import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import NotificationsNoneTwoToneIcon from '@material-ui/icons/NotificationsNoneTwoTone';
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import CreateIcon from '@material-ui/icons/Create';
import AssistantIcon from '@material-ui/icons/Assistant';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import InfoIcon from '@material-ui/icons/Info';
import Notifications from './Notifications';
import Events from './Events'
import PermMediaIcon from '@material-ui/icons/PermMedia';
import Opportunity from './Opportunity';
import CreateOpp from './CreateOpp';
import Achive from './Achive';
import Donation from './Donations';
import history from './history';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import FeedbackIcon from '@material-ui/icons/Feedback';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import YouTubeIcon from '@material-ui/icons/YouTube';
import {useParams} from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#a52a2a'
    }
  }
});

const useStyles = makeStyles((theme) => ({
    rootP: {
        flexGrow: 1,
         minWidth:'98vw',
         maxWidth:'98vw',
         margin:5
      },
      '@global': {
        '*:focus': {
          outline: ' none !important'
        },
      },
      container:{       
        display: 'flex',
        // marginTop:20,
        // marginLeft:5,
        // marginRight:5,
        // height:'98vh',
        // minWidth:'98vw',
        // maxWidth:'98vw',
      },
      paper: {
        height: 60,
        width: 60,
        
      },
      
      control: {
        padding: theme.spacing(2),
      },
root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    
  },
  root1: {
    marginLeft:30,
    maxWidth: '98vw',   
    marginBottom:10,
    marginTop:20,
  },
  gridList: {
    width: '98vw',
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  media: {
    height: 140,
  },
Griditemstyle:{
  flexDirection: 'column',
  alignItems: 'center' ,
  textAlign:'center',
  border: '1px solid #a52a2a ',
   [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
    alignItems: 'center' ,
    textAlign:'center',
    //'border': '1px solid',
    
   }
},
Griditemstyle1:{
  flexDirection: 'column',
  alignItems: 'center' ,
  textAlign:'center',
  borderLeft: '1px solid #a52a2a ',
  borderTop: '1px solid #a52a2a ',
   [theme.breakpoints.up('sm')]: {
    display:'none'
    //'border': '1px solid',
    
   }
}
}));

  export default function Dash(props) {
    const {sid} = useParams();

    useEffect(()  =>{
      if(props.location.data==undefined && JSON.parse(localStorage.getItem('data'))==null){
       window.alert("You are not logged in")
        history.push({pathname:"/alumni/about/"+sid})
      }
    },[])
    // window.alert(JSON.stringify(props.location.data.data))
    const classes = useStyles();
     //console.log(props.location.data.data)
    var s;
   const Event=()=>{s={hd:"Events",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const Notif=()=>{s={hd:"Latest Notifications",data:JSON.parse(localStorage.getItem('data')).data};localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s});}
   const Opp=()=>{s={hd:"Latest Opportunity",data:JSON.parse(localStorage.getItem('data')).data} ; localStorage.setItem('data',JSON.stringify(s));  history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const CrOp=()=>{s={hd:"Submit Opportunity",data:JSON.parse(localStorage.getItem('data')).data};localStorage.setItem('data',JSON.stringify(s));  history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const Achivee=()=>{s={hd:"Achievements",data:JSON.parse(localStorage.getItem('data')).data} ; localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const Donations=()=>{s={hd:"Make Donation",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const Gallary=()=>{s={hd:"Gallery",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const Help=()=>{s={hd:"Helpdesk",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const Feedbk=()=>{s={hd:"Feedback",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const Form=()=>{s={hd:"Update your Profile",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const Campaign=()=>{s={hd:"Upcoming Projects",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const Aboutmain=()=>{s={hd:"About Us",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const Contactinfo=()=>{s={hd:"Contact Us",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const PMemory=()=>{s={hd:"Share Your School Memory",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
   const About=()=>{s={hd:"AboutM",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/about/"+props.match.params.sid,data:s})};
   const youtube=()=>{s={hd:"Videos",data:JSON.parse(localStorage.getItem('data')).data} ;localStorage.setItem('data',JSON.stringify(s)); history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
 
   const Logout=()=>{
      localStorage.setItem('data',null)
      history.push({pathname:"/alumni/about/"+sid})
    }
    
    return (
      <MuiThemeProvider theme={theme}>
      <div style={{minWidth:'98%',maxWidth:'98%',margin:0}}>
        <CssBaseline />
          <AppBar>
            <Toolbar>
              <Typography variant="h6">Dashboard</Typography>
              <IconButton color="inherit" edge="start" style={{position:'fixed',right:20}} onClick={Form}>
            <AccountCircleIcon fontSize='large'/></IconButton>
            </Toolbar>
          </AppBar>
        <Toolbar />
        <div className={classes.container}>
     
        <Grid container className={classes.rootP} >
      <Grid  item xs={12} >
        <Grid container  spacing={1}>
         
            <Grid  item  xs={4} lg={2} className={classes.Griditemstyle}>
              <IconButton style={{ }} onClick={Notif}>
                  <NotificationsNoneTwoToneIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{}} onClick={Notif}> Notification</Typography>
            </Grid>
            <Grid  item xs={4} lg={2} className={classes.Griditemstyle}>
              <IconButton onClick={Event}>

                  <EventAvailableTwoToneIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{marginLeft:0}} onClick={Event}> Events</Typography>
            </Grid>
           
            <Grid  item xs={4} lg={2} className={classes.Griditemstyle}>
              <IconButton style={{ }} onClick={Opp}>

                  <WorkOutlineIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{}} onClick={Opp}> Opportunities from Alumni</Typography>
            </Grid>
            <Grid  item xs={4} lg={2} className={classes.Griditemstyle}>
              <IconButton style={{}} onClick={Achivee}>

                  <AssistantIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{}} onClick={Achivee}> Achievements</Typography>
            </Grid>
            <Grid  item  xs={4} lg={2} className={classes.Griditemstyle}>
              <IconButton style={{}} onClick={Donations}>
              <img  src={'/icons/rupee1.png'} className={classes.paper} alt="fireSpot"/>
                  {/* <AttachMoneyIcon className={classes.paper} color="primary"/> */}
              </IconButton>
              <Typography style={{}}  onClick={Donations}>Make Donation</Typography>
            </Grid>
            <Grid  item xs={4} lg={2} className={classes.Griditemstyle}>
              <IconButton style={{}} onClick={Campaign}>

                  <AccountBalanceIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{}} onClick={Campaign}>Upcoming Projects</Typography>
            </Grid>
            <Grid  item xs={4} lg={2} className={classes.Griditemstyle}>
              <IconButton style={{}} onClick={Gallary}>

                  <PermMediaIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{}}  onClick={Gallary}> Gallery</Typography>
            </Grid>
            <Grid  item xs={4} lg={2} className={classes.Griditemstyle}>
              <IconButton style={{}} onClick={youtube}>

                  <YouTubeIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{}}  onClick={youtube}> Videos</Typography>
            </Grid>
            <Grid  item xs={4} lg={2} className={classes.Griditemstyle}>
              <IconButton style={{}} onClick={PMemory}>

                  <PhotoAlbumIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{}} onClick={PMemory}> Share School Memory</Typography>
            </Grid>
            <Grid  item xs={4} lg={2} className={classes.Griditemstyle}>
              <IconButton style={{}} onClick={Help}>

                  <ContactSupportIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{}} onClick={Help}> Helpdesk</Typography>
            </Grid>
            <Grid  item xs={4} lg={2} className={classes.Griditemstyle}>
              <IconButton style={{}} onClick={Feedbk}>

                  <FeedbackIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography  style={{wordWrap:'break-word'}} onClick={Feedbk}> Suggestion/ Feedback</Typography>
            </Grid>
            <Grid  item xs={4} lg={2} className={classes.Griditemstyle} >
              <IconButton style={{}} onClick={Contactinfo}>

                  <WhatsAppIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{}} onClick={Contactinfo}>Contact Us</Typography>
            </Grid>
            <Grid  item xs={4} lg={2} className={classes.Griditemstyle} style={{borderBottom:'2px solid #a52a2a'}}>
              <IconButton style={{}} onClick={Aboutmain}>

                  <InfoIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{}} onClick={Aboutmain}> About Us</Typography>
            </Grid>
            <Grid  item  xs={4} lg={2} className={classes.Griditemstyle} style={{borderBottom:'2px solid #a52a2a'}}>
              <IconButton style={{}} onClick={Logout}>

                  <PowerSettingsNewIcon className={classes.paper} color="primary"/>
              </IconButton>
              <Typography style={{}} onClick={Logout}>Logout</Typography>
            </Grid>
            <Grid  item  xs={4} lg={2} className={classes.Griditemstyle1} >
              
            </Grid>
        </Grid>
      </Grid>
      
    </Grid>
      
        </div>
      </div>
      </MuiThemeProvider>

    );
  }