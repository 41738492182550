import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {GridList,GridListTileBar} from '@material-ui/core';
import GridListTile from '@material-ui/core/GridListTile';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import axios from 'axios';
import Imageprvw from './Imageprvw';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  tile:{
    display:'flex',
  }
}));
  


const GallaryImage=(props)=>{
  const classes = useStyles();
  const [result, setRes]= useState(null);
  const {sid} = useParams();

  const [imgprvw, setImg]= useState('');
  const getGridListCols = () => {
    if (isWidthUp('xs', props.width)) {
      return 4;
    }

    if (isWidthUp('sm', props.width)) {
      return 8;
    }

    if (isWidthUp('md', props.width)) {
      return 12;
    }

    return 1;
  }
  useEffect(()=>{
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"AlumniGetSharedPhoto",['sid']:sid})
     .then(res=> {
          console.log(res);
          
         setRes(res)}).catch(err =>console.log("Error-"+err));
   },[])
   const Close=()=>{
     setImg('');
   }
  return (
    <div >
        {console.log(imgprvw.length)}
      {imgprvw.length==0?<GridList cellHeight={120} style={{ display:'flex'}}   className={classes.gridList} cols={props.width=='xs'?2:props.width=='xs4'?2:props.width=='sm'?3:props.width=='md4'?4:props.width=='lg4'?4:4}>
        {result !=null? result.data.alumini_photos.map((tile) => (
          tile.photo.length>0 && tile.admin_approve==1?
          <GridListTile classes={{
            tile: classes.tile,
          }} style={{ display:'flex'}}  key={tile.photo}  onClick={()=>setImg(tile.photo)}>
            <img src={tile.photo} style={{flex: 1,justifyContent:'canter', display:'flex',
   objectFit:'contain'
  }}  alt={tile.name} />
   <GridListTileBar style={{ display:'flex'}} 
   title={tile.name}/>
          </GridListTile>:null
        )):null}
      </GridList>:null}
      {imgprvw.length>0?<Imageprvw img={imgprvw} exit={Close} isopen={true}/>:null}
    </div>
  );
}
export default withWidth()(GallaryImage);
