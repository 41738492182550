import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";

const ViewRecords = () => {
    const { sid, formid, userid } = useParams();
    const [apiloader, setapiLoader] = useState(false);
    const [records, setRecords] = useState(false);

    const getRecords = async () => {
        setapiLoader(true);
        try {
            //  const data = formdata
            const data = {
                sid,
                "formId": formid,
                "userid": userid
            }
            await axios({
                method: "POST",
                // url: `${process.env.REACT_APP_BASE_URL}/api/information/create/form`,
                url: `${process.env.REACT_APP_BASE_URL}/api/information/get/dynamicrecord`,
                data,
                headers: {
                    Authorization: "#$laksdfnlknoea#$@$%^&%$",
                },
            })
                .then((response) => {
                    setRecords(response.data)
                    setapiLoader(false);
                })
                .catch((error) => {
                    setapiLoader(false);
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: error,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        } catch (error) {
            setapiLoader(false);
            Swal.fire({
                position: "center",
                icon: "warning",
                title: error,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }
    useEffect(() => {
        getRecords();
    }, [])

    return (
        <div>
            {
                apiloader ? (
                    <div className="TMLoader">
                        <Loader type="ThreeDots" color="#bd2f5e" height={80} width={80} />
                    </div>
                ) :
                    <div className='container mt-5 '>
                        <h2 className='text-center mb-2'>RECORDS</h2>
                        <div className='table-responsive border'>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col"><b>sr no</b></th>
                                        {
                                            records ? records.tableHead.map((table, index) => (
                                                <th scope="col" key={index}><strong>{table}</strong></th>
                                            )) : <th scope="col"></th>
                                        }
                                    </tr>
                                </thead>

                                <tbody>

                                    {

                                        records ? records.tableData.map((table, index) => (
                                            <tr key={index}>
                                                <th scope="row"><b>{index + 1}</b></th>
                                                {
                                                    table.map((rows, i) => (
                                                        <td key={i} scope="row">{rows}</td>
                                                    ))
                                                }
                                            </tr>
                                        )) : <tr>
                                            <td colSpan={7} className='text-center'>NO DATA FOUND</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
        </div>
    )
}

export default ViewRecords