import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  let history = useHistory();
  return (
    <Header>
      <div>
        <button
          className="bt"
          onClick={() => {
            history.goBack();
          }}
        >
          Dashboard
        </button>
      </div>
    </Header>
  );
};

export default Navbar;

const Header = styled.div`
  background-color: #022a79;
  position: sticky;
  top: 0;
  z-index: 10;
  overflow: hidden;
  padding: 13px;
  color: white;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  .bt {
    width: 130px;
    color: #fff;
    height: 40px;
    background: transparent;
    border: none;
    outline: none;
    &:hover {
      background-color: rgba(71, 184, 255, 0.26);
      padding: 3px 7px;
      border-radius: 5px;
      transition: all 0.5s;
    }
  }
`;
