import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import {useParams} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {MenuItem,InputLabel,FormControl,Select} from '@material-ui/core';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));
export default function FormDialog(props) {
    const classes = useStyles();
  const [age, setAge] = React.useState('');
  const [Darray, setDarray] = React.useState(null);
  const [Sel, setSel] = React.useState(null);
  const {sid}=useParams();
  useEffect(()=>{
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'GetIDDesign',['sid']:sid}).then (res=> {
          // console.log(res.data.design)
        setDarray(res.data.design)
        });
  },[])


  const handleClose = () => {
    props.close()
  };

const FontFm = (event) => {
       
    setAge(event.target.value);
    for(var i=0;i<Darray.length;i++){
        if(Darray[i].name === event.target.value){
        setSel(Darray[i])
        // setOpen(Darray[i].orientation)
        }
    }
    console.log(event.target.value)
    // props.ld(event.target.value)
  };

const Update=()=>{

    console.log(Sel.id+Sel.name)
    if(Sel!=null){
          props.clear();
          var dt=localStorage.getItem('design');
          props.ld(dt)
               window.alert('Successfully Updated the design')
         axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'DesignUpdate',['sid']:sid,['id']:Sel.id,['name']:Sel.name,['design']:dt,['ori']:props.Ori}).then (res=> {
           props.ld(dt)
             window.alert('Successfully Updated the design')
             });
           }
}
const handleSave=()=>{
    props.savenew();
}
  return (
    <div>
     
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Design</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To Load the design Select the title and click on load button.
          </DialogContentText>
          <FormControl fullWidth className={classes.formControl}>
        <InputLabel  >
          Designs
        </InputLabel>
        <Select fullWidth
         
          value={age || localStorage.getItem('design-name')}
          onChange={FontFm}
          displayEmpty
          className={classes.selectEmpty}
        >
            {Darray!=null || Darray!==undefined? Darray.map((v,i)=>
            <MenuItem key={i} value={v.name}>{v.name}</MenuItem>
            ):null}
          
          
        </Select>
      </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>Update()} color="primary">
            Update
          </Button>
          <Button onClick={()=>handleSave()} color="primary">
            Save New 
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
