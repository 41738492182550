import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ReportResult from "./ReportResult";
import axios from "axios";
import {DecryptED} from "../../DecryptJS/Decrypt"

const useStyles = makeStyles({
  mainDiv: {
    marginTop: "0px",
  },
  headDiv: {
    // textAlign: "center",
    // margin: "15px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  home: {
    marginLeft: "24px",
    fontSize: "20px",
    color: "#0099FF",
    fontWeight: "bold",
    cursor: "pointer",
    position: "absolute",
    left: "0",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // textAlign:'center'
  },
  dateDiv: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginTop: "40px",
  },
  input: {
    width: "200px",
    marginLeft: "15px",
    backgroundColor: "#42A0EA",
    padding: "5px 8px",
    border: "none",
    borderRadius: "0",
    outline: "none",
  },
  label: {
    fontSize: "18px",
    color: "#111",
    fontWeight: "bold",
    fontFamily: "monospace",
  },
  form: {
    margin: "10px 0px",
  },
  button: {
    margin: "20px 0px",
    backgroundColor: "#42A0EA",
    padding: "6px 18px",
    color: "white",
  },
});
// #42A0EA
const Report = () => {
  const classes = useStyles();
  let { sid, userid } = useParams();
  sid = DecryptED(sid);
  userid = DecryptED(userid);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reports, setReports] = useState([]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = {
      fun_name: "getWeeklyReportBwteenDateLessonPlan",
      staff_id: userid,
      sid,
      start_date: startDate,
      end_date: endDate,
    };
    const response = await axios.post(
      "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
      data
    );
    if (response) {
      setReports(response.data.result);
    }
  };

  return (
    <div className={classes.mainDiv}>
      <div className={classes.heading}>
        <Link className={classes.home} to={`/weeklyHome/${sid}/${userid}`}>
          Home
        </Link>
        <h3
          style={{
            // color: "#42A0EA",
            color: "black",
            padding: "8px 12px",
            fontSize: "24px",
            fontFamily: "monospace",
            fontWeight: "bold",
          }}
        >
          Weekly Reports
        </h3>
      </div>
      <div className={classes.dateDiv}>
        <form className={classes.form}>
          <label className={classes.label} htmlFor="fromDate">
            Start Date
          </label>
          <input
            className={classes.input}
            type="date"
            style={{ color: "white" }}
            onChange={(e) => setStartDate(e.target.value)}
            name="fromDate"
          />
        </form>
        <form className={classes.form}>
          <label className={classes.label} htmlFor="toDate">
            End Date
          </label>
          <input
            className={classes.input}
            style={{ marginLeft: "20px", color: "white" }}
            type="date"
            name="toDate"
            onChange={(e) => setEndDate(e.target.value)}
          />
        </form>
        <div>
          <Button
            className={classes.button}
            variant="contained"
            onClick={(e) => submitHandler(e)}
          >
            Submit
          </Button>
        </div>
      </div>
      <ReportResult
        reports={reports}
        setReports={setReports}
        startDate={startDate}
        endDate={endDate}
        userid={userid}
        sid={sid}
      />
    </div>
  );
};

export default Report;
