import React, { useState, useEffect } from 'react';
import "./StartingPage.css"
import {  useHistory } from 'react-router-dom';
import axios from 'axios';


const StartingPage = () => {
  const history = useHistory()
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [walletStatus, setWalletStatus] = useState()
  const [Token, setToken] = useState("")
  const [isNewUser, setisNewUser] = useState()
  const [isDis, setisDis] = useState(false)
  const [userType, setuserType] = useState("")
  const [userName, setuserName] = useState()
  const [createUser, setcreateUser] = useState({
    username:"",
    userid:"",
    password:"p1234",
    mobile:"",
    walletid:""
  })


  const [Cred, setCred] = useState({
    userid: "",
    password: "p1234"
  })

  const changeHandler = (e) => {
    const { name, value } = e.target
    setCred({
      ...Cred,
      [name]: value
    })
  }

  useEffect(() => {
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : ""
    axios.post(" https://api.paykit.in/get-wallet-status", {}, {
      headers: { "Authorization": token }
    }).then(res => {
      if (res.status !== 200) {
        setisLoggedIn(false)
        localStorage.removeItem('token')
        // window.location.reload();
      }
      else {
        if (typeof (res) === 'object') {
          setWalletStatus(res.data)
        }
      }
    }
    ).catch(err => {
      if (err.response.status === 401) {
        setisLoggedIn(false)
        localStorage.removeItem('token')
        // window.location.reload();
      }
    })
  }, [isLoggedIn])


  const loginHandler = (e) => {
    e.preventDefault()
    axios.post("https://api.paykit.in/auth-by-passwd", Cred
    ).then(res => {
      setToken(res.data.token)
      localStorage.clear()
      if (Token) {
        localStorage.setItem("token", JSON.stringify(Token))
        if (Cred.password === "p1234") {
          history.push("/wallet/auth/changepassword")
        }
        localStorage.setItem("loginid", JSON.stringify(Cred.userid))
        axios.post("https://marcopolo.prisms.in/api/LinksPay/getUserType", { walletUsername: Cred.userid }, { headers: { Authorization: "#$laksdfnlknoea#$@$%^&%$" } })
          .then(res => {
            setuserType(res.data[0].type)
            localStorage.setItem("userType", res.data[0].type)
            setisLoggedIn(true)
            
          })
          .catch(err => console.log(err))
      }

    })
      .catch(err => {
        alert("Wrong credentials kindly re-enter your username and password")

      })
  }

  useEffect(() => {

    if (localStorage.getItem("token")) {
      setisLoggedIn(true)
    }
  }, [])


  const isWalletCreated = async() => {
    await axios.post("https://marcopolo.prisms.in/api/LinksPay/getWalletFlag", {
      walletUsername: Cred.userid
    }, { headers: { Authorization: "#$laksdfnlknoea#$@$%^&%$" } })
      .then(async res => {
        if(res.data.length===0){alert("Wrong User Id")}
        if (res.data[0].walletFlag === 0) {
          localStorage.setItem("loginid", JSON.stringify(Cred.userid))
          // 
          await axios.post("https://marcopolo.prisms.in/api/LinksPay/getUserDetails",{walletUsername:Cred.userid},{headers: { Authorization: "#$laksdfnlknoea#$@$%^&%$" }})
          .then( res=>
            {
            localStorage.setItem('userdetails',JSON.stringify({
              "username":res.data[0].name,
              "userid":(res.data[0].mobilenumber).toString(),
              "password":"p1234",
              "mobile":(res.data[0].mobilenumber).toString(),
              "walletid":res.data[0].walletId
            }))
            // setCred({
            //   userid:res.data.mobilenumber,
            //   password:"p1234"
            // })
             axios.post("https://api.paykit.in/auth-by-passwd",{userid:"AS",password:"12345678"})
            .then(res=>{
              localStorage.setItem('tokens',res.data.token)
              setToken(res.data.token)
              const details = localStorage.getItem('userdetails')?JSON.parse(localStorage.getItem('userdetails')):""
              axios.post("https://api.paykit.in/create-wallet",details
           ,{headers:{Authorization:localStorage.getItem('tokens')?localStorage.getItem('tokens'):""}})
           .then(async res=>{
            const details = localStorage.getItem('userdetails')?JSON.parse(localStorage.getItem('userdetails')):""

            await setCred({
              ...Cred,
              userid:details.mobile,
              password:details.password
            })
            axios.post("https://api.paykit.in/auth-by-passwd", Cred
            ).then(res => {
              setToken(res.data.token)
              console.log(Token)
              localStorage.clear()
              if (Token) {
                
                localStorage.setItem("token", JSON.stringify(Token))
                if (Cred.password === "p1234") {
                  history.push("/wallet/auth/changepassword")
                }
                localStorage.setItem("loginid", JSON.stringify(Cred.userid))
                axios.post("https://marcopolo.prisms.in/api/LinksPay/getUserType", { walletUsername: Cred.userid }, { headers: { Authorization: "#$laksdfnlknoea#$@$%^&%$" } })
                  .then(res => {
                    setuserType(res.data[0].type)
                    localStorage.setItem("userType", res.data[0].type)
                    setisLoggedIn(true)
                    
                  })
                  .catch(err => console.log(err))
              }
              else{
                console.log("somethingingig")
              }
        
            })
              .catch(err => {
                alert("Wrong credentials kindly re-enter your username and password")
        
              })
          })
            })
            })
          .catch(err=>console.log(err))
        }
        setisNewUser(res.data[0].walletFlag)
        setisDis(true)
        localStorage.setItem("loginid", JSON.stringify(Cred.userid))

      })
      .catch(err => console.log(err))
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     settempvar(true)
  //   }, 2000);
  // }, [userType])


  useEffect(() => {
    const user = localStorage.getItem('loginid') ? localStorage.getItem('loginid') : ""
    axios.post("https://marcopolo.prisms.in/api/LinksPay/getUserName", {
      walletUsername: user
    }, { headers: { Authorization: "#$laksdfnlknoea#$@$%^&%$" } })
      .then(res => {
        setuserName(res.data[0].name)
      })
      .catch(err => console.log(err))
  }, [isLoggedIn])

  const logout = ()=>{
    localStorage.clear()
    window.location.reload();
    history.push("/wallet")
  }




  return (
    <>
      {console.log("mobileno.", process.env.REACT_APP_BASE_URL)}

      {!isLoggedIn ?
        <div className='main'>
          
          <div className='title'>
            <img src="./icons/wallet/Links pay.png" alt="" />
          </div>
          <div className='number'>Login</div>
          <input style={{ marginBottom: "10px",maxWidth:"15rem" }} disabled={isDis} type="text" autocomplete="off" onChange={changeHandler} value={Cred.userid} name="userid" placeholder='User ID' required></input>
          {isNewUser === 1 ? "" : <button style={{ marginTop: "0rem" }} className='btn' onClick={isWalletCreated}>Next</button>}
          {isNewUser === 1 ?
            <div style={{ display: "flex", flexDirection: "column" }}>
              <input type="password" onChange={changeHandler} value={Cred.password} name="password" placeholder='Password' required></input>
              <button style={{ marginTop: "2rem" }} className='btn' onClick={loginHandler} type="submit" >SUBMIT</button></div>
            : ""}
        </div>
        :
        <div className='main2__wrapper'>
          
          <div className='main2'>
          <div className='btn__wrapper'>
            <button style={{marginTop:"-5rem"}} className='btn logout' onClick={logout}>Logout</button>
          </div>
            <div className='card'>
              <img className='paycard' src="../icons/wallet/PayCard.PNG" alt="" />
              <p className='card__para' style={{ color: "black" }}>{userName ? `Hello,${userName}` : "Hello,"}</p>
            </div>

            <div className='balance__div'>
              <p>BALANCE</p>
              <h1>{walletStatus ? `SSP ${walletStatus.amount}` : "Loading..."}</h1>
            </div>
            <div className='btn-flexer'>
            {localStorage.getItem('userType') == 1 ?"":
              <div className='transaction__div'>
                <button onClick={() => { history.push("/wallet/pay") }} className='btn btn1'><p>Pay</p></button>
              </div>}
              {/* {tempvar ? <div> */}
                {console.log(localStorage.getItem('userType'))}
                {localStorage.getItem('userType') == 1 ?
                  <div className='transaction__div'>
                    <button onClick={() => { history.push("/wallet/add-balance") }} className='btn'><p>Add balance</p></button>
                  </div>
                  : ""}
              {/* </div> : ""} */}

              <div className='transaction__div'>
                <button onClick={() => { history.push("/wallet/transaction") }} className='btn'><p>Transactions</p></button>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
};

export default StartingPage;
