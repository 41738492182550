import React from 'react'
import { Link } from 'react-router-dom'
import Style from './Pos.module.css'

const Pos = () => {
  return (
    <div className={Style.main}>
      <div>
        <img src="../icons/wallet/sales.png" alt="" />
        <Link to="/wallet/pos/new-sale"><span>New sale</span></Link>
      </div>
      <div>
        <img src="../icons/wallet/dashboard.png" alt="" />
        <Link to="/wallet/pos/reports"><span>Reports</span></Link>
      </div>
    </div>
  )
}

export default Pos