import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import DonationForm from './DonationForm';
import moment from 'moment';
import {useParams} from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft:10,
    marginRight:10,
    maxWidth: 345,   
    marginBottom:10,
    marginTop:20,
    fontFamily:'monospace',
    fontSize:'medium',
    
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize:'contain'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    
  },
  avatar: {
    backgroundColor: '#2d3436',
    verticalAlign: 'bottom'
   
  },
  counter:{
    height:44,
    maxWidth:"40%"
  },
  btn:{
    marginLeft:20,
    height:'100%',
    maxWidth:"50%",
    wordWrap: 'break-word',
    // background:"#2d3436",
    // color:'white'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))


export default function Campaign(props) {
  const [result, setRes]= useState({});
  const [CurrentCampaignName, setCampaign]= useState('');
  const [CurrentCampaignAmount, setAmount]= useState();
  const [inputIndex,setIndex]=useState();
  const classes = useStyles();  
  const [S,setSuccess]=useState(false);
  const [E,setError]=useState(false);
  const [cnt,setCnt]=useState(0);
  const [open, setOpen] = useState(false);
  const {sid} = useParams();

  useEffect(()  =>{
    async function fetchD(){
     const res= await axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"get_alumini_campaign",['sid']:sid})
     .then(res=> {
          console.log("Camp"+ JSON.stringify(res));
         setRes(res)}).catch(err =>console.log("Error-"+err));
 }
   fetchD();
   
},[])
const handleSnack = () => {  //Snackbar Open
  setCnt(0);        
  setSuccess(true);
};
const handleError = () => { //Amount Handle
  setCnt(0);
  setError(true);
};
const handleClose = (event, reason) => { //Snackbar close
  if (reason === 'clickaway') {
    return;
  }
  setError(false);
  setSuccess(false);
};
const handleCloseModelWithsuccess = () => { // Model Close with success
  setOpen(false);
  window.open("https://easebuzz.in/pay/ShikshanXL0RA", "_blank");
  handleSnack();
};
const handleCloseModel= () => { // Model Close without payment
  setOpen(false);
 
};
const handleOpenModel= () => { //Model open
  setOpen(true);
};
  return (
    result.data !=null? 
    <div>
        {/* <CssBaseline />
          <AppBar>
            <Toolbar>
              <Typography variant="h6">Alumni</Typography>
            </Toolbar>
          </AppBar>
        <Toolbar /> */}
   <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                
            >
{result.data.alumini_campaign.map((v,i)=>(
  <div style={ {display:'inner-block'}}>
     <Snackbar anchorOrigin={{  vertical: 'top', horizontal: 'center' }} open={S} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
         Your Donation for {CurrentCampaignName} of &#x20B9;{CurrentCampaignAmount} is in process. The Donation receipts will be send to your Registered Mobile number and Email address, Once 
          the Administrator approves it.
          Thank You.
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{  vertical: 'top', horizontal: 'center' }} open={E} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Please Enter Valid Amount for Donation.
        </Alert>
      </Snackbar>
      {open ? <DonationForm sid={props.sid} ud={props.ud} amt={CurrentCampaignAmount} isopen={open} camp_name={CurrentCampaignName} handleClose={handleCloseModel} onClick={handleCloseModelWithsuccess}/>:<></>}
  <Card  className={classes.root}  key={i}>
       <CardMedia
      className={classes.media}
      image={v.image==null || v.image==''? "https://images.unsplash.com/photo-1468495244123-6c6c332eeece?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"
        :v.image}
      title="Donations"
    />
 
   <div className={classes.details}>
    
    <CardHeader
      avatar={
        <Avatar aria-label="Event" className={classes.avatar}>
          D
        </Avatar>       
      }      
      title={v.Campaign_Name}
    />
    <div style={{
      display: 'flex',
      alignitems: 'center',
      alignSelf: "center"
  }}>
    </div>  
   
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="p">
          <strong>Campaign Information :</strong>
      </Typography><br/>
      <Typography variant="body2" color="textSecondary" component="p">{v.campaign_info}</Typography><br/>
      <Typography variant="body2" color="textSecondary" component="p">
          <strong>Date :</strong>
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">{moment(v.Start_Date, ["YYYY-MM-DD"]).format("LL")} To {moment(v.End_Date, ["YYYY-MM-DD"]).format("LL")} </Typography><br/>
      <Typography variant="body2" color="textSecondary" component="p">
          <strong>Total Campaign Collection :</strong>
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">&#x20B9;{v.Total_Amount}.00 </Typography><br/>

      <Typography variant="subtitle"  color="textSecondary" style={{margin:20}}>
            <strong> Minimun Donation is :&#x20B9; {v.Min_Amount}</strong>
      </Typography><br/><br/>
      <TextField key={i} variant="outlined" type="number" className={classes.counter} label="Donations" 
       onChange={(e) =>setCnt(e.target.value)} 
      InputLabelProps={{ shrink: true }} />
      <Button variant="contained" color="primary" onClick={()=>{
        setCampaign(v.Campaign_Name);
        setAmount(cnt);
        console.warn("btnclk-->"+cnt+typeof(v.Min_Amount)+CurrentCampaignAmount);
        cnt >= Number(v.Min_Amount)?handleOpenModel():handleError();
        
          }} className={classes.btn} variant="contained" >
            Donate 
      </Button> 
     
  </CardContent>
    <CardActions disableSpacing>
  
    </CardActions>
    </div>
    
  </Card></div>
  ))}
      </Grid>
    </div> :<><CircularProgress/></>
  );

}
