import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import Cards from './Cards';
import CardsSearch from './CardsSearch';
import CatLog from './CatLog';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    direction:'row',
    alignItems:"flex-start",
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    
   
  },
 card:{
    
    maxWidth: 200,
    maxHeight:350,
    marginLeft:5,
    marginBottom:5,
    padding:0
 },
 progressbar: {
  display: 'flex',
  position:'absolute',
  left:'40%',
  top:'40%',
  '& > * + *': {
    marginLeft: theme.spacing(2),
  },
},

})); 
export default function Items(props) {
  const classes = useStyles();
 
  const [result,setRes]=useState({});

  
  return (
    props.pdata.data !=null ?
    
    
    <div className={classes.root} >       
<Grid
  container
  direction="row"
  justify="flex-start"
  alignItems="flex-start"
  spacing={0}
  flexWrap='nowrap'
>  
{props.pdata.data.products.map((tile) => (

    tile.p_name.includes(props.metaData) && tile.prod_cat_id==props.cat?
    
    <Cards data={tile} p={props}/>
    
   : (props.metaData==null || props.metaData=='') && (props.catlogid==null || props.catlogid=='') ?<>
    {  tile.prod_cat_id==props.cat ? 
    
    <CardsSearch data={tile} p={props} /> 
    :null}</>:<><CatLog data={tile} p={props} catlog={props.catlogid} /></>
      ))}
    </Grid>
    </div>:<div className={classes.progressbar}>
      <CircularProgress   /></div>

  );
}
