import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SortIcon from '@material-ui/icons/Sort';
import moment from 'moment';
import {Typography,MenuItem,Menu,IconButton,TextField} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Comments from './Comments'
import {useParams} from 'react-router-dom';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));
function getMax(arr, prop) {
  var max;
  for (var i=0 ; i<arr.length ; i++) {
      if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop]))
          max = arr[i];
  }
  return max;
}
export default function Clist(props) {
  const classes = useStyles();
  const assert = require("assert");
  const ITEM_HEIGHT = 48;
 // var udata=JSON.parse(localStorage.getItem('data'))!=undefined?JSON.parse(localStorage.getItem('data')):null;
 // console.log(udata[0].cmaster)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sortby, SetSort] = React.useState('Name');
  const open = Boolean(anchorEl);
  const [expanded, setExpanded] = React.useState(false);
  const [cdata, setCdata] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const {sid}=useParams();

  //const [cm, setCm] = React.useState(props.cm!=undefined?props.cm:udata[0].cmaster!=undefined?udata[0].cmaster:[]);
  var sortJsonArray = require('sort-json-array');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const SortArray=(opt)=>{
    //console.log(props.Data[0].cmaster);
     SetSort(opt);
     if(opt=='Name')
     var sorted=sortJsonArray(props.cm,'school_name');
     if(opt=='Date')
     var sorted=sortJsonArray(props.cm,'created_on','des');
     if(opt=='Username')
     var sorted=sortJsonArray(props.cm,'userid');
    
     setAnchorEl(null);
    // console.log(sorted);
  }
  
 
  const Cmap=()=>{
    var temp=[];
    for(var i=0;i<props.Data[0].cmaster.length;i++){
      for(var j=0;j<props.Data[0].fcomments;j++){
      if(props.Data[0].cmaster[i].cid===props.Data[0].fcomments[j].cid){
        console.log(props.Data[0].fcomments[j].created_on+props.Data[0].fcomments[j].comment)
      }
    }
  }
  }
  const options = [
    'Name',
    'Date',
    'Username'
  ];
  /*
   */
  useEffect(()=>{
    var newi=-1;
    if(props.all!=null && props.all!=undefined && props.all!={}){
      for(var i=0;i<props.cm.length;i++){
       
        if(props.cm[i].cid===props.all.cid){
          newi=i;
        }
      }
    }
    setExpanded(newi!=-1?newi: false);
  },[])
  const handleChange = (panel) => (event, isExpanded) => {
  
    setExpanded(isExpanded ? panel : false);
  };
  return (  
    <List className={classes.root}>
     
      <ListItem alignItems="flex-start" >
      <ListItemText
          primary="Sort"
        />
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SortIcon />
      </IconButton>
        <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
         {options.map((option,i) => (
          <MenuItem key={option} selected={option === sortby} onClick={()=>SortArray(option)}>
            {option}
          </MenuItem>))}
      </Menu>
      
      </ListItem>
      <Divider variant='fullWidth' />
      <ListItem>
      <TextField
                variant="outlined"
                fullWidth
                id="search"
                label="Search"
                name="Search"
                value={search}
                 onChange={(e)=>setSearch(e.target.value)}
                autoComplete="Search"
              />
      </ListItem>
      {/* {console.log(props.Data[0])} */}
     {props.cm!=undefined? props.cm.map((v,i)=>(
          
           search!='' && search!=null? v.school_name.toLowerCase().includes(search.toLowerCase())?
         <Accordion expanded={expanded === i} onChange={handleChange(i)}> 
        <AccordionSummary
          expandIcon={<IconButton onClick={()=>props.isC(v)}><ExpandMoreIcon /></IconButton>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         
        <ListItem alignItems="flex-start" >
        <ListItemAvatar style={{marginTop:3}} >
          <Avatar  src={v.photo} sizes="large" />
        </ListItemAvatar>
        {/* {console.log(v.cid)} */}
        <ListItemText
          primary={v.school_name }
     secondary={<p style={{float:'right',margin:0}}> {v.userid} {moment(v.created_on, ["YYYY-MM-DD"]).format("L")}</p>}
        />
      </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        
      <Comments send={props.send} usrinfo={props.userinfo} cm={props.all!=null?props.all:props.cms} cmt={props.cmt}  />
      </AccordionDetails>
      
      {/* <Divider variant='fullWidth' /> */}
      </Accordion>:null:
      <Accordion expanded={expanded === i} onChange={handleChange(i)}> 
      <AccordionSummary
        expandIcon={<IconButton onClick={()=>props.isC(v)}><ExpandMoreIcon /></IconButton>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
       
      <ListItem alignItems="flex-start" >
      <ListItemAvatar style={{marginTop:3}} >
        <Avatar  src={v.photo} sizes="large" />
      </ListItemAvatar>
      {/* {console.log(v.cid)} */}
      <ListItemText
        primary={v.school_name }
   secondary={<p style={{float:'right',margin:0}}>{v.userid} {moment(v.created_on, ["YYYY-MM-DD"]).format("L")}</p>}
      />
    </ListItem>
    </AccordionSummary>
    <AccordionDetails>
      
    <Comments send={props.send} usrinfo={props.userinfo} cm={props.all!=null?props.all:props.cms} cmt={props.cmt}  />
    </AccordionDetails>
    
    {/* <Divider variant='fullWidth' /> */}
    </Accordion>
      )):null}
      
    </List>
  );
}
