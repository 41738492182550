import React, { useEffect } from 'react';
import './APSstyles.css'
import { Link, useParams } from 'react-router-dom'

export default function UserLanding() {
    const { sid, uid } = useParams();

    useEffect(() => {
        document.getElementsByTagName("title")[0].innerHTML = "Appointment"
    }, []);

    return (
        <div className="APMCard">
            <div className="APMCardSub">
                <div className="APUserLandingPageMainDiv">
                    <div>
                        <Link to={`/appointment/user/requestappointment/${sid}/${uid}`} style={{ textDecoration: "none" }}>
                            <div className="APLandingButtons">
                                Request Appointment
                            </div>
                        </Link>
                        <Link to={`/appointment/user/appointmentstatus/${sid}/${uid}`} style={{ textDecoration: "none" }}>
                            <div className="APLandingButtons">
                                Appointment Status
                            </div>
                        </Link>
                        <Link to={`/appointment/user/appointmentRequest/${sid}/${uid}`} style={{ textDecoration: "none" }}>
                            <div className="APLandingButtons">
                                Approve Appointments
                            </div>
                        </Link>
                        <Link to={`/appointment/user/groupmeetings/${sid}/${uid}`} style={{ textDecoration: "none" }}>
                            <div className="APLandingButtons">
                                Group Meetings
                            </div>
                        </Link>
                        <Link to={`/appointment/user/requests/${sid}/${uid}`} style={{ textDecoration: "none" }}>
                            <div className="APLandingButtons">
                                Parent Appointments
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    );
}
