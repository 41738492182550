import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {Divider} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useParams} from 'react-router-dom';
import Swal from 'sweetalert2'
import history from './history';
import axios from 'axios';
import { EmojiSymbolsTwoTone } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Reg(props) {
  const classes = useStyles();
  const [name,setName]=React.useState(null);
  const [email,setEmail]=React.useState(null);
  const [mob,setMob]=React.useState(null);
  const [otp,setOtp]=React.useState(null);
  const [success,setSuccess]=React.useState(false);
  const [verifyotp,setVerify]=React.useState(null);
  const [newpass,setNewPass]=React.useState('');
  const [cnewpass,setCNewPass]=React.useState('');
  const [isotpsend,setOtpSend]=React.useState(false);
  const {sid} = useParams();

  const [pass,setPass]=React.useState(null);
  const VOTP=()=>{
   
    if(otp==verifyotp){
      Swal.fire(
        "Success",
        "The OTP Verified",
        'success'
    )
      setSuccess(true)
    }
  }
  const  UpdatePass=()=>{
    if(newpass==cnewpass){
      axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?",{['fun_name']:'AlumniPassUpdate',['sid']:sid,['mobile']:mob,['pass']:newpass})
      .then((resp)=>{
        Swal.fire(
          "Success",
          "The Password is successfully updated",
          'success'
      )
      props.lg();
    })
    }
    else 
    Swal.fire(
      "Mismatch",
      "The Password are mismatch",
      'error'
  )
  }
  const Sendotp=()=>{
    
    if(mob!=null){
    var otp= Math.floor(Math.random()*90000) + 10000;
    axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?",{['fun_name']:'AlumniOTPStore',['sid']:sid,['mobile']:mob,['ttl']:otp}).then((resp)=>{
      if(resp.data.Otp!=0){
      setOtpSend(true)
      setVerify(resp.data.Otp)
      }
      if(resp.data.Otp==0){
       Swal.fire(
         "Not Found",
         "The Mobile number is not found",
         'error'
     )
       }
    })
  }
  else
    Swal.fire(
         "Enter Data Correctly",
         "The Mobile number is Wrong ",
         'info'
     )
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Typography component="h1" variant="h5">
          Alumni App
        </Typography>
       {success==false? <div className={classes.form} >
     
          {success==false? <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="mob"
            disabled={isotpsend}
            label="Mobile No"
            name="nob"
            value={mob}
            onChange={(e)=>{setMob(e.target.value)}}
            autoComplete="mob"
            autoFocus
          />:null}
          {isotpsend?<TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="otp"
            label="Enter OTP"
            name="otp"
            value={otp}
            onChange={(e)=>{setOtp(e.target.value)}}
            autoFocus
          />:null}
          
         {isotpsend==false? <Button
          onClick={()=>Sendotp(true)}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
           Send OTP
          </Button>:<Button
          onClick={()=>VOTP()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
           Verify OTP
          </Button>}
          <Link component="button" variant="body2" 
          onClick={() => {
         props.lg();
         }}
        >
        Login
        </Link>
        </div>: success==true?        
        <div className={classes.form} >
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="newpass"
            label="New Password"
            value={newpass}
            onChange={(e)=>{setNewPass(e.target.value)}}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
        
            label="Confirm New Password"
         
            value={cnewpass}
            onChange={(e)=>{setCNewPass(e.target.value)}}
            autoFocus
          />      
          
        <Button
          onClick={()=>UpdatePass()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
           Update Password
          </Button>
        </div>:null
        }
      </div>
    </Container>
  );
}