import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Appointment from '../ReusedComponents/Appointments';
import { useParams } from 'react-router-dom';

export default function AppointmentRequests() {
    const [Appointmentslist, setAppointmentslist] = useState([]);
    const [Loader, setLoader] = useState(true);
    const { sid, uid } = useParams();
    useEffect(() => {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "GetSpuerAStatusBysid", sid: sid }).then((res) => {
            setAppointmentslist((res.data.Data !== undefined) ? res.data.Data : []); setLoader(false);
        })
    }, []);

    return (
        <Appointment data={Appointmentslist} type="admin" loader={Loader} sid={sid} uid={uid} />
    );
}
