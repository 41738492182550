import React from "react";
import styled from "styled-components";

const StudentDetails = ({ fname, lname }) => {
  return (
    <>
      <Container>
        <div className="student-details">
          <h4 className="student_names">
            {fname} {lname}
          </h4>
        </div>
      </Container>
    </>
  );
};

export default StudentDetails;

const Container = styled.div`
  .title {
    padding: 0;
  }
  .student_div-student_names {
    width: 100px;
  }

  .student_names{
    font-size: 1rem;
    font-weight: 600;
    margin-left: 4px;
  }
`;
