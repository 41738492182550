import React, { useState } from 'react';
import './TMStyles.css'


export default function Header(props) {
    const [Hamberger, setHamberger] = useState(false);
    return (
        <div>
            <div className="TMHeader">
                <div className="TMHeaderTitle" onClick={() => { window.location.reload(true) }}>
                    {props.title}
                </div>
                <div className="TMHeaderOptions">
                    {(props.options).map((option, k) => {
                        return (
                            <div className="TMHeaderOption" key={k} onClick={() => { props.ChangePage(option) }}>
                                {option.title}
                            </div>
                        )
                    })}
                </div>
                <div className="TMHamberger" onClick={() => { setHamberger(!Hamberger) }}>{!Hamberger ? "☰" : "✖"}</div>
            </div>
            <div className="TMHeaderHamberger" style={{ width: Hamberger ? "100%" : "0px" }}>
                {(props.options).map((option, k) => {
                    return (
                        <div className="TMHeaderMobile" key={k} onClick={() => { props.ChangePage(option) }}>
                            {option.title}
                        </div>
                    )
                })}
                {Hamberger ?
                    <div style={{ display: "flex", backgroundColor: "rgba(0,0,0,0)", alignItems: "center", justifyContent: "center", height: "100%", width: "100%", position: "fixed" }} onClick={() => { setHamberger(!Hamberger) }}></div>
                    : null}
            </div>
        </div>
    );
}
