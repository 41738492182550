import React from "react";

const NotAvailable = ({ title }) => {
  const header = {
    textAlign: "center",
  };

  const subTitleStyle = {
    fontSize: "15px",
    color: "#444",
    fontWeight: "400",
    fontFamily: "Poppins",
    marginTop: "15px", // Assuming mt={4} corresponds to 16px (4 * 4px)
    textAlign: "center",
  };
  return (
    <div style={header}>
      <h1 style={subTitleStyle}>{title}</h1>
    </div>
  );
};


export default NotAvailable;