import React, { useEffect, useState } from "react";
import CommentIcon from "@material-ui/icons/Comment";
import styled from "styled-components";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import TeacherComments from '../Report/teacherComments'
import StudentDetails from "./StudentDetails";
const Comments = () => {
  const { user_id, sid, staffid } = useParams();
  const { state } = useLocation();
  let history = useHistory();

  const [Comments, setComments] = useState([]);
  

  const divArr = state.split(" ");

  console.log(divArr)
  const FirstName = divArr[0];
  const LastName = divArr[1];
  const class_div = divArr[2];
  const divName = divArr[3];
  const std = divArr[4]

  const getStudentData = async () => {
    try {
      const token = "#$laksdfnlknoea#$@$%^&%$";
      const data = {
        sid: `${sid}`,
        userid: `${user_id}`
      };
      const response = await axios({
        method: "post",
        url: "https://marcopolo.prisms.in/api/studentlog/reports",
        data,
        headers: {
          Authorization: `${token}`,
        },
      });

      const reports = response.data;
      function combine(arr) {
        var combined = arr.reduce(function(result, item) {
          var current = result[item.createdDate];

          result[item.createdDate] = !current
            ? item
            : {
                userid: current.userid,
                fname: item.first_name,
                lname: item.last_name,
                date: item.createdDate,
                division:item.division,
                field_name: current.field_name + "," + item.field_name,
                value: current.value + "," + item.value,
              };

          return result;
        }, {});

        return Object.keys(combined).map(function(key) {
          return combined[key];
        });
      }

      const result = combine(reports);
      // console.log(result.reverse());
      setComments(result.reverse());

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStudentData();
  }, []);


  return (
    <>
      <Container>
        <Wrapper>
          <div className="comments">
            <div className="heading">
              <CommentIcon
                style={{ color:'#eb317f', fontSize: "30px" }}
              />
              <p>Comments</p>
            </div>
            <StudentDetails  fname={FirstName} lname={LastName} />

            <TeacherComments Comments={Comments} />
          </div>

          <div className="plus">
            <button
              onClick={() =>
                history.push({
                  pathname: `/studentlog/addcomments/${user_id}/${sid}/${staffid}`,
                  state: `${FirstName} ${LastName} ${class_div} ${user_id} ${divName} ${std}`,
                })
              }
            >
              Add
            </button>

            <Link to={`/studentlog/${sid}/${staffid}`}>
              <button>Home</button>
            </Link>
          </div>
        </Wrapper>
      </Container>
    </>
  );
};

export default Comments;

const Container = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
`;
const Wrapper = styled.div`
  max-width: 1150px;
  width: 100%;
  height: auto;
  padding: 30px 0;
  margin: 50px auto;
  background-color: white;

  @media (max-width: 768px) {
    width: 95%;
    margin: 20px 0;
    height: 100vh;
    box-shadow: none;
  }

  .comments {
    max-width: 900px;
    width: 100%;
    height: auto;
    margin: 20px auto 15px;

    @media (max-width: 768px) {
      width: 90%;
    }
  }

  .teacherName {
    color: #444;
    margin: 10px 8px;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0;

    p {
      margin-bottom: 0;
    }
  }
  .heading {
    display: flex;
    margin: 15px 8px;

    p {
      margin-left: 10px;
      margin-top: 0;
      font-size: 1.1rem;
      font-weight: 600;
      color: #eb317f;
    }
  }

  button {
    width: 140px;
    border: none;
    background-color: rgb(75, 130, 230);
    color: white;
    outline: none;
    border-radius: 4px;
    height: 40px;
    font-size: 1rem;
    margin: 0 12px;
  }
  .plus {
    height: 50px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
