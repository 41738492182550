import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import EventIcon from '@material-ui/icons/Event';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

import {useParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft:10,
    marginRight:10,
    maxWidth: 345,
    minWidth:345,   
    marginBottom:10,
    marginTop:20,
    fontFamily:'monospace',
    fontSize:'medium'
    
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize:'contain'
  },
  expand: {
    transform: 'rotate(0deg)',
  
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  
  expandOpen: {
    transform: 'rotate(180deg)',
    
  },
  avatar: {
    backgroundColor: red[500],
    verticalAlign: 'bottom'
   
  },
}))


export default function Events(props) {
  const [result, setRes]= useState({});
  const classes = useStyles();  
  const {sid} = useParams();

  useEffect(()  =>{
    async function fetchD(){
     const res= await axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"get_alumini_events",['sid']:sid})
     .then(res=> {
          console.log("then"+ JSON.stringify(res));
         setRes(res)}).catch(err =>console.log("Error-"+err));
 }
   fetchD();
   
},[])

  return (
    result.data !=null ? 
    <div style={ {display:'inner-block'}} >
        {/* <CssBaseline />
          <AppBar>
            <Toolbar>
              <Typography variant="h6">Alumni</Typography>
            </Toolbar>
          </AppBar>
        <Toolbar /> */}
   <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                
            >
    {result.data.alumini_events.map((v,i)=>(
      
       <Card id={i} className={classes.root}>
       <CardMedia
      className={classes.media}
      image={v.photo==null || v.photo==''?"https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTFoULAK7D7XnJxJBCSNL_qQCr0tZ1aNUnqRg&usqp=CAU"
    :v.photo}
      title="Alumni Meet"
    />
   
   <div className={classes.details}>
    
    <CardHeader 
      avatar={
        <Avatar aria-label="Event" className={classes.avatar}>
          E
        </Avatar>
        
      }
      
      title={<Typography variant='subtitle1'>{v.event_name}</Typography>}
    subheader={<><RoomOutlinedIcon style={{verticalAlign: 'bottom'}} fontSize="small" color="secondary" /> <span style={{fontSize:"small"}}>{v.location}</span></>}
    />
    <div style={{
      display: 'flex',
      alignitems: 'center',
      alignSelf: "center"
  }}>
    
   
    <div style={{paddingLeft:15,paddingRight:15 ,justifyContent:'space-between'}}> 
    <EventIcon  style={{verticalAlign: 'bottom'}} color="secondary"/>
   { v.end_date!=null || v.end_date!='' ? <span style={{verticalAlign: 'center',alignSelf: "center"}}>{v.astart_date} To {v.end_date} </span> 
     :<span style={{verticalAlign: 'center',alignSelf: "center"}}>{v.astart_date}</span>
}
    <span style={{display: 'inline-block'}}><TimerOutlinedIcon style={{verticalAlign: 'bottom'}}  color="secondary"/>{v.event_time}</span>
      </div>
      

  </div>  
   
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="p" style={{overflowWrap:'break-word'}}>
      {v.description}
      </Typography>
    </CardContent>
    <CardActions disableSpacing>
     
    </CardActions>
    </div>
    
  </Card>
    ))}
      
    

    
      </Grid>
    </div> :<><CircularProgress/></>
  );

}
