import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom'
import Navbar from './navbar'
import Loader from 'react-loader-spinner'
import ScoreAnimate from './ScoreAnimate';
import Swal from 'sweetalert2';
import { TextareaAutosize } from '@material-ui/core';

function Review() {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Quiz />
        </div>
    )
}

export default Review

function Quiz() {

    const history = useHistory();
    const answers = JSON.parse(history.location.state.answers.replaceAll("\n", "\\n"));
    const { sid, uid } = useParams();
    const [questions, setQuestions] = useState([]);
    const [answersState, setAnswersState] = useState([]);
    const [loader, setLoader] = useState(true);
    const [viewquestions, setviewquestions] = useState(false);
    const [CWUNA, setCWUNA] = useState({ correct: 0, wrong: 0, unattempted: 0 });
    const [isreview, setIsreview] = useState("");

    useEffect(() => {

        let isCancelled = false;
        let correct = 0;
        let wrong = 0;
        let unattempted = 0;

        if (history.location.state !== undefined) {
            axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Quizapp_Get_DataByQiz", sid: sid, quizid: history.location.state.quizid, isreview: 0 }).then((res) => {
                try {
                    setIsreview(res.data.quizlist[0].review === "yes")
                } catch (e) {
                    setIsreview(false)
                    console.error(e);
                }
            })
            axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Quizapp_Getquestion", sid: sid, quizid: history.location.state.quizid })
                .then((res) => {
                    if (!isCancelled) {
                        try {
                            let filledarr = new Array((res.data.Questions.length) - (answers.length)).fill({ isOption1: "0", isOption2: "0", isOption3: "0", isOption4: "0" })
                            let CArray = [...answers, ...filledarr]
                            let ques = res.data.Questions
                            setAnswersState(CArray)
                            setQuestions(res.data.Questions);
                            CArray.forEach((i, k) => {
                                if (ques[k].questionType === "4") {
                                    // if (!i.isOption1 || i.isOption1 === "") unattempted++
                                }
                                else if (i.isOption1 === ques[k].isOption1 && i.isOption2 === ques[k].isOption2 && i.isOption3 === ques[k].isOption3 && i.isOption4 === ques[k].isOption4)
                                    correct += 1;
                                else if (i.isOption1 === "1" || i.isOption2 === "1" || i.isOption3 === "1" || i.isOption4 === "1")
                                    wrong++;
                                else
                                    unattempted++;
                            })
                            setCWUNA({ correct, wrong, unattempted });
                            setLoader(false);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                })
            return () => isCancelled = true;
        }
    }, []);

    return (
        <div className="quiz-container">
            <div className="quiz-head"><div style={{fontFamily:'Poppins'} }>Quiz Review</div><Navbar home={{ isHome: true, home: `/quizapp/${sid}/${uid}` }} back={true} refresh={false} /></div>
            <hr className="hr-line" />
            {
                loader || answersState.length !== questions.length || isreview === "" ?
                    <div id="loader">
                        <Loader
                            type="Oval"
                            color="#0066ff"
                            height={70}
                            width={70}
                        /></div>
                    :
                    <div>
                        <div style={{ maxHeight: "80vh", overflow: "auto", marginTop: "10px" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                <label style={{ whiteSpace: "nowrap", overflowX: "scroll", fontSize: 19, marginBottom: 20 }}>Quiz Name : {history.location.state.quizName}</label>
                                <label style={{ fontSize: 19, marginBottom: 20,fontFamily:'Poppins'  }}>MCQ's Correct : {CWUNA.correct}</label>
                                <label style={{ fontSize: 19, marginBottom: 20,fontFamily:'Poppins'  }}>MCQ's Wrong : {CWUNA.wrong}</label>
                                <label style={{ fontSize: 19, marginBottom: 20,fontFamily:'Poppins'  }}>Unattempted : {CWUNA.unattempted} </label>
                                <label style={{ fontSize: 19, marginBottom: 20,fontFamily:'Poppins'  }}>Score : </label>
                                <div style={{ width: "30%", height: "30%", }}>
                                    <ScoreAnimate score={history.location.state.score !== "0" && history.location.state.score !== NaN ? Math.round((history.location.state.score / history.location.state.total) * 100) : 0} />
                                </div>
                                <div className="button-div" style={{ display: answers[0].isOption1 !== undefined ? "block" : "none", marginBottom: "20px" }}>
                                    <button onClick={() => {
                                        if (isreview === true) {
                                            setviewquestions(!viewquestions)
                                        } else {
                                            Swal.fire('No Access to Review this Quiz', "Please contact your teacher to review this quiz", 'error')
                                        }
                                    }} style={{ fontSize: "20px", position: "relative", paddingLeft: "20px", display: "inline", paddingRight: "20px", marginTop: "20px",fontFamily:'Poppins'  }} className="create-button">{viewquestions ? "Hide Questions" : "View Questions"}</button>
                                </div>
                            </div>
                            <div style={{ display: viewquestions ? "block" : "none" }}>
                                {questions.map((question, k) => {
                                    return (
                                        <div style={{ width: "95%", marginBottom: "50px" }} key={k}>

                                            <div style={{ backgroundColor: "white", padding: "10px", borderRadius: "7px", marginBottom: "20px" }}>{k + 1} . {question.questionTitle}<br></br>{question.additionalText}<div style={{ float: 'right', color: "red" }}>* {question.mark} {question.mark > 1 ? "marks" : "mark"}</div><br></br></div>
                                            {question.image !== "" ?
                                                <div style={{ display: "flex", alignItems: 'center', justifyContent: "center", marginBottom: 5, minHeight: "200px", maxHeight: "315px", width: "100%", position: "relative", border: 0 }}>
                                                    <img src={question.image} onLoad={() => { document.getElementById(`image${k}`).style.opacity = 0 }}
                                                        style={{ maxHeight: "300px", maxWidth: "100%", position: "relative", border: 0 }}
                                                        alt="Image Loading... Please Wait..."
                                                    />
                                                    <div id={`image${k}`} style={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", border: 0, padding: 5 }}>
                                                        <Loader
                                                            type="Oval"
                                                            color="red"
                                                            height={50}
                                                            width={50}
                                                        />
                                                    </div>
                                                </div> : null
                                            }{question.questionType === "4" ?
                                                <div>
                                                    <TextareaAutosize aria-label="minimum height"
                                                        className="quiz__validae__adiv"
                                                        disabled={true}
                                                        value={"Answer : \n\n" + answersState[k].isOption1}
                                                    />
                                                </div>
                                                :
                                                <>
                                                    <p id="isOption1" style={{ backgroundColor: answersState[k].isOption1 === question.isOption1 ? answersState[k].isOption1 === "0" ? "white" : "lightgreen" : question.isOption1 === "0" ? "#ffcccb" : "lightgreen", padding: "10px", borderRadius: "25px", display: question.option1 !== "" ? 'block' : "none" }}>
                                                        <input type={question.questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {question.option1}
                                                    </p>
                                                    <p id="isOption2" style={{ backgroundColor: answersState[k].isOption2 === question.isOption2 ? answersState[k].isOption2 === "0" ? "white" : "lightgreen" : question.isOption2 === "0" ? "#ffcccb" : "lightgreen", padding: "10px", borderRadius: "25px", display: question.option2 !== "" ? 'block' : "none" }}>
                                                        <input type={question.questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {question.option2}
                                                    </p>
                                                    <p id="isOption3" style={{ backgroundColor: answersState[k].isOption3 === question.isOption3 ? answersState[k].isOption3 === "0" ? "white" : "lightgreen" : question.isOption3 === "0" ? "#ffcccb" : "lightgreen", padding: "10px", borderRadius: "25px", display: question.option3 !== "" ? 'block' : "none" }}>
                                                        <input type={question.questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {question.option3}
                                                    </p>
                                                    <p id="isOption4" style={{ backgroundColor: answersState[k].isOption4 === question.isOption4 ? answersState[k].isOption4 === "0" ? "white" : "lightgreen" : question.isOption4 === "0" ? "#ffcccb" : "lightgreen", padding: "10px", borderRadius: "25px", display: question.option4 !== "" ? 'block' : "none" }}>
                                                        <input type={question.questionType === "2" ? 'checkbox' : "radio"} checked={false} readOnly></input> {question.option4}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}
