import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import "./Style.css";
import html2canvas from "html2canvas";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  gridList: {
    height: "auto",
    overflow: "scroll",
  },
}));

export default function ImageGridList(props) {
  const classes = useStyles();
  const location = useLocation();
  const [ar, setArr] = React.useState([]);
  const [isprint, setPrint] = React.useState(false);

  const ImageCanvas = async () => {
    for (let j = 0; j < ar.length; j++) {
      const input = document.getElementById("grid" + j);
      await html2canvas(input, {
        dpi: 300,
        scale: 4,
        useCORS: true,
      }).then(function (canvas) {
        const imageURL = canvas.toDataURL("image/jpg");
        const a = document.createElement("a");
        a.href = imageURL;
        a.download = "IDCARD_Images_Page_" + (j + 1) + ".jpg";
        a.click();
      });
    }
  };

  const Print = () => {
    setPrint(!isprint);
  };

  useEffect(() => {
    if (isprint === true) window.print();
  }, [isprint]);

  useEffect(() => {
    const x = [];
    const round = Math.ceil(location.state.data2.length / 4); // Update to 4 images per page

    var i, j, temporary, chunk = 4;
    for (i = 0, j = location.state.data2.length; i < j; i += chunk) {
      temporary = location.state.data2.slice(i, i + chunk);
      x.push(temporary);
    }
    setArr(x);
  }, []);

  return (
    <div style={{ maxWidth: isprint ? "100%" : 900, minWidth: isprint ? "100%" : 900 }}>
      {!isprint && (
        <Button
          style={{ marginLeft: 30, marginRight: 30 }}
          variant="outlined"
          color="primary"
          id="hidbtn"
          onClick={() => ImageCanvas()}
        >
          Download As Image
        </Button>
      )}
      <Button
        style={{ marginLeft: 30, marginRight: 30 }}
        variant="outlined"
        color="primary"
        id="hidbtn"
        onClick={() => Print()}
      >
        {isprint ? "Image View" : "Print View"}
      </Button>
      {ar.map((v, i) => (
        <React.Fragment key={i}>
          <p style={{ textAlign: "center" }} id="hidbtn">
            Page - {i + 1}
          </p>
          <GridList
            id={"grid" + i}
            style={{ marginLeft: isprint ? 47 : 110 }}
            className={classes.gridList}
            cols={2} // Two columns for better alignment in print view
            spacing={0} // No spacing between tiles
          >
            {ar[i].map((tile, j) => (
              <GridListTile
                key={j}
                style={{
                  border: "1px solid",
                  borderBottom: (j + 1) % 2 === 0 ? "1px solid" : "none",
                  height: isprint ? "auto" : "auto", 
                  margin: 4,
                  padding: 0, 
                  width: "47%", 
                }}
              >
                <img style={{ width: "100%", height: "100%" }} src={tile} alt="" />
              </GridListTile>
            ))}
          </GridList>
        </React.Fragment>
      ))}
    </div>
  );
}
