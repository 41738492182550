import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

const TeacherComments = ({ Comments }) => {
  const url =
    "https://cdn.iconscout.com/icon/free/png-256/data-not-found-1965034-1662569.png";
  return (
    <>
      <Container>
        <Wrapper>
          {Comments.length > 0 ? (
            Comments.map((data, index) => {
              const { field_name, value, fname, lname, division } = data;
              return (
                <div className="main_box" key={index}>
                  <div className="box">
                    <div className="fields">
                      <span>
                        {field_name.split(",").map((item) => (
                          <>
                            {" "}
                            {item} <br />{" "}
                          </>
                        ))}
                      </span>
                    </div>

                    <div className="values">
                      <span>
                        {value.split(",").map((item) => (
                          <>
                            {" "}
                            {item} <br />{" "}
                          </>
                        ))}
                      </span>
                    </div>
                  </div>

                  <div className="box__2">
                    <span className="teacher__commits">Commented By</span>
                    <div className="teacher_date">
                      <span className="teacher_name">
                        {fname} {lname}
                      </span>
                      <span className="date_sec">
                        {moment(data.date).format("DD-MM-YYYY")}, {division}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <Nocomments className="student_comments">
              <img src={url} alt="pics" />
              <span className="nocomments">
                There are currently no comments for this student.
              </span>
            </Nocomments>
          )}
        </Wrapper>
      </Container>
    </>
  );
};
// May 14th 22

export default TeacherComments;

const Container = styled.div``;

const Wrapper = styled.div`
  border-radius: 4px;
  padding: 10px 15px;
  @media screen and (max-width: 768px) {
    padding: 10px 5px;
    margin: 10px 0;
    font-family: sans-serif;
  }

  .main_box {
    border-radius: 4px;
    /* background-color: #eef2f4; */
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 8px;
    &:hover {
    background-color: #dceaf3;
    color: #111;
  }
  }
  .box {
    width: 100%;
    height: auto;
    display: flex;
  }
  .fields {
    text-align: start;
    color: #0099ff;
    font-size: 1rem;
    max-width: 177px;
    width: 100%;
  }

  .values {
    text-align: start;
    color: #3d3939;
    font-size: 1rem;
    text-transform: capitalize;
    margin-left: 25px;
  }

  .comment {
    margin: 0px;
  }

  .teacher__commits {
    font-size: 1rem;
    color: #eb317f;
    font-weight: 600;
  }

  .teacher_name {
    font-size: 0.9rem;
  }

  .teacher_date {
    display: flex;
    justify-content: space-between;
  }
  .date_sec {
    color: #eb317f;
  }
`;

const Nocomments = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  img {
    width: 80px;
    height: 80px;
  }
  .nocomments {
    text-align: center;
    color: #eb317f;
    font-size: 1rem;
  }
`;
