import React, { Component } from "react";
import "../App.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import axios from "axios";
import { MDBCard, MDBCardText, MDBContainer } from "mdbreact";
//import { object } from "prop-types";
class Studonlineclass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sid: this.props.match.params.sid ? this.props.match.params.sid : "",
      userid: this.props.match.params.userid
        ? this.props.match.params.userid
        : "",
      flag: 0,
      isLoading: true,
    };
    /* this.getStudclassMeeting = this.getStudclassMeeting.bind(this); */
  }

  getStudentOnlineMeetingData(self) {
    console.log("geting stud online meeting details");
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        ["fun_name"]: "get_Studonlinemeeting_Data_For_IOS",
        ["sid"]: this.state.sid,
        ["userid"]: this.state.userid,
      })
      .then(function(result) {
        console.log("result", result);
        if (result.data.length > 0) {
          self.setState({
            flag: 1,
            data: result.data,
          });
        } else {
          self.setState({
            flag: 1,
            data: [],
          });
        }
      });
    //set state
  }

  getStudclassMeetingLink = () => {
    console.log("getting student online meeting link details");
    //alert(this.state.userid);
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        ["fun_name"]: "get_Studonlinemeeting_Link_IOS",
        ["sid"]: this.state.sid,
        ["userid"]: this.state.userid,
      })
      .then(function(result) {
        console.log("result", result);
        if (result.data.length > 0) {
          window.open(result.data, "_blank");
          console.log("called..");
        } else {
          console.log("error..");
        }
      });
  };

  componentDidMount() {
    this.getStudentOnlineMeetingData(this);
  }

  render() {
    return (
      <MDBContainer>
        {this.state.data && this.state.data == "INVALID" ? (
          <MDBCard className="card-body mt-5 card-background">
            <MDBCardText>
              Online Classes are not available at the moment
            </MDBCardText>
          </MDBCard>
        ) : this.state.data && this.state.data.length > 0 ? (
          this.state.data.map((value, index) => (
            <div className="mx-auto pt-2" key={index}>
              <MDBCard className="card-body card-background">
                <MDBCardText>
                  <span className="float_left">
                    <strong>Event: </strong>
                    {value.title}
                  </span>
                  <span className="float_right">
                    <strong>Title: </strong>
                    {value.cal_title}
                  </span>
                </MDBCardText>

                <MDBCardText>
                  <span className="float_left">
                    <strong>Class: </strong>
                    {value.div_name}
                  </span>
                  <span className="float_right">
                    <strong>Date: </strong>
                    {value.date}
                  </span>
                </MDBCardText>

                <MDBCardText>
                  <span className="float_left">
                    <strong>Time: </strong>
                    {value.time}
                  </span>
                  <span className="float_center">
                    <button
                      type="button"
                      onClick={this.getStudclassMeetingLink}
                      className="btn btn-light-green btn-md"
                    >
                      JOIN
                    </button>
                  </span>
                  <span className="float_right">
                    <strong>Duration: </strong>
                    {value.duration + " Min"}
                  </span>
                </MDBCardText>
              </MDBCard>
            </div>
          ))
        ) : (
          <div className="d-flex justify-content-center">
            <div className="loader border-top-info margin-top-1" />
          </div>
        )}
      </MDBContainer>
    );
  }
}

export default Studonlineclass;
