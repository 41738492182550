import React, { useEffect, useRef } from 'react'
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from 'react';
import searchIcon from './../images/searchIcon.png';
import Loader from "react-loader-spinner";
import { useReactToPrint } from 'react-to-print';
import Swal from "sweetalert2";
import { DecryptED } from "../../DecryptJS/Decrypt";
import { downloadExcel } from 'react-export-table-to-excel';
import moment from 'moment';
const LessonPlanAdminHome = () => {

    const { sid, userid } = useParams();
    const tableRef = useRef(null);

    const [Cpage, setCpage] = useState(1);
    
    const [teachers, setTeachers] = useState([]);
    const [classList, setClassList] = useState([]);
    const [lessonReport, setLessonReport] = useState([]);
    const [apiloader, setapiLoader] = useState();
    const [getDataLoading, setGetDataLoading] = useState(false);

    const [newReport, setNewReport] = useState([]);
    let tableCounter ;

    const [formData, setFormData] = useState({ sid: DecryptED(sid), userId: DecryptED(userid), staff: [], div: [], startDate: "", endDate: "", pageSize: 15, page: 1 });

    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    const getAllTeachers = async () => {
        try {
            setGetDataLoading(true)
            const data = {
                "sid": DecryptED(sid),
                "userId": DecryptED(userid)
            }
            const response = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASE_URL}/api/admin/lessonPlan/teachers`,
               // url: `http://localhost:8080/api/admin/lessonPlan/teachers`,
                data,
                headers: {
                    Authorization: "#$laksdfnlknoea#$@$%^&%$",
                },
            })
                .then((response) => {
                    setGetDataLoading(false)
                    setTeachers(response.data)
                })
                .catch((error) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 5000,
                    });
                });
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "error",
                title: error.response.data.message,
                showConfirmButton: false,
                timer: 5000,
            });
        }
    }

    const getAllClasses = async () => {
        try {
            setGetDataLoading(true)
            const data = {
                "sid": DecryptED(sid),
                "userId": DecryptED(userid)
            }
            const response = await axios({
                method: "POST",
               // url: `http://localhost:8080/api/admin/lessonPlan/class`,
                 url: `${process.env.REACT_APP_BASE_URL}/api/admin/lessonPlan/class`,
                data,
                headers: {
                    Authorization: "#$laksdfnlknoea#$@$%^&%$",
                },
            })
                .then((response) => {
                    setClassList(response.data)
                    setGetDataLoading(false)
                })
                .catch((error) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: error.response.data.message,
                        showConfirmButton: false,
                        timer: 5000,
                    });
                });
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "error",
                title: error.response.data.message,
                showConfirmButton: false,
                timer: 5000,
            });
        }
    }

    

    const generatePdf = useReactToPrint({
        content: () => tableRef.current,
        documentTitle: "LessonReport",
        onAfterPrint: () => alert("Print Success!")
    })
   
    const selectTeacher = (e) => {
        if (e === "SELECT ALL") {
            for (let i = 0; i < teachers.length; i++) {
                formData.staff.push(teachers[i].staff_id);
            }
        } else {
            setFormData({ ...formData, staff: [parseInt(e)] });
            // formData.staff.push(parseInt(e));
        }
    }
    const selectClass = (e) => {
        if (e === "SELECT ALL") {
            for (let i = 0; i < classList.length; i++) {
                formData.div.push(classList[i].classdiv_id);
            }
        } else {
            setFormData({ ...formData, div: [parseInt(e)] });
            // formData.div.push(parseInt(e));
        }
    }

    useEffect(() => {
        getAllTeachers();
        getAllClasses();
    }, [])

    const fetchData = async () => {
        setapiLoader(true);
        try {
                if(formData.staff.length <= 0 || formData.div.length <= 0){
                    throw "Select Teachers and Class"
                }
                const response = await axios({
                    method: "POST",
                    //url: `http://localhost:8080/api/admin/lessonPlan/all/report`,
                    url: `${process.env.REACT_APP_BASE_URL}/api/admin/lessonPlan/all/report`,
                    data: formData,
                    headers: {
                        Authorization: "#$laksdfnlknoea#$@$%^&%$",
                    },
                })
                .then((response)=>{
                    setLessonReport(response.data);
                    const updatedArray = response.data.data.map(item => {
                        return {
                          Teacher: item.Teacher,
                          created_date: item.created_date,
                          div_name: item.div_name,
                          subject: item.subject,
                          LessonName: item.LessonName
                        }
                      });
                    setNewReport(updatedArray)
                    setapiLoader(false);
                })
                .catch((error)=> {throw error});
            
        } catch (error) {
            console.log(error)
            Swal.fire({
                position: "center",
                icon: "error",
                title: error,
                showConfirmButton: false,
                timer: 5000,
            });
            setapiLoader(false);
        }
    };


    const submitForm = () => {
        fetchData();
    };

    const changePage = (pageNo) =>{
        setFormData({...formData, page: pageNo});  
    }
     useEffect(() => {
        if(formData.staff.length > 0 && formData.div.length >0){
            fetchData();
        }
     }, [formData.page]);

    
    function handleDownloadExcel() {
        downloadExcel({
          fileName: `Admin_LessonReport_${moment().format('DD-MM-YYYY, h:mm:ss a') }`,
          sheet: "react-export-table-to-excel",
          tablePayload: {
            header: ["Teacher Name", "Date", "Class", "Subject", "Lesson Name"],
            // accept two different data structures
            body: newReport,
          },
        });
      }

      useEffect(() => {
        const today = new Date();
        const numberOfDaysToAdd = -30;
        const date = today.setDate(today.getDate() + numberOfDaysToAdd);

        const today2 = new Date();
        const CurrentDate = today2.toISOString().split('T')[0];

        const defaultValue = new Date(date).toISOString().split('T')[0];

        setFormData({...formData, startDate: defaultValue, endDate: CurrentDate});

        
      }, [])
      

    return (
        <div className='container'>
            <div className='mt-5'>
                <form className='row' onSubmit={submitForm}>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="sel1">Teacher</label>
                            <select className="form-control" id="teacherList" onChange={(e) => { selectTeacher(e.target.value) }}>
                                <option>SELECT TEACHER</option>
                                
                                {
                                    getDataLoading ? <option>Loading... Please Wait</option> :
                                        <>
                                            <option value={"SELECT ALL"} >SELECT ALL TEACHERS</option>
                                            {teachers.length > 0 ? teachers.map((teacher, index) => (
                                                <option key={index} value={teacher.staff_id}>{teacher.Teacher}</option>
                                            )) : <option>NO DATA FOUD</option>}
                                        </>
                                }

                            </select>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="sel1">Class</label>
                            <select className="form-control" id="teacherList" onChange={(e) => { selectClass(e.target.value) }}>
                                <option>SELECT CLASS</option>

                                {
                                    getDataLoading ? <option>Loading... Please Wait</option> :
                                        <>
                                            <option value={"SELECT ALL"}>SELECT ALL CLASSES</option>
                                            {classList.length ? classList.map((classes, index) => (
                                                <option key={index} value={classes.classdiv_id}>{classes.div_name}</option>
                                            )) : (<option>NO DATA FOUND</option>)}
                                        </>
                                }
                            </select>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <label htmlFor='fromDate' className='m-0'>FROM</label>
                        <div id="from-date-picker" className="md-form md-outline input-with-post-icon datepicker" inline="true">
                            <input type="date" id="fromDate" className="form-control" defaultValue={formData.startDate} onChange={(e) => setFormData({ ...formData, startDate: e.target.value })} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <label htmlFor='toDate' className='m-0'>TO</label>
                        <div id="to-date-picker" className="md-form md-outline input-with-post-icon datepicker" inline="true">
                            <input type="date" id="toDate" className="form-control" defaultValue={formData.endDate} onChange={(e) => setFormData({ ...formData, endDate: e.target.value })} />
                        </div>
                    </div>
                    <input type='button' className='btn btn-primary mt-0' value={"Submit"} onClick={() => submitForm()} />
                </form>
                <div>
                    {lessonReport.TotalCount  ?
                        <div className='w-100 d-flex justify-content-between'>
                            <div>Showing Data: {formData.startDate} <b className='text-bold'>TO</b> {formData.endDate}</div>
                            <button className='btn btn-primary m-0' onClick={() => { generatePdf(); }}>Export to PDF</button>{" "}
                            <button className='btn btn-primary m-0' onClick={()=>handleDownloadExcel()}>Export to Excel</button>
                        </div>
                        : null}
                </div>

                <hr />
                {apiloader ? (
                    <div className="TMLoader">
                        <Loader type="ThreeDots" color="#bd2f5e" height={80} width={80} />
                    </div>
                ) : (
                    <div className='table-responsive' >
                        <table className="table table-hover" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col"><strong>Teacher Name</strong></th>
                                    <th scope="col"><strong>Date</strong></th>
                                    <th scope="col"><strong>Class</strong></th>
                                    <th scope="col"><strong>Subject</strong></th>
                                    <th scope="col"><strong>Lesson Name</strong></th>
                                    <th scope="col"><strong>Actions</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {lessonReport.TotalCount > 0 ? lessonReport.data.map((reports, index) => {
                                     tableCounter = (formData.page - 1) * 15 + index + 1;
                                   return (
                                    <tr key={index}>
                                        <th scope="row">{tableCounter}</th>
                                        <td>{reports.Teacher}</td>
                                        <td>{formatDate(reports.created_date)}</td>
                                        <td>{reports.div_name}</td>
                                        <td>{reports.subject}</td>
                                        <td>{reports.LessonName}</td>
                                        <td>
                                            <Link to={`/admin/report/${sid}/${userid}/${reports.id}`}>
                                                <img
                                                    src={searchIcon}
                                                    alt="searchIcon"
                                                    width={"20px"}
                                                />
                                            </Link>
                                        </td>
                                    </tr>
                                )}) : <tr>
                                    <td colSpan={7} className='text-center'>NO DATA FOUND</td>
                                </tr>}
                            </tbody>
                            <tfoot>
                                <tr>
                                    {lessonReport.TotalCount > 0 ?
                                        <td colSpan={7}>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className={`page-item ${formData.page <= 1 ? 'disabled' : ''}`}>
                                                        <div className="page-link" tabIndex={'-1'} onClick={() =>changePage(formData.page-1)}>Previous page</div>
                                                    </li>
                                                    <li class="page-item">
                                                        <div className="page-link">showing <b className='font-weight-bold'>{tableCounter}</b> OUT OF <b className='font-weight-bold'>{lessonReport.TotalCount}</b></div>
                                                    </li>
                                                    <li className={`page-item ${tableCounter === lessonReport.TotalCount ? 'disabled' : ''}`}>
                                                        <div className={`page-link`} onClick={() =>changePage(formData.page+1)}>Next page</div>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </td> : <td>{""}</td>
                                    }
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LessonPlanAdminHome
