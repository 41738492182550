import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {IconButton} from '@material-ui/core';
import Barchart2 from './barchart2';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import axios from 'axios';
// import { LineChart } from 'recharts';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    //   width: '100%',
    //   height: '97vh',
    },
  },
  img:{
      position:'fixed',
      bottom:-25
  },
  formControl:{
      display:'flex',
      margin:'0 auto',
      marginLeft:'20vw',
width:'50vw',
marginTop:'20px',

  },
  barchart:{
    marginTop:'1vh',
        width: '100%',
    [theme.breakpoints.up('sm')]: {   
        marginTop:'5vh',
    width: '60%',
      },
  }
}));

export default function RA(props) {
    const [Fdata,setFdata]=React.useState(null);
    const [Mdata,setMdata]=React.useState(null);
    const [disc,setDisc]=React.useState(false);
      const [isdata,setIsData]=React.useState(true);
    const [Name,setName]=React.useState('');
    const [Sublist,setSublist]=React.useState([]);
useEffect(()=>{
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'ResultAnalysisData',['sid']:props.match.params.sid,['userid']:props.match.params.userid}).then (res=> {
    if(res.data.marks.length<1){
      setIsData(false)
    }  
    const sbjt = [...new Set(res.data.marks.map(item => item.subject))];
         setSublist(sbjt)
        setMdata(res.data.marks)
        setName(res.data.userinfo[0].name)
        handleChangeN()
        });
},[])

  const classes = useStyles();
  

  const [sub, setSub] = React.useState('');
  const [c, setCount] = React.useState(0);
  const [text,setText]=React.useState('')
   var avg=[];

useEffect(()=>{
  handleChangeN()
},[c,Mdata])

  const handleChangeN = () => {
    if(Mdata!=null){
    const selected = [...new Set(Mdata.map(item => item.subject==Sublist[c]?item:null ))];
    var filteredData = selected.filter(Boolean);
    // selected.pop(selected.indexOf(null))
    setFdata(filteredData)
    console.log(filteredData)
   console.log(c)

    setSub(Sublist[c]);
    // window.alert(JSON.stringify(Sublist[c]))
    }
  };
  useEffect(()=>{
    Analysis()
    },[sub])
  const Analysis=()=>{
    if(Fdata!=null){
      
      if(Fdata.length===1){
        avg.push(0)
        avg.push(0)
      }
      else if(Fdata.length===2){
        avg.push(0)
      }
    for(let i=0;i<Fdata.length;i++){

      if(Fdata[i].score>=1 && Fdata[i].score<=2){
        avg.push(1);
      }
      else if(Fdata[i].score>=2 && Fdata[i].score<=3.4){
        avg.push(2);
      }
      else if(Fdata[i].score>=3.41){
        avg.push(3);
      }

    }
console.log(avg)
if(avg[0]==0 && avg[1]==0 & avg[2]==1){
  setText('has done done well this time in ')
}
if(avg[0]==0 && avg[1]==0 & avg[2]==2){
  setText('Can do better in ')
}
if(avg[0]==0 && avg[1]==0 & avg[2]==3){
  setText(' is doing good in ')
}
if(avg[0]==0 && avg[1]==1 & avg[2]==1){
  setText('has to take more efforts in ')
}
if(avg[0]==0 && avg[1]==1 & avg[2]==2){
  setText('is doing better in ')
}
if(avg[0]==0 && avg[1]==1 & avg[2]==3){
  setText('is doing much better in ')
}
if(avg[0]==0 && avg[1]==2 & avg[2]==1){
  setText('need to improve in ')
}
if(avg[0]==0 && avg[1]==2 & avg[2]==2){
  setText('need to work on this subject ')
}
if(avg[0]==0 && avg[1]==2 & avg[2]==3){
  setText(' is doing better in ')
}
if(avg[0]==0 && avg[1]==2 & avg[2]==3){
  setText(' is doing better in ')
}
if(avg[0]==0 && avg[1]==3 & avg[2]==1){
  setText('was doing  good in subject earlier, need to put more efforts to maintain consistency in ')
}
if(avg[0]==0 && avg[1]==3 & avg[2]==2){
  setText('was doing  good in subject earlier, need to put more efforts to maintain consistency in ')
}
if(avg[0]==0 && avg[1]==3 & avg[2]==3){
  setText('is consistent and is doing good in ')
}
if(avg[0]==1 && avg[1]==0 & avg[2]==1){
  setText('has to take more efforts in ')
}
if(avg[0]==1 && avg[1]==0 & avg[2]==2){
  setText('is doing better in ')
}
if(avg[0]==1 && avg[1]==0 & avg[2]==3){
  setText('is doing much better in ')
}
if(avg[0]==1 && avg[1]==1 & avg[2]==0){
  setText('has to take more efforts in ')
}
if(avg[0]==1 && avg[1]==1 & avg[2]==1){
  setText('has to work on bsics of this subject ')
}
if(avg[0]==1 && avg[1]==1 & avg[2]==2){
  setText('is doing better in ')
}
if(avg[0]==1 && avg[1]==2 & avg[2]==1){
  setText('has to work on basics of ')
}
if(avg[0]==1 && avg[1]==2 & avg[2]==2){
  setText('is doing good but can do better in ')
}
if(avg[0]==1 && avg[1]==2 & avg[2]==3){
  setText('is consistently improving in ')
}
if(avg[0]==1 && avg[1]==3 & avg[2]==1){
  setText('has to work on basics of ')
}
if(avg[0]==1 && avg[1]==3 & avg[2]==2){
  setText('is doing good but can do better in')
}
if(avg[0]==1 && avg[1]==3 & avg[2]==3){
  setText('has shown very good improvement')
}
if(avg[0]==2 && avg[1]==1 & avg[2]==1){
  setText('has to work on basics of ')
}
if(avg[0]==2 && avg[1]==1 & avg[2]==2){
  setText('has to work on basics of ')
}
if(avg[0]==2 && avg[1]==1 & avg[2]==3){
  setText('is doing good but can do better in ')
}
if(avg[0]==2 && avg[1]==2 & avg[2]==1){
  setText('has to work on basics of ')
}
if(avg[0]==2 && avg[1]==2 & avg[2]==2){
  setText('can do better but need to work on basics of')
}
if(avg[0]==2 && avg[1]==2 & avg[2]==3){
  setText('is doing very good, need to keep up the work in ')
}
if(avg[0]==2 && avg[1]==3 & avg[2]==1){
  setText('can do better but needs to work on basics ')
}
if(avg[0]==2 && avg[1]==3 & avg[2]==2){
  setText('is doing very good, need to keep up the work in ')
}
if(avg[0]==2 && avg[1]==3 & avg[2]==3){
  setText('is doing excellent in ')
}
if(avg[0]==3 && avg[1]==1 & avg[2]==1){
  setText('has to work on basics of ')
}
if(avg[0]==3 && avg[1]==1 & avg[2]==2){
  setText('needs to work harder to maintain consistency in ')
}
if(avg[0]==3 && avg[1]==1 & avg[2]==3){
  setText('needs to work harder to maintain consistency in ')
}
if(avg[0]==3 && avg[1]==2 & avg[2]==1){
  setText('has to work on basics of')
}
if(avg[0]==3 && avg[1]==2 & avg[2]==2){
  setText('can do better but but needs to work on basics of ')
}
if(avg[0]==3 && avg[1]==2 & avg[2]==3){
  setText('is doing excellent in ')
}
if(avg[0]==3 && avg[1]==3 & avg[2]==1){
  setText('has to work harder to maintain the previous level of consistency in  ')
}
if(avg[0]==3 && avg[1]==3 & avg[2]==2){
  setText('has to work harder to maintain the previous level of consistency in  ')
}
if(avg[0]==3 && avg[1]==3 & avg[2]==3){
  setText('is doing verry good in ')
}
    }
  }
  const capitalize=(input)=> {  
    return input.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }  
  const AlertClose=()=>{
    setDisc(false)
  }
  return (isdata?<div>
    
   {Mdata!=null?<> 
  
   <div className={classes.root} >
  
    <div className={classes.barchart}>{sub!=''&& sub.length>0?<Barchart2 sub={sub}data={Fdata} Close={AlertClose} alert={disc}/>:null}</div>       
   {Sublist.length>0  && c<=Sublist.length+1?<div style={{marginTop:30}}><p style={{display:'inline',color:'green'}}>Analysis :</p><p style={{margin:0,display:'inline',textAlign:'center'}}>{ capitalize(Name.substring(0,Name.indexOf(' ')))+' ' + text + sub}</p></div>
     :null}

    
    {Sublist.length>0  && c<Sublist.length-1?<IconButton style={{position:'fixed',bottom:'14%',right:10,width:10,height:8}} onClick={()=>setCount(c+1)}>
            <PlayCircleOutlineIcon color="secondary" fontSize='large' />
        </IconButton>:null}
        {Sublist.length>0? <IconButton style={{position:'fixed',bottom:'14%',right:'44%',width:10,height:8}} onClick={()=>setDisc(true)}><InfoSharpIcon color="secondary" fontSize='large'/> </IconButton>:null}
        {c>0 ?<IconButton style={{position:'fixed',bottom:'14%',left:10,width:10,height:8,transform: "rotate(-180deg)"}} onClick={()=>setCount(c-1)}>
            <PlayCircleOutlineIcon color="secondary" fontSize='large' />
        </IconButton>:null}
      {/* </Paper> */}  
    
    </div>
    
    <div  className={classes.img}>
    <img style={{width:'100vw',height:'18vh'}}  src={'/icons/largebk.png'}/>
    </div>
    </>:null}
    </div>:<div >
    <p style={{textAlign:'center',marginTop:'40vh',fontSize:'x-large'}}> Exam Data Not Available </p>
      <div  className={classes.img}>
    <img style={{width:'100vw',height:'18vh'}}  src={'/icons/largebk.png'}/>
    </div>
    </div>
  );
}
