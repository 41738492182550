import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, createMuiTheme, MuiThemeProvider, ThemeProvider } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import axios from 'axios';
const themenew = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 888,
      md: 890,
      lg: 1170,
      xl: 1200
    }
  }
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  tile: {
    display: 'flex',
  },
  live: {
    width: '96vw', height: '38vh', marginTop: '5vh',

    [theme.breakpoints.up('sm')]: {
      width: 300, height: 200,
    }
  },
  title: {
    textAlign: 'center', width: '96vw',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center', width: 300,
    }
  }
}));



const GallaryImage = (props) => {
  const classes = useStyles();
  //   const theme = useTheme();
  const { sid } = useParams();

  const [result, setRes] = useState(null);
  const [imgprvw, setImg] = useState('');
  //   const matches = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const getGridListCols = () => {
    if (isWidthUp('xs', props.width)) {
      return 6;
    }
    if (isWidthUp)

      if (isWidthUp('sm', props.width)) {
        return 6;
      }

    if (isWidthUp('md', props.width)) {
      return 4;
    }

    return 1;
  }

  useEffect(() => {
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { ['fun_name']: "Alumni_Video_Select", ['sid']: sid })
      .then(res => {
        console.log(res);

        setRes(res)
      }).catch(err => console.log("Error-" + err));
  }, [])
  const LinkConvert = (link) => {
    var l2=link.split('&')[0];
    // var l='https://www.youtube.com/watch?v=TWVXuaRhvt8';
    var new1 = l2.replace('watch?v=', 'embed/')
    if (!new1.includes('?rel=0')) {
      var new2 = new1 + '?rel=0';
    }
    else {
      var new2 = new1;
    }
    return new2
  }
  return (
    <ThemeProvider theme={themenew}>
      <div >

        <Grid container>
          {result != null ? result.data.Videos.map((v, i) =>
            v.admin_approve == "1" ?
              <Grid item xs={8} sm={6} md={6} lg={4}>
                <iframe src={LinkConvert(v.link)}
                  allowFullScreen="allowfullscreen"
                  mozallowFullScreen="mozallowfullscreen"
                  msallowFullScreen="msallowfullscreen"
                  oallowFullScreen="oallowfullscreen"
                  webkitallowFullScreen="webkitallowfullscreen"
                  className={classes.live}
                  // style={{width:600, height:350}}
                  frameborder='1'
                  allow='autoplay; encrypted-media'
                  allowFullScreen
                  title='video'
                />
                <Typography component="h5" className={classes.title}>{v.title}</Typography>
              </Grid> : null
          ) : null}


        </Grid>
      </div>
    </ThemeProvider>
  );
}
export default withWidth()(GallaryImage);
