import React, { Component } from "react";
import "../App.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { useParams} from "react-router-dom";
import Showgraph from "./showgraph";
import Inputtags from "./inputtags";
import { DecryptED } from "../DecryptJS/Decrypt"
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBContainer,
  MDBDatePicker,
} from "mdbreact";
import axios from "axios";
class Attendance extends Component {



  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sid: DecryptED(this.props.match.params.sid) ? DecryptED(this.props.match.params.sid) : "",
      userid: DecryptED(this.props.match.params.userid)
        ? DecryptED(this.props.match.params.userid)
        : "",
      month: "",
      changedDate: new Date(),
      next_previous_count: 0,
      highlightWithRanges: [],
      dataDoughnut: {},
      flag: 0,
    };
  }

  getAttendanceData(month, self) {
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        ["fun_name"]: "get_Attendance_Data_For_IOS",
        ["sid"]: DecryptED(this.state.sid),
        ["userid"]: DecryptED(this.state.userid),
        ["month"]: month,
      })
      .then(function(result) {
        if (result) {
          self.setState({
            flag: 1,
          });
          var modifyResponseObj = [
            {
              "react-datepicker__day--highlighted-custom-1": [],
            },
            {
              "react-datepicker__day--highlighted-custom-2": [],
            },
            {
              "react-datepicker__day--highlighted-custom-3": [],
            },
            {
              "react-datepicker__day--highlighted-custom-4": [],
            },
            {
              "react-datepicker__day--highlighted-custom-5": [],
            },
          ];

          var modifydatasets = [];
          result.data.map((value, index) => {
            if (Object.keys(value) == "present") {
              modifyResponseObj[1][
                "react-datepicker__day--highlighted-custom-2"
              ].push(new Date(value.present));
            }
            if (Object.keys(value) == "absent") {
              modifyResponseObj[0][
                "react-datepicker__day--highlighted-custom-1"
              ].push(new Date(value.absent));
            }
            if (Object.keys(value) == "holiday") {
              modifyResponseObj[2][
                "react-datepicker__day--highlighted-custom-3"
              ].push(new Date(value.holiday));
            }
            if (Object.keys(value) == "wholiday") {
              modifyResponseObj[3][
                "react-datepicker__day--highlighted-custom-4"
              ].push(new Date(value.wholiday));
            }
            if (Object.keys(value) == "nottaken") {
              modifyResponseObj[4][
                "react-datepicker__day--highlighted-custom-5"
              ].push(new Date(value.nottaken));
            }
            if (Object.keys(value) == "present_percentage") {
              modifydatasets.push(
                parseFloat(value.present_percentage).toFixed(2)
              );
            }
            if (Object.keys(value) == "absent_percentage") {
              modifydatasets.push(
                parseFloat(value.absent_percentage).toFixed(2)
              );
            }
            if (Object.keys(value) == "holiday_percentage") {
              modifydatasets.push(
                parseFloat(value.holiday_percentage).toFixed(2)
              );
            }
            if (Object.keys(value) == "weekly_holiday_percentage") {
              modifydatasets.push(
                parseFloat(value.weekly_holiday_percentage).toFixed(2)
              );
            }
            if (Object.keys(value) == "not_taken_percentage") {
              modifydatasets.push(
                parseFloat(value.not_taken_percentage).toFixed(2)
              );
            }
          });
          var dataDoughnut = {
            labels: [
              "Present",
              "Absent",
              "Holiday",
              "Weekly Holiday",
              "Not Taken",
            ],
            legend: {
              display: false,
            },
            datasets: [
              {
                data: modifydatasets,
                legend: {
                  fontSize: 30,
                },
                backgroundColor: [
                  "#46BFBD",
                  "#F7464A",
                  "#FDB45C",
                  "#949FB1",
                  "#4D5360",
                ],
                hoverBackgroundColor: [
                  "#5AD3D1",
                  "#FF5A5E",
                  "#FFC870",
                  "#A8B3C5",
                  "#4D5360",
                ],
              },
            ],
          };
          console.log("modifydatasets==>" + JSON.stringify(dataDoughnut));
          self.setState({
            highlightWithRanges: modifyResponseObj,
            dataDoughnut,
          });
        }
      });
    //set state
  }

  componentDidMount() {
    this.getAttendanceData(this.state.changedDate, this);
    //call function this.getAttendance(month);
  }
  onPrevClick(month) {
    this.setState({
      changedDate: month,
      flag: 0,
    });
    this.getAttendanceData(month, this);
    //call funciton this.getAttendaceData()
    //set state data
  }
  onNextClick(month) {
    let CurrentDate = new Date();
    if (month <= CurrentDate) {
      this.setState({
        changedDate: month,
        flag: 0,
      });
      this.getAttendanceData(month, this);
    }
    //call funciton this.getAttendaceData()
    //set state data
  }

  render() {
    return (
      <div className="Calender-App">
        {this.state.flag && this.state.flag == 1 ? (
          <div>
            <Inputtags
              onPrevClick_attr={this.onPrevClick.bind(this)}
              onNextClick_attr={this.onNextClick.bind(this)}
              highlightDates={this.state.highlightWithRanges}
              changeDates={this.state.changedDate}
              next_previous_count={this.state.next_previous_count}
            >
              Input Tags
            </Inputtags>
            <Showgraph dataDoughnutattr={this.state.dataDoughnut}>
              Showgraph
            </Showgraph>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="loader border-top-info margin-top-1" />
          </div>
        )}
      </div>
    );
  }
}

export default Attendance;
