import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useParams} from 'react-router-dom';
import axios from 'axios';
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});
const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 1,      
  maximumFractionDigits: 1,
});
function createData(clas, total, datac, proofc) {
  return {
    clas,
    total,
    datac,
    proofc,
    history: [
      {clas:clas,Total:total,DataC:datac,ProofC:proofc },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const classes = useRowStyles();
 
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {/* <TableCell  >
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}
        <TableCell component="th" scope="row" >
       <div style={{display:'inline-block'}}>{row.clas}
       <IconButton style={{border:'none',outline:'none'}} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          </div>
        </TableCell>
        <TableCell align="right">{formatter.format(row.datac/row.total * 100)}%</TableCell>
        <TableCell align="right">{formatter.format(row.proofc/row.total * 100)}%</TableCell>
        {/* <TableCell align="right">{row.proofc}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {row.clas}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Total Students</TableCell>
                    <TableCell>Data Confirmed</TableCell>
                    <TableCell >Proof Conformed </TableCell>
                   
                  </TableRow>
                </TableHead>
                <TableBody> 
                  {row.history.map((historyRow) => (
                    
                    <TableRow key={historyRow.Total}>
                      <TableCell component="th" scope="row">
                        {historyRow.Total}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {historyRow.DataC}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {historyRow.ProofC}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Coll(props) {
const [Ayear, setAyear] = React.useState('2020-2021');
const {sid}=useParams();
const [report,setReport]=React.useState(null);
const [Darray, setDarray] = React.useState(null);
const [age, setAge] = React.useState('');
const [Sel, setSel] = React.useState(null);
const handleChange = (event) => {
  setAyear(event.target.value);
};

useEffect(()=>{
  axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'GetIDDesign',['sid']:sid}).then (res=> {
        // console.log(res.data.design)
      setDarray(res.data.design)
     
      });
},[])
const FontFm = (event) => {
       
  setAge(event.target.value);
  for(var i=0;i<Darray.length;i++){
      if(Darray[i].name === event.target.value){
      setSel(Darray[i])
      // setOpen(Darray[i].orientation)
      }
  }
  console.log(event.target.value)
  // props.ld(event.target.value)
};
useEffect(()=>{
  if(Sel!=null){
  axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'IDcard_report',['sid']:sid,['did']:Sel.id}).then (res=> {
const temp=[];

for(let i=0;i<res.data.total.length;i++){
  var total=res.data.total[i].total;
var divname=res.data.total[i].divname;
var ta='0';
var imgc='0';

for(let j=0;j<res.data.ta.length;j++){
  if(res.data.ta[j].cid==res.data.total[i].class && res.data.ta[j].cdid==res.data.total[i].classdiv){
    console.log(res.data.ta[j].total_data_approve)
    // temp.push(res.data.total[j].total_data_approve)
    ta=res.data.ta[j].total_data_approve;
  }
}
for(let k=0;k<res.data.image_approve.length;k++){
  if(res.data.image_approve[k].class==res.data.total[i].class && res.data.image_approve[k].classdiv==res.data.total[i].classdiv){
    // temp.push(res.data.total[k].no_of_confirm)
    imgc=res.data.image_approve[k].no_of_confirm;
  }
}
var tem={divname:divname,ta:ta,imgc:imgc,total:total}; 
temp.push(tem);
}
setReport(temp)

    });
  }
},[Sel])
const rows = [
report!=null||report!=undefined?report.map((v,i)=>
createData(v.divname,parseInt(v.total),parseInt(v.ta),parseInt(v.imgc))
):null
];

  return (
    <div>
     <FormControl variant="outlined" fullWidth  style={{marginBottom:20}}>
        <InputLabel  >
          Design
        </InputLabel>
        <Select fullWidth
          variant="outlined"
          value={age}
          onChange={FontFm}
          displayEmpty
          label="Design"
         
        >
            {Darray!=null || Darray!=undefined? Darray.map((v,i)=>
            <MenuItem key={i} value={v.name}>{v.name}</MenuItem>
            ):null}
        </Select>
      </FormControl>
      {report!=null?  <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow>
            {/* <TableCell /> */}
            <TableCell>Class</TableCell>
            <TableCell align="right">Data Confirmed</TableCell>
            <TableCell align="right">Proof Confirmed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows[0].map((row) => (  
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>:null}
    </div>
  );
}
