import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./Form.css";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import { DecryptED } from "../../DecryptJS/Decrypt";
const useStyles = makeStyles((theme) => ({
  labelDiv: {
    width: "25%",
    display:'flex'
  },
  inputDiv: {
    width: "65%",
  },
  inputStyle: {
    backgroundColor: "rgb(111, 214, 255)",
    color: "white",
    width: "100%",
    fontSize: "1rem",
    height: "35px",
    border: "none",
    borderRadius: "5px",
  },
  ["@media(max-width:800px)"]: {
    inputStyle: {
      marginLeft: "10px",
    },
  },
  selectStyle: {
    backgroundColor: "rgb(111, 214, 255)",
    color: "white",
    width: "100%",
    fontSize: "1rem",
    height: "35px",
  },
  durationDiv: {
    display: "flex",
  },
  ["@media(min-width:800px)"]: {
    durationDiv: {
      width: "50%",
    },
  },
}));

const Form = () => {
  const classes = useStyles();
  const history = useHistory();
  let { sid, userid } = useParams();
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [lessonName, setLessonName] = useState("");
  const [lessonObjective, setLessonObjective] = useState("");
  const [learningOutcome, setLearningOutcome] = useState("");
  const [pedagogicalPractices, setPedagogicalPractices] = useState("");
  const [audioVisualAdis, setAudioVisualAdis] = useState("");
  const [teacherPreparation, setTeacherPreparation] = useState("");
  const [studentPreparation, setStudentPreparation] = useState("");
  const [additionalExtended, setAdditionalExtended] = useState("");
  const [interdisciplinaryLinkage, setInterdisciplinaryLinkage] = useState("");
  const [infusion, setInfusion] = useState("");
  const [artIntegration, setArtIntegration] = useState("");
  const [storyBased, setStoryBased] = useState("");
  const [artificialIntelligence, setArtificialIntelligence] = useState("");
  const [fln, setFln] = useState("");
  const [remedialPlan, setRemedialPlan] = useState("");
  const [cwsnPlan, setCwsnPlan] = useState("");
  const [assignment, setAssignment] = useState("");
  const [referenceUsed, setReferenceUsed] = useState("");
  const [currentAcadId, setCurrentAcadId] = useState("");
  const [classId, setClassId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [lessonDuration, setLessonDuration] = useState("");
  const [month, setMonth] = useState("");
  const [arr, setArr] = useState();

  const submitForm = async (e) => {
    e.preventDefault();
    if (lessonName && classId && month && subjectId && lessonDuration) {
      const data = {
        fun_name: "addLessonplanData",
        user_id: DecryptED(userid),
        sid: DecryptED(sid),
        acad_id: currentAcadId,
        month,
        subject_id: `${subjectId}`,
        classdiv_id: `${classId}`,
        Lessonname: lessonName,
        Lessonduration: `${lessonDuration}`,
        lesson_objective: lessonObjective,
        learning_outcome: learningOutcome,
        pedagogical_practices: pedagogicalPractices,
        audio_visual_adis: audioVisualAdis,
        teacher_preparation: teacherPreparation,
        student_preparation: studentPreparation,
        additional_extended_information: additionalExtended,
        interdisciplinary_linkage: interdisciplinaryLinkage,
        infusion_with_life_skills: infusion,
        art_integration: artIntegration,
        story_based: storyBased,
        artificial_intelligence: artificialIntelligence,
        fln: fln,
        remedial_plan: remedialPlan,
        cwsn_plan: cwsnPlan,
        assignment: assignment,
        reference_used: referenceUsed,
      };
      const response = await axios.post(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data
      );
      if (response) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `LessonPlan for ${lessonName} has been submitted successfully!`,
          showConfirmButton: false,
          timer: 1500,
        });
        history.push(`/lessonHome/${sid}/${userid}`);
      }
    } else {
      let missingFields = [];

      if (!lessonName) missingFields.push("Lesson Name");
      if (!classId) missingFields.push("Class");
      if (!month) missingFields.push("Month");
      if (!subjectId) missingFields.push("Subject");

      // Display alert with missing fields
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Please fill the required fields:${missingFields.join(", ")}`,
      });
      // window.alert("Please, Fill all the Fields!");
    }
  };
  const selectClassIdHandler = (e) => {
    e.preventDefault();
    if (e.target.value !== "default") {
      const classId = e.target.value;
      setClassId(classId);
      const getAccessableSubject = async () => {
        const response = await axios.post(
          "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
          {
            fun_name: "getAccessClassSubject",
            sid: DecryptED(sid),
            staff_id: DecryptED(userid),
            div_id: classId,
          }
        );
        if (response) {
          setSubjectList(response.data.result);
        }
      };
      getAccessableSubject();
    }
  };
  useEffect(() => {
    const getAccessableClass = async () => {
      const data = {
        fun_name: "getAccessClass",
        sid: DecryptED(sid),
        staff_id: DecryptED(userid),
      };
      const response = await axios.post(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data
      );
      if (response) {
        setClassList(response.data.result);
      }
    };
    getAccessableClass();

    const getCurrentAcadId = async () => {
      const data = {
        fun_name: "currentAcadId",
        sid: DecryptED(sid),
      };
      const response = await axios.post(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data
      );
      if (response) {
        setCurrentAcadId(response.data.result[0].acadid);
      }
    };
    getCurrentAcadId();
  }, []);

  // Lesson Duration change 1 to 99.
  useEffect(() => {
    let arr = [];
    for (let i = 1; i <= 99; i++) {
      arr.push(i);
    }
    setArr(arr);
  }, []);
  return (
    <div className="form-main">
      <div className="lesson-heading">
        <p>LESSON PLAN</p>
      </div>
      <form className="form">
        <div className="user-details">
          <div className="form-details">
            <div className="form-inputs">
              <div className={classes.labelDiv}>
                <label className="label__title">
                  Class 
                </label>
                <span style={{color:'red'}}>*</span>
              </div>
              <div className={classes.inputDiv}>
                <select
                  className={classes.selectStyle}
                  onChange={(e) => selectClassIdHandler(e)}
                >
                  <option value="default">Select Class</option>
                  {classList &&
                    classList.map((data, idx) => {
                      return (
                        <option key={idx} value={data.id}>
                          {data.div_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="form-inputs">
              <div className={classes.labelDiv}>
                <label className="label__title">Month</label>
                <span style={{color:'red'}}>*</span>

              </div>
              <div className={classes.inputDiv}>
                <input
                  className={classes.inputStyle}
                  onChange={(e) => setMonth(e.target.value)}
                  name="month"
                  id="month"
                  type="month"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="form-details">
            <div className="form-inputs">
              <div className={classes.labelDiv}>
                <label className="label__title">Subject</label>
                <span style={{color:'red'}}>*</span>

              </div>
              <div className={classes.inputDiv}>
                <select
                  className={classes.selectStyle}
                  onClick={(e) => setSubjectId(e.target.value)}
                >
                  <option value="default">Select Subject</option>
                  {subjectList &&
                    subjectList.map((data, idx) => {
                      return (
                        <option key={idx} value={data.id}>
                          {data.subject}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="form-inputs">
              <div className={classes.labelDiv}>
                <label style={{ marginTop: "8px" }} className="label__title">
                  Lesson Name
                </label>
                <span style={{color:'red'}}>*</span>

              </div>
              <div className={classes.inputDiv}>
                <input
                  className={classes.inputStyle}
                  value={lessonName}
                  onChange={(e) => setLessonName(e.target.value)}
                  id="lessonName"
                  type="text"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className={classes.durationDiv}>
            <div className={classes.labelDiv}>
              <label className="label__title">Lesson Duration</label>
            </div>
            <div className={classes.inputDiv}>
              <select
                className={classes.selectStyle}
                name="lessonDuration"
                id="lessonDuration"
                onClick={(e) => setLessonDuration(e.target.value)}
              >
                <option value="default">Select Duration</option>
                {arr &&
                  arr.map((item, idx) => {
                    return (
                      <option key={idx} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="mid-details">
          <div>
            <TextField
              fullWidth
              multiline
              minRows={3}
              id="outlined-basic"
              label="Lesson Objective"
              variant="outlined"
              size="small"
              value={lessonObjective}
              onChange={(e) => setLessonObjective(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              multiline
              minRows={3}
              id="outlined-basic"
              label="Learning Outcome"
              variant="outlined"
              size="small"
              value={learningOutcome}
              onChange={(e) => setLearningOutcome(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              multiline
              minRows={3}
              id="outlined-basic"
              label="Pedagogical Practices"
              variant="outlined"
              size="small"
              value={pedagogicalPractices}
              onChange={(e) => setPedagogicalPractices(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              multiline
              minRows={3}
              id="outlined-basic"
              label="Audio/VisualAids"
              variant="outlined"
              size="small"
              value={audioVisualAdis}
              onChange={(e) => setAudioVisualAdis(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Teacher Preparation"
              variant="outlined"
              size="small"
              value={teacherPreparation}
              onChange={(e) => setTeacherPreparation(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Student Preparation"
              variant="outlined"
              size="small"
              value={studentPreparation}
              onChange={(e) => setStudentPreparation(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Additional Extended Information"
              variant="outlined"
              size="small"
              value={additionalExtended}
              onChange={(e) => setAdditionalExtended(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Interdisciplinary Information"
              variant="outlined"
              size="small"
              value={interdisciplinaryLinkage}
              onChange={(e) => setInterdisciplinaryLinkage(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Infusion with Life Skills"
              variant="outlined"
              size="small"
              value={infusion}
              onChange={(e) => setInfusion(e.target.value)}
            />
          </div>
        </div>
        <div className="form-supplemental">
          <p>Supplemental Activites</p>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Art-Integration"
              variant="outlined"
              size="small"
              value={artIntegration}
              onChange={(e) => setArtIntegration(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Story Based"
              variant="outlined"
              size="small"
              value={storyBased}
              onChange={(e) => setStoryBased(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Artificial Intelligence"
              variant="outlined"
              size="small"
              value={artificialIntelligence}
              onChange={(e) => setArtificialIntelligence(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="FLN"
              size="small"
              value={fln}
              onChange={(e) => setFln(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Remedial Plan"
              size="small"
              value={remedialPlan}
              onChange={(e) => setRemedialPlan(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="CWSN Plan"
              size="small"
              value={cwsnPlan}
              onChange={(e) => setCwsnPlan(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Assignment"
              size="small"
              value={assignment}
              onChange={(e) => setAssignment(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Reference Used"
              size="small"
              value={referenceUsed}
              onChange={(e) => setReferenceUsed(e.target.value)}
            />
          </div>
        </div>
        <div className="btn-div" style={{ marginTop: "20px" }}>
          <button
            style={{
              height: "40px",
              color: "white",
              backgroundColor: "rgb(111, 214, 255)",
              border: "none",
              outline: "none",
              fontSize: "18px",
              padding: "0px 16px",
              borderRadius: "6px",
              cursor: "pointer",
            }}
            onClick={(e) => submitForm(e)}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
