import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Navbar from './Navbar';
import Container from '@material-ui/core/Container';

export default function ElevateAppBar(props) {
  React.useEffect(() => {
    document.getElementsByTagName("title")[0].innerHTML = "IDcard"
}, []);
  return (
    <React.Fragment>
      <CssBaseline />
        <AppBar>
          <Toolbar>
            <Typography variant="h6">ID Card </Typography>
          </Toolbar>
        </AppBar>
      <Toolbar />
      <Navbar/>
      <Container>
        
      </Container>
    </React.Fragment>
  );
}
