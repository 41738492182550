import React, { useState, useEffect } from 'react'
import styles from './Pay.module.css'
import axios from 'axios'
import { useHistory } from 'react-router-dom'


const Pay = () => {


  const history = useHistory()
 
  const [userList, setuserList] = useState([])
  const [walletId, setwalletId] = useState();
  const [apiData, setApiData] = useState({
    to: "",
    amount: "",
    datetime: (new Date()).toISOString().slice(0, 19).replace("T", " "),
    purpose: "",

  })

  const clickHandler = () => {
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : ""
    if (apiData.amount > 0 && apiData.to !== "") {
      
      axios.post(" https://api.paykit.in/get-wallet-status", {}, {
      headers: { "Authorization": token }
    }).then(res => {
      if(res.data.amount >= apiData.amount){
      axios.post(" https://api.paykit.in/create-transaction", apiData, {
        headers: { "Authorization": token }

      }).then(res => {
        alert("Transaction successful!")
        history.push("/wallet")
      }
      )
        .catch(err => alert("Something went wrong!"))}
      else{
        alert("Not enough Balance!")
      }
    }
    ).catch(err => {
      if (err.response.status === 401) {
        localStorage.removeItem('token')
        
      }
      else alert(err)
    })

    }
  }

  useEffect(() => {
    if(walletId){
    
    axios.post("https://marcopolo.prisms.in/api/LinksPay/getwalletId", {"walletUsername":walletId}, {
        headers: { "authorization": "#$laksdfnlknoea#$@$%^&%$" }

      }).then(res => {
        setApiData({
              ...apiData,
              to: res.data[0].walletId
             })
      }
      )
        .catch(err => console.log(err))}

  }, [walletId]);

  const selectHandler = (e) => {
    setwalletId(e.target.value)
  }

  const changeHandler = (e)=>{
    const{name,value} = e.target
    setApiData({
      ...apiData,
      [name]:value
    })
  }



  useEffect(() => {
    axios.post('https://marcopolo.prisms.in/api/LinksPay/getMerchantList', {}, {
      headers: { Authorization: "#$laksdfnlknoea#$@$%^&%$" }
    })
      .then(res => setuserList(res.data))
      .catch(err => console.log(err))
  }, [])




  return (
    <div className={styles.wrapper}>
      {console.log(apiData)}
      <div className={styles.main}>
        <h2>Payment</h2>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img style={{ width: "150px", height: "150px" }} src="../icons/wallet/sales.png" alt="" />
        </div>
        <div style={{ width: "100%", textAlign: "left" }}>
          <p>Pay To</p>

          <select onChange={selectHandler} name='to' style={{ width: "100%" }}>
            <option hidden>Select</option>
            {userList.length ? userList.sort(function (a, b) {
              var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
              if (nameA < nameB)
                return -1
              if (nameA > nameB)
                return 1
              return 0
            }).map((data, index) => {

              return (<option key={index} value={data.mobilenumber} > {data.name}</option>)
            }) : ""}
          </select>

        </div>
        <div style={{ width: "100%", textAlign: "left" }}>
          <p>Enter Amount</p>
          <input onChange={changeHandler} name='amount' type="number" style={{ width: "100%" }} />
          <p>Enter purpose</p>
          <input onChange={changeHandler} name='purpose' value={apiData.purpose} type="text" style={{ width: "100%" }}/>
        </div>
        <button onClick={clickHandler} className={styles.btn}>Pay</button>
      </div>
    </div>
  )
}

export default Pay
