import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams,Link } from "react-router-dom";
import "./ReportView.css";
import DayPlan from "./ViewDayPlan";
import axios from "axios";
import Swal from "sweetalert2";
import {DecryptED} from "../../DecryptJS/Decrypt"
import { FcDownload } from "react-icons/fc";
import { handleDownloadExcel } from "../ExportToExcel";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  headDiv: {
    // textAlign: "center",
    // margin: "15px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  home: {
    marginLeft: "24px",
    fontSize: "20px",
    color: "#0099FF",
    fontWeight: "bold",
    cursor: "pointer",
  },
  report: {
    marginRight: "24px",
    fontSize: "20px",
    color: "#0099FF",
    fontWeight: "bold",
    cursor: "pointer",
  },
  button: {
    color: "white",
    backgroundColor: "rgb(46, 175, 226)",
    padding: "6px 10px",
    fontSize: "18px",
    outline: "none",
    border: "none",
    borderRadius: "6px",
  },
}));

const WeeklyReportView = () => {
  const classes = useStyles();
  let { sid, userid, reportid } = useParams();
  sid = DecryptED(sid);
  userid = DecryptED(userid);
  const [weeklyData, setWeeklyData] = useState([]);
  const [lessonName, setLessonName] = useState("");
  const [classId, setClassId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [month, setMonth] = useState("");
  const [className, setClassName] = useState("");
  const [subject, setSubject] = useState("");

  const [exportData, setExportData] = useState({
    headers: [
      "Days", "Subject", "sub topic", "Class_Division","Lesson Name", "Period", "imp. notes", "class activity", "HomeWork", "accomplished", "if No reason"
    ],
    body: []
  }
  )

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!month || !lessonName) {
      Swal.fire("Please fill the required fields");
    } else {
      const data = {
        fun_name: "updateWeeklyLessonplanData",
        staff_id: userid,
        sid,
        class_id: classId,
        lesson_id: reportid,
        days: weeklyData,
      };
      const response = await axios.post(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data
      );
      if (response) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Plan has been updated successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };
  useEffect(() => {
    const getReportData = async () => {
      const data = {
        fun_name: "getWeeklyLessonPlanByID",
        staff_id: userid,
        sid,
        id: reportid,
      };
      const response = await axios.post(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data
      );
      if (response) {
        const obj = response.data.result;
        setLessonName(obj[0].lession_name);
        setMonth(obj[0].month);
        setClassName(obj[0].div_name);
        setSubject(obj[0].subject);
        setWeeklyData(obj);
        setClassId(obj[0].class_id);
        setSubjectId(obj[0].subject_id);
      }
    };
    getReportData();
  }, []);

  useEffect(() => {  
    const myArray = weeklyData.map(item => {
      return {
        Days: item.days,
        subject: item.subject,
        top_sub_top: item.top_sub_top,
        div_name: item.div_name,
        Lesson_Name: item.lession_name,
        Period: item.no_of_period,
        imp_notes: item.imp_note,
        class_activity: item.cls_activity,
        HomeWork: item.homework,
        accomplished: item.accomplished,
        if_No_reason: item.if_no_reas_with_comp_date
      }
    });
    setExportData({...exportData, body: myArray})
  }, [weeklyData])
  

  return (
    <div className="form-main">
      <div className={classes.headDiv}>
      <Link className={classes.home} to={`/weeklyHome/${sid}/${userid}`}>
          Home
        </Link>
        <h2 style={{ color: "Black", marginTop: "5px", fontSize: "24px", fontFamily:'monospace', fontWeight:'bold' }}>
          WEEKLY LESSON PLAN
        </h2>
        <Link className={classes.report} to={`/weeklyReport/${sid}/${userid}`}>Report</Link>

      </div>
      <form className="form">
        <div className="user-details">
          <div className="form-details">
            <div style={{ display: "flex" }}>
              <label htmlFor="class">Class: </label>
              <p style={{ marginLeft: "10px", fontSize:'18px',marginTop:'4px' }}>{className}</p>
            </div>
            <div>
              <label htmlFor="month">Month: </label>
              <input
                onChange={(e) => setMonth(e.target.value)}
                name="month"
                id="month"
                type="date"
                placeholder=""
                value={month}
              />
            </div>
          </div>
          <div className="form-subject-details">
            <div style={{ display: "flex" }}>
              <label htmlFor="class">Subject: </label>
              <p style={{ marginLeft: "10px", fontSize:'18px',marginTop:'4px' }}>{subject}</p>
            </div>
          </div>
          <div className="lesson-name">
            <label htmlFor="lessonName">Lesson Name: </label>
            <input
              value={lessonName}
              onChange={(e) => setLessonName(e.target.value)}
              id="lessonName"
              type="text"
              placeholder=""
            />
          </div>
          <div>
              <FcDownload size={28} cursor={"pointer"} onClick={()=>handleDownloadExcel(exportData.headers, exportData.body)}/>
            </div>
        </div>
        <div className="Accordion">
          {days.map((day, idx) => {
            return (
              <DayPlan
                day={day}
                key={idx}
                weeklyData={weeklyData}
                setWeeklyData={setWeeklyData}
              />
            );
          })}
        </div>
        <div className="btn-div">
          <button
            onClick={(e) => handleUpdate(e)}
            className={classes.button}
            variant="contained"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default WeeklyReportView;
