import React,{useState,useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import DashboardIcon from '@material-ui/icons/Dashboard';
import axios from 'axios';
import history from './history';
import OptionalForm from './OptionalForm';
import { GoogleLogin  } from 'react-google-login';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';  
import Login from './Login';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    //backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 'auto'),
    
  },
  desc: {
    [theme.breakpoints.up('sm')]: {
      marginLeft:'12%',
      marginRight:'12%'
    }
  },
  heroButtons: {
    marginTop: theme.spacing(3),
  },
  cardGrid: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
  },
  card: {
    height: '100%',
    
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop:   '46.25%', //'56.25%', // 16:9
    backgroundSize:'contain'
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  Googlebtn:{
    width: '165px',
    height:'35px',
    borderRadius: '4px',
    background: '#3b5998',
    color:'white',
    border:'0px transparent',
    textAlign: 'center',
    margin:'5px',
    display: 'inline-block',
   '&:hover':{
        background: '#3b5998',
        opacity: 0.6,
    }
  }
}));


export default function About(props) {
  var sortJsonArray = require('sort-json-array');

  const classes = useStyles();
  const [result, setRes]= useState({});
  useEffect(()  =>{
    // if(props.location.data==undefined){
    //   //window.alert('You are not logged in')
    //   history.push({pathname:"/alumni/login/"+props.match.params.sid})
    // }
   // window.alert(JSON.stringify(props.location.data))
   
    // console.log(props.location.data);
    // window.addEventListener('beforeunload', (event) => {
      
    //     event.returnValue = 'There is pending work. Sure you want to leave?';
      
    // });
    async function fetchD(){
     const res= await axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"AlumniAboutUsMain",['sid']:props.sid})
     .then(res=> {
        sortJsonArray(res.data.alumini_about,'id')
         setRes(res)}).catch(err =>console.log("Error-"+err));
         
 }
   fetchD();
   
},[])

//const Dash=()=>{s={hd:"dash",data:props.location.data.data,sid:props.match.params.sid} ; history.push({pathname:"/alumni/Dashboard/"+props.match.params.sid,data:s})};
//const Notif=()=>{s={hd:"Latest Notifications",data:props.location.data.data,sid:props.match.params.sid} ; history.push({pathname:"/alumni/AlumniHome/"+props.match.params.sid,data:s})};
  return (
    result.data!=null || result==null?<>
    <div>
           <CssBaseline />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="sm" style={{maxWidth:500}}> 
            {/* <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
              About Us
            </Typography> */}
  <Typography variant="h5" align="center" color="textPrimary" paragraph><strong>{result.data.alumini_about[0].title}</strong></Typography>
  <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={result.data.alumini_about[0].photo}
                    title="Image title"
                  />
                  
                  </Card><br/></Container>
                  <Typography className={classes.desc} style={{justifyContent:'center',marginLeft:30}}><a href={result.data.alumini_about[0].website} target="_blank">{result.data.alumini_about[0].website}</a></Typography> 
            <Typography variant="h6" align="center" color="textSecondary" paragraph className={classes.desc}> 
              {result.data.alumini_about[0].description}
            </Typography>
          
          
        </div>
       
      </main></div></>:<><div className="d-flex justify-content-center">
            <div className="loader border-top-info margin-top-1" />
          </div></>
  );
}