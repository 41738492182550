import React, { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import moment from "moment";
import { animateScroll } from "react-scroll";
import ImageClick from "./ImageClick";
// import { Link } from 'react-router-dom';
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { saveAs } from "file-saver";
import InfiniteScroll from "react-infinite-scroll-component";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {DecryptED} from "../DecryptJS/Decrypt"
var sortJsonArray = require("sort-json-array");

// console.log = console.warn = console.error = () => {};
const useStyles = makeStyles((theme) => ({
  container: {
    //marginTop:5,
    bottom: 0,
    //backgroundColor:"#E9F1E6",
    backgroundColor: "#e6dcdc59",
    height: "85vh",
    overflow: "auto",
    overflowY: "scroll",
    // marginBottom: "7vh",
    paddingBottom: "20px",
    // position: "fixed" // remove this so we can apply flex design
  },
  bubbleContainer: {
    width: "90%",
    display: "flex",
    justifyContent: "flex-start",
    overflowWrap: "anywhere",
  },
  bubble: {
    border: "0.5px solid black",
    borderRadius: "15px",
    margin: "5px",
    padding: "10px",
    display: "inline-block",
    backgroundColor: "#c5da86",
    //"#a2cf6e",
  },
  bubbleContainer1: {
    width: "90%",
    // display: "flex",
    float: "right",
    justifyContent: "flex-end",
    overflowWrap: "anywhere",
  },
  bubble1: {
    border: "0.5px solid black",
    borderRadius: "15px",
    margin: "5px",
    padding: "10px",
    display: "inline-block",
    float: "right",
    backgroundColor: "#add8e6 ",
    //#82ccdd",
    //color:'white',
  },
  Avatar: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  var txt = text.replace(urlRegex, function(url) {
    return '<a href="' + url + '"target="_blank">' + url + "</a>";
  });
  return txt;
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

export default function ChatLayout(props) {
  const classes = useStyles();
  const [dataa, setProfileState] = React.useState(props);
  const [Iclick, setIModel] = React.useState(false);

  const [selectedTodo, setSelectedTodo] = React.useState(null);

  const handleTodoClick = (id) => {
    console.log(id)
    if (selectedTodo === id) {
      setSelectedTodo(null);
    } else {
      setSelectedTodo(id);
      // scrollToBottom();
    }
  };

  var dates = [];

  useEffect(() => {
    setProfileState(props);

    //  scrollToBottom();
    // props.setScrolloff();

    // scrollToBottom()
  }, [props]);
  useEffect(() => {
    scrollToBottom();
    props.setScrolloff();
  }, []);

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: "chat",
      duration: 0,
    });
  };
  const AddDates = (d1) => {
    let da = d1.substr(0, d1.indexOf(":"));
    //  console.warn('date-'+dates);
    dates.push(da);
    //dates.push(moment(da).format('LL'));
  };
  const [imgprv, setImgPrv] = React.useState(null);
  const SaveFile = (file) => {
    let fname = file.substr(file.lastIndexOf("-") + 1);
    saveAs(file, fname);
  };
  const handleClose = () => {
    setIModel(false);
  };
  const setImagePrv = (i) => {
    setImgPrv(i);
    setIModel(true);
  };
  const getData = () => {
    console.log("next data");
  };
  const capitalize = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };
  const Sdata = sortJsonArray(dataa.C.data.forumchatdetails, "time", "asc");

  const hadleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform the deletion here
        DeleteMessage(id);
      }
    });
  };
  const DeleteMessage = async (id) => {
    try {
      // Input validation
      if (!id) {
        throw new Error("Invalid id");
      }
      if (!props.sid) {
        throw new Error("Invalid sid");
      }

      props.setMsgDeleting(true);

      const data = {
        sid: DecryptED(props.sid),
        id: id,
      };

      // Retry mechanism
      let retries = 3;
      while (retries > 0) {
        try {
          const response = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_BASE_URL}/api/ForumApp/chat/delete`,
            data,
            headers: {
              Authorization: "#$laksdfnlknoea#$@$%^&%$",
            },
          });

          if (
            response.data.success &&
            response.data.data === "Message Deleted"
          ) {
            Sdata.find((element) => element.id === id).MSG_DELETED = "1";
            props.setMsgDeleting(false);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            //  scrollToBottom();
          } else {
            // handle unsuccessful deletion here
          }
          break;
        } catch (error) {
          if (retries === 1) {
            throw new Error("Failed to delete message");
          }
          retries--;
        }
      }
    } catch (error) {
      Swal.fire("Error!", error.message, "error");
    }
  };

  const chatBubbles =
    (dataa.C != null && dataa.C.data != undefined && Sdata != undefined) ||
    Sdata != null
      ? Sdata.map((obj, i = 0) =>
          obj.groupid == props.Gid && obj.subgroupid == props.sub ? (
            <div key={i}>
              {dates.includes(
                obj.time.substr(0, obj.time.indexOf(":"))
              ) ? null : (
                <Typography style={{ marginLeft: "30vw" }} key={i}>
                  {moment(obj.time.substr(0, obj.time.indexOf(" "))).format(
                    "LL"
                  )}
                </Typography>
              )}
              {/* { console.info(urlify(obj.msg))}  */}
              <>
                {obj.userid != props.uid ? (
                  <>
                    {AddDates(obj.time)}
                    <div
                      className={classes.bubbleContainer}
                      key={i}
                    
                    >
                      {/* <Avatar style={{display:'flex'}} alt="A" src="" /> */}
                      <div key={i++} className={classes.bubble}>
                        <div>
                          <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ fontWeight: "bold" }}
                          >
                            {capitalize(obj.name)}
                          </Typography>
                        </div>
                        {obj.file.length > 0 ? (
                          <>
                            {" "}
                            {obj.file.substr(obj.file.lastIndexOf(".") + 1) ==
                              "png" ||
                            obj.file.substr(obj.file.lastIndexOf(".") + 1) ==
                              "jpg" ? (
                              <>
                                <Button onClick={() => setImagePrv(obj.file)}>
                                  <img
                                    style={{
                                      float: "right",
                                      objectFit: "contain",
                                      maxWidth: "100%",
                                      maxHeight: "70vw",
                                    }}
                                    src={obj.file}
                                  />
                                </Button>
                              </>
                            ) : obj.file.substr(
                                obj.file.lastIndexOf(".") + 1
                              ) != "png" ||
                              obj.file.substr(obj.file.lastIndexOf(".") + 1) !=
                                "jpg" ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <InsertDriveFileIcon
                                    color="primary"
                                    style={{ height: 40, width: 40 }}
                                  />
                                  <Typography style={{ fontWeight: 40 }}>
                                    {" "}
                                    {obj.file.substr(
                                      obj.file.lastIndexOf("/") + 1
                                    )}
                                  </Typography>
                                  <IconButton
                                    onClick={() => SaveFile(obj.file)}
                                  >
                                    <PlayForWorkIcon
                                      color="primary"
                                      style={{ height: 30, width: 30 }}
                                    />
                                  </IconButton>
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : null}
                        <div>
                          {/* <Typography style={{whiteSpace: 'pre-line'}} > {obj.msg}</Typography> */}
                        </div>
                        <Typography
                          style={{ whiteSpace: "pre-line" }}
                          dangerouslySetInnerHTML={{
                            __html: urlify(
                              obj.MSG_DELETED === "1" ? "" : obj.msg
                            ),
                          }}
                        />
                        {obj.MSG_DELETED === "1" ? (
                          <>
                            <i>This message was deleted </i>
                          </>
                        ) : (
                          ""
                        )}
                    
                        <div className="w-100 d-flex justify-content-between">
                          <Typography variant="subtitle2" color="textSecondary">
                            {moment(obj.time.substr(obj.time.indexOf(" ")), [
                              "HH.mm",
                            ]).format("hh:mm a")}
                          </Typography>
                          {obj.msg.length > 0 && obj.MSG_DELETED != "1" ? (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => handleTodoClick(`msg${i}`)}
                            >
                              <MoreHorizIcon />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {selectedTodo === `msg${i}` &&
                        obj.MSG_DELETED !== "1"  ? (
                          <div className="mt-4 w-100">
                            <hr className="m-0" />
                            <CopyToClipboard
                              text={obj.msg}
                              onCopy={() =>
                                Swal.fire("", "Text Copied", "success")
                              }
                              className="float-right"
                            >
                              <span style={{cursor:'pointer'}}>
                                <FileCopyIcon className=" text-primary" />
                              </span>
                            </CopyToClipboard>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : obj.userid == props.uid ? (
                  <>
                    <div className={classes.bubbleContainer1} key={i}>
                      {AddDates(obj.time)}
                      {/* {console.warn("meg-->"+obj.msg)} */}
                      <div
                        key={i++}
                        className={classes.bubble1}
                        style={{ minWidth: "200px" }}
                      >
                        {obj.file.length > 0 ? (
                          <>
                            {" "}
                            {obj.file.substr(obj.file.lastIndexOf(".") + 1) ==
                              "png" ||
                            obj.file.substr(obj.file.lastIndexOf(".") + 1) ==
                              "jpg" ? (
                              <>
                               {obj.MSG_DELETED === "0" && <Button onClick={() => setImagePrv(obj.file)}>
                                  <img
                                    style={{
                                      float: "right",
                                      objectFit: "contain",
                                      maxWidth: "100%",
                                      maxHeight: "70vw",
                                    }}
                                    src={obj.file}
                                  />
                                </Button>
                              }
                              </>
                            ) : obj.file.substr(
                                obj.file.lastIndexOf(".") + 1
                              ) != "png" ||
                              obj.file.substr(obj.file.lastIndexOf(".") + 1) !=
                                "jpg" ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <InsertDriveFileIcon
                                    color="primary"
                                    style={{ height: 40, width: 40 }}
                                  />
                                  <Typography style={{ fontWeight: 40 }}>
                                    {" "}
                                    {obj.file.substr(
                                      obj.file.lastIndexOf("/") + 1
                                    )}
                                  </Typography>
                                  <IconButton
                                    onClick={() => SaveFile(obj.file)}
                                  >
                                    <PlayForWorkIcon
                                      color="primary"
                                      style={{ height: 30, width: 30 }}
                                    />
                                  </IconButton>
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : null}
                        {/* {obj.file!=null?<>{ console.log("Filename-"+obj.file.substr(obj.file.lastIndexOf('/')+1))}

                        <img style={{  float:'right',objectFit:'cover',width:'100%',maxHeight:400}} src={obj.file}></img>

                        </> :null}       */}
                        <div style={{ whiteSpace: "pre-line" }}>
                          {/* <Typography style={{whiteSpace: 'pre-line'}}> {urlify(obj.msg) }</Typography> */}
                          <Typography
                            style={{ whiteSpace: "pre-line" }}
                            dangerouslySetInnerHTML={{
                              __html: urlify(
                                obj.MSG_DELETED === "1" ? "" : obj.msg
                              ),
                            }}
                          />
                          {obj.MSG_DELETED === "1" ? (
                            <>
                              <i>This message was deleted</i>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="w-100 d-flex justify-content-between">
                          <Typography variant="subtitle2" color="textSecondary">
                            {moment(obj.time.substr(obj.time.indexOf(" ")), [
                              "HH.mm",
                            ]).format("hh:mm a")}
                          </Typography>
                          {obj.MSG_DELETED != "1" ? (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => handleTodoClick(`msg${i}`)}
                            >
                              <MoreHorizIcon />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {selectedTodo === `msg${i}` &&
                        obj.MSG_DELETED != "1" ? (
                          <div className="mt-4 w-100">
                            <hr className="m-0" />
                            <DeleteIcon
                              className=" text-danger float-right ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => hadleDelete(obj.id)}
                            />

                          { obj.msg.length > 0 && <CopyToClipboard
                              text={obj.msg}
                              onCopy={() =>
                                Swal.fire("", "Text Copied", "success")
                              }
                              className="float-right ms-2"
                            >
                              <span style={{cursor: 'pointer'}}>
                                <FileCopyIcon className=" text-primary" />
                              </span>
                            </CopyToClipboard>}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
              </>{" "}
            </div>
          ) : null
        )
      : null;
  return (
    <>
      {" "}
      <div className={classes.container} id="chat">
        {/* {console.log("C->"+Sdata.length)} */}
        <InfiniteScroll
          dataLength={dataa.C.data.forumchatdetails.length}
          refreshFunction={() => props.scrolledup()}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <h3 style={{ textAlign: "center" }}>
              &#8595; Pull down to load more
            </h3>
          }
          releaseToRefreshContent={
            <h3 style={{ textAlign: "center" }}>
              &#8593; Release to load more
            </h3>
          }
        >
          {chatBubbles}
        </InfiniteScroll>
        {Iclick == true ? (
          <ImageClick img={imgprv} close={handleClose} isopen={Iclick} />
        ) : null}
      </div>
    </>
  );
}
