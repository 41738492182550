import React, { useState } from "react";
import { useEffect, useRef } from "react";
import axios from "axios";
import "../css/ParentForm.css";
import Swal from "sweetalert2";
import { useLocation, useParams, Link } from "react-router-dom";

const StudentInfo = () => {
  const defaultUrl = "https://commonfiles.prisms.in/commonfiles/avatar.jpg";

  const ref = useRef(null);
  const { state } = useLocation();
  const { sid, userid } = useParams();
  const acadYear = state.acadYear;
  const schoolName = state.schoolName;
  const [title] = useState(state.txtTitle2);
  const [title2] = useState(state.txtTitle);

  const [values] = useState([state.studentValues]);
  const [studentVariables] = useState(state.studentVariables);
  const [tcolumn, setColumn] = useState([]);
  const [inputFiled, setInputFiled] = useState([]);
  const [Data, setData] = useState({});
  const [nature, setNature] = useState("");
  const [storeValueArr, setStoreValueArr] = useState([]);
  const [render, setRender] = useState(false);
  const [imageInfo, setImageInfo] = useState({
    file: [],
    filepreview: null,
  });

  // image width and height change
  const handleImageChange = (event) => {
    let reader = new FileReader();
    const imageFile = event.target.files[0];
    const imageFilname = event.target.files[0].name;

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        //------------- Resize img code ----------------------------------
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var MAX_WIDTH = 700;
        var MAX_HEIGHT = 700;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob(
          (blob) => {
            const file = new File([blob], imageFilname, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            setImageInfo({
              ...imageInfo,
              file: file,
              filepreview: URL.createObjectURL(imageFile),
            });
          },
          "image/jpeg",
          1
        );
      };
      //debugger
      img.src = e.target.result;
    };
    reader.readAsDataURL(imageFile);
  };

  // set column as key value pair
  const handleChange = (e, idx) => {
    let key = tcolumn[idx];
    let value = e.target.value;

    setData((state) => ({
      ...state,
      [key]: value,
    }));
  };

  //update Data
  const updateData = async (e) => {
    const data = {
      sid,
      user_id: userid,
      tablename: studentVariables,
      columns: Data,
    };
    const response = await axios({
      method: "POST",
      url: "https://marcopolo.prisms.in/api/parentinfo/update",
      data,
      headers: {
        Authorization: "#$laksdfnlknoea#$@$%^&%$",
      },
    })
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `Form updated successfully!`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
        });
      });
  };

  // image upload
  const handleImageSubmit = async (e, pictype) => {
    e.preventDefault();
    let bucketName = `topschool-attachments/${schoolName}prisms/${acadYear}/studentUpload/${pictype.toLowerCase()}picture`;
    let formData = new FormData();
    formData.append("sid", sid);
    formData.append("user_id", userid);
    formData.append("tablename", studentVariables);
    formData.append("bucketName", bucketName);
    formData.append("profile", `${pictype.toLowerCase()}`);
    // formData.append("image", image);
    formData.append("image", imageInfo.file);
    const response = await axios({
      method: "POST",
      url: "https://marcopolo.prisms.in/api/parentinfo/changepic",
      data: formData,
      headers: {
        Authorization: "#$laksdfnlknoea#$@$%^&%$",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `Image updated successfully!`,
          showConfirmButton: false,
          timer: 1500,
        });
        // window.location.reload();
        setRender(!render);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
        });
      });
  };
  useEffect(() => {
    const lable = [];
    const column = [];
    const inputType = [];
    const Nature = [];

    const getData = async () => {
      const data = {
        sid,
        user_id: userid,
        tablename: studentVariables,
        data: column,
      };
      const result = await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/parentinfo/data",
        data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      });
      if (result) {
        console.log(result.data);
        setStoreValueArr(Object.values(result.data[0]));
      }
    };

    values[0]
      .split("|")[0]
      .split(",")
      .map((data) => {
        return column.push(data);
      });
    setColumn(column);

    values[0]
      .split("|")[1]
      .split(",")
      .map((data) => {
        return lable.push(data);
      });
    setInputFiled(lable);

    values[0]
      .split("|")[2]
      .split(",")
      .map((data) => {
        return Nature.push(data);
      });
    setNature(Nature);

    getData();
  }, [render]);

  return (
    <div className="mainDiv" style={{ maxWidth: "800px" }}>
      <p className="heading">Information Update Form</p>
      <div className="mtables">
        {title2 ? (
          <Link to={`/parentForm/${sid}/${userid}`} className="next_subheading">
            {title2}
          </Link>
        ) : null}
        {title ? <span className="next_subheading">{title}</span> : null}
      </div>
      {inputFiled.map((value, idx) => {
        if (nature[idx] === "text") {
          return (
            <div className="resultDiv" key={idx}>
              <div className="labelDiv">
                <label className="labelStyle" htmlFor={tcolumn[idx]}>
                  {value}
                </label>
              </div>
              <div className="inputDiv">
                <input
                  className="inputStyle"
                  value={Data[idx]}
                  placeholder={storeValueArr[idx]}
                  name={tcolumn[idx]}
                  onChange={(e) => handleChange(e, idx)}
                />
              </div>
            </div>
          );
        } else if (nature[idx] === "info") {
          return (
            <div style={{ textAlign: "start", margin: "5px 0" }}>
              <div ref={ref}>
                <a
                  className="link__tag"
                  target="_blank"
                  href={value.match(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"/)[1]}
                  id="myid"
                >
                  {value.replace(/<\/?a[^>]*>/g, "")}
                </a>
              </div>
            </div>
          );
        } else if (nature[idx] === "img") {
          return (
            <div className="resultImageDiv" key={idx}>
              <div className="labelDiv">
                <label className="labelStyle" htmlFor={tcolumn[idx]}>
                  {value}
                </label>
              </div>
              <div className="inputDiv">
                <img
                  className="inputImageStyle"
                  src={storeValueArr[idx] ? storeValueArr[idx] : defaultUrl}
                  // src={storeValueArr[idx]}
                  alt="profilePhoto"
                  // alt={storeValueArr[idx]}
                />
                <div className="uploadDiv">
                  <form
                    className="input__form"
                    onSubmit={(e) => handleImageSubmit(e, tcolumn[idx])}
                  >
                    <input
                      className="uploadInputStyle"
                      type="file"
                      name="filename"
                      id="myFile"
                      onChange={(e) => handleImageChange(e)}
                    />
                    <input
                      // onClick={(e) => handleImageSubmit(e, value.split(" ")[0])}
                      value="Upload"
                      className="uploadBtn"
                      type="submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          );
        }
      })}
      <div className="buttonDiv">
        <button className="button" onClick={(e) => updateData(e)}>
          Update
        </button>
      </div>
    </div>
  );
};

export default StudentInfo;
