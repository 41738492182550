import React from "react";
import styled from "styled-components";
import moment from "moment";

const Report = ({ adminReport }) => {
  return (
    <>
      <Container>
        <Wrapper>
          {adminReport.map((data, index) => {
            const {
              fname,
              lname,
              std,
              division,
              sname,
              slname,
              field_name,
              value,
              month,
            } = data;
            return (
              <div className="main_box" key={index}>
                <SCLASS>
                  <div className="student__name">
                    <span className="__st">Student Name</span>
                    <span className="st__1">
                      {sname} {slname}
                    </span>
                  </div>

                  <div className="class__div">
                    <span className="__ct">Class</span>

                    <span>
                      {std}-{division}
                    </span>
                  </div>
                </SCLASS>
                <div className="box">
                  <div className="fields">
                    <span>
                      {field_name.split(",").map((item) => (
                        <>
                          {" "}
                          {item} <br />{" "}
                        </>
                      ))}
                    </span>
                  </div>

                  <div className="values__1">
                    <span>
                      {value.split(",").map((item) => (
                        <>
                          {" "}
                          {item} <br />{" "}
                        </>
                      ))}
                    </span>
                  </div>
                </div>

                <div className="box__2">
                  <span className="teacher__commits">Commented By</span>
                  <div className="teacher_date">
                    <span className="teacher_name">
                      {fname} {lname}
                    </span>
                    <span className="date_sec">
                      {moment(month).format("DD-MM-YYYY")}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </Wrapper>
      </Container>
    </>
  );
};

export default Report;

const Container = styled.div``;

const Wrapper = styled.div`
  border-radius: 4px;
  padding: 10px 15px;
  @media screen and (max-width: 768px) {
    padding: 10px 5px;
    margin: 10px 0;
    font-family: sans-serif;
  }

  .main_box {
    border-radius: 4px;
    /* background-color: #eef2f4; */
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 8px;
    &:hover {
      background-color: #dceaf3;
      color: #111;
    }
  }
  .box {
    width: 100%;
    height: auto;
    display: flex;
  }
  .fields {
    text-align: start;
    color: rgb(75, 130, 230);
    font-size: 1rem;
    max-width: 177px;
    width: 100%;
  }

  .values__1 {
    text-align: start;
    color: #3d3939;
    font-size: 1rem;
    text-transform: capitalize;
    margin-left: 95px;
  }

  .comment {
    margin: 0px;
  }

  .teacher__commits {
    font-size: 1rem;
    color: #eb317f;
    font-weight: 600;
  }

  .teacher_name {
    font-size: 0.9rem;
  }

  .teacher_date {
    display: flex;
    justify-content: space-between;
  }
  .date_sec {
    color: #eb317f;
  }
`;

const SCLASS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .__st {
    color: rgb(75, 130, 230);
    font-weight: 600;
  }
  .st__1 {
    margin-left: 20px;
  }
  .__ct {
    margin-right: 10px;
    color: rgb(75, 130, 230);
    font-weight: 600;

  }
`;

