import React, { PureComponent, useEffect } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer,
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    histogram:{
        height: '55vh',
        [theme.breakpoints.up('sm')]: { 
            height: '50vh',
        }
        // width: '90vw',
        // ba: 'blue',
        // background: 'blue'
      }
}))


export default function Barchart(props) { 
    useEffect(()=>{
        console.log(props.sub+'sss')
    },[])
    const classes = useStyles();
//   static jsfiddleUrl = 'https://jsfiddle.net/alidingling/q4eonc12/';
    return (
      <div className={classes.histogram}>
         {props.alert?<Alert style={{marginBottom:0,zIndex:10,position: 'fixed', width:'98vw'   }} severity="info"  onClose={()=>props.Close()}>
        <AlertTitle>Info</AlertTitle>
        Above score/graph is based upon AI calculation on data entered. This is an indicative performance. The score/graph can change in the future. Please do not consider these as official result. School or Prisms will not be held responsible for the graph and comment displayed.
      </Alert>:null}
      <ResponsiveContainer width="100%" minHeight={150} height='100%'>
       
         <BarChart
        layout='vertical'
        
          // width={400}
          // height={300}
          data={props.data}
          margin={{
            top: 10, right: 30, left: 25, bottom: 5,
          }}
        >
          {/* <CartesianGrid   strokeDasharray="5 5" /> */}
          <XAxis  type="number"  domain={[0, 5]} tickCount={6}/>
          <YAxis  dataKey="academics" type='category' />
          <Tooltip />
          <Legend />
          <Bar barSize={20} name={'score'} dataKey={'score'} stackId="a" fill="#8884d8" />
          {/* <Bar dataKey="uv" stackId="a" fill="#82ca9d" /> */}
        </BarChart>
        </ResponsiveContainer>
        </div>
    );
  
}
