import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar,Paper} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DetailsForm from './DetailsForm';
import Report from './Report';
import axios from 'axios';
import Conform from './Conform';
import Proof from './Proof';
import { useParams } from 'react-router-dom';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div 
    role="tabpanel"
    hidden={value !== index}
    id={`wrapped-tabpanel-${index}`}
    aria-labelledby={`wrapped-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);
}

TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.any.isRequired,
value: PropTypes.any.isRequired,
};

function a11yProps(index) {
return {
  id: `wrapped-tab-${index}`,
  'aria-controls': `wrapped-tabpanel-${index}`,
};
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
}));

export default function Navbar() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [divlist, setDivlist]=React.useState(null);
  const [classnm, setClassnm]=React.useState(null);
 
const {sid}=useParams() ;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
useEffect(()=>{
    
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'GetClassDiv',['sid']:sid}).then (res=> {
  
setDivlist(res.data.div);
setClassnm(res.data.classnm);
});
},[])

  return (
   <div className={classes.root}>
      {/* <AppBar position="static" color="default" class="d-flex input-group w-auto"> */}
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          centered
        >
          <Tab label="Data" {...a11yProps(0)}wrapped />
          {/* <Tab label="Conform Submitted Data" {...a11yProps(1)} wrapped /> */}
          <Tab label="Final Proof Reading" {...a11yProps(1)} wrapped />
          <Tab label="Report" {...a11yProps(2)}wrapped />
        </Tabs>
        </Paper>
      <TabPanel value={value} index={0}>
        <DetailsForm divlist={divlist} classnm={classnm} />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
      <Conform divlist={divlist} classnm={classnm} />
      </TabPanel> */}
      <TabPanel value={value} index={1}>
        <Proof divlist={divlist} classnm={classnm} />
        </TabPanel>
      <TabPanel value={value} index={2}>
        <Report  />
      </TabPanel>
    </div>
  );
}
