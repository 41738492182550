import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function PreviousO(props) {
  const classes = useStyles();
  const capitalize=(input)=> {  
    return input.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }  
  return (
    
    
    <List className={classes.root}>

        <ListItem>
          <ListItemText primary={<Typography style={{justifyContent:'center',marginLeft:'30vw',display:'inline-block'}}>Order History</Typography>}></ListItemText>
        </ListItem>{
        props.data.map((v,i)=>(
          <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="User" src={props.avatar} />
        </ListItemAvatar>
        <ListItemText
          primary={capitalize(v.prodname)}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
               <p>
               Order id :{v.orderid}<br/>
               Status: {v.order_status} <br/>
               Quantity : {v.quantity}<br/>
               Price : &#x20B9;{v.tt_sale_price}<br/>
               Order On: {moment(v.created_date, ["YYYY-MM-DD hh:mm:ss"]).format("LLL")} <br/>
               Payment :{v.paymode}</p>
              </Typography>
            
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" /></>))}
      
    </List>
  );
}
