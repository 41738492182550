import React, { useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import history from './history';
import axios from 'axios';
import sha512 from 'js-sha512'


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Reg(props) {
  const classes = useStyles();
  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [mob, setMob] = React.useState(null);
  const [pass, setPass] = React.useState(null);
  const { sid } = useParams();
  const [contact, setcontact] = useState(mob)
  const [password, setpassword] = useState(pass)

  // State to send in easebuzz 
  const [state, setstate] = useState({
    'key':  "90G5UH6NVL",
    'txnid': "isfdfdfdgdfsiweuf",
    'amount': 100.00,
    'email': "sh@gmail.com",
    'phone': "9876543210",
    'firstname': "data.name",
    'udf1': "data.udf",
    'udf2': "data.udf",
    'udf3': "data.udf",
    'udf4': "data.udf",
    'udf5': "data.udf",
    'hash': "",
    'productinfo': "laptop",
    'udf6': "data.udf",
    'udf7': "data.udf",
    'udf8': "data.udf",
    'udf9': "data.udf",
    'udf10': "data.udf",
    'furl': 'http://localhost:3000/ww',
    'surl':'http://localhost:3000/response'
})

// funtion for genterating hash to send in easebuzz

async function generateHash() {

  var hashstring = "90G5UH6NVL" + "|" + state.txnid + "|" + state.amount + "|" + state.productinfo + "|" + state.name + "|" + state.email +
    "|" + state.udf1 + "|" + state.udf2 + "|" + state.udf3 + "|" + state.udf4 + "|" + state.udf5 + "|" + state.udf6 + "|" + state.udf7 + "|" + state.udf8 + "|" + state.udf9 + "|" + state.udf10 + "|GGPV1GL21V";
  let hash = await sha512.sha512(hashstring)

  return (hash);
}
useEffect(() => {
    generateHash().then(res=>{
      setstate({
        ...state,
        hash:res
      })
  })
}, [state]);


// api call for easebuzz
async function postData(url = '', data = {state}) {

  const response = await fetch(url, {
    method: 'POST', 
    mode: 'no-cors', 
    cache: 'no-cache', 
    credentials: 'same-origin', 
    headers: {
      'Content-Type': 'application/json'
     
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer', 
    body: JSON.stringify(data) 
  });
  return response;
}
// button click to call api
const clickHandler =()=>{
postData('https://testpay.easebuzz.in/payment/initiateLink', { state })
  .then(data => {
    console.log(data); 
  });}



  // const [data, setData] = useState({
  //   "name": name,
  //   "email": email,
  //   "contact": mob,
  //   "password": pass,
  // })



  //   axios.post("http://localhost:8080/api/tscommon/registerUser", { name, email, contact: mob, password: pass }, {
  //     headers: { authorization: "#$laksdfnlknoea#$@$%^&%$" }
  //   }
  //   ).then(console.log("done"))
  //     .then((res) => console.log(res))
  //     .catch(err => console.log(err))

  const Reg = () => {
    // const data= JSON.parse(resp);
    if (email != null && name != null && pass != null) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email) && /^\d{10}$/.test(mob)) {
        axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?", { ['fun_name']: 'AlumniReg', ['sid']: sid, ['email']: email, ['pass']: pass, ['name']: name, ['mobile']: mob }).then((resp) => {
          if (resp.data.isReg == 1) {
            Swal.fire(
              "Success",
              "Successfully Registered",
              'success'
            )
            props.lg();
          }
          if (resp.data.isReg == 2) {
            Swal.fire(
              "Already Registered",
              "The Mobile number/Email provided is Already Registered",
              'info'
            )
          }

        })
      }
      else {
        Swal.fire(
          "Alert",
          "Email or Mobile is Not Correct please check !",
          'warning'
        )
      }
      var s = "About";
      // console.log(resp);
      //history.push({pathname:"/about/"+props.match.params.sid,data:s})
    }
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <Typography component="h1" variant="h5">
          Alumni App
        </Typography>
        <div className={classes.form} >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Full Name"
            name="name"
            value={name}
            onChange={(e) => { setName(e.target.value) }}
            autoComplete="name"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="mob"
            label="Mobile No"
            name="nob"
            value={mob}
            onChange={(e) => { setMob(e.target.value) }}
            autoComplete="mob"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            onChange={(e) => { setEmail(e.target.value) }}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={pass}
            onChange={(e) => { setPass(e.target.value) }}
            autoComplete="current-password"
          />

          <Button
            onClick={() => Reg()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Register
          </Button>
          <Link component="button" variant="body2"
            onClick={() => {
              props.lg();
            }}
          >
            Already Registered? Login
          </Link>
        </div>
      </div>
    </Container>
  );
}