import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {FormLabel,FormControl,Radio,RadioGroup,Select,MenuItem,InputLabel} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import SchoolIcon from '@material-ui/icons/School';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import { GoogleLogin } from 'react-google-login';
import AchiveImageUp from './AchiveImageUp';
import history from './history';
import axios from 'axios';
import Alert from './alert';
import Swal from 'sweetalert2'
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
function daysInMonth(yer,month) {
  if(yer!='' && month!='' ){
  var days=Array.from(Array(moment(yer+'-'+month).daysInMonth()), (_, i) => i + 1)
  return days;
  }
  else return [];
}
export default function SignIn(props) {
  const classes = useStyles();
  

  var udata=JSON.parse(localStorage.getItem('data'));
  var school=JSON.parse(localStorage.getItem('Schoollist'));
  var auth=JSON.parse(localStorage.getItem('auth'));
  //const useForceUpdate = () => React.useState()[1];
// var mysname= school.filter((val) => val==udata.data[0].school_id);
  const [udata1,setUdata]=React.useState(JSON.parse(localStorage.getItem('data')));

  //console.log(auth[0].name);
  const [usid,setUsid]=React.useState(udata!=null?''+udata.data[0].userid:null);
  const [Name,setName]=React.useState(udata!=null?''+udata.data[0].full_name:auth!=undefined?auth[0].name:'');
  const [isName,setDName]=React.useState(Name!=''?true:false);
  const [city,setCity]=React.useState(udata!=null ?''+udata.data[0].city:'');
  const [iscity,setDcity]=React.useState(city!=''?true:false);  
  const [curr_comp,setCompany]=React.useState(udata!=null ?''+udata.data[0].current_company:'');
  const [iscurr,setDcurr]=React.useState(curr_comp!=''?true:false);  
  const [email,setEmail] = React.useState(udata!=null?''+udata.data[0].emailid:auth!=undefined?auth[0].email:'');
  const [isEmail,setDemail]=React.useState(email!=''?true:false);  
  const [state,setState] = React.useState(udata!=null?''+udata.data[0].state:'');
  const [isstate,setDstate]=React.useState(state!=''>0?true:false);  
  const [country,setCountry]=React.useState(udata!=null?''+udata.data[0].country:'');  
  // const [iscountry,setDCountry]=React.useState(country.length>0?true:false);  
  const [comm,setComm] = React.useState(udata!=null?''+udata.data[0].communication_address:'');
  const [iscomm,setDcomm]=React.useState(comm.length>0?true:false); 
  const [sname,setSname] = React.useState(null);
  const [yop,setYop] = React.useState(udata!=null?''+udata.data[0].passout_batch:'');
  // const [isyop,setDyop]=React.useState(yop.length>0?true:false);
  const [mop,setMop] = React.useState(udata!=null?''+udata.data[0].passout_month:'');
  // const [ismop,setDMop]=React.useState(mop.length>0?true:false);

  const [mob,setMob] = React.useState(udata!=null?''+udata.data[0].contact:'');
   const [Amob,setAltMob]=React.useState(udata!=null?''+udata.data[0].contact2:'');
  const [occu,setOccu] = React.useState(udata!=null?''+udata.data[0].occupation:'');
  const [Wp,setWp] = React.useState(udata!=null ?''+udata.data[0].workplace:'');
  const [status,setStatus] = React.useState(udata!=null?''+udata.data[0].current_status:'');
  const [isstatus,setDStatus]=React.useState(status!=''?true:false);
  const [photo,setPhoto] = React.useState(null);
  const [photolink,setPhotolink] = React.useState(udata!=null?''+udata.data[0].photo:'');
   const [isphoto,setDphoto]=React.useState(photolink!=''?true:false);
  const [dob,setDob] = React.useState(udata!=null?''+udata.data[0].dob:'');
   const [isdob,setDdob]=React.useState(dob!=''?true:false);
  const [value, setValue] = React.useState(udata!=null?''+udata.data[0].gender:'');
  const [staffStu, setStaff] = React.useState(udata!=null?''+udata.data[0].alumni_type:'');
   const [DStaff,setDstaff]=React.useState(staffStu!=''?true:false);  
  const [isStaff,setIsStaff]=React.useState(false);
   const [isSubmitUpdate,setData]=React.useState(JSON.parse(localStorage.getItem('data')));
  const [join,setJoining]=React.useState(udata!=null?''+udata.data[0].year_of_joining:'');
  const [schoolname,setSchoolname]=React.useState(udata!=null?''+udata.data[0].school_id:'');
  const [leaving,setLeaving]=React.useState(udata!=null?''+udata.data[0].year_of_leaving:'');
  const [isleaving,setDleaving]=React.useState(leaving.length>0?true:false);  
  const [desig,setDesig] = React.useState(udata!=null?''+udata.data[0].designation:'');
  const [schoollist,setSchoollist]=React.useState(school!=undefined?school:null)
  const [isUpdt,setUpdt]=React.useState(isName&&isdob ?1:0)
  const now = new Date().getUTCFullYear();    
const [isalert,setAlert]=React.useState(false)
const [Ydob,setYdob]=React.useState(udata!=null?''+udata.data[0].dob.split('-')[0]:'')

const [Mdob,setMdob]=React.useState(udata!=null?udata.data[0].dob.split('-')[1]:'02')


const [Ddob,setDaydob]=React.useState(udata!=null?''+udata.data[0].dob.split('-')[2]:'')

const currentYear = (new Date()).getFullYear();
const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
const Yr=range(currentYear, currentYear - 80, -1) 
const Mnth=moment.months();
 //const Yr=getYear();
 //console.log(Yr);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleChangeStaff = (event) => {
    setStaff(event.target.value);
    
  };
  
  const handleAlertClose = () => {
    setAlert(false);
   
  };
  const convertDate = (dateString) => {
    var p = dateString.split(/\D/g)
    return [p[2], p[1], p[0]].join("-")
  }

useEffect(()=>{
  if(udata1!=null || udata1!=undefined){
    if(udata1.data!=undefined){
      
setSchoolname(udata1.data[0].school_id)
setComm(udata1.data[0].communication_address)
setCity(udata1.data[0].city)
setState(udata1.data[0].state)
setCountry(udata1.data[0].country)
setEmail(udata1.data[0].emailid)
setMob(udata1.data[0].contact)
setYop(udata1.data[0].passout_batch)
setMop(udata1.data[0].passout_month)
setStatus(udata1.data[0].current_status)
setDesig(udata1.data[0].designation)
 setCompany(udata1.data[0].current_company)  
setOccu(udata1.data[0].occupation)
setWp(udata1.data[0].workplace)
  }
}
},[udata1])
  const Submit=()=>{
    
    var newday=Number(Ddob)<10?'0'+Ddob:Ddob;
    var d=Ydob+'-'+Mdob+'-'+newday;
    if(email==null||email==undefined||email=='' || d==null || d.length<5){
      console.log(email)
      localStorage.setItem('data',null);
      window.alert('Something went wrong please login again!')
      history.push({pathname:"/alumni/about/"+props.sid})
    }

    const formData= new FormData();
    formData.append('userid',usid)
    formData.append("file",photo);
    formData.append("photolink",photolink);
    formData.append("fun_name",'AlumniForm');
    formData.append("sid",props.sid);
    formData.append("Name",Name);
    formData.append("email",email);
    formData.append("city",city);
    formData.append("state",state);
    formData.append("country",country);
    formData.append("school_id",schoolname);
    formData.append("comm_adr",comm);
    formData.append('atype',staffStu);
    formData.append("status",status);
    formData.append('yoj',join);
    formData.append('yol',leaving);
    formData.append('desig',desig);
    formData.append("yop",yop);
    formData.append("mop",mop);
    formData.append("mobile",mob);
    formData.append("mobile2",Amob);
    formData.append("gender",value);
    formData.append("Curr_Comp",curr_comp);
    formData.append("occup",occu);
    formData.append("workp",Wp);
    formData.append("dob",d);
    formData.append('isUpdate',isUpdt)
    if(Name!=null && email!=null  && mob!=null && value!=null && dob!=null && staffStu!=null && staffStu!=''  && schoolname !=null && schoolname!='' && Name!='' && email!=''  && mob!='' && value!='' && d!=''){
      if(/^\d{10}$/.test(mob)){
      axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?",formData).then((resp)=>{
        if(resp.data.FormSubmitted==1){
          var s={hd:"Dash",data:resp.data.user_data} ;
          if(isUpdt==0){
            var s={hd:"dash",data:resp.data.user_data} ;
            localStorage.setItem('data',JSON.stringify(s));
            setUdata(JSON.parse(localStorage.getItem('data')));
            Swal.fire(
              "Submitted",
              "Your Alumni Form is Successfully Submitted",
              'success'
          ).then(
            history.push({pathname:"/alumni/Dashboard/"+props.sid,data:s})
          )
          }
          if(isUpdt==1){
           
            var s={hd:"Update your Profile",data:resp.data.user_data} ;
            console.log(s)
            localStorage.setItem('data',JSON.stringify(s));
            setUdata(JSON.parse(localStorage.getItem('data')));
            Swal.fire(
              "Updated",
              "Your profile is successfully updated",
              'success'
          ).then(
            history.push({pathname:"/alumni/Dashboard/"+props.sid})
          )
          
          }
        }
      })
    }else{
      Swal.fire(
        "Alert",
        "Mobile No. is Incorrect !",
        'warning'
    )
    }
    }
    else{
      Swal.fire(
        "Alert",
        "Some Fields are missing",
        'warning'
    )
    }
  }
  const Getfile=(file)=>{
    console.log(file)
    setPhoto(file);

  }
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
       
        <Avatar className={classes.avatar}>
          <SchoolIcon  />
        </Avatar>
        {/* <Typography component="h1" variant="h5">
          Sign in 
        </Typography> */}
        <Typography component="h1" variant="h5">
       Your {isUpdt==0?"Details":"Profile"}
        </Typography>
        {photolink.length>0?<img  style={{objectFit:'contain',maxWidth:'100%',maxHeight:'70vw'}} src={photolink}></img>:null}
        <div className={classes.form} >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="Name"
            label="Full Name"
            name="Name"
            value={Name}
          
            onChange={(e)=>{setName(e.target.value)}}
            autoComplete="Name"
            autoFocus
          />
          <FormControl  fullWidth variant="outlined" id='School Name' className={classes.formControl}>
        <InputLabel variant='outlined'>School Name</InputLabel>
        <Select variant='outlined'
          // disabled={Dschool}
          value={schoolname}
          onChange={(e) => setSchoolname(e.target.value)}
          label="School Name"
        >{schoollist.map((v,i)=>(
        <MenuItem value={v.id} >{v.school_name}</MenuItem>
          ))}
        
        </Select>
      </FormControl>
      {isalert?<Alert open={isalert} close={handleAlertClose} msg={"Your profile is successfully updated"}/>:null}
      <TextField
            variant="outlined"
            margin="normal"
            // disabled={iscomm}
            multiline
            rows={2}
            fullWidth
            name="Comm"
            label="Communication Address"
            type="text"
            id="Communication Address"
            value={comm!='null'?comm:'' }
            onChange={(e)=>{setComm(e.target.value)}}
            autoComplete="Communication Address"
          />
          <TextField
            variant="outlined"
            margin="normal"
            // disabled={iscity}
            fullWidth
            name="City"
            label="City"
            type="City"
            id="City"
            value={city!='null'?city:'' }
            onChange={(e)=>{setCity(e.target.value)}}
            autoComplete="City"
          />
          <TextField
            variant="outlined"
            margin="normal"
            // disabled={isstate}
            fullWidth
            name="State"
            label="State"
            type="State"
            id="State"
            value={state!='null'?state:''}
            onChange={(e)=>{setState(e.target.value)}}
            autoComplete="State"
          />
          <TextField
            variant="outlined"
            margin="normal"
            // disabled={iscountry}
            fullWidth
            name="Country"
            label="Country"
            type="Country"
            id="Country"
            value={country!='null'?country:''}
            onChange={(e)=>{setCountry(e.target.value)}}
            autoComplete="country"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            // disabled={isEmail}
            fullWidth
            name="email"
            label="email"
            type="email"
            id="email"
            value={email}
            onChange={(e)=>{setEmail(e.target.value)}}
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="mob"
            label="Mobile No."
            type="mob"
            id="mob"
            value={mob}
            onChange={(e)=>{setMob(e.target.value)}}
            autoComplete="mob"
          />
           <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="mob"
            label="Alternate Mobile No."
            type="mob"
            id="mob"
            value={Amob}
            onChange={(e)=>{setAltMob(e.target.value)}}
            autoComplete="mob"
          />
           <FormControl  component="fieldset">
      <FormLabel required component="legend">Gender</FormLabel>
      <RadioGroup  aria-label="gender" name="gender1" value={value} onChange={handleChange}>
        <FormControlLabel  value="female" control={<Radio />} label="Female" />
        <FormControlLabel  value="male" control={<Radio />} label="Male" />
      </RadioGroup>
    </FormControl>
          <FormControl required fullWidth component="fieldset">
      <FormLabel  component="legend">Staff/Alumni</FormLabel>
      <RadioGroup  aria-label="gender" name="staff/alumni" value={staffStu} onChange={handleChangeStaff}>
        <FormControlLabel  disabled={DStaff} value="Staff" control={<Radio />} label="Staff" />
        <FormControlLabel disabled={DStaff}  value="Student" control={<Radio />} label="Alumni" />
      </RadioGroup>
    </FormControl>
        {staffStu=='Student'?<>
        <FormControl  fullWidth variant="outlined" id='Select Passing Batch' className={classes.formControl}>
        <InputLabel variant='outlined'>Select Passing Year</InputLabel>
        <Select variant='outlined'
          // disabled={isyop}
          value={yop}
          onChange={(e) => setYop(e.target.value)}
          label="Select Passing Batch"
        >{Yr.map((v,i)=>(
        <MenuItem value={v}>{v}</MenuItem>
          ))}
        
        </Select>
      </FormControl><br/><br/>
      <FormControl  fullWidth variant="outlined" id='Select Passing Month' className={classes.formControl}>
        <InputLabel variant='outlined'>Select Passing Month</InputLabel>
        <Select variant='outlined'
          // disabled={ismop}
          value={mop}
          onChange={(e) => setMop(e.target.value)}
          label="Select Passing Month"
        >{['March','Octobar'].map((v,i)=>(
        <MenuItem value={v}>{v}</MenuItem>
          ))}
        
        </Select>
      </FormControl><br/><br/>
      <FormControl  fullWidth variant="outlined" id='Current_status' className={classes.formControl}>
        <InputLabel variant='outlined'>Your Current Status</InputLabel>
        <Select variant='outlined'
          // disabled={isstatus}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          label="You'r Current Status"
        >{['Self Employed','Doing Job','Retired'].map((v,i)=>(
        <MenuItem value={v}>{v}</MenuItem>
          ))}
        
        </Select>
      </FormControl>
      <TextField
            variant="outlined"
            margin="normal"
            // disabled={isdesig}
            fullWidth
            name="Designation"
            label="Designation"
            type="text"
            id="desig"
            value={desig!='null'?desig:''}
            onChange={(e)=>{setDesig(e.target.value)}}
            autoComplete="Designation"
          />

      </>
        :staffStu=="Staff"?<>
        <FormControl  fullWidth variant="outlined" id='Current_status' className={classes.formControl}>
        <InputLabel variant='outlined'>You'r Current Status</InputLabel>
        <Select variant='outlined'
         
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          label="You'r Current Status"
        >{['Working','Retired'].map((v,i)=>(
        <MenuItem value={v}>{v}</MenuItem>
          ))}
        
        </Select>
      </FormControl>
      <FormControl  fullWidth variant="outlined" id='School Name' style={{marginTop:20}} className={classes.formControl}>
        <InputLabel variant='outlined'>School Name</InputLabel>
        <Select variant='outlined'
          // disabled={Dschool}
          value={schoolname}
          onChange={(e) => setSchoolname(e.target.value)}
          label="School Name"
        >{schoollist.map((v,i)=>(
        <MenuItem value={v.id} >{v.school_name}</MenuItem>
          ))}
        
        </Select>
      </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            // disabled={isdesig}
            fullWidth
            name="Designation"
            label="Designation"
            type="text"
            id="desig"
            value={desig!='null'?desig:''}
            onChange={(e)=>{setDesig(e.target.value)}}
            autoComplete="Designation"
          />
          <TextField
            variant="outlined"
            margin="normal"
            inputProps={{
              style: {
                  color:'black',
                  fontWeight: 'initial',
                  
              }
           }}
            fullWidth
            name="Joining Date"
            label="Joining Date"
            type="date"
            id="Joining Date"
            // disabled={djoin}
            value={join}
            onChange={(e)=>{setJoining(e.target.value)}}
            autoComplete="Joining Date"
            InputLabelProps={{
          shrink: true,
        }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            inputProps={{
              style: {
                  color:'black',
                  fontWeight: 'initial',
                  
              }
           }}
            fullWidth
            name="Retiring Date"
            label="Retiring Date"
            type="date"
            id="Retiring Date"
            disabled={isleaving}
            value={leaving}
            onChange={(e)=>{setLeaving(e.target.value)}}
            autoComplete="Retiring Date"
            InputLabelProps={{
          shrink: true,
        }}
          />
          </>:null
        
        }
        <FormControl  fullWidth variant="outlined" id='School Name' style={{marginTop:20}} className={classes.formControl}>
        <InputLabel variant='outlined'>Year of Birth</InputLabel>
        <Select variant='outlined'
          // disabled={Dschool}
          value={Ydob}
          onChange={(e) => setYdob(e.target.value)}
          label="School Name"
        >{Yr.map((v)=>(
        <MenuItem value={v} >{v}</MenuItem>
          ))}
        
        </Select>
      </FormControl>
      <FormControl  fullWidth variant="outlined" id='School Name' style={{marginTop:20}} className={classes.formControl}>
        <InputLabel variant='outlined'>Month of Birth</InputLabel>
        <Select variant='outlined'
          // disabled={Dschool}
          value={Number(Mdob)}
          onChange={(e) => setMdob(e.target.value)}
          label="Month of Birth"
        >{[0,1,2,3,4,5,6,7,8,9,10,11].map((v,i)=>(
        <MenuItem value={i+1} >{moment.months(Number(i))}</MenuItem>
          ))}
        
        </Select>
      </FormControl>
      <FormControl  fullWidth variant="outlined" id='School Name' style={{marginTop:20}} className={classes.formControl}>
        <InputLabel variant='outlined'>Day of Birth</InputLabel>
        <Select variant='outlined'
          // disabled={Dschool}
          value={Number(Ddob)}
          onChange={(e) => setDaydob(e.target.value)}
          label="Day of Birth"
        >{daysInMonth(Ydob,Mdob).map((v,i)=>(
        <MenuItem value={v} >{v}</MenuItem>
          ))}
        
        </Select>
      </FormControl>
             {/* <MuiPickersUtilsProvider utils={MomentUtils}>
             <KeyboardDatePicker
             inputVariant="outlined"
          
          fullWidth
          format="DD-MM-YYYY"
          margin="normal"
          id="date"
        label="Date of Birth"
          value={dob}
        onChange={(e)=>{setDob(e)}}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

        </MuiPickersUtilsProvider> */}



    {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
        id="date"
        label="Date of Birth"
        type="date"
        inputProps={{
          style: {
              color:'black',
              fontWeight: 'initial',
              
          }
       }}
        value={dob}
        onChange={(e)=>{setDob(e.target.value)}}
       // defaultValue="2017-05-24"
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
      <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="Current Company"
            label="Current Company"
            type="Current Company"
            id="Current Company"
            disabled={ staffStu==="Staff" && status==="Working"}
            value={curr_comp!='null'?curr_comp:''}
            onChange={(e)=>{setCompany(e.target.value)}}
            autoComplete="Current Company"
          />
      <TextField
            variant="outlined"
            margin="normal"
            disabled={staffStu==="Staff" && status==="Working"}
            fullWidth
            name="Occupation"
            label="Occupation"
            type="Occupation"
            id="Occupation"
            value={occu!='null'?occu:''}
            onChange={(e)=>{setOccu(e.target.value)}}
            autoComplete="Occupation"
          />
          <TextField
            variant="outlined"
            margin="normal"
            disabled={staffStu==="Staff" && status==="Working"}
            fullWidth
            name="Work Place"
            label="Work Place"
            type="Work Place"
            id="Work Place"
            value={Wp}
            onChange={(e)=>{setWp(e.target.value)}}
            autoComplete="Work Place"
          />
         <AchiveImageUp title={"Upload your recent photo"} file={Getfile}/>
          {usid!=null?<Button
           onClick={()=>Submit()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Update
          </Button>:<Button
           onClick={()=>Submit()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>}
          <div style={{marginLeft:'35%'}}>
        
  </div>
        </div>
      </div>
    </Container>
  );
}