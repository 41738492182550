
import React, { useEffect, useState } from 'react';
import { Avatar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormLabel, Radio, RadioGroup, InputLabel } from '@material-ui/core';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import axios from 'axios';
import Imageupload from './Imageupload';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '@global': {
    '*:focus': {
      outline: ' none !important'
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Quizform(props) {
  const classes = useStyles();
  const [sname, setSname] = useState(null);
  const [strength, setStrength] = useState(null);
  const [board, setBoard] = useState(null);
  const [pname, setPname] = useState(null);
  const [pcont, setPcont] = useState(null);
  const [itname, setITname] = useState(null);
  const [itcont, setITcont] = useState(null);
  const [dname, setDname] = useState(null);
  const [dcont, setDcont] = useState(null);
  const [pmet, setPmet] = useState(null);
  const [saddr, setSaddr] = useState(null);
  const [scity, setScity] = useState(null);
  const [email, setEmail] = useState(null);
  const [iserp, setIsERP] = useState(null);
  const [erp, setERP] = useState(null);
  const [ilevel, setIlevel] = useState(null);
  const [infra, setInfra] = useState(null);
  const {sid}=useParams();

  const [photo,setPhoto ] = useState(null);

  useEffect(() => {


  }, [])
  const Getfile=(file)=>{
    console.log(file)
    setPhoto(file);

  }
const Submit=()=>{
  var fm=new FormData();
  fm.append('sid',sid)
  fm.append('fun_name','client_followup_topschool_client_insert')
  fm.append('school_name',sname)
  fm.append('userid',JSON.parse(localStorage.getItem('UserInfo1')).user_master[0].username)
  fm.append('strength',strength)
  fm.append('board',board)
  fm.append('principal_name',pname)
  fm.append('principal_contact',pcont)
  fm.append('it_person_name',itname)
  fm.append('it_person_contact',itcont)
  fm.append('director_name',dname)
  fm.append('director_contact',dcont)
  fm.append('person_met',pmet)
  fm.append('school_addr',saddr)
  fm.append('school_city',scity)
  fm.append('email',email)
  fm.append('using_erp',iserp)
  fm.append('erp_name',erp)
  fm.append('interest_level',ilevel)
  fm.append('School_infra',infra)
  fm.append('file',photo)
  props.submit(fm)
}

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>

        <PersonAddIcon color='secondary' fontSize='large' />
        <Typography component="h1" variant="h5">
          Add New Client
        </Typography>

        <div className={classes.form} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id="sname"
                value={sname}
                onChange={(e) => setSname(e.target.value)}
                // defaultValue={result!=null? result.data.quiz[0].name:''}
                label="School Name"

              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                id="strength"
                label="Strength"
                name="Strength"
                value={strength}
                 onChange={(e)=>setStrength(e.target.value)}
                autoComplete="Strength"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" id='Board' className={classes.formControl}>
                <InputLabel variant='outlined'>Board</InputLabel>
                <Select variant='outlined'                 
                  value={board}
                   onChange={(e) => setBoard(e.target.value)}
                  label="Board"
                >{['SB', 'CBSE', 'ICSE', 'IB', 'Others'].map((v, i) => (
                  <MenuItem value={v}>{v}</MenuItem>
                ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="pname"
                label="Principal Name"
                name="pname"
                value={pname}
                 onChange={(e)=>setPname(e.target.value)}
                autoComplete="pname"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="pname"
                label="Principal Contact"
                name="pcontact"
                value={pcont}
                 onChange={(e)=>setPcont(e.target.value)}
                autoComplete="pmob"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="itpname"
                label="IT Person Name"
                name="itpname"
                value={itname}
                 onChange={(e)=>setITname(e.target.value)}
                autoComplete="itname"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="itpno"
                label="IT Person Contact No."
                name="itpno"
                value={itcont}
                 onChange={(e)=>setITcont(e.target.value)}
                autoComplete="itno"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="director"
                label="Director Name"
                name="dname"
                value={dname}
                 onChange={(e)=>setDname(e.target.value)}
                autoComplete="dname"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="director cont"
                label="Director Contact"
                name="dcont"
                value={dcont}
                 onChange={(e)=>setDcont(e.target.value)}
                autoComplete="dcont"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="pmet"
                label="Person Met"
                name="pmet"
                value={pmet}
                 onChange={(e)=>setPmet(e.target.value)}
                autoComplete="pmet"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="saddt"
                label="School Address"
                name="saddr"
                value={saddr}
                 onChange={(e)=>setSaddr(e.target.value)}
                autoComplete="saddr"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="scity"
                label="School City"
                name="scity"
                value={scity}
                 onChange={(e)=>setScity(e.target.value)}
                autoComplete="scity"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="email"
                label="Email ID"
                name="email"
                value={email}
                 onChange={(e)=>setEmail(e.target.value)}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="iserp"
                label="Currently using any ERP"
                name="iserp"
                value={iserp}
                 onChange={(e)=>setIsERP(e.target.value)}
                autoComplete="iserp"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                fullWidth
                id="erpname"
                label="Name of ERP"
                name="erp"
                value={erp}
                 onChange={(e)=>setERP(e.target.value)}
                autoComplete="erp"
              />
            </Grid>
            <Grid item xs={12}>
            <FormControl required  fullWidth variant="outlined" id='Interest' className={classes.formControl}>
        <InputLabel variant='outlined'>Interest Level</InputLabel>
        <Select variant='outlined'
          // disabled={isyop}
          value={ilevel}
           onChange={(e) => setIlevel(e.target.value)}
          label="Interest Level"
        >{['Low','Medium','High'].map((v,i)=>(
        <MenuItem value={v}>{v}</MenuItem>
          ))}
        
        </Select>
      </FormControl>
      </Grid>
      <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" id='Board' className={classes.formControl}>
                <InputLabel variant='outlined'>School Infra</InputLabel>
                <Select variant='outlined'                 
                  value={infra}
                   onChange={(e) => setInfra(e.target.value)}
                  label="School Infra"
                >{['Very Good', 'Good', 'Ok', 'Bad'].map((v, i) => (
                  <MenuItem value={v}>{v}</MenuItem>
                ))}

                </Select>
              </FormControl>
              </Grid>
      <Grid item xs={12}>
        <Imageupload title={"Upload your recent photo"} file={Getfile}/>
      </Grid>
          </Grid>{/* final */}
          <Button
            onClick={() =>Submit()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </div>
      </div>

    </Container>
  );
}