import React from 'react';
import {Button,IconButton} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Divider,InputLabel,Select,MenuItem} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto #f50057',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#f50057',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#f50057',
    },
  },
  drop:{
      width:'80%',
      marginLeft:20,
      marginBottom:20,
      borderRadius:10,
  }
});

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
export default function Chip(props) {

    const classes = useStyles();
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    today = yyyy+'-'+mm+'-'+dd;
    const [date, setDate] = React.useState(today);
    const [pmet, setPmet] = React.useState(null);
    const [time,setTime]=   React.useState(null);
    const [interest,setInt]=   React.useState(null);
    const [demo,setDemo]=   React.useState(null);
    const [vtype,setVtype]=   React.useState('Phone Call');
    const [comment, setComment] = React.useState(null);

    const slots = [
        "1:00 PM", "1:30 PM", "2:00 PM",
        "2:30 PM", "3:00 PM", "3:30 PM",
        "4:00 PM", "4:30 PM", "5:00 PM",
        "5:30 PM", "6:00 PM", "6:30 PM",
        "7:00 PM", "7:30 PM", "8:00 PM",
        "8:30 PM", "9:00 PM", "9:30 PM",
        "10:00 PM", "10:30 PM", "11:00 PM",
        "11:30 PM", "12:00 PM", "12:30 PM",
        "1:00 AM", "1:30 AM", "2:00 AM",
        "2:30 AM", "3:00 AM", "3:30 AM",
        "4:00 AM", "4:30 AM", "5:00 AM",
        "5:30 AM", "6:00 AM", "6:30 AM",
        "7:00 AM", "7:30 AM", "8:00 AM",
        "8:30 AM", "9:00 AM", "9:30 AM",
        "10:00 AM", "10:30 AM", "11:00 AM",
        "11:30 AM", "12:00 AM", "12:30 AM"
    ]


    const Submit=()=>{      
        props.FormS(vtype,time,date,pmet,interest,demo,comment);       

    }
  return (
    <div>
        
      <Dialog open={props.open}  aria-labelledby="form-dialog-title" onClose={props.close} >
        <DialogTitle id="form-dialog-title">{props.data.school_name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
             Please fill the form
          </DialogContentText>
          <div>
    <FormControl component="fieldset">
      <FormLabel component="legend" style={{color:'#f50057'}}>Visit Type</FormLabel>
      <RadioGroup defaultValue="Phone Call" value={vtype} onChange={(e)=>setVtype(e.target.value)} name="customized-radios" style={{display:'block'}}>
        <FormControlLabel  value="Phone Call" control={<StyledRadio />} label="Phone Call" />
        <FormControlLabel value="Personal Visit" control={<StyledRadio />} label="Personal Visit" />
      </RadioGroup>
    </FormControl> 
    <TextField style={{marginLeft:20,marginBottom:20,width:'80%'}}
            fullWidth
          type="date"
          inputProps={{
            style: {
                color:'black',
                fontWeight: 'initial',
                
            }
         }}
         InputLabelProps={{
            shrink: true, 
          }}
          label="Select Date"
          value={date}
          onChange={(e)=>{setDate(e.target.value)} }
          defaultValue={date}
          variant="outlined"
          size="small"
          
        />
        <FormControl fullWidth variant="outlined" id='Board' className={classes.drop}  size="small" color="secondary">
                <InputLabel variant='outlined'>Time</InputLabel>
                <Select variant='outlined'                 
                  value={time}
                   onChange={(e) => setTime(e.target.value)}
                  label="Time"
                >{slots.map((v, i) => (
                  <MenuItem value={v}>{v}</MenuItem>
                ))}

                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                color="secondary"
                fullWidth
                multiline
                rows={2}
                className={classes.drop} 
                id="pname"
                label="Person Met Name & Designation"
                name="pname"
                value={pmet}
                 onChange={(e)=>setPmet(e.target.value)}
                autoComplete="pname"
              />
    <FormControl fullWidth variant="outlined" id='Board' className={classes.drop}  size="small" color="secondary">
                <InputLabel variant='outlined'>Success probablity</InputLabel>
                <Select variant='outlined'                 
                  value={interest}
                   onChange={(e) => setInt(e.target.value)}
                  label="Success probablity"
                >{['Very Intersted', 'Intersted','Neutral','Not Intersted'].map((v, i) => (
                  <MenuItem value={v}>{v}</MenuItem>
                ))}

                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined" id='Board' className={classes.drop}  size="small" color="secondary">
                <InputLabel variant='outlined'>Product Demo</InputLabel>
                <Select variant='outlined'                 
                  value={demo}
                   onChange={(e) => setDemo(e.target.value)}
                  label="Product Demo"
                >{['Yes','No'].map((v, i) => (
                  <MenuItem value={v}>{v}</MenuItem>
                ))}

                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                color="secondary"
                fullWidth
                multiline
                rows={2}
                className={classes.drop} 
                id="comment"
                label="Comment"
                name="comment"
                value={comment}
                 onChange={(e)=>setComment(e.target.value)}
                autoComplete="comment"
              />
              <Button variant="outlined" color="secondary" onClick={()=>Submit()}>
                Done
              </Button>
    </div>
        </DialogContent>
      
      </Dialog>
    </div>
  );
}
