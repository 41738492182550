import React, { Component } from "react";
import "../App.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import axios from "axios";
import {
  MDBCard,
  MDBCardText,
  MDBContainer,
  MDBBtn,
  MDBCollapse,
  MDBCardBody,
} from "mdbreact";
//import { object } from "prop-types";
class Teacheronlineclass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sid: this.props.match.params.sid ? this.props.match.params.sid : "",
      userid: this.props.match.params.userid
        ? this.props.match.params.userid
        : "",
      flag: 0,
      isLoading: true,
      collapseID: "",
    };
    /* this.getStudclassMeeting = this.getStudclassMeeting.bind(this); */
  }

  /*  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  }; */

  getTeacherOnlineMeetingData(self) {
    console.log("geting teacher online meeting details");
    axios
      .post("https://topschool.prisms.in/rest/index.php/staff_list.json?", {
        ["fun_name"]: "get_Teacheronlinemeeting_Data_For_IOS",
        ["sid"]: this.state.sid,
        ["userid"]: this.state.userid,
      })
      .then(function(result) {
        console.log("result", result);
        if (result.data.length > 0) {
          self.setState({
            flag: 1,
            data: result.data,
          });
        } else {
          self.setState({
            flag: 1,
            data: [],
          });
        }
      });
    //set state
  }

  /*   getStudclassMeetingLink = () => {
    console.log("getting student online meeting link details");
    //alert(this.state.userid);
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        ["fun_name"]: "get_Studonlinemeeting_Link_IOS",
        ["sid"]: this.state.sid,
        ["userid"]: this.state.userid,
      })
      .then(function(result) {
        console.log("result", result);
        if (result.data.length > 0) {
          window.open(result.data, "_blank");
          console.log("called..");
        } else {
          console.log("error..");
        }
      });
  }; */

  componentDidMount() {
    this.getTeacherOnlineMeetingData(this);
  }

  render() {
    return (
      <MDBContainer>
        {this.state.data && this.state.data == "INVALID" ? (
          <MDBCard className="card-body mt-5 card-background">
            <MDBCardText>
              Online Classes are not Scheduled at the moment
            </MDBCardText>
          </MDBCard>
        ) : this.state.data && this.state.data.length > 0 ? (
          this.state.data.map((value, index) => (
            <div className="mx-auto pt-2" key={index}>
              <MDBCard className="card-body card-background">
                <MDBCardText>
                  <span className="float_left">
                    <strong>Title: </strong>
                    {value.title}
                  </span>
                  <span className="float_right">
                    <strong>Class: </strong>
                    {value.div_name}
                  </span>
                </MDBCardText>
                {/* <MDBCardText>
                  <span className="float_left">
                    <MDBBtn
                      rounded
                      size="sm"
                      color="secondary"
                      onClick={this.toggleCollapse(index)}
                    >
                      Show More
                    </MDBBtn>
                  </span>
                </MDBCardText> */}

                <MDBCardText>
                  <span className="float_left">
                    <strong>Date: </strong>
                    {value.date}
                  </span>
                  <span className="float_right">
                    <strong>Time: </strong>
                    {value.time}
                  </span>
                </MDBCardText>
                <MDBCardText className="card-margin">
                  <span className="float_left">
                    <MDBBtn
                      rounded
                      color="light-green"
                      size="sm"
                      href={value.moderator_link}
                      target="_blank"
                      onClick={this.getStudclassMeetingLink}
                    >
                      START CLASS
                    </MDBBtn>
                  </span>
                  <span className="float_right">
                    <strong>Duration: </strong>
                    {value.duration + " Min"}
                  </span>
                </MDBCardText>

                {/* <MDBCardText className="card-margin">
                    <span className="float_left">
                      <MDBBtn
                        rounded
                        color="deep-orange"
                        size="sm"
                        onClick={this.getStudclassMeetingLink}
                      >
                        CLASS INFO
                      </MDBBtn>
                    </span>
                    <span className="float_right">
                      <MDBBtn
                        rounded
                        color="cyan"
                        size="sm"
                        onClick={this.getStudclassMeetingLink}
                      >
                        RECORDING INFO
                      </MDBBtn>
                    </span>
                  </MDBCardText> */}
              </MDBCard>
            </div>
          ))
        ) : (
          <div className="d-flex justify-content-center">
            <div className="loader border-top-info margin-top-1" />
          </div>
        )}
      </MDBContainer>
    );
  }
}

export default Teacheronlineclass;
