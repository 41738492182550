import React from 'react'
import Css from './Admin.module.css'
import { Link } from 'react-router-dom'

const Admin = () => {
  return (
    <div className={Css.main}>
      <div className={Css.load__div}>
        <img src="../icons/wallet/folder.png" alt="" />
        <Link to="/wallet/admin/load"><p>Load</p></Link>
      </div>
      <div className={Css.report__div}>
        <img src="../icons/wallet/dashboard.png" alt="" />
        <Link to="/wallet/transaction"><p>Report</p></Link>
      </div>
    </div>
  )
}

export default Admin