import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardHeader,CardActionArea} from '@material-ui/core';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    direction:'row',
    alignItems:"flex-start",
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
   
  },
 card:{
   
    maxWidth: 200,
    maxHeight:350,//350
    minHeight:350,
    marginLeft:5,
    marginBottom:5,
    padding:0,
    shadowColor: "#000000",
    shadowOpacity: 0.8,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 1
    }
    //border: "1px solid"
 },
})); 
export default function Cards(props) {
  let url1 = "https://topschool-attachments.s3.amazonaws.com/";
  let url2 = "https://s3.amazonaws.com/topschool-attachments/";
  const classes = useStyles();
return(
<Grid item xs={6} sm={5} md={3} lg={2} xl={5} >

<Card className={classes.card}>
<CardActionArea onClick={()=>props.p.onClick(props.data)} >
    <CardHeader  style={{padding:'0px', marginLeft:5}}
     title= {<Typography  style={{textTransform: 'capitalize'}}>{props.data.p_name}</Typography>}
        subheader={<Typography>&#x20B9;{props.data.purchase_price}</Typography>}/>
      <CardContent style={{display:'contents'}}>
        <Button style={{justifyContent:'canter',maxWidth:164,minHeight:170,maxHeight:170}}>
      <img style={{flex: 1,justifyContent:'canter',
   objectFit:'contain',maxWidth:160,maxHeight:160,
  }} src={props.data.prod_img=="" || props.data.prod_img==null ?
    "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS6Dw1Etemhe9K_LEK8pXzVO0X2tnqkMW8q4Q&usqp=CAU" :props.data.prod_img.includes(',')?
    props.data.prod_img.substr(0,props.data.prod_img.indexOf(',')):props.data.prod_img.replace(url2, "https://files.prisms.in/")
    }  alt={props.data.title} />
    </Button>
     <p style={{marginLeft:20}}>{Number(props.data.available_stock)>0?<p style={{color:'#12C407'}}>Stock Avaliable</p>:<p style={{color:'#C20906'}}>Out Of Stock</p>}</p>
      </CardContent>
       </CardActionArea>
      <CardActions  disableSpacing > 
        <Button>
          <ShoppingCartOutlinedIcon />
          </Button>
          <Button onClick={()=>props.p.addCart(props.data)}>
          <AddShoppingCartOutlinedIcon />
          </Button>
        </CardActions>
    </Card>
    
    </Grid>
    );
}