
import React,{useEffect,useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Quizform(props) {
  const classes = useStyles();
  const [result,setResult]=useState();
  const [submit,setSubmit]=useState();
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = React.useState(null);
  const [dup, setDup] = React.useState(false);
  useEffect(()=>{
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"QuizAppForm",['sid']:props.match.params.sid,['u_id']:props.match.params.userid}).
    then (res=> {
        console.log(res);
      setResult(res);
      for(var i=0;i<res.data.master.length;i++){
       
        if(res.data.quiz[0].name==res.data.master[i].name){
          setDup(true);
        }
      }
      });
  },[])

  const handleChange = (event) => {
    console.log(event.target.value);
    setEvent(event.target.value);
  };

  const handlesubmit=()=>{
    if(result.data.quiz[0].name!=null &&result.data.quiz[0].mobile1!=null &&result.data.quiz[0].email!=null
       &&result.data.quiz[0].div_name!=null && event !=null && event !=""){
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"QuizInsertInfo",['sid']:props.match.params.sid,['userid']:props.match.params.userid,
      ['name']:result.data.quiz[0].name,['mob']:result.data.quiz[0].mobile1,['email']:result.data.quiz[0].email,['class']:result.data.quiz[0].div_name,
      ['event']:event}).
        then (res=> {
            console.log(res);
            setSubmit(res);
            setOpen(true);
            window.location.reload();
          });
       }
       else {
         window.alert("please check all fields are filled")
       }
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
  dup==false? <Container component="main" maxWidth="xs">
      <CssBaseline />
    
     <Snackbar  anchorOrigin={{vertical:'top',horizontal:'center'}} open={open} autoHideDuration={6000} onClose={handleClose}>
     <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success</AlertTitle>
  Successfully Registered — <strong>{result!=null || result!=undefined? result.data.quiz[0].name: null}!</strong>
      </Alert>
      </Snackbar>
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
  </Avatar> */}
  <HowToRegIcon color='secondary' fontSize='large'/>
        <Typography component="h1" variant="h5">
          Free Quiz 
        </Typography>
        
        <form className={classes.form} noValidate='false'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                disabled
                fullWidth
                id="sname"
                value={
                  result === null ||
                  result === undefined
                    ? ""
                    : result.data.quiz[0].name
                }
                // defaultValue={result!=null? result.data.quiz[0].name:''}
                label="Student's Name"
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                disabled
                id="pmob"
                value={
                  result === null ||
                  result === undefined
                    ? ""
                    : result.data.quiz[0].mobile1
                }
                label="Parent's Mobile Number"
                name="pmob"
                autoComplete="pmob"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                disabled
                fullWidth
                name="Parent's Email"
                label="Parent's Email"
                id="pemail"
                value={
                  result === null ||
                  result === undefined
                    ? ""
                    : result.data.quiz[0].email
                }
                autoComplete="pemail"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                disabled
                name="Class"
                label="Class "
                value={
                  result === null ||
                  result === undefined
                    ? ""
                    : result.data.quiz[0].div_name
                }
                id="class"
                autoComplete="class"
              />
            </Grid>
            <FormControl variant="outlined" required fullWidth >
        <InputLabel variant='outlined' >Event</InputLabel>
        <Select
        variant='outlined'
        label="Event"
          value={event}
          onChange={(e)=>setEvent(e.target.value)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
            {result!=null && result!=undefined && result.data!=null && result.data!=undefined &&result.data.events!=undefined ? result.data.events.map((evnt,i=0)=>(
            <MenuItem  value={evnt.name}>{evnt.name}</MenuItem>
            )):null}
        </Select>
      </FormControl>
          
          </Grid>
          <Button
            onClick={handlesubmit}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </form>
      </div>
    
    </Container>:<div style={{minHeight:'100vh',minWidth:'100vw',overflow:'hidden',backgroundColor:'#b2bec3'}}>
    
    <CheckCircleIcon  style={{ minHeight:'30vh',minWidth:'50vw',color:'#27ae60',position: 'absolute',
    top: '35%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'}} />
    <Typography style={{marginLeft:'24vw',fontSize:'x-large',position: 'absolute',
    top: '53%',
    left: '25%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'}} >{result.data.quiz[0].name} </Typography>
    <Typography style={{marginLeft:'24vw',fontSize:'x-large',position: 'absolute',
    top: '58%',
    left: '25%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'}} >Already Registered </Typography><br/>
    <Typography style={{marginLeft:'24vw',fontSize:'x-large',position: 'absolute',
    top: '63%',
    left: '25%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'}} >Thank You </Typography>
    </div>
  );
}