import React from 'react';
import TicketReply from '../ReusedComponents/TicketReply'
import { useParams, useHistory } from 'react-router-dom'

export default function UserReply() {
    const history = useHistory();
    const { sid, uid } = useParams();

    return (
        <div className="totalTicketingModule">
            <TicketReply
                userType={history.location.state.userType}
                title={history.location.state.title}
                options={[
                    { title: "Create ticket", url: `/tickets/user/creating/${sid}/${uid}` },
                    { title: "Ticket reports", url: `/tickets/user/report/${sid}/${uid}` },
                    { title: "Assigned Tickets", url: `/tickets/user/assigned_tickets/${sid}/${uid}` }
                ]}
            />
        </div>
    )
}