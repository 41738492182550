import React, { useState,useEffect } from 'react';
import { fade, makeStyles,useTheme,withStyles } from '@material-ui/core/styles';
import {AppBar,Toolbar,IconButton,Typography,InputBase,Badge,
  Divider,ListItem,List,ListItemText,Hidden,Drawer,Snackbar,Collapse ,Avatar ,CircularProgress } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import Items from './Items';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Product_Details from './Product_Details';
import Orders from './Orders';
import { Alert, AlertTitle} from '@material-ui/lab';
import LatestItem from './LatestItem';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CategoryIcon from '@material-ui/icons/Category';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PreviousO from './PreviousO';
import HistoryIcon from '@material-ui/icons/History';
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  Snackbar: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  hide: {
    display: 'none',
  },

  menuButton: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  tool:{
    minHeight:10,
    [theme.breakpoints.up('sm')]: {
      minHeight:64
    }

  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    
    flexGrow: 1,
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft:drawerWidth+1,
      
    },
    
  },
  grow: {
    flexGrow: 1,
  },
  
  title: {
    // display: 'flex',
    // flexDirection:'row', 
    maxWidth:'80vw',
    marginLeft:5,
    marginRight:20,
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      maxWidth:'25vw',      
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis'
    },
  },
  search: {
    
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    //marginRight: theme.spacing(2),
    marginLeft: 20,
    width: '90%',
    //paddingRight:20,
    color:'white',
    top:5,
    [theme.breakpoints.up('sm')]: {
      // marginLeft: theme.spacing(3),
      // width: 'auto',
      display:'none',
    },
  },
  searchdesk:{
    display:'none',
    [theme.breakpoints.up('sm')]: {
      display:'flex',
      position: 'relative', 
    
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    
    width: '40%',
    marginLeft:'auto',
    marginTop:'auto',
    marginBottom:'auto'
    },
  },
  searchblock:{
    backgroundColor:'#3f51b5',
     marginTop:49,
     height:45,
     [theme.breakpoints.up('sm')]: {
       display:'none'
     },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    
  },
  inputInput: {
    color: 'inherit',
    padding: theme.spacing(1, 1, 1, 0),
      
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  root2: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  cart:{
    display:'flex',
    position:'absolute',
    right:5,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
      
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },

  },
  Avaitor: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  MobileAvatar:{
    display:'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
      
    },
  },
  DesktopAvatar:{
    display:'none',

    [theme.breakpoints.up('sm')]: {
      display:'flex',
      
    },
  },
  progressbar: {
    display: 'flex',
    position:'absolute',
    left:'40%',
    top:'40%',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

export default function Home(props) {
  const classes = useStyles();
  const [search_var,setSearch]=React.useState(null);
  const { window } = props;
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [result,setRes]= useState({});
  const [resultLogin,setLogin]= useState({});
  const [openDList, setDListOpen] = React.useState(true);
  const [p_details,setPdetails]=useState(false);
  const [Ohistory,setOhistory]=useState(false);
  const [currProd, setProd]=useState({});
  const [isHome,setHome]=React.useState(true);
  const [isOrder,setOrder]=React.useState(false);
  const [CartItm,setCart]=React.useState([]);
  const [CartItmTotal,setCartTotal]=React.useState(0);
  const [isItem,setIsitem]=React.useState(true);
  const [open,setOpen]=React.useState(false);
  const [opencart,setOpencart]=React.useState(false);
  const [CatLogID,setCatlog]=useState('');
  const [CatLogname,setCatlogname]=useState('');
  const [LatestPr,setLatestPr]=useState([]);
  const [cartpopup,setCartpopup]=React.useState(false);

  // console.log = console.warn = console.error = () => {};

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if(open==true)
    setOpen(false);
    if(opencart==true)
    setOpencart(false)
    if(cartpopup==true)
    setCartpopup(false)
  };
  useEffect(()  =>{
    async function fetchD(){
      try{
      const [firstResponse, secondResponse] = await axios.all([
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"getProductandCategory",['sid']:props.match.params.sid,['u_id']:props.match.params.userid}),
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"get_studentInfo",['sid']:props.match.params.sid,['u_id']:props.match.params.userid}),
      ]).then(axios.spread((firstResponse, secondResponse)=>{
        // console.log(firstResponse);
        setRes(firstResponse);
        setLogin(secondResponse);
        let latestProd=firstResponse.data.products.slice(Math.max(firstResponse.data.products.length - 3, 0))
        setLatestPr(latestProd);
        
      } )).catch(err =>console.log("Error-"+err));
 }
catch(err){
    console.log(err)
}
  }
   fetchD();
   
},[])
 
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    
  };
  const DrawerListHandle = () => {
    setDListOpen(!openDList);
  };
  const handleClick=(data)=>{ 
   
    setProd(data);
    setIsitem(false);
    setPdetails(true);
    setOhistory(false);
    setCatlogname('');
    setCatlog('');
    Mobiedrawer();
  }
  const OrderBtn=()=>{
    setOrder(true);
    setHome(false);
    setCatlogname('');
    setCatlog('');
    Mobiedrawer();
    setOhistory(false);
  }
  const POrderBtn=()=>{
    setOrder(false);
    setHome(false);
    setCatlogname('');
    setCatlog('');
    Mobiedrawer();
    setOhistory(true);
  }
  const HomeBtn=()=>{
    setHome(true);
    setOrder(false);
    setPdetails(false);
    setIsitem(true);
    setSearch(null);
    setCatlogname('');
    setCatlog('');
    Mobiedrawer();
    setOhistory(false);
  }
  
  const handleAddtoCart=(data)=>{
    if(Number(data.available_stock)> 0){
    setCart([...CartItm,data]);
    setCartTotal(CartItmTotal+Number(data.purchase_price));
    setCartpopup(true);
    }
    else{
      setOpen(true)
    }
  }
  const RemoveFromCart=(v,i)=>{
   // var index = CartItm.indexOf(v);
  if (i > -1) {
  CartItm.splice(i, 1);
  setCartTotal(CartItmTotal-Number(v.purchase_price))
  setOpencart(true);
}
setCart([...CartItm]);

  }
  const ClearCart=()=>{
    // var index = CartItm.indexOf(v);
//    if (i > -1) {
//    CartItm.splice(i, 1);
//    setCartTotal(CartItmTotal-Number(v.purchase_price))
//    setOpencart(true);
//  }
 setCart([]);
 
   }
  const SetSearchFromLatestItem=(search)=>{
    setSearch(search);
  }
 const Mobiedrawer=()=>{
   setMobileOpen(false);
 }
 
 
  const drawer = (
    <div>
        {mobileOpen? null :<div className={classes.toolbar} />}
     
      {resultLogin.data !=null?<><p className={classes.MobileAvatar} style={{marginLeft:'30%',marginTop:'5%'}}>   
        <Avatar  src={resultLogin.data.student[0].avatar} className={classes.large} /></p>
      <p className={classes.MobileAvatar} style={{marginLeft:20}}>{resultLogin.data.student[0].name}</p>
  <p className={classes.MobileAvatar} style={{marginLeft:20}}>Class : {resultLogin.data.student[0].div_name}</p>
      </>:<></>}  
      <Divider />
      <List>
      <ListItem button onClick={HomeBtn}>
          <ListItemIcon>
            <HomeOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Store Home"  />
        </ListItem>
        <ListItem button onClick={OrderBtn} style={{padding:'0 4px'}}>
          <ListItemIcon>
          <IconButton aria-label="cart">
          <StyledBadge badgeContent={CartItm.length} color="secondary">
          <ShoppingCartOutlinedIcon />
          </StyledBadge>
          </IconButton>
          </ListItemIcon>
        <ListItemText style={{padding:10}} primary="Orders"  />
        </ListItem> 
        <ListItem button onClick={POrderBtn} style={{padding:'0 4px'}}>
          <ListItemIcon>
          <IconButton aria-label="cart">
          <StyledBadge  color="secondary">
          <HistoryIcon />
          </StyledBadge>
          </IconButton>
          </ListItemIcon>
        <ListItemText style={{padding:10}} primary="Previous Orders"  />
        </ListItem> 
        <ListItem button onClick={DrawerListHandle}>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Categories" />
        {openDList ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openDList} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {result.data!=null? result.data.product_category.map((Item) =>(
          <ListItem button style={{marginLeft:20}} onClick={()=>{setCatlog(Item.prod_cat_id);
           setCatlogname(Item.prod_cat_name); setPdetails(false);setOhistory(false);setOrder(false);setSearch(null);Mobiedrawer()}}>
            <ListItemText primary={Item.prod_cat_name} />
          </ListItem>
           )) : null}
        </List>
       
      </Collapse>
      </List>
    </div>
  );
  

  const container = window !== undefined ? () => window().document.body : undefined;
  
  return (
    resultLogin.data!=null ?
    <div className={classes.grow}  >
<Snackbar anchorOrigin={{vertical:'top',horizontal:'center'}} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled"  severity="error">
        <AlertTitle>Out Of Stock</AlertTitle>
         The Product you Want is currently out of stock
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{vertical:'top',horizontal:'center'}} open={opencart} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled"  severity="warning">
         The Item is removed from Cart
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{vertical:'top',horizontal:'center'}} open={cartpopup} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled"  severity="success">
         The Item is Added to your Cart
        </Alert>
      </Snackbar>

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.tool}>
          {p_details==true || isOrder==true?<IconButton
            edge="start"
            className={classes.menuButton }
            onClick={HomeBtn}
            color="inherit"
            aria-label="open drawer"
            
          > 
            <KeyboardBackspaceIcon />
          </IconButton>:null}
          <IconButton
            edge="start"
            className={classes.menuButton }
            onClick={handleDrawerToggle}
            color="inherit"
            aria-label="open drawer"
            
          > 
            <MenuIcon />
          </IconButton> 

          <Typography className={classes.title} variant="h6" onClick={HomeBtn} >
            {resultLogin.data !=null? resultLogin.data.school.school_name:<></>}  
          </Typography>
          <div className={classes.searchdesk}    >
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
           
            <Autocomplete
             onChange={(event, value) => {setSearch(value); setPdetails(false);setIsitem(true);setCatlogname('');setCatlog('')  }}
        freeSolo
        value={search_var}
        
        options={ result.data !=null? result.data.products.map((option) => option.p_name):<><CircularProgress/></>}
        getOptionLabel={(option) => String(option)}        
        autoHighlight
        renderInput={(params) => (
          <InputBase
          placeholder="Search…"
         
          ref={params.InputProps.ref}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          autoFocus
          inputProps={params.inputProps}
          
        />
  )}
      />
          </div>
          
          <IconButton className={classes.cart} aria-label="cart" onClick={OrderBtn} color="inherit">
          <StyledBadge badgeContent={CartItm.length} color="secondary">
          <ShoppingCartOutlinedIcon  />
          </StyledBadge>
          </IconButton>
          {resultLogin.data !=null?<div style={{position:'absolute',right:15, top:7,display: 'flex',  alignItems: 'center'}}>
            <p className={classes.DesktopAvatar}>   
        <Avatar  src={resultLogin.data.student[0].avatar} className={classes.large} /></p>
     <p className={classes.DesktopAvatar} style={{marginTop:5,marginLeft:5}}> {resultLogin.data.student[0].name}</p>
     </div>:<></>} 

          <div className={classes.grow} />
         
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, 
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <div className={classes.searchblock}>
      <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
           
        <Autocomplete
        onChange={(event, value) => {  setSearch(value); setPdetails(false);setIsitem(true);setCatlogname('');setCatlog('') }}
        freeSolo      
        value={search_var}
        
        options={ result.data !=null?  result.data.products.map((option) => option.p_name):<>Loading....</>}
        getOptionLabel={(option) => String(option)}        
        autoHighlight
        renderInput={(params) => (
          <InputBase
          placeholder="Search…"
         
          ref={params.InputProps.ref}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          autoFocus
          inputProps={params.inputProps}
          
        />


        )}
      />
          </div>
          </div>
          {isHome==true && isOrder==false&& p_details==false&& (search_var==null || search_var=='') && CatLogID=='' ? <LatestItem select={SetSearchFromLatestItem} latest={LatestPr} />:null}
      
      <main className={classes.content} >
      
     
        {mobileOpen==false && search_var!=null && search_var !='' ||isOrder==true ||CatLogname!='' ||p_details==true ||Ohistory==true?<div className={classes.tool}/>:null}
          
        {isHome==true && isOrder==false ?<> 
        
      {result.data!=null && p_details==false && isItem==true && CatLogID==''  ? result.data.product_category.map((tile) => (<>
      
        {(search_var ==null || search_var =='')  ? <>
         <Typography variant="h6">{tile.prod_cat_name}</Typography>
       <Items pdata={result} sid={props.match.params.sid} metaData={search_var} cat={tile.prod_cat_id}  onClick={handleClick} addCart={handleAddtoCart}/></>:
      
       <>
       <Items pdata={result} sid={props.match.params.sid} metaData={search_var} cat={tile.prod_cat_id}  onClick={handleClick} addCart={handleAddtoCart} /></>}
      </>)):<></>}    

      {p_details==true ? <Product_Details data={currProd} addCart={handleAddtoCart} back={HomeBtn}/>:<></>}</>
      : <></>}
        {isHome==false && isOrder==true ? <Orders data={CartItm} CC={ClearCart} Total={CartItmTotal} info={resultLogin} del={RemoveFromCart}/>:<></>}
        {isHome==false && Ohistory==true ?<PreviousO data={result.data.history} avatar={resultLogin.data.student[0].avatar}/>:<></>}
        {( search_var=='' || search_var==null)? result.data.product_category.map((tile) => (
        CatLogID!='' && CatLogID==tile.prod_cat_id  ?
        <><Typography  variant="h6">{CatLogname}</Typography><br/>
       <Items pdata={result} sid={props.match.params.sid} metaData={search_var} cat={tile.prod_cat_id} catlogid={CatLogID} catlogname={CatLogname}  onClick={handleClick} addCart={handleAddtoCart} />
      </> :<></>)):<></>}

      </main>

    </div>:  <div className={classes.progressbar}>
      <CircularProgress   /></div>
  );
} 