import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button,IconButton, Typography} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import {Select,InputLabel,MenuItem} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

export default function Barcode(props) {
    const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [age, setAge] = React.useState('');
  const [age2, setAge2] = React.useState('');
  const [age3, setAge3] = React.useState(null);
  const fonts=['Userid','Mobile Number','Timestamp']
const [text,setText]=React.useState(null)
  const Change = () => {
   
  };

  const handleClose = () => {
      props.gen(age3,age,age2);
      props.close()
  };

  return (
    <div>
        
      <Dialog open={props.open} onClose={props.close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Barcode</DialogTitle>
        <DialogContent>
          <DialogContentText>
             <div style={{display:'flex'}}>
            <TextField style={{marginRight:20}}
            label="Enter Prefix"
            variant="outlined"
            value={age3}
            onChange={(e)=>setAge3(e.target.value)}
            />
            <Typography style={{marginRight:20,marginTop:'revert'}}>+</Typography>
            <FormControl fullWidth className={classes.formControl}>
        <InputLabel variant="outlined" >
        Field 1
        </InputLabel>
        <Select 
        style={{marginRight:20}}
         variant="outlined"
         label=" Field 1"
          value={age}
          onChange={(e)=>setAge(e.target.value)}
          displayEmpty
          className={classes.selectEmpty}
        >
            {fonts.map((v,i)=>
            <MenuItem value={v}>{v}</MenuItem>
            )}
        </Select>
      </FormControl>
      <Typography style={{marginRight:20,marginTop:'revert'}}>+</Typography>    
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel variant="outlined" >
        Field 2
        </InputLabel>
        <Select 
        label=" Field 2"
        style={{marginRight:20}}
         variant="outlined"
          value={age2}
          onChange={(e)=>setAge2(e.target.value)}
          displayEmpty
          className={classes.selectEmpty}
        >
            {fonts.map((v,i)=>
            <MenuItem value={v}>{v}</MenuItem>
            )}
        </Select>
      </FormControl>
             </div>
          </DialogContentText>
    
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
