import React, { useState,useEffect,useLayoutEffect } from 'react';
import axios from 'axios';
import Style from './StudentList.module.css'


const StudentList = (props) => {
  const [ApiData, setApiData] = useState([])
  const [loginedUser, setLoginedUser] = useState("");
  const [names, setNames] = useState([]);

const apiHandler = () => {
  const token= localStorage.getItem('token')?JSON.parse(localStorage.getItem("token")):""
  axios.post("https://api.paykit.in/get-txn-list", {}
    , {
      headers: { Authorization: token }
    }).then(res =>{
      console.log(res.data.txns)
       setApiData(res.data.txns)})
    .catch(err => console.log(err))
}
useEffect(() => {
  apiHandler()
}, [])



useLayoutEffect(() => {
  if(localStorage.getItem('loginid')){
    setLoginedUser(JSON.parse(localStorage.getItem("loginid")))
  }
}, [])

useEffect(() => {
  axios.post("https://marcopolo.prisms.in/api/LinksPay/getWalletidAndName", {},
   { headers: { Authorization: "#$laksdfnlknoea#$@$%^&%$" } })
    .then( res => {    
      setNames(res.data)
    }).catch(err => { console.log(err) })}
, []);


const WalletidToName =(id)=>{
  const temp = names.find(element=>element.walletId==id)
  if(temp){
  return temp.name
  }
  return
}




  return <>
  {ApiData.length>0?
   ApiData.map((val,index )=>
    (<div key={index} className={Style.main}>
      {/* {props.isPos ? "" : <div className={Style.txnid}><p>{val.txnid}</p></div>} */}
      <div className={Style.name}>
        <div className={Style.name__wrapper}>
          <p>From : {WalletidToName(val.from)}</p>
          <p>To: {WalletidToName(val.to)}</p>
          </div>
               
      
        <div className={Style.amount}>
          
          <img src={(val.from).slice(0,9) === loginedUser?"../../icons/wallet/Minus.PNG":"../../icons/wallet/Add.PNG"} alt="" />
          <p>{val.amount}</p>
        </div>
      </div>
      {props.isPos ? "" : <div className={Style.datetimepurpose}>
        <p>Date: {(val.datetime).slice(0,17)}</p>
        <p>Time: {(val.datetime).slice(18,22)}</p>

        <p>Purpose: {val.purpose}</p>
        
      </div>}
      
    </div>)):
    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
      <p>No transactions done yet!</p>
    </div>
    }
  </>;
};

export default StudentList;
