import React, { useState, useEffect } from 'react';
// import '../AddNewQuiz.css'
import "../css/AddNewQuiz.css"
import { useHistory, Redirect, useParams } from "react-router-dom";
import axios from 'axios';
import Navbar from '../User/navbar'
import QMMultiselect from './QMMultiselect';
import moment from 'moment'
import { debounce } from '@material-ui/core';
import Swal from 'sweetalert2';

export default function AddNewQuiz() {

  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <Quiz />
    </div>
  )
}

function Quiz() {

  const history = useHistory();
  const { newSid ,sid, uid } = useParams();
  const hArray = history.location.state;
  const [classes, setclasses] = useState([]);
  const [div, setdiv] = useState([]);
  const [divnm, setdivnm] = useState([]);
  const [classSelected, setclassSelected] = useState('');
  const [divSelected, setdivSelected] = useState('');
  const [attempts, setattempts] = useState('');
  const [duration, setduration] = useState('');

  useEffect(() => {
    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "GetClassDiv", sid: sid, u_id: uid })
      .then(async res => {
        let a = [];
        let clss = [];
        try {
          await res.data.div.map(c => {
            if (c.class_name !== null) {
              a.push(c);
              clss.push(c.class_name);
            }
          })
        } catch (e) { console.error(e); }
        setclasses([...new Set(clss)]);
        setdiv(a);
        setdivnm(a);
        if (hArray.actiontodo !== "addquiz") {
          setclassSelected(hArray.class.split(',')); setdivSelected(hArray.division.split(',')); setattempts(hArray.attempts); setduration(hArray.duration);
        }
      })

  }, []);

  useEffect(() => {
    try {
      classSelected === '' ? setdivnm(div) : setdivnm(div.filter((d) => { return classSelected.includes(d.class_name) }))
    } catch (e) { console.error(e); }
  }, [classSelected]);


  if (hArray === undefined) {
    return <Redirect to={'/quizapp' + '/' + sid + '/' + uid} />
  }
  sessionStorage.setItem("lpath", history.location.pathname)

  const createQuiz = async () => {
    let classnmid = [];
    let divnmid = [];
    div.map((i) => {
      if (divSelected.includes(i.div_name)) {
        if (divnmid.indexOf(i.divid) === -1)
          divnmid.push(i.divid)
        if (classnmid.indexOf(i.class) === -1)
          classnmid.push(i.class)
      }
    })
    let formData = Array.from(document.getElementsByTagName('form')[0]).reduce((a, i) => (
      {
        ...a, [i.name === "" ? "invalidMS" : i.name]: i.value
      }
    ), {});
    delete formData.invalidMS
    console.log(formData)
    if (formData.quizName !== "") {
      if (formData.duration !== "") {
        if (formData.class !== "" && formData.class !== "Choose Class") {
          if (formData.division !== "" && formData.division !== "Choose Division") {
            if (formData.publishDate !== "") {
              if (formData.publishTime !== "") {
                if (formData.attempts !== "") {
                  try {
                    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', {
                      fun_name: "Quizapp_Notif", sid: sid, userid: uid, quizid: formData.id, classid: classnmid.join(","), divid: divnmid.join(","),
                      action: `${formData.quizName}${formData.quizName.toLowerCase().includes("quiz") ? "" : " quiz"} is ${hArray.actiontodo === "addquiz" ? "published on " + moment(formData.publishDate + " " + formData.publishTime, "YYYY-MM-DD HH:mm:ss").format('LLLL') : "updated"} `
                    })
                    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
                      {
                        fun_name: hArray.actiontodo === "addquiz" ? "Quizapp_AddQuiz" : "Quizapp_UpdateQuiz", sid: newSid, sid_school:sid, userid: parseInt(uid), id: formData.id, quizName: formData.quizName,
                        duration: formData.duration, class: formData.class, division: formData.division,
                        negativeMarking: formData.negativeMarking, questionOrder: formData.questionOrder, quizStatus: formData.quizStatus,
                        publishDate: formData.publishDate, publishTime: formData.publishTime, attempts: formData.attempts, review: formData.review
                      })
                      .then(history.push(hArray.actiontodo === "addquiz" ? '/quizapp_all/createquestion' + '/' + newSid + '/' + sid + '/' + uid : '/quizapp/editquiz/quizUpdated' + '/' + sid + '/' + uid, hArray.actiontodo === "addquiz" ? { ...formData, actiontodo: 'addquestion', nmark: formData.negativeMarking } : { ...formData, actiontodo: "updatequiz" }));
                  } catch (e) { console.error(e); }
                } else Swal.fire('Warning', 'Please enter no of attempts', 'error');
              } else Swal.fire('Warning', 'Please choose publish time', 'error');
            } else Swal.fire('Warning', 'Please choose publish date', 'error');
          } else Swal.fire('Warning', 'Please choose division', 'error');
        } else Swal.fire('Warning', 'Please choose class', 'error');
      } else Swal.fire('Warning', 'Please enter duration', 'error');
    } else Swal.fire('Warning', 'Please enter quiz name', 'error');
  }

  function divcheckingfun(div, clas) {
    let finalArray = [];
    try {
      if (div.length !== 0) {
        div.forEach(item => {
          if (item.split("-")[0] === "A" || item.split("-")[0] === "B") {
            if (clas.includes('Jr Kg')) finalArray.push(item);
          } else if (item.split("-")[0] === "12") {
            if (clas.includes('XII')) finalArray.push(item);
          } else {
            if (clas.includes(item.split("-")[0])) finalArray.push(item);
          }
        })
      }
    } catch (e) { console.error(e); }
    setclassSelected(clas)
    setdivSelected(finalArray);
  }

  return (
    <div className="quiz-container">
      <div className="quiz-head">
        <div className="quiz-head-title">{hArray.quizName}</div>
        <Navbar home={{ isHome: true, home: `${sessionStorage.tora}` }} back={true} refresh={false} />
      </div>
      <hr className="hr-line" />
      <div className="quiz-list-items">
        <div className="quiz-form">
          <form id="form" autoComplete="off">
            <input type="number" style={{ display: 'none' }} name="id" defaultValue={hArray.id}></input>

            <div className="form-div">
              <label style={{fontFamily:'Poppins' }}>Quiz Name</label>
              <input type="text" placeholder="Enter Quiz Name" name='quizName' defaultValue={hArray.actiontodo === "addquiz" ? null : hArray.quizName} />
            </div>
            <div className="form-div">
              <label style={{fontFamily:'Poppins' }}>Duration</label>
              <input
                type="text"
                placeholder="Enter Duration in mins or 0 for unlimited"
                name="duration"
                value={duration}
                onChange={(e => setduration(e.target.value.replace(/\D/g, "")))}
              />
            </div>
            <div className="form-div">
              <label style={{fontFamily:'Poppins' }} >Class</label>
              <QMMultiselect data={classes} initialValues={classSelected} selected={(params => { divcheckingfun(divSelected, params); })} name="class" placeholder="Choose Class" />
            </div>
            <div className="form-div">
              <label style={{fontFamily:'Poppins' }}>Division</label>
              <QMMultiselect data={divnm} initialValues={divSelected} value={"div_name"} label={"div_name"} selected={(params => divcheckingfun(params, classSelected))} name="division" placeholder="Choose Division" />
            </div>
            <div className="form-div">
              <label style={{fontFamily:'Poppins' }}>Negative Marking</label>
              <select name="negativeMarking" defaultValue={hArray.actiontodo === "addquiz" ? null : hArray.negativeMarking}>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            <div className="form-div">
              <label style={{fontFamily:'Poppins' }}>Question Order</label>
              <select name="questionOrder" defaultValue={hArray.actiontodo === "addquiz" ? null : hArray.questionOrder}>
                <option value="sequential">Sequential</option>
                <option value="randomized">Randomized</option>
              </select>
            </div>
            <div className="form-div" style={{ display: "none" }}>
              <label style={{fontFamily:'Poppins' }}>Publish Status</label>
              <select name="quizStatus" defaultValue={hArray.actiontodo === "addquiz" ? null : hArray.quizStatus}>
                <option value={0}>Draft</option>
                <option value={1}>Public</option>
              </select>
            </div>
            <div className="form-div">
              <label style={{fontFamily:'Poppins' }}>No of Attempts</label>
              <span style={{ marginTop: "0px", fontSize: "13px", color: "gray" }}>Note : Enter 0 for unlimited attempts</span>
              <input name="attempts"
                type="text"
                placeholder="Enter any number or 0 for unlimited"
                onChange={(e => setattempts(e.target.value.replace(/\D/g, "")))}
                value={attempts} />
            </div>
            <div className="form-div">
              <label style={{fontFamily:'Poppins' }}>Review</label>
              <select name="review" defaultValue={hArray.actiontodo === "addquiz" ? null : hArray.review}>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="form-div">
              <label style={{fontFamily:'Poppins' }} >Publish Date</label>
              <input type="date" placeholder="Enter Quiz Name" name="publishDate" defaultValue={hArray.actiontodo === "addquiz" ? new Date().toISOString().slice(0, 10) : hArray.publishDate} />
            </div>
            <div className="form-div">
              <label style={{fontFamily:'Poppins' }}>Publish Time</label>
              <input type="time" placeholder="Enter Quiz Name" name="publishTime" defaultValue={hArray.actiontodo === "addquiz" ? new Date().toTimeString().slice(0, 5) : hArray.publishTime} />
            </div>
          </form>
        </div>
      </div>
      <div className="button-div">
        <button onClick={debounce(createQuiz, 500)} className="create-button">{hArray.actiontodo === "addquiz" ? "Create Quiz" : "Update Quiz"}</button>
      </div>
    </div>
  );
}


