import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Button,TextField,IconButton} from '@material-ui/core';
import Resizer from 'react-image-file-resizer';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY:'scroll'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UploadImg(props) {
  const classes = useStyles();
  const [imgarr, setImg] = useState([]);
  const [prvw,setPrvw]=useState([]);
  const [filename,setFileName]=useState([]);
  const [fileimg,setFileimg]=useState(null);
  const [FileLocal,setFile]=useState(null);
  const [Foldername,setFolderName]=useState(null);
  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 600, 600, 'JPEG', 100, 0,
    uri => {
      resolve(uri);
    },
    'base64'
    );
  });
const handleChange= async(e)=> {
    var ImPr=[];
    var fileupl=[];
    var filename11=[];
    var file=null;
    // let FileO=[];
    const filesArr = Array.from(e.target.files);
    var len=filesArr.length;
      
    for(var i=0;i<len;i++){        
      var f=  filesArr[i];
      let ext1=filesArr[i].type.substr(0,filesArr[i].type.lastIndexOf('/'))
      let file2= await resizeFile(f);
      var arr = file2.split(','),
           mime = arr[0].match(/:(.*?);/)[1],
           
           bstr = atob(arr[1]), 
           n = bstr.length, 
           u8arr = new Uint8Array(n);
           
        while(n--){
           u8arr[n] = bstr.charCodeAt(n);
        }
        let FileO= new File([u8arr], filesArr[i].name, {type:mime});
        ImPr.push(file2);
       fileupl.push(FileO);
       props.file(FileO)
       FileO=null;
    
         arr=null;
         u8arr=null;
    }
    setPrvw(ImPr);
    setFile(fileupl);
    setFileName(filename11)
    
}

  return (
          <div >
           {FileLocal!=null && prvw!=null? <div>
              { prvw.map((p,i) =><> <img style={{ objectFit:'containe',maxWidth:'40%',maxHeight:'40%'}} src={p}></img>
              
              
              </>)}
              
              </div>:null}
             {FileLocal!=null?<> Remove Image(s) :<IconButton onClick={()=>{setPrvw(null);setFile(null);}}>
                 
           <DeleteForeverIcon color='primary' fontSize='large'/>
          </IconButton></>:null}
              
          <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        key={ Math.random().toString(36)}
       // multiple
        type="file"
        hidden
        onChange={(e)=>handleChange(e)}
      />
       
    { FileLocal==null? <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span" disableElevation startIcon={<CloudUploadIcon />}>
          {props.title==undefined?'Upload Photo':props.title} 
        </Button>
      </label>:null}
      {/* {FileLocal!=null?<><Button disableElevation style={{margin:20}} variant="contained" color="primary" component="span" onClick={()=>{props.Submited();props.isup();}}>
          Submit 
        </Button>
        </>:null} */}
          </div>
    
  );
}
