import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {Divider} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import OptionalForm from './OptionalForm';
 import { GoogleLogin  } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import history from './history';
import axios from 'axios';
import Login from './Login';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  fb:{
    width: '165px',
    height:'35px',
    borderRadius: '4px',
    background: '#3b5998',
    color:'white',
    border:'0px transparent',
    textAlign: 'center',
    margin:'5px',
    display: 'inline-block',
   '&:hover':{
        background: '#3b5998',
        opacity: 0.6,
    }
  },
  Googlebtn:{
    width: '165px',
    height:'35px',
    borderRadius: '4px',
    background: '#3b5998',
    color:'white',
    border:'0px transparent',
    textAlign: 'center',
    margin:'5px',
    display: 'inline-block',
   '&:hover':{
        background: '#3b5998',
        opacity: 0.6,
    }
  }
}));

export default function SignIn(props) {
  const classes = useStyles();
  const [page2,setPage2]=React.useState(false);
  const [form,setForm]=React.useState(false);
  const [email,setEmail]=React.useState(null);
  const [eid,setEid]=React.useState(null);
  const {sid} = useParams();

  const [pass,setPass]=React.useState(null);
  const responseGoogle=(resp)=>{
    // const data= JSON.parse(resp);
    
    var em=resp.profileObj.email;
    console.log(em);
    if(resp.profileObj.email !=null){
     axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?",{['fun_name']:'AlumniLogin',['sid']:sid,['email']:resp.profileObj.email,['glogin']:1}).then((resp)=>{
       console.log(resp);
       if(resp.data.Login==1 ){
        setForm(true);
        setEid(em);
        if(resp.data.user_data.length>0){
          var s={hd:"About",data:resp.data.user_data} ;
         history.push({pathname:"/alumni/about/"+props.match.params.sid,data:s})
        }
      }
     })
    }
   
    console.log(resp);
  
  }
  const responseGoogleFail=(resp)=>{
    window.alert("Error in Google login"+resp);
  }
  const handleOtherOpt=()=>{
    setPage2(false);
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {page2==false && form==false ?<div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in 
        </Typography>
        <Typography component="h1" variant="h5">
          Alumni App
        </Typography>
        
          <div style={{display:'flex',justifyContent:'center',marginTop:'12vh',marginBottom:30}}>
          <GoogleLogin 
    clientId={process.env.REACT_APP_API_KEY}
    buttonText="Login With Google"
    // className={classes.Googlebtn}
    onSuccess={responseGoogle}
    
    cookiePolicy={'single_host_origin'}
    isSignedIn={false}
  />
  </div>
  <Button variant="outlined" color="primary" onClick={()=>setPage2(true)}>
        <LockOutlinedIcon /> <p style={{marginLeft:10,marginBottom:0}}>Sign in With Email or Phone Number </p>
      </Button>
       
      </div>:page2==true && form==false?<Login sid={props.match.params.sid}/>:form==true?<OptionalForm sid={props.match.params.sid} email={eid}/>:null}
    </Container>
  );
}