import Styles from './Student.module.css'
import React from 'react'

const Filter = () => {
  return <>
    <div className={Styles.main__filter}>
      <h1>Filters</h1>
      <div className={Styles.info__div}>
        <div className={Styles.date__div}>
          <div className={Styles.grid__div1}> </div>
          <div className={Styles.grid__div2}>Date </div>
          <div className={Styles.grid__div3}>Start </div>
          <div className={Styles.grid__div4}>End </div>
          <div className={Styles.grid__div5}>
            <input type="date" />
          </div>
          <div className={Styles.grid__div6}>
            <input type="date" />
          </div>
        </div >
        <div>User Id</div>
        <div>POS</div>
        <div>Transaction ID</div>
      </div >
    </div >
  </>;
};

export default Filter;
