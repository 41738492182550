import React from "react";
import styled from "styled-components";
import Navbar from "../Components/Navbar";
import Tab from "../Components/Tab";
import { useParams } from "react-router-dom";
import { DecryptED } from "../../../DecryptJS/Decrypt";

const Dashboard = () => {
  let {sid} = useParams();
  let newSid =  DecryptED(sid);

  return (
    <Container>
      <Navbar />
      <Tab sid={newSid}/>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;


