import React, { useEffect, useState } from 'react';
import CreateForm from './CreateForm'
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import DeleteIcon from '@mui/icons-material/Delete';
const DynamicHome = () => {
    const url = window.location.href;
    const { sid } = useParams();
    const [fields, setFields] = useState([]);
    const [avalFileds, setAvailFields] = useState([]);
    const [labelName, setLableName] = useState("");
    const [inputType, setInputType] = useState("");
    const [customField, setCustomFiled] = useState(false);

    const [apiloader, setapiLoader] = useState();

    //  const [startDate, setstartDate] = useState(new Date())
    // const [endDate, setendDate] = useState(new Date())

    const [formdata, setFormData] = useState({
        "sid": sid,
        "FormTitle": "",
        "startDate": new Date(),
        "endDate": new Date(),
        "acadYear": ''
    })

    const addCostomField = (e) => {
        e.preventDefault();
        setFields([...fields, { Title: labelName, type: inputType, status: "custom", value: "" }]);
    };

    const getFields = async () => {
        setapiLoader(true);
        try {
            if (!sid) {
                throw "Sid Required"
            }
            const data =
            {
                "sid": sid
            }
            await axios({
                method: "POST",
                //url: `http://localhost:8080/api/information/title`,
                url: `${process.env.REACT_APP_BASE_URL}/api/information/title`,
                data,
                headers: {
                    Authorization: "#$laksdfnlknoea#$@$%^&%$",
                },
            })
                .then((response) => {
                    setAvailFields(response.data)
                    setapiLoader(false);
                })
                .catch((error) => {
                    setapiLoader(false);
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: error,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        } catch (error) {
            setapiLoader(false);
            Swal.fire({
                position: "center",
                icon: "warning",
                title: error,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }

    const getAcadYear = async () => {

        try {
            if (!sid) {
                throw "Sid Required"
            }
            const data =
            {
                "sid": sid
            }
            await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASE_URL}/api/tscommon/getCurrentAcad`,
                data,
                headers: {
                    Authorization: "#$laksdfnlknoea#$@$%^&%$",
                },
            })
                .then((response) => {
                    const AcadID = response.data[0].acadid
                    setFormData(prevData => ({
                        ...prevData,
                        acadYear: AcadID
                    }));

                })
                .catch((error) => {
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: error,
                        showConfirmButton: false,
                        timer: 1500,
                    });

                });
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: error,
                showConfirmButton: false,
                timer: 1500,
            });

        }
    }

    const InsertAvailFields = (e) => {
        setFields([...fields, avalFileds[e]]);
        console.log(avalFileds[e]);
    }
    useEffect(() => {
        getFields();
        getAcadYear();
    }, [])

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-white">
                <b className="navbar-brand">Create Form</b>

                <div className="" >
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item ">
                            <NavLink to={`/dynamic/myforms/${sid}`}>Exising forms</NavLink>
                        </li>
                    </ul>
                </div>
            </nav>

            {
                apiloader ? (
                    <div className="TMLoader">
                        <Loader type="ThreeDots" color="#bd2f5e" height={80} width={80} />
                    </div>
                ) :

                    <div className='container mt-3' id='mainContainer'>

                        <div className='d-flex flex-column justify-content-center mx-auto' style={{ maxWidth: "500px" }}>
                            <div className=''>
                                <div>
                                    <input type='text' className='form-control' placeholder='Enter Title' onChange={(e) => setFormData({ ...formdata, FormTitle: e.target.value })} />
                                    <div className="row">
                                        <div className='col-md-6'>
                                            <label htmlFor='startDate'>Start Date</label>
                                            {/* <input type='date' className='form-control' id='startDate' placeholder='Start date' onChange={(e) => setFormData({ ...formdata, startDate: e.target.value })} /> */}
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={formdata.startDate}
                                                onChange={(date) => setFormData({ ...formdata, startDate: date })}
                                            />
                                        </div>

                                        <div className='col-md-6'>
                                            <label htmlFor='endDate'>{" "}End Date</label>
                                            {/* <input type='date' className='form-control' id='endDate' placeholder='End date' onChange={(e) => setFormData({ ...formdata, endDate: e.target.value })} /> */}
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={formdata.endDate}
                                                onChange={(date) => setFormData({ ...formdata, endDate: date })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <CreateForm fields={fields} setFields={setFields} formdata={formdata} setapiLoader={setapiLoader} />

                                <hr className="my-3" />

                                {/* <div className='form-group'>
                                    <select className='form-control' onChange={(e) => showElements(e.target.value)}>
                                        <option value={false}>Existing Fields</option>
                                        <option value={true}>Custom Fields</option>
                                    </select>
                                </div> */}
                                <form>
                                        <p style={{ fontSize: '15px' }} className='ms-0'>SELECT INPUT FIELDS: </p>
                                    <div className='form-group'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="ExistingF" id="ExistingFields" onChange={(e) => setCustomFiled(false)} checked={customField === false} />
                                            <label className="form-check-label" htmlFor="exampleRadios1">
                                                Existing Fields
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="CustomF" id="CustomFields" onChange={(e) => setCustomFiled(true)} checked={customField === true} />
                                            <label className="form-check-label" htmlFor="exampleRadios2">
                                                Custom Fields
                                            </label>
                                        </div>
                                    </div>
                                </form>



                                {customField ?
                                    <form onSubmit={(e) => { addCostomField(e) }} className='mb-3'>

                                        <div className="form-group">
                                            <label htmlFor="sel1">Select Input Type:</label>
                                            <select className="form-control" id="sel1" onChange={(e) => setInputType(e.target.value)}>
                                                <option defaultValue={"text"}>Select following</option>
                                                <option value={"label"}>Label</option>
                                                <option value={"text"}>Text</option>
                                                <option value={"number"}>Number</option>
                                                {/* <option value={"file"}>File</option> */}
                                            </select>

                                        </div>

                                        <div className="form-group">
                                            <label >Label Name:</label>
                                            <input
                                                type='text'
                                                name={"inputNames"}
                                                placeholder="Field Name"
                                                onChange={(e) => setLableName(e.target.value)}
                                                required
                                                className='form-control'
                                            />

                                        </div>
                                        {/* <input type='submit' className='btn btn-primary m-0' value={"Add Field"} /> */}
                                        <button className='btn btn-primary m-0'>Add Field</button>
                                    </form> :
                                    <select className='form-control' onChange={(e) => InsertAvailFields(e.target.value)}>
                                        <option >Select Fields</option>
                                        {
                                            avalFileds.length ? avalFileds.map((field, index) => (
                                                <option value={index} key={index}>{field.Title}</option>
                                            )) : ""
                                        }
                                    </select>
                                }

                            </div>

                        </div>

                    </div>
            }

        </>
    )
}

export default DynamicHome