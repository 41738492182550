import React, { version } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import {ListItem,ListItemSecondaryAction,Divider,ListItemText,ListItemAvatar,IconButton } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    minHeight:'20%',
    padding:5,
    backgroundColor: theme.palette.background.paper,
 
  },
  inline: {
    display: 'inline',
    padding:5,
  },
  img:{
    maxWidth:"60%",
    minWidth:"60%",
    minHeight:"60%",
    maxHeight:"60%",
  },
 
}));

export default function CartList(props) {
  const classes = useStyles();
  return (
    <> 
    {

    props.data.map((v,i)=>(
<List className={classes.root}>
      <ListItem style={{padding:1}} alignItems="flex-start">
        <ListItemAvatar >
          <Avatar alt={v.p_name} variant="square" className={classes.img}
          src={props.data.prod_img=="" || v.prod_img==null ?
          "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS6Dw1Etemhe9K_LEK8pXzVO0X2tnqkMW8q4Q&usqp=CAU" :v.prod_img.includes(',')?
          v.prod_img.substr(0,v.prod_img.indexOf(',')):v.prod_img
          }
          />
        
      
        </ListItemAvatar>
        <ListItemText 
          primary={<>{v.p_name}   <p className={classes.inline} style={{color:'#12C407'}}>&#x20B9;{v.purchase_price}</p>  </>}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {v.p_details}
              </Typography>
            </React.Fragment>
          }
        />

      </ListItem>
      <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={()=>props.del(v,i)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
      <Divider variant="inset" component="li" />
         
    </List>
    ))}
    </>);
    }
    