import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./ReportView.css";
import { TextField, Button } from "@material-ui/core";
import axios from "axios";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import { DecryptED } from "../../DecryptJS/Decrypt";

const useStyles = makeStyles(() => ({
  headingDiv: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginBottom: "20px",
    backgroundColor: "white",
  },
  labelDiv: {
    width: "25%",
  },
  inputDiv: {
    width: "65%",
    // marginLeft: "50px",
  },
  inputStyle: {
    backgroundColor: "rgb(111, 214, 255)",
    color: "white",
    width: "100%",
    fontSize: "1rem",
    height: "35px",
    border: "none",
    borderRadius: "5px",
  },
  ["@media(max-width:800px)"]: {
    inputStyle: {
      marginLeft: "10px",
    },
  },
  selectStyle: {
    backgroundColor: "rgb(111, 214, 255)",
    color: "white",
    width: "100%",
    fontSize: "1rem",
    height: "35px",
  },
  durationDiv: {
    display: "flex",
  },
  ["@media(min-width:800px)"]: {
    durationDiv: {
      width: "50%",
    },
  },
}));

const ReportView = () => {
  const classes = useStyles();
  const history = useHistory();
  let { sid, userid, reportid } = useParams();

  const [reportData, setReportData] = useState([]);
  let dataObj = reportData[0];
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [lessonName, setLessonName] = useState("");
  const [lessonObjective, setLessonObjective] = useState("");
  const [learningOutcome, setLearningOutcome] = useState("");
  const [pedagogicalPractices, setPedagogicalPractices] = useState("");
  const [audioVisualAdis, setAudioVisualAdis] = useState("");
  const [teacherPreparation, setTeacherPreparation] = useState("");
  const [studentPreparation, setStudentPreparation] = useState("");
  const [additionalExtended, setAdditionalExtended] = useState("");
  const [interdisciplinaryLinkage, setInterdisciplinaryLinkage] = useState("");
  const [infusion, setInfusion] = useState("");
  const [artIntegration, setArtIntegration] = useState("");
  const [storyBased, setStoryBased] = useState("");
  const [artificialIntelligence, setArtificialIntelligence] = useState("");
  const [fln, setFln] = useState("");
  const [remedialPlan, setRemedialPlan] = useState("");
  const [cwsnPlan, setCwsnPlan] = useState("");
  const [assignment, setAssignment] = useState("");
  const [referenceUsed, setReferenceUsed] = useState("");
  const [classId, setClassId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [lessonDuration, setLessonDuration] = useState("");
  const [month, setMonth] = useState("");
  const [subject, setSubject] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();

    const data = {
      fun_name: "updateLessonplanData",
      staff_id: DecryptED(userid),
      sid: DecryptED(sid),
      id: reportid,
      month,
      subject_id: `${subjectId}`,
      classdiv_id: `${classId}`,
      Lessonname: lessonName,
      Lessonduration: `${lessonDuration}`,
      lesson_objective: lessonObjective,
      learning_outcome: learningOutcome,
      pedagogical_practices: pedagogicalPractices,
      audio_visual_adis: audioVisualAdis,
      teacher_preparation: teacherPreparation,
      student_preparation: studentPreparation,
      additional_extended_information: additionalExtended,
      interdisciplinary_linkage: interdisciplinaryLinkage,
      infusion_with_life_skills: infusion,
      art_integration: artIntegration,
      story_based: storyBased,
      artificial_intelligence: artificialIntelligence,
      fln: fln,
      remedial_plan: remedialPlan,
      cwsn_plan: cwsnPlan,
      assignment: assignment,
      reference_used: referenceUsed,
    };
    const response = await axios.post(
      "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
      data
    );
    if (response) {
      // window.alert(
      //   `LessonPlan for ${lessonName} has been updated successfully!`
      // );
      Swal.fire({
        position: "center",
        icon: "success",
        title: `LessonPlan for ${lessonName} has been updated successfully!`,
        showConfirmButton: false,
        timer: 1500,
      });
      history.push(`/report/${sid}/${userid}`);
    }
  };

  useEffect(() => {
    const getReportData = async () => {
      const data = {
        fun_name: "getLessonPlanByID",
        staff_id: DecryptED(userid),
        sid: DecryptED(sid),
        id: reportid,
      };
      const response = await axios.post(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data
      );
      if (response) {
        setReportData(response.data.result);
        const obj = response.data.result[0];
        setLessonName(obj.Lessonname);
        setSubject(obj.subject);
        setClassId(obj.classdiv_id);
        setLessonDuration(obj.Lessonduration);
        setLessonObjective(obj.lesson_objective);
        setLearningOutcome(obj.learning_outcome);
        setPedagogicalPractices(obj.pedagogical_practices);
        setAudioVisualAdis(obj.audio_visual_adis);
        setTeacherPreparation(obj.teacher_preparation);
        setStudentPreparation(obj.student_preparation);
        setAdditionalExtended(obj.additional_extended_information);
        setInterdisciplinaryLinkage(obj.interdisciplinary_linkage);
        setInfusion(obj.infusion_with_life_skills);
        setArtIntegration(obj.art_integration);
        setStoryBased(obj.story_based);
        setArtificialIntelligence(obj.artificial_intelligence);
        setFln(obj.fln);
        setRemedialPlan(obj.remedial_plan);
        setCwsnPlan(obj.cwsn_plan);
        setAssignment(obj.assignment);
        setReferenceUsed(obj.reference_used);
        setMonth(obj.month);
        setLessonDuration(obj.Lessonduration);
      }
    };
    getReportData();

    const getAccessableClass = async () => {
      const data = {
        fun_name: "getAccessClass",
        sid: DecryptED(sid),
        staff_id: DecryptED(userid),
      };
      const response = await axios.post(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data
      );
      if (response) {
        setClassList(response.data.result);
      }
    };
    getAccessableClass();
  }, []);
  return (
    <div className="form-main">
      <div className={classes.headingDiv}>
        <p>LESSON PLAN</p>
      </div>
      <form className="form">
        <div className="user-details">
          <div className="form-details">
            <div className="form-inputs">
              <div className={classes.labelDiv}>
                <label className="label__title">Class</label>
              </div>
              <div className={classes.inputDiv}>
                {classList &&
                  classList.map((data, idx) => {
                    if (data.id === classId) {
                      return (
                        <input
                          style={{
                            backgroundColor: "rgb(111, 214, 255)",
                            color: "white",
                            width: "100%",
                            fontSize: "1rem",
                            border: "none",
                            height: "35px",
                            borderRadius: "5px",
                          }}
                          value={data.div_name}
                          id="lessonName"
                          type="text"
                          placeholder=""
                        />
                      );
                    }
                  })}
              </div>
            </div>
            <div className="form-inputs">
              <div className={classes.labelDiv}>
                <label className="label__title">Month</label>
              </div>
              <div className={classes.inputDiv}>
                <input
                  style={{
                    backgroundColor: "rgb(111, 214, 255)",
                    color: "white",
                    width: "100%",
                    fontSize: "1rem",
                    height: "35px",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  onChange={(e) => setMonth(e.target.value)}
                  name="month"
                  id="month"
                  type="month"
                  placeholder=""
                  value={month}
                />
              </div>
            </div>
          </div>
          <div className="form-details">
            <div className="form-inputs">
              <div className={classes.labelDiv}>
                <label className="label__title">Subject</label>
              </div>
              <div className={classes.inputDiv}>
                <input
                  style={{
                    backgroundColor: "rgb(111, 214, 255)",
                    color: "white",
                    width: "100%",
                    fontSize: "1rem",
                    border: "none",
                    height: "35px",
                    borderRadius: "5px",
                  }}
                  value={subject}
                  id="lessonName"
                  type="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="form-inputs">
              <div className={classes.labelDiv}>
                <label className="label__title">Lesson Name</label>
              </div>
              <div className={classes.inputDiv}>
                <input
                  style={{
                    backgroundColor: "rgb(111, 214, 255)",
                    color: "white",
                    width: "100%",
                    fontSize: "1rem",
                    border: "none",
                    height: "35px",
                    borderRadius: "5px",
                  }}
                  value={lessonName}
                  onChange={(e) => setLessonName(e.target.value)}
                  id="lessonName"
                  type="text"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className={classes.durationDiv}>
            <div className={classes.labelDiv}>
              <label className="label__title">Lesson Duration</label>
            </div>
            <div className={classes.inputDiv}>
              <input
                style={{
                  backgroundColor: "rgb(111, 214, 255)",
                  color: "white",
                  width: "100%",
                  fontSize: "1rem",
                  border: "none",
                  height: "35px",
                  borderRadius: "5px",
                }}
                value={lessonDuration}
                id="lessonName"
                type="text"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div className="mid-details">
          <div>
            <TextField
              fullWidth
              multiline
              minRows={3}
              id="outlined-basic"
              label="Lesson Objective"
              variant="outlined"
              size="small"
              value={lessonObjective}
              onChange={(e) => setLessonObjective(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              multiline
              minRows={3}
              id="outlined-basic"
              label="Learning Outcome"
              variant="outlined"
              size="small"
              value={learningOutcome}
              onChange={(e) => setLearningOutcome(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              multiline
              minRows={3}
              id="outlined-basic"
              label="Pedagogical Practices"
              variant="outlined"
              size="small"
              value={pedagogicalPractices}
              onChange={(e) => setPedagogicalPractices(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              multiline
              minRows={3}
              id="outlined-basic"
              label="Audio/VisualAids"
              variant="outlined"
              size="small"
              value={audioVisualAdis}
              onChange={(e) => setAudioVisualAdis(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Teacher Preparation"
              variant="outlined"
              size="small"
              value={teacherPreparation}
              onChange={(e) => setTeacherPreparation(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Student Preparation"
              variant="outlined"
              size="small"
              value={studentPreparation}
              onChange={(e) => setStudentPreparation(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Additional Extended Information"
              variant="outlined"
              size="small"
              value={additionalExtended}
              onChange={(e) => setAdditionalExtended(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Interdisciplinary Information"
              variant="outlined"
              size="small"
              value={interdisciplinaryLinkage}
              onChange={(e) => setInterdisciplinaryLinkage(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Infusion with Life Skills"
              variant="outlined"
              size="small"
              value={infusion}
              onChange={(e) => setInfusion(e.target.value)}
            />
          </div>
        </div>
        <div className="form-supplemental">
          <p>Supplemental Activites</p>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Art-Integration"
              variant="outlined"
              size="small"
              value={artIntegration}
              onChange={(e) => setArtIntegration(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Story Based"
              variant="outlined"
              size="small"
              value={storyBased}
              onChange={(e) => setStoryBased(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Artificial Intelligence"
              variant="outlined"
              size="small"
              value={artificialIntelligence}
              onChange={(e) => setArtificialIntelligence(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="FLN"
              size="small"
              value={fln}
              onChange={(e) => setFln(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Remedial Plan"
              size="small"
              value={remedialPlan}
              onChange={(e) => setRemedialPlan(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="CWSN Plan"
              size="small"
              value={cwsnPlan}
              onChange={(e) => setCwsnPlan(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Assignment"
              size="small"
              value={assignment}
              onChange={(e) => setAssignment(e.target.value)}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Reference Used"
              size="small"
              value={referenceUsed}
              onChange={(e) => setReferenceUsed(e.target.value)}
            />
          </div>
        </div>
        <div className="btn-div" style={{ marginTop: "20px" }}>
          <button
            style={{
              height: "40px",
              color: "white",
              backgroundColor: "rgb(111, 214, 255)",
              border: "none",
              outline: "none",
              fontSize: "18px",
              padding: "0px 16px",
              borderRadius: "6px",
              cursor: "pointer",
            }}
            onClick={(e) => submitForm(e)}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReportView;
