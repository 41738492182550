import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import Loader from 'react-loader-spinner'

export default function GroupMeetings() {
    const history = useHistory();
    const { sid, uid } = useParams();
    const [meetings, setmeetings] = useState('');
    useEffect(() => {
        const formData = new FormData();
        formData.append('uid', `%"${uid}"%`);
        formData.append('part', `%"s${sid}u${uid}"%`);
        formData.append('fun_name', "GetSpuerAStatusByP");
        formData.append('sid', sid);
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', formData).then((res) => {
            setmeetings(res.data.AStatus)
        })
    }, []);

    return (
        <div className="APMCard">
            <div className="APMCardSub">
                <div className="APHeader">
                    <img onClick={() => { history.goBack() }} src="/icons/back.png" alt="back" /><div>Group Meetings</div>
                </div>
                <hr className="APhr-line"></hr>
                <div style={{ overflow: "scroll", height: "100%" }}>
                    {
                        meetings === '' ?
                            <div className="APLoader">
                                <Loader
                                    type="Puff"
                                    color="#1e5597"
                                    height={80}
                                    width={80}
                                />
                            </div>

                            :
                            meetings === undefined || meetings.length < 1 ?
                                <div style={{ padding: "10px", color: "#1e5597", fontWeight: 600, width: "90%", height: "50vh", alignItems: "center", justifyContent: "center", display: "flex", textAlign: "center" }}>
                                    No Meetings
                                </div> :
                                <div style={{ marginBottom: "100px" }}>
                                    {meetings.map((m, k) => {
                                        return (
                                            <div key={k} style={{ padding: "10px", color: "#1e5597", fontWeight: 600 }}>
                                                <div>
                                                    {k + 1} . {m.subject}
                                                </div>
                                                <div className="APAppoinmentDetails">
                                                    <div className="APAppoinmentDetailsSub1">
                                                        <div>
                                                            <img src={'/icons/person.png'} alt="pending" style={{ width: "21px" }} />&nbsp; {m.person}
                                                        </div>
                                                        <div>
                                                            <img src={'/icons/calendar.png'} alt="pending" style={{ width: "16px" }} />&nbsp; {(m.requestDate).split('-').reverse().join('-')}
                                                        </div>
                                                    </div>
                                                    <div className="APAppoinmentDetailsSub2" style={{ fontSize: "100%", margin: "0px" }}>
                                                        <div>
                                                            Scheduled at
                                                        </div>
                                                        <div style={{ color: "#00a500" }}>
                                                            Time - {m.requestStatus}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {k + 1} . {m.subject}
                                                </div>
                                                <div className="APAppoinmentDetails">
                                                    <div className="APAppoinmentDetailsSub1">
                                                        <div>
                                                            <img src={'/icons/person.png'} alt="pending" style={{ width: "21px" }} />&nbsp; {m.person}
                                                        </div>
                                                        <div>
                                                            <img src={'/icons/calendar.png'} alt="pending" style={{ width: "16px" }} />&nbsp; {(m.requestDate).split('-').reverse().join('-')}
                                                        </div>
                                                    </div>
                                                    <div className="APAppoinmentDetailsSub2" style={{ fontSize: "100%", margin: "0px" }}>
                                                        <div>
                                                            Scheduled at
                                                        </div>
                                                        <div style={{ color: "#00a500" }}>
                                                            Time - {m.requestStatus}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                    }
                </div>
            </div>
        </div>
    );
}
