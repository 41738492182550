import React from 'react';
import { useHistory } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function BookSlot(props) {
    const history = useHistory();
    const AppointmentsBooked = props.data;
    const personslist = props.personslist.length > 1 ? props.personslist.sort((a, b) => -b.type.localeCompare(a.type)) : props.personslist;
    const { reschedule, date, Slot } = props;
    const slots = [
        "1:00 PM", "1:30 PM", "2:00 PM",
        "2:30 PM", "3:00 PM", "3:30 PM",
        "4:00 PM", "4:30 PM", "5:00 PM",
        "5:30 PM", "6:00 PM", "6:30 PM",
        "7:00 PM", "7:30 PM", "8:00 PM",
        "8:30 PM", "9:00 PM", "9:30 PM",
        "10:00 PM", "10:30 PM", "11:00 PM",
        "11:30 PM", "12:00 PM", "12:30 PM",
        "1:00 AM", "1:30 AM", "2:00 AM",
        "2:30 AM", "3:00 AM", "3:30 AM",
        "4:00 AM", "4:30 AM", "5:00 AM",
        "5:30 AM", "6:00 AM", "6:30 AM",
        "7:00 AM", "7:30 AM", "8:00 AM",
        "8:30 AM", "9:00 AM", "9:30 AM",
        "10:00 AM", "10:30 AM", "11:00 AM",
        "11:30 AM", "12:00 AM", "12:30 AM"
    ]

    return (
        <div className="APMCard">
            <div className="APMCardSub">
                <div className="APHeader">
                    <img onClick={() => { history.goBack() }} src="/icons/back.png" alt="back" /><div>{reschedule === "true" ? "Reschedule" : "Book Slot"}</div>
                </div>
                <hr className="APhr-line"></hr>
                <div style={{ marginBottom: "30px", width: "100%" }}>
                    {((AppointmentsBooked === '' || typeof (AppointmentsBooked) !== "object") || (reschedule === "true" ? (personslist === '' || typeof (personslist) !== "object") : false)) ?

                        <div className="APLoader"><Loader
                            type="Puff"
                            color="#1e5597"
                            height={100}
                            width={100}
                        /></div> : <>
                            <div className="APDetailsname" style={{ justifyContent: "center", margin: '15px 0px', textAlign: "center", display: AppointmentsBooked.length > 0 ? "block" : "none" }}>
                                Appointments Booked on <br></br> {date !== null && date !== undefined ? date : ""}
                            </div>
                            <div className="APA_booked">
                                <div className="APA_booked_sub">
                                    {AppointmentsBooked.map((i, k) => {
                                        delete slots[slots.indexOf(i.requestStatus)]
                                        return (
                                            <div key={k} className="APA-booked_child">
                                                <span style={{ width: "70%" }}>{i.name}</span>
                                                <span>{i.requestStatus}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="APTimePicker">
                                <img src={'/icons/clock.png'} style={{ width: "35px", marginTop: "10px" }} alt="clock" />
                                <div>
                                    <select value={Slot} onChange={(e) => { props.setSlot(e.target.value) }} style={{ width: "155px" }}>
                                        <option value="">Choose Slot</option>
                                        {
                                            slots.map((i, k) => {
                                                return (
                                                    <option key={k} value={i}>{i}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="APTimePicker" style={{ display: reschedule === "true" ? "flex" : "none" }}>
                                <img src={'/icons/calendar.png'} style={{ width: "27px", marginLeft: "10px" }} alt="clock" />
                                <div className="APform-div" style={{ width: "185px" }}>
                                    <input type="date" value={date} onChange={(e) => { props.setDate(e.target.value); }} />
                                </div>
                            </div>
                            {reschedule === "true" ?
                                <div className="APTimePicker">
                                    <img src={'/icons/person.png'} style={{ width: "40px", marginBottom: "25px" }} alt="person img" />
                                    <div className="APform-div" style={{ width: "210px" }}>
                                        <Autocomplete
                                            id="custom-input-demo"
                                            options={personslist}
                                            getOptionLabel={i => i.first_name + " " + i.middle_name + " " + i.last_name}
                                            onChange={(object, value) => {
                                                if (!value || value === "") {
                                                    props.setPerson({ person: "", staff_id: "" })
                                                } else {
                                                    props.setPerson({ person: value.first_name + " " + value.middle_name + " " + value.last_name, staff_id: value.staff_id })
                                                }
                                            }}
                                            groupBy={(option) => option.type}
                                            renderInput={(params) => (
                                                <div ref={params.InputProps.ref}>
                                                    <input placeholder="Choose Staff" name="person" type="text" {...params.inputProps} />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                : null
                            }
                            <div style={{ alignItems: "center", justifyContent: "center", display: "flex", width: "100%", marginTop: "35px" }}>
                                <div className="APLandingButtons" style={{ padding: "7px 40px", backgroundColor: "#d4faf7" }} onClick={() => { props.OnConfirm() }}>
                                    Confirm
                                </div>
                            </div>
                        </>}
                </div>
            </div>
        </div >
    );
}
