
import React,{useEffect,useState} from 'react';
import {Avatar} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import {useParams} from 'react-router-dom';
 import Alert1 from './Alert';
 import './style.css'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '@global': {
    '*:focus': {
      outline: ' none !important'
    },
  },
 
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#2996ed'
  },
  notchedOutline: {
    borderWidth: "3px",
    borderColor: "#2996ed !important",
  },
  resize:{
    color:'#2996ed'
  },
}));

export default function Quizform(props) {
  const classes = useStyles();
  const [result,setResult]=useState();
  const [submit,setSubmit]=useState();
  const [open, setOpen] = useState(false);
  const [isalert, setAlert] = useState(false);
  const [event, setEvent] = useState(null);
  const [dup, setDup] = useState(false);
  const [state, setState] = useState(false)
  const [dob,setDob]=useState(null)
  const [mob,setMob]=useState(null)
  const [pan,setPan]=useState(null)
  const [name,setName]=useState(null)
  const [lname,setLName]=useState(null)
  const {sid}=useParams();
  useEffect(()=>{
    axios.post('https://dataapi.credin.in/api/shared',{"PRCID":"Score","Method":"getToken","Data":{"username":"uatvendor@credin.in","password":"uatcredin@123"}}).
    then (res=> {
        setResult(res.data.Data)
    })
  },[])

  const handleChange = (event) => {
    console.log(event.target.value);
    setEvent(event.target.value);
  };
const ConsentClose=()=>{
  setOpen(false)
}
  const handlesubmit=()=>{
    if(name!=null && dob!=null && pan!=null && mob!=null)
    {
      if(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(mob) && /^([a-zA-Z0-9 _-]+)$/.test(pan)){
    setOpen(true)
      }
      else{
        window.alert("Please fill the required information correctly")
      }
    }
    else
    window.alert("Please fill the required information")
  }
  const AlertClose=()=>{
    setAlert(false)
  }
    const handleAlert=()=>{
      setAlert(true)
     setOpen(false)
    }
  const Proceed=()=>{
    if(name!=null && mob!=null && pan!=null && dob!=null){
     
   axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
    ["fun_name"]: "Credin_log",
    ["sid"]: sid,
    ['name']:name+' '+lname,
    ['mob']:mob,
    ['pan']:pan,
    ['dob']:dob
  }).then((res1)=>{
    handleAlert();
    axios.post('https://dataapi.credin.in/api/check_approval',{"dob":dob,"firstname":name,"lastname":lname,"mobilenumber":mob,"pan":pan}, 
    { headers: {"Authorization" : `Bearer ${result}`} }
    ).
    then (res=> {
        console.log(res);
    })
   })
}
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const ConsentCheckbox=()=>{
      setState(!state)
  }
  return (
   <Container component="main" maxWidth="xs">
      <CssBaseline />
    
      <div className={classes.paper}>
      <Typography component="h1" variant="h4" style={{textAlign:'center', fontFamily:'Roboto',fontWeight:400}}>
          Pay Fees at
        </Typography>
        <Typography component="h1" variant="h4" style={{textAlign:'center', fontFamily:'Roboto',fontWeight:400}}>
         0% Interest EMI
        </Typography>
        <Typography component="h1" variant="h6" style={{textAlign:'center', fontFamily:'Roboto',fontWeight:400}}  >
          onetime processing fees of 2.99%
        </Typography>
        <Typography component="h6" variant="subtitle1" style={{textAlign:'center', fontFamily:'Roboto',fontWeight:400}}  >
          below information to be filled of Parent
        </Typography>
       {open || isalert? <Alert1 chk={ConsentCheckbox} proceed={Proceed} state={state} open={open} close={ConsentClose} isa={isalert} aclose={AlertClose}/>:null}
        <div className={classes.form}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
                variant="outlined"
                required                
                fullWidth
                name="Parent First Name"
                label="Parent First Name"
                id="Parent First Name"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input:classes.resize
                  }
                }}
                InputLabelProps={{
                  style: { color: '#2996ed' },
                }}
                value={name||''}
                onChange={(e)=>setName(e.target.value)}
                autoComplete="Name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required                
                fullWidth
                name="Parent Last Name"
                label="Parent Last Name"
                id="Parent Last Name"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input:classes.resize
                  }
                }}
                InputLabelProps={{
                  style: { color: '#2996ed' },
                }}
                value={lname||''}
                onChange={(e)=>setLName(e.target.value)}
                autoComplete="Name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={dob}
                onChange={(e)=>setDob(e.target.value)}
                id="DOB"
                InputLabelProps={{
                  shrink: true, 
                  style: { color: '#2996ed' },
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input:classes.resize
                  }
                }}
                value={dob===null?'':dob}
                label="Date Of Birth of Parent"
                name="DOB"
                autoComplete="DOB"
                type="date"
              />
            </Grid>
         
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                     input:classes.resize
                  }
                }}
                InputLabelProps={{
                  style: { color: '#2996ed' },
                }}
                fullWidth
                id="sname"
                value={pan||''}
                onChange={(e)=>setPan(e.target.value)}
                // defaultValue={result!=null? result.data.quiz[0].name:''}
                label="PAN Card Number"
                
              />
            </Grid>  
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input:classes.resize
                  }
                }}
                InputLabelProps={{
                  style: { color: '#2996ed' },
                }}
                fullWidth
                name="Mobile Number"
                label="Mobile Number"
                id="Mobile Number"
                value={mob||''}
                onChange={(e)=>setMob(e.target.value)}
                autoComplete="Mobile Number"
              />
            </Grid>               
          </Grid>
          <Button
            onClick={handlesubmit}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Apply
          </Button>
        </div>
      </div>
    
    </Container>
)}