import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    //maxWidth: '',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  iconButton: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  menuButton: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  root1: {
    overflowX: "hidden",
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    fontFamily:'Poppins'
  },
  appbars: {
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      left: 1,
      width: "auto",
    },
  },
}));

export default function ChatDetails(props) {
  const classes = useStyles();
  const [usr1, setUsers] = useState(props.members);
 
  useEffect(() => {}, []);
  return (
    <div className={classes.root1}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            onClick={() => props.back(false)}
            color="inherit"
            aria-label="open drawer"
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Group Details
          </Typography>
        </Toolbar>
      </AppBar>

      <List className={classes.root} style={{ marginTop: 5 }}>
        <ListItem
          alignItems="center"
          style={{ marginLeft: "30vw", fontWeight: "bold" }}
        >
          <Typography variant="subtitle1">{props.To}</Typography>
        </ListItem>
        <Divider />
        <ListItem alignItems="flex-start">
          <Typography>
            {usr1 != null ? usr1.length : null} participants
          </Typography>
        </ListItem>
        <Divider />

        {usr1 != null
          ? usr1.map((p) => (
              <>
                {/* {console.log(p)} */}
                {p.type == null ? (
                  <ListItem
                    alignItems="center"
                    style={{ justifyContent: "center" }}
                  >
                    <ListItemAvatar>
                      <Avatar alt="User " />
                    </ListItemAvatar>
                    <ListItemText primary={p.name} secondary={""} />
                  </ListItem>
                ) : (
                  <ListItem
                    alignItems="center"
                    style={{
                      justifyContent: "center",
                      backgroundColor: "#dfe6e9",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar alt="User " />
                    </ListItemAvatar>
                    <ListItemText primary={p.name} secondary={p.type} />
                  </ListItem>
                )}

                <Divider variant="inset" component="li" />
              </>
            ))
          : null}
      </List>
    </div>
  );
}
