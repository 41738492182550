import React, { useState, useRef, useEffect } from 'react';
import '../css/AddQuestion.css'
import { useHistory, Redirect, useParams } from 'react-router-dom';
import axios from 'axios'
import { specialChars, power, base } from './specialCharacter'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { TextareaAutosize } from '@material-ui/core';
import Navbar from '../User/navbar'
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Loader from 'react-loader-spinner';
import { debounce } from './debounce';
import Swal from 'sweetalert2';

export default function AddQuestion() {


  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <Quiz />
    </div>
  )
}

function Quiz() {

  const history = useHistory();
  const { sid, uid } = useParams()
  let hdl = history.location.state.actiontodo
  sessionStorage.setItem("lpath", history.location.pathname)
  useEffect(() => {
    try {
      if (history.location.state !== undefined) {
        if ('addquestion' !== hdl) {
          setQuestionType(history.location.state.questionType);
          setImage(history.location.state.image);
        }
      }
    } catch (e) { console.error(e); }
  }, []);
  const [questionType, setQuestionType] = useState("1");
  const [image, setImage] = useState([]);
  const [imageName, setImageName] = useState('Add Image');
  const [open, setOpen] = useState(false);
  const [spch, setSpCh] = useState("");
  const questionF = useRef(null);
  const [progress, setProgress] = useState(0);
  const [is1, setis1] = useState('addquestion' === hdl ? false : history.location.state.isOption1 === "1" ? true : false);
  const [is2, setis2] = useState('addquestion' === hdl ? false : history.location.state.isOption2 === "1" ? true : false);
  const [is3, setis3] = useState('addquestion' === hdl ? false : history.location.state.isOption3 === "1" ? true : false);
  const [is4, setis4] = useState('addquestion' === hdl ? false : history.location.state.isOption4 === "1" ? true : false);

  useEffect(() => {
    try {
      const op1 = document.getElementById("option1")
      const op2 = document.getElementById("option2")
      if (questionType === "3") {
        if (op1 && op2) {
          op1.value = "True";
          op2.value = "False";
        }
      } else {
        op1.value = 'addquestion' === hdl ? "" : history.location.state.option1
        op2.value = 'addquestion' === hdl ? "" : history.location.state.option2
      }
    } catch (e) { console.error(e); }
  }, [questionType])


  if (history.location.state === undefined) {
    return <Redirect to={'/quizapp' + '/' + sid + '/' + uid} />
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const createQuestion = async () => {
    let fData = Array.from(document.getElementsByTagName('form')[0]).reduce((a, i) => (
      {
        ...a, [i.type === "radio" || i.type === "checkbox" ? i.id : i.name]: i.type === "radio" || i.type === "checkbox" ? i.checked ? "1" : "0" : i.value
      }
    ), {});

    if (fData.mark < 1) fData.mark = 1;

    if (fData.questionTitle !== "") {
      if (fData.option1 !== "" || fData.questionType === "4") {
        if (fData.option2 !== "" || fData.questionType === "4") {
          if (fData.option3 !== (fData.questionType !== "3" ? "" : "false") || fData.questionType === "4") {
            if (fData.option4 !== (fData.questionType !== "3" ? "" : "false") || fData.questionType === "4") {
              if (fData.mark !== "" && parseInt(fData.mark) !== 0) {
                if (fData.isOption1 === "1" || fData.isOption2 === "1" || fData.isOption3 === "1" || fData.isOption4 === "1" || fData.questionType === "4") {
                  setProgress(1)
                  const formData = new FormData();
                  await formData.append('fun_name', 'addquestion' === hdl ? "Quizapp_AddQuestion" : 'Quizapp_QueUpdate')
                  await formData.append('sid', sid)
                  await formData.append('quizid', fData.quizid)
                  await formData.append('questionType', fData.questionType)
                  await formData.append('questionTitle', fData.questionTitle)
                  await formData.append('isOption1', fData.isOption1)
                  await formData.append('option1', fData.option1)
                  await formData.append('isOption2', fData.isOption2)
                  await formData.append('option2', fData.option2)
                  await formData.append('isOption3', fData.isOption3)
                  await formData.append('option3', fData.option3)
                  await formData.append('isOption4', fData.isOption4)
                  await formData.append('option4', fData.option4)
                  await formData.append('additionalText', fData.additionalText)
                  await formData.append('file', image);
                  await formData.append('mark', Math.abs(fData.mark));
                  await formData.append('negativ_mark', Math.abs(fData.negativ_mark));
                  await formData.append('userid', uid);
                  if ('addquestion' !== hdl) await formData.append('qid', fData.qid)

                  await axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', formData,
                    { onUploadProgress: ProgressEvent => { setProgress(Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)) } })
                    .then(() => {
                      'addquestion' === hdl ? history.push('/quizapp/editquiz/cque' + '/' + sid + '/' + uid, history.location.state) : history.goBack()
                    });
                } else { Swal.fire('Warning', "Please Select Correct Answer", 'error') }
              } else { Swal.fire('Warning', "Please Enter Marks Greater than 0", 'error') }
            } else { Swal.fire('Warning', "Please Enter Your Fourth Opiton", 'error') }
          } else { Swal.fire('Warning', "Please Enter Your Third Opiton", 'error') }
        } else { Swal.fire('Warning', "Please Enter Your Second Opiton", 'error') }
      } else { Swal.fire('Warning', "Please Enter Your First Opiton", 'error') }
    } else { Swal.fire('Warning', "Please Enter Your Question", 'error') }

  }

  function AddSc(id) {
    try {
      const element = document.getElementById(id);
      element.value += spch;
      setSpCh("")
      handleClose();
    } catch (e) { console.error(e); }
  }

  return (
    <>
      <div className="quiz-container">
        <div className="quiz-head"><div>{'addquestion' === hdl ? "Add Question" : 'Update Question'}</div><Navbar home={{ isHome: true, home: `${sessionStorage.tora}` }} back={true} refresh={false} /></div>
        <hr className="hr-line" />
        <div className="quiz-list-items">
          <div className="sidemenu1" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: progress > 0 ? "100%" : "0%", padding: 0, backgroundColor: "rgba(0,0,0,0.4)" }}>
            <div className="quiz-card">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <h3 style={{ fontSize: 20, fontWeight: "bold", color: "blue" }}>{image.length === 0 ? "Please Wait ..." : "Image Uploading ..."}</h3><br></br>
                <div style={{ width: "50%", height: "50%" }}>
                  <CircularProgressbar
                    value={progress}
                    text={`${progress}%`}
                    circleRatio={0.75}
                    styles={buildStyles({
                      rotation: 1 / 2 + 1 / 8,
                      trailColor: "#eee",
                      pathTransition: "none",
                      pathColor: progress > 20 ? "#4CC417" : "red",
                      textColor: progress > 20 ? "#4CC417" : "red",
                      textSize: "20px"
                    })}
                  />
                </div><br></br>
                <h4 style={{ textAlign: "center", fontWeigh: "100", color: "lightblue" }}>{image.length === 0 ? "Please wait you will automatically redirected" : "Please wait after image upload you will automatically redirected"}</h4>
              </div>
            </div>
          </div>
          <form>
            <div className="form-div">
              <label>Type - </label>
              <input type="text" name="qid" defaultValue={'addquestion' === hdl ? "" : history.location.state.qid} style={{ display: 'none' }} />
              <input type="number" name="quizid" defaultValue={'addquestion' === hdl ? history.location.state.id : history.location.state.quizid} style={{ display: 'none' }} />
              <select name="questionType" onChange={e => { setQuestionType(e.target.value) }} defaultValue={'addquestion' === hdl ? null : history.location.state.questionType}>
                <option value="1">Single Choice Question</option>
                <option value="2">Multiple Choice Question</option>
                <option value="3">True or False</option>
                <option value="4">Short Answer Question</option>
              </select>
            </div>
            <div className="form-div">
              <label htmlFor="add-text">Question Title - </label>
              <TextareaAutosize id="questionTitle" ref={questionF} name="questionTitle" placeholder="Enter Question" defaultValue={'addquestion' === hdl ? null : history.location.state.questionTitle} />
            </div>
            {image.length !== 0 ?
              <div style={{ display: "flex", alignItems: 'center', justifyContent: "center", marginBottom: 5, minHeight: "200px", maxHeight: "315px", width: "100%", position: "relative", border: 0 }}>
                <img src={'addquestion' === hdl ? URL.createObjectURL(image) : typeof image === 'string' ? image : URL.createObjectURL(image)} onLoad={() => { document.getElementById(`imageloading`).style.opacity = 0 }}
                  style={{ maxHeight: "300px", maxWidth: "100%", position: "relative", border: 0 }}
                  alt="Image Loading... Please Wait..."
                />
                <div id={`imageloading`} style={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", border: 0, padding: 5 }}>
                  <Loader
                    type="Oval"
                    color="red"
                    height={50}
                    width={50}
                  />
                </div>
              </div> : null
            }
            <div id="add-button" className="form-div">
              <div >
                <input style={{ display: "none" }} type="file" id="image" name="image" accept="image/*" onChange={(e) => { setImageName(e.target.files.length === 0 ? imageName : e.target.files[0].name); setImage(e.target.files.length === 0 ? image : e.target.files[0]) }} />
                <label htmlFor="image" style={{ display: "flex", alignItems: "center" }}>
                  <img style={{ width: "30px", height: "30px" }} alt="" src={image.length !== 0 ? 'addquestion' === hdl ? URL.createObjectURL(image) : typeof image === 'string' ? image : URL.createObjectURL(image) : "https://img.icons8.com/material-rounded/24/fa314a/add-image.png"} />&nbsp;&nbsp;
                  {'addquestion' === hdl || image === "" ? imageName : "Change Img"}
                </label>
              </div>
              <div id="sc-button" onClick={handleClickOpen}>
                <img src="https://img.icons8.com/metro/26/fa314a/plus-math.png" alt="" />
                <label>Special Character</label>
              </div>
              <Dialog fullWidth={true} maxWidth={'xs'} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <div style={{ padding: "20px" }}>
                  <label style={{ fontSize: "18px" }}><b>{spch === "" ? "Choose which you want" : "Where do you want to add?"}</b></label>
                  {open ? (
                    <div className="closeButton" onClick={handleClose}>
                      <CloseIcon />
                    </div>
                  ) : null}
                </div>
                <div className="popup" style={{ padding: "10px", overflow: "scroll" }}>
                  {spch === "" ?
                    <>
                      <label>Special Characters</label>
                      <div>
                        {
                          specialChars.map((i, k) => {
                            return (
                              <button key={k} onClick={() => { setSpCh(i) }}>
                                {i}
                              </button>
                            )
                          })
                        }
                      </div>
                      <label>Power</label>
                      <div>
                        {
                          power.map((i, k) => {
                            return (
                              <button key={k} onClick={() => { setSpCh(i) }}>
                                {i}
                              </button>
                            )
                          })
                        }
                      </div>
                      <label>Base</label>
                      <div>
                        {
                          base.map((i, k) => {
                            return (
                              <button key={k} onClick={() => { setSpCh(i) }}>
                                {i}
                              </button>
                            )
                          })
                        }
                      </div>
                    </>
                    :
                    <div className="spchButton">
                      <button className="youSelected">You Selected <br></br>{spch}</button>
                      <button onClick={() => { AddSc("questionTitle") }}>Question Title</button>
                      {questionType === "3" ? null :
                        <>
                          <button onClick={() => { AddSc("option1") }}>Option 1</button>
                          <button onClick={() => { AddSc("option2") }}>Option 2</button>
                          <button onClick={() => { AddSc("option3") }}>Option 3</button>
                          <button onClick={() => { AddSc("option4") }}>Option 4</button>
                        </>}
                      <button onClick={() => { AddSc("additionalText") }}>Additional Text</button>
                    </div>
                  }
                </div>
              </Dialog>

            </div>
            <div className="form-div" style={{ display: questionType === "4" ? "none" : "flex" }}>
              <p>
                <label>Options - </label><br />
              </p>
              <span >
                {questionType === "2" ?
                  <input type={"checkbox"} id="isOption1" name="isOption1" onClick={() => { setis1(!is1) }} checked={is1} readOnly />
                  :
                  <input type={"radio"} id="isOption1" name="answer" defaultChecked={is1} />
                }
                <TextareaAutosize id="option1" name="option1" defaultValue={questionType === "3" ? "True" : 'addquestion' === hdl ? "" : history.location.state.option1} disabled={questionType === "3" ? true : false} placeholder="Enter Option 1" /><br />
              </span><br />
              <span >
                {questionType === "2" ?
                  <input type={"checkbox"} id="isOption2" name="isOption2" onClick={() => { setis2(!is2) }} checked={is2} readOnly />
                  :
                  <input type={"radio"} id="isOption2" name="answer" defaultChecked={is2} />
                }
                <TextareaAutosize id="option2" name="option2" defaultValue={questionType === "3" ? "False" : 'addquestion' === hdl ? "" : history.location.state.option2} disabled={questionType === "3" ? true : false} placeholder="Enter Option 2" /><br />
              </span><br></br>
              <span style={questionType === "3" ? { display: "none" } : null}>
                {questionType === "2" ?
                  <input type={"checkbox"} id="isOption3" name="isOption3" onClick={() => { setis3(!is3) }} checked={is3} readOnly />
                  :
                  <input type={"radio"} id="isOption3" name="answer" defaultChecked={is3} />
                }
                <TextareaAutosize id="option3" name="option3" defaultValue={'addquestion' === hdl ? "" : history.location.state.option3} placeholder="Enter Option 3" />
              </span><br></br>
              <span style={questionType === "3" ? { display: "none" } : null}>
                {questionType === "2" ?
                  <input type={"checkbox"} id="isOption4" name="isOption4" onClick={() => { setis4(!is4) }} checked={is4} readOnly />
                  :
                  <input type={"radio"} id="isOption4" name="answer" defaultChecked={is4} />
                }
                <TextareaAutosize id="option4" name="option4" defaultValue={'addquestion' === hdl ? "" : history.location.state.option4} placeholder="Enter Option 4" />
              </span>
            </div>
            <div className="form-div">
              <label>Additional Text (Optional) - </label>
              <TextareaAutosize id="additionalText" name="additionalText" placeholder="Enter Additional Text" defaultValue={'addquestion' === hdl ? null : history.location.state.additionalText} />
            </div>
            <div className="form-div" style={{ display: hdl === "addquestion" ? 'flex' : 'none' }}>
              <label>Marks - </label>
              <input type="number" placeholder="Enter Marks" name="mark" defaultValue={hdl === "addquestion" ? 1 : history.location.state.mark} />
            </div>
            <div className="form-div" style={{ display: hdl === "addquestion" && history.location.state.nmark === "yes" ? 'flex' : 'none' }}>
              <label>Negative Marks - </label>
              <input type="number" placeholder="Enter Marks" name="negativ_mark" defaultValue={hdl === "addquestion" ? 0 : history.location.state.negativ_mark} />
            </div>
          </form>
          <div className="button-div">
            <button onClick={debounce(createQuestion, 500)} className="create-button">{'addquestion' === hdl ? "Create Question" : "Update Question"}</button>
          </div>
        </div>
      </div>
    </>
  );
}
