import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";



const drawerWidth = 280;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer2: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    [theme.breakpoints.up("sm")]: {
      marginTop: 64,
    },
    width: drawerWidth,
    height: "90vh",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  submit: {
    position: "fixed",
    bottom: 30,
    left: "45vw",
    marginLeft: "10%",
  },
  applist: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    marginBottom: 0,
    marginLeft: "5%",
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "70vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "60vw",
    },
  },
  drawerOpen: {
    width: drawerWidth,

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",

    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100%)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  "@global": {
    "*:focus": {
      outline: " none !important",
    },
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  input: {
    borderRadius: 54,
  },
}));


const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: 58,
      top: -410,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrap1 = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: -430,
      top: -80,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapS = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: 408,
      top: -410,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapS1 = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: 15,
      top: -80,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapD = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: 298,
      top: -410,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapD1 = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: -212,
      top: -80,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapP = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: 178,
      top: -410,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapP1 = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: -312,
      top: -80,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapSD = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: 408,
      top: -410,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapSD1 = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: 75,
      top: -80,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapT = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: 674,
      top: -410,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapT1 = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: -85,
      top: -80,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapG = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: 538,
      top: -410,
      backgroundColor: theme.palette.common.black,
    },
  }));
  const useStylesBootstrapG1 = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      position: "absolute",
      left: 90,
      top: -80,
      backgroundColor: theme.palette.common.black,
    },
  }));
 const BootstrapTooltip =(props) =>{
    if (props.nm === "Save") {
      if (props.ctype === 1) {
        const classes = useStylesBootstrapS1();
        return <Tooltip arrow classes={classes} {...props} />;
      } else {
        const classes = useStylesBootstrapS();
        return <Tooltip arrow classes={classes} {...props} />;
      }
    } else if (props.nm === "Down") {
      if (props.ctype === 1) {
        const classes = useStylesBootstrapD1();
        return <Tooltip arrow classes={classes} {...props} />;
      } else {
        const classes = useStylesBootstrapD();
        return <Tooltip arrow classes={classes} {...props} />;
      }
    } else if (props.nm === "Print") {
      if (props.ctype === 1) {
        const classes = useStylesBootstrapP1();
        return <Tooltip arrow classes={classes} {...props} />;
      } else {
        const classes = useStylesBootstrapP();
        return <Tooltip arrow classes={classes} {...props} />;
      }
    } else if (props.nm === "Default") {
      if (props.ctype === 1) {
        const classes = useStylesBootstrapSD1();
        return <Tooltip arrow classes={classes} {...props} />;
      } else {
        const classes = useStylesBootstrapSD();
        return <Tooltip arrow classes={classes} {...props} />;
      }
    } else if (props.nm === "Tutorial") {
      if (props.ctype === 1) {
        const classes = useStylesBootstrapT1();
        return <Tooltip arrow classes={classes} {...props} />;
      } else {
        const classes = useStylesBootstrapT();
        return <Tooltip arrow classes={classes} {...props} />;
      }
    } else if (props.nm === "Student Specific") {
      if (props.ctype === 1) {
        const classes = useStylesBootstrapG1();
        return <Tooltip arrow classes={classes} {...props} />;
      } else {
        const classes = useStylesBootstrapG();
        return <Tooltip arrow classes={classes} {...props} />;
      }
    } else {
      if (props.ctype === 1) {
        const classes = useStylesBootstrap1();
        return <Tooltip arrow classes={classes} {...props} />;
      } else {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
      }
    }
  }

  export default BootstrapTooltip;