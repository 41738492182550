import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import {Typography,Button} from '@material-ui/core/';
const useStyles = makeStyles((theme) => ({
  root: {
   
  },
  inline: {
    display: 'inline',
  },
}));

export default function InboxList(props) {
  const classes = useStyles();


useEffect(()=>{
  //  console.log(props.grps);
},[])
  return (
    
    props.grps!=null?
    props.grps.length==0?<p className={classes.root} style={{marginLeft:50,marginTop:100,fontWeight:'bolder'}}>No Groups</p>:
    props.grps.map((groups,indx)=>
    
    <List className={classes.root} key={indx}>
      {/* {console.log("Warn-->"+groups)} */}
      <Button>
      <ListItem onClick={()=>props.G(groups,indx,props.grps)}>
        <ListItemAvatar >
          <Avatar alt="User " />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography style={{textTransform:'capitalize', fontFamily:'Poppins',color:'#444'}}>{groups.gname}</Typography>}
        />
      </ListItem>
      </Button>
      <Divider variant="inset" component="li" hidden />
    </List>):null
    
  );
}

       