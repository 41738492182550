import React from 'react'
import { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import { DecryptED } from "../../DecryptJS/Decrypt";

const UpdateLessonReport = () => {
  const { sid, userid, id } = useParams();
  const [isSaved, setIsSaved] = useState(false);
  const [apiloader, setapiLoader] = useState();
  const [reports, setReports] = useState({});
  const [isCalled, setIscalled] = useState(0);


  const getReportById = async () => {
    setapiLoader(true);
    console.log(userid)
    try {
      const data = {
        "sid": DecryptED(sid),
        "userId": DecryptED(userid),
        "id": DecryptED(id)
      }
      const response = await axios({
        method: "POST",
        //url: `http://localhost:8080/api/admin/lessonPlan/reportById`,
        url: `${process.env.REACT_APP_BASE_URL}/api/admin/lessonPlan/reportById`,
        data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      })
        .then((response) => {
          setReports(response.data[0]);

          setapiLoader(false);

        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
          setapiLoader(false);
        });
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 5000,
      });
      setapiLoader(false);
    }
  }

  useEffect(() => {
    const updateData = async () => {
      if (isCalled > 0) {
        setapiLoader(true);

        const data = reports;

        const response = await axios.post(
          `https://topschooldev.prisms.in/rest/index.php/staff_list.json`,
          data
        )
          .then((response) => {
            setapiLoader(false);
            Swal.fire({
              position: "center",
              icon: "success",
              title: `update successfully!`,
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((error) => {
            setapiLoader(false);
            Swal.fire({
              position: "center",
              icon: "warning",
              title: `Some Error occured!`,
              showConfirmButton: false,
              timer: 1500,
            });
          });
        setapiLoader(false);
      }
      setIscalled(1);
    };
    updateData();
  }, [reports.fun_name]);

  const updateState = () => {
    setReports({
      ...reports,
      fun_name: "updateLessonplanData",
      staff_id: DecryptED(userid),
      sid: DecryptED(sid),
      id: DecryptED(id)
    })
  };

  useEffect(() => {
    getReportById();
  }, [])

  return (
    <>
      {apiloader ? (
        <div className="TMLoader">
          <Loader type="ThreeDots" color="#bd2f5e" height={80} width={80} />
        </div>
      ) : (
        <div className='container p-5'>
          <div className='card px-2'>
            <h2 className='mt-5 mx-auto mt-0 '>EDIT REPORT</h2>
            <hr />
            <form className='row'>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Learning Objectives</label>
                <textarea defaultValue={reports.lesson_objective} onChange={(e) => setReports({ ...reports, lesson_objective: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Learning Outcome</label>
                <textarea defaultValue={reports.learning_outcome} onChange={(e) => setReports({ ...reports, learning_outcome: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Audio / Visual Aids</label>
                <textarea defaultValue={reports.audio_visual_adis} onChange={(e) => setReports({ ...reports, audio_visual_adis: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Pedagogical Practices</label>
                <textarea defaultValue={reports.pedagogical_practices} onChange={(e) => setReports({ ...reports, pedagogical_practices: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Teacher Preparation</label>
                <textarea defaultValue={reports.teacher_preparation} onChange={(e) => setReports({ ...reports, teacher_preparation: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Student Preparation</label>
                <textarea defaultValue={reports.student_preparation} onChange={(e) => setReports({ ...reports, student_preparation: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Additional Extended Information</label>
                <textarea defaultValue={reports.additional_extended_information} onChange={(e) => setReports({ ...reports, additional_extended_information: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Interdisciplinary Linkage</label>
                <textarea defaultValue={reports.interdisciplinary_linkage} onChange={(e) => setReports({ ...reports, interdisciplinary_linkage: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Infusion With Life Skills</label>
                <textarea defaultValue={reports.infusion_with_life_skills} onChange={(e) => setReports({ ...reports, infusion_with_life_skills: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Art Integration</label>
                <textarea defaultValue={reports.art_integration} onChange={(e) => setReports({ ...reports, art_integration: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Story Based</label>
                <textarea defaultValue={reports.story_based} onChange={(e) => setReports({ ...reports, story_based: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Artificial Intelligence</label>
                <textarea defaultValue={reports.artificial_intelligence} onChange={(e) => setReports({ ...reports, artificial_intelligence: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">fln</label>
                <textarea defaultValue={reports.fln} onChange={(e) => setReports({ ...reports, fln: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Cwsn Plan</label>
                <textarea defaultValue={reports.remedial_plan} onChange={(e) => setReports({ ...reports, remedial_plan: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Assignment</label>
                <textarea defaultValue={reports.assignment} onChange={(e) => setReports({ ...reports, assignment: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>

              <div className="col-md-6">
                <label for="exampleFormControlTextarea1">Reference Used</label>
                <textarea defaultValue={reports.reference_used} onChange={(e) => setReports({ ...reports, reference_used: e.target.value })} className="form-control" id="exampleFormControlTextarea1" ></textarea>
              </div>
              <div className='col-md-12 '>
                <input type='button' className='btn btn-primary my-0' value={"save"} onClick={() => { updateState(); }} />
              </div>
            </form>
          </div>
        </div>
      )
      }


    </>
  )
}

export default UpdateLessonReport
