import React, { useState } from 'react';
import AppointmentStatus from '../User/AppointmentStatus';
import RequestAppointment from '../User/RequestAppointment';

export default function RequestAndStatus() {

  const [Active, setActive] = useState(true);

  return (
    <div className="APMCard">
      <div className="APMCardSub">
        <div className="APHeaderSwitch">
          <div>
            <span onClick={() => { setActive(true) }} style={{ borderBottom: Active ? "3px solid #1e5597" : "0px", cursor: "pointer" }}>Request</span>
          </div>
          <div>
            <span onClick={() => { setActive(false) }} style={{ borderBottom: !Active ? "3px solid #1e5597" : "0px", cursor: "pointer" }}>Status</span>
          </div>
        </div>
        <div style={{ overflow: "scroll", height: "100%" }}>
          <div>
            <div>
              {Active ?
                <RequestAppointment userType="Parent" showAppointment={() => { setActive(false) }} />
                :
                <AppointmentStatus userType="Parent" />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
