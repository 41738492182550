import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Report from "./Report";
import { useHistory } from 'react-router-dom';

// studentlog/adminreport/258/453


const AdminReport = () => {
  let history = useHistory();
  const { sid,staffid } = useParams();
  const [Sclass, setClass] = useState([]);
  const [standard, setStand] = useState([]);
  const [division, setDiv] = useState([]);
  const [section, setSection] = useState("");
  const [adminReport, setAdmin] = useState([]);

  const classHandler = (e) => {
    e.preventDefault();
    const classArr = e.target.value.split(" ");
    const dataTarget = classArr[0];
    const std_1 = classArr[1];
    setStand(std_1);
    const getDivDetails = async () => {
      try {
        const token = "#$laksdfnlknoea#$@$%^&%$";
        const data = {
          class_id: dataTarget,
          sid: `${sid}`,
        };
        const response = await axios({
          method: "post",
          data: data,
          url: "https://marcopolo.prisms.in/api/tscommon/getalldiv",
          headers: {
            Authorization: `${token}`,
          },
        });
        setDiv(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getDivDetails();
  };

  // const onHome = (e) => {
  //   e.preventDefault();
  //   history.goBack({
  //    pathname:`/studentlog/${sid}/${staffid}`,
  //   });
  // }
  // Div Handler
  const divHandler = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    const divArr = e.target.value.split(" ");
    const divisionName = divArr[1];
    setSection(divisionName);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (standard && section) {
      const data = {
        sid: `${sid}`,
        std: `${standard}`,
        division: `${section}`,
      };

      // view report details
      const getReportDetails = async () => {
        try {
          const token = "#$laksdfnlknoea#$@$%^&%$";
          const response = await axios({
            method: "post",
            url: "https://marcopolo.prisms.in/api/studentlog/adminreport",
            data,
            headers: {
              Authorization: `${token}`,
            },
          });
          if (response) {
            const report = response.data;
            function combine(arr) {
              var combined = arr.reduce(function(result, item) {
                var current = result[item.createdDate];
      
                result[item.createdDate] = !current
                  ? item
                  : {
                      userid: current.userid,
                      fname: current.fname,
                      lname: item.last_name,
                      sname: item.fname,
                      slname:item.lname,
                      std: item.std,
                      division: item.division,
                      month: item.createdDate,
                      field_name: current.field_name + "," + item.field_name,
                      value: current.value + "," + item.value,
                    };
      
                return result;
              }, {});
      
              return Object.keys(combined).map(function(key) {
                return combined[key];
              });
            }

            const result = combine(report);
            console.log(result.reverse())

            setAdmin(result)

            const report2 = result;
            function combine2(arr) {
              var combined = arr.reduce(function(result, item) {
                var current = result[item.userid];
      
                result[item.userid] = !current
                  ? item
                  : {
                      userid: current.userid,
                      fname: item.first_name,
                      lname: item.last_name,
                      sname: item.fname,
                      slname:item.lname,
                      std: current.std + "," + item.std,
                      division: current.division + "," + item.division,
                      date: current.createdDate + "," + item.createdDate,
                      field_name: current.field_name + "," + item.field_name,
                      value: current.value + "," + item.value,
                    };
      
                return result;
              }, {});
      
              return Object.keys(combined).map(function(key) {
                return combined[key];
              });
            }

            const result1  = combine2(report2)
            console.log(result1)

          }
        } catch (error) {
          console.log(error);
        }
      };
      getReportDetails();
    } else {
      Swal.fire(`Select class and division`);
    }
  };


  useEffect(() => {
    const getClassDetails = async () => {
      try {
        const token = "#$laksdfnlknoea#$@$%^&%$";
        const data = {
          sid: `${sid}`,
        };
        const response = await axios({
          method: "post",
          url: "https://marcopolo.prisms.in/api/tscommon/getallclass",
          data,
          headers: {
            Authorization: `${token}`,
          },
        });
        setClass(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getClassDetails();
  }, []);


  return (
    <>
      <Container>
        <Wrapper>
          <h3>Admin Report</h3>
          <Form>
            <form>
              {/* Select Class */}
              <h4>Select Class</h4>
              <select onChange={(e) => classHandler(e)}>
                <option value="default">Select</option>
                {Sclass.map((data, idx) => {
                  return (
                    <option key={idx} value={`${data.id} ${data.class_name}`}>
                      {data.class_name}
                    </option>
                  );
                })}
              </select>

              {/* Select Div  */}
              <h4>Select Div</h4>
              <select onChange={(e) => divHandler(e)}>
                <option value="default">Select</option>
                {division.map((data, idx) => {
                  const { div_name, id } = data;
                  return (
                    <option key={idx} value={`${id} ${div_name}`}>
                      {div_name}
                    </option>
                  );
                })}
              </select>
              <div className="btn__box">
                <button className="btn" onClick={(e) => onSubmit(e)}>
                  submit
                </button>
              </div>
            </form>
          </Form>

          <Report adminReport={adminReport}/>
        </Wrapper>
      </Container>
    </>
  );
};

export default AdminReport;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 10px auto;
  padding: 20px;
  height: 80vh;

  @media (max-width: 768px) {
    width: 95%;
    padding: 0;
    box-shadow: none;
    margin: 0 auto;
    height: 100vh;
  }
  h3 {
    text-align: center;
    color: #444;
    font-weight: 600;
    font-size: 1.7rem;
    text-transform: capitalize;
    margin-top: 20px;

    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }
`;

const Form = styled.div`
  max-width: 1100px;
  height: 400px;
  margin: 20px auto;

  @media (max-width: 768px) {
    margin: 10px auto;
  }

  form {
    display: flex;
    flex-direction: column;

    h4 {
      margin-top: 15px;
      font-weight: 600;
      font-size: 1.2rem;
      color: #444;
    }

    select {
      width: 100%;
      padding: 10px;
      margin: 12px 0;
      border-top: none;
      border-left: none;
      height: 40px;
      outline: none;
      border-right: none;
      font-size: 1rem;
      box-sizing: border-box;
      background-color: rgb(75, 130, 230);
      color: white;
      border-radius: 4px;
    }
  }
  .btn__box {
    display: flex;
  }
  .btn {
    border: none;
    height: 43px;
    text-transform: capitalize;
    border-radius: 4px;
    width: 170px;
    font-size: 1rem;
    font-weight: 9000;
    width: 40%;
    background-color: rgb(75, 130, 230);
    color: white;
    margin: 0 auto;
    margin-top: 0px;
    margin-top: 25px;
  }
`;
