


const specialChars = [
    'ƒ', 'Α', 'Β', 'Γ', 'Δ', 'Ε', 'Ζ', 'Η', 'Θ', 'Ι', 'Κ', 'Λ', 'Μ',
    'Ν', 'Ξ', 'Ο', 'Π', 'Ρ', 'Σ', 'Τ', 'Υ', 'Φ', 'Χ', 'Ψ', 'Ω', 'α', 'β', 'γ', 'δ', 'ε', 'ζ', 'η',
    'θ', 'ι', 'κ', 'λ', 'μ', 'ν', 'ξ', 'ο', 'π', 'ρ', 'ς', 'σ', 'τ', 'υ', 'φ', 'χ', 'ψ', 'ω', 'ϑ',
    'ϒ', 'ϖ', '•', '‾', '℘', 'ℑ', 'ℜ', '™', 'ℵ', '←', '↑', '→', '↓', '↔', '↵',
    '⇐', '⇑', '⇒', '⇓', '⇔', '∀', '∂', '∃', '∅', '∇', '∈', '∉', '∋', '∏', '∑', '−', '∗', '√', '∝',
    '∞', '∠', '∧', '∨', '∩', '∪', '∫', '∴', '∼', '≅', '≈', '≠', '≡', '≤', '≥', '⊂', '⊃', '⊄', '⊆',
    '⊇', '⊕', '⊗', '⊥', '⋅', '⌈', '⌉', '⌊', '⌋', '⟨', '⟩', '◊', '♠', '♣', '♥', '♦', '±'
]

const power = [
    "⁺", "⁻", "⁼", "⁽", "⁾", "⁰", "¹", "²", "³", "⁴", "⁵", "⁶", "⁷", "⁸", "⁹", "ᴬ", "ᵃ", "ᴭ", "ᵆ",
    "ᵄ", "ᵅ", "ᶛ", "ᴮ", "ᵇ", "ᶜ", "ᶝ", "ᴰ", "ᵈ", "ᶞ", "ᴱ", "ᵉ", "ᴲ", "ᵊ", "ᵋ", "ᶟ", "ᵌ", "ᶠ", "ᴳ",
    "ᵍ", "ᶢ", "ˠ", "ʰ", "ᴴ", "ʱ", "ᴵ", "ⁱ", "ᶦ", "ᶤ", "ᶧ", "ᶥ", "ʲ", "ᴶ", "ᶨ", "ᶡ", "ᴷ", "ᵏ", "ˡ",
    "ᴸ", "ᶫ", "ᶪ", "ᶩ", "ᴹ", "ᵐ", "ᶬ", "ᴺ", "ⁿ", "ᶰ", "ᶮ", "ᶯ", "ᵑ", "ᴼ", "ᵒ", "ᵓ", "ᵔ", "ᵕ", "ᶱ",
    "ᴽ", "ᴾ", "ᵖ", "ᶲ", "ʳ", "ᴿ", "ʴ", "ʵ", "ʶ", "ˢ", "ᶳ", "ᶴ", "ᵀ", "ᵗ", "ᶵ", "ᵁ", "ᵘ", "ᶸ", "ᵙ",
    "ᶶ", "ᶣ", "ᵚ", "ᶭ", "ᶷ", "ᵛ", "ⱽ", "ᶹ", "ᶺ", "ʷ", "ᵂ", "ˣ", "ʸ", "ᶻ", "ᶼ", "ᶽ", "ᶾ", "ꝰ", "ᵜ",
    "ᵝ", "ᵞ", "ᵟ", "ᶿ", "ᵠ", "ᵡ", "ᵸ", "ჼ", "ˤ", "ⵯ", "'"
]

const base = [
    "₊", "₋", "₌", "₍", "₎", "₀", "₁", "₂", "₃", "₄", "₅", "₆", "₇", "₈", "₉", "ₐ", "ₑ", "ₔ",
    "ₕ", "ᵢ", "ⱼ", "ₖ", "ₗ", "ₘ", "ₙ", "ₒ", "ₚ", "ᵣ", "ₛ", "ₜ", "ᵤ", "ᵥ", "ₓ", "ᵦ", "ᵧ", "ᵨ", "ᵩ", "ᵪ"
]

export { specialChars, power, base };


