import React from 'react';
import {Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));
function getSteps() {
    return ['Click on Open Design', 'Select the design created from website', 'Design the idcard on canvas as required using tools given on left hand side panel and click on save button to save the design',
            'After saving the design click of "Select Class" to select the class and the click on "Select Div" to select the division or select staff type if you want to generate the staff id card',
            'Click on "Preview" to preview the idcard and to check the information and design of student /staff idcard',
            'If the idcard information and design is previewed, click on "Apply to Div" button to apply the selected design to all students/staff selected division/staff type and wait for the process to complete',
          'After the process completed click on "ok" and then click on Print icon on the top for printing','Done'  ];
  }
  
  function getStepContent(step) {
    switch (step) {
    case 0:
        return <img src={"/icons/1st.png"}/>;
    case 1:
        return <img src={"/icons/2nd.png"}/>;
    case 2:
        return  <img src={"/icons/3rd.png"}/> ;
    case 3:
        return  <img src={"/icons/4th.png"}/> ;  
    case 4:
            return <img src={"/icons/5th.png"}/> ;  
    case 5:
     return <div> <img src={"/icons/6th.png"}/> <img src={"/icons/7th.png"}/></div>;  
    case 6:
     return <div> <img src={"/icons/9th.png"}/><img src={"/icons/8thth.png"}/></div> ;  
    case 7:
     return `Now you can click on Download Image button to download grid of idcard as high quality images 
            or click on print button to print grid of id cards or save it as pdf` ;  
       
      default:
        return 'Unknown step';
    }
  }
  
export default function Tutorial(props) {
  const [open, setOpen] = React.useState(true);

const [isload,setload]=React.useState(false);
const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
        
      <Dialog open={props.open}  aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Tutorial</DialogTitle>
        <DialogContent>
          <DialogContentText>
             Tutorial for creating an ID Card
          </DialogContentText>
          <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - Now you can check on website for proof reading of generated id cards</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
          
        </DialogContent>
        <Button onClick={props.close} color="primary">
            Cancel
          </Button>
      
      </Dialog>
    </div>
  );
}
