import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios";
import moment from "moment";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Loader from "../common/Loader";
import { DecryptED } from "../DecryptJS/Decrypt";

const LeaveReport = () => {
  const { sid, userid } = useParams();
  let history = useHistory();

  const data = {
    sid: DecryptED(sid),
    user_id: DecryptED(userid),
  };
  const [report, setReport] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);

  const getReport = async () => {
    setApiLoader(true);
    try {
      const token = "#$laksdfnlknoea#$@$%^&%$";
      const response = await axios({
        method: "post",
        url: "https://marcopolo.prisms.in/api/studentLeave/report",
        data,
        headers: {
          Authorization: `${token}`,
        },
      });

      setTimeout(() => {
        setReport(response.data);
        setApiLoader(false);
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <>
      <Container>
        {apiLoader ? (
          <Loader />
        ) : (
          <Wrapper>
            <h3 className="leave__heading">Leave Report</h3>
            <div className="Arrow__back">
              <ArrowBackIcon className="Arrow__back" onClick={history.goBack} />
            </div>
            {report.map((data, index) => {
              const {
                leave_applied_date,
                leave_start_date,
                leave_end_date,
                leave_reason,
                leave_attachment_path,
                halfDay,
              } = data;
              return (
                <div className="leave__Report" key={index}>
                  <div className="various__Date">
                    <div className="date__section">
                      <span className="applied__date">Applied Date</span>
                      <span className="start__date">Start Date</span>
                      <span className="end__date">End Date</span>
                    </div>
                    <div className="date__section">
                      <span className="applied__date_1">
                        {moment(leave_applied_date).format("DD-MM-YYYY")}
                      </span>
                      <span className="start__date_2">
                        {moment(leave_start_date).format("DD-MM-YYYY")}
                      </span>
                      <span className="end__date_3">
                        {moment(leave_end_date).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  </div>

                  <div className="leave__reason">
                    <div className="leave__reason_path">
                      <span className="leave__reason__heading">
                        Reason For Leave :{" "}
                      </span>
                      {halfDay === 1 ? (
                        <span className="leave__reason__heading">Half day</span>
                      ) : null}
                      <div className="file__path">
                        {leave_attachment_path !== "" ? (
                          <a
                            href={leave_attachment_path}
                            download="leave_attachments"
                          >
                            <CloudDownloadIcon/>
                          </a>
                        ) : null}
                      </div>
                    </div>
                    <span>{leave_reason ? leave_reason : <span>-</span>}</span>
                  </div>
                </div>
              );
            })}
          </Wrapper>
        )}
      </Container>
    </>
  );
};

export default LeaveReport;

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 25px auto;
  padding: 20px;
  height: 80vh;

  @media (max-width: 768px) {
    width: 95%;
    padding: 0;
    box-shadow: none;
    margin: 0 auto;
    height: 100vh;
  }

  .leave__heading {
    color: #111;
    text-align: center;
    font-size: 1.5rem;
    margin-top: 15px;
    font-weight: 600;
    font-family: Poppins;

  }
  .Arrow__back {
    font-size: 2rem;
    color: #444;
  }
  .leave__Report {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 8px;
    width: 100%;
    height: auto;
    margin-bottom: 10px;

    .various__Date {
      .date__section {
        display: flex;
        justify-content: space-between;

        .applied__date {
          font-weight: 600;
          color: rgba(75, 130, 230);
          font-family: Poppins;

        }
        .start__date {
          font-weight: 600;
          color: rgba(68, 188, 82);
          font-family: Poppins;

        }
        .end__date {
          font-weight: 600;
          color: rgba(235, 49, 49);
          font-family: Poppins;

        }
        .start__date_2 {
          margin-left: 30px;
          color: rgba(68, 188, 82);
          font-family: Poppins;

        }
        .end__date_3 {
          color: rgba(235, 49, 49);
         font-family: Poppins;

        }
      }
    }
    .leave__reason {
      .leave__reason_path {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;

        .leave__reason__heading {
          font-weight: 500;
          color: #444;
          font-family: Poppins;

        }
        .file__path {
          color: rgb(75, 130, 255);
        }
      }
    }
  }
`;
