import React from "react";

const SuccessPage = ({ formTitle, setShowSuccess }) => {
  return (
    <div>
      <div className="card text-center mt-5">
        <div className="card-header">{formTitle}</div>
        <div className="card-body">
          <h5 className="card-title">Information is filled successfully.</h5>
          <p className="card-text">You can update anytime.</p>
          <button className="btn btn-primary mt-0" onClick={()=>{setShowSuccess(false)}}>Update Information</button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
