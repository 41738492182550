import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import "../Report/ReportView.css";
import DayPlan from "./ViewDayPlan";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import {DecryptED} from "../../DecryptJS/Decrypt"
import { FcDownload } from "react-icons/fc";
import { handleDownloadExcel } from "../ExportToExcel";
const AdminReportView = () => {
  const classes = useStyles();
  const { sid, userid, reportid } = useParams();
  const [weeklyData, setWeeklyData] = useState([]);
  const [reportData, setReportData] = useState({ month: "", lession_name: "", subject: "", div_name: "" });
  const [apiloader, setapiLoader] = useState();
  
  const [exportData, setExportData] = useState({
    headers: [
      "Days", "Subject", "sub topic", "Class_Division","Lesson Name", "Period", "imp. notes", "class activity", "HomeWork", "accomplished", "if No reason"
    ],
    body: []
  }
  )

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getReportById = async () => {
    setapiLoader(true);
    try {
      const data = {
        sid: DecryptED(sid),
        id: DecryptED(reportid),
        userId: DecryptED(userid)
      }
      const response = await axios({
        method: "POST",
        //url: `http://localhost:8080/api/admin/weeklyPlan/reportById`,
        url: `${process.env.REACT_APP_BASE_URL}/api/admin/weeklyPlan/reportById`,
        data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      })
        .then((response) => {
          setWeeklyData(response.data);
          setReportData({ ...reportData, month: response.data[0].month, lession_name: response.data[0].lession_name, subject: response.data[0].subject, div_name: response.data[0].div_name });
          setapiLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setapiLoader(false);
        });
    } catch (error) {
      setapiLoader(false);
      console.log(error)
    }
    setapiLoader(false);
  }
  useEffect(() => {
    getReportById();
  }, [])


  const updateReport = async(e) =>{
    e.preventDefault();
    console.log(weeklyData);

    if (!reportData.month || !reportData.lession_name) {
      Swal.fire("Please fill the required fields");
    } else {
      const data = {
        fun_name: "updateWeeklyLessonplanData",
        staff_id: weeklyData[0].staff_id,
        sid: DecryptED(sid),
        class_id: weeklyData[0].class_id,
        lesson_id: weeklyData[0].lesson_id,
        days: weeklyData,
        userId: DecryptED(userid)
      };
      const response = await axios.post(
        "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data
      );
      if (response) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Plan has been updated successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }

  useEffect(() => {
    
    const myArray = weeklyData.map(item => {
      return {
        Days: item.days,
        subject: item.subject,
        top_sub_top: item.top_sub_top,
        div_name: item.div_name,
        Lesson_Name: item.lession_name,
        Period: item.no_of_period,
        imp_notes: item.imp_note,
        class_activity: item.cls_activity,
        HomeWork: item.homework,
        accomplished: item.accomplished,
        if_No_reason: item.if_no_reas_with_comp_date
      }
    });
    setExportData({...exportData, body: myArray})
  }, [weeklyData])

  return (
    <>
      {
        apiloader ? (
          <div className="TMLoader">
            <Loader type="ThreeDots" color="#bd2f5e" height={80} width={80} />
          </div>
        ) :
          <div className="form-main">
            <div className="form-heading">
              <h2 style={{ color: "white", marginTop: "5px", fontSize: "24px" }}>
                WEEKLY LESSON PLAN
              </h2>
            </div>
            <form className="form" onSubmit={updateReport}>
              <div className="user-details">
                <div className="form-details">
                  <div style={{ display: "flex" }}>
                    <label htmlFor="class">Class: </label>
                    <p style={{ marginLeft: "10px" }}>{reportData.div_name}</p>
                  </div>
                  <div>
                    <label htmlFor="month">Month: </label>
                    <input
                      // onChange={""}
                      name="month"
                      id="month"
                      type="month"
                      placeholder=""
                      defaultValue={reportData.month}
                    />
                  </div>
                </div>
                <div className="form-subject-details">
                  <div style={{ display: "flex" }}>
                    <label htmlFor="class">Subject: </label>
                    <p style={{ marginLeft: "10px" }}>{reportData.subject}</p>
                  </div>
                </div>
                <div className="lesson-name">
                  <label htmlFor="lessonName">Lesson Name: </label>
                  <input
                    defaultValue={reportData.lession_name}
                    // onChange={""}
                    id="lessonName"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div>
              <FcDownload size={28} cursor={"pointer"} onClick={()=>handleDownloadExcel(exportData.headers, exportData.body)}/>
            </div>
              </div>
              <div className="Accordion">
                {weeklyData.length > 0 ? days.map((day, idx) => {
                  return (
                    <DayPlan
                      days={day}
                      key={idx}
                      index ={idx}
                      weeklyData={weeklyData[idx] ? weeklyData[idx] : ""}
                      setWeeklyData={setWeeklyData}
                    />
                  );
                }) : "No data avalable"}
              </div>
                <input type="submit" className="btn btn-primary form-controll mt-0" value={"Update Data"}/>
            </form>
          </div>
      }
    </>
  );
};

export default AdminReportView;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    color: "white",
    backgroundColor: "rgb(46, 175, 226)",
    padding: "6px 10px",
    fontSize: "18px",
    outline: "none",
    border: "none",
    borderRadius: "6px",
  },
}));