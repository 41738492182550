import React, { useRef } from 'react'
import { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useReactToPrint } from 'react-to-print';
import Swal from "sweetalert2";
import { DecryptED } from "../../DecryptJS/Decrypt";

const AdminLessonReport = () => {
  const { sid, id, userid } = useParams();
  const [reports, setReports] = useState([]);
  const [apiloader, setapiLoader] = useState();

  const tableRef = useRef(null);
  const generatePdf = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: "LessonReport",
    onAfterPrint: () => alert("Print Success!")
  })

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  const getReportById = async () => {
    setapiLoader(true);
    try {
      const data = {
        "sid": DecryptED(sid),
        "id": DecryptED(id),
        "userId": DecryptED(userid)
      }
     await axios({
        method: "POST",
        //url: `http://localhost:8080/api/admin/lessonPlan/reportById`,
        url: `${process.env.REACT_APP_BASE_URL}/api/admin/lessonPlan/reportById`,
        data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      })
        .then((response) => {
          setReports(response.data[0]);
          setapiLoader(false);
        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
          setapiLoader(false);
        });
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 5000,
      });
      setapiLoader(false);
    }
  }
  useEffect(() => {
    getReportById();
    //console.log(reports)
  }, [])

  return (
    <div className='container '>
      {apiloader ? (
        <div className="TMLoader">
          <Loader type="ThreeDots" color="#bd2f5e" height={80} width={80} />
        </div>
      ) :

        <div className='mt-5 '>
          <div className='row'>
            <div className='col-md-6 my-2'><b className='text-primary'>Date:{" "}</b>{reports.created_date ? formatDate(reports.created_date) : ""}</div>
            <div className='col-md-6 my-2'><b className='text-primary'>Subject:{" "}</b>{reports.subject ? reports.subject : ""}</div>
            <div className='col-md-6 my-2'><b className='text-primary'>Class: </b>{reports.div_name ? reports.div_name : ""}</div>
            <div className='col-md-6 my-2'><b className='text-primary'>Teacher:{" "}</b>{reports.Teacher ? reports.Teacher : ""}</div>
          </div>

          <hr />

          <div className='my-3'><b className='text-primary'>Lesson Name:{" "}</b>{reports.Lessonname ? reports.Lessonname : ""}</div>
          <hr />
          <button className='btn btn-primary mt-0 mb-2' onClick={() => { generatePdf(); }}>Export to PDF</button>
          <table className="table table-hover table-bordered" ref={tableRef}>
            <tbody>
              <tr>
                <td><b className='text-primary'>Learning Objectives: </b></td>
                <td>{reports.lesson_objective ? reports.lesson_objective : ""}</td>
                <td><b className='text-primary'>Learning Outcome: </b></td>
                <td>{reports.learning_outcome ? reports.learning_outcome : ""}</td>
              </tr>

              <tr>
                <td><b className='text-primary'>Audio / Visual Aids: </b></td>
                <td>{reports.audio_visual_adis ? reports.audio_visual_adis : ""}</td>
                <td><b className='text-primary'>Pedagogical Practices: </b></td>
                <td>{reports.pedagogical_practices ? reports.pedagogical_practices : ""}</td>
              </tr>

              <tr>
                <td><b className='text-primary'>Teacher Preparation: </b></td>
                <td>{reports.teacher_preparation ? reports.teacher_preparation : ""}</td>
                <td><b className='text-primary'>Student Preparation: </b></td>
                <td>{reports.student_preparation ? reports.student_preparation : ""}</td>
              </tr>

              <tr>
                <td><b className='text-primary'>Additional Extended Information: </b></td>
                <td>{reports.additional_extended_information ? reports.additional_extended_information : ""}</td>
                <td><b className='text-primary'>Interdisciplinary Linkage: </b></td>
                <td>{reports.interdisciplinary_linkage ? reports.interdisciplinary_linkage : ""}</td>
              </tr>

              <tr>
                <td><b className='text-primary'>Infusion With Life Skills: </b></td>
                <td>{reports.infusion_with_life_skills ? reports.infusion_with_life_skills : ""}</td>
                <td><b className='text-primary'>Art Integration: </b></td>
                <td>{reports.art_integration ? reports.art_integration : ""}</td>
              </tr>

              <tr>
                <td><b className='text-primary'>Story Based: </b></td>
                <td>{reports.story_based ? reports.story_based : ""}</td>
                <td><b className='text-primary'>Artificial Intelligence: </b></td>
                <td>{reports.artificial_intelligence ? reports.artificial_intelligence : ""}</td>
              </tr>

              <tr>
                <td><b className='text-primary'>fln: </b></td>
                <td>{reports.fln ? reports.fln : ""}</td>
                <td><b className='text-primary'>Remedial Plan: </b></td>
                <td>{reports.remedial_plan ? reports.remedial_plan : ""}</td>
              </tr>

              <tr>
                <td><b className='text-primary'>Cwsn Plan: </b></td>
                <td>{reports.cwsn_plan ? reports.cwsn_plan : ""}</td>
                <td><b className='text-primary'>Assignment: </b></td>
                <td>{reports.assignment ? reports.assignment : ""}</td>
              </tr>

              <tr>
                <td><b className='text-primary'>Reference Used: </b></td>
                <td>{reports.reference_used ? reports.reference_used : ""}</td>
              </tr>
            </tbody>
          </table>

          <div className="btn-div my-3">
            <button
              style={{
                height: "40px",
                color: "white",
                backgroundColor: "rgb(111, 214, 255)",
                border: "none",
                outline: "none",
                fontSize: "18px",
                padding: "0px 16px",
                borderRadius: "6px",
                cursor: "pointer",
              }}
            >
              <Link to={`/admin/updatereport/${sid}/${userid}/${id}`}>
                EDIT INFORMATION
              </Link>
            </button>
          </div>
        </div>

      }
    </div>
  )
}

export default AdminLessonReport
