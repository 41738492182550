import React from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { FcDownload } from "react-icons/fc";
import { makeStyles } from "@material-ui/core/styles";
import { FcSearch } from "react-icons/fc";
import { AiFillDelete } from "react-icons/ai";
import { IconContext } from "react-icons";
import axios from "axios";
import Swal from "sweetalert2";
import generatePDF from "./reportGenerator";
import { DecryptED } from "../../DecryptJS/Decrypt";
const useStyles = makeStyles({
  mainDiv: {
    margin: "0px 30px",
    marginTop: "40px",
  },
  dateInfo: {
    display: "flex",
    margin: "0px 10px",
    justifyContent: "space-between",
  },
  teacherInfo: {
    display: "flex",
  },
});

const ReportResult = ({
  startDate,
  endDate,
  reports,
  setReports,
  userid,
  sid,
}) => {
  const classes = useStyles();
  const handleDelete = async (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteReport = async () => {
          const response = await axios.post(
            "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
            {
              fun_name: "deleteLessonPlan",
              staff_id: DecryptED(userid),
              sid: DecryptED(sid),
              id,
            }
          );
          if (response) {
            const newReports = reports
              .reverse()
              .filter((report) => report.id !== id);
            setReports(newReports);
          }
        };
        deleteReport();
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };
  return (
    <div className={classes.mainDiv}>
      <div className={classes.dateInfo}>
        <div>
          <span style={{ color: "rgb(111, 214, 255)" }}>
            {startDate} - {endDate}
          </span>
        </div>
        <FcDownload
          style={{ cursor: "pointer" }}
          onClick={() => generatePDF(reports)}
          size={28}
        />
      </div>

      <div className={classes.teacherInfo}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Class</TableCell>
                <TableCell align="center">Subject</TableCell>
                <TableCell>Lesson Name</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports &&
                reports.reverse().map((report, idx) => {
                  return (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{report.div_name}</TableCell>
                      <TableCell align="center">{report.subject}</TableCell>
                      <TableCell component="th" scope="row">
                        {report.Lessonname}
                      </TableCell>
                      <TableCell align="center">
                        {report.created_date.substring(0, 11)}
                      </TableCell>

                      <TableCell align="center">
                        <Link
                          to={`/reportLessonView/${sid}/${userid}/${report.id}`}
                        >
                          <FcSearch size={28} />{" "}
                        </Link>

                        <IconContext.Provider value={{ color: "red" }}>
                          <AiFillDelete
                            size={28}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleDelete(e, report.id)}
                          />
                        </IconContext.Provider>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ReportResult;
