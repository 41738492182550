import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import '../css/ViewResults.css';
import Loader from 'react-loader-spinner'
import Navbar from '../User/navbar';
// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';

export default function ViewResults() {

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Quiz />
        </div>
    )
}

function Quiz() {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const history = useHistory();
    const { sid, uid } = useParams()
    const [results, setresults] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [loader, setLoader] = useState(true);
    const [Questionloader, setQuestionLoader] = useState(true);
    const [ScoreOrQues, setScoreOrQues] = useState(true);
    const [click, setClick] = useState(false);
    const [validate, setValidate] = useState(false);
    const [downloadData, setDownloadData] = useState([]);
    sessionStorage.setItem("lpath", history.location.pathname)

    useEffect(() => {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
            { fun_name: "Quizapp_Get_QuizResult", sid: sid, userid: uid, quizid: history.location.state.id })
            // { fun_name: "Quizapp_Get_QuizResult", sid: "681", userid: "1028", quizid: "1625996861552" })
            .then(async res => {
                if (history.location.state.class !== undefined) {
                    try {
                        if (res.data.quizresults.length > 0) {
                            let data = [];
                            let status = false;
                            try {
                                await res.data.quizresults.map((list, index) => {
                                    if (list.status === "pending") { status = true; }
                                    data.push({
                                        ['S.NO']: index + 1,
                                        ['Student Name']: list.studentName,
                                        ['Score']: list.score,
                                        // ['Time']: list.time
                                    })
                                })
                            } catch (e) { console.error(e); }
                            setValidate(status);
                            setDownloadData(data);
                        }
                        setresults(res.data.quizresults)
                    } catch (e) {
                        setresults(results)
                        console.error(e);
                    }
                }
                else {
                    try {
                        setresults((res.data.quizresults).sort(function (a, b) {
                            if (a.score > b.score) return -1;
                            else if (a.score < b.score) return 1;
                            else if (a.time < b.time) return -1;
                            else if (a.time > b.time) return 1;
                            return 0;
                        }).slice(0, 5))
                    } catch (e) {
                        setresults(results)
                        console.error(e);
                    }
                }
                setLoader(false);
            })
    }, [])

    useEffect(() => {
        axios.all(
            [
                axios.post(
                    'https://topschool.prisms.in/rest/index.php/user_list.json?',
                    {
                        fun_name: "Quizapp_Getquestion", sid: sid, userid: uid, quizid: history.location.state.id
                    }
                ),
                axios.post(
                    'https://topschool.prisms.in/rest/index.php/user_list.json?',
                    {
                        fun_name: "Quizapp_Get_DataByQiz", sid: sid, quizid: history.location.state.id, isreview: 1
                    }
                )
            ]
        ).then((args) => {
            let QData = []
            let RData = []
            try {
                QData = args[0].data.Questions.filter(i => i.isdeleted === "0" && i.questionType !== "4");
                RData = (args[1].data.review).map(i => JSON.parse(i.answers.replaceAll("\n", "\\n")))
            } catch (err) {
                console.error(err)
            }
            for (let i = 0; i < QData.length; i++) {
                let correct = 0, wrong = 0, unattempted = 0;
                RData.forEach(val => {
                    try {
                        let Udata = { isOption1: val[i].isOption1, isOption2: val[i].isOption2, isOption3: val[i].isOption3, isOption4: val[i].isOption4 },
                            Adata = { isOption1: QData[i].isOption1, isOption2: QData[i].isOption2, isOption3: QData[i].isOption3, isOption4: QData[i].isOption4 },
                            Odata = { isOption1: "0", isOption2: "0", isOption3: "0", isOption4: "0" }
                        if (!Udata) unattempted++
                        else if (JSON.stringify(Udata) === JSON.stringify(Adata)) correct++
                        else if (JSON.stringify(Odata) === JSON.stringify(Adata)) unattempted++
                        else wrong++
                    } catch (e) {
                        unattempted++;
                        console.error(e)
                    }
                })
                QData[i].correct = correct
                QData[i].wrong = wrong
                QData[i].unattempted = unattempted
            }
            setQuestions(QData)
            setQuestionLoader(false)
        })
    }, [])

    useEffect(() => {
        if (history.action === "PUSH") {
            if (validate) {
                Swal.fire({
                    title: 'There are few pending results',
                    text: "would you like to check them",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Check!',
                    cancelButtonText: 'No, Cancel!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        history.push(`/quizapp/checking_answers/${sid}/${uid}`, history.location.state)
                    }
                })
            }
        }
    }, [validate])

    function download(data, filename) {
        try {
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data1 = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data1, filename + fileExtension);
        } catch (e) {
            console.error(e);
        }
    }

    function sortResult(click) {
        try {
            if (!click) {
                setresults(results.sort(function (a, b) {
                    if (a.score > b.score) return -1;
                    else if (a.score < b.score) return 1;
                    else if (a.time < b.time) return -1;
                    else if (a.time > b.time) return 1;
                    return 0;
                }))
                setClick(true);
            } else {
                setresults(results.sort(function (a, b) {
                    if (a.score < b.score) return -1;
                    else if (a.score > b.score) return 1;
                    else if (a.time > b.time) return -1;
                    else if (a.time < b.time) return 1;
                    return 0;
                }))
                setClick(false);
            }
        } catch (e) {
            console.error(e);
        }
    }
    function sortResultscore(click) {
        try {
            if (!click) {
                setresults(results.sort(function (a, b) {
                    if (a.score > b.score) return -1;
                    else if (a.score < b.score) return 1;
                    return 0;
                }))
                setClick(true);
            } else {
                setresults(results.sort(function (a, b) {
                    if (a.score < b.score) return -1;
                    else if (a.score > b.score) return 1;
                    return 0;
                }))
                setClick(false);
            }
        } catch (e) {
            console.error(e);
        }
    }
    function sortResulttime(click) {
        try {
            if (!click) {
                setresults(results.sort(function (a, b) {
                    if (a.time < b.time) return -1;
                    else if (a.time > b.time) return 1;
                    return 0;
                }))
                setClick(true);
            } else {
                setresults(results.sort(function (a, b) {
                    if (a.time > b.time) return -1;
                    else if (a.time < b.time) return 1;
                    return 0;
                }))
                setClick(false);
            }
        } catch (e) {
            console.error(e);
        }
    }




    return (
        <div className="quiz-container" >
            <div className="quiz-head">
                <div>{history.location.state.class !== undefined ? 'Results' : 'Leaderboard'}</div>
                {!loader ?
                    <Navbar home={{ isHome: true, home: history.location.state.class !== undefined ? `${sessionStorage.tora}` : `/quizapp/${sid}/${uid}` }} back={true} refresh={true} />
                    : null
                }
            </div>
            <hr className="hr-line" />
            <div className="quiz-list" >
                <div className="quiz-list-table-div" style={{ marginTop: "0px" }}>
                    {loader || Questionloader ?
                        <div id="loader">
                            <Loader
                                type="Oval"
                                color="#0066ff"
                                height={70}
                                width={70}
                            /></div> :
                        results.length === 0 ? <div className="quiz-notfound">
                            <label>Results Not Found or Quiz Not Started Yet</label>
                            {validate ?
                                <div>
                                    <span className="QM__Check__Button" onClick={() => { history.push(`/quizapp/checking_answers/${sid}/${uid}`, history.location.state) }}>
                                        Check Answers
                                    </span>
                                </div>
                                :
                                null
                            }
                        </div> :
                            <div style={{ marginBottom: "100px" }}>
                                <label>Quiz Title : {(history.location.state.quizName).toUpperCase()}</label><br></br>
                                {/* <label>Total : {results[0].total}</label> */}
                                <br></br>
                                <div className="QM__Check__Download">
                                    {validate ?
                                        <div >
                                            <span className="QM__Check__Button" onClick={() => { history.push(`/quizapp/checking_answers/${sid}/${uid}`, history.location.state) }}>
                                                Check Answers
                                            </span>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                    <div style={{ display: history.location.state.class !== undefined ? "block" : "none" }}>
                                        <img onClick={() => { download(downloadData, results[0].quizName) }} src="https://img.icons8.com/metro/26/4b4bff/download.png" alt="download" />
                                    </div>
                                </div>
                                {/* <div className="download" style={{ display: history.location.state.class !== undefined ? "block" : "none" }}>
                                    <ExcelFile element={<img src="https://img.icons8.com/metro/26/042CAD/download.png" alt="download" style={{ opacity: 0.8 }} />}>
                                        <ExcelSheet data={downloadData} name={results[0].quizName}>
                                            <ExcelColumn label="S.No" value="S.NO" />
                                            <ExcelColumn label="Student Name" value="Student Name" />
                                            <ExcelColumn label="Score" value="Score" />
                                            <ExcelColumn label="Time" value={"Time"} />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </div> */}
                                <div style={{ display: history.location.state.class !== undefined ? 'flex' : "none", alignItems: 'center', justifyContent: 'center', width: '100%', boxShadow: "0px 2px 8px 0px rgba(0,0,0,0.3)", cursor: 'pointer' }}>
                                    <div
                                        style={{ backgroundColor: ScoreOrQues ? "rgba(75, 75, 255, 0.653)" : "rgb(239, 241, 241)", width: "50%", textAlign: "center", padding: "5px 0px", color: ScoreOrQues ? "white" : "rgb(0, 102, 255)", fontWeight: "bold" }}
                                        onClick={() => { setScoreOrQues(true) }}
                                    >
                                        Score
                                    </div>
                                    <div
                                        style={{ backgroundColor: !ScoreOrQues ? "rgba(75, 75, 255, 0.653)" : "rgb(239, 241, 241)", width: "50%", textAlign: "center", padding: "5px 0px", color: !ScoreOrQues ? "white" : "rgb(0, 102, 255)", fontWeight: "bold" }}
                                        onClick={() => { setScoreOrQues(false) }}
                                    >
                                        Questions
                                    </div>
                                </div>
                                {ScoreOrQues ?
                                    <table id="Users" className="sortable">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th onClick={() => { sortResult(click) }}>Student Name</th>
                                                <th onClick={() => { sortResultscore(click) }}>Score</th>
                                                {/* <th onClick={() => { sortResulttime(click) }}>Time</th> */}
                                            </tr>
                                        </thead>
                                        {click ?
                                            <tbody>
                                                {results.map((r, k) => {
                                                    return (
                                                        <tr key={k}>
                                                            <td>{k + 1}</td>
                                                            <td>{r.studentName}</td>
                                                            <td>{r.score}/{r.total}</td>
                                                            {/* <td>{r.time}</td> */}
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            :
                                            <tbody>
                                                {results.map((r, k) => {
                                                    return (
                                                        <tr key={k}>
                                                            <td>{k + 1}</td>
                                                            <td>{r.studentName}</td>
                                                            <td>{r.score}/{r.total}</td>
                                                            {/* <td>{r.time}</td> */}
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        }
                                    </table>
                                    :
                                    <table id="Users" className="sortable">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Question</th>
                                                <th>Correct</th>
                                                <th>Wrong</th>
                                                <th>Unattempted</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {questions.map((val, ind) => (
                                                <tr key={ind}>
                                                    <td>{ind + 1}</td>
                                                    <td>{val.questionTitle}</td>
                                                    <td>{val.correct}</td>
                                                    <td>{val.wrong}</td>
                                                    <td>{val.unattempted}</td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                    }
                </div >
            </div>
        </div>
    );
}
