import React from 'react';
import { Redirect, useHistory, useParams } from "react-router-dom";
import Navbar from '../User/navbar';
import moment from 'moment';

export default function ViewQuizDetails() {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Quiz />
        </div>
    )
}

function Quiz() {

    const history = useHistory();
    const {newSid, sid, uid } = useParams();
    try {
        if (history.action !== "PUSH") {
            return <Redirect to={"/quizapp_all/" + newSid +'/' + sid + '/' + uid} />
        }
    } catch (e) {
        console.error(e);
    }
    sessionStorage.setItem("lpath", history.location.pathname)
    const quizDetails = history.location.state;
    return (
        <div className="quiz-container">
            <div className="quiz-head"><div>Quiz Details</div><Navbar home={{ isHome: true, home: `/quizapp_all/${newSid}/${sid}/${uid}` }} back={true} refresh={false} /></div>
            <hr className="hr-line" />
            <div>
                {
                    Object.keys(quizDetails).map((quizd, i) => {

                        if (quizd === "quizName" || quizd === "negativeMarking" || quizd === "publishDate" || quizd === "publishTime" || quizd === "attempts" || quizd === "duration" || quizd === "class" || quizd === "division") {
                            return (
                                <div key={i} style={{ padding: "10px" }}>
                                    <span style={{ color: "rgb(0, 102, 255)", fontWeight: "600" }}>{
                                        quizd === "quizName" ? "QUIZ NAME" :
                                            quizd === "negativeMarking" ? "NEGATIVE MARKING" :
                                                quizd === "publishDate" ? "PUBLISH DATE" :
                                                    quizd === "publishTime" ? "PUBLISH TIME" :
                                                        quizd === "attempts" ? "TOTAL ATTEMPTS" :
                                                            quizd.toUpperCase()
                                    }</span> - <span style={{ color: "rgb(0, 102, 255)", fontWeight: "600" }}>
                                        {(quizd === "attempts" && Object.values(quizDetails)[i] === "0" ? "UNLIMITED" :
                                            quizd === "duration" && Object.values(quizDetails)[i] === "0" ? "UNLIMITED" :
                                                quizd === "publishDate" ? moment(Object.values(quizDetails)[i], "YYYY-MM-DD").format('L') :
                                                    quizd === "publishTime" ? moment(Object.values(quizDetails)[i], "H:mm").format("LT") :
                                                        quizd === "negativeMarking" ? Object.values(quizDetails)[i].toUpperCase() :
                                                            Object.values(quizDetails)[i])} {quizd === "duration" && Object.values(quizDetails)[i] !== "0" ? "mins" : null}
                                    </span>
                                    {
                                        quizd === "attempts" ?
                                            <div>
                                                <br></br>
                                                <span style={{ color: "rgb(0, 102, 255)", fontWeight: "600" }}>
                                                    ATTEMPTS LEFT - {typeof (history.location.state.ioru) === "object" ?
                                                        `${parseInt(Object.values(quizDetails)[i]) - parseInt(history.location.state.ioru.attempts)}` === "0" ? "UNLIMITED" :
                                                            `${parseInt(Object.values(quizDetails)[i]) - parseInt(history.location.state.ioru.attempts)}` :
                                                        Object.values(quizDetails)[i] === "0" ? "UNLIMITED" : Object.values(quizDetails)[i]}
                                                </span>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            )
                        } else {
                            return (null)
                        }
                    })
                }
            </div>
            <div className="button-div">

                <button
                    onClick={() => { history.push('/quizapp_all/quizcard' + '/' + newSid + '/' + sid + '/' + uid, quizDetails) }}
                    style={{ fontSize: "20px", paddingLeft: 40, paddingRight: 40 }}
                    className="create-button">
                    <img src="https://img.icons8.com/ios-filled/15/0066ff/runner-starting-the-race.png" alt="start" />
                    &nbsp;&nbsp;Start&nbsp;&nbsp;
                    <img alt="start" src="https://img.icons8.com/ios-filled/15/0066ff/runner-starting-the-race.png" />
                </button>
            </div>
        </div>
    );
}
