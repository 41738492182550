import React, { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import "./StartingPage.css"

const ChangePassword = () => {

  const history = useHistory()
  const [Pass, setPass] = useState("")
  const [Validate, setValidate] = useState("")


  const clickHandler = () => {
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : ""
    axios.post(" https://api.paykit.in/change-passwd", { password: Pass }, {
      headers: { "Authorization": token }
    }
    ).then(res => {
      const userid = localStorage.getItem('loginid') ? localStorage.getItem('loginid') : ""
      axios.post("https://marcopolo.prisms.in/api/LinksPay/walletCreated", {
        walletUsername: userid
      }, { headers: { Authorization: "#$laksdfnlknoea#$@$%^&%$" } })
        .then(res => {
          alert("Password changed successfully you can now log into your wallet!")
          history.push("/wallet")
        })
        .catch(err => console.log(err))

    }
    )
      .catch(err => alert("Something went wrong!"))
  }


  const changeHandler = (e) => {
    setPass(e.target.value)
  }
  const changeHandler2 = (e) => {
    setValidate(e.target.value)
  }

  return (
    <div>
      <div style={{ marginTop: "2rem", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <h2 style={{ marginBottom: "1rem" }}>Change your Password</h2>
        <p>Password</p>
        <input style={{ marginBottom: "1rem",maxWidth:"15rem" }} onChange={changeHandler} type="password" />
        <p>Confirm Your password</p>
        <input style={{ marginBottom: "1rem",maxWidth:"15rem" }} onChange={changeHandler2} type="password" />
        <button style={{ marginTop: "1rem", width: "13rem" }} className='btn' disabled={!(Pass === Validate && Pass.length > 0)} onClick={clickHandler}>Change</button>
      </div>
    </div>
  )
}

export default ChangePassword