import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ReportResult from "./ReportResult";
import axios from "axios";
import { DecryptED } from "../../DecryptJS/Decrypt";
const useStyles = makeStyles({
  mainDiv: {
    marginTop: "0px",
  },
  heading: {
    margin: "0",
    padding: "0",
    height: "45px",
    textAlign: "center",
  },
  dateDiv: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginTop: "40px",
  },
  input: {
    width: "200px",
    marginLeft: "15px",
    backgroundColor: "rgb(111, 214, 255)",
    padding: "5px 8px",
    border: "none",
    outline: "none",
  },
  label: {
    fontSize: "16px",
    color: "black",
  },
  form: {
    margin: "10px 0px",
  },
  button: {
    margin: "20px 0px",
    backgroundColor: "rgb(111, 214, 255)",
    padding: "6px 18px",
    color: "white",
  },
});
// #42A0EA
const Report = () => {
  const classes = useStyles();
  let { sid, userid } = useParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reports, setReports] = useState([]);


  console.log(startDate)
  console.log(endDate)

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = {
      sid: DecryptED(sid),
      staff: DecryptED(userid),
      startDate,
      endDate,
    };

    const response = await axios({
      method: "POST",
      url: "https://marcopolo.prisms.in/api/admin/lessonPlan/reportBydate",
      data: data,
      headers: {
        Authorization: "#$laksdfnlknoea#$@$%^&%$",
      },
    });
    if (response) {
      console.log(response.data);
      setReports(response.data);
    }
  };
  useEffect(() => {
    let currentDate = new Date().toISOString().split("T")[0];
    setEndDate(currentDate);
    let lastMonth = currentDate.split("-")[1] - 1;
    let lastDate;
    if (lastMonth < 10) {
      lastDate =
        currentDate.split("-")[0] +
        `-0${lastMonth}-` +
        currentDate.split("-")[2];
    } else {
      lastDate =
        currentDate.split("-")[0] +
        `-${lastMonth}-` +
        currentDate.split("-")[2];
    }
    setStartDate(lastDate);

    const getReports = async () => {
      const data = {
        sid: DecryptED(sid),
        staff: DecryptED(userid),
        startDate,
        endDate,
      };
  
  
      const response = await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/admin/lessonPlan/reportBydate",
        data: data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      });
      if (response) {
        console.log(response.data);
        setReports(response.data);
      }
    };
    getReports();
  }, []);

  return (
    <div className={classes.mainDiv}>
      <div className={classes.heading}>
        <h3
          style={{
            color: "black",
            fontSize: "24px",
            padding: "8px 12px",
            marginLeft: "30px",
          }}
        >
          Lesson Reports
        </h3>
      </div>
      <div className={classes.dateDiv}>
        <form className={classes.form}>
          <label className={classes.label} htmlFor="fromDate">
            Start Date
          </label>
          <input
            className={classes.input}
            type="date"
            style={{ color: "white" }}
            onChange={(e) => setStartDate(e.target.value)}
            name="fromDate"
            value={startDate}
          />
        </form>
        <form className={classes.form}>
          <label className={classes.label} htmlFor="toDate">
            End Date
          </label>
          <input
            className={classes.input}
            style={{ marginLeft: "20px", color: "white" }}
            type="date"
            name="toDate"
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
          />
        </form>
        <div>
          <Button
            className={classes.button}
            variant="contained"
            onClick={(e) => submitHandler(e)}
          >
            Submit
          </Button>
        </div>
      </div>
      <ReportResult
        reports={reports}
        setReports={setReports}
        startDate={startDate}
        endDate={endDate}
        userid={userid}
        sid={sid}
      />
    </div>
  );
};

export default Report;
