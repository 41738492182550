import React, { Component } from "react";
import "../App.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import Eventdetails from "./eventdetails";
import Inputdetails from "./inputdetails";
import axios from "axios";

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sid: this.props.match.params.sid ? this.props.match.params.sid : "",
      userid: this.props.match.params.userid
        ? this.props.match.params.userid
        : "",
      month: "",
      changedDate: new Date(),
      next_previous_count: 0,
      highlightWithRanges: [],
      dataDoughnut: {},
      flag: 0,
      eventData: [],
    };
  }

  getAttendanceData(month, self) {
    console.log("MMMMMMMMM", month);
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        ["fun_name"]: "get_Calendar_Data_For_IOS",
        ["sid"]: this.state.sid,
        ["userid"]: this.state.userid,
        ["month"]: month,
      })
      .then(function(result) {
        console.log("result", result);

        if (result.data.length > 0) {
          self.setState({
            flag: 1,
            eventData: result.data,
          });
          var modifyResponseObj = [
            {
              "react-datepicker__day--highlighted-custom-1": [],
            },
            {
              "react-datepicker__day--highlighted-custom-2": [],
            },
            {
              "react-datepicker__day--highlighted-custom-3": [],
            },
            {
              "react-datepicker__day--highlighted-custom-4": [],
            },
            {
              "react-datepicker__day--highlighted-custom-5": [],
            },
          ];
          var modifydatasets = [];

          result.data.map((value, index) => {
            if (value && value != "") {
              var responseArray = value;
              Object.keys(value).forEach(function(eachObj) {
                if (eachObj == "eventdate") {
                  modifyResponseObj[1][
                    "react-datepicker__day--highlighted-custom-2"
                  ].push(new Date(value.eventdate));
                }
              });
            }
          });
          var dataDoughnut = {
            labels: [
              "Present",
              "Absent",
              "Holiday",
              "Weekly Holiday",
              "Not Taken",
            ],
            legend: {
              display: false,
            },
            datasets: [
              {
                data: modifydatasets,
                legend: {
                  fontSize: 30,
                },
                backgroundColor: [
                  "#46BFBD",
                  "#F7464A",
                  "#FDB45C",
                  "#949FB1",
                  "#4D5360",
                ],
                hoverBackgroundColor: [
                  "#5AD3D1",
                  "#FF5A5E",
                  "#FFC870",
                  "#A8B3C5",
                  "#4D5360",
                ],
              },
            ],
          };
          console.log("modifydatasets==>" + JSON.stringify(dataDoughnut));
          self.setState({
            highlightWithRanges: modifyResponseObj,
            dataDoughnut,
          });
        } else {
          self.setState({
            flag: 1,
            eventData: [],
          });
        }
      });
    //set state
  }

  componentDidMount() {
    this.getAttendanceData(this.state.changedDate, this);
    //call function this.getAttendance(month);
  }
  onPrevClick(month) {
    console.log("onPrevClick", month);
    this.setState({
      changedDate: month,
      flag: 0,
    });
    this.getAttendanceData(month, this);
    //call funciton this.getAttendaceData()
    //set state data
  }
  onNextClick(month) {
    console.log("onNextClick", month);
    let CurrentDate = new Date();
    // if(month <= CurrentDate)
    // {
    this.setState({
      changedDate: month,
      flag: 0,
    });
    this.getAttendanceData(month, this);
    // }
    //call funciton this.getAttendaceData()
    //set state data
  }

  render() {
    return (
      <div className="container">
        {this.state.flag && this.state.flag == 1 ? (
          <div className="">
            <Inputdetails
              onPrevClick_attr={this.onPrevClick.bind(this)}
              onNextClick_attr={this.onNextClick.bind(this)}
              highlightDates={this.state.highlightWithRanges}
              changeDates={this.state.changedDate}
              next_previous_count={this.state.next_previous_count}
              flag={this.state.flag}
            >
              Input Tags
            </Inputdetails>
            <Eventdetails
              flag={this.state.flag}
              getEventDataFromParent={this.state.eventData}
            >
              Showgraph
            </Eventdetails>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="loader border-top-info margin-top-1" />
          </div>
        )}
      </div>
    );
  }
}

export default Calendar;
