import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    [theme.breakpoints.down('sm')]: { 
      zoom:'68%'
    }
  },
  container: {
   
  },
}));
function groupBy(arr, prop){
  const map = new Map(Array.from(arr, obj => [obj[prop], []]));
  arr.forEach(obj => map.get(obj[prop]).push(obj));
  return Array.from(map.values());
}
export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [finalarray, setFinalArr] = React.useState(null);
  const [TA, setTA] = React.useState(0);
  const [TP, setTP] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
 React.useState(()=>{
   if(finalarray!=null){
     setFinalArr(null)
   }
  var key1=Object.keys(props.SD);
  console.log(key1)
var sr=0;
var taa=0;
var tpp=0;
const tf=[];
var urrow=[];
   for(let i=0;i<key1.length;i++){
    var stp=0;
    var sta=0;
    var cnt=0;
    var uniq=[];
    var rowd={}
    var newrr={}
    var totalp=0;
var totalabs=0;
    var key2=Object.keys(props.SD[key1[i]])
    for(let j=0;j<key2.length;j++){
      
      if(cnt==0){
        
        rowd['sr']=sr
        rowd['name']=props.SD[key1[i]][key2[j]].name;
        sr=sr+1;
      }
      var timeslot=key2[j];
      var lecturetime=props.SD[key1[i]][key2[j]].lecturetime;
      var created_date=props.SD[key1[i]][key2[j]].created_date;
      var str_time=lecturetime.split('to');
      // console.log('str_time--',str_time)
      var dt2=moment(created_date).format('hh:mm A');
      // var st_t=moment.utc(str_time[0]);
      // var nd_t=moment.utc(str_time[1]);
      var st_t=moment(str_time[0]).format('hh:mm A');
      var nd_t=moment(str_time[1]).format('hh:mm A');
    
      var start_time= moment(str_time[0],'hh:mm A').subtract(10, 'minutes').subtract(10,'seconds').format('hh:mm A');
      var end_time=moment(str_time[1],'hh:mm A').add(1,'minutes').format('hh:mm A');
      if(moment(dt2, 'HH:mm a').isBetween(moment(start_time, 'HH:mm a'),moment(end_time, 'HH:mm a'))){
      // if(moment(dt2, 'HH:mm a').isAfter(moment(start_time, 'HH:mm a')) && moment(dt2, 'HH:mm a').isBefore(moment(end_time, 'HH:mm a')) ){
      // if(dt2>=start_time && dt2<end_time){
        
        stp=stp+1;
        totalp=totalp+1;
        tpp=tpp+1;
        console.log('P',timeslot,dt2,moment(str_time[0],'hh:mm A').subtract(10, 'minutes').format('hh:mm A'), rowd.name)
        rowd['attendance']="P";
        rowd['timeslot']=timeslot
      }
      else{
        
        sta=sta+1;
       totalabs=totalabs+1;
       taa=taa+1;
       console.log('A',timeslot,dt2,moment(str_time[0],'hh:mm A').subtract(10, 'minutes').format('hh:mm A'),rowd.name)
       rowd['attendance']='A';
       rowd['timeslot']=timeslot
      
      }
      if(rowd['attendance']=='P'){
        uniq.push('P')
        tf.push(rowd)
      }
      else{
        uniq.push('A')
        tf.push(rowd)
      }
    
    }
    rowd['attendance']=uniq
    rowd['totalp']=totalp
    rowd['totalabs']=totalabs
    
   }
  
   var ff= groupBy(tf,'name')
   setTA(taa);
   setTP(tpp);
  setFinalArr(ff);
 
 },[props])


  // const rows = [
  // //  props.stud_data!=null||props.stud_data!=undefined?props.stud_data.map((v,i)=>
  // //   createData(v.divname,parseInt(v.total),parseInt(v.ta),parseInt(v.imgc))
  // //   ):null
  //   ];
  //   const columns =  props.olstud_report!=null||props.olstud_report!=undefined?props.olstud_report.map((v,i)=>[
  //    {id:v.teachname,label:v.teachname,minWidth: 170}
  //   ]);
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            <TableCell
                  key={'#'}
                >
                  {"#"}
                </TableCell>
                <TableCell
                  key={'name'}
                >
                  {"Name"}
                </TableCell>
              {props.olstud_report!=null||props.olstud_report!=undefined?props.olstud_report.map((column) => (
                <TableCell
                  key={column.teachname}
                
                >
                  {column.teachname}<br/>
                  {column.lecturetime}<br/>
                  {column.subject}
                </TableCell>
              )):null}
               <TableCell
                  key={'Pr'}
                >
                  {"Pr"}
                </TableCell>
                <TableCell
                  key={'Ab'}
                >
                  {"Ab"}
                </TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>

            <TableRow hover >
              <TableCell style={{color:'green'}} colSpan={props.olstud_report.length+2} >
                Total Present 
              </TableCell>
              <TableCell style={{color:'green'}}  colSpan={2} align="left" >
                {TP} 
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell style={{color:'red'}} colSpan={props.olstud_report.length+2}>
                Total Absent 
              </TableCell>
              <TableCell style={{color:'red'}} colSpan={2} align="right">
                {TA} 
              </TableCell>
            </TableRow>
              {finalarray!=null?finalarray.map((v,i)=>{return(
               <TableRow hover role="checkbox" tabIndex={-1} key={v.name}>
                    <TableCell key={i} >
                    { i+1}
                    </TableCell>
                  <TableCell key={i} >
                    { v[0].name}
                    </TableCell>
                  {finalarray[i].map((column,j) => {
                    return(
                      
                      <TableCell key={column.attendance[j]}  style={{ minWidth: 100,color:column.attendance[j]=='A'?'red':'green' }} align={'left'}>
                    { column.attendance[j]}
                    </TableCell>);
                  })}
                <TableCell key={i} >
                    { v[v.length-1].totalp}
                    </TableCell>
                    <TableCell key={i} >
                    { v[v.length-1].totalabs}
                    </TableCell>
               </TableRow>);
              
                }):null}
            {/* {[].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {[].map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })} */}
          </TableBody>
        </Table>
      </TableContainer>
    
    </Paper>
  );
}
