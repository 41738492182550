import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import weeklyImg from "../images/weeklyPlan.png";
import reportImg from "../images/report.png";
import {DecryptED} from "../../DecryptJS/Decrypt"
const Home = () => {
  let { sid, userid } = useParams();
  sid = DecryptED(sid);
  userid = DecryptED(userid);

  return (
    <>
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "40%", height: "40%", textAlign: "center" }}>
          <div className="weeklyPlan" style={{ marginBottom: "20px" }}>
            <Link to={`/weeklyPlan/${sid}/${userid}`}>
              <img
                style={{ width: "100px", height: "100px" }}
                src={weeklyImg}
                alt="weeklyImg"
              />
            </Link>
            <h4 style={{ color: "black", marginTop: "8px" }}>Weekly Plan</h4>
          </div>
          <div className="report">
            <Link to={`/weeklyReport/${sid}/${userid}`}>
              <img
                style={{ width: "100px", height: "100px" }}
                src={reportImg}
                alt="reportImg"
              />
            </Link>
            <h4 style={{ color: "black", marginTop: "8px" }}>Report</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
