import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import Navbar from '../User/navbar'
import Loader from 'react-loader-spinner'
import axios from 'axios';
import Swal from 'sweetalert2';
import { TextareaAutosize } from '@material-ui/core';

export default function CheckingAnswer() {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Quiz />
        </div>
    )
}

function Quiz() {
    const history = useHistory();
    const { sid, uid } = useParams();
    const [loader, setloader] = useState(true);
    const [results, setresults] = useState([]);
    const [questions, setquestions] = useState([]);
    const [answers, setanswers] = useState("");
    const [resultCounter, setresultCounter] = useState(0);
    const [questionCounter, setquestionCounter] = useState(0);
    const [counter, setcounter] = useState(1);
    const [marksCounter, setMarksCounter] = useState(0);
    const [ans, setAns] = useState("");

    useEffect(() => {

        const getResults = axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
            { fun_name: "Quizapp_Get_QuizResult", sid: sid, userid: uid, quizid: history.location.state.id }).then(res => {
                try {
                    setresults(res.data.quizresults.filter(i => i.status === "pending"))
                } catch (e) {
                    setresults([])
                    console.error(e);
                }
            })
        const getQuestions = axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
            { fun_name: "Quizapp_Getquestion", sid: sid, userid: uid, quizid: history.location.state.id }).then(res => {
                try {
                    setquestions(res.data.Questions.reduce((acc, i, k) => {
                        if (i.questionType === "4") {
                            return (
                                [
                                    ...acc, { ...i, a_id: k }
                                ]
                            )
                        } else {
                            return acc
                        }
                    }, []));
                } catch (e) {
                    setquestions([])
                    console.error(e);
                }
            })
        const getAnswers = axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
            { fun_name: "Quizapp_Get_DataByQiz", sid: sid, quizid: history.location.state.id, isreview: 1 }).then(res => {
                try {
                    setanswers(
                        (res.data.review).reduce((acc, i) => {
                            return (
                                {
                                    ...acc, [i.userid]: { ...i, answers: JSON.parse(i.answers.replaceAll("\n", "\\n")) }
                                }
                            )
                        }, {})
                    );
                } catch (e) {
                    setanswers([])
                    console.error(e);
                }
            })

        axios.all([getAnswers, getResults, getQuestions]).then(() => {
            setloader(false)
        })
    }, [])


    useEffect(() => {
        try {
            if (results.length !== 0) {
                if (questions.length !== 0) {
                    if (answers !== "") {
                        const u_id = results[resultCounter].userid
                        const a_id = questions[questionCounter].a_id
                        console.log(u_id, a_id)
                        setAns(answers[u_id].answers[a_id].isOption1)
                    }
                }
            }
        } catch (e) {
            setAns("")
            console.error(e);
        }
    }, [questionCounter, resultCounter, answers, questions, results])


    function nextFunc(markGivem, resultsObj, score) {
        try {
            document.getElementById("QmMarksInput").value = ""
            const totalNoofQuestion = questions.length * results.length
            if (totalNoofQuestion - 1 >= counter) {
                if (questions.length === questionCounter + 1) {
                    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
                        {
                            fun_name: "UpdateQuizResult",
                            sid: sid,
                            quizid: resultsObj.quizid,
                            userid: resultsObj.userid,
                            score: parseInt(resultsObj.score) + score,
                            time: resultsObj.time,
                            attempts: resultsObj.attempts,
                            quizName: resultsObj.quizName,
                            total: resultsObj.total,
                            studentName: resultsObj.studentName,
                            status: "",
                        }
                    )
                    setMarksCounter(0)
                    setquestionCounter(0)
                    setresultCounter(resultCounter + 1)
                    setcounter(counter + 1)
                } else {
                    setMarksCounter(marksCounter + markGivem)
                    setquestionCounter(questionCounter + 1)
                    setcounter(counter + 1)
                }
            } else {
                setloader(true);
                axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
                    {
                        fun_name: "UpdateQuizResult",
                        sid: sid,
                        quizid: resultsObj.quizid,
                        userid: resultsObj.userid,
                        score: parseInt(resultsObj.score) + score,
                        time: resultsObj.time,
                        attempts: resultsObj.attempts,
                        quizName: resultsObj.quizName,
                        total: resultsObj.total,
                        studentName: resultsObj.studentName,
                        status: "",
                    }
                ).then(() => {
                    history.goBack();
                })
            }
        } catch (e) { console.error(e); }
    }


    return (
        <div className="quiz-container">
            <div className="quiz-head">
                <div className="quiz-head-title">{history.location.state.quizName}</div>
                <Navbar home={{ isHome: true, home: `${sessionStorage.tora}` }} back={true} refresh={true} />
            </div>
            <hr className="hr-line" />
            <div>
                {
                    loader ?
                        <div id="loader">
                            <Loader
                                type="Oval"
                                color="#0066ff"
                                height={70}
                                width={70}
                            /></div> :
                        results.length === 0 ?
                            <div id="loader" style={{ marginBottom: "100px", color: "rgba(4, 44, 173, 0.856)", fontWeight: "500", textAlign: "center" }}>
                                Students not yet completed the quiz
                            </div> :
                            <div className="quiz-list">
                                <div className="quiz-list-items" style={{ maxHeight: "100vh", marginTop: "5px" }}>

                                    <div style={{ marginBottom: "100px", color: "rgba(4, 44, 173, 0.856)", fontWeight: "500" }}>
                                        {/* //progress bar and result details */}
                                        <div className="quiz__progress__main">
                                            <div className="quiz-head-title quiz__student__details">
                                                <span>Name : {results[resultCounter].studentName}</span><br />
                                                <span>Marks in MCQ's : {results[resultCounter].score}</span><br /><br />
                                            </div>
                                            <div className="quiz__progress__bar">
                                                <span className="quiz__progress__colorbar" style={{ width: `${(counter / (results.length * questions.length)) * 100}%` }}></span>
                                                <span className="quiz__progress__percentbar">Question : {`${counter}/${results.length * questions.length}`}</span><br />
                                            </div>
                                            <div className="quiz__validate__next">
                                                <input type="number" placeholder="Enter Marks Here..." id="QmMarksInput" />
                                                <span className="quiz__validate_next_button" onClick={() => {
                                                    const marksID = document.getElementById("QmMarksInput");
                                                    if (marksID !== null) {
                                                        if (questions) {
                                                            if (questions[questionCounter]) {
                                                                if (questions[questionCounter].mark && questions[questionCounter].negativ_mark) {
                                                                    const marks = parseInt(questions[questionCounter].mark)
                                                                    const negative_mark = parseInt(questions[questionCounter].negativ_mark)
                                                                    const MarksEntered = parseInt(marksID.value)
                                                                    if (marks === 0 && negative_mark === 0) {
                                                                        nextFunc(0, results[resultCounter], marksCounter)
                                                                    } else {
                                                                        if (MarksEntered <= marks && MarksEntered >= (negative_mark === 0 ? 0 : -negative_mark)) {
                                                                            nextFunc(MarksEntered, results[resultCounter], marksCounter + MarksEntered)
                                                                        } else {
                                                                            Swal.fire(
                                                                                "Please Enter Valid Marks",
                                                                                `Please Enter Marks Between or Equals to ${negative_mark === 0 ? 0 : -negative_mark} and ${marks}`,
                                                                                "error"
                                                                            )
                                                                        }
                                                                    }
                                                                } else {
                                                                    Swal.fire(
                                                                        "Marks Not found",
                                                                        "",
                                                                        "error"
                                                                    )
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}>Next</span>
                                            </div>
                                        </div>

                                        {/* //question */}
                                        <div className="quiz__validate__qdiv">
                                            <span>Q.{counter}. {questions[questionCounter].questionTitle}</span>
                                            <div style={{ textAlign: "right", color: "red" }}>* {questions[questionCounter].mark} marks</div>
                                        </div>

                                        {/* //answer */}
                                        <div>
                                            <TextareaAutosize aria-label="minimum height"
                                                className="quiz__validae__adiv"
                                                disabled={true}
                                                value={"Answer : \n\n" + ans}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                }
            </div >
        </div>
    );
}
