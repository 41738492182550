import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useLocation, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import TeacherName from "./TeacherName";

const AddComments = () => {
  const { user_id, sid, staffid } = useParams();
  const location = useLocation();
  let history = useHistory();
  const divArr = location.state.split(" ");
  const fname = divArr[0];
  const lname = divArr[1];
  const divName = divArr[2]; //std
  const d = divArr[4] // division



  const [FieldID, setFieldID] = useState({});
  const [teacher, setTeacher] = useState([]);
  const [FieldName, setFieldname] = useState([]);

  const onChangeHandler = (e) => {
    setFieldID({ ...FieldID, [e.target.id]: e.target.value });
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (FieldID) {
        const data = {
          sid: `${sid}`,
          userid: `${user_id}`,
          staffid: `${staffid}`,
          FieldId: FieldID,
          division: `${divName}`,
          std: `${d}`,
        };

        const token = "#$laksdfnlknoea#$@$%^&%$";
        const response = await axios({
          method: "post",
          url: "https://marcopolo.prisms.in/api/studentlog/comments",
          data,
          headers: {
            Authorization: `${token}`,
          },
        });

        if (response) {
          Swal.fire({
            icon: "success",
            title: "Successfully submited",
            showConfirmButton: false,
            timer: 1500,
          });

          history.goBack({
            pathname: `/studentlog/comment/${user_id}/${sid}/${staffid}`,
          });
        }
      } else {
        Swal.fire("All Fields are required");
      }
    } catch (error) {}
  };

  // navigate to Home
  const onHome = (e) => {
    e.preventDefault();
    history.push(`/studentlog/${sid}/${staffid}`);
  };

  //https://marcopolo.prisms.in/api/studentlog/fields"
  useEffect(() => {
    const getFiledname = async () => {
      const data = {
        sid: `${sid}`,
      };
      const token = "#$laksdfnlknoea#$@$%^&%$";
      const response = await axios({
        method: "post",
        url: "https://marcopolo.prisms.in/api/studentlog/fields",
        data,
        headers: {
          Authorization: `${token}`,
        },
      });
      setFieldname(response.data);
    };

    //https://marcopolo.prisms.in/api/tscommon/staffname

    const getTeachername = async () => {
      const data = {
        sid: `${sid}`,
        staffid: `${staffid}`,
      };
      const token = "#$laksdfnlknoea#$@$%^&%$";
      const response = await axios({
        method: "post",
        url: "https://marcopolo.prisms.in/api/tscommon/staffname",
        data,
        headers: {
          Authorization: `${token}`,
        },
      });

      setTeacher(response.data);
    };

    getTeachername();
    getFiledname();
  }, []);

  console.log(divArr[2])

  return (
    <Container>
      <Wrapper>
        <div>
          <ArrowBackIcon className="icon__btn" onClick={(e) => onHome(e)} />
          <TeacherName divName={divName} teacher={teacher} />
          <div className="form__box">
            <div className="input_box">
              {FieldName.map((data, index) => {
                return (
                  <>
                    {" "}
                    <div className="label_box" key={index}>
                      <h5 className="input_title">{data.field_name}</h5>
                    </div>
                    <TextField
                      id={data.id}
                      variant="outlined"
                      className="input_comment"
                      name="comment"
                      onChange={onChangeHandler}
                    />
                  </>
                );
              })}
            </div>

            <div className="btn__box">
              <button className="btn" onClick={(e) => onSubmit(e)}>
                submit
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export default AddComments;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
`;
const Wrapper = styled.div`
  max-width: 1150px;
  width: 100%;
  height: 80vh;
  margin: 50px auto;
  background-color: white;

  @media (max-width: 768px) {
    width: 100%;
    margin: 5px 0;
    height: 100vh;
    box-shadow: none;
  }

  h4 {
    color: #444;
    font-weight: 400;
    font-size: 1rem;
    @media (max-width: 768px) {
      padding: 0 10px;
    }
  }

  .icon__btn {
    border-radius: 50px;
    font-size: 1.7rem;
    margin-left: 12px;
    color: #1a1f3c;
  }

  .form__box {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 768px) {
      width: 96%;
      margin: 5px 0;
      height: 100vh;
    }
  }
  .input_box {
    margin: 3px 0;
  }
  .label_box {
    width: 90%;
  }

  .input_title {
    font-weight: 600;
    margin-left: 19px;
    font-size: 1.1rem;
    color: #1a1f3c;
  }

  .input_comment {
    margin-left: 15px;
    width: 95%;
    color: #111;
    padding: 3px 5px;
    font-size: 1.1rem;
  }

  .btn__box {
    display: flex;
  }
  .btn {
    border: none;
    height: 43px;
    text-transform: capitalize;
    border-radius: 4px;
    width: 120px;
    font-size: 1rem;
    font-weight: 9000;
    width: 40%;
    background-color: rgb(75, 130, 230);
    color: white;
    margin: 0 auto;
    margin-top: 0px;
    margin-top: 25px;

    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;
