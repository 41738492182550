import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import OrderPay from './OrderPay';
import CartList from './CartList';
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    minHeight:'20%',
    padding:5,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
    padding:5,
    
  },
  img:{
    maxWidth:"60%",
    minWidth:"60%",
    minHeight:"60%",
    maxHeight:"60%",
  },
 
}));

export default function Orders(props) {
  const classes = useStyles();
  const [openModal, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



  return (
    props.data.length>0 &&   props.data.length!=null?
    <> 
    <Typography component="h6"
                variant="h6"
                className={classes.inline}
                color="textPrimary"> Your Cart :</Typography>
    {/* {
    props.data.map((v,i)=>( */}
      <CartList data={props.data} del={props.del}/>

    {openModal ? <OrderPay isopen={openModal} cc={props.CC} data={props.data} total={props.Total} usr={props.info} del={props.del}  close={handleClose}/>:<></>}
     <Typography
                component="h6"
                variant="h6"
                className={classes.inline}
                color="textPrimary"
              >
                Total Payable Amount :  &#x20B9;{props.Total} 
               <Button style={{marginLeft:10}} onClick={handleOpen}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<DoneIcon />}
      >
        Pay Now
      </Button>
              </Typography>
    </>:<>Cart is Empty please add some products</>
  );
}
