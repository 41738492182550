import React, { Component } from 'react';
import { Doughnut } from "react-chartjs-2";
import { MDBContainer,MDBCardText } from "mdbreact";
import '../App.css';
import "react-datepicker/dist/react-datepicker.css";
class Showgraph extends Component {

  render() {
      return (
      <div> 
        <Doughnut data={this.props.dataDoughnutattr} height="225" width="225" options={{ responsive: true, legend: {labels: {fontSize: 15}}, maintainAspectRatio : false }} />
      </div>
      );
    }
  }

export default Showgraph;

