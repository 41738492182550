import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import {Toolbar,Box} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Alert, AlertTitle } from '@material-ui/lab';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  // },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function CreateOpp(props) {
  const [result, setRes]= useState({});
  var sortJsonArray = require('sort-json-array');
  const classes = useStyles();
  const [fname,setFName]=useState();
  const [lname,setLName]=useState();
  const [email,setEmail]=useState();
  const [company,setCompany]=useState();
  const [mob,setMob]=useState();
  const [S,setSuccess]=useState(false);
  const [E,setError]=useState(false);
  const [name,setName]=useState();
  const [opp_type,setOpp]=useState();
  const [desc,setDesc]=useState();
  const {sid} = useParams();

  // const [data,setData]=useState({
  //   fname:null,
  //   lname:null,
  //   email:null,
  //   company:null,
  //   mob:null,
  //   opp_type:null,
  //   desc:null
  // })
  useEffect(()=>{
    console.log(props.opt)
   
    console.log();

  },[])
  
  const handleSnack = () => {
    setSuccess(true);
  };
  const handleFail = () => {
    setError(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  const handleSubmit=(event)=>{
   // console.log("OnSubmit-->"+props.uid)
    // if(fname !='' && lname !='' && email !='' && mob != ''
    // && opp_type !='' && company !='' && desc!='' 
    // && fname !=null && lname !=null&& email != null && mob !=null && opp_type !=null && company !=null&& desc!=null ){
    //   if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email) && /^\d{10}$/.test(mob)){
      if(opp_type !='' && company !='' && desc !='' && opp_type!=null && company !=null && desc!=null && props.uid !=null && props.uid!='' ){
 const res=  axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'insert_opportunity_infofromalumini'
    ,['sid']:sid,['userid']:props.uid,['opp_type']:opp_type,['company']:company,['desc']:desc}
    
    )
    .then(res=> {
         console.log("Result insert"+ JSON.stringify(res));
        setRes(res)}).catch(err =>console.log("Error-"+err));  
        handleSnack();
        alert("Successfully Created the Opportunity");
    
      
    }
    else {
     // alert("Email address is invalid or mobile number is invalid");
      handleFail();
    }   
      // alert("Please Fill All details Correctly")   
     event.preventDefault()
  }

 
  // const fname=()=>{}
  return (
    <Container component="main" maxWidth="xs">
       {/* {window.alert(props.sid+props.uid)} */}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <CreateRoundedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Submit Opportunity
        </Typography>
        {props.info!=undefined?<Box fontFamily="Monospace" fontSize="h6.fontSize" fontStyle='italic' m={1}>{props.info}</Box>:null}
        <Snackbar anchorOrigin={{  vertical: 'top', horizontal: 'center' }} open={S} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Successfully submited the Opportunity. The Opportunity will be live once the Administrator approves.
          Thank You.
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{  vertical: 'top', horizontal: 'center' }} open={E} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Please Check Whether All Fields Are Correctly Filled.
        </Alert>
      </Snackbar>
        <form className={classes.form} noValidate='false' >
          <Grid container spacing={2}>
            
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="Company"
                label="Company"
                type="text"
                id="Company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                autoComplete="current_Company"
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
          <TextField required variant="outlined" id="Mob" name="Mob" label="Contact-No" type="number" 
           value={mob}
           onChange={(e) => setMob(e.target.value)}
          fullWidth />
        </Grid> */}
        <Grid item xs={12} >
        
        <FormControl  fullWidth variant="outlined" id='Opportunity type' className={classes.formControl}>
        <InputLabel variant='outlined'>Opportunity Type</InputLabel>
        <Select variant='outlined'
          
          value={opp_type}
          onChange={(e) => setOpp(e.target.value)}
          label="Opportunity Type"
        >{sortJsonArray(props.opt,'id').map((v,i)=>(
        <MenuItem value={v.id} >{v.type}</MenuItem>
          ))}
        
        </Select>
      </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
        
        </Grid>
        <TextField
          variant="outlined"
            required
            id="Desc"
            name="Desc"
            label="Description"
            multiline
            rows={4}
            fullWidth
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            autoComplete="Description"
          />
           
          </Grid>
          <Button
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
         

        
        </form>
      </div>
      
    </Container>
  );
}