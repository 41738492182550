import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MenuItem, InputLabel, FormControl, Select } from "@material-ui/core";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 50,
  },
  circle: {
    strokeLinecap: "round",
  },
}));
export default function FormDialog(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState("");
  const [Darray, setDarray] = React.useState(null);
  const [Sel, setSel] = React.useState(null);
  const { sid } = useParams();
  const [isapi, setApi] = React.useState(false);

  useEffect(() => {
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        fun_name: "GetIDDesign",
        sid: sid,
      })
      .then((res) => {
        setDarray(res.data.design.reverse());
        setApi(true);
      });
  }, []);

  const handleClose = () => {
    // console.log(props.mod);
    if (props.mod === true) {
      props.modclose();
    } else props.close();
  };
  const Save = () => {
    // console.log(Sel.name);
    // var s = JSON.stringify(Sel.design)
    if (Sel.design === null || Sel.design === "" || Sel.design === " ") {
      var temp =
        '[{"type":"image","version":"3.6.3","originX":"left","originY":"top","left":-450,"top":-450,"width":900,"height":900,"fill":"rgb(0,0,0)","stroke":null,"strokeWidth":0,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"clipTo":null,"backgroundColor":"#fff","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","transformMatrix":null,"skewX":0,"skewY":0,"crossOrigin":"","cropX":0,"cropY":0,"id":"workarea","name":"","src":"","filters":[]}]';
      sessionStorage.setItem("design", temp);
    } else {
      // sessionStorage.setItem('design',Sel.design)
    }
    // sessionStorage.setItem('design-name',Sel.name)
    // sessionStorage.setItem('design-current',JSON.stringify(Sel))

    // props.fun(inn)
    // console.log(inn)
    props.ld(Sel);
    props.close();
  };
  const FontFm = (event) => {
    setAge(event.target.value);
    for (var i = 0; i < Darray.length; i++) {
      if (Darray[i].name === event.target.value) {
        setSel(Darray[i]);
        sessionStorage.setItem("canvastype", Darray[i].orientation);
        // setOpen(Darray[i].orientation)
      }
    }
    console.log(event.target.value);
    // props.ld(event.target.value)
  };


  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Open Design</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To Open the design Select the title and click on Open button.
          </DialogContentText>
          {isapi ? (
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel>Designs</InputLabel>
              <Select
                fullWidth
                value={age}
                onChange={FontFm}
                displayEmpty
                className={classes.selectEmpty}
              >
                {Darray != null || Darray !== undefined
                  ? Darray.map((v, i) => (
                      // <MenuItem key={i} value={v.name}>
                      //   {v.name}                          {v.created_date}
                      // </MenuItem>
                      <div className="menuItemlist" key={i} value={v.name}>
                        <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid rgba(193, 187, 187, 0.67)"
                      }} ><MenuItem>{v.name}</MenuItem><MenuItem style={{
                        color: "blue"
                      }} >{moment(v.created_date).format("DD-MM-YYYY")}</MenuItem></div>
                      </div>
                    ))
                  : null}
              </Select>
            </FormControl>
          ) : (
            <CircularProgress
              variant="indeterminate"
              disableShrink
              className={classes.top}
              classes={{
                circle: classes.circle,
              }}
              size={40}
              thickness={4}
              {...props}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => Save()} color="primary">
            Open
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
