import React, { useEffect, useState } from 'react';
import Header from '../ReusedComponents/Header'
import { useHistory, useParams } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import moment from 'moment'

export default function TMReports({ userType }) {
    const history = useHistory();
    const { sid, uid } = useParams();
    const [Filters, setFilters] = useState(false);
    const [data, setData] = useState("");
    const [sorting, setsorting] = useState(false);
    const [dataa, setdataa] = useState("");

    useEffect(() => {
        if (userType === "superadmin") SuperAdminGetTickets();
        else {
            axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?", { fun_name: "GetTicketCreate", sid: sid }
            ).then(res => {
                const data = res.data.Tickets.filter(i => i.status === "Closed");
                setData(data)
                setdataa(data)
            })
        }
    }, [])


    function SuperAdminGetTickets() {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Appointment_Superadmin", sid: sid }).then(async (res) => {
            if (!res.data || (res.data !== undefined ? (res.data.slist).length === 0 : false) || typeof (res.data) !== "object") {
                axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?", { fun_name: "GetTicketCreate", sid: sid }
                ).then(res => {
                    const data = res.data.Tickets.filter(i => i.status === "Closed");
                    setData(data)
                    setdataa(data)
                })
            } else {
                const response = await axios.all((res.data.slist).map(s => axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "GetTicketCreate", sid: s.sid })))
                const data = response.map(s => {
                    if (s.data) {
                        if (s.data.Tickets) {
                            if (typeof (s.data.Tickets) === "object") {
                                return s.data.Tickets
                            }
                        }
                    }
                    return []
                })
                console.log(data);
                const data1 = data.flat().filter(i => i.status === "Closed");
                console.log(data1);
                setData(data1)
                setdataa(data1)
            }
        })
    }

    function Sorting(p) {
        setData("")
        if (sorting === false) {
            setData(dataa.sort((a, b) => {
                if (a[p] > b[p]) return -1;
                if (a[p] < b[p]) return 1;
                return 0;
            }));
            setsorting(true)
        } else {
            setData(dataa.sort((a, b) => {
                if (a[p] < b[p]) return -1;
                if (a[p] > b[p]) return 1;
                return 0;
            }));
            setsorting(false)
        }
    }


    function removeFilters() {
        setData(dataa);
    }


    function filterFromDatetoDate() {
        const fromdate = document.getElementById("TMreportsfromdate").value
        const todate = document.getElementById("TMreportstodate").value
        const from = new Date(fromdate).getTime()
        const to = new Date(todate).getTime()

        if (fromdate !== "" && todate !== "") {
            setData("")
            setData(dataa.filter(i => {
                const date = new Date(i.date).getTime()
                return from <= date && date <= to
            }))
        } else {
            if (fromdate === "") {
                alert("Please select from date")
            } else {
                alert("Please select to date")
            }
        }
    }

    return (
        <>
            <div className="totalTicketingModule" style={{ overflow: "hidden", height: "100vh" }}>
                <Header
                    title={"Reports"}
                    options={[{ title: "Dashboard", url: `/tickets/admin/dashboard/${sid}/${uid}` }]}
                    ChangePage={(params => history.push(params.data !== undefined ? params.url : params.url, params.data))}
                />
                <div>
                    <div className="TMTicketReportTableMainDiv">
                        <div className="TMReportsFiltersDiv"
                            style={{
                                padding: Filters ? "10px" : "3px", width: Filters ? "auto" : "200px",
                                display: data === "" || dataa === "" ? "none" : dataa.length < 1 || dataa === undefined ? "none" : "block"
                            }}>
                            <div className="TMReportsFilterDropDown" onClick={() => { removeFilters(); setFilters(!Filters) }}>
                                <h4>Filters</h4>
                                <img src={Filters ? "/icons/TMSortUp.png" : "/icons/TMSortDown.png"}></img>
                            </div>
                            {Filters ?
                                <div className="TMReportsFilters">
                                    {/* <div>
                                        <span className="TMReportFiltersHeader">Column</span><br></br>
                                        <select>
                                            <option value="">None</option>
                                        </select>
                                    </div> */}
                                    <div>
                                        <span className="TMReportFiltersHeader">From Date</span><br></br>
                                        <input id="TMreportsfromdate" type="date" />
                                    </div>
                                    <div>
                                        <span className="TMReportFiltersHeader">To Date</span><br></br>
                                        <input id="TMreportstodate" type="date" />
                                    </div>
                                    <div>
                                        <br></br>
                                        <span className="TMSubmitButton" onClick={() => { filterFromDatetoDate() }}>
                                            Apply Filters
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                        {
                            data === "" || dataa === "" ?
                                <div className="TMLoader">
                                    <Loader
                                        type="Puff"
                                        color="#1e5597"
                                        height={80}
                                        width={80}
                                    />
                                </div>
                                :
                                data === undefined || data.length < 1 ?
                                    <div style={{ padding: "10px", color: "#1e5597", fontWeight: 600, width: "90%", height: "50vh", alignItems: "center", justifyContent: "center", display: "flex", textAlign: "center" }}>
                                        No Reports
                                    </div>
                                    :
                                    <div className="TMTicketReportTableDiv" style={{ overflow: "scroll", width: "100%", height: "100vh" }}>
                                        <div style={{ marginBottom: "300px" }}>
                                            <table id="TMreportstable" className="TMTicketReportTable">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th onClick={() => { Sorting("name") }}>Name</th>
                                                        <th onClick={() => { Sorting("duedate") }}>Due Date</th>
                                                        <th onClick={() => { Sorting("title") }}>Task Name</th>
                                                        <th onClick={() => { Sorting("remark") }}>Remark</th>
                                                        <th onClick={() => { Sorting("closed_tickets") }}>Closed Reports</th>                                                </tr>
                                                </thead>
                                                {sorting ?
                                                    <tbody>
                                                        {data.map((d, k) => {
                                                            return (
                                                                <tr key={k}>
                                                                    <td>{k + 1}</td>
                                                                    <td>{d.name}</td>
                                                                    <td>{d.duedate}</td>
                                                                    <td>{d.title}</td>
                                                                    <td>{d.remark === "" ? "Unknown" : d.remark}</td>
                                                                    <td>{d.closed_tickets}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                    :
                                                    <tbody>
                                                        {data.map((d, k) => {
                                                            return (
                                                                <tr key={k}>
                                                                    <td>{k + 1}</td>
                                                                    <td>{d.name}</td>
                                                                    <td>{d.duedate}</td>
                                                                    <td>{d.title}</td>
                                                                    <td>{d.remark === "" ? "Unknown" : d.remark}</td>
                                                                    <td>{d.closed_tickets}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                }
                                            </table>
                                        </div>
                                    </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
