import { downloadExcel } from 'react-export-table-to-excel';
import moment from 'moment';

export const handleDownloadExcel =(header, body)=> {
    downloadExcel({
      fileName: `Admin_Weekly_Plan${moment().format('DD-MM-YYYY, h:mm:ss a') }`,
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body
      },
    });
  }