import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import SlotBooking from '../ReusedComponents/SlotBooking'

export default function BookSlot() {
    const history = useHistory();
    const { reschedule, sid, uid } = useParams();
    const [Slot, setSlot] = useState('');
    const [date, setDate] = useState(history.location.state.requestDate)
    const [AppointmentsBooked, setAppointmentsBooked] = useState('');
    const [person, setperson] = useState({ person: history.location.state.person, staff_id: history.location.state.staff_id });
    const [personslist, setpersonslist] = useState('');

    useEffect(() => {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "GetSuperAStatusByDate", requestDate: date, sid: sid }).then((res) => {
            setAppointmentsBooked((res.data.AStatus !== undefined) ? res.data.AStatus : [])
        })
        if (reschedule === "true") {
            axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "Appointment_StaffAccess", sid: sid }).then((res) => {
                setpersonslist(res.data.Staff);
            })
        }
    }, [date]);

    function OnConfirm() {
        if (Slot !== '') {
            history.push(`/appointment/admin/appointmentdetails/${sid}/${uid}`, history.location.state)
        } else {
            alert('Please Select Slot')
        }
    }

    return (
        <SlotBooking
            data={AppointmentsBooked}
            personslist={personslist}
            setSlot={(params) => { setSlot(params) }}
            setDate={(params) => { console.log(params); setDate(params) }}
            reschedule={reschedule}
            date={date}
            Slot={Slot}
            setPerson={(params) => { setperson(params) }}
            OnConfirm={() => {
                if (history.location.state.staff_id !== "") history.location.state.staff_id = person.staff_id
                history.location.state.requestStatus = Slot;
                history.location.state.requestDate = date;
                history.location.state.person = person.person;
                OnConfirm()
            }}
        />
    );
}
