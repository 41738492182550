import React from 'react';
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";


export default function ScoreAnimate({ score, textsize }) {
    return (
        <AnimatedProgressProvider
            valueStart={0}
            valueEnd={score}
            duration={1}
            easingFunction={easeQuadInOut}
        >
            {(value) => {
                const roundedValue = Math.round(value);
                return (
                    <>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                            <CircularProgressbar
                                value={value}
                                text={`${roundedValue}%`}
                                circleRatio={0.75}
                                strokeWidth={6.5}
                                styles={buildStyles({
                                    rotation: 1 / 2 + 1 / 8,
                                    trailColor: "#eee",
                                    strokeLinecap: "butt",
                                    pathTransition: "none",
                                    pathColor: value > 20 ? "#4CC417" : "red",
                                    textColor: value > 20 ? "#4CC417" : "red",
                                    textSize: `${textsize}px`
                                })}
                            />
                        </div>
                    </>
                );
            }}
        </AnimatedProgressProvider>
    );
}
