import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function OrderPayForm(props) {
  const classes = useStyles();
// const [name,setName]=React.useState();
// const [email,setEmail]=React.useState();
// const [mob,setMob]=React.useState();
// const [address,setAddress]=React.useState();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
       {console.log(props.info)}
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="Name"
                variant="outlined"
                required
                disabled
                fullWidth
                id="Name"
                value={props.info.data.student[0].name}
                onChange={(e)=>props.pname(e.target.value)}
                label="Name"
                autoFocus
              />
            </Grid>
           
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                disabled
                label="Email Address"
                value={props.info.data.student[0].email}
                onChange={(e)=>props.pemail(e.target.value)}
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
          <TextField required variant="outlined" id="Mob" name="Mob" label="Contact-No" type="number" 
           value={props.info.data.student[0].mobile1} disabled
           onChange={(e) => props.pmob(e.target.value)}
          fullWidth />
        </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="School Name"
                label="School Name"
                disabled
                id="School Name"
                value={props.info.data.school.school_name}
                onChange={(e)=>props.paddr(e.target.value)}
                autoComplete="School Name"
                multiline
              />
            </Grid>
           
          </Grid>
          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button> */}
          
        </form>
      </div>
      
    </Container>
  );
}