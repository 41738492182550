import React from 'react';

const SaveAsImageWithResolution = ({ type, refInput }) => {
    const resolution = {}; // Declare an empty object

    if (type === 0) {
        resolution.width = 637;
        resolution.height = 400;
    } else if (type === 1) {
        resolution.width = 400;
        resolution.height = 637;
    }
    const originalCanvas = refInput.current.handler.canvas; // Get the original fabric canvas element

    // Create a temporary canvas with the desired resolution
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = resolution.width;
    tempCanvas.height = resolution.height;
    const tempContext = tempCanvas.getContext('2d');

    // Draw the original canvas image onto the temporary canvas with the desired resolution
    tempContext.drawImage(originalCanvas.lowerCanvasEl, 0, 0, resolution.width, resolution.height);

    // Convert the temporary canvas image to a Blob object
    tempCanvas.toBlob((blob) => {
        // Create a download link for the Blob object
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'image.png'; // Set the filename
        link.click();
    }, 'image/png', 1); 
};

export default SaveAsImageWithResolution;