import React, { useState, useEffect, useContext } from 'react';
import '../css/Quizzes.css';
import {DecryptED} from "../../DecryptJS/Decrypt.js";
import '../css/main.css';
import '../css/main.css';
import { useHistory, useParams } from "react-router-dom";
import axios from 'axios';
import Loader from "react-loader-spinner";
import Navbar from '../User/navbar';
import Swal from 'sweetalert2'
import moment from 'moment';

export default function Quizzes() {

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Quiz />
        </div>
    )
}

function Quiz() {

    const history = useHistory();
    const { sid, uid } = useParams();
    const [quizlist, setQuizlist] = useState([{ quizName: "Quizzes Not Created Yet" }]);
    const [quizzes, setQuizzes] = useState([]);
    const [quizstatus, setstatus] = useState(0);
    const [search, setSearch] = useState("");
    const [div, setdiv] = useState("");
    const [loader, setLoader] = useState(true);
    sessionStorage.setItem("lpath", history.location.pathname)
    try {
        if (sessionStorage.isAdmin === undefined) {
            sessionStorage.setItem("isAdmin", true)
        }
    } catch (e) { console.error(e); }

    // console.log(quizlist)

    useEffect(() => {
        sessionStorage.tora = history.location.pathname
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: "GetClassDiv", sid: sid, u_id: uid }).then(res => {
            try {
                setdiv(res.data.div)
            } catch (e) {
                setdiv([])
                console.error(e);
            }
        })
    }, []);

    useEffect(() => {
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', { fun_name: history.location.pathname === `/admin/${sid}/${uid}` ? "Quizbyuid" : "Quizapp_Get_Quizlist", sid: sid, uid: uid })
            .then(res => {
                try {
                    if (res.data.quizlist.length > 0) {
                        setQuizlist((res.data.quizlist).reverse());
                    }
                } catch (e) {
                    setQuizlist(quizlist);
                    console.error(e);
                }
                setLoader(false);
            })
    }, [quizstatus]);

    useEffect(() => {
        try {
            if (quizlist[0].quizName !== "Quizzes Not Created Yet") {
                setQuizzes(quizlist.filter((quiz) => {
                    return (JSON.stringify({
                        ...quiz,
                        publishDate: moment(quiz.publishDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                        publishTime: moment(quiz.publishTime, "HH:mm:ss").format("h:mm a")
                    }).toLowerCase().indexOf(search.toLowerCase()) !== -1 && quiz.deleted !== "1"
                    )
                }))
            } else {
                setQuizzes(quizlist)
            }
        } catch (e) {

            setQuizzes(quizlist)
            console.error(e);
        }
    }, [search, quizlist])

    function quizStatus(quiz, status) {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: status === "1" ? "Do you want publish this quiz?" : "Do you want to stop responses?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: status === "1" ? 'Publish now' : 'Stop',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoader(true);
                    let classnmid = [];
                    let divnmid = [];
                    const divSelected = quiz.division.split(",")
                    div.map((i) => {
                        if (divSelected.includes(i.div_name)) {
                            if (divnmid.indexOf(i.divid) === -1)
                                divnmid.push(i.divid)
                            if (classnmid.indexOf(i.class) === -1)
                                classnmid.push(i.class)
                        }
                    })
                    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', {
                        fun_name: "Quizapp_Notif", sid: sid, userid: uid, quizid: quiz.id, classid: classnmid.join(","), divid: divnmid.join(","),
                        action: `${quiz.quizName}${quiz.quizName.toLowerCase().includes("quiz") ? "" : " quiz"} is ${status === "1" ? "active now, You can start the quiz now" : "no longer accepting responses"}`
                    })
                    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
                        {
                            fun_name: "Quizapp_UpdateQuiz", sid: sid, userid: uid, id: quiz.id, quizName: quiz.quizName,
                            duration: quiz.duration, class: quiz.class, division: quiz.division,
                            negativeMarking: quiz.negativeMarking, questionOrder: quiz.questionOrder, quizStatus: status,
                            publishDate: quiz.publishDate, publishTime: quiz.publishTime, attempts: quiz.attempts, review: quiz.review, deleted: quiz.deleted
                        })
                        .then(() => { setstatus(quizstatus + 1) })
                        .then(() => {
                            Swal.fire(
                                status === "1" ? 'Published Successfully!' : 'Responses Stopped Successfully!',
                                status === "1" ? 'Quiz is active now' : ' ',
                                'success'
                            )
                        })
                }
                else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    Swal.fire(
                        'Cancelled',
                        status === "1" ? 'Your quiz is not published :)' : " ",
                        'error'
                    )
                }
            })
        } catch (e) { console.error(e); }
    }

    function quizDelete(quiz) {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoader(true);
                    let classnmid = [];
                    let divnmid = [];
                    const divSelected = quiz.division.split(",")
                    div.map((i) => {
                        if (divSelected.includes(i.div_name)) {
                            if (divnmid.indexOf(i.divid) === -1)
                                divnmid.push(i.divid)
                            if (classnmid.indexOf(i.class) === -1)
                                classnmid.push(i.class)
                        }
                    })
                    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?', {
                        fun_name: "Quizapp_Notif", sid: sid, userid: uid, quizid: quiz.id, classid: classnmid.join(","), divid: divnmid.join(","),
                        action: `${quiz.quizName}${quiz.quizName.toLowerCase().includes("quiz") ? "" : " quiz"} is deleted`
                    })
                    axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',
                        {
                            fun_name: "Quizapp_hideQuiz", sid: sid, userid: uid, id: quiz.id, quizName: quiz.quizName,
                            duration: quiz.duration, class: quiz.class, division: quiz.division,
                            negativeMarking: quiz.negativeMarking, questionOrder: quiz.questionOrder, quizStatus: 0,
                            publishDate: quiz.publishDate, publishTime: quiz.publishTime, attempts: quiz.attempts, review: quiz.review, deleted: 1
                        })
                        .then(() => { setstatus(quizstatus + 1) })
                        .then(() => {
                            Swal.fire(
                                'Deleted!',
                                'Your quiz has been deleted.',
                                'success'
                            )
                        })
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    Swal.fire(
                        'Cancelled',
                        'Your quiz is safe :)',
                        'error'
                    )
                }
            })
        } catch (e) { console.error(e); }
    }


    console.log(sid);
    console.log(DecryptED(uid))

    return (

        <div className="quiz-container">
            <div className="quiz-head"><div>Quizzes</div><Navbar home={{ isHome: false, home: `/admin/${sid}/${uid}` }} back={false} refresh={true} /></div>
            <hr className="hr-line" />
            <div className="search-div">
                <input className="quiz-search" type="text" placeholder=" Quick Search" onChange={e => { setSearch(e.target.value) }} />
            </div>
            {loader || div === "" ?
                <div id="loader">
                    <Loader
                        type="Oval"
                        color="#0066ff"
                        height={70}
                        width={70}
                    /></div> :
                <div className="quiz-list">
                    <div className="quiz-list-items">
                        {quizzes.map((quiz, key) => {
                            console.log(key)
                            if (quiz.quizName !== "Quizzes Not Created Yet") {
                                return (
                                    <div key={key} className="quiz-card">
                                        <div className="quiz-title">
                                            {key + 1} . {quiz.quizName}
                                        </div>
                                        <div className="quiz-options">
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div style={{ marginBottom: 4, marginLeft: 3 }}>
                                                    <label style={{ fontSize: "15px", padding: "10px" }}
                                                    >Date:{((quiz.publishDate).split('-'))[2] + "-" + ((quiz.publishDate).split('-'))[1] + "-" + ((quiz.publishDate).split('-'))[0]}
                                                    </label>
                                                </div>
                                                <div>
                                                    <span className="quiz-edit QMquiz_EditButton"
                                                        onClick={() => {
                                                            history.push('/quizapp/editquiz/main' + '/' + sid + '/' + uid, quiz)
                                                        }}><b>Edit</b> </span>
                                                    <span className="quiz-view-results QMquiz_EditButton"
                                                        onClick={() => {
                                                            history.push('/quizapp/results' + '/' + sid + '/' + uid, quiz)
                                                        }}><b>View Result</b>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="switch-container" onClick={() => { quizStatus(quiz, quiz.quizStatus === "0" ? "1" : "0") }}>
                                                <label className="switch">
                                                    <input type="checkbox" id="quizStatusCheck" defaultChecked={quiz.quizStatus === "1" ? true : false} disabled />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", paddingLeft: "17px", flexWrap: "wrap" }}>
                                            <span className="QMquiz_EditButton"
                                                style={{ cursor: "pointer", color: "red", opacity: "0.7" }}
                                                onClick={() => { quizDelete(quiz) }}>
                                                <b>Delete</b>
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="quiz-notfound" key={key}>Quizzes Not Created Yet</div>
                                )
                            }
                        })
                        }
                        <div className="quiz-notfound">
                            {
                                quizzes.length < 1 && search !== "" ? "Quiz Not Found" : null
                            }
                        </div>
                    </div>
                </div>
            }
            <img className="quiz-create-new" onClick={() => {
                history.push('/quizapp/addnewquiz' + '/' + sid + '/' + uid, { id: Date.now(), actiontodo: "addquiz", quizName: "Add New Quiz" })
            }}
                src="https://img.icons8.com/ios-glyphs/50/042CAD/plus-math.png" alt='New Quiz' style={{ opacity: 0.75 }} />
        </div>
    );
}
