export const debounce = (fn, delay) => {
    try {
        let timeoutID;

        return function (...args) {

            if (timeoutID) {
                clearTimeout(timeoutID);

            }

            timeoutID = setTimeout(() => {

                fn(...args);

            }, delay);

        };
    } catch (e) {
        console.error(e);
    }
    return fn
};