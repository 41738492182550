import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {FormLabel,FormControl,Radio,RadioGroup,Box} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// import {Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import { GoogleLogin } from 'react-google-login';
import AchiveImageUp from './AchiveImageUp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import history from './history';
import axios from 'axios';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Helpdesk(props) {
  const classes = useStyles();
  //  const [Name,setName]=React.useState(props.ud!=undefined?props.ud.data[0].full_name:JSON.parse(localStorage.getItem('data')).data[0].full_name);
  const {sid} = useParams();

  const [Name,setName]=React.useState(props.ud.data[0].full_name);
  const [disabledN,setDisabled]=React.useState(props.ud.data[0].full_name==null||props.ud.data[0].full_name==''?false:true);
  const [mob,setMob]=React.useState(null);
  const [sname,setSname]=React.useState(props.ud.data[0].school_name);
  //const [disableds,setDisableS]=React.useState(props.ud.data[0].school_name==null||props.ud.data[0].school_name==''?false:true);
  const [title,setTitle]=React.useState(null);
  const [details,setDetails]=React.useState(null);
  const [value, setValue] = React.useState(null);
  
  const Submit=()=>{
    // const data= JSON.parse(resp);
    if(Name !=null && mob!=null && title !=null && props.ud.data[0].userid!=null && details!=null && details!='' && Name !='' && mob!='' && title !='' && props.ud.data[0].userid!=''){
      if(/^\d{10}$/.test(mob)){

     axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?",{['fun_name']:'AlumniHelpdesk',['sid']:sid,['userid']:props.ud.data[0].userid,['isFeedback']:0,['name']:Name,['sname']:sname,['mobile']:mob,['query']:title,['details']:details}).then((resp)=>{
       if(resp.data.Helpdesk==1){
         window.alert("Query Successfully Registared");
        
         setMob('');
         setTitle('');
         setDetails('');
       }

     })
    } else {
      window.alert('Mobile No. is Incorrect !')
    }
  }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <HelpOutlineIcon fontSize='large' />
        </Avatar>
        {/* <Typography component="h1" variant="h5">
          Sign in 
        </Typography> */}
        <Typography component="h1" variant="h5">
         Helpdesk
        </Typography>
        <Box fontFamily="Monospace" fontSize="h6.fontSize" fontStyle='italic' m={1}>{"शिक्षण मंडळ, कऱ्हाड कार्यालयाशी संपर्क साधण्यासाठी मदत हवी असल्यास आपण इथे आपला निरोप ठेवू शकता. आम्ही आपल्याशी संपर्क करू ..........."}</Box>

        <div className={classes.form} >
          <TextField
           disabled={disabledN}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="Name"
            label="Full Name"
            name="Name"
            value={Name}
            // onChange={(e)=>{setName(e.target.value)}}
            autoComplete="Name"
            
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Mobile No"
            label="Mobile No"
            type="Mobile No"
            id="Mobile No"
            value={mob}
            onChange={(e)=>{setMob(e.target.value)}}
            autoComplete="mob"
            
          />
          {/* <TextField
          disabled={disableds}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Sname"
            label="School Name"
            type="School Name"
            id="School Name"
            value={sname}
            onChange={(e)=>{setSname(e.target.value)}}
            autoComplete="School Name"
          /> */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Query"
            label="Query"
            type="Query"
            id="Query"
            value={title}
            onChange={(e)=>{setTitle(e.target.value)}}
            autoComplete="Query"
            
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Details"
            label="Details"
            type="Details"
            id="Details"
            value={details}
            multiline
            rows={4}
            onChange={(e)=>{setDetails(e.target.value)}}
            
            // autoComplete="Title"
          />
           
          </div>
          <Button style={{margin:20}} variant="contained" color="primary" component="span" onClick={()=>Submit()}>
          Submit
        </Button>
          </div></Container>
  );
    }