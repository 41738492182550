import axios from 'axios';
import React,{useState,useEffect} from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {CardMedia,Box} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CallIcon from '@material-ui/icons/Call';
import WorkIcon from '@material-ui/icons/Work';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Alert} from '@material-ui/lab';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
   
    minWidth: 100,
    marginBottom:10,
    border: "solid #3F51B5",
    background:"#dfe6e9",
    //minWidth:'98vw',
    //maxWidth:'98vw',
  },
  Opp1: {
    //minWidth:'90vw',
    //maxWidth:'90vw',
    backgroundImage:`radial-gradient( circle 939px at 94.7% 50%,  rgba(0,178,169,1) 0%, rgba(0,106,101,1) 76.9% );`,
    // backgroundColor:'#00b894',
    marginBottom:10,
  
  },
  Opp2: {
   // minWidth:'',
    //maxWidth:'90vw',
    backgroundImage: `radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,95,104,1) 0%, rgba(15,156,168,1) 90% );`,
    //backgroundColor:'#574b90',
    marginBottom:10,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
 
  avatar: {

    backgroundColor: "#16a085",
    border:"solid "
    
    
  },
  avatar2: {
    backgroundColor: "#1abc9c",
    border:"solid "
    
    
  },
  inline: {
    
    display: 'inline',
    color:'#000000'
  },
}));


export default function Opportunity(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const {sid} = useParams();

  const [result, setRes]= useState({});
  const [about, setAbout]= useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  var sortJsonArray = require('sort-json-array');
  useEffect(()  =>{
    async function fetchD(){
     
     const res= await axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:"get_alumini_shared_opportunities",['sid']:sid})
     .then(res=> {
       console.log();
       props.Op(res.data.alumni_oppmaster,res.data.about_opp[0].description);
        setAbout(res.data.about_opp[0].description)
         setRes(res)}).catch(err =>console.log("Error-"+err));
        
 }
   fetchD();

  
},[])


    
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      
      const handleClose = () => {
        setAnchorEl(null);
      };
      
  return (
    
    
    result.data!=null ?
    <div style={ {display:'inner-block'}}>
      
    
    
    {console.log("APID-->"+JSON.stringify(sortJsonArray(result.data.alumini_opp,'userid','des')))}

      { 
      
       result.data.alumini_opp.map((v,i)=>(
         
        
        v.type !=null && v.admin_approve==1?
        
    <Card className={v.type=="JOB"? classes.Opp1:classes.Opp2}>
      {console.log("UserID->"+v.userid+ "ss->"+typeof(result.data.alumini_opp))}
      <CardHeader
        avatar={ v.type=="JOB"?
         <Avatar  className={classes.avatar}>
          <WorkIcon/>
       </Avatar>: v.type=="INTERNSHIP"?<Avatar  className={classes.avatar2}>
          <SchoolOutlinedIcon/>
          </Avatar>: null
        }
        title={<p style={{color:'white',margin:0,textTransform: 'capitalize'}}>{v.name!=null && v.name.length>0?v.name:v.full_name}</p>}
        subheader={<React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textSecondary"
                        
                      >
                       {v.type}<br/>
                       <AlternateEmailIcon style={{verticalAlign: 'bottom', height: '0.7em' }} color="#00b894" fontSize="small"/>{": "+v.emailid }<br/>
                      <CallIcon style={{verticalAlign: 'bottom', height: '0.7em' }}  color="#00b894" fontSize="small"/>{": "+v.contact}<br/>  
                      </Typography>
                      
                    </React.Fragment>
        }
      />
      <CardContent>
        <Typography variant="body2" color="#010000" component="p">
        <strong>  {v.description}</strong>
        </Typography>
      </CardContent>
    
      
      </Card>:null
     )) }</div>:<><CircularProgress/></>
  );
}