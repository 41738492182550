import React from 'react';
import Loader from 'react-loader-spinner'

export default function TMHistory(props) {

    return (
        <>
            <div className="totalTicketingModule" style={{ overflow: "hidden", height: "100vh" }}>
                {props.data === "" ?
                    <div className="TMLoader">
                        <Loader
                            type="Puff"
                            color="#1e5597"
                            height={70}
                            width={70}
                        />
                    </div> :
                    <div className="TMHistoryMainDiv">
                        <div style={{ overflow: "scroll", height: "100vh" }}>
                            <div style={{ marginBottom: "300px" }}>
                                {
                                    props.data.map((i, k) => {
                                        return (
                                            <div className="TMHistoryDetailsDiv" key={k}>
                                                <div style={{ display: "flex", alignItems: "flex-start", borderLeft: "2px solid #122854", padding: "10px", marginLeft: "50px", position: "relative" }}>
                                                    {k === 0 ?
                                                        <div style={{ position: "absolute", top: 0, left: 0, height: "30px", backgroundColor: "white", width: "10px", marginLeft: "-3px", marginTop: "0px", zIndex: 1 }}></div>
                                                        : null}
                                                    <img className="TMHistoryIcons" src={`/icons/${(i.history).includes('remark') ? "remarkticket" : (i.history).includes('create') ? "ticketOpened" : (i.history).includes('replied') ? "replyCircle" : (i.history).includes('update') ? "ticketUpdated" : "closeTicket"}.png`} />
                                                    <span className="TMHistoryDetailsDots">••••••••••</span>
                                                    <div className="TMHistoryDetails">
                                                        <span>{i.date1}</span>
                                                        <h4>{i.history}</h4>
                                                        <span dangerouslySetInnerHTML={{ __html: i.history_details }}></span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}
