// APPOINTMENT REQUEST BY USER
import React, { useState, useEffect } from "react";
import axios from "axios";
import Appoinment from "../ReusedComponents/Appointments";
import { useParams } from "react-router-dom";
import { DecryptED } from "../../DecryptJS/Decrypt";

const UserRequest = () => {
  const [Appointmentslist, setAppointmentslist] = useState([]);
  const [Loader, setLoader] = useState(true);
  const { sid, uid } = useParams();

  

  useEffect(() => {
    const getAppointment = async () => {
      try {
        const token = "#$laksdfnlknoea#$@$%^&%$";
        const data = {
          sid: DecryptED(sid),
          uid: DecryptED(uid),
        };
        await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/appointment/user/details",
          data,
          headers: {
            Authorization: `${token}`,
          },
        }).then((res) => {
          console.log(res.data);
          setAppointmentslist((res.data !== undefined) ? res.data : []);
           setLoader(false);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getAppointment();
  }, []);

  return (
    <Appoinment data={Appointmentslist} type="user" loader={Loader} sid={sid} uid={uid} />

  );
};

export default UserRequest;
