import axios from "axios";
function SendNotification({ sid, userid, ticket_id, action }) {
  axios
    .post("https://topschool.prisms.in/rest/index.php/user_list.json", {
      fun_name: "TicketNotif",
      sid,
      userid,
      ticket_id,
      action,
    })
    .then((response) => {
      // Handle the successful response here
      console.log("Request successful:", response);
    })
    .catch((error) => {
      // Handle errors here
      console.error("Request failed:", error);
    });
}
export { SendNotification };
