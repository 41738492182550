import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100vw",
  },
  drawer2: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    [theme.breakpoints.up("sm")]: {
      marginTop: 64,
    },
    width: drawerWidth,
    height: "90vh",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  submit: {
    position: "fixed",
    bottom: 30,
    left: "45vw",
    marginLeft: "10%",
  },
  applist: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    marginBottom: 0,
    marginLeft: "5%",
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "70vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "60vw",
    },
  },
  drawerOpen: {
    width: drawerWidth,

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",

    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  input: {
    borderRadius: 54,
  },
  divclass: {
    position: "fixed",
    top: "35vh",
    left: "50%",
    transform: "translateX(-50%)",
    minHeight: 177,
    maxHeight: 177,
    minWidth: 200,
    maxWidth: 200,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: "35vh",
      height: 200,
      width: 200,
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
    },
  },
  arrowsmall: {
    position: "fixed",
    top: "45vh",
    left: "30%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: "36vh",
      left: "22%",
      transform: "translateX(-50%)",
    },
  },
  arrowsmall2: {
    position: "fixed",
    top: "45vh",
    left: "70%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: "36vh",
      left: "80%",
      transform: "translateX(-50%)",
    },
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  select: {
    position: "fixed",
    left: "50%",
    top: "22%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: "22%",
      left: "45%",
      transform: "translateX(-50%)",
      display: "flex",
    },
  },
  form1: {
    marginBottom: 30,
    marginRight: 12,
    marginTop: 12,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
      marginRight: 12,
      marginTop: 10,
      width: 150,
    },
  },
  form2: {
    marginBottom: 30,
    marginRight: 12,
    marginTop: 12,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
      marginRight: 12,
      marginTop: 10,
      width: 150,
    },
  },
  btn: {
    position: "fixed",
    left: "65%",
    top: "23%",
    transform: "translateX(-50%)",
    borderRadius: 54,
    marginRight: 3,
    marginLeft: 14,
    height: "fit-content",
    marginTop: 15,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      left: "64%",
      top: "24%",
      width: 152,
      transform: "translateX(-50%)",
      borderRadius: 54,
      marginRight: 3,
      marginLeft: 10,
      height: "fit-content",
      marginTop: 5,
    },
  },
  btcip: {
    position: "fixed",
    left: "50%",
    top: "65vh",
    transform: "translateX(-50%)",
    display: "flex",
    width: 260,
    borderRadius: 54,
    marginRight: 3,
    marginLeft: 10,
    height: "fit-content",
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      left: "50%",
      top: "57vh",
      transform: "translateX(-50%)",
      display: "flex",
      width: 260,
      borderRadius: 54,
      marginRight: 3,
      marginLeft: 10,
      height: "fit-content",
      marginTop: 5,
    },
  },
  btnip: {
    position: "fixed",
    left: "50%",
    top: "75vh",
    transform: "translateX(-50%)",
    display: "flex",
    borderRadius: 54,
    marginRight: 3,
    marginLeft: 10,
    height: "fit-content",
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      left: "50%",
      top: "71vh",
      transform: "translateX(-50%)",
      display: "flex",
      width: 260,
      borderRadius: 54,
      marginRight: 3,
      marginLeft: 10,
      height: "fit-content",
      marginTop: 5,
    },
  },
  btncrop: {
    position: "fixed",
    left: "48%",
    top: "85vh",
    transform: "translateX(-50%)",
    borderRadius: 54,
    width: 152,
    marginRight: 3,
    marginLeft: 10,
    height: "fit-content",
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      left: "48%",
      top: "87vh",
      width: "152px",
      transform: "translateX(-50%)",
      borderRadius: 54,
      marginRight: 3,
      marginLeft: 10,
      height: "fit-content",
      marginTop: 5,
    },
  },
  t1: {
    borderRadius: 90,
    marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },
  t2: {
    borderRadius: 90,
    marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },
  t3: {
    background: "#6610f2",
    border: "none",
    color: "#fff",
    borderRadius: 90,
    marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },
}));

export default useStyles;
