import React from 'react';
import TicketReply from '../ReusedComponents/TicketReply'
import { useParams, useHistory } from 'react-router-dom'

export default function AdminTicket() {
    const history = useHistory();
    const { uid, ssid } = useParams();

    return (
        <div className="totalTicketingModule">

            <TicketReply
                userType={history.location.state.userType}
                title={history.location.state.title}
                options={[{ title: "Dashboard", url: `/tickets/superadmin/dashboard/${ssid}/${uid}` }, { title: "Reports", url: `/tickets/superadmin/reports/${ssid}/${uid}` }]}
            />

        </div>
    )
}