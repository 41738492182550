import React, { useEffect, useState } from "react";
import RSelect from "react-select";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Button, Typography } from "@material-ui/core";
import makeAnimated from "react-select/animated";
import { useParams } from "react-router-dom";
import { DecryptED } from "../DecryptJS/Decrypt";
import Navbar from "./Header";

const DownloadStaff = () => {
  const [stafftype, setStafftype] = useState();
  const [staff, setStaff] = useState();
  const [StaffD, setStaffD] = useState();
  const [totalStaff, setTotalStaff] = useState();
  const [loader, setLoader] = useState(false);
  const [availableStaffID, setAvailableStaffID] = useState();
  const animatedComponents = makeAnimated();
  const { sid, idesign } = useParams();
  const token = "#$laksdfnlknoea#$@$%^&%$";
  const configUrl2 = process.env.REACT_APP_BASE_URL;

  // Featch Staff Details
  const fetchStaffData = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `${configUrl2}/api/idcard/staff/data`,
        data: {
          sid: DecryptED(sid),
          did: idesign,
        },
        headers: { Authorization: token },
      });
      var a = [];
      response.data.StaffData.map((i) => {
        if (i.name !== "") {
          const idata = i;

          if (a.length > 0 ? a[0][i.staff_type] === undefined : true) {
            a.length === 0
              ? a.push({ [i.staff_type]: [idata] })
              : (a[0][i.staff_type] = [idata]);
          } else {
            a[0][i.staff_type].push(idata);
          }
          return null;
        }
      });
      if (a.length > 0) {
        setStafftype(Object.keys(a[0]));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (sid && idesign) {
      fetchStaffData();
    }
  }, [sid, idesign]);
  // Download THE selected staff type data

  const downloadImagesAsZip = async (StaffD) => {
    const zip = new JSZip();

    const imagePromises = StaffD.map(async (image) => {
      if (image.idcard_image) {
        const url = image.idcard_image;
        const filename = url.substring(url.lastIndexOf("/") + 1);
        const escapedFilename = filename.substring(11); // Escape the first 20 characters
        const response = await axios.get(url, { responseType: "blob" });
        zip.file(escapedFilename, response.data);
      }
    });

    try {
      await Promise.all(imagePromises);
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `StaffID.zip`);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const checkIdcards = async () => {
    setLoader(true);
    try {
      axios({
        method: "POST",
        url: `${configUrl2}/api/idcard/staff/idcards`,
        data: {
          sid: DecryptED(sid),
          did: idesign,
          stafftype: staff,
        },
        headers: { Authorization: token },
      }).then((res) => {
        setStaffD(res.data.IDcards);
        setTotalStaff(res.data.Total);
        if (res.data.IDcards) {
          const availableStaffID = res.data.IDcards.reduce((count, image) => {
            if (image.idcard_image.trim() !== "") {
              return count + 1;
            }
            return count;
          }, 0);

          setAvailableStaffID(availableStaffID);
        } else {
          console.log("Data not available");
        }

        setLoader(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(StaffD);
  return (
    <>
      <Navbar />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          flexDirection: "column",
          marginTop: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ width: "600px" }}>
            <RSelect
              // className="st"
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={(selectedOptions) => {
                const selectedIds = selectedOptions.map(
                  (option) => option.label
                );
                setStaff(selectedIds);
              }}
              placeholder="Select Staff"
              isMulti
              options={
                Array.isArray(stafftype)
                  ? stafftype.map((data, index) => ({
                      value: index,
                      label: data,
                    }))
                  : "Data is Not available"
              }
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={checkIdcards}
            style={{ marginLeft: "10px" }}
          >
            Check ID
          </Button>
        </div>
        <div>
          {loader ? (
            "...Loading"
          ) : (
            <div
              style={{
                marginTop: "100px",
                display: "grid",
              }}
            >
              {totalStaff > 0 ? (
                <Typography>Total Staff : {totalStaff}</Typography>
              ) : null}
              {availableStaffID > 0 ? (
                <Typography>
                  Total ID-Cards to Download : {availableStaffID}
                </Typography>
              ) : null}

              {totalStaff > 0 ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "15px" }}
                  onClick={() => downloadImagesAsZip(StaffD)}
                >
                  Download
                </Button>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DownloadStaff;
