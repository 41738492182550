import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

const handleP=()=>{
  if(props.state==true){
    props.proceed()
  }
  else{
    window.alert("Please select the checkbox")
  }
}
  return (
    <div>
     {props.isa? <Dialog onClose={props.aclose} aria-labelledby="customized-dialog-title" open={props.isa}>
        <DialogTitle id="customized-dialog-title" onClose={props.aclose}>
        Alert
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
          Your request is successfully submited. We'll get back to you soon
          </Typography>
          </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.aclose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>:
      props.open?
      <Dialog onClose={props.close} aria-labelledby="customized-dialog-title" open={props.open}>
        <DialogTitle id="customized-dialog-title" onClose={props.close}>
        Consent
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            
          By continuing, I agree to allow CREDIN to fetch my credit report from Credit Bureau for the purpose of Credit Assessment. This consent shall be valid for a period of 12 months. By checking the checkbox, I agree to Credit Bureau's Credit Score Terms of Use.
          I understand that I shall have the option to opt out/unsubscribe from the service by sending email to help@credin.in Fetching report from the credit bureau will not impact my credit score.  I have read the T&C on https://credin.shiksha/terms-and-conditions/ and I hereby accept & agree to these T&C.
          </Typography>
          <FormControlLabel
        control={
          <Checkbox
            checked={props.state}
            onChange={props.chk}
            name="checkedB"
            color="primary"
          />
        }
        label="I Agree with the T&C"
      />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleP} style={{color:'#2996ed'}}>
          Continue
          </Button>
        </DialogActions>
      </Dialog>:null
      }
    </div>
  );
}
