import React from "react";
import styled from "styled-components";
const Navbar = () => {
  return (
    <>
      <Navbox>
        <Heading>
          <h3 className="title">Holidays</h3>
        </Heading>
      </Navbox>
    </>
  );
};

export default Navbar;

const Navbox = styled.div`
  position: static;
  background-color: #00c89a;
  height: 75px;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const Heading = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  .title {
     font-size: 1.4rem;
    color: #fff;
    font-weight: 600;
    font-family: Poppins;

    @media screen and (max-width: 768px) {
      padding: 0;
      margin-left: 12px;
    }
  }
`;
