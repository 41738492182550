import React, { useState, useEffect } from "react";
import Filter from "./Filter";
import StudentList from "./StudentList";
import css from "./Transaction.module.css";

const Transaction = () => {
  return (
    <>
      <div className={css.main}>
        <img
          className={css.logo__main}
          src="../icons/wallet/dashboard.png"
          alt=""
        />
        <Filter />
        <div className={css.info__bar}>
          <p>Transaction id</p>
          <p>Name</p>
          <p>Amount</p>
          <p>Transaction Details</p>
        </div>
        <StudentList />
      </div>
    </>
  );
};

export default Transaction;
