import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {Divider} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import OptionalForm from './OptionalForm';
 import { GoogleLogin  } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import history from './history';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import Register from './Register';
import { useEffect } from 'react';
import Alert from './alert';
import Swal from 'sweetalert2'
import Forgetpass from './Forgetpass';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  fb:{
    width: '165px',
    height:'35px',
    borderRadius: '4px',
    background: '#3b5998',
    color:'white',
    border:'0px transparent',
    textAlign: 'center',
    margin:'5px',
    display: 'inline-block',
   '&:hover':{
        background: '#3b5998',
        opacity: 0.6,
    }
  },
  Googlebtn:{
    width: '165px',
    height:'35px',
    borderRadius: '4px',
    background: '#3b5998',
    color:'white',
    border:'0px transparent',
    textAlign: 'center',
    margin:'5px',
    display: 'inline-block',
   '&:hover':{
        background: '#3b5998',
        opacity: 0.6,
    }
  }
}));

export default function Signin(props) {
  const classes = useStyles();
  const [isReg,setReg]=React.useState(false);
  const [isFog,setFog]=React.useState(false);
  const [form,setForm]=React.useState(false);
  const [email,setEmail]=React.useState(null);
  const [contact,setContact]=React.useState(null);
  const [eid,setEid]=React.useState(null);
  const [pass,setPass]=React.useState(null);
  const [userdata,setUserdata]=React.useState(null);
  const [isMoblogin,setLoginMob]=React.useState(true);
  const [isalert,setAlert]=React.useState(false)

  const {sid} = useParams();
  const handleAlertClose = () => {
    setAlert(false);
  };
  const SignIn=()=>{
    localStorage.setItem('data',null)
    if(email !=null && pass!==null && email!=''){
        axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'AlumniLogin',['sid']:sid,['email']:email,['pass']:pass,['glogin']:0}).then((resp)=>{
        console.log(resp);
        if(resp.data.Login==1){
          setEid(resp.data.auth_data[0].email);
          localStorage.setItem('auth',JSON.stringify(resp.data.auth_data))
          localStorage.setItem('Schoollist',JSON.stringify(resp.data.school_list))
          console.log(resp.data.school_list)
          if(resp.data.user_data.length>0){
            
            console.log(resp.data.user_data)
            var s={hd:"Dashboard",data:resp.data.user_data} ;
            console.log(s);
            localStorage.setItem('data',JSON.stringify(s));
            localStorage.setItem('userdata',JSON.stringify(resp.data.user_data));
             history.push({pathname:"/alumni/Dashboard/"+sid,data:s})
            //setForm(true);
          }
          else {
            Swal.fire(
              "Welcome"+resp.data.auth_data[0].name,
              "Welcome to Shikshan Mandal, Karad ",
              'success'
          ).then( setForm(true))
           
            
          }
        }
        else if(resp.data.Login==0){
          Swal.fire(
            "Alert",
            "Email/Mobile and password mismatch",
            'warning'
        )
        }
      })
     }
     else{
      Swal.fire(
        "Alert",
        "Email/Mobile and password mismatch",
        'warning'
    )
     }
  }
 const MobLogin=()=>{
  localStorage.setItem('data',null)
  if(contact !=null && pass!==null && contact!=''){
      axios.post('https://topschool.prisms.in/rest/index.php/user_list.json?',{['fun_name']:'AlumniLogin',['sid']:sid,['contact']:contact,['pass']:pass,['glogin']:2}).then((resp)=>{
      console.log(resp);
      if(resp.data.Login==1){
        localStorage.setItem('auth',JSON.stringify(resp.data.auth_data))
        localStorage.setItem('Schoollist',JSON.stringify(resp.data.school_list))
        console.log(resp.data.school_list)
        if(resp.data.user_data.length>0){
          
          console.log(resp.data.user_data)
          var s={hd:"Dashboard",data:resp.data.user_data} ;
          console.log(s);
          localStorage.setItem('data',JSON.stringify(s));
          localStorage.setItem('userdata',JSON.stringify(resp.data.user_data));
           history.push({pathname:"/alumni/Dashboard/"+sid,data:s})
        }
        else {
          Swal.fire(
            "Welcome "+resp.data.auth_data[0].name,
            "Welcome to Shikshan Mandal, Karad ",
            'success'
        ).then( setForm(true))
        }
      }
      else if(resp.data.Login==0){
        Swal.fire(
          "Alert",
          "Email/Mobile and password mismatch",
          'warning'
      )
      }
    })
   }
   else{
    Swal.fire(
      "Alert",
      "Email/Mobile and password mismatch",
      'warning'
  )
   }
 }

  const responseGoogle=(resp)=>{
    // const data= JSON.parse(resp);
    
    var em=resp.profileObj.email;
    var v=[{name:resp.profileObj.name,email:resp.profileObj.email}];
    if(resp.profileObj.email !=null){
     axios.post("https://topschool.prisms.in/rest/index.php/user_list.json?",{['fun_name']:'AlumniLogin',['sid']:sid,['email']:resp.profileObj.email,['pass']:'',['glogin']:1}).then((respo)=>{
       //console.log(resp.data.Login);
       
       if(respo.data.Login==1 ){
        localStorage.setItem('Schoollist',JSON.stringify(respo.data.school_list))
       console.log(respo.data.school_list)
        setEid(em);
        
        console.log(v);
        localStorage.setItem('auth',JSON.stringify(v))
        
        if(respo.data.user_data.length>0){
          var s={hd:"dash",data:respo.data.user_data} ;
            console.log(s);
            localStorage.setItem('data',JSON.stringify(s));
            localStorage.setItem('userdata',JSON.stringify(respo.data.user_data));
           // localStorage.setItem('Schoollist',JSON.stringify(respo.data.school_list))
         // var s={hd:"Dashboard",data:resp.data.user_data} ;
          history.push({pathname:"/alumni/Dashboard/"+sid,data:s})
        }
        else{
          localStorage.setItem('data',null);
          setForm(true);
        }
      }
    
     })
    }
   
    console.log(resp);
  
  }
  useEffect(()=>{
    if(contact!=null || contact!=''){
      setContact('')
      setLoginMob(false)
    }
  },[email])
  useEffect(()=>{
    if(email!=null || email!=''){
      setEmail('')
      setLoginMob(true)
    }
  },[contact])
  const responseGoogleFail=()=>{
    window.alert("Something went wrong Google login");
  }
  const handleRegClose=()=>{
    setReg(false);
  }
  const handleForClose=()=>{
    setFog(false);
  }
  const handleReg=()=>{
    setReg(true);setFog(false);setForm(false)
  }
  const handleFor=()=>{
    setFog(true);setReg(false);setForm(false); 
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {isReg==false && form==false && isFog==false ?<div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in 
        </Typography>
        <Typography component="h1" variant="h5">
          Alumni App
        </Typography>
        <div className={classes.form} >
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            onChange={(e)=>{setEmail(e.target.value)}}
            autoComplete="email"
            autoFocus
          />
          <p style={{textAlign:'center',marginTop:20}}>OR</p>
          <TextField
          type="Number"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="Mobile"
            label="Mobile Number"
            name="Mobile"
            value={contact!==null?contact.length>10?contact.substring(0,9):contact:null}
            onChange={(e)=>{setContact(e.target.value)}}
            autoComplete="Mobile"
            autoFocus
        />
         <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={pass}
            onChange={(e)=>{setPass(e.target.value)}}
            autoComplete="current-password"
          />
          
         {isMoblogin==false? <Button
          onClick={()=>SignIn()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In With Email/Mobile
          </Button>:
          <Button
          onClick={()=>MobLogin()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
             Sign In With Email/Mobile
          </Button>
          }
          <Link component="button" variant="body2" style={{justifyContent:'center',marginLeft:20,fontSize:'medium'}}
          onClick={() =>handleReg()}
        >
        Register
        </Link>
        <Link component="button" variant="body2" style={{justifyContent:'center',marginLeft:20,fontSize:'medium'}}
          onClick={() => handleFor()}
        >
        forgot password
        </Link>
          <Divider variant='fullWidth'/>
         {/* {isMoblogin==false? <p style={{textAlign:'center', marginTop:20, fontStyle:'italic',color:'#3f51b5',fontSize:'initial',cursor:'pointer' }} onClick={()=>{setLoginMob(true);setEmail('');setPass('')}}>Login With Mobile Number</p>
         : <p style={{textAlign:'center', marginTop:20, fontStyle:'italic',color:'#3f51b5',fontSize:'initial',cursor:'pointer' }} onClick={()=>{setLoginMob(false);setEmail('');setPass('')}}>Login With Email</p>}
          <p style={{textAlign:'center', marginTop:20}}>OR</p> */}
          <div style={{display:'flex',justifyContent:'center',marginTop:25}}>
            
          <GoogleLogin 
    clientId={process.env.REACT_APP_API_KEY}
    buttonText="Login With Google"
    // className={classes.Googlebtn}
    onSuccess={responseGoogle}
    // onFailure={responseGoogleFail}
    cookiePolicy={'single_host_origin'}
    isSignedIn={false}
  />
  </div>
 
        </div>
      </div>: isReg==true &&form==false && isFog==false?<Register sid={sid} lg={handleRegClose} />:isReg==false && form==true && isFog==false?<OptionalForm sid={sid} email={eid}/>
      :isReg==false &&form==false && isFog==true?<Forgetpass sid={sid} lg={handleForClose} />
      :null }
    </Container>
  );
}